<template>
  <section class="">
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Dados do Usuário do Telegram</h3>
        <div v-if="telecomManager">
          <div @click="openModalTelegramUser">
            <AddUser>
              <li slot="title">
                Editar<br />
                Mensagem
              </li>
            </AddUser>
          </div>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'mensagens' }"
              >Lista de Eventos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'usuarios-telegram' }"
              >Lista de Usuários do Telergam</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Dados do Usuário do Telegram</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingMessage">
        <Loading />
      </div>
      <div v-else>
        <article v-if="telegramUser">
          <ul>
            <li class="items itemList" v-if="telegramUser.chatID">
              <span class="titleBlue">Telegram ID</span>
              <div class="list">
                <span class="textGray">{{ telegramUser.chatID }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="telegramUser.firstName">
              <span class="titleBlue">Nome</span>
              <div class="list">
                <span class="textGray">{{ telegramUser.firstName }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="telegramUser.description">
              <span class="titleBlue">Descrição</span>
              <div class="list">
                <span class="textGray">{{ telegramUser.description }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="telegramUser.active">
              <span class="titleBlue">Ativo</span>
              <div class="list">
                <span class="textGray">{{ telegramUser.active }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="telegramUser.created">
              <span class="titleBlue">Criado</span>
              <div class="list">
                <span class="textGray">{{
                  telegramUser.created | formatDateReverse
                }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="telegramUser.modified">
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <span class="textGray">{{
                  telegramUser.modified | formatDateReverse
                }}</span>
              </div>
            </li>
          </ul>
        </article>
        <div class="modal">
          <div
            class="overlay"
            v-if="showModalTelegramUser"
            @click="closeModalTelegramUser"
          ></div>
          <div class="modal_container" v-if="showModalTelegramUser">
            <button class="close btn" @click="closeModalTelegramUser">x</button>
            <h2>Editar Descrição</h2>
            <TelegramUserForm :telegramUser="telegramUser" :errors="errors">
              <button class="btn" @click.prevent="updateTelegramUser">
                Guardar
              </button>
            </TelegramUserForm>
          </div>
        </div>
      </div>
    </div>
    <!-- <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" /> -->
  </section>
</template>

<script>
import TelegramUserForm from "@/components/message/TelegramUserForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "TelegramUserInfos",
  props: ["idTelegramUser"],
  data() {
    return {
      searchHistory: false,
    };
  },
  components: {
    TelegramUserForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError"]),
    ...mapState("user", ["user"]),
    ...mapState("message", [
      "loadingMessage",
      "telegramUser",
      "showModalTelegramUser",
    ]),
    ...mapGetters("message", ["telegramUserReply", "telegramUserReset"]),
    ...mapGetters("user", ["telecomManager"]),
  },
  methods: {
    //##########################################
    // Modal Edit
    //##########################################
    openModalTelegramUser() {
      this.$store.dispatch("message/openModalTelegramUser");
    },
    closeModalTelegramUser() {
      this.$store.dispatch("message/closeModalTelegramUser");
    },
    updateTelegramUser() {
      this.$store.dispatch("message/updateTelegramUser", {
        idTelegramUser: this.idTelegramUser,
        telegramUser: this.telegramUserReply,
      });
    },
  },
  created() {
    this.$store.dispatch("message/getTelegramUser", {
      idTelegramUser: this.idTelegramUser,
    });
  },
};
</script>

<style scoped>
.callsHistory {
  margin-top: 30px;
}

.iconInfoSearchHistory {
  margin: 0 10px;
}
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
}
</style>
