import { api } from "@/services.js";
// import router from "@/router";
import { removeAccent } from "@/helpers.js";
import { formatDate } from "@/helpers.js";

export default {
  namespaced: true,
  state: {
    //############################
    //       Message            
    //############################
    messages: null,
    message: {
      event: null,
      description: null,
      telegramWatchers: [],
    },
    //############################
    //       Telegram Users            
    //############################
    telegramUsers: null,
    telegramUser: {
      chatID: null,
      firstName: null,
      description: null,
      active: true,
    },
    //############################
    //         LOADING            
    //############################
    loadingMessage: false,
    loadingTelegramUser: false,
    //############################
    //  MODAL DELETE
    //############################
    showModalDeleteMessage: false,
    selectedId: null,
    //############################
    //  MODAL EDIT
    //############################
    showModalTelegramUser: false,

    //###############
    //  PESQUISA
    //###############
    searchWord: null,
    filtered: null,

  },
  //########################################################################################
  //                 GETTERS            
  //########################################################################################
  getters: {
    //###############
    //  PESQUISA
    //###############
    getFiltered: (state) => state.filtered,

    messageReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "event": state.message.event,
        "description": state.message.description,
        "telegramWatchers": state.message.telegramWatchers,
      }
    },
    messageReset() {
      return {
        event: null,
        description: null,
        telegramWatchers: [],
      }
    },

    messageTelegramWatchersReset() {
      return {
        telegramWatchers: [],
      }
    },

    telegramUserReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "chatID": state.telegramUser.chatID,
        "firstName": state.telegramUser.firstName,
        "description": state.telegramUser.description,
        "active": state.telegramUser.active,
      }
    },
    telegramUserReset() {
      return {
        chatID: null,
        firstName: null,
        description: null,
        active: true,
      }
    },

    //#####################################
    //  DUAL LIST BOX - MessageForm
    //#####################################
    userActive(state) {
      // console.log("passou aqui 1", state.variant.redirects);
      var active = [];
      for (var index in state.message.telegramWatchers) {
        var userName;
        for (var user in state.telegramUsers) {
          if (state.telegramUsers[user].id == state.message.telegramWatchers[index]) {
            userName = state.telegramUsers[user].firstName;
          }
        }
        active.push({
          id: state.message.telegramWatchers[index],
          contactName: userName,
          selected: false,
        });
      }
      return active;
    },
    userInactive(state) {
      // console.log("passou aqui 2", state.variant.redirects);
      var inactive = [];

      for (var telegramUser in state.telegramUsers) {
        var isActive = false;
        for (var index in state.message.telegramWatchers) {
          if (
            state.message.telegramWatchers[index] == state.telegramUsers[telegramUser].id
          ) {
            isActive = true;
          }
        }
        if (isActive == false) {
          inactive.push({
            id: state.telegramUsers[telegramUser].id,
            contactName: state.telegramUsers[telegramUser].firstName,
            selected: false,
          });
        }
      }
      return inactive;
    },
    //###############
    //  PAGINAÇÃO
    //###############
    paginatedData(state, getters, rootState) {
      // console.log("rootState.currentPage", rootState.currentPage)//acessando o state da global
      let start = (rootState.currentPage - 1) * rootState.limit,
        end = start + rootState.limit;
      if (state.filtered) {
        return state.filtered.slice(start, end);
      } else {

        return state.contacts.slice(start, end);
      }
    },

  },
  //########################################################################################
  //                 MUTATIONS            
  //########################################################################################
  mutations: {
    //#####################################
    //  DUAL LIST BOX - MessageForm
    //#####################################
    UPDATE_MESSAGE_TELEGRAMWATCHERS(state, active) {
      for (var index in active) {
        state.message.telegramWatchers.push(active[index].id)
      }
    },
    RESET_MESSAGE_TELEGRAMWATCHERS(state) {
      state.message.telegramWatchers.splice(0);
    },

    //###############
    //  PESQUISA
    //###############
    RESET_FILTER(state, clean) {
      state.searchWord = clean;
      state.filtered = clean;
    },
    FILTERED_TELEGRAM_USER(state, word) {
      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())
        state.filtered = state.telegramUsers
          .filter(telegramUser => {
            return telegramUser.chatID != null && telegramUser.chatID.toLowerCase().includes(word)
          })
      }
    },

    //########################### 
    //    LOADING
    //###########################
    UPDATE_LOADING(state, payload) {
      state.loadingMessage = payload;
    },
    UPDATE_LOADING_TELEGRAMUSER(state, payload) {
      state.loadingTelegramUser = payload;
    },

    //################################################################# 
    //       MESSAGES
    //#################################################################
    UPDATE_MESSAGES(state, payload) {
      // console.log(state.filtered)
      if (payload != null)
        state.messages = payload.sort((a, b) => {
          let x = a.description.toUpperCase(),
            y = b.description.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else state.messages = payload
    },

    UPDATE_MESSAGE(state, payload) {
      state.message = Object.assign(state.message, payload);
    },

    FILTERED_UPDATE_MESSAGES(state, word) {
      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())
        state.filtered = state.messages
          .filter(message => {
            // return telegramUser.chatID != null && telegramUser.chatID.toLowerCase().includes(word)
            let hasTelegramWatcher = false
            message.telegramWatchers.filter(telegramWatcher => { telegramWatcher.includes(word) ? hasTelegramWatcher = true : false })
            return (message.event != null && message.event.toLowerCase().includes(word)) || (message.description != null && message.description.toLowerCase().includes(word)) || (message.telegramWatchers != null && hasTelegramWatcher)

          })
      }
    },
    // Modal delete message 
    UPDATE_MODAL_DELETE_MESSAGE(state, payload) {
      state.showModalDeleteMessage = payload;
    },

    UPDATE_DELETE_MESSAGE(state, id) {
      let messages = state.messages.filter(c => c.id != id)
      state.messages = messages;
    },


    //################################################## 
    //    TELEGRAM USER
    //##################################################

    UPDATE_TELEGRAM_USERS(state, payload) {
      // state.telegramUsers = payload
      if (payload != null)
        state.telegramUsers = payload.sort((a, b) => {
          let x = a.created.toUpperCase(),
            y = b.created.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else state.telegramUsers = payload
    },

    UPDATE_TELEGRAM_USER(state, payload) {
      state.telegramUser = Object.assign(state.telegramUser, payload);
    },

    UPDATE_MODAL_TELEGRAMUSER(state, payload) {
      state.showModalTelegramUser = payload;
    },




  },
  //########################################################################################
  //                 ACTION            
  //########################################################################################
  actions: {


    //##################################################
    //                 MESSAGES  
    //##################################################

    getMessages(context, refresh) {
      context.commit("RESET_FILTER", null);
      context.commit("UPDATE_LOADING", true);
      if (context.state.messages === null || refresh != undefined) {
        api.get(`/message/event_watch`)
          .then(
            response => {
              const messages = response.data.map(function (message) {
                message.created = formatDate(new Date(message.created));
                message.modified = formatDate(new Date(message.modified));
                return message;
              });
              // console.log(messages)
              context.commit("UPDATE_MESSAGES", messages);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_MESSAGES", context.state.messages);
        context.commit("UPDATE_LOADING", false);
      }
    },


    //##################################################
    //    MESSAGE - INFORMAÇÕES - ACTIONS
    //##################################################

    getMessage(context, { idMessage, refreshId }) {
      // console.log("refreshId", refreshId)
      context.commit("UPDATE_LOADING", true);
      if (context.state.messages === null || refreshId != undefined) {
        api.get(`/message/event_watch/${idMessage}`)
          .then(
            response => {
              const message = {}
              Object.keys(response.data).filter(key => {
                // ##### retornando todas as datas formatadas
                return ((key === "created") && (message[key] = formatDate(new Date(response.data[key])))) ||
                  ((key === "modified") && (message[key] = formatDate(new Date(response.data[key])))) ||
                  (message[key] = response.data[key])
              })
              context.commit("UPDATE_MESSAGE", message);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        const contact = context.state.messages.find(c => c.id == idMessage)
        // console.log(contact)
        context.commit("UPDATE_MESSAGE", contact);
        context.commit("UPDATE_LOADING", false);
      }
    },
    //##################################################
    //   ADD - MESSAGE - ACTIONS
    //##################################################
    addMessage(context, { message }) {
      console.log("context", context)
      console.log("message", message)
      context.commit("UPDATE_LOADING", true);
      api.post(`/message/event_watch`, message)
        .then(() => {
          context.dispatch("getMessages", "refresh");
          context.commit("UPDATE_MESSAGE", null);
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Mensagem adicionada com sucesso!",
          }, { root: true });
          context.commit("RESET_MESSAGE_TELEGRAMWATCHERS");
          context.commit("UPDATE_MESSAGE", this.getters["message/messageReset"]);//limpa o formulário depois de cadastrar        
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "missing required body") {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //##################################################
    //     UPDATE - MESSAGE - UPDATE - ACTIONS
    //##################################################
    updateMessage(context, { message, idMessage }) {
      // console.log("context", context)
      // console.log("message", message)
      // console.log("idMessage", idMessage)
      context.commit("UPDATE_LOADING", true);
      api.put(`/message/event_watch/${idMessage}`, message)
        .then(() => {
          context.dispatch("getMessages", "refresh");
          context.dispatch("getMessage", { idMessage, refreshId: "refreshId" });

          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Mensagem editada com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response.data)
          if (error.response.data.error === "missing required body") {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //##################################################
    //    DELETE - MESSAGE - ACTIONS
    //##################################################

    //MODAL DELETE MESSAGE 
    openModalDelMessage(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_MESSAGE", true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    closeModalDelMessage(context) {
      context.commit("UPDATE_MODAL_DELETE_MESSAGE", false);
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },

    //Delete - Delete 1 de 2
    confirmDeleteMessage(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelMessage");//abre o modal
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },

    //Delete - Delete 2 de 2
    deleteMessage(context, { id }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/message/event_watch/${id}`)
        .then(() => {
          context.commit("UPDATE_DELETE_MESSAGE", id); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Evento deletado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //##################################################
    //             TELEGRAM USERS  
    //##################################################
    // MODAL TELEGRAM USER
    openModalTelegramUser(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_TELEGRAMUSER", true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    closeModalTelegramUser(context) {
      context.commit("UPDATE_MODAL_TELEGRAMUSER", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },

    getTelegramUsers(context, refresh) {
      context.commit("RESET_FILTER", null);
      context.commit("UPDATE_LOADING", true);
      if (context.state.telegramUsers === null || refresh != undefined) {
        api.get(`/message/telegram_user`)
          .then(
            response => {
              const telegramUsers = response.data.map(function (telegramUser) {
                telegramUser.created = formatDate(new Date(telegramUser.created));
                telegramUser.modified = formatDate(new Date(telegramUser.modified));
                return telegramUser;
              });
              // console.log(telegramUsers)
              context.commit("UPDATE_TELEGRAM_USERS", telegramUsers);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_TELEGRAM_USERS", context.state.telegramUsers);
        context.commit("UPDATE_LOADING", false);
      }
    },

    //##################################################
    //             TELEGRAM USER - Informações
    //##################################################
    //Puxar Dados do Telegram user
    getTelegramUser(context, { idTelegramUser, refreshId }) {
      // console.log("refreshId", refreshId)
      context.commit("UPDATE_LOADING", true);
      if (context.state.telegramUsers === null || refreshId != undefined) {
        api.get(`/message/telegram_user/${idTelegramUser}`)
          .then(
            response => {
              // const contact = response.data
              const telegramUser = {}
              Object.keys(response.data).filter(key => {
                // ##### retornando todas as datas formatadas
                return ((key === "created") && (telegramUser[key] = formatDate(new Date(response.data[key])))) ||
                  ((key === "modified") && (telegramUser[key] = formatDate(new Date(response.data[key])))) ||
                  (telegramUser[key] = response.data[key])
              })
              context.commit("UPDATE_TELEGRAM_USER", telegramUser);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        const contact = context.state.telegramUsers.find(c => c.id == idTelegramUser)
        // console.log(contact)
        context.commit("UPDATE_TELEGRAM_USER", contact);
        context.commit("UPDATE_LOADING", false);
      }
    },


    //##################################################
    //          UPDATE - TELEGRAM USER
    //##################################################
    updateTelegramUser(context, { telegramUser, idTelegramUser }) {
      // console.log("context", context)
      // console.log("message", telegramUser)
      // console.log("idMessage", idTelegramUser)
      context.commit("UPDATE_LOADING", true);
      api.put(`/message/telegram_user/${idTelegramUser}`, telegramUser)
        .then(() => {
          context.dispatch("getTelegramUsers", "refresh");
          context.dispatch("getTelegramUser", { idTelegramUser, refreshId: "refreshId" });

          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Descrição editada com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response.data)
          if (error.response.data.error === "missing required body") {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
  },
}