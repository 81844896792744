import { api } from "@/services.js";
import router from "@/router";
import { removeAccent } from "@/helpers.js";
import { formatDate } from "@/helpers.js";
import { alertDate } from "@/helpers.js";

export default {
  namespaced: true,
  state: {
    //############################
    //       CLIENT            
    //############################
    processes: null,
    process: {
      // oppositeList: [],
      // taxes: [],
      courtID: null,
      positionID: null,
      value: null,
      startDate: null,
      processTypeID: null,
      processNumber: null,    
      archived: false,
      files: null,    
    },
    processDel: null,
    timeline:[],
    processNotes: null,
    processNote: {
      title: null,
      notes: null,
      date: null,
      rememberDate: null,
    },
    // btnRememberDate:false,
    btnRememberDate: {
      btnRememberDate:false,
    },
    // noteItem: {
    //   title: null,
    //   notes: null,
    //   date: null,
    //   rememberDate: null,
    // },
    processTypes: null,
    processType: {
      name: null,
      description: null,
    },
    processCourts: null,
    processCourt: {
      name: null,
    },
    processPositions: null,
    processPosition: {
      name: null,
    },
    processTaxes: null,
    processTax: {
      value: null,
      taxTypeID: null,
      paymentDate: null,
    },

    processTypeTaxes: null,
    processTypeTax: {
      name: null,
      description: null,
    },

    processOpposites: null,
    processOpposite: {
      name: null,
      nif: null,
      addresses: [],
      contacts: [],
      files: null,
    },

    contactID: {
      contactID: null,
    },
    //############################
    //         DASHBOARD            
    //############################
    processNoteStatus:null,
    //############################
    //         LOADING            
    //############################
    loadingProcess: false,
    loadingLegal: false,
    //############################
    //  PESQUISA
    //############################
    searchWord: null,
    filtered: null,
    oppositeHistory: null,
    dateProcess: null,
    oppositeSearch: null,
    numberSearch: null,
    valueSearch: null,
    courtSearch: null,
    arSearch: null,
    typeProcessSearch: null,
    //############################
    //  MODAL            
    //############################
    showModalProcess: false,
    showModalDelProcess: false,  
    showModalProcessNote: false,
    showModalDelProcessNote: false,  
    showModalProcessType: false,
    showModalDelProcessType: false,     
    showModalProcessCourt: false,
    showModalDelProcessCourt: false,      
    showModalProcessPosition: false,
    showModalDelProcessPosition: false,      
    showModalProcessTax: false,
    showModalDelProcessTax: false,  
    showModalProcessTypeTax: false,
    showModalDelProcessTypeTax: false,    
    showModalProcessOpposite: false,
    showModalDelProcessOpposite: false,    
    showModalDeleteFile: false,
    showModalDeleteFileTax: false,
    showModalContact: false,
    showModalDelContact: false,
    //############################
    //      ficheiro            
    //############################
    selectedFile: null,
    archiveID: null,
    taxID:null,
    // fileID: null,
    //############################
    //      Update tax            
    //############################
    taxUpdate:false,
  },
  //########################################################################################
  //                 GETTERS            
  //########################################################################################
  getters: {
    getFiltered: (state) => state.filtered,
    
    processReply(state) {
      return {
        // "oppositeList": state.process.oppositeList,
        // "taxes": state.process.taxes,
        "courtID": state.process.courtID,
        "positionID": state.process.positionID,
        "value": state.process.value,
        "startDate": state.process.startDate,
        "processTypeID": state.process.processTypeID,
        "processNumber": state.process.processNumber,
        "archived": state.process.archived,   
        "files": state.process.files,
      }
    },
    processReset() {
      return {
        // oppositeList: [],
        // taxes: [],
        courtID: null,
        positionID: null,
        value: null,
        startDate: null,
        processTypeID: null,
        processNumber: null,
        archived:false,
        files:null
      }
    },
    processNoteReply(state) {
      return {
        "title": state.processNote.title,
        "notes": state.processNote.notes,
        "date": state.processNote.date,
        "rememberDate": state.processNote.rememberDate,
      }
    },
    processNoteReset() {
      return {
        title: null,
        notes: null,
        date: null,
        rememberDate: null,
      }
    },
    rememberDateReply(state) {
      return {
        rememberDate: state.processNote.rememberDate,
      }
    },
    rememberDateReset() {
      return {
        rememberDate: null,
      }
    },

    processTypeReply(state) {
      return {
        "name": state.processType.name,
        "description": state.processType.description,       
      }
    },
    processTypeReset() {
      return {
        name: null,
        description: null,       
      }
    },
    processCourtReply(state) {
      return {
        "name": state.processCourt.name,     
      }
    },
    processCourtReset() {
      return {
        name: null,  
      }
    },
    processPositionReply(state) {
      return {
        "name": state.processPosition.name,     
      }
    },
    processPositionReset() {
      return {
        name: null,  
      }
    },
    processTaxReply(state) {
      return {
        "value": state.processTax.value,
        "taxTypeID": state.processTax.taxTypeID,       
        "paymentDate": state.processTax.paymentDate,       
      }
    },
    processTaxReset() {
      return {
        value: null,
        taxTypeID: null,     
        paymentDate: null,     
      }
    },
    otherTaxes(state) {
      let active = null;
      if (state.filtered != null) {
        active = state.filtered
      } else {
        active = state.processTaxes;
      }
      if (active != null) {
        for (let index in state.process.taxes) {
          active = active.filter(
            (processTax) => processTax.id != state.process.taxes[index].id
          );
        }
      }
      return active;
    },

    processTypeTaxReply(state) {
      return {
        "name": state.processTypeTax.name,
        "description": state.processTypeTax.description,       
      }
    },
    processTypeTaxReset() {
      return {
        name: null,
        description: null,     
      }
    },
    
    processOppositeReply(state) {
      return {
        "name": state.processOpposite.name,
        "nif": state.processOpposite.nif,       
      }
    },
    processOppositeReset() {
      return {
        name: null,
        nif: null,       
      }
    },
    contactIDReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "contactID": state.contactID.contactID,
      }
    },
    contactIDReset() { //Enviar somente estas informações para cadastro e edição.
      return {
        contactID: null,
      }
    },
    otherContacts(state, getters, rootState) {
      let active = null;
      if (rootState.contact.filtered != null) {
        active = rootState.contact.filtered
      } else {
        active = rootState.contact.contacts;
      }
      if (active != null) {
        for (let index in state.processOpposite.contact) {
          active = active.filter(
            (contact) => contact.id != state.processOpposite.contacts[index].id
          );
        }
      }
      return active;
    },
    otherAddresses(state, getters, rootState) {
      let active = null;
      if (rootState.address.filtered != null) {
        active = rootState.address.filtered
      } else {
        active = rootState.address.addresses;
      }
      if (active != null) {
        for (let index in state.processOpposite.adress) {
          active = active.filter(
            (contact) => contact.id != state.processOpposite.adresses[index].id
          );
        }
      }
      return active;
    },
    //##############################
    //      PAGINAÇÃO OPPOSITE
    //##############################
    paginatedData(state, getters, rootState) {
      let start = (rootState.currentPage - 1) * rootState.limit,
      end = start + rootState.limit;
      if (state.filtered) {
        return state.filtered.slice(start, end);
      } else {
        
        return state.processOpposites.slice(start, end);
      }
    },
    //##############################
    //        PAGINAÇÃO PROOCESS
    //##############################
    paginatedProcess(state, getters, rootState) {
      let start = (rootState.currentPage - 1) * rootState.limit,
      end = start + rootState.limit;
      if (state.filtered) {
        return getters.filteredProcess.slice(start, end);
        // return state.filtered.slice(start, end);
      } else {
        
        return getters.filteredProcess.slice(start, end);
        // return state.processes.slice(start, end);
      }
    },
    //##############################
    //      FILTRO PROCESSE
    //##############################
    // if (router.currentRoute.query.startDate) {
    //   context.commit("UPDATE_STARTDATE", {
    //     startDate: router.currentRoute.query.startDate
    //   });
    // }
    filteredProcess(state,getters) {     
      return getters.getFiltered || state.processes;
      // if (router.currentRoute.query.archived === "true") {
      //   return state.processes.filter((process) => {
      //     return process.archived === true;
      //   });       
      // } else if (router.currentRoute.query.archived === "false") {      
      //   return (
      //     getters.getFiltered ||
      //     state.processes.filter((process) => {
      //       return process.archived === false;
      //     }
      //     )
      //   );
      // } else {
      //   return getters.getFiltered || state.processes;
      // }
    },
    //##############################
    //        PAGINAÇÃO TYPE
    //##############################
    paginatedProcessType(state, getters, rootState) {
      let start = (rootState.currentPage - 1) * rootState.limit,
      end = start + rootState.limit;
      if (state.filtered) {
        return state.filtered.slice(start, end);
      } else {
        
        return state.processTypes.slice(start, end);
      }
    },
    //##############################
    //        PAGINAÇÃO COURT
    //##############################
    paginatedProcessCourt(state, getters, rootState) {
      let start = (rootState.currentPage - 1) * rootState.limit,
      end = start + rootState.limit;
      if (state.filtered) {
        return state.filtered.slice(start, end);
      } else {
        
        return state.processCourts.slice(start, end);
      }
    },
    //##############################
    //     PAGINAÇÃO TYPE TAX
    //##############################
    paginatedProcessTypeTax(state, getters, rootState) {
      let start = (rootState.currentPage - 1) * rootState.limit,
      end = start + rootState.limit;
      if (state.filtered) {
        return state.filtered.slice(start, end);
      } else {        
        return state.processTypeTaxes.slice(start, end);
      }
    },
    //##############################
    //      PAGINAÇÃO POSITION
    //##############################
    paginatedProcessPosition(state, getters, rootState) {
      let start = (rootState.currentPage - 1) * rootState.limit,
      end = start + rootState.limit;
      if (state.filtered) {
        return state.filtered.slice(start, end);
      } else {
        
        return state.processPositions.slice(start, end);
      }
    },

    processNoteStatus(state) {
      let processItens = [];
      let alertType = null;
      for (let process in state.processes) {
        if (state.processes[process].notes != null && state.processes[process].notes.length > 0) {
          for (let note in state.processes[process].notes) {
            alertType = alertDate(new Date(state.processes[process].notes[note].rememberDate), 60)
            if (alertType == true) {              
              let url = "/juridico/processos/processo/" + `${state.processes[process].id}` + "#" + `${state.processes[process].notes[note].id}`
              processItens.push({
                path: url,
                processNumber: state.processes[process].processNumber,
                date: state.processes[process].notes[note].rememberDate,
                title: state.processes[process].notes[note].title,
              })
            }
          }
        }
      }
      return processItens;
    },
  },
  mutations: {
    
    UPDATE_PROCESS_DEL(state, payload) {
      state.processDel = payload;
    },

    UPDATE_SELECTED_ID(state, payload) {
      state.selectedId = payload;
    },
    //###############
    //  FICHEIRO
    //###############
    UPDATE_MODAL_DELETE_FILE(state, payload) {
      state.showModalDeleteFile = payload;
    },
    UPDATE_MODAL_DELETE_FILERTAX(state, payload) {
      state.showModalDeleteFileTax = payload;
    },
    UPDATE_SELECTEDFILE(state, payload) {
      state.selectedFile = payload;
    },
    UPDATE_SELECTED_ARCHIVE(state, { archiveID }) {
      state.archiveID = archiveID;
      // state.fileID = fileID;
    },
    UPDATE_SELECTED_TAXID(state,  taxID ) {
      state.taxID = taxID;
    },
    

    
    //########################### 
    //    LOADING
    //###########################
    UPDATE_LOADING(state, payload) {
      state.loadingProcess = payload;
    },
    UPDATE_LOADING_LEGAL(state, payload) {
      state.loadingLegal = payload;
    },    
    //###############
    //  PESQUISA
    //###############
    RESET_FILTER(state, clean) {
      state.searchWord = clean;
      state.filtered = clean;
      state.dateProcess = clean;
      state.oppositeSearch = clean;
      state.numberSearch = clean;
      state.valueSearch = clean;
      state.courtSearch = clean;
      state.arSearch = clean;
      state.typeProcessSearch = clean;
    },
    UPDATE_SEARCHWORD(state, payload) {
      state.searchWord = payload;
    },
    UPDATE_DATEPROCESS(state, payload) {
      state.dateProcess = payload;
    },
    UPDATE_OPPOSITEHISTORY(state, payload) {
      state.oppositeHistory = payload
    },
    UPDATE_OPPOSITE_SEARCH(state, payload) {
      state.oppositeSearch = payload;
    },
    UPDATE_NUMBER_SEARCH(state, payload) {
      state.numberSearch = payload;
    },
    UPDATE_VALUE_SEARCH(state, payload) {
      state.valueSearch = payload;
    },
    UPDATE_COURT_SEARCH(state, payload) {
      state.courtSearch = payload;
    },
    UPDATE_AR_SEARCH(state, payload) {
      state.arSearch = payload;
    },
    UPDATE_TYPEPROCESS_SEARCH(state, payload) {
      state.typeProcessSearch = payload;
    },
    //########################### 
    //    CONTACT
    //###########################

    UPDATE_MODAL_CONTACT(state, payload) {
      state.showModalContact = payload;
    },
    UPDATE_MODAL_DEL_CONTACT(state, payload) {
      state.showModalDelContact = payload;
    },
    //################################################################# 
    //       PROCESSES
    //#################################################################
   
    UPDATE_PROCESSES(state, payload) {
      if (payload != null && payload != ""){
        let listProcess = null;
          listProcess = payload.sort((a, b) => {
          let x = null,
              y = null;   
            for (let aIndex in a.oppositeList){
              for (let bIndex in b.oppositeList){
                if(a.oppositeList[aIndex] != undefined || b.oppositeList[bIndex] != undefined){
                  x = a.oppositeList[aIndex].name.toUpperCase()
                y = b.oppositeList[bIndex].name.toUpperCase()
                }               
              };
            };
            return x == y ? 0 : x > y ? 1 : -1;
          }
        )
        state.processes = listProcess     
      }  
      else {state.processes = payload}        
    },

    UPDATE_PROCESSES_DASHBOARD(state, payload) { 
      let processItens = [];
      let alertType = null;
      for (let process in payload) {
        if (payload[process].notes != null && payload[process].notes.length > 0) {
          for (let note in payload[process].notes) {
            if (payload[process].notes[note].rememberDate != null) {              
              alertType = alertDate(new Date(payload[process].notes[note].rememberDate), 60)
              if (alertType == true) {              
                let url = "/juridico/processos/processo/" + `${payload[process].id}` + "#" + `${payload[process].notes[note].id}`
                processItens.push({
                  path: url,
                  processNumber: payload[process].processNumber,
                  date: payload[process].notes[note].rememberDate,
                  title: payload[process].notes[note].title,
                })
              }
            }
          }
        }
      }
      state.processNoteStatus = processItens
    },
    UPDATE_PROCESS(state, payload) {
      state.process = Object.assign(state.process, payload);
     
      let itemTimeline = [];
      let typeTimeline = [
        {
          type: "tax",
          nameProcess: "Taxa",
          nameProcess2: null,
          // path: "/juridico/taxas/taxa/",
          path: null,
          img: "taxId.svg"
        },
        {
          type: "court",
          nameProcess: "Tribunal",
          nameProcess2: null,
          // path: "/juridico/tribunais/tribunal/",
          path: null,
          img: "courtProcess.svg"
        },
        {
          type: "position",
          nameProcess: "Posição",
          nameProcess2: null,
          // path: "/juridico/lista-posicoes/posicao/",
          path: null,
          img: "AR.svg"
        },	
        {
          type: "value",
          nameProcess: "Valor",
          nameProcess2: null,
          path: null,
          img: "value.svg"
        },
        {
          type: "archived",
          nameProcess: "Processo Arquivado",
          nameProcess2: "Processo Aberto",
          path: null,
          img: "archived.svg"
        },	
        {
          type: "note",
          nameProcess: "Nota",
          nameProcess2: null,
          path: null,
          img: "noteAddProcess.svg"
        },	
        {
          type: "attachment",
          nameProcess: "Ficheiro",
          nameProcess2: null,
          path: null,
          img: "imgIcon.svg"
        },	
        {
          type: "opposite",
          nameProcess: "Interveniente",
          nameProcess2: null,
          path: "/juridico/interveniente/",
          img: "oppositeIcon.svg"
        },	
        {
          type: "processType",
          nameProcess: "Tipo de Processo",
          nameProcess2: null,
          // path: "/juridico/tipos-de-processos/tipo-de-processo/",
          path: null,
          img: "processType.svg"
        },	
        {
          type: "processNumberID",
          nameProcess: "Número do Processo",
          nameProcess2: null,
          path: null,
          img: "processNumber.svg"
        },	
        {
          type: "startDate",
          nameProcess: "Início do Processo",
          nameProcess2: null,
          path: null,
          img: "starDateIcon.svg"
        }		
      ]	      
        if (state.process.history != null && state.process.history.length > 0) {
          for (var index in state.process.history) {
            let operationName = null;
            let classOperation = null;
            if (state.process.history[index].operation == "update") {
              operationName = "Alteração";
              classOperation = "titleBlue";
            } else if (state.process.history[index].operation == "insert") {
              operationName = "Inserção";
                classOperation = "textGreen";
              } else if (state.process.history[index].operation == "remove" || state.process.history[index].operation == "delete") {
                operationName = "Remoção";
                classOperation = "textRed";
              };
              for (let item in typeTimeline) { 
              let typeOldData = state.process.history[index].type == "processType" || state.process.history[index].type == "value" || state.process.history[index].type == "processNumberID" || state.process.history[index].type == "startDate" || state.process.history[index].type == "archived"? true : false
              let typeNewData = state.process.history[index].type == "processType" || state.process.history[index].type == "value" || state.process.history[index].type == "processNumberID" || state.process.history[index].type == "startDate" || state.process.history[index].type == "archived" || (state.process.history[index].type == "attachment" && state.process.history[index].operation == "insert")? true : false
             
              if (typeTimeline[item].type === state.process.history[index].type) {
                itemTimeline.push({
                  id: state.process.history[index].id,
                  css: itemTimeline.length % 2 == 0? 'true':'false',    
                  type: state.process.history[index].type,
                  username: state.process.history[index].username,
                  created: state.process.history[index].created, //mantendo a data mesmo para organizar tanto pela data quanto as horas
                  modified: state.process.history[index].modified, //mantendo a data mesmo para organizar tanto pela data quanto as horas
                  date: state.process.history[index].date, 
                  operation: operationName, //variações - delete, update, insert
                  classOperation: classOperation,
                  oldData: typeOldData ? state.process.history[index].oldData[typeTimeline[item].type] : state.process.history[index].oldData,
                  newData: typeNewData ? state.process.history[index].newData[typeTimeline[item].type] : state.process.history[index].newData,
                  // oldData:state.process.history[index].oldData,
                  // newData:state.process.history[index].newData,
                  nameProcess: typeTimeline[item].nameProcess,
                  nameProcess2: typeTimeline[item].nameProcess2,
                  // link: typeTimeline[item].link,
                  path: typeTimeline[item].path,
                  img: typeTimeline[item].img,
                  });
              }
            }     
          }
        } 
        state.timeline = itemTimeline.sort((a, b) => {
          // console.log(a.created)
          let x = a.created.toUpperCase(),
            y = b.created.toUpperCase();
          return x == y ? 0 : x < y ? 1 : -1;
        });     
    
    },         
    UPDATE_MODAL_PROCESS(state, payload) {
      state.showModalProcess = payload;
    },
    UPDATE_MODAL_DELETE_PROCESS(state, payload) {
      state.showModalDelProcess = payload;
    },
    UPDATE_DELETE_PROCESS(state, processesId) {
      let processes = state.processes.filter(c => c.id != processesId)
      state.processes = processes;
    },    
    UPDATE_DELETE_FILEPROCESS(state, archiveID) {
      let processFile = state.process.files.filter(c => c.id != archiveID)
      state.process.files = processFile;
    },
    // FILTERED_PROCESS(state, {oppositeSearch, numberSearch, valueSearch,courtSearch, arSearch, typeProcess, dateProcess}) {
    //   console.log('foi')
    //   state.filtered = [];      

    //   // let dateProcess = state.dateProcess
      
    //   if ((oppositeSearch == null || oppositeSearch == "") && (dateProcess == null)) {
    //     // if (!(oppositeSearch) || oppositeSearch === '{}' ) {
    //     // state.oppositeSearch = null
    //     state.filtered = null
    //   } else {
    //     // state.oppositeSearch = word
    //     let word = state.oppositeSearch != null ? removeAccent(oppositeSearch.trim().toLowerCase()): null
    //     // let date = dateProcess
    //     // console.log(state.processCourts)
    //      state.filtered = state.processes
    //       .filter(process => {
    //         let hasOpposite = false
    //         let hasCourtID = false
    //         let hasPositionID = false
    //         let hasProcessTypeID = false
    //         if(process.oppositeList != null){
    //           process.oppositeList.filter(opposite => {
    //             removeAccent(opposite.name.toString().toLowerCase().trim()).includes(word) ? hasOpposite = true : false
    //             // console.log("opposite", removeAccent(opposite.name.toString().toLowerCase().trim()).includes(word))
    //           });
    //         }
    //         if(process.courtID != null){
    //           state.processCourts.filter(court => {
    //             if(court.id === process.courtID){
    //               removeAccent(court.name.toString().toLowerCase().trim()).includes(word) ? hasCourtID = true : false
    //             }
    //           })
    //         }
    //         if(process.positionID != null){
    //           state.processPositions.filter(position => {
    //             // console.log(position)
    //             if(position.id === process.positionID){
    //               removeAccent(position.name.toString().toLowerCase().trim()).includes(word) ? hasPositionID = true : false
    //             }
    //           })
    //         }
    //         if(process.processTypeID != null){
    //           // console.log(state.processTypes)
    //           state.processTypes.filter(type => {
    //             if(type.id === process.processTypeID){
    //               removeAccent(type.name.toString().toLowerCase().trim()).includes(word) ? hasProcessTypeID = true : false
    //             }
    //           })
    //         }            
    //         // console.log("dateProcess", date)
    //         // console.log("startDate", process.startDate)
    //         // return (process.startDate != null && process.startDate >= dateProcess) ||
    //         // (process.value != null && process.value.toString().trim()).includes(word) ||
    //         //   (process.processNumber != null && removeAccent(process.processNumber.toLowerCase().trim()).includes(word)) || (process.oppositeList != null && hasOpposite) || (process.courtID != null && hasCourtID) || (process.processTypeID != null && hasProcessTypeID) || (process.positionID != null && hasPositionID)


    //         // return (process.startDate != null && process.startDate > dateProcess) ||
    //         // ((process.value != null && process.value.toString().trim()).includes(word) ||
    //         //   (process.processNumber != null && removeAccent(process.processNumber.toLowerCase().trim()).includes(word)) || (process.oppositeList != null && hasOpposite) || (process.courtID != null && hasCourtID) || (process.processTypeID != null && hasProcessTypeID) || (process.positionID != null && hasPositionID))
           
    //         return (process.startDate != null && process.startDate > dateProcess) &&
    //         ((process.value != null && process.value.toString().trim()).includes(word) ||
    //           (process.processNumber != null && removeAccent(process.processNumber.toLowerCase().trim()).includes(word)) || (process.oppositeList != null && hasOpposite) || (process.courtID != null && hasCourtID) || (process.processTypeID != null && hasProcessTypeID) || (process.positionID != null && hasPositionID))
            
    //       })
    //   }
    // },

    FILTERED_PROCESS(state, {oppositeSearch, numberSearch, valueSearch,courtSearch, arSearch, typeProcessSearch, dateProcess}) {      
      state.filtered = []; 
      let startDate = null,
          processStartDate = null   

      var searchProcess = [
        {
          type: "oppositeSearch",
          item: oppositeSearch,
          code: "(process.oppositeList != null && hasOpposite)",
          code2: "&&"
        },
        {
          type: "numberSearch",
          item: numberSearch,
          code: "(process.processNumber != null && hasNumberSearch)"
        },
        {
          type: "valueSearch",
          item: valueSearch,
          code: "(process.value != null && hasValueSearch)"
        },
        {
          type: "courtSearch",
          item: courtSearch,
          code: "process.courtID != null && hasCourtID"
        },
        {
          type: "arSearch",
          item: arSearch,
          code: "process.positionID != null && hasPositionID  "
        },
        {
          type: "typeProcessSearch",
          item: typeProcessSearch,
          code: "process.processTypeID != null && hasProcessTypeID"
        },
        {
          type: "dateProcess",
          item: dateProcess,
          code: "process.startDate != null && hasDate"
          // code: "process.startDate != null && processStartDate > startDate"
        }
      ];
      
      if ((oppositeSearch == null || oppositeSearch == "") && 
      (numberSearch == null || numberSearch == "") && 
      (valueSearch == null || valueSearch == "") &&
      (courtSearch == null || courtSearch == "") &&
      (arSearch == null || arSearch == "") &&
      (typeProcessSearch == null || typeProcessSearch == "") &&
      (dateProcess == null)) {        
        // if (!(oppositeSearch) || oppositeSearch === '{}' ) {
        // state.oppositeSearch = null
        state.filtered = null
      } else {
        // context.commit("UPDATE_BTNFILTER", true, { root: true });
        // state.oppositeSearch = word
        let wordOpposite = state.oppositeSearch != null ? removeAccent(oppositeSearch.trim().toLowerCase()): null,
            wordNumber = state.numberSearch != null ? removeAccent(numberSearch.trim().toLowerCase()): null,
            wordValue = state.valueSearch != null ? valueSearch : null,
            // wordValue = state.valueSearch != null ? removeAccent(valueSearch.trim().toLowerCase()): null,
            wordCourt = state.courtSearch != null ? removeAccent(courtSearch.trim().toLowerCase()): null,
            wordAR = state.arSearch != null ? removeAccent(arSearch.trim().toLowerCase()): null,
            wordTypeProcessSearch = state.typeProcessSearch != null ? removeAccent(typeProcessSearch.trim().toLowerCase()): null
            startDate = new Date(dateProcess),
            processStartDate = null

        // let date = dateProcess
        
        // console.log(state.processCourts)
         state.filtered = state.processes
          .filter(process => {
            let hasOpposite = false;
            let hasCourtID = false;
            let hasPositionID = false;
            let hasNumberSearch = false;
            let hasValueSearch = false;
            let hasProcessTypeID = false;
            let hasDate = false

            if(process.startDate != null){
              processStartDate = new Date(process.startDate);
              hasDate = processStartDate >= startDate  
              // console.log(processStartDate, "processStartDate")
              // console.log(startDate, "startDate")            
              // console.log(hasDate, "hasDate")            
            }
            if(process.oppositeList != null){
              process.oppositeList.filter(opposite => {
                hasOpposite = removeAccent(opposite.name.toString().toLowerCase().trim()).includes(wordOpposite)
                // console.log("opposite", removeAccent(opposite.name.toString().toLowerCase().trim()).includes(word))
              });
            }
            if(process.processNumber != null){              
              hasNumberSearch = removeAccent(process.processNumber.toLowerCase().trim()).includes(wordNumber)
            }
            if(process.value != null){        
              // process.value.includes(wordValue) ? hasValueSearch = true : false 
              hasValueSearch = process.value.toString().trim().includes(wordValue) 
            }
            if(process.courtID != null && state.processCourts != null){          
              // console.log("state.processCourts", state.processCourts)  
              state.processCourts.filter(court => {
                // console.log("court", court.id)
                if(court.id === process.courtID){
                  hasCourtID = removeAccent(court.name.toString().toLowerCase().trim()).includes(wordCourt)
                }
              })
            }
            if(process.positionID != null && state.processPositions != null){              
              // console.log("wordAR", wordAR)  
              state.processPositions.filter(position => {
                // console.log("position", position.id)
                if(position.id === process.positionID){
                  hasPositionID = removeAccent(position.name.toString().toLowerCase().trim()).includes(wordAR)
                }
              })
            }
            if(process.processTypeID != null && state.processTypes != null){
              // console.log("wordTypeProcessSearch", wordTypeProcessSearch)              
              state.processTypes.filter(type => {
                // console.log("type", type.id)
                if(type.id === process.processTypeID){
                  // removeAccent(type.name.toString().toLowerCase().trim()).includes(wordTypeProcessSearch) ? hasProcessTypeID = true : false
                  hasProcessTypeID = removeAccent(type.name.toString().toLowerCase().trim()).includes(wordTypeProcessSearch)
                }
              })
            }            

            let itemsProcess = null
            for (let index in searchProcess) {
              // console.log(searchProcess[index])
              if (searchProcess[index].item != null) {
                if (itemsProcess == null) {                  
                  itemsProcess = eval(searchProcess[index].code)
                  // console.log("itemsProcess", itemsProcess)
                }
                else {
                  itemsProcess = itemsProcess + '' + `&&` + '' + eval(searchProcess[index].code)
                }
              }
            }
            // console.log(eval(itemsProcess))
            return eval(itemsProcess) 
            // (process.oppositeList != null && hasOpposite) && (process.value != null && hasValueSearch)
                  
          })
      }
    },

    
    //################################################################# 
    //       PROCESS_NOTES
    //#################################################################
    UPDATE_PROCESS_NOTES(state, payload) {
      // state.processNotes = payload
      if (payload != null && payload != "")
      state.processNotes = payload.sort((a, b) => {
        let x = a.date.toUpperCase(),
        y = b.date.toUpperCase();
        return x == y ? 0 : x > y ? 1 : -1;
      });
      else
      state.processNotes = payload
    },
    
    UPDATE_PROCESS_NOTE(state, payload) {
      let note;
      if (payload != undefined && payload.title != null && payload.rememberDate != null) {
        note = {
          "title": payload.title,
          "notes": payload.notes,
          "date": payload.date,
          "rememberDate": formatDate(new Date(payload.rememberDate))
        }    
        state.processNote = Object.assign(state.processNote, note);
      } else {
        state.processNote = Object.assign(state.processNote, payload);
      }
      // state.processNote = Object.assign(state.processNote, payload);
    }, 

    UPDATE_PROCESS_NOTE_REMEMBERDATE(state, payload) {      
      // console.log(payload)
      // state.btnRememberDate = payload;
      state.btnRememberDate = Object.assign(state.btnRememberDate, payload);
    }, 
    
    // UPDATE_PROCESS_NOTEITEM(state, payload) {
    //   console.log(payload)
    //   state.noteItem = Object.assign(state.processNote, payload);
    // },         
    UPDATE_MODAL_PROCESS_NOTE(state, payload) {
      state.showModalProcessNote = payload;
    },        
    UPDATE_MODAL_DELETE_PROCESS_NOTE(state, payload) {
      state.showModalDelProcessNote = payload;
    },
    UPDATE_DELETE_PROCESS_NOTE(state, processNotesId) {
      let processNotes = state.processNotes.filter(c => c.id != processNotesId)
      state.processNotes = processNotes;
    },    
    FILTERED_PROCESS_NOTE(state, word) {
      state.filtered = [];

      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())

        state.filtered = state.processNotes
          .filter(processNote => {
            return (processNote.title != null && removeAccent(processNote.title.toLowerCase().trim()).includes(word)) ||
              (processNote.notes != null && removeAccent(processNote.notes.toString().toLowerCase().trim()).includes(word)) ||
              (processNote.date != null && removeAccent(processNote.date.toLowerCase().trim()).includes(word)) ||
              (processNote.rememberDate != null && removeAccent(processNote.rememberDate.toLowerCase().trim()).includes(word))
          })
      }
    },
    //################################################################# 
    //       PROCESS_TYPES
    //#################################################################
    UPDATE_PROCESS_TYPES(state, payload) {
      // state.processTypes = payload
      if (payload != null && payload != "")
        state.processTypes = payload.sort((a, b) => {
          let x = a.name.toUpperCase(),
            y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.processTypes = payload
    },
    UPDATE_PROCESS_TYPE(state, payload) {
      state.processType = Object.assign(state.processType, payload);
    },         
    UPDATE_MODAL_PROCESS_TYPE(state, payload) {
      state.showModalProcessType = payload;
    },        
    UPDATE_MODAL_DELETE_PROCESS_TYPE(state, payload) {
      state.showModalDelProcessType = payload;
    },
    UPDATE_DELETE_PROCESS_TYPE(state, processTypesId) {
      let processTypes = state.processTypes.filter(c => c.id != processTypesId)
      state.processTypes = processTypes;
    },    
    FILTERED_PROCESS_TYPE(state, word) {
      state.filtered = [];

      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())

        state.filtered = state.processTypes
          .filter(processType => {
            return (processType.name != null && removeAccent(processType.name.toLowerCase().trim()).includes(word)) ||
              (processType.description != null && removeAccent(processType.description.toString().toLowerCase().trim()).includes(word))
          })
      }
    },
    //################################################################# 
    //       PROCESS_COURTS
    //#################################################################
    UPDATE_PROCESS_COURTS(state, payload) {
      // state.processCourts = payload
      if (payload != null && payload != "")
        state.processCourts = payload.sort((a, b) => {
          let x = a.name.toUpperCase(),
            y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.processCourts = payload
    },
    UPDATE_PROCESS_COURT(state, payload) {
      state.processCourt = Object.assign(state.processCourt, payload);
    },         
    UPDATE_MODAL_PROCESS_COURT(state, payload) {
      state.showModalProcessCourt = payload;
    },        
    UPDATE_MODAL_DELETE_PROCESS_COURT(state, payload) {
      state.showModalDelProcessCourt = payload;
    },
    UPDATE_DELETE_PROCESS_COURT(state, processCourtsId) {
      let processCourts = state.processCourts.filter(c => c.id != processCourtsId)
      state.processCourts = processCourts;
    },    
    FILTERED_PROCESS_COURT(state, word) {
      state.filtered = [];

      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())

        state.filtered = state.processCourts
          .filter(processCourt => {
            return (processCourt.name != null && removeAccent(processCourt.name.toLowerCase().trim()).includes(word)) 
          })
      }
    },
    //################################################################# 
    //       PROCESS_POSITIONS
    //#################################################################
    UPDATE_PROCESS_POSITIONS(state, payload) {
      // state.processPositions = payload
      if (payload != null && payload != "")
        state.processPositions = payload.sort((a, b) => {
          let x = a.name.toUpperCase(),
            y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.processPositions = payload
    },
    UPDATE_PROCESS_POSITION(state, payload) {
      state.processPosition = Object.assign(state.processPosition, payload);
    },         
    UPDATE_MODAL_PROCESS_POSITION(state, payload) {
      state.showModalProcessPosition = payload;
    },        
    UPDATE_MODAL_DELETE_PROCESS_POSITION(state, payload) {
      state.showModalDelProcessPosition = payload;
    },
    // UPDATE_DELETE_PROCESS_POSITION(state, processPositionsId) {
    //   let processPositions = state.processPositions.filter(c => c.id != processPositionsId)
    //   state.processPositions = processPositions;
    // },   
    UPDATE_DELETE_PROCESS_FILE_POSITION(state, archiveID) {
      let processPositionFile = state.processPosition.files.filter(c => c.id != archiveID)
      state.processPosition.files = processPositionFile;
    },
    FILTERED_PROCESS_POSITION(state, word) {
      state.filtered = [];

      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())

        state.filtered = state.processPositions
          .filter(processPosition => {
            return (processPosition.name != null && removeAccent(processPosition.name.toLowerCase().trim()).includes(word)) 
          })
      }
    },
    //################################################################# 
    //       PROCESS_TAXES
    //#################################################################
    UPDATE_TAX_UPDATE(state,  payload ) {
      state.taxUpdate = payload;
    },
    UPDATE_PROCESS_TAXE(state, payload) {      
      // state.processTaxes = payload
      if (payload != null && payload != "")
        state.processTaxes = payload.sort((a, b) => {
          let x = a.value,
            y = b.value;
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.processTaxes = payload
    },
    UPDATE_PROCESS_TAX(state, payload) {
      state.processTax = Object.assign(state.processTax, payload);
    },   

    UPDATE_MODAL_PROCESS_TAX(state, payload) {
      state.showModalProcessTax = payload;
    },        
    UPDATE_MODAL_DELETE_PROCESS_TAX(state, payload) {
      state.showModalDelProcessTax = payload;
    },
    UPDATE_DELETE_PROCESS_TAXE(state, archiveID) {
      let processTaxFile = state.processTax.files.filter(c => c.id != archiveID)
      state.processTax.files = processTaxFile;
    },
    UPDATE_DELETE_FILEPROCESSTAX(state, archiveID) {
      let taxFile = state.processTax.files.filter(c => c.id != archiveID)
      state.processTax.files = taxFile;
    },
    //################################################################# 
    //       PROCESS_TYPETAXES
    //#################################################################
    UPDATE_PROCESS_TYPETAXES(state, payload) {
      // state.processTaxes = payload
      if (payload != null && payload != "")
        state.processTypeTaxes = payload.sort((a, b) => {
          let x = a.name,
            y = b.name;
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.processTypeTaxes = payload
      // if (payload != null && payload != "")
      //   state.processTaxes = payload.sort((a, b) => {
      //     let x = a.name.toUpperCase(),
      //       y = b.name.toUpperCase();
      //     return x == y ? 0 : x > y ? 1 : -1;
      //   });
      // else
      //   state.processTaxes = payload
    },
    UPDATE_PROCESS_TYPETAX(state, payload) {
      state.processTypeTax = Object.assign(state.processTypeTax, payload);
    },         
    
    
    UPDATE_MODAL_PROCESS_TYPETAX(state, payload) {
      state.showModalProcessTypeTax = payload;
    },        
    UPDATE_MODAL_DELETE_PROCESS_TYPETAX(state, payload) {
      state.showModalDelProcessTypeTax = payload;
    },
    FILTERED_PROCESS_TYPETAX(state, word) {
      state.filtered = [];

      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())

        state.filtered = state.processTypeTaxes
          .filter(processTypeTax => {
            return (processTypeTax.name != null && String(processTypeTax.name).includes(word)) ||
              (processTypeTax.description != null && removeAccent(processTypeTax.description.toString().toLowerCase().trim()).includes(word))
          })
      }
    },    
   
    //################################################################# 
    //       PROCESS_OPPOSITES
    //#################################################################
    UPDATE_PROCESS_OPPOSITES(state, payload) {
      // state.processOpposites = payload
      if (payload != null && payload != "")
        state.processOpposites = payload.sort((a, b) => {
          let x = a.name.toUpperCase(),
            y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.processOpposites = payload
    },
    UPDATE_PROCESS_OPPOSITE(state, payload) {
      state.processOpposite = Object.assign(state.processOpposite, payload);
    },         
    UPDATE_MODAL_PROCESS_OPPOSITE(state, payload) {
      state.showModalProcessOpposite = payload;
    },        
    UPDATE_MODAL_DELETE_PROCESS_OPPOSITE(state, payload) {
      state.showModalDelProcessOpposite = payload;
    },
    UPDATE_DELETE_PROCESS_OPPOSITE(state, processOppositesId) {
      let processOpposites = state.processOpposites.filter(c => c.id != processOppositesId)
      state.processOpposites = processOpposites;
    },    
    UPDATE_DELETE_FILE_PROCESS_OPPOSITE(state, archiveID) {
      let OppositeFile = state.processOpposite.files.filter(c => c.id != archiveID)
      state.processOpposite.files = OppositeFile;
    },
    FILTERED_PROCESS_OPPOSITE(state, word) {
      state.filtered = [];

      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())

        state.filtered = state.processOpposites
          .filter(processOpposite => {
            return (processOpposite.name != null && removeAccent(processOpposite.name.toLowerCase().trim()).includes(word)) ||
              (processOpposite.nif != null && removeAccent(processOpposite.nif.toString().toLowerCase().trim()).includes(word))
          })
      }
    },
    
  },
  actions: {
    //##################################################
    //    PROCESSES - INFORMAÇÕES - ACTIONS
    //##################################################
    getFilterURL(context){
      // console.log("filtro ativo")
      context.commit("FILTERED_PROCESS", {
        oppositeSearch: context.state.oppositeSearch || null,
        numberSearch: context.state.numberSearch || null,
        valueSearch: context.state.valueSearch || null,
        courtSearch: context.state.courtSearch || null,
        arSearch: context.state.arSearch || null,
        typeProcessSearch: context.state.typeProcessSearch || null,
        dateProcess: context.state.dateProcess || null,
      })
      let filterActive = (context.state.oppositeSearch == null || context.state.oppositeSearch == "") && 
      (context.state.numberSearch == null || context.state.numberSearch == "") && 
      (context.state.valueSearch == null || context.state.valueSearch == "") &&
      (context.state.courtSearch == null || context.state.courtSearch == "") &&
      (context.state.arSearch == null || context.state.arSearch == "") &&
      (context.state.typeProcessSearch == null || context.state.typeProcessSearch == "") &&
      (context.state.dateProcess == null) ? false : true;
      context.commit("UPDATE_BTNFILTER", filterActive, {root: true})
      context.commit("UPDATE_LOADING_LEGAL", false);
    },
    getProcesses(context, refresh, associate) {       
      let urlApi = (router.currentRoute.name == "processos" && (router.currentRoute.query.archived === "true" || router.currentRoute.query.archived === undefined))? "/legal/?archived=true" : "/legal/";
      context.commit("UPDATE_OPPOSITE_SEARCH", router.currentRoute.query.oppositeSearch || null);
      context.commit("UPDATE_NUMBER_SEARCH", router.currentRoute.query.numberSearch || null);
      context.commit("UPDATE_VALUE_SEARCH", Number(router.currentRoute.query.valueSearch) || null);
      context.commit("UPDATE_COURT_SEARCH", router.currentRoute.query.courtSearch || null);
      context.commit("UPDATE_AR_SEARCH", router.currentRoute.query.arSearch || null);
      context.commit("UPDATE_TYPEPROCESS_SEARCH", router.currentRoute.query.typeProcessSearch || null);
      context.commit("UPDATE_DATEPROCESS", router.currentRoute.query.dateProcess || null);
      if (!associate) {
        context.commit("UPDATE_LOADING_LEGAL", true);
      }
      if (router.currentRoute.query.archived === "true" || router.currentRoute.name === "processos" || context.state.processes === null || refresh != undefined) {
        // api.get(`/legal/?archived=true`)
        // console.log("teste")
        // context.commit("RESET_FILTER", null);
        api.get(urlApi)
          .then(
            response => {
              const processes = response.data.map(function (process) {
                process.created = formatDate(new Date(process.created));
                process.modified = formatDate(new Date(process.modified));
                process.startDate = formatDate(new Date(process.startDate));
                return process;
              });
              // context.commit(
              //   "UPDATE_CURRENTPAGE",
              //   Number(router.currentRoute.query.page) || 1, { root: true } 
              // );        
             
                // context.commit("UPDATE_LOADING", false);
              // })
             
              // Tipo arquivado, não arquivado ou todos
              if (router.currentRoute.query.archived === "true") {
                let arquived = processes.filter((process) => {
                  return process.archived === true;
                });
                context.commit("UPDATE_PROCESSES", arquived);  
                context.dispatch("getFilterURL"); 
                // context.commit("UPDATE_LOADING", false);
              } else if (router.currentRoute.query.archived === "false") {   
                let arquived = processes.filter((process) => {
                  return process.archived === false;
                });
                context.commit("UPDATE_PROCESSES", arquived);
                context.dispatch("getFilterURL");
                // context.commit("UPDATE_LOADING", false);
              } else {
                context.commit("UPDATE_PROCESSES", processes);
                context.dispatch("getFilterURL");
                // context.commit("UPDATE_LOADING", false);
              }
              // fim Tipo arquivado, não arquivado ou todos
              // context.commit("UPDATE_PROCESSES", processes);
              context.commit("UPDATE_PROCESSES_DASHBOARD", processes);             
              
            })
          .catch(error => {
            context.commit("UPDATE_LOADING_LEGAL", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_PROCESSES", context.state.processes);
        context.commit("UPDATE_LOADING_LEGAL", false);
      }
      
    },
    
    //    PROCESS - INFORMAÇÕES - ACTIONS
    //##################################################

    getProcess(context, { idProcess }) {
      context.commit("UPDATE_LOADING", true);
      api.get(`/legal/${idProcess}`)
        .then(
          response => {
            const process = {}
            Object.keys(response.data).filter(key => {  
              // console.log(response.data.notes)
              // ##### formatando o array do history
              let historyFormat;
              (response.data.history != null && key === "history") && (historyFormat = response.data[key].map(function (timeline) {
                timeline.date = formatDate(new Date(timeline.date))
                  return timeline
               }))
               // ##### formatando o array do note
              // let noteFormat;
              // (response.data.notes != null && key === "notes") && (noteFormat = response.data[key].map(function (note) {
              //   // note.date = formatDate(new Date(note.date));
              //   note.rememberDate = formatDate(new Date(note.rememberDate))
              //     return note
              //  }))
              // ##### retornando todas as datas formatadas
              return ((key === "created") && (process[key] = formatDate(new Date(response.data[key])))) ||
                  ((key === "modified") && (process[key] = formatDate(new Date(response.data[key])))) ||
                  ((key === "startDate") && (process[key] = formatDate(new Date(response.data[key])))) ||
                // (key === "history") && (process[key] = historyFormat) ||
                // (key === "notes") && (process[key] = noteFormat) ||
                (process[key] = response.data[key])
            })

            // console.log("process", process)            
            context.commit("UPDATE_PROCESS", process);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          // console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })

    },
    
    //            CADASTRAR POCESS
    //########################################################################
    addProcess(context, { process }) {
      // console.log(process)
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
        api.post(`/legal/`, process)
          .then(response => {
            // console.log(response.data)
            // const idOpposite = response.data.oppositeID
            const idProcess = response.data.id
            context.dispatch("getProcesses", "refresh");
            // context.dispatch("getProcess", { idProcess });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Processo adicionado com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_PROCESS", context.getters["processReset"]);//limpa o formulário depois de cadastrar        
            context.commit("UPDATE_LOADING", false);
            router.push({ name: "processo", params: { idProcess } })
            // router.push({ name: "interveniente", params: { idOpposite } })
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)          
            if (error.response.data.error === "opposite required." || error.response.data.error === "Opposite doesnt exist.") {
              context.commit("UPDATE_ERRORS", "opposite", { root: true });
            }
            if (error.response.data.error === "taxID required.") {
              context.commit("UPDATE_ERRORS", "taxID", { root: true });
            }
            if (error.response.data.error === "Start Date Required") {
              context.commit("UPDATE_ERRORS", "startDate", { root: true });
            }
            if (error.response.data.error === "Start Date cant be in future") {
              context.commit("UPDATE_ERRORS", "dateInvalid", { root: true });
            }
            if (error.response.data.error === "processTypeID required.") {
              context.commit("UPDATE_ERRORS", "processTypeID", { root: true });
            }
            if (error.response.data.error === "Process number already exist.") {
              context.commit("UPDATE_ERRORS", "processNumber", { root: true });
            }
            if (error.response.data.error === "type required.") {
              context.commit("UPDATE_ERRORS", "processType", { root: true });
            }
            if (error.response.data.error === "Value required.") {
              context.commit("UPDATE_ERRORS", "value", { root: true });
            }            
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },
    //   UPDATE - POCESS - ACTIONS
    //##################################################
    updateProcess(context, { process, idProcess }) {
      // console.log(process)
      context.commit("UPDATE_LOADING", true);  
        api.put(`/legal/${idProcess}`, process)
          .then(() => {
            context.dispatch("getProcesses", "refresh");
            context.dispatch("getProcess", { idProcess, refreshId: "refreshId" });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Processo editado com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_LOADING", false);
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            if (error.response.data.error === "opposite required." || error.response.data.error === "Opposite doesnt exist.") {
              context.commit("UPDATE_ERRORS", "opposite", { root: true });
            }
            if (error.response.data.error === "taxID required.") {
              context.commit("UPDATE_ERRORS", "taxID", { root: true });
            }
            if (error.response.data.error === "Start Date Required") {
              context.commit("UPDATE_ERRORS", "startDate", { root: true });
            }
            if (error.response.data.error === "Start Date cant be in future") {
              context.commit("UPDATE_ERRORS", "dateInvalid", { root: true });
            }
            if (error.response.data.error === "processTypeID required.") {
              context.commit("UPDATE_ERRORS", "processTypeID", { root: true });
            }
            if (error.response.data.error === "Process number already exist.") {
              context.commit("UPDATE_ERRORS", "processNumber", { root: true });
            }
            if (error.response.data.error === "type required.") {
              context.commit("UPDATE_ERRORS", "processType", { root: true });
            }
            if (error.response.data.error === "Value required.") {
              context.commit("UPDATE_ERRORS", "value", { root: true });
            }            
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },    
    
    //       DELETE
    //############################################

    //       MODAL - DELETE 
    openModalDelProcess(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS", true);
    },
    closeModalDelProcess(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS", false);
    },

    //Confirmação abrindo modal Delete - Delete 1 de 2
    confirmDeleteProcess(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DELETE_PROCESS", true);//abre o modal
    },

    //Delete - Delete 2 de 2
    deleteProcess(context, { id, redirect }) {
      // console.log(context)
      console.log("id", id)
      context.commit("UPDATE_LOADING", true, { root: true });
      api.delete(`/legal/${id}`)
        .then(() => {
          // context.commit("UPDATE_DELETE_PROCESS", id); //atualiza 
          // context.dispatch("getProcesses", "refresh");
          // if (redirect != undefined) {
            router.push({ name: "processos", query: { archived: 'false' } })
          // }
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Processo removido com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false, { root: true });
          // console.log("deletou", id)
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false, { root: true });
          console.log(error)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //##################################################
    //    PROCESS_NOTES - INFORMAÇÕES - ACTIONS
    //##################################################    
    getProcessNote(context, { idProcess, idProcessNote }) {
      // console.log("chamou", idProcess, idProcessNote)
      context.commit("UPDATE_LOADING", true);
      api.get(`/legal/${idProcess}/note/${idProcessNote}`)
        .then(
          response => {
            // const processNote = response.data
            const processNote = {}
            Object.keys(response.data).filter(key => {  
              // ##### retornando todas as datas formatadas
              return ((key === "date") && (processNote[key] = formatDate(new Date(response.data[key])))) || 
                  
              
                (processNote[key] = response.data[key])
            })

            // console.log("processNote", processNote.rememberDate)     
            let btnRememberDate = processNote.rememberDate != null ? true : false;
            context.commit("UPDATE_PROCESS_NOTE_REMEMBERDATE", {btnRememberDate});
            context.commit("UPDATE_PROCESS_NOTE", processNote);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          // console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })

    },
    //            CADASTRAR POCESS
    //########################################################################
    addProcessNote(context, {idProcess, processNote }) {
      // console.log(process)
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
        api.post(`/legal/${idProcess}/note`, processNote)
          .then(response => {
            const idProcessNote = response.data.id
            context.dispatch("getProcess", { idProcess, refreshId: "refreshId" });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Nota adicionada com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.dispatch("closeModalNote");
            context.commit("UPDATE_PROCESS_NOTE", context.getters["processNoteReset"]);//limpa o formulário depois de cadastrar        
            context.commit("UPDATE_LOADING", false);
            // router.push({ name: "processo", params: { idProcess } })
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)          
            if (error.response.data.error === "Title required.") {
              context.commit("UPDATE_ERRORS", "title", { root: true });
            }
            if (error.response.data.error === "Date required.") {
              context.commit("UPDATE_ERRORS", "date", { root: true });
            }  
            if (error.response.data.error === "Want to add past date.") {
              context.commit("UPDATE_ERRORS", "rememberDate", { root: true });
            }          
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },
    //   UPDATE - POCESS - ACTIONS
    //##################################################
    updateProcessNote(context, { processNote, idProcess, idProcessNote }) {
      context.commit("UPDATE_LOADING", true);  
        api.put(`/legal/${idProcess}/note/${idProcessNote}`, processNote)
          .then(() => {
            context.dispatch("getProcess", { idProcess, refreshId: "refreshId" });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Nota editada com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_LOADING", false);
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            if (error.response.data.error === "Title required.") {
              context.commit("UPDATE_ERRORS", "title", { root: true });
            }
            if (error.response.data.error === "Date required.") {
              context.commit("UPDATE_ERRORS", "date", { root: true });
            }  
            if (error.response.data.error === "Want to add past date.") {
              context.commit("UPDATE_ERRORS", "rememberDate", { root: true });
            }          
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },    
    
    //       DELETE
    //############################################

    //       MODAL - DELETE 
    openModalDelProcessNote(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS_NOTE", true);
    },
    closeModalDelProcessNote(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS_NOTE", false);
    },

    //Confirmação abrindo modal Delete - Delete 1 de 2
    confirmDeleteProcessNote(context, id) {
      // console.log("confirme")
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DELETE_PROCESS_NOTE", true);//abre o modal
    },

    //Delete - Delete 2 de 2
    deleteProcessNote(context, { id,idProcess}) {
      // console.log(context)
      // console.log("id", id)
      context.commit("UPDATE_LOADING", true, { root: true });
      api.delete(`/legal/${idProcess}/note/${id}`)
        .then(() => {
          // context.commit("UPDATE_DELETE_CLIENT", id); //atualiza 
          context.dispatch("getProcesses", "refresh");
          // if (redirect != undefined) {
            //   router.push({ name: "processos" })
            // }
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Nota removida com sucesso!",
            }, { root: true });
            // context.commit("UPDATE_LOADING", false, { root: true });
            // console.log("deletou", id)
            context.dispatch("getProcess", { idProcess });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    // MODAL NOTE
    openModalNote(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_PROCESS_NOTE", true);
      context.commit("UPDATE_PROCESS_NOTE_REMEMBERDATE", {"btnRememberDate":false});
    },
    closeModalNote(context) {
      context.commit("UPDATE_MODAL_PROCESS_NOTE", false);
    },


    //##################################################
    //    PROCESS_TYPES - INFORMAÇÕES - ACTIONS
    //##################################################
    getProcessTypes(context, refresh, associate) {
      // context.commit("UPDATE_LOADING", true);
      if (!associate) {
        context.commit("UPDATE_LOADING", true);
      }
      if (context.state.processTypes === null || refresh != undefined) {
        api.get(`/legal/type/all`)
          .then(
            response => {
              const processTypes = response.data.map(function (processType) {
                processType.created = formatDate(new Date(processType.created));
                processType.modified = formatDate(new Date(processType.modified));
                return processType;
              });
              // console.log(process)
              context.commit("UPDATE_PROCESS_TYPES", processTypes);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_PROCESS_TYPES", context.state.processTypes);
        context.commit("UPDATE_LOADING", false);
      }
    },
    
    //    PROCESS_TYPES - INFORMAÇÕES - ACTIONS
    //##################################################

    getProcessType(context, { idProcessType }) {
      // console.log(idProcessType)
      context.commit("UPDATE_LOADING", true);
      api.get(`/legal/type/${idProcessType}`)
        .then(
          response => {
            const processType = {}
            Object.keys(response.data).filter(key => {
              // ##### retornando todas as datas formatadas
              return ((key === "created") && (processType[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (processType[key] = formatDate(new Date(response.data[key])))) ||
                (processType[key] = response.data[key])
            })
            // console.log(process)
            context.commit("UPDATE_PROCESS_TYPE", processType);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    
    //            CADASTRAR PROCESS_TYPES
    //########################################################################
    addProcessType(context, { processType }) {
      // console.log(processType)
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
        api.post(`/legal/type`, processType)
          .then(response => {
            const idProcessType = response.data.id
            context.dispatch("getProcessTypes", "refresh");
            context.dispatch("getProcessType", { idProcessType });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Tipo de Processo adicionado com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_PROCESS_TYPE", context.getters["processTypeReset"]);//limpa o formulário depois de cadastrar        
            context.commit("UPDATE_LOADING", false);
            router.push({ name: "tipo-de-processo", params: { idProcessType } })
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)          
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "description required.") {
              context.commit("UPDATE_ERRORS", "description", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },
    //   UPDATE - PROCESS_TYPES - ACTIONS
    //##################################################
    updateProcessType(context, { processType, idProcessType }) {
      context.commit("UPDATE_LOADING", true);  
        api.put(`/legal/type/${idProcessType}`, processType)
          .then(() => {
            context.dispatch("getProcessTypes", "refresh");
            context.dispatch("getProcessType", { idProcessType, refreshId: "refreshId" });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Tipo de Processo editado com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_LOADING", false);
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "description required.") {
              context.commit("UPDATE_ERRORS", "description", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },    
    
    //       DELETE PROCESS_TYPES
    //############################################

    //       MODAL - DELETE 
    openModalDelProcessType(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS_TYPE", true);
    },
    closeModalDelProcessType(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS_TYPE", false);
    },

    //Confirmação abrindo modal Delete - Delete 1 de 2
    confirmDeleteProcessType(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DELETE_PROCESS_TYPE", true);//abre o modal
    },

    //Delete - Delete 2 de 2
    deleteProcessType(context, { id, redirect }) {
      // console.log(context)
      // console.log("id", id)
      context.commit("UPDATE_LOADING", true, { root: true });
      api.delete(`/legal/type/${id}`)
        .then(() => {
          // context.commit("UPDATE_DELETE_CLIENT", id); //atualiza 
          context.dispatch("getProcesses", "refresh");
          context.dispatch("getProcessTypes", "refresh");
          // if (redirect != undefined) {
          //   router.push({ name: "processos" })
          // }
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Tipo de Processo removido com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false, { root: true });
          // console.log("deletou", id)
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //##################################################
    //    PROCESS_COURT - INFORMAÇÕES - ACTIONS
    //##################################################
    getProcessCourts(context, refresh, associate) {
      // context.commit("UPDATE_LOADING", true);
      if (!associate) {
        context.commit("UPDATE_LOADING", true);
      }
      if (context.state.processCourts === null || refresh != undefined) {
        api.get(`/legal/court/all`)
          .then(
            response => {
              const processCourts = response.data.map(function (processCourt) {
                processCourt.created = formatDate(new Date(processCourt.created));
                processCourt.modified = formatDate(new Date(processCourt.modified));
                return processCourt;
              });
              // console.log(process)
              context.commit("UPDATE_PROCESS_COURTS", processCourts);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_PROCESS_COURTS", context.state.processCourts);
        context.commit("UPDATE_LOADING", false);
      }
    },
    
    //    PROCESS_COURT - INFORMAÇÕES - ACTIONS
    //##################################################

    getProcessCourt(context, { idProcessCourt }) {
      context.commit("UPDATE_LOADING", true);
      api.get(`/legal/court/${idProcessCourt}`)
        .then(
          response => {
            const processCourt = {}
            Object.keys(response.data).filter(key => {
              // ##### retornando todas as datas formatadas
              return ((key === "created") && (processCourt[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (processCourt[key] = formatDate(new Date(response.data[key])))) ||
                (processCourt[key] = response.data[key])
            })
            // console.log(process)
            context.commit("UPDATE_PROCESS_COURT", processCourt);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    
    //            CADASTRAR PROCESS_COURT
    //########################################################################
    addProcessCourt(context, { processCourt }) {
      // console.log(process)
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
        api.post(`/legal/court`, processCourt)
          .then(response => {
            const idProcessCourt = response.data.id
            context.dispatch("getProcessCourts", "refresh");
            context.dispatch("getProcessCourt", { idProcessCourt });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Tipo de Processo adicionado com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_PROCESS_COURT", context.getters["processCourtReset"]);//limpa o formulário depois de cadastrar        
            context.commit("UPDATE_LOADING", false);
            router.push({ name: "tribunal", params: { idProcessCourt } })
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)          
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },
    //   UPDATE - PROCESS_COURT - ACTIONS
    //##################################################
    updateProcessCourt(context, { processCourt, idProcessCourt }) {
      context.commit("UPDATE_LOADING", true);  
        api.put(`/legal/court/${idProcessCourt}`, processCourt)
          .then(() => {
            context.dispatch("getProcessCourts", "refresh");
            context.dispatch("getProcessCourt", { idProcessCourt, refreshId: "refreshId" });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Tribunal editado com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_LOADING", false);
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },    
    
    //       DELETE PROCESS_COURT
    //############################################

    //       MODAL - DELETE 
    openModalDelProcessCourt(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS_COURT", true);
    },
    closeModalDelProcessCourt(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS_COURT", false);
    },

    //Confirmação abrindo modal Delete - Delete 1 de 2
    confirmDeleteProcessCourt(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DELETE_PROCESS_COURT", true);//abre o modal
    },

    //Delete - Delete 2 de 2
    deleteProcessCourt(context, { id, redirect }) {
      // console.log(context)
      // console.log("id", id)
      context.commit("UPDATE_LOADING", true, { root: true });
      api.delete(`/legal/court/${id}`)
        .then(() => {
          // context.commit("UPDATE_DELETE_CLIENT", id); //atualiza 
          context.dispatch("getProcesses", "refresh");
          context.dispatch("getProcessCourts", "refresh");
          // if (redirect != undefined) {
          //   router.push({ name: "processos" })
          // }
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Tribunal removido com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false, { root: true });
          // console.log("deletou", id)
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //##################################################
    //    PROCESS_POSITIONS - INFORMAÇÕES - ACTIONS
    //##################################################
    getProcessPositions(context, refresh, associate) {
      // context.commit("UPDATE_LOADING", true);
      if (!associate) {
        context.commit("UPDATE_LOADING", true);
      }
      if (context.state.processPositions === null || refresh != undefined) {
        api.get(`/legal/position/all`)
          .then(
            response => {
              const processPositions = response.data.map(function (processPosition) {
                processPosition.created = formatDate(new Date(processPosition.created));
                processPosition.modified = formatDate(new Date(processPosition.modified));
                return processPosition;
              });
              // console.log(process)
              context.commit("UPDATE_PROCESS_POSITIONS", processPositions);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_PROCESS_POSITIONS", context.state.processPositions);
        context.commit("UPDATE_LOADING", false);
      }
    },
    
    //    PROCESS_POSITIONS - INFORMAÇÕES - ACTIONS
    //##################################################

    getProcessPosition(context, { idProcessPosition }) {
      context.commit("UPDATE_LOADING", true);
      api.get(`/legal/position/${idProcessPosition}`)
        .then(
          response => {
            const processPosition = {}
            Object.keys(response.data).filter(key => {
              // ##### retornando todas as datas formatadas
              return ((key === "created") && (processPosition[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (processPosition[key] = formatDate(new Date(response.data[key])))) ||
                (processPosition[key] = response.data[key])
            })
            // console.log(process)
            context.commit("UPDATE_PROCESS_POSITION", processPosition);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    
    //            CADASTRAR PROCESS_POSITIONS
    //########################################################################
    addProcessPosition(context, { processPosition }) {
      console.log(processPosition)
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
        api.post(`/legal/position`, processPosition)
          .then(response => {
            const idProcessPosition = response.data.id
            context.dispatch("getProcessPositions", "refresh");
            context.dispatch("getProcessPosition", { idProcessPosition });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Posição adicionada com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_PROCESS_POSITION", context.getters["processPositionReset"]);//limpa o formulário depois de cadastrar        
            context.commit("UPDATE_LOADING", false);
            router.push({ name: "posicao", params: { idProcessPosition } })
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)          
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "name", { root: true });
            }
            if (error.response.data.error === "Position name already exist") {
              context.commit("UPDATE_ERRORS", "positionExist", { root: true });
            }            
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },
    //   UPDATE - PROCESS_POSITIONS - ACTIONS
    //##################################################
    updateProcessPosition(context, { processPosition, idProcessPosition }) {
      context.commit("UPDATE_LOADING", true);  
        api.put(`/legal/position/${idProcessPosition}`, processPosition)
          .then(() => {
            context.dispatch("getProcessPositions", "refresh");
            context.dispatch("getProcessPosition", { idProcessPosition, refreshId: "refreshId" });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Posição editada com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_LOADING", false);
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "name", { root: true });
            }
            if (error.response.data.error === "Position name already exist") {
              context.commit("UPDATE_ERRORS", "positionExist", { root: true });
            }  
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },    
    
    //       DELETE PROCESS_POSITIONS
    //############################################

    //       MODAL - DELETE 
    openModalDelProcessPosition(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS_POSITION", true);
    },
    closeModalDelProcessPosition(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS_POSITION", false);
    },

    //Confirmação abrindo modal Delete - Delete 1 de 2
    confirmDeleteProcessPosition(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DELETE_PROCESS_POSITION", true);//abre o modal
    },

    //Delete - Delete 2 de 2
    deleteProcessPosition(context, { id, redirect }) {
      context.commit("UPDATE_LOADING", true, { root: true });
      api.delete(`/legal/position/${id}`)
        .then(() => {
          context.dispatch("getProcesses", "refresh");
          context.dispatch("getProcessPositions", "refresh");
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Posição removida com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //##################################################
    //    PROCESS_TYPETAXES - INFORMAÇÕES - ACTIONS
    //##################################################
    getProcessTypeTaxes(context, refresh, associate) {
      // context.commit("UPDATE_LOADING", true);
      if (!associate) {
        context.commit("UPDATE_LOADING", true);
      }
      if (context.state.processTypeTaxes === null || refresh != undefined) {
        api.get(`/legal/taxtype/all`)
          .then(
            response => {
              const processTypeTaxes = response.data.map(function (processTypeTax) {
                processTypeTax.created = formatDate(new Date(processTypeTax.created));
                processTypeTax.modified = formatDate(new Date(processTypeTax.modified));
                return processTypeTax;
              });
              // console.log(process)
              context.commit("UPDATE_PROCESS_TYPETAXES", processTypeTaxes);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_PROCESS_TYPETAXES", context.state.processTypeTaxes);
        context.commit("UPDATE_LOADING", false);
      }
    },
    
    //    PROCESS_TYPETAXES - INFORMAÇÕES - ACTIONS
    //##################################################

    getProcessTypeTax(context, { idProcessTypeTax }) {
      context.commit("UPDATE_LOADING", true);
      api.get(`/legal/taxtype/${idProcessTypeTax}`)
        .then(
          response => {
            const processTypeTax = {}
            Object.keys(response.data).filter(key => {
              // ##### retornando todas as datas formatadas
              return ((key === "created") && (processTypeTax[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (processTypeTax[key] = formatDate(new Date(response.data[key])))) ||
                (processTypeTax[key] = response.data[key])
            })
            // console.log(process)
            context.commit("UPDATE_PROCESS_TYPETAX", processTypeTax);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    
    //            CADASTRAR PROCESS_TYPETAXES
    //########################################################################
    addProcessTypeTax(context, { processTypeTax, idModule, mutation, associate }) {
      // console.log(process)
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
        api.post(`/legal/taxtype`, processTypeTax)
          .then(response => {
            const idProcessTypeTax = response.data.id
            if (mutation != null && mutation != "") {
              context.dispatch(eval(mutation), { idModule, idProcessTypeTax }, { root: true })//eval() pegar o nome da constante e não o valor da sua variável.
            } else if (associate != null || associate != ''){
              context.dispatch("getProcessTypeTaxes", "refresh");
              context.dispatch("success", {        //cria as informações do texto do toast
                id: Date.now(),
                h1: "Successo!",
                body: "Tipo de Taxa adicionada com sucesso!",
              }, { root: true });
              context.commit("RESET_ERRORS", [], { root: true });
              context.commit("UPDATE_PROCESS_TYPETAX", context.getters["processTypeTaxReset"]);//limpa o formulário depois de cadastrar        
              context.commit("UPDATE_LOADING", false);
            }
            else {              
              router.push({ name: "tipo-de-taxa", params: { idProcessTypeTax } })
            
            context.dispatch("getProcessTypeTaxes", "refresh");
            context.dispatch("getProcessTypeTax", { idProcessTypeTax });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Tipo de Taxa adicionada com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_PROCESS_TYPETAX", context.getters["processTypeTaxReset"]);//limpa o formulário depois de cadastrar        
            context.commit("UPDATE_LOADING", false);}
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)          
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "name", { root: true });
            }
            if (error.response.data.error === "description required.") {
              context.commit("UPDATE_ERRORS", "description", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },
    //   UPDATE - PROCESS_TYPETAXES - ACTIONS
    //##################################################
    updateProcessTypeTax(context, { processTypeTax, idProcessTypeTax }) {
      context.commit("UPDATE_LOADING", true);  
        api.put(`/legal/taxtype/${idProcessTypeTax}`, processTypeTax)
          .then(() => {
            context.dispatch("getProcessTypeTaxes", "refresh");
            context.dispatch("getProcessTypeTax", { idProcessTypeTax, refreshId: "refreshId" });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Tipo de Taxa editada com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_LOADING", false);
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "name", { root: true });
            }
            if (error.response.data.error === "description required.") {
              context.commit("UPDATE_ERRORS", "description", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },   

     //       DELETE PROCESS_TYPETAXES
    //############################################
    //       MODAL - DELETE 
    openModalProcessTypeTax(context) {
      context.commit("UPDATE_MODAL_PROCESS_TYPETAX", true);
    },
    closeModalProcessTypeTax(context) {
      context.commit("UPDATE_MODAL_PROCESS_TYPETAX", false);
    },
    
    //       DELETE PROCESS_TYPETAXES
    //############################################

    //       MODAL - DELETE 
    openModalDelProcessTypeTax(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS_TYPETAX", true);
    },
    closeModalDelProcessTypeTax(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS_TYPETAX", false);
    },

    //Confirmação abrindo modal Delete - Delete 1 de 2
    confirmDeleteProcessTypeTax(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DELETE_PROCESS_TYPETAX", true);//abre o modal
    },

    //Delete - Delete 2 de 2
    deleteProcessTypeTax(context, { id, redirect }) {
      // console.log(context)
      // console.log("id", id)
      context.commit("UPDATE_LOADING", true, { root: true });
      api.delete(`/legal/taxtype/${id}`)
        .then(() => {
          // context.commit("UPDATE_DELETE_CLIENT", id); //atualiza 
          context.dispatch("getProcesses", "refresh");
          context.dispatch("getProcessTypeTaxes", "refresh");
          // if (redirect != undefined) {
          //   router.push({ name: "processos" })
          // }
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Tipo de Taxa removida com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false, { root: true });
          // console.log("deletou", id)
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //##################################################
    //    PROCESS_OPPOSITES - INFORMAÇÕES - ACTIONS
    //##################################################
    getProcessOpposites(context, refresh, associate) {      
      context.commit("UPDATE_OPPOSITEHISTORY", null);
      context.commit("UPDATE_LOADING", true);
      if (!associate) {
        context.commit("UPDATE_LOADING", true);
      }
      if (context.state.processOpposites === null || refresh != undefined) {
        api.get(`/legal/opposite/all`)
          .then(
            response => {
              const processOpposites = response.data.map(function (processOpposite) {
                processOpposite.created = formatDate(new Date(processOpposite.created));
                processOpposite.modified = formatDate(new Date(processOpposite.modified));
                return processOpposite;
              });
              // console.log(process)
              context.commit("UPDATE_PROCESS_OPPOSITES", processOpposites);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_PROCESS_OPPOSITES", context.state.processOpposites);
        context.commit("UPDATE_LOADING", false);
      }
    },
    
    //    PROCESS_OPPOSITES - INFORMAÇÕES - ACTIONS
    //##################################################

    getProcessOpposite(context, { idOpposite }) {
      // console.log("atualizando")
      context.commit("UPDATE_LOADING", true);
      api.get(`/legal/opposite/${idOpposite}`)
        .then(
          response => {
            const processOpposite = {}
            Object.keys(response.data).filter(key => {
              // ##### retornando todas as datas formatadas
              return ((key === "created") && (processOpposite[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (processOpposite[key] = formatDate(new Date(response.data[key])))) ||
                (processOpposite[key] = response.data[key])
            })
            // console.log(process)
            context.commit("UPDATE_PROCESS_OPPOSITE", processOpposite);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    
    //            CADASTRAR PROCESS_OPPOSITES
    //########################################################################
    addProcessOpposite(context, { processOpposite, idModule }) {
      // console.log(process)
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
        api.post(`/legal/opposite`, processOpposite)
          .then(response => {
            const idOpposite = response.data.id
            context.dispatch("getProcessOpposites", "refresh");
            context.dispatch("getProcessOpposite", { idOpposite });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Interveniente adicionado com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_PROCESS_OPPOSITE", context.getters["processOppositeReset"]);//limpa o formulário depois de cadastrar        
            context.commit("UPDATE_LOADING", false);            
            if(idModule){
              context.dispatch("updateAssocieateOpposite", {idModule, idOpposite});
            }else{
              router.push({ name: 'interveniente',
              params: { idOpposite: idOpposite } })
            }
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)          
            if (error.response.data.error === "NIF already exist") {
              context.commit("UPDATE_ERRORS", "nifExist", { root: true });
            }
            if (error.response.data.error === "invalid nif, nif must have 9 digits.") {
              context.commit("UPDATE_ERRORS", "nif", { root: true });
            }
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "Opposite with this name is already registered.") {
              context.commit("UPDATE_ERRORS", "nameExist", { root: true });
            }            
            if (error.response.data.error === "type required.") {
              context.commit("UPDATE_ERRORS", "type", { root: true });
            }
            if (error.response.data.error === "nif required.") {
              context.commit("UPDATE_ERRORS", "nifRequired", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },
    //   UPDATE - PROCESS_OPPOSITES - ACTIONS
    //##################################################
    updateProcessOpposite(context, { processOpposite, idOpposite }) {
      // console.log(processOpposite)
      context.commit("UPDATE_LOADING", true);  
        api.put(`/legal/opposite/${idOpposite}`, processOpposite)
          .then(() => {
            context.dispatch("getProcessOpposites", "refresh");
            context.dispatch("getProcessOpposite", { idOpposite, refreshId: "refreshId" });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Interveniente editado com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_LOADING", false);
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            if (error.response.data.error === "NIF already exist") {
              context.commit("UPDATE_ERRORS", "nifExisty", { root: true });
            }
            if (error.response.data.error === "Nif length not 9") {
              context.commit("UPDATE_ERRORS", "nif", { root: true });
            }
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "type required.") {
              context.commit("UPDATE_ERRORS", "type", { root: true });
            }
            if (error.response.data.error === "nif required.") {
              context.commit("UPDATE_ERRORS", "nifRequired", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },    
    
    //       DELETE PROCESS_OPPOSITES
    //############################################

    //       MODAL - DELETE 
    openModalDelProcessOpposite(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS_OPPOSITE", true);
    },
    closeModalDelProcessOpposite(context) {
      context.commit("UPDATE_MODAL_DELETE_PROCESS_OPPOSITE", false);
    },

    //Confirmação abrindo modal Delete - Delete 1 de 2
    confirmDeleteProcessOpposite(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DELETE_PROCESS_OPPOSITE", true);//abre o modal
    },

    //Delete - Delete 2 de 2
    deleteProcessOpposite(context, { id, redirect }) {
      // console.log(context)
      // console.log("id", id)
      context.commit("UPDATE_LOADING", true, { root: true });
      api.delete(`/legal/opposite/${id}`)
        .then(() => {
          // context.commit("UPDATE_DELETE_CLIENT", id); //atualiza 
          context.dispatch("getProcesses", "refresh");
          context.dispatch("getProcessOpposites", "refresh");
          // if (redirect != undefined) {
            router.push({ name: "intervenientes" })
          // }
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Interveniente removido com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false, { root: true });
          // console.log("deletou", id)
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },  

    //##############################################################
    //    UPDATE ADDRESS - associando address ao opposite
    //##############################################################
    updateAddressOpposite(context, { idModule, idAddress }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("Opposite contex", context)
      // console.log("Opposite idModule", idModule)
      // console.log("Opposite idAddress", idAddress)
      context.commit("UPDATE_LOADING_ADDRESS", true, { root: true });
      api.put(`/legal/opposite/${idModule}/address/${idAddress}`)
        .then(() => {
          context.dispatch("getProcessOpposites", { refresh: "refresh", associate: "associate" });
          context.dispatch("getProcessOpposite", { idOpposite: idModule, refreshId: "refreshId", address: "address" });          
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    deleteAddressOpposite(context, { idOpposite, id }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("context", context)
      // console.log("idOpposite", idOpposite)
      // console.log("id", id)
      context.commit("UPDATE_LOADING_ADDRESS", true, { root: true });
      api.delete(`/legal/opposite/${idOpposite}/address/${id}`)
        .then(() => {
          context.dispatch("getProcessOpposites", { refresh: "refresh", associate: "associate" });
          context.dispatch("getProcessOpposite", { idOpposite, refreshId: "refreshId", address: "address" });          
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //############################################
    //     MODAL - OPPOSITE ADICIONAR CONTACT   
    //############################################
    openModalContact(context) {
      context.commit("UPDATE_MODAL_CONTACT", true);
    },
    closeModalContact(context) {
      context.commit("UPDATE_MODAL_CONTACT", false);
    },
    updateContactContact(context, { idModule, idContact }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("contextUser", context)
      // console.log("idModule", idModule)
      // console.log("idContact", idContact)
      context.commit("contact/UPDATE_LOADING", true, { root: true });
      api.put(`/legal/opposite/${idModule}/contact/${idContact}`)
        .then(() => {
          context.dispatch("getProcessOpposites", { refresh: "refresh", associate: "associate" });
          context.dispatch("getProcessOpposite", { idOpposite: idModule, refreshId: "refreshId", associate: "associate" });
          context.commit("UPDATE_MODAL_CONTACT", false);
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Contacto adicionado com sucesso!",
          }, { root: true });
          context.commit("contact/UPDATE_LOADING", false, { root: true });
        })
        .catch(error => {
          context.commit("contact/UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //############################################
    //     MODAL - OPPOSITE DELETE CONTACT   
    //############################################

    openModalDelContactOpposite(context) {
      context.commit("UPDATE_MODAL_DEL_CONTACT", true);
    },
    closeModalDelContactOpposite(context) {
      context.commit("UPDATE_MODAL_DEL_CONTACT", false);
    },
    //Delete - Delete 1 de 2
    confirmDeleteContactOpposite(context, id) {
      context.commit("UPDATE_SELECTED_ID", id); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DEL_CONTACT", true);//abre o modal
    },
    //Delete - Delete 2 de 2 - desassociando
    deleteContactOpposite(context, { idOpposite, id }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("context", context)
      // console.log("idOpposite", idOpposite)
      // console.log("id", id)
      context.commit("contact/UPDATE_LOADING", true, { root: true });
      api.delete(`/legal/opposite/${idOpposite}/contact/${id}`)
        .then(() => {
          context.dispatch("getProcessOpposites", { refresh: "refresh", associate: "associate" });
          context.dispatch("getProcessOpposite", { idOpposite, refreshId: "refreshId", associate: "associate" });
          context.dispatch("contact/getContacts", { refresh: "refresh" }, { root: true });
          // context.dispatch("contact/deleteContact", { id }, { root: true })
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Contacto removido com sucesso!",
          }, { root: true });
          context.commit("contact/UPDATE_LOADING", false, { root: true });
        })
        .catch(error => {
          context.commit("contact/UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    // ASSOCIAR OPPOSITE AO PROCESSO

    updateAssocieateOpposite(context, { idModule, idOpposite }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("contextUser", context)
      // console.log("idModule", idModule)
      // console.log("idProcessTax", idProcessTax)
      context.commit("UPDATE_LOADING_OPPOSITE", true, { root: true });
      api.post(`/legal/${idModule}/opposite/${idOpposite}`)
        .then(() => {
          context.dispatch("getProcess", {idProcess: idModule});
          // context.dispatch("getProcess", { idProcess: idModule, refreshId: "refreshId", associate: "associate" });
          context.commit("UPDATE_MODAL_PROCESS_OPPOSITE", false);
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Interveniente adicionado no processo com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING_OPPOSITE", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_OPPOSITE", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          if(error.response.data.error === "Process opposite list already contain this opposite."){
            // console.log("Process opposite list already contain this opposite.")
            context.dispatch("errorToast", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Atenção!",
              body: "Interveniente já existe!",
            }, { root: true });
        
          } else {
            context.dispatch("errorStatus", status, { root: true })
          }
          // Process opposite list already contain this opposite.
        })
    },

    openModalProcessOpposite(context) {
      context.commit("UPDATE_MODAL_PROCESS_OPPOSITE", true);
    },
    closeModalProcessOpposite(context) {
      context.commit("UPDATE_MODAL_PROCESS_OPPOSITE", false);
    },

    // Desassociar Opposite do Processo
    deleteAssocieateOpposite(context, { idProcess, id }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("context", context)
      // console.log("idOpposite", idOpposite)
      // console.log("id", id)
      context.commit("UPDATE_LOADING", true);
      api.delete(`/legal/${idProcess}/opposite/${id}`)
        .then(() => {
          context.dispatch("getProcesses", "refresh");
          // context.dispatch("getProcesses", { refresh: "refresh", associate: "associate" });
          // context.dispatch("getProcess", { idProcess, refreshId: "refreshId", associate: "associate" });
          // context.dispatch("getProcessOpposites", { refresh: "refresh" });
          // context.dispatch("contact/deleteContact", { id }, { root: true })          
          context.commit("UPDATE_MODAL_DELETE_PROCESS_OPPOSITE", false);
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Interveniente removido com sucesso!",
          }, { root: true });
          context.dispatch("getProcess", { idProcess});
          // context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //##################################################
    //    ATTACHMENT - PROCESS - ACTIONS
    //##################################################

    //    PROCESS TAX - ATTACHMENT - ACTIONS
    //##################################################

    //ASSOCIAR ATTACHMENT TAX
    onUploadProcessTax(context, { data, idProcess, idProcessTax, namefile }) {
      let fileName = { 'filename': namefile }
      context.commit("RESET_ERRORS", [], { root: true });
      api.post(`/legal/${idProcess}/tax/${idProcessTax}/attachment`, fileName)
        .then(response => {
          const attachmentID = response.data.id
          const urlDelete = `/legal/${idProcess}/tax/${idProcessTax}/attachment/${attachmentID}`
          const mutationDel = "'legal/UPDATE_DELETE_PROCESS_TAXE'"
          context.dispatch("attachment/attachment", { data, attachmentID, urlDelete, mutationDel }, { root: true })
          context.commit("UPDATE_SELECTEDFILE", null);
          context.dispatch("getProcess", {idProcess});
          // context.dispatch("getProcessTaxes", "refresh");
          // context.dispatch("getProcessTax", { idProcessTax });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_IMG", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorArchiveStatus", status, { root: true })
        })
    },


    
    addProcessTax(context, { processTax, idModule, mutation }) {
      // addProcessTax(context, { processTax, idModule, mutation, data, namefile }) {
      // console.log(process)
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
        api.post(`/legal/${idModule}/tax`, processTax) //process
          .then(() => {
            // const idProcessTax = response.data.id
            // console.log(response.data)
            // if (mutation != null && mutation != "") {
            //   context.dispatch(eval(mutation), { idModule, idProcessTax }, { root: true })//eval() pegar o nome da constante e não o valor da sua variável.
            // }   
            // console.log(mutation)
            // if(namefile != null && data != null) {
            //   context.dispatch("onUploadProcessTax", {
            //     idProcess: idModule,
            //     data: data,
            //     namefile: namefile,
            //     idProcessTax: idProcessTax,
            //   })
            // }                    
            context.dispatch("getProcess", { idProcess: idModule });
            context.dispatch("closeModalProcessTypeTax")
            context.dispatch("success", { 
              id: Date.now(),
              h1: "Successo!",
              body: "adicionada com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            // context.commit("UPDATE_PROCESS_TAX", context.getters["processTaxReset"]);//limpa o formulário depois de cadastrar        
            context.commit("UPDATE_LOADING", false);
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)     
                
            if (error.response.data.error === "Value required.") {
              context.commit("UPDATE_ERRORS", "value", { root: true });
            }
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "name", { root: true });
            }
            if (error.response.data.error === "description required.") {
              context.commit("UPDATE_ERRORS", "description", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },
    updateProcessTax(context, { idProcessTax, processTax, idModule }) {
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
        api.put(`/legal/${idModule}/tax/${idProcessTax}`, processTax) //process
          .then(() => {          
            context.dispatch("getProcess", { idProcess: idModule });
            context.dispatch("closeModalProcessTypeTax")
            context.dispatch("success", { 
              id: Date.now(),
              h1: "Successo!",
              body: "Taxa atualizada com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            // context.commit("UPDATE_PROCESS_TAX", context.getters["processTaxReset"]);//limpa o formulário depois de cadastrar        
            context.commit("UPDATE_LOADING", false);
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)     
                
            if (error.response.data.error === "Value required.") {
              context.commit("UPDATE_ERRORS", "value", { root: true });
            }
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "name", { root: true });
            }
            if (error.response.data.error === "description required.") {
              context.commit("UPDATE_ERRORS", "description", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
    },

    updateAssocieateTax(context, { idModule, idProcessTax }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("contextUser", context)
      // console.log("idModule", idModule)
      // console.log("idProcessTax", idProcessTax)
      context.commit("UPDATE_LOADING_TAX", true, { root: true });
      api.post(`/legal/${idModule}/tax/${idProcessTax}`)
        .then(() => {
          context.dispatch("getProcess", {idProcess: idModule});
          // context.dispatch("getProcess", { idProcess: idModule, refreshId: "refreshId", associate: "associate" });
          context.commit("UPDATE_MODAL_PROCESS_TAX", false);
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Taxa adicionada no processo com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING_TAX", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_TAX", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    
    // Desassociar Taxa do PRocesso
    deleteAssocieateTax(context, { idProcess, id }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("context", context)
      // console.log("idOpposite", idOpposite)
      // console.log("id", id)
      context.commit("UPDATE_LOADING", true);
      api.delete(`/legal/${idProcess}/tax/${id}`)
        .then(() => {
          context.dispatch("getProcesses", "refresh");
          // context.dispatch("getProcess", { idProcess, refreshId: "refreshId", associate: "associate" });
          // context.dispatch("getProcessTypeTaxes", { refresh: "refresh" });
          // context.dispatch("contact/deleteContact", { id }, { root: true })          
          context.commit("UPDATE_MODAL_DELETE_PROCESS_TAX", false);
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Taxa removida com sucesso!",
          }, { root: true });
          context.dispatch("getProcess", { idProcess});
          // context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //MODAL DELETE ATTACHMENT PROCESS TAX 
    openModalDelFileProcessTax(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_FILERTAX", true);
    },
    closeModalDelFileProcessTax(context) {
      context.commit("UPDATE_MODAL_DELETE_FILERTAX", false);
    },   
    // Delete - Delete 1 de 2
    confirmDeleteFileProcessTax(context, { archiveID, taxID }) {
      // console.log(archiveID, taxID);
      context.commit("UPDATE_SELECTED_ARCHIVE", { archiveID }); //armazena o id para enviar ao delete depois
      context.commit("UPDATE_SELECTED_TAXID",  taxID); //armazena o id para enviar ao delete depois
      context.dispatch("openModalDelFileProcessTax");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteFileProcessTax(context, { idProcess, taxID, archiveID }) {
      // console.log("context", context)
      context.commit("UPDATE_LOADING", true);
      api.delete(`/legal/${idProcess}/tax/${taxID}/attachment/${archiveID}`)
        .then(() => {
          context.dispatch("getProcess", { idProcess, refreshId: "refreshId" });
          // context.commit("UPDATE_DELETE_PROCESS_TAXE", archiveID); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Ficheiro removido com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          console.log(error.response)
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorArchiveStatus", status, { root: true })
          }
        })
    },   


    //    PROCESS POSITION - ATTACHMENT - ACTIONS
    //##################################################

    //ASSOCIAR ATTACHMENT TAX
    onUploadProcessPosition(context, { data, idProcessPosition, namefile }) {
      let fileName = { 'filename': namefile }
      context.commit("RESET_ERRORS", [], { root: true });
      api.post(`/legal/position/${idProcessPosition}/attachment`, fileName)
        .then(response => {
          const attachmentID = response.data.id
          const urlDelete = `/legal/position/${idProcessPosition}/attachment/${attachmentID}`
          const mutationDel = "'legal/UPDATE_DELETE_PROCESS_FILE_POSITION'"
          context.dispatch("attachment/attachment", { data, attachmentID, urlDelete, mutationDel }, { root: true })
          context.commit("UPDATE_SELECTEDFILE", null);
          context.dispatch("getProcessPositions", "refresh");
          context.dispatch("getProcessPosition", { idProcessPosition });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_IMG", false, { root: true });
          console.log(error.response.status)
          const status = error.response.status
          context.dispatch("errorArchiveStatus", status, { root: true })
        })
    },


    //MODAL DELETE ATTACHMENT PROCESS POSITION
    openModalDelFileProcessPosition(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_FILE", true);
    },
    closeModalDelFileProcessPosition(context) {
      context.commit("UPDATE_MODAL_DELETE_FILE", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteFileProcessPosition(context, { archiveID }) {
      context.commit("UPDATE_SELECTED_ARCHIVE", { archiveID }); //armazena o id para enviar ao delete depois
      context.dispatch("openModalDelFileProcessPosition");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteFileProcessPosition(context, { idProcessPosition, archiveID }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/legal/position/${idProcessPosition}/attachment/${archiveID}`)
        .then(() => {
          context.commit("UPDATE_DELETE_PROCESS_FILE_POSITION", archiveID); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Ficheiro removido com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          console.log(error.response)
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorArchiveStatus", status, { root: true })
          }
        })
    },

    //    PROCESS OPPOSITE - ATTACHMENT - ACTIONS
    //##################################################

    //ASSOCIAR ATTACHMENT 
    onUploadProcessOpposite(context, { data, idOpposite, namefile }) {
      let fileName = { 'filename': namefile }
      context.commit("RESET_ERRORS", [], { root: true });
      api.post(`/legal/opposite/${idOpposite}/attachment`, fileName)
        .then(response => {
          const attachmentID = response.data.id
          const urlDelete = `/legal/opposite/${idOpposite}/attachment/${attachmentID}`
          const mutationDel = "'legal/UPDATE_DELETE_PROCESS_OPPOSITE'"
          context.dispatch("attachment/attachment", { data, attachmentID, urlDelete, mutationDel }, { root: true })
          context.commit("UPDATE_SELECTEDFILE", null);
          context.dispatch("getProcessOpposites", "refresh");
          context.dispatch("getProcessOpposite", { idOpposite });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_IMG", false, { root: true });
          console.log(error.response.status)
          const status = error.response.status
          context.dispatch("errorArchiveStatus", status, { root: true })
        })
    },

    
    //MODAL DELETE ATTACHMENT PROCESS Opposite
    openModalDelFileProcessOpposite(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_FILE", true);
    },
    closeModalDelFileProcessOpposite(context) {
      context.commit("UPDATE_MODAL_DELETE_FILE", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteFileProcessOpposite(context, { archiveID }) {
      context.commit("UPDATE_SELECTED_ARCHIVE", { archiveID }); //armazena o id para enviar ao delete depois
      context.dispatch("openModalDelFileProcessOpposite");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteFileProcessOpposite(context, { idOpposite, archiveID }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/legal/opposite/${idOpposite}/attachment/${archiveID}`)
        .then(() => {
          context.commit("UPDATE_DELETE_FILE_PROCESS_OPPOSITE", archiveID); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Ficheiro removido com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          console.log(error.response)
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorArchiveStatus", status, { root: true })
          }
        })
    },
    //    PROCESS POSITION - ATTACHMENT - ACTIONS
    //##################################################

    //ASSOCIAR ATTACHMENT TAX
    onUploadProcess(context, { data, idProcess, namefile }) {
      let fileName = { 'filename': namefile }
      context.commit("RESET_ERRORS", [], { root: true });
      api.post(`/legal/${idProcess}/attachment`, fileName)
        .then(response => {
          const attachmentID = response.data.id
          const urlDelete = `/legal/${idProcess}/attachment/${attachmentID}`
          const mutationDel = "'legal/UPDATE_DELETE_PROCESS'"
          context.dispatch("attachment/attachment", { data, attachmentID, urlDelete, mutationDel }, { root: true })
          context.commit("UPDATE_SELECTEDFILE", null);
          context.dispatch("getProcesses", "refresh");
          context.dispatch("getProcess", { idProcess });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_IMG", false, { root: true });
          console.log(error.response.status)
          const status = error.response.status
          context.dispatch("errorArchiveStatus", status, { root: true })
        })
    },


    //MODAL DELETE ATTACHMENT PROCESS 
    openModalDelFileProcess(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_FILE", true);
    },
    closeModalDelFileProcess(context) {
      context.commit("UPDATE_MODAL_DELETE_FILE", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteFileProcess(context, { archiveID }) {
      context.commit("UPDATE_SELECTED_ARCHIVE", { archiveID }); //armazena o id para enviar ao delete depois
      context.dispatch("openModalDelFileProcess");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteFileProcess(context, { idProcess, archiveID }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/legal/${idProcess}/attachment/${archiveID}`)
        .then(() => {
          context.commit("UPDATE_DELETE_FILEPROCESS", archiveID); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Ficheiro removido com sucesso!",
          }, { root: true });
          // context.dispatch("getProcesses");
          context.dispatch("getProcess", { idProcess });
          // context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          console.log(error.response)
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorArchiveStatus", status, { root: true })
          }
        })
    },

   

  }

}