<template>
  <section>
    <h1>Central Telefónica</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Editar Contacto</h3>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'historico-chamadas' }"
              >Histórico de Chamadas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'lista-contacto' }"
              >Lista de Contactos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'contacto-interno-info',
                params: { idTelecomPhone: idTelecomPhone },
              }"
              >Dados do Contacto Interno</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Editar Contacto</span>
          </li>
        </ol>
      </nav>
      <TelecomPhoneForm :telecomPhone="telecomPhone" :errors="errors">
        <button class="btn" @click.prevent="updateTelecomPhone">Gravar</button>
      </TelecomPhoneForm>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import TelecomPhoneForm from "@/components/telecom/TelecomPhoneForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditContact",
  props: ["idTelecomPhone"],
  components: {
    TelecomPhoneForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("telecom", ["telecomPhone"]),
    ...mapGetters("telecom", ["telecomPhoneReply"]),
  },
  methods: {
    updateTelecomPhone() {
      this.$store.dispatch("telecom/updateTelecomPhone", {
        idPhone: this.idTelecomPhone,
        contact: this.telecomPhoneReply,
      });
    },
  },
  created() {
    // console.log(this.idTelecomPhone);
    this.$store.dispatch("telecom/getTelecomPhone", {
      idPhone: this.idTelecomPhone,
    });
  },
};
</script>

<style scoped>
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

@media screen and (max-width: 690px) {
  .containerTelecom {
    grid-template-columns: 1fr;
    justify-items: flex-start;
    grid-gap: 10px;
    margin-right: 10px;
  }
}
</style>