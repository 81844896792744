<template>
  <form action="">
    <div v-if="loadingClient">
      <p><LoadingCircle /></p>
    </div>
    <div class="formInfos" v-else>
      <label for="name"> Nome: </label>
      <input
        id="name"
        name="name"
        type="text"
        v-model="name"
        :class="validateFull"
        @change="resetError"
      />
      <div v-if="!errors.indexOf('full')" class="notify">
        O nome é um campo requerido
      </div>
      <label for="nif"> Nif: </label>
      <input
        id="nif"
        name="nif"
        type="number"
        v-model.number="nif"
        :class="[
          validateNif,
          validateNifRequired,
          validateNifExisty,
          validateNifNumber,
        ]"
        @change="resetError"
      />
      <div v-if="!errors.indexOf('nifExisty')" class="notify">
        Nif já existe.
      </div>
      <div v-if="!errors.indexOf('nif')" class="notify">
        Nif deve conter 9 caracteres.
      </div>
      <div v-if="!errors.indexOf('nifRequired')" class="notify">
        Nif é um campo requerido.
      </div>
      <div v-if="!errors.indexOf('nifNumber')" class="notify">
        Insira somente números.
      </div>
      <div v-if="route" class="selectContainer">
        <label for="type"> Tipo de Cliente: </label>
        <select
          v-model="clientTypeID"
          :class="validateType"
          class="option"
          @change="resetError"
        >
          <option value="null">Selecione um Tipo de Cliente</option>

          <option
            id="vehicleTypeID"
            v-for="(type, index) in typeClients"
            :key="type.id"
            :selected="index"
            :value="type.id"
          >
            {{ type.name }}
          </option>
          >
        </select>
      </div>
      <div v-else class="radioContainer">
        <div class="formInfos2">
          <label for="type" @click.prevent="btnRadio">
            Alterar Tipo de Cliente:
          </label>
          <div class="">
            <button class="buttonAdd" @click.prevent="btnRadio">
              <svg
                class="icon-add"
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
              </svg>
            </button>
          </div>
        </div>
        <div class="radioContent" v-if="radioTrue">
          <div
            id="vehicleTypeID"
            v-for="type in typeClients"
            :key="type.id"
            class="radio"
          >
            <input
              type="radio"
              :id="type.name"
              :value="type.id"
              v-model="clientTypeID"
            />
            <label :for="type.name">
              {{ type.name }}
            </label>
          </div>
        </div>
      </div>

      <div v-if="!errors.indexOf('type')" class="notify">
        O tipo é um campo requerido.
      </div>
    </div>
    <div class="button">
      <slot name="cancel"></slot>
      <slot name="delete"></slot>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "ClientForm",
  props: ["errors"],
  computed: {
    ...mapState("client", ["client", "typeClients"]),
    validateFull() {
      return this.errors.indexOf("full") ? "true" : "validate";
    },
    validateNifExisty() {
      return this.errors.indexOf("nifExisty") ? "true" : "validate";
    },
    validateNifNumber() {
      return this.errors.indexOf("nifNumber") ? "true" : "validate";
    },
    validateNif() {
      return this.errors.indexOf("nif") ? "true" : "validate";
    },
    validateType() {
      return this.errors.indexOf("type") ? "true" : "validate";
    },
    validateNifRequired() {
      return this.errors.indexOf("nifRequired") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["name", "nif", "clientTypeID"], //nome dos campos
      module: "client", //qual módulo está acessando
      base: "client", //colocar o state que vai receber
      mutation: "client/UPDATE_CLIENT", //nome da mutation que vai alterar o state
    }),
    ...mapState("client", ["loadingClient"]),
    // ...mapGetters("client", ["otherTypeClients"]),
    route() {
      return this.$route.name === "criar-cliente";
    },
    radioTrue() {
      return this.radio;
    },
  },
  data() {
    return {
      radio: false,
    };
  },
  methods: {
    btnRadio() {
      this.radio = !this.radio;
    },
    resetError() {
      this.$store.commit("RESET_ERRORS", []);
    },
  },
  created() {
    this.$store.dispatch("client/getTypeClients");
  },
};
</script>

<style scoped>
.formInfos2 {
  display: grid;
  grid-template-columns: 180px 100px;
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  margin-top: 10px;
  align-content: center;
}
.icon-add {
  fill: #fff;
  /* stroke: #fff; */
  z-index: 99;
}
.buttonAdd {
  display: grid;
  justify-content: center;
  align-items: center;
  /* margin-left: 60px; */
  background-color: #00bf48;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin: 0 15px;
  overflow: hidden;
  /* fill: #fff; */
  color: #ffffff;
}
select {
  margin: 10px 0;
}
.selectContainer,
.radioContainer {
  margin: 20px 0;
  /* display: grid;
  grid-template-columns: 30px 1fr; */
}
.radioContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: 0 4px 8px rgb(31 43 66 / 10%);
  padding: 20px;
  border-radius: 9px;
  margin: 20px 0;
}

.radio {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  outline: none;
  box-shadow: none;
}
form {
  max-width: 500px;
}
.formInfos {
  display: grid;
  grid-template-columns: minmax(300px, 500px);
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  margin-top: 20px;
  margin-right: 10px;
  max-width: 490px;
}

label {
  margin-top: 10px;

  outline: none;
}

.formInfos2 label {
  cursor: pointer;
}
.button {
  display: grid;
  grid-template-columns: repeat(1, 130px);
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}
</style>