var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h1',[_vm._v("Meus dados")]),(_vm.user)?_c('article',{staticClass:"contentInfos"},[_c('ul',[(_vm.user.fullName)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Nome")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.user.fullName))])])]):_vm._e(),(_vm.user.nickName)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Alcunha")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.user.nickName))])])]):_vm._e(),(_vm.user.username || _vm.emails.length)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Usuário")]),_c('ul',{staticClass:"items"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.user.username))])])]):_vm._e(),_c('li',{staticClass:"items itemList",attrs:{"id":"address"}},[_c('span',{staticClass:"titleBlue"},[_vm._v("Contacto")]),_c('ul',{staticClass:"items"},[(_vm.loadingAddress)?_c('div',[_c('p',[_c('LoadingCircle')],1)]):_c('div',[(_vm.user.contacts && _vm.user.contacts.length > 0)?_c('div',_vm._l((_vm.user.contacts),function(contact){return _c('li',{key:contact.id,staticClass:"textGray"},[_c('ContactItem',{attrs:{"contact":contact,"idRouter":_vm.user.id}})],1)}),0):_c('div',[_c('p',{staticClass:"textGray"},[_vm._v("Não possui Moradas")])])])])]),_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Morada")]),_c('ul',{staticClass:"items"},[(_vm.loadingAddress)?_c('div',[_c('p',[_c('LoadingCircle')],1)]):_c('div',[(_vm.user.addresses && _vm.user.addresses.length > 0)?_c('div',_vm._l((_vm.user.addresses),function(address){return _c('li',{key:address.id,staticClass:"textGray"},[_c('AddressItem',{attrs:{"address":address}},[(_vm.staffEdit)?_c('router-link',{staticClass:"edit",attrs:{"slot":"editAddress","to":{
                      name: 'morada',
                      params: {
                        idAddress: address.id,
                        idRouter: _vm.user.id,
                      },
                    }},slot:"editAddress"}):_vm._e(),(_vm.staffEdit)?_c('button',{staticClass:"delete",attrs:{"slot":"delete"},on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteAddress(address.id)}},slot:"delete"}):_vm._e()],1)],1)}),0):_c('div',[_c('p',{staticClass:"textGray"},[_vm._v("Não possui Moradas")])])])])]),_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Permissões")]),_c('ul',{staticClass:"items"},[(_vm.loadingPermission)?_c('div',[_c('p',[_c('LoadingCircle')],1)]):_c('div',[(_vm.user.superUser)?_c('div',[_c('li',{staticClass:"textGray info"},[_vm._v("Super Usuário")])]):_vm._e(),(_vm.permissionDescrip && _vm.permissionDescrip.length > 0)?_c('div',_vm._l((_vm.permissionDescrip),function(permission){return _c('li',{key:permission.id,staticClass:"textGray"},[_c('PermissionItem',{attrs:{"permission":permission,"idCollaborator":_vm.user.id}})],1)}),0):_c('div',[_c('p',{staticClass:"textGray"},[_vm._v("Não possui Permissões")])])])])])])]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }