<template>
  <section class="">
    <router-link
      class="collabItens itemList"
      :to="{
        name: 'processo',
        params: { idProcess: process.id },
      }"
    >
      <article class="containerLinkForm">
        <!-- {{ process }} -->
        <div class="list oppositeList">
                <div v-for="opposite in process.oppositeList" :key="opposite.id">
                    
                  <!-- <router-link
                    :to="{
                      name: 'interveniente',
                      params: {
                        idOpposite: opposite.id,
                        idRouter: idProcess,
                        router: 'processo',
                      },
                    }"
                  > -->
                    <span class="textGray"
                      >{{ opposite.name }}
                      <!-- <span class="textGreen">(mais detalhes)</span> -->
                    </span>
                  <!-- </router-link> -->
                </div>
              </div>
        <div>{{ process.processNumber }}</div>
        <div>{{ process.value | numberPrice }}</div>
        <div>{{ processCourt }}</div>
        <div>{{ processPosition }}</div>
        <!-- <div>{{ processTax }}</div> -->
        <!-- <div>{{ processType }}</div> -->
        <!-- <div>{{ process.startDate | formatDateReverse }}</div> -->
        <!-- <div>{{ process.archived ? "Arquivado" : "Ativo" }}</div> -->
        <div class="delete">
          <slot name="delete" />
        </div>
      </article>
    </router-link>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProcessItemList",
  props: ["process", "processPositions", "processCourts",],
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["processEdit"]),
    processPosition() {
      if (
        (this.processPositions != null || this.processPositions != undefined) &&
        (this.process.positionID != null ||
          this.process.positionID != undefined)
      ) {
        return this.processPositions.find((processPosition) => {
          return processPosition.id == this.process.positionID;
        }).name;
      }
    },
    processCourt() {
      if (
        (this.processCourts != null || this.processCourts != undefined) &&
        (this.process.courtID != null || this.process.courtID != undefined)
      ) {
        return this.processCourts.find((processCourt) => {
          return processCourt.id == this.process.courtID;
        }).name;
      }
    },
  },
  created(){
    // console.log(typeof this.process)
  }
};
</script>

<style scoped>
.list.oppositeList {
    display: grid;
}
.itemList {
  align-items: center;
}
.containerLinkForm {
  display: grid;
  grid-template-columns: 307px 225px 115px 218px 1fr 50px;
  width: 100%;
  padding: 5px 10px;
  grid-gap: 5px;
}
.delete {
  margin-right: 10px;
}
.itemAddress {
  display: none;
}
@media screen and (max-width: 690px) {
  .containerLinkForm {
    grid-template-columns: 1fr;
    padding: 5px 10px;
  }
  .itemAddress {
    display: block;
  }
}
</style>