<template>
  <section>
    <h1>Viaturas</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Lista de Viaturas</h3>
        <div
          class="menuDropdown"
          v-if="vehicleManager || vehicleAnalysis || vehicleEdit"
        >
          <BtnConfig>
            <span slot="title">
              Controlo <br />
              de Viaturas</span
            >
            <ul slot="itemsMenu" class="menuDropdownContent">
              <li>
                <router-link
                  :to="{
                    name: 'tipos-viaturas',
                  }"
                >
                  Tipos
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'marcas',
                  }"
                >
                  Marcas
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'modelos',
                  }"
                >
                  Modelos
                </router-link>
              </li>
              <li v-if="vehicleEdit || vehicleManager">
                <router-link
                  :to="{
                    name: 'adicionar-viatura',
                  }"
                  >Adicionar</router-link
                >
              </li>
            </ul>
          </BtnConfig>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item-active">
            <span> Lista de Viaturas</span>
          </li>
        </ol>
      </nav>
      <article>
        <div v-if="loadingVehicle">
          <Loading />
        </div>
        <div v-else>
          <div v-if="vehicles && vehicles.length > 0">
            <div class="filterBtn">
              <div @click.prevent="filterBtn">
                <BtnFilter>
                  <span slot="filtro">Filtro Viatura</span>
                </BtnFilter>
              </div>
            </div>
            <div v-if="btnFilter">
              <VehicleSearch />
            </div>
            <div class="titleCollab">
              <p class="titleBlue">Matrícula</p>
              <p class="titleBlue">Próxima Inspeção</p>
              <p class="titleBlue">Última Revisão</p>
              <p class="titleBlue">Validade de Seguro</p>
              <p class="titleBlue">Serviços</p>
            </div>
            <hr />
            <ul>
              <li
                class="itemList"
                v-for="vehicle in filteredVehicles"
                :key="vehicle.id"
              >
                <VehicleItem
                  :vehicle="vehicle"
                  :brands="brands"
                  :models="models"
                  :idVehicle="vehicle.id"
                >
                  <button
                    slot="delete"
                    class="delete"
                    @click.prevent="confirmDeleteVehicle(vehicle.id)"
                  ></button>
                </VehicleItem>
              </li>
            </ul>
          </div>
          <div v-else>
            <p class="noResults">Não possui viaturas registradas</p>
          </div>
        </div>
      </article>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteVehicle"
        @click="closeModalDelVehicle"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteVehicle">
        <button class="close btn" @click="closeModalDelVehicle">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover esta viatura?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelVehicle"
          />
          <button class="btnDel" @click.prevent="deleteVehicle">Delete</button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import VehicleSearch from "@/components/vehicle/VehicleSearch.vue";
import VehicleItem from "@/components/vehicle/VehicleItem.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddContact",
  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId", "btnFilter"]),
    ...mapState("user", ["user"]),
    ...mapState("vehicle", [
      "vehicles",
      "loadingVehicle",
      "showModalDeleteVehicle",
      "searchWord",
      "filtered",
      "brands",
      "models",
    ]),
    ...mapGetters("user", [
      "vehicleAccess",
      "vehicleManager",
      "vehicleAnalysis",
      "vehicleEdit",
    ]),
    ...mapGetters("vehicle", ["getFiltered"]),

    filteredVehicles() {
      const a = this.getFiltered || this.vehicles;
      return a;
    },
  },
  components: {
    VehicleItem,
    VehicleSearch,
  },
  methods: {
    filterBtn() {
      this.btnFilter == true
        ? this.$store.commit("UPDATE_BTNFILTER", false)
        : this.$store.commit("UPDATE_BTNFILTER", true);
      // console.lgo(update);
    },
    onPageChange(page) {
      this.$store.commit("UPDATE_CURRENTPAGE", page);
    },
    openModalDelVehicle() {
      this.$store.dispatch("vehicle/openModalDelVehicle");
    },
    closeModalDelVehicle() {
      this.$store.dispatch("vehicle/closeModalDelVehicle");
    },
    //######################################################
    //     DELETE
    //######################################################
    confirmDeleteVehicle(id) {
      this.$store.dispatch("vehicle/confirmDeleteVehicle", {
        id: `${id}`,
      });
    },
    deleteVehicle() {
      this.$store.dispatch("vehicle/deleteVehicle", {
        id: this.selectedId.id,
      });
      this.closeModalDelVehicle();
    },
  },
  created() {
    this.$store.dispatch("vehicle/getModels");
    this.$store.dispatch("vehicle/getBrands");
    this.$store.dispatch("vehicle/getVehicles");
    this.$store.commit("UPDATE_BTNFILTER", false);
    this.$store.commit("vehicle/RESET_FILTER", null);
  },
};
</script>

<style scoped>
.filterBtn {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px 0;
}
.modal_container {
  max-width: 450px;
}
.titleContainer {
  display: grid;
  grid-template-columns: 1fr 140px;
  justify-content: space-between;
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1200px);
  justify-content: center;
  align-content: flex-start;
}

.titleCollab {
  display: grid;
  /* grid-template-columns: repeat(10, 130px); */
  /* grid-template-columns: 200px 150px 70px 90px repeat(2, 100px); */
  /* grid-template-columns: 350px 380px; */
  grid-template-columns: 150px 200px 180px 250px minmax(200px, 380px);
  /* grid-gap: 5px; */
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  min-width: 350px;
  max-width: 1200px;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  /* margin-bottom: 30px; */
}

.titleBlue {
  margin: 0 5px;
}
h3 {
  text-align: initial;
  /* margin-left: 10px; */
}
@media screen and (max-width: 695px) {
  .titleBlue {
    display: none;
  }
  .containerTelecom {
    margin: 80px 10px 20px 10px;
  }
}
</style>
