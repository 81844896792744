<template>
  <section class="">
    <h1>Jurídico</h1>
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Dados da Posiçã̀o</h3>
        <div v-if="processAccess">
          <router-link
            class="collabItens"
            :to="{
              name: 'editar-posicao',
              params: {
                idProcessPosition: idProcessPosition,
              },
            }"
          >
            <AddUser>
              <span slot="title">
                Editar<br />
                Posição</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb" v-if="router == 'processo'">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos', query: { archived: 'false'} }"  >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'processo',
                params: { idProcess: idRouter },
              }"
              >Processo</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Dados da Posição</span>
          </li>
        </ol>
      </nav>
      <nav class="navBreadcrumb" v-else>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos', query: { archived: 'false'} }"  >Lista de Processos</router-link
            >
          </li>

          <li class="breadcrumb-item">
            <router-link :to="{ name: 'lista-posicoes' }">Lista de Posições</router-link>
          </li>
          <li class="breadcrumb-item-active">
            <span>Dados da Posição</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingProcess">
        <Loading />
      </div>
      <div v-else>
        <article v-if="processPosition">
          <ul class="borderTable">
            <li
              class="items itemList"
              v-if="processPosition.name && processPosition.name.length > 0"
            >
              <span class="titleBlue">Nome</span>
              <div class="list">
                <span class="textGray">{{ processPosition.name }}</span>
              </div>
            </li>

            <li
              class="items itemList"
              v-if="
                processPosition.created && processPosition.created.length > 0
              "
            >
              <span class="titleBlue">Criado</span>
              <div class="list">
                <!-- <span class="textGray">{{ createdprocessPosition }}</span> -->
                <span class="textGray">{{
                  processPosition.created | formatDateReverse
                }}</span>
              </div>
            </li>
            <li
              class="items itemList"
              v-if="
                processPosition.modified && processPosition.modified.length > 0
              "
            >
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <!-- <span class="textGray">{{ modifiedprocessPosition }}</span> -->
                <span class="textGray">{{
                  processPosition.modified | formatDateReverse
                }}</span>
              </div>
            </li>
            <li class="items containerFile">
              <span class="titleBlue">Ficheiros</span>

              <div v-if="loadingImg">
                <LoadingCircle />
              </div>

              <div v-else>
                <div v-if="processEdit || processManager" class="containerFile">
                  <div class="inputList">
                    <label for="fileInput" class="inputFile"
                      >Selecione um ficheiro
                      <span class="spot">clique aqui</span></label
                    >
                    <span v-if="selectedFile != null" class="itemFile"
                      >{{ selectedFile.name
                      }}<span
                        @click.prevent="cleanFile($event)"
                        class="clearFile"
                        >&#215;</span
                      ></span
                    >
                    <input
                      type="file"
                      @change="onFileSelected"
                      id="fileInput"
                      class="inputNone"
                    />
                  </div>
                  <button
                    class="btn addFile"
                    @click="onUpload(processPosition.id)"
                  >
                    Adicionar
                  </button>
                </div>
              </div>
            </li>
            <article>
              <div v-if="loadingImg"></div>
              <div v-else>
                <div
                  v-if="
                    processPosition.files != null &&
                    processPosition.files.length > 0
                  "
                >
                  <article class="downloadImgLoading" v-if="loadingDownloadImg">
                    Aguarde, fazendo download...
                  </article>
                  <ul v-else>
                    <li
                      v-for="archive in processPosition.files"
                      :key="archive.id"
                    >
                      <!-- {{ archive }} -->
                      <article class="articleInfos">
                        <!-- <hr /> -->
                        <ArchiveItem
                          :archive="archive"
                          :idMicroservice="processPosition.id"
                          @getFile="getFile(archive.filename, archive.id)"
                        >
                          <button
                            slot="delete"
                            class="delete"
                            @click.prevent="
                              confirmDeleteFileProcessPosition(archive.id)
                            "
                          ></button>
                        </ArchiveItem>
                      </article>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <p class="noResults noArchive">
                    Não possui ficheiros registrados
                  </p>
                </div>
              </div>
            </article>
          </ul>
        </article>
      </div>
    </div>
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteFile"
        @click="closeModalDelFileProcessPosition"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteFile">
        <button class="close btn" @click="closeModalDelFileProcessPosition">
          x
        </button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este ficheiro?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelFileProcessPosition"
          />
          <button class="btnDel" @click.prevent="deleteFileProcessPosition">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import ArchiveItem from "@/components/legal/ArchiveItem.vue";
// import { getLastDate } from "@/helpers.js";
// import { formatDate } from "@/helpers.js";
// import { alertDate } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProcessPosition",
  props: ["idProcessPosition", "idRouter", "router"],
  data() {
    return {
      searchHistory: false,
    };
  },
  components: {
    ArchiveItem,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "loadingDownloadImg",
      "selectedId",
      "loadingImg",
    ]),
    ...mapState("user", ["user"]),
    ...mapState("legal", [
      "processPosition",
      "loadingProcess",
      "showModalDelProcessPosition",
      "showModalDeleteFile",
      "selectedFile",
      "archiveID",
      "fileID",
    ]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalyse",
      "processManager",
    ]),
  },
  methods: {
    //######################################################
    //     FILE ARQUIVO PADRÂO
    //######################################################
    getFile(filename, archiveID) {
      this.$store.dispatch("attachment/getFile", {
        archiveID: `${archiveID}`,
        fileName: `${filename}`,
      });
    },
    cleanFile() {
      this.$store.commit("legal/UPDATE_SELECTEDFILE", null);
    },
    onFileSelected(event) {
      this.$store.commit("legal/UPDATE_SELECTEDFILE", event.target.files[0]);
    },

    onUpload(id) {
      const formData = new FormData();
      formData.append("file", this.selectedFile, this.selectedFile.name);
      // console.log("formData", formData);
      this.$store.dispatch("legal/onUploadProcessPosition", {
        idProcessPosition: `${id}`,
        data: formData,
        namefile: this.selectedFile.name,
      });
    },
    //######################################################
    //    ARQUIVOS MODAL DELETE
    //######################################################
    openModalDelFileProcessPosition() {
      this.$store.dispatch("legal/openModalDelFileProcessPosition");
    },
    closeModalDelFileProcessPosition() {
      this.$store.dispatch("legal/closeModalDelFileProcessPosition");
    },
    //######################################################
    //     ARQUIVOS DELETE
    //######################################################
    confirmDeleteFileProcessPosition(archiveID) {
      // console.log(archiveID);
      this.$store.dispatch("legal/confirmDeleteFileProcessPosition", {
        archiveID: `${archiveID}`,
      });
    },
    deleteFileProcessPosition() {
      this.$store.dispatch("legal/deleteFileProcessPosition", {
        idProcessPosition: this.idProcessPosition,
        archiveID: this.archiveID,
      });
      this.closeModalDelFileProcessPosition();
    },
    openModalDelProcessPosition() {
      this.$store.dispatch("legal/openModalDelProcessPosition");
    },
    closeModalDelProcessPosition() {
      this.$store.dispatch("legal/closeModalDelProcessPosition");
    },
  },
  created() {
    this.$store.dispatch("legal/getProcessPosition", {
      idProcessPosition: this.idProcessPosition,
    });
  },
};
</script>

<style scoped>
.proximitDate {
  color: red;
}
.validate {
  margin-left: 10px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}
/* files */
.clearFile {
  font-size: 24px;
  line-height: 28px;
  margin-left: 10px;
  font-weight: bold;
  color: #00732c;
  padding-top: 5px;
  cursor: pointer;
}
.containerFile {
  display: flex;
  align-content: center;
  align-items: baseline;
  /* grid-gap: 10px; */
  margin: 10px 0 0 0;
}
.inputNone {
  display: none;
}
.noArchive {
  margin: 20px 0 30px 0;
  margin-left: 10px;
}

.spot {
  font-size: 14px;
  color: #015eb0;
}
.list {
  /* display: grid; */
  align-content: center;
}
.inputList {
  display: grid;
}
/*INPUT FILE botão e arquivo */
.inputFile {
  cursor: pointer;
  font-size: 14px;
  width: 240px;
  border: 1px solid #015eb0;
  background: #eff7fe;
  border-radius: 9px;
  padding: 10px;
  color: #1f2b42;
  margin: 3px 10px 0 0;
  border-style: dotted;
}
/* fim files  */
.textGreen {
  margin-left: 10px;
  color: #00732c;
  font-size: 14px;
  line-height: 28px;
}
.textGreen:hover {
  text-decoration: underline;
}
.item2 {
  padding-left: 15px;
}
.addTipeVehicle {
  display: flex;
  align-items: center;
  /* margin-left: 15px; */
}
.containerLink {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.articleInfos,
.callsHistory {
  margin-top: 30px;
}

.titleInfos {
  margin-bottom: 10px;
}

.iconInfoSearchHistory {
  margin: 0 10px;
}
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .items li {
    padding: 0 0 0 10px;
  }
  .list {
    padding: 0 10px;
  }
  .inputFile {
    margin: 3px 10px 0 10px;
  }
}
@media screen and (max-width: 390px) {
  .containerFile {
    display: grid;
    grid-gap: 10px;
  }
}
</style>
