<template>
  <section>
    <div class="list-box-wrapper">
      <div class="colList">
        <ul class="list-box" :class="validateRedirect">
          <li
            v-for="(item, key) in active"
            :key="key"
            class="list-item"
            :class="{ selected: item.selected, notSelected: !item.selected }"
            @click="activeContact(item)"
          >
            {{ item.contactName }}
          </li>
        </ul>
      </div>
      <div class="actions">
        <div class="btn-action" @click="moveLeft">&lt;</div>
        <div class="btn-action" @click="allLeft">&#171;</div>
        <div class="btn-action" @click="allRight">&#187;</div>
        <div class="btn-action" @click="moveRight">&gt;</div>
      </div>
      <div class="colList">
        <ul class="list-group list-group-flush border rounded list-box">
          <li
            v-for="(item, key) in inactive"
            v-bind:key="key"
            class="list-item"
            :class="{ selected: item.selected, notSelected: !item.selected }"
            @click="activeContact(item)"
          >
            {{ item.contactName }}
          </li>
        </ul>
      </div>
    </div>
    <!-- {{ errors }} -->
  </section>
</template>

<script>
// import { mapState } from "vuex";
export default {
  props: {
    source: Array,
    destination: Array,
    errors: Array,
  },
  data() {
    return {
      selected: false,
      active: null,
      inactive: null,
      hasPopulated: null,
    };
  },
  computed: {
    validateRedirect() {
      return this.errors.indexOf("redirectError") ? "true" : "validate";
    },
  },

  methods: {
    refreshList() {
      // if (this.inactive == null && this.destination != "") {
      if (this.source != "" || this.destination != "") {
        this.active = this.source;
        this.inactive = this.destination;
        window.clearTimeout(this.hasPopulated);
      }
    },
    activeContact(item) {
      if (item.selected == false) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    },
    moveLeft() {
      for (var index in this.inactive) {
        if (this.inactive[index].selected == true) {
          this.active.push(this.inactive[index]);
        }
      }
      this.inactive = this.inactive.filter((item) => {
        return item.selected == false;
      });
      this.setupFalse();
      this.$emit("onChangeList", this.active);
    },

    allLeft() {
      for (var index in this.inactive) {
        this.inactive[index].selected = true;
      }
      this.moveLeft();
      this.setupFalse();
      this.$emit("onChangeList", this.active);
    },

    moveRight() {
      for (var index in this.active) {
        if (this.active[index].selected == true) {
          this.inactive.push(this.active[index]);
        }
      }
      this.active = this.active.filter((item) => {
        return item.selected == false;
      });
      this.setupFalse();
      this.$emit("onChangeList", this.active);
    },

    allRight() {
      for (var index in this.active) {
        this.active[index].selected = true;
      }
      this.moveRight();
      this.setupFalse();
      this.$emit("onChangeList", this.active);
    },

    setupFalse() {
      for (var itemActive in this.active) {
        this.active[itemActive].selected = false;
      }
      for (var itemInactive in this.inactive) {
        this.inactive[itemInactive].selected = false;
      }
    },
    // deSelectAllDestination: function () {

    // },
  },
  created() {
    this.hasPopulated = setInterval(this.refreshList, 100);
  },
};
</script>

<style scoped>
.col2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.notSelected,
.not-selected {
  background: #fff;
}
.selected {
  background: #858585;
}
*,
*::before,
*::after {
  box-sizing: border-box;
}

.list-box-wrapper {
  align-items: center;
  display: grid;
  grid-template-columns: 1fr 50px 1fr;
}
.list-box-wrapper > div {
  flex: 1;
}
.actions {
  display: grid;
  justify-content: center;
}
.actions .btn-action {
  margin-bottom: 0.5rem;
}

.colList {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
}
.list-box {
  display: grid;
  grid-template-columns: 1fr;
  align-content: flex-start;
  width: 100%;
  height: 250px;
  overflow: auto;
  list-style: none;
  padding: 0;
  border: solid 1px #cccccc;
  border-radius: 3px;
}
.list-box .list-item {
  padding: 0.5rem 1rem;
  border-bottom: solid 1px #cccccc;
  cursor: pointer;
}
.list-box .list-item:last-child {
  border: none;
}

.validate {
  width: 100%;
  border-radius: 4px;
  border: 1px solid rgba(220, 53, 70, 0.5);
  padding: 15px;
  box-shadow: 0 4px 8px rgba(30, 60, 90, 0.1);
  transition: all 0.3s;
  font-size: 1rem;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  margin-bottom: 15px;
  color: #463737;
}
.btn-action {
  width: 30px;
  height: 30px;
  display: grid;
  /* padding: 10px; */
  align-content: center;
  justify-content: center;
  background: #10a4e8;
  border-radius: 9px;
  overflow: none;
  outline: none;
  border: none;
  color: #ffffff;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  box-shadow: 0 4px 8px rgba(30, 60, 90, 0.2);
}
.deselect-all {
  background-color: #6c757d;
  border-color: #6c757d;
}
</style>


