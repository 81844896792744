<template>
  <section>
    <h1>Central Telefónica</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Adicionar Redirecionamento</h3>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'historico-chamadas' }"
              >Histórico de Chamadas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'lista-redirecionamentos' }"
              >Lista de Redirecionamentos
            </router-link>
          </li>
          <li class="breadcrumb-item-active">
            <span> Adicionar Redirecionamento</span>
          </li>
        </ol>
      </nav>
      <VariantForm :errors="errors" :telecomPhones="telecomPhones">
        <button class="btn" @click.prevent="addVariant">Adicionar</button>
      </VariantForm>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import VariantForm from "@/components/telecom/VariantForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditContact",
  components: {
    VariantForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("telecom", ["telecomPhone", "telecomPhones"]),
    ...mapGetters("telecom", ["variantReply", "variantReset"]),
  },
  methods: {
    addVariant() {
      // console.log(this.variantReply);
      this.$store.dispatch("telecom/addVariant", {
        variant: this.variantReply,
      });
    },
  },
  created() {
    this.$store.dispatch("telecom/getTelecomPhones");
    this.$store.commit("telecom/UPDATE_VARIANT", this.variantReset);
    this.$store.commit("RESET_ERRORS", []);
  },
};
</script>

<style scoped>
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

@media screen and (max-width: 690px) {
  .containerTelecom {
    grid-template-columns: 1fr;
    justify-items: flex-start;
    grid-gap: 10px;
    margin-right: 10px;
  }
}
</style>