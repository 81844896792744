<template>
  <section class="itemContainer">
    <router-link
      :to="{
        name: 'usuario-telegram',
        params: { idTelegramUser: telegramUser.id },
      }"
    >
      <div class="collabItens">
        <div class="collabIten">
          <div>
            <span class="titleBlue">Telegram ID</span>
            <p>{{ telegramUser.chatID }}</p>
          </div>
          <div>
            <span class="titleBlue">Nome</span>
            <p v-if="telegramUser.description">
              {{ telegramUser.description }}
            </p>
            <p v-else>{{ telegramUser.firstName }}</p>
          </div>
          <!-- <div>
            <span class="titleBlue">Descrição</span>
            <p v-if="telegramUser.description">
              {{ telegramUser.description }}
            </p>
            <p v-else>Sem descrição</p>
          </div> -->
          <div>
            <span class="titleBlue">Ativo</span>
            <p>{{ telegramUser.active }}</p>
          </div>
        </div>
        <div class="collabConfig"></div>
      </div>
    </router-link>
  </section>
</template>  

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "TelegramUsertem",
  props: ["telegramUser", "idTelegramUser"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["telecomManager"]),
  },
};
</script>

<style scoped>
.itemContainer {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
  width: 100%;
}

.collabItens {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  /* grid-template-columns: 1fr 90px; */
  grid-template-columns: 200px minmax(200px, 380px) 1fr 130px;
}

.collabIten {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  /* grid-template-columns: minmax(200px, 380px) 1fr; */
  grid-template-columns: repeat(4, 200px);
  align-items: center;
}

.collabConfig {
  display: grid;
  grid-template-columns: 40px 50px;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

.collabItens:hover {
  background: #dbebf3;
}

.collabItens p {
  padding: 0 10px;
}
.titleBlue {
  display: none;
}

@media screen and (max-width: 690px) {
  .collabItens {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* height: 30px; */
  }
  .collabIten {
    grid-template-columns: 260px;
  }
  .textGray {
    padding: 0 0 0 20px;
  }

  .titleBlue {
    display: block;
    padding: 5px 0 0 10px;
  }
  .itemContainer {
    width: 100%;
  }
}
</style>
