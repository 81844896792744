<template>
  <form action="">
    <div class="container-topSearch">
      <article class="searchContainer formInfos">
        <div>          
          <label for="searchWord">Matrícula / Serviços:</label>
          <div class="searchLupa">
            <input
              type="text"
              name="searchWord"
              id="searchWord"
              v-model="searchWord"
              autocomplete="off"
              placeholder="Matrícula / Serviços..."
              @keypress.enter.prevent="searchVehicle"
            />
            <span id="lupa"></span>
          </div>
        </div>       
        <div class="dataSearch">
          <label for="nextInspectionStartDate">
            Próxima Inspeção - Data Início:
          </label>
          <input
            id="nextInspectionStartDate"
            type="date"
            v-model="nextInspectionStartDate"
            autocomplete="off"
            @keyup.enter="searchVehicle"
          />
        </div>
        <div class="dataSearch">
          <label for="nextInspectionEndDate">
            Próxima Inspeção - Data fim:
          </label>
          <input
            id="nextInspectionEndDate"
            type="date"
            v-model="nextInspectionEndDate"
            autocomplete="off"
            @keyup.enter="searchVehicle"
          />
          <!-- @keyup.enter="searchVehicle" -->
          <!-- @click="event" -->
        </div>
      </article>
      <article class="searchContainerDate formInfos">
        <div class="dataSearch">
          <label for="revisonStartDate"> Última Revisão - Data Início: </label>
          <input
            id="revisonStartDate"
            type="date"
            v-model="revisonStartDate"
            autocomplete="off"
            @keyup.enter="searchVehicle"
          />
          <!-- @keyup.enter="searchVehicle" -->
        </div>
        <div class="dataSearch">
          <label for="revisonEndDate"> Última Revisão - Data fim: </label>
          <input
            id="revisonEndDate"
            type="date"
            v-model="revisonEndDate"
            autocomplete="off"
            @keyup.enter="searchVehicle"
          />
          <!-- @keyup.enter="searchVehicle" -->
          <!-- @click="event" -->
        </div>
        <!-- <div class="buttonSearch">
          <div
            class="button"
            @keyup.enter="searchRevision"
            @click.prevent="searchRevision"
          >
            <BtnSearch />
          </div>
          <div class="button btnReset" @click.prevent="reset">
            <BtnReset />
          </div>
        </div> -->
      </article>
      <article class="searchContainerDate formInfos">
        <div class="dataSearch">
          <label for="insuranceStartDate">
            Validade de Seguro - Data Início:
          </label>
          <input
            id="insuranceStartDate"
            type="date"
            v-model="insuranceStartDate"
            autocomplete="off"
            @keyup.enter="searchVehicle"
          />
          <!-- @keyup.enter="searchVehicle" -->
        </div>
        <div class="dataSearch">
          <label for="insuranceEndDate"> Validade de Seguro - Data fim: </label>
          <input
            id="insuranceEndDate"
            type="date"
            v-model="insuranceEndDate"
            autocomplete="off"
            @keyup.enter="searchVehicle"
          />
          <!-- @keyup.enter="searchVehicle" -->
          <!-- @click="event" -->
        </div>
      </article>
      <div class="buttonSearch">
        <div class="button" @keyup.enter="searchVehicle" @click="searchVehicle">
          <BtnSearch />
        </div>
        <div class="button btnReset" @click.prevent="reset">
          <BtnReset />
        </div>
      </div>
      <ul class="searchActiveContainer" v-if="filterItem">
        <div class="filterActive" v-if="filterItem">Filtro activo:</div>
        <!-- <ul class="searchActiveContainer">
        <div class="filterActive">Filtro activo:</div> -->
        <article v-if="searchWord" class="searchActive">
          <div>Matrícula / Serviços...</div>
          <div class="closeSearch" @click.prevent="closeSearchWord">x</div>
        </article>
        <article v-if="nextInspectionStartDate" class="searchActive">
          <div>Próxima Inspeção - Data início</div>
          <div
            class="closeSearch"
            @click.prevent="closeNextInspectionStartDate"
          >
            x
          </div>
        </article>
        <article v-if="nextInspectionEndDate" class="searchActive">
          <div>Próxima Inspeção - Data Fim</div>
          <div class="closeSearch" @click.prevent="closeNextInspectionEndDate">
            x
          </div>
        </article>
        <article v-if="revisonStartDate" class="searchActive">
          <div>Revisão - Data início</div>
          <div class="closeSearch" @click.prevent="closeRevisonStartDate">
            x
          </div>
        </article>
        <article v-if="revisonEndDate" class="searchActive">
          <div>Revisão - Data Fim</div>
          <div class="closeSearch" @click.prevent="closeRevisonEndDate">x</div>
        </article>
        <article v-if="insuranceStartDate" class="searchActive">
          <div>Seguro - Data início</div>
          <div class="closeSearch" @click.prevent="closeInsuranceStartDate">
            x
          </div>
        </article>
        <article v-if="insuranceEndDate" class="searchActive">
          <div>Seguro - Data Fim</div>
          <div class="closeSearch" @click.prevent="closeInsuranceEndDate">
            x
          </div>
        </article>
      </ul>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState(["filterItem"]),
    ...mapState("vehicle", [
      "searchWord",
      "nextInspectionStartDate",
      "nextInspectionEndDate",
      "revisonStartDate",
      "revisonEndDate",
      "insuranceStartDate",
      "insuranceEndDate",
    ]),
    // msgFilter() {
    //   return this.searchWord != null ||
    //     this.nextInspectionStartDate != null ||
    //     this.nextInspectionEndDate != null ||
    //     this.revisonStartDate != null ||
    //     this.revisonEndDate != null ||
    //     this.insuranceStartDate != null ||
    //     this.insuranceEndDate != null
    //     ? true
    //     : false;
    // },
    searchWord: {
      get() {
        return this.$store.state.vehicle.searchWord;
      },
      set(value) {
        this.$store.commit("vehicle/UPDATE_SEARCHWORD", value);
      },
    },
    nextInspectionStartDate: {
      get() {
        return this.$store.state.vehicle.nextInspectionStartDate;
      },
      set(value) {
        this.$store.commit("vehicle/UPDATE_STARTDATE_NEXTINSPECTION", value);
      },
    },
    nextInspectionEndDate: {
      get() {
        return this.$store.state.vehicle.nextInspectionEndDate;
      },
      set(value) {
        this.$store.commit("vehicle/UPDATE_ENDDATE_NEXTINSPECTION", value);
      },
    },
    revisonStartDate: {
      get() {
        return this.$store.state.vehicle.revisonStartDate;
      },
      set(value) {
        this.$store.commit("vehicle/UPDATE_STARTDATE_REVISION", value);
      },
    },
    revisonEndDate: {
      get() {
        return this.$store.state.vehicle.revisonEndDate;
      },
      set(value) {
        this.$store.commit("vehicle/UPDATE_ENDDATE_REVISION", value);
      },
    },
    insuranceStartDate: {
      get() {
        return this.$store.state.vehicle.insuranceStartDate;
      },
      set(value) {
        this.$store.commit("vehicle/UPDATE_STARTDATE_INSURANCE", value);
      },
    },
    insuranceEndDate: {
      get() {
        return this.$store.state.vehicle.insuranceEndDate;
      },
      set(value) {
        this.$store.commit("vehicle/UPDATE_ENDDATE_INSURANCE", value);
      },
    },
  },
  methods: {
    msgFilter() {
      return this.$store.commit("UPDATE_FILTER_ITEM",this.searchWord != null ||
        this.nextInspectionStartDate != null ||
        this.nextInspectionEndDate != null ||
        this.revisonStartDate != null ||
        this.revisonEndDate != null ||
        this.insuranceStartDate != null ||
        this.insuranceEndDate != null)
    },
    closeSearchWord() {
      this.$store.commit("vehicle/RESET_FILTER_SEARCHWORD", null);
      this.searchVehicle();
    },
    closeNextInspectionStartDate() {
      this.$store.commit("vehicle/UPDATE_STARTDATE_NEXTINSPECTION", null);
      this.searchVehicle();
    },
    closeNextInspectionEndDate() {
      this.$store.commit("vehicle/UPDATE_ENDDATE_NEXTINSPECTION", null);
      this.searchVehicle();
    },
    closeRevisonStartDate() {
      this.$store.commit("vehicle/UPDATE_STARTDATE_REVISION", null);
      this.searchVehicle();
    },
    closeRevisonEndDate() {
      this.$store.commit("vehicle/UPDATE_ENDDATE_REVISION", null);
      this.searchVehicle();
    },
    closeInsuranceStartDate() {
      this.$store.commit("vehicle/UPDATE_STARTDATE_INSURANCE", null);
      this.searchVehicle();
    },
    closeInsuranceEndDate() {
      this.$store.commit("vehicle/UPDATE_ENDDATE_INSURANCE", null);
      this.searchVehicle();
    },
    reset() {
      this.$store.commit("vehicle/RESET_FILTER", null);
      this.msgFilter()
      this.updateQuert()
      if (Object.entries(this.$router.currentRoute.query).length != 0) {
        this.$router.push({ query: {} });
      }
    },
    updateQuert() {
      const filter = {
        searchWord: this.searchWord,
        nextInspectionStartDate: this.nextInspectionStartDate,
        nextInspectionEndDate: this.nextInspectionEndDate,
        revisonStartDate: this.revisonStartDate,
        revisonEndDate: this.revisonEndDate,
        insuranceStartDate: this.insuranceStartDate,
        insuranceEndDate: this.insuranceEndDate,
        // page: Number(this.$route.query.page),
      };
      const query = Object.entries(filter).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$router.replace({ query: { ...query } }).catch(() => {});
    },
    searchVehicle() {
      // console.log("searchVehicle")
      // this.$store.commit("vehicle/FILTERED_REVISIONS", this.searchWord);
      this.$store.commit("vehicle/FILTERED_VEHICLE", {
        searchWord: this.searchWord,
        nextInspectionStartDate: this.nextInspectionStartDate,
        nextInspectionEndDate: this.nextInspectionEndDate,
        revisonStartDate: this.revisonStartDate,
        revisonEndDate: this.revisonEndDate,
        insuranceStartDate: this.insuranceStartDate,
        insuranceEndDate: this.insuranceEndDate,
      });
        this.msgFilter()
        this.updateQuert();
    },

    // event(e) {
    //   console.log(e.type);
    //   e.type == "click" ? this.searchVehicle() : "nothing";
    // },
  },
  created(){
    this.msgFilter();
  }
};
</script>

<style scoped>
.searchActive {
  display: grid;
  grid-template-columns: minmax(60px, auto) 25px;
  grid-gap: 5px;
  justify-content: center;
  /* box-shadow: 0 4px 8px rgb(31 43 66 / 20%); */
  /* max-width: 320px; */
  border-radius: 4px;
  border: 1px solid rgba(31, 43, 66, 0.4);
  padding: 10px;
  transition: all 0.3s;
  outline: none;
}
.searchActiveContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0px 0 30px 0;
  grid-gap: 10px;
  font-size: 0.8rem;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #373946;
  padding: 10px;
  border-radius: 4px;
  /* box-shadow: 0 4px 8px rgb(31 43 66 / 20%); */
  border-radius: 4px;
  border: 1px solid rgba(31, 43, 66, 0.4);
  /* justify-content: center; */
  justify-content: flex-start;
  max-width: 1000px;
}
.closeSearch {
  padding: 0 10px;
  /* padding: 0 10px; */
  border-left: 1px solid #ccc;
  cursor: pointer;
  font-weight: bold;
}

.buttonSearch {
  display: flex;
  justify-content: flex-end;
}
.dataSearch {
  display: grid;
  grid-template-columns: 1fr;
}
.searchLupa {
  margin-top: 5px;
  display: grid;
  position: relative;
}
.container-topSearch {
  display: grid;
  grid-template-columns: minmax(300px, 885px);
  width: 100%;
  padding: 0 10px 40px 0;
  justify-content: center;
  grid-gap: 10px;
}

#search {
  width: 100%;
}
.searchContainerDate {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  align-items: flex-end;
}
.searchContainer {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 350px repeat(2, 1fr);
  grid-gap: 10px;
  align-items: flex-end;
}
#lupa {
  width: 62px;
  height: 46px;
  background: url("../../assets/svg/search2.svg") no-repeat center center;
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;
}

@media screen and (max-width: 690px) {
  .searchContainer {
    grid-template-columns: 1fr;
    margin-left: 10px;
  }
  .btnReset {
    justify-self: end;
  }
  .buttonSearch {
    width: 100%;
    justify-items: flex-end;
  }
}
</style>