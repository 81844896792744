<template>
  <section class="containerLogin">
    <figure>
      <img src="@/assets/logo-simpliesgoto-top-branco.png" alt="Simpliesgoto" />
    </figure>
    <h1 class="titleLogin">Login</h1>

    <LoginForm>
      <span v-if="btnLogin">
        <button class="btn" @click.prevent="logInto">Entrar</button>
      </span>
    </LoginForm>
  </section>
</template>

<script>
import LoginForm from "@/components/login/LoginForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Login",
  components: { LoginForm },
  computed: {
    ...mapState(["btnLogin"]),
    ...mapGetters(["loginUserReply"]),
  },
  methods: {
    logInto() {
      // console.log(this.loginUserReply);
      this.$store.commit("UPDATE_BTNLOGIN", false);
      this.$store.dispatch("login", {
        login: this.loginUserReply,
        redirect: this.$route.query.redirect,
      });
    },
  },
};
</script>

<style scoped>
figure img {
  margin-top: 60px;
}

.containerLogin {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  width: 100%;
  margin-top: 5%;
}
.titleLogin {
  margin: 40px 0 70px 0;
  color: #ffffff;
}
</style>