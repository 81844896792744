<template>
  <section class="itemContainer" :class="disabled">
    <router-link
      class="collabItens"
      :to="{
        name: 'servico',
        params: { idService: service.id },
      }"
    >
      <span class="titleBlue">Nome</span>
      <p :class="editMode">
        <!-- <img
          class="dragDrop"
          src="@/assets/svg/hamburgerIcon.svg"
          alt="Simpliesgoto"
        /> -->
        <span v-if="editDrag" class="drag">
          <svg
            version="1.1"
            class="dragDrop"
            id="Layer_1"
            xmlns="http://www.w3.org/2000/svg"
            xmlns:xlink="http://www.w3.org/1999/xlink"
            x="0px"
            y="0px"
            viewBox="0 0 50 50"
            enable-background="new 0 0 50 50"
            xml:space="preserve"
          >
            <path
              d="M8.667,15h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,15,8.667,15z"
            />
            <path
              d="M8.667,37h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,37,8.667,37z"
            />
            <path
              d="M8.667,26h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,26,8.667,26z"
            />
          </svg>
        </span>
        {{ service.name }}
      </p>
      <span class="titleBlue">Descrição</span>
      <p>{{ service.description }}</p>
    </router-link>
    <slot name="delete"></slot>
  </section>
</template>  

<script>
import { mapState } from "vuex";
export default {
  name: "ServiceItem",
  props: ["service"],
  computed: {
    ...mapState("service", ["editDrag"]),
    editMode() {
      return this.editDrag ? "nameDragDrop" : "nothing";
    },
    disabled() {
      return this.service.active === false ? "disabled" : "nothing";
    },
  },
};
</script>

<style scoped>
.titleBlue {
  display: none;
}

.itemContainer {
  display: grid;
  grid-template-columns: minmax(250px, 1000px) 100px;
  /* grid-template-columns: minmax(250px, 1000px); */
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

.disabled {
  background: #f7dede;
}

.collabItens {
  display: grid;
  /* grid-template-columns: repeat(10, 130px); */
  /* grid-template-columns: 200px 150px 70px 90px repeat(2, 100px); */
  grid-template-columns: minmax(250px, 350px) 1fr;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

.collabItens:hover {
  background: #dbebf3;
}

.collabItens p {
  padding: 0 10px;
}

.nameDragDrop {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
}

span.drag {
  display: flex;
  align-items: center;
}
.dragDrop {
  width: 22px;
  fill: #858585;
  stroke: #858585;
}

@media screen and (max-width: 690px) {
  .titleBlue {
    display: block;
    padding: 5px 0 0 10px;
  }

  .nameDragDrop,
  .collabItens {
    grid-template-columns: 1fr;
  }
  .itemContainer {
    width: 100%;
  }
  .dragDrop {
    display: none;
  }
}
</style>
