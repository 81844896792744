<template>
  <form action="">
    <div v-if="wait">
      <Loading />
    </div>
    <div v-else>
      <div v-if="!errors.indexOf('full')" class="formItens">
        <label for="fullName"> Nome </label>
        <input
          :class="validateFull"
          id="fullName"
          name="fullName"
          type="text"
          v-model="fullName"
          placeholder=" O nome é um campo requerido"
        />
      </div>
      <div class="formItens" v-else>
        <label for="fullName"> Nome </label>
        <input
          :class="validateFull"
          id="fullName"
          name="fullName"
          type="text"
          v-model="fullName"
        />
      </div>
      <div v-if="!errors.indexOf('full') || !errors.indexOf('notAvailable')">
        <div class="formItens">
          <label for="username"> E-mail </label>
          <input
            id="username"
            name="username"
            type="email"
            v-model.trim="username"
            :class="[validateFull, validateNotAvailable]"
            placeholder="O e-mail é um campo requerido"
            autocomplete="off"
          />
        </div>
        <div v-if="!errors.indexOf('notAvailable')" class="notify">
          Este não é um e-mail válido ou não está disponível
        </div>
      </div>
      <div class="formItens" v-else>
        <label for="username"> E-mail </label>
        <input
          id="username"
          name="username"
          type="email"
          v-model.trim="username"
          :class="[validateFull, validateNotAvailable]"
          autocomplete="off"
        />
      </div>
      <div class="formItens" v-if="!errors.indexOf('full')">
        <label for="password"> Password </label>
        <input
          id="password"
          name="password"
          type="password"
          v-model="password"
          :class="validateFull"
          placeholder="Password é um campo requerido"
          autocomplete="off"
        />
      </div>
      <div v-else-if="!errors.indexOf('password')">
        <div class="formItens">
          <label for="password"> Password </label>
          <input
            id="password"
            name="password"
            type="password"
            v-model="password"
            :class="validatePassword"
            placeholder="Password é um campo requerido"
            autocomplete="off"
          />
        </div>
        <div v-if="!errors.indexOf('password')" class="notify">
          Password deve ter pelo menos seis caracteres
        </div>
      </div>
      <div class="formItens" v-else>
        <label for="password"> Password </label>
        <input
          id="password"
          name="password"
          type="password"
          v-model="password"
          :class="validateFull || validateCheckPassword"
          autocomplete="off"
        />
      </div>
      <div class="formItens">
        <label for="password"> Confirme a Password </label>
        <input
          v-if="!errors.indexOf('full')"
          id="checkPassword"
          name="checkPassword"
          type="password"
          v-model="checkPassword"
          :class="validateFull"
          placeholder="Password é um campo requerido"
          autocomplete="off"
        />
        <input
          v-else-if="!errors.indexOf('password')"
          id="checkPassword"
          name="checkPassword"
          type="password"
          v-model="checkPassword"
          :class="validatePassword"
          autocomplete="off"
        />
        <input
          v-else
          id="checkPassword"
          name="checkPassword"
          type="password"
          v-model="checkPassword"
          :class="[validateFull || validateCheckPassword]"
          autocomplete="off"
        />
        <div v-if="!errors.indexOf('checkPass')" class="notify3">
          As passwords não são iguais
        </div>
      </div>
      <div class="formItens">
        <label for="nickName"> Alcunha </label>
        <input id="nickName" name="nickName" type="text" v-model="nickName" />
      </div>
      <div class="activeForm">
        <span v-if="active === true">
          <label for="active"> Desativar Collaborador</label>
        </span>
        <span v-else>
          <label for="active"> Activar Collaborador</label>
        </span>
        <!-- <input id="active" name="active" type="text" v-model="active" /> -->
        <BtnChoice v-model="active" />
      </div>
      <div v-if="user.superUser" class="activeForm">
        <label for="superUser"> Super Usuário </label>
        <!-- <input id="superUser" name="active" type="text" v-model="active" /> -->
        <BtnChoice v-model="superUser" />
      </div>
    </div>
    <div class="button">
      <slot></slot>
    </div>
  </form>
</template>
<script>
import BtnChoice from "@/components/items/BtnChoice.vue";
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  props: ["collaborator", "errors"],
  components: {
    BtnChoice,
  },
  computed: {
    ...mapState(["wait"]),
    ...mapState("user", ["user"]),
    validateFull() {
      return this.errors.indexOf("full") ? "true" : "validate";
    },

    validateNotAvailable() {
      return this.errors.indexOf("notAvailable") ? "true" : "validate";
    },

    validatePassword() {
      return this.errors.indexOf("password") ? "true" : "validate";
    },

    validateCheckPassword() {
      return this.errors.indexOf("checkPass") ? "true" : "validate";
    },
    ...mapFields({
      fields: [
        "fullName",
        "username",
        "password",
        "nickName",
        "active",
        "superUser",
      ], //nome dos campos
      module: "collaborator", //qual módulo está acessando
      base: "collaborator", //colocar o state que vai receber
      mutation: "collaborator/UPDATE_COLLABORATOR", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["checkPassword"], //nome dos campos
      module: "collaborator", //qual módulo está acessando
      base: "checkPassword", //colocar o state que vai receber
      mutation: "collaborator/UPDATE_CHECKPASSWORD", //nome da mutation que vai alterar o state
    }),
  },
};
</script>

<style scoped>
form {
  display: grid;
  grid-template-columns: minmax(300px, 800px);
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
  padding: 0 20px;
}

.formItens {
  grid-template-columns: 100px 1fr;
}

.button {
  display: grid;
  grid-template-columns: 300px;
  padding: 20px;
  /* grid-column:1fr 1fr; */
  justify-content: center;
  margin: 20px auto;
}
.notify3 {
  grid-column: 2;
  color: #dc3545;
}
@media screen and (max-width: 560px) {
  .formItens {
    grid-template-columns: 1fr !important;
  }
  .button {
    grid-template-columns: 1fr !important;
  }
}
.notify3,
.notifyCenter {
  /* justify-items: center; */
  color: #dc3545;
  font-size: 1.2rem;
  margin-bottom: 15px;
  margin-left: 85px;
}
</style>