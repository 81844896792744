import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";

Vue.config.productionTip = false

/*##################################
#             LOADING
##################################*/
import Loading from "@/components/loading/Loading.vue";
import LoadingCircle from "@/components/loading/LoadingCircle.vue";

Vue.component("Loading", Loading);
Vue.component("LoadingCircle", LoadingCircle);

/*##################################
#             BOTÕES
##################################*/
import BtnChoice from "@/components/items/BtnChoice.vue";
import AddUser from "@/components/items/AddUser.vue";
import BtnConfig from "@/components/items/BtnConfig.vue";
import BtnSearch from "@/components/items/BtnSearch.vue";
import BtnReset from "@/components/items/BtnReset.vue";
import BtnMenu from "@/components/items/BtnMenu.vue";
import BtnFilter from "@/components/items/BtnFilter.vue";

Vue.component("BtnChoice", BtnChoice);
Vue.component("AddUser", AddUser);
Vue.component("BtnConfig", BtnConfig);
Vue.component("BtnSearch", BtnSearch);
Vue.component("BtnReset", BtnReset);
Vue.component("BtnMenu", BtnMenu);
Vue.component("BtnFilter", BtnFilter);

/*##################################
#          NOTIFICAÇÕES
##################################*/
import ErrorLogin from "@/components/notify/ErrorLogin.vue";
import NotifySuccess from "@/components/notify/NotifySuccess.vue";
import NotifyDelete from "@/components/notify/NotifyDelete.vue";
import NotifyError from "@/components/notify/NotifyError.vue";

Vue.component("ErrorLogin", ErrorLogin);
Vue.component("NotifySuccess", NotifySuccess);
Vue.component("NotifyDelete", NotifyDelete);
Vue.component("NotifyError", NotifyError);

/*##################################
#          PAGINAÇÃO
##################################*/
import Pagination from "@/components/items/Pagination.vue";
Vue.component("Pagination", Pagination);

import PaginationUrl from "@/components/items/PaginationUrl.vue";
Vue.component("PaginationUrl", PaginationUrl);

/*##################################
#       FILTRO MOEDA EURO
##################################*/
Vue.filter("numberPrice", value => {
  value = Number(value);
  if (!isNaN(value)) {
    return value.toLocaleString("IT", {
      style: "currency",
      currency: "EUR"
    });
  } else {
    return ""
  }
})

/*##################################
#   Filtro Formatando Datas
##################################*/
Vue.filter("getLastDate", (list, key) => {
  let lastDate;
  if (list != undefined || key != undefined) {
    for (let i in list) {
      if (lastDate == null || new Date(lastDate) < new Date(list[i][key])) {
        // lastDate = new Date(list[i][key]);
        lastDate = list[i][key];
      }
    }
  }
  let array = lastDate.split('-')
  let year = array[0]
  let month = array[1]
  let day = array[2]
  let finalDate = year + "-" + month + "-" + day;
  return finalDate
})

Vue.filter("formatDateReverse", date => {
  // console.log(date)
  let array = date.split('-')
  let year = array[0]
  let month = array[1]
  let day = array[2]
  let finalDate = day + "-" + month + "-" + year;
  return finalDate
})

Vue.filter("expireDate", date => {
  // console.log(date)
  let result = null;
  let today = new Date();
  let verifyDate = new Date(date);
  // console.log("today", verifyDate)
  verifyDate < today ? (result = "(expirado)") : (result = "")
  return result;
})

Vue.filter("formatDateString", date => {
  // console.log(date)
  let dateT;
  let dateNow = date.replace('00:00:00.000', '')
  if (dateNow.includes('T')) {
     dateT = dateNow.replace('T', '')
  } else {
     dateT = dateNow
  }
  // let dateT = dateNow.replace('T', '')
  let array = dateT.split('-')
  let year = array[0]
  let month = array[1]
  let day = array[2]
  let finalDate = day.trim() + "-" + month + "-" + year;
  return finalDate
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
