<template>
  <section v-if="contact">
    <h1>Contactos</h1>
    <div class="contentGeneral">
      <h3>Editar Contacto</h3>
      <nav class="navBreadcrumb" v-if="router == 'Colaborator'">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'colaborador-ativo' }"
              >Lista Ativos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'colaborador-info-ativo',
                params: { idCollaborator: idRouter },
              }"
              >Dados do Colaborador</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Editar Contacto do Colaborador</span>
          </li>
        </ol>
      </nav>
      <nav class="navBreadcrumb" v-else-if="router == 'Client'">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'clientes' }"
              >Lista de Clientes</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'cliente',
                params: { idClient: idRouter },
              }"
              >Dados do Cliente</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Editar Contacto do Cliente</span>
          </li>
        </ol>
      </nav>
      <nav class="navBreadcrumb" v-else-if="router == 'ProcessOpposite'">
        <ol class="breadcrumb">
          <slot />
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos',
                   query: { archived: 'false'} }"
              >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'intervenientes'
              }"
              >Lista de Intervenientes</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
               name: 'interveniente',
                    params: { idOpposite: idRouter },
              }"
              >Dados do Interveniente</router-link
            >
          </li>          
          <li class="breadcrumb-item-active">
            <span> Editar Contacto do Interveniente</span>
          </li>
        </ol>
      </nav>
      <!-- breadcrumb normal -->
      <nav class="navBreadcrumb" v-else>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'contactos' }"
              >Lista de Contactos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{ name: 'contacto', params: { idContact: idContact } }"
              >Dados do Contacto</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Editar Contacto</span>
          </li>
        </ol>
      </nav>
      <transition mode="out-in">
        <ContactForm :contact="contact" :errors="errors">
          <button slot="enviar" class="btn btn-form" @click.prevent="updateContact">
            Gravar
          </button>
        </ContactForm>
      </transition>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </section>
</template>

<script>
import ContactForm from "@/components/contact/ContactForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ContactAdd",
  props: ["idContact", "idRouter", "router"],

  data() {
    return {
      create: false,
    };
  },
  components: {
    ContactForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("contact", ["contact"]),
    ...mapGetters("contact", ["contactReset", "contactReply"]),
  },
  methods: {
    updateContact() {
      // console.log(this.contactReply);
      this.$store.dispatch("contact/updateContact", {
        idContact: this.idContact,
        contact: this.contactReply, //enviar getters com as infos necessárias para o cadastro
      });

      // this.$store.dispatch("collaborator/getCollaborators");
    },
  },
  created() {
    // console.log(this.idPhone);
    // this.$store.dispatch("contact/refreshContact", {
    //   idContact: this.idContact,
    // });

    // console.log(this.$route);
    this.$store.dispatch("contact/getContact", {
      idContact: this.idContact,
      refreshId: "refreshId",
    });
  },
};
</script>

<style scoped>
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
</style>
