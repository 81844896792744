<template>
  <section class="">
    <h1>Jurídico</h1>
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Dados do Tipo de Processo</h3>
        <div v-if="processAccess">
          <router-link
            class="collabItens"
            :to="{
              name: 'editar-tipo-de-processo',
              params: {
                idProcessType: idProcessType,
              },
            }"
          >
            <AddUser>
              <span slot="title">
                Editar<br />
                Tipo de Processo</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb" v-if="router == 'processo'">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos', query: { archived: 'false'} }"  >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'processo',
                params: { idProcess: idRouter },
              }"
              >Processo</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Tipo de Processo</span>
          </li>
        </ol>
      </nav>
      <nav class="navBreadcrumb" v-else>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos', query: { archived: 'false'} }"  >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'tipos-de-processos' }"
              >Lista de Tipos de Pocessos</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Tipo de Processo</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingProcess">
        <Loading />
      </div>
      <div v-else>
        <article v-if="processType">
          <ul class="borderTable">
            <li
              class="items itemList"
              v-if="processType.name && processType.name.length > 0"
            >
              <span class="titleBlue">Nome</span>
              <div class="list">
                <span class="textGray">{{ processType.name }}</span>
              </div>
            </li>
            <li
              class="items itemList"
              v-if="
                processType.description && processType.description.length > 0
              "
            >
              <span class="titleBlue">Descrição</span>
              <div class="list">
                <span class="textGray">{{ processType.description }}</span>
              </div>
            </li>
            <li
              class="items itemList"
              v-if="processType.created && processType.created.length > 0"
            >
              <span class="titleBlue">Criado</span>
              <div class="list">
                <!-- <span class="textGray">{{ createdprocessType }}</span> -->
                <span class="textGray">{{
                  processType.created | formatDateReverse
                }}</span>
              </div>
            </li>
            <li
              class="items itemList"
              v-if="processType.modified && processType.modified.length > 0"
            >
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <!-- <span class="textGray">{{ modifiedprocessType }}</span> -->
                <span class="textGray">{{
                  processType.modified | formatDateReverse
                }}</span>
              </div>
            </li>
          </ul>
        </article>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import ArchiveItem from "@/components/legal/ArchiveItem.vue";
// import { getLastDate } from "@/helpers.js";
// import { formatDate } from "@/helpers.js";
// import { alertDate } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProcessType",
  props: ["idProcessType", "idRouter", "router"],
  data() {
    return {
      searchHistory: false,
    };
  },
  components: {
    ArchiveItem,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "loadingDownloadImg",
      "selectedId",
      "loadingImg",
    ]),
    ...mapState("user", ["user"]),
    ...mapState("legal", [
      "processType",
      "loadingProcess",
      "selectedFile",
      "archiveID",
      "fileID",
      "showModalDeleteFile",
    ]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalyse",
      "processManager",
    ]),
  },
  created() {
    this.$store.dispatch("legal/getProcessType", {
      idProcessType: this.idProcessType,
    });
  },
};
</script>

<style scoped>
.proximitDate {
  color: red;
}
.validate {
  margin-left: 10px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}
/* files */
.clearFile {
  font-size: 24px;
  line-height: 28px;
  margin-left: 10px;
  font-weight: bold;
  color: #00732c;
  padding-top: 5px;
  cursor: pointer;
}
.containerFile {
  display: flex;
  align-content: center;
  align-items: baseline;
  /* grid-gap: 10px; */
  margin: 10px 0 0 0;
}
.inputNone {
  display: none;
}
.noArchive {
  margin: 20px 0 30px 0;
  margin-left: 10px;
}

.spot {
  font-size: 14px;
  color: #015eb0;
}
.list {
  /* display: grid; */
  align-content: center;
}
.inputList {
  display: grid;
}
/*INPUT FILE botão e arquivo */
.inputFile {
  cursor: pointer;
  font-size: 14px;
  width: 240px;
  border: 1px solid #015eb0;
  background: #eff7fe;
  border-radius: 9px;
  padding: 10px;
  color: #1f2b42;
  margin: 3px 10px 0 0;
  border-style: dotted;
}
/* fim files  */
.textGreen {
  margin-left: 10px;
  color: #00732c;
  font-size: 14px;
  line-height: 28px;
}
.textGreen:hover {
  text-decoration: underline;
}
.item2 {
  padding-left: 15px;
}
.addTipeVehicle {
  display: flex;
  align-items: center;
  /* margin-left: 15px; */
}
.containerLink {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.articleInfos,
.callsHistory {
  margin-top: 30px;
}

.titleInfos {
  margin-bottom: 10px;
}

.iconInfoSearchHistory {
  margin: 0 10px;
}
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .items li {
    padding: 0 0 0 10px;
  }
  .list {
    padding: 0 10px;
  }
  .inputFile {
    margin: 3px 10px 0 10px;
  }
}
@media screen and (max-width: 390px) {
  .containerFile {
    display: grid;
    grid-gap: 10px;
  }
}
</style>
