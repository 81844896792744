var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{},[_c('div',{staticClass:"containerContact"},[_c('div',{staticClass:"titleContainer"},[_c('h3',[_vm._v("Dados do Modelo")]),(_vm.vehicleManager || _vm.vehicleEdit)?_c('div',[_c('router-link',{staticClass:"collabItens",attrs:{"to":{
            name: 'editar-modelo',
            params: {
              idModel: _vm.idModel,
            },
          }}},[_c('AddUser',[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Editar"),_c('br'),_vm._v(" Modelo")])])],1)],1):_vm._e()]),_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'viaturas' }}},[_vm._v("Lista de Viaturas")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'modelos' }}},[_vm._v("Lista de Modelos")])],1),_vm._m(0)])]),(_vm.loadingVehicle)?_c('div',[_c('Loading')],1):_c('div',[(_vm.model)?_c('article',[_c('ul',{staticClass:"borderTable"},[(_vm.model.name)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Nome")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.model.name))])])]):_vm._e(),(_vm.model.name)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Marca")]),(_vm.brandName && _vm.brandName.name != undefined)?_c('router-link',{attrs:{"to":{
                  name: 'marca',
                  params: { idBrand: _vm.model.brandID },
                }}},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.brandName.name)+" "),_c('span',{staticClass:"textGreen"},[_vm._v("(mais detalhes)")])])]):_vm._e()],1):_vm._e(),(_vm.model.created)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Criado")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm._f("formatDateReverse")(_vm.model.created)))])])]):_vm._e(),(_vm.model.modified)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Modificado")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm._f("formatDateReverse")(_vm.model.modified)))])])]):_vm._e()])]):_vm._e()])]),_c('NotifySuccess',{attrs:{"items":_vm.items}}),_c('NotifyError',{attrs:{"itemsError":_vm.itemsError}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Dados do Modelo")])])
}]

export { render, staticRenderFns }