<template>
  <form action="">
    <div class="loading" v-if="loadingPermission">
      <p><Loading /></p>
    </div>
    <div class="containerPermissions" v-else>
      <article>
        <h3>Calendário</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Calendário'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
      <article>
        <h3>Cliente</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Cliente'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
      <article>
        <h3>Colaboradores</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Colaboradores'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
      <article>
        <h3>Contactos</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Contactos'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
      <!-- <article>
        <h3>Ficheiros</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Ficheiros'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
            </div>
          </div>
        </div>
      </article> -->
      <article>
        <h3>Financeiro</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Financeiro'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
      <article>
        <h3>Jurídico</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Jurídico'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
      <article>
        <h3>Moradas</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Moradas'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
      <article>
        <h3>Serviços</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Serviços'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
      <article>
        <h3>Solicitação</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Solicitação'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
      <article>
        <h3>Telecom</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Telecom'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
      <article>
        <h3>Tarefas</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Tarefas'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
      <article>
        <h3>Tracker</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Tracker'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
      <article>
        <h3>Viaturas</h3>
        <div v-for="permission in listPermissions" :key="permission.id">
          <div class="formInfos" v-if="permission.group == 'Viaturas'">
            <label for="code" class="tooltip">
              {{ permission.description }}
              <span
                v-if="
                  permission.tooltip.items &&
                  permission.tooltip.items.length > 0
                "
                class="tooltiptext"
              >
                <ul>
                  <span class="titleBlue">{{ permission.tooltip.title }}</span>
                  <li
                    v-for="tooltip in permission.tooltip.items"
                    :key="tooltip.description"
                    class="itemTooltip"
                  >
                    {{ tooltip.description }}
                  </li>
                </ul>
              </span>
            </label>
            <div @click="addPermission(permission)">
              <BtnChoice v-model="permission.value"> </BtnChoice>
              <!-- {{ permission.value }} -->
            </div>
          </div>
        </div>
      </article>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  props: ["idCollaborator"],

  computed: {
    ...mapFields({
      fields: ["permission.value"], //nome dos campos
      module: "permission", //qual módulo está acessando
      base: "listPermissions", //colocar o state que vai receber
      mutation: "permission/UPDATE_PERMISSION", //nome da mutation que vai alterar o state
    }),
    ...mapState("permission", ["permissions", "listPermissions"]),
    ...mapState(["loadingPermission"]),
    ...mapGetters("permission", ["permissionReset", "listPermissionsReset"]),
  },
  methods: {
    addPermission(permission) {
      this.$store.dispatch("permission/addPermission", {
        idCollaborator: this.idCollaborator,
        itemPermission: `${permission.code}`, //enviar getters com as infos necessárias para o cadastro
        value: `${permission.value}`,
        listPermissions: this.listPermissions,
      });
      // console.log("cadastrou", `${permission.code}`);
    },
  },
  created() {
    // console.log("abriu");
    // this.$store.commit("permission/UPDATE_PERMISSION_DELETE", {
    //   itemPermission: this.listPermissionsReset,
    //   listPermissions: this.listPermissions,
    // });

    this.$store.dispatch("permission/refreshPermissions", {
      itemPermission: this.listPermissionsReset,
      listPermissions: this.listPermissions,
    });
    // this.$store.commit(
    //   "permission/UPDATE_PERMISSION_RESET",
    //   this.listPermissionsReset
    // );
    // this.$store.dispatch("collaborator/refreshCollaborator", {
    //   idCollaborator: this.idCollaborator,
    // });
    this.$store.dispatch("permission/getPermissions", {
      idCollaborator: this.idCollaborator,
    });
  },
};
</script>

<style scoped>
.tooltip .tooltiptext {
  left: 0px;
  top: 25px;
  max-height: 400px;
  /* text-align: left; */
}

.titleBlue {
  display: flex;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

li.itemTooltip {
  font-size: 16px;
}

li.itemTooltip::before {
  content: "•";
  font-size: 1.25em;
  padding-right: 10px;
  color: #00732c;
}

.loading {
  height: 660px;
  display: flex;
  padding-top: 200px;
  justify-content: center;
}
.containerPermissions {
  /* max-width: 600px; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  /* display: flex;*/
  flex-wrap: wrap;
  justify-items: center;
  grid-gap: 5px;
  align-items: flex-start;
  margin: 20px 0 20px 0;
  max-height: 500px;
  overflow-y: auto;

  scrollbar-width: thin; /*padrão firefox*/
  scrollbar-color: #00bf48 transparent; /*padrão firefox*/
}

.containerPermissions::-webkit-scrollbar {
  width: 10px;
  background: #ecebeb;
  border-radius: 7px;
}

.containerPermissions::-webkit-scrollbar-thumb {
  background: #00bf48;
  opacity: 0.5;
  border-radius: 7px;
}

.formInfos {
  display: grid;
  grid-template-columns: 250px 100px;
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  margin: 20px 0 20px 0;
}

.button {
  display: grid;
  justify-content: flex-end;
  margin-top: 10px;
}

h3 {
  text-align: left;
}

@media screen and (max-width: 690px) {
  .containerPermissions {
    grid-template-columns: 1fr;
    justify-items: center;
  }
}
</style>