<template>
  <section v-if="processCourt">
    <h1>Jurídico</h1>
    <div class="contentGeneral">
      <h3>Adicionar Tribunal</h3>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos', query: { archived: 'false'} }"  >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'tribunais' }"
              >Lista de Tribunais</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Adicionar Tribunal</span>
          </li>
        </ol>
      </nav>
      <transition mode="out-in">
        <ProcessCourtForm :processCourt="processCourt" :errors="errors">
          <div slot="enviar">
            <button class="btn btn-form" @click.prevent="addProcessCourt">
              Adicionar
            </button>
          </div>
        </ProcessCourtForm>
      </transition>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </section>
</template>

<script>
import ProcessCourtForm from "@/components/legal/ProcessCourtForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProcessCourtAdd",
  // props: ["idProcessCourt"],
  data() {
    return {
      create: false,
    };
  },
  components: {
    ProcessCourtForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("legal", ["processCourt"]),
    ...mapGetters("legal", ["processCourtReply", "processCourtReset"]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalysis",
      "processManager",
    ]),
  },
  methods: {
    addProcessCourt() {
      // console.log(this.processCourtReply);
      this.$store.dispatch("legal/addProcessCourt", {
        processCourt: this.processCourtReply, //enviar getters com as infos necessárias para o cadastro
      });
    },
  },
  created() {
    this.$store.commit("legal/UPDATE_PROCESS_COURT", this.processCourtReset);
    this.$store.commit("RESET_ERRORS", []);
  },
};
</script>

<style scoped>
#address {
  grid-column: 2;
}
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
</style>
