import { api } from "@/services.js";
// import router from "@/router";

export default {
  namespaced: true,
  state: {
    calendarAnalysis: {
      code: true,
    },
    permissions: null,
    listPermissions: [
      //##################################################
      //                 ADDRESS  
      //##################################################     

      {
        code: "addressAccess",
        description: "Ver lista de Moradas",
        tooltip: {
          title: "Acesso à lista de Moradas",
          items: [
            { description: "O Utilizador pode ver a lista de todas as moradas," },
            {
              description: "O Utilizador pode ver a informações básicas de todas as moradas."
            },
          ],
        },
        group: "Moradas",
        value: false,
      },
      {
        code: "addressEdit",
        description: "Criar/Remover Moradas",
        tooltip: {
          title: "Permite Criar/Remover Moradas",
          items: [
            {
              description: "O Utilizador consegue adicionar moradas;"
            },
            {
              description: "O Utilizador consegue remover moradas,"
            },
            {
              description: "O Utilizador consegue alterar dados das moradas."
            }
          ],
        },
        group: "Moradas",
        value: false,
      },
      //##################################################
      //                 ATTACHMENT  
      //##################################################     
      {
        code: "attachmentAccess",
        description: "Ver lista de Ficheiros",
        tooltip: {
          title: "Acesso à lista de Ficheiros",
          items: [
            { description: "O Utilizador pode ver a lista de todos os ficheiros," },
            {
              description: "O Utilizador pode fazer download de todos os ficheiros."
            },
          ],
        },
        group: "Ficheiros",
        value: false,
      },
      {
        code: "attachmentEdit",
        description: "Criar/Remover Ficheiros",
        tooltip: {
          title: "Permite Criar/Remover Ficheiros",
          items: [
            {
              description: "O Utilizador consegue adicionar ficheiros,"
            },
            {
              description: "O Utilizador consegue remover ficheiros com menos de 15 dias."
            }
          ],
        },
        group: "Ficheiros",
        value: false,
      },
      {
        code: "attachmentManager",
        description: "Gerir Configurações dos ficheiros",
        tooltip: {
          title: "Autorização para gerir configurações dos ficheiros",
          items: [
            {
              description: "O Utilizador consegue adicionar / alterar e remover ficheiros;"
            },
            {
              description: "O Utilizador remover ficheiros gravados a mais de 15 dias,"
            }
          ],
        },
        group: "Ficheiros",
        value: false,
      },
      //##################################################
      //                 CONTACTOS  
      //##################################################   
      {
        code: "contactAccess",
        description: "Ver lista de Contactos",
        tooltip: {
          title: "Acesso à lista de contactos",
          items: [
            { description: "O Utilizador pode ver a lista de todos os contactos," },
            {
              description: "O Utilizador pode ver a informações básicas de todos os contactos, com telefone e e-mail."
            },
          ],
        },
        group: "Contactos",
        value: false,
      },
      {
        code: "contactEdit",
        description: "Criar/Editar Contactos",
        tooltip: {
          title: "Permite Criar/Editar Contactos",
          items: [
            {
              description: "O Utilizador consegue adicionar contactos;"
            },
            {
              description: "O Utilizador consegue desativar contactos,"
            },
            {
              description: "O Utilizador consegue alterar dados dos contactos."
            }
          ],
        },
        group: "Contactos",
        value: false,
      },
      //##################################################
      //                 CALENDÁRIO  
      //##################################################   
      {
        code: "calendarEdit",
        description: "Criar/Editar Agendamentos",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Calendário",
        value: false,
      },
      {
        code: "calendarAnalysis",
        description: "Ver Análises do Calendário",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Calendário",
        value: false,
      },
      {
        code: "calendarAccess",
        description: "Ver Lista do Calendário",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Calendário",
        value: false,
      },
      //##################################################
      //  TELECOM - CENTRAL TELEFÓNICA   E DE MENSAGENS
      //################################################## 
      {
        code: "telecomAnalysis",
        description: "Ver Análises das comunicações",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Telecom",
        value: false,
      },
      {
        code: "telecomAccess",
        description: "Ver Informações/Configurações das comunicações",
        tooltip: {
          title: "Acesso às configurações das comunicações",
          items: [
            {
              description: "O Utilizador consegue visualizar redirecionamento de chamadas e contactos de redirecionamento;"
            },
            {
              description: "O Utilizador consegue visualizar o histórico de chamadas;"
            },
            {
              description: "O Utilizador tem acesso á gravação de chamadas,"
            },
            {
              description: "O Utilizador consegue criar e alterar notas das chamadas."
            }
          ],
        },
        group: "Telecom",
        value: false,
      },
      {
        code: "telecomManager",
        description: "Gerir Configurações das comunicações",
        tooltip: {
          title: "Autorização para gerir configurações das comunicações",
          items: [
            {
              description: "O Utilizador consegue alterar redirecionamento de chamadas;"
            },
            {
              description: "O Utilizador consegue adicionar / alterar e remover contactos de redirecionamento;"
            },
            {
              description: "O Utilizador consegue alterar AudioUrl e CallbackUrl,"
            },
            {
              description: "Pressupõe ter acesso às configurações das comunicações."
            },
          ],
        },
        group: "Telecom",
        value: false,
      },
      //##################################################
      //                 CLIENTES  
      //################################################## 
      {
        code: "clientEdit",
        description: "Criar/Editar Cliente",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Cliente",
        value: false,
      },
      {
        code: "clientAnalysis",
        description: "Ver Análises dos Clientes",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Cliente",
        value: false,
      },
      {
        code: "clientAccess",
        description: "Ver Lista dos Clientes",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Cliente",
        value: false,
      },
      {
        code: "clientDetail",
        description: "Ver Detalhes dos Clientes",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Cliente",
        value: false,
      },
      //##################################################
      //                 COLABORADORES  
      //################################################## 
      {
        code: "staffEdit",
        description: "Criar/Editar Colaboradores",
        tooltip: {
          title: "Permite Criar/Editar Colaboradores",
          items: [
            {
              description: "O Utilizador consegue adicionar colaboradores;"
            },
            {
              description: "O Utilizador consegue desativar colaboradores,"
            },
            {
              description: "O Utilizador consegue alterar dados dos colaboradores."
            }
          ],
        },
        group: "Colaboradores",
        value: false,
      },
      {
        code: "staffAccess",
        description: "Ver Lista de Colaboradores.",
        tooltip: {
          title: "Acesso à lista de colaboradores",
          items: [
            { description: "O Utilizador pode ver a lista de todos os colaboradores," },
            {
              description: "O Utilizador pode ver a informações básicas de todos os colaboradores, com telefone, e-mail, morada e permissões."
            },
          ],
        },
        group: "Colaboradores",
        value: false,
      },
      {
        code: "staffDetail",
        description: "Ver Detalhes de Colaboradores",
        tooltip: {
          title: "Acesso avançado de informações",
          items: [
            { description: "O Utilizador pode  ver informações avançadas dos colaboradores, como histórico." },
            {
              description: "Pressupõe ter acesso à lista de colaboradores "
            },
          ],
        },
        group: "Colaboradores",
        value: false,
      },
      {
        code: "staffChangePermissions",
        description: "Alterar permissões",
        tooltip: {
          title: "Possui autorização",
          items: [{
            description: "Para dar ou remover as permissões de outros colaboradores"
          },
          {
            description: "Para dar ou remover as suas próprias permissões"
          },
          {
            description: "Pressupõe ter acesso à lista de colaboradores "
          },
          ],
        },
        group: "Colaboradores",
        value: false,
      },
      //##################################################
      //                 FINANCEIRO  
      //################################################## 
      {
        code: "financeAnalysis",
        description: "Ver Análises Finaceiras",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Financeiro",
        value: false,
      },
      {
        code: "financeManager",
        description: "Fazer Gestão Financeira",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Financeiro",
        value: false,
      },
      //##################################################
      //                 SOLICITAÇÃO  
      //################################################## 
      {
        code: "requestAnalysis",
        description: "Ver Análises das Solicitações",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Solicitação",
        value: false,
      },
      {
        code: "requestManager",
        description: "Gerir Solicitações (Chefe de equipa)",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Solicitação",
        value: false,
      },
      //##################################################
      //                 SERVIÇOS  
      //################################################## 
      {
        code: "serviceDetail",
        description: "Ver detalhes",
        tooltip: {
          title: "Acesso à lista de serviços",
          items: [
            {
              description: "O Utilizador consegue adicionar / alterar e ativar / destivar os serviços;"
            },
            {
              description: "O Utilizador consegue alterar a ordem dos serviços por prioridades"
            },
            {
              description: "Pressupõe ter autorização para gerir serviço"
            },
          ],
        },
        group: "Serviços",
        value: false,
      },
      {
        code: "serviceManager",
        description: "Gerir Serviço",
        tooltip: {
          title: "Autorização para gerir configurações dos serviços",
          items: [
            {
              description: "O Utilizador consegue adicionar / alterar e ativar / destivar os serviços;"
            },
            {
              description: "O Utilizador consegue alterar a ordem dos serviços por prioridades"
            },
            {
              description: "Pressupões ter acesso à ver os detalhes do serviço"
            },
          ],
        },
        group: "Serviços",
        value: false,
      },
      {
        code: "serviceAnalysis",
        description: "Ver Análises dos Serviços",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Serviços",
        value: false,
      },
      {
        code: "serviceMake",
        description: "Fazer Intervenções",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Serviços",
        value: false,
      },

      //##################################################
      //                 TAREFAS  
      //################################################## 
      {
        code: "taskAnalysis",
        description: "Ver análises das tarefas",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Tarefas",
        value: false,
      },
      {
        code: "taskAccess",
        description: "Ver todas as tarefas",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Tarefas",
        value: false,
      },
      {
        code: "taskManager",
        description: "Gerir Tarefas",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Tarefas",
        value: false,
      },
      //##################################################
      //                 TRACKER  
      //################################################## 
      {
        code: "trackerEdit",
        description: "Ver e gerir Tracker",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Tracker",
        value: false,
      },
      //##################################################
      //                 VIATURAS  
      //################################################## 
      {
        code: "vehicleAccess",
        description: "Ver lista das Viaturas e Ficheiros",
        tooltip: {
          title: "Acesso à lista das Viaturas e  Ficheiros",
          items: [
            { description: "O Utilizador pode ver a lista de todas as Viaturas e Ficheiros," },
            {
              description: "O Utilizador pode fazer download de todos os ficheiros."
            },
          ],
        },
        group: "Viaturas",
        value: false,
      },
      {
        code: "vehicleAnalysis",
        description: "Ver Análises das Viaturas",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Viaturas",
        value: false,
      },
      {
        code: "vehicleEdit",
        description: "Criar e editar Viaturas",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Viaturas",
        value: false,
      },
      {
        code: "vehicleManager",
        description: "Gerir Viaturas",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Viaturas",
        value: false,
      },
      //##################################################
      //                 JURÍDICO  
      //################################################## 
      {
        code: "processAccess",
        description: "Ver listas do Jurídico e Ficheiros",
        tooltip: {
          title: "Acesso à listas do Jurídico e  Ficheiros",
          items: [
            { description: "O Utilizador pode ver todas as listas do Jurídico e Ficheiros," },
            {
              description: "O Utilizador pode fazer download de todos os ficheiros."
            },
          ],
        },
        group: "Jurídico",
        value: false,
      },
      {
        code: "processEdit",
        description: "Criar e editar listas e itens do Jurídico",
        tooltip: {
          title: "Permite Criar/Editar Listas e Itens do Jurídico",
          items: [
            {
              description: "O Utilizador consegue adicionar itens no jurídico;"
            },
            {
              description: "O Utilizador consegue remover itens do jurídico,"
            },
            {
              description: "O Utilizador consegue alterar dados dos itens do jurídico."
            }
          ],
        },
        group: "Jurídico",
        value: false,
      },
      {
        code: "processManager",
        description: "Gerir Jurídico",
        tooltip: {
          title: "",
          items: [
            // {
            //   description: ""
            // }
          ],
        },
        group: "Jurídico",
        value: false,
      },
    ],
    showModalPermission: false,
    permission: {
      code: null
    },
    permissionDescrip: [],
  },
  getters: {
    permissionReply(state) {
      return {
        "code": state.permission.code,
      }
    },
    permissionReset() {
      return {
        code: null,
      }
    },
    listPermissionsReset() {
      return [
        //##################################################
        //                 ADDRESS  
        //##################################################     
  
        {
          code: "addressAccess",
          description: "Ver lista de Moradas",
          tooltip: {
            title: "Acesso à lista de Moradas",
            items: [
              { description: "O Utilizador pode ver a lista de todas as moradas," },
              {
                description: "O Utilizador pode ver a informações básicas de todas as moradas."
              },
            ],
          },
          group: "Moradas",
          value: false,
        },
        {
          code: "addressEdit",
          description: "Criar/Remover Moradas",
          tooltip: {
            title: "Permite Criar/Remover Moradas",
            items: [
              {
                description: "O Utilizador consegue adicionar moradas;"
              },
              {
                description: "O Utilizador consegue remover moradas,"
              },
              {
                description: "O Utilizador consegue alterar dados das moradas."
              }
            ],
          },
          group: "Moradas",
          value: false,
        },
        //##################################################
        //                 ATTACHMENT  
        //##################################################     
        {
          code: "attachmentAccess",
          description: "Ver lista de Ficheiros",
          tooltip: {
            title: "Acesso à lista de Ficheiros",
            items: [
              { description: "O Utilizador pode ver a lista de todos os ficheiros," },
              {
                description: "O Utilizador pode fazer download de todos os ficheiros."
              },
            ],
          },
          group: "Ficheiros",
          value: false,
        },
        {
          code: "attachmentEdit",
          description: "Criar/Remover Ficheiros",
          tooltip: {
            title: "Permite Criar/Remover Ficheiros",
            items: [
              {
                description: "O Utilizador consegue adicionar ficheiros,"
              },
              {
                description: "O Utilizador consegue remover ficheiros com menos de 15 dias."
              }
            ],
          },
          group: "Ficheiros",
          value: false,
        },
        {
          code: "attachmentManager",
          description: "Gerir Configurações dos ficheiros",
          tooltip: {
            title: "Autorização para gerir configurações dos ficheiros",
            items: [
              {
                description: "O Utilizador consegue adicionar / alterar e remover ficheiros;"
              },
              {
                description: "O Utilizador remover ficheiros gravados a mais de 15 dias,"
              }
            ],
          },
          group: "Ficheiros",
          value: false,
        },
        //##################################################
        //                 CONTACTOS  
        //##################################################   
        {
          code: "contactAccess",
          description: "Ver lista de Contactos",
          tooltip: {
            title: "Acesso à lista de contactos",
            items: [
              { description: "O Utilizador pode ver a lista de todos os contactos," },
              {
                description: "O Utilizador pode ver a informações básicas de todos os contactos, com telefone e e-mail."
              },
            ],
          },
          group: "Contactos",
          value: false,
        },
        {
          code: "contactEdit",
          description: "Criar/Editar Contactos",
          tooltip: {
            title: "Permite Criar/Editar Contactos",
            items: [
              {
                description: "O Utilizador consegue adicionar contactos;"
              },
              {
                description: "O Utilizador consegue desativar contactos,"
              },
              {
                description: "O Utilizador consegue alterar dados dos contactos."
              }
            ],
          },
          group: "Contactos",
          value: false,
        },
        //##################################################
        //                 CALENDÁRIO  
        //##################################################   
        {
          code: "calendarEdit",
          description: "Criar/Editar Agendamentos",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Calendário",
          value: false,
        },
        {
          code: "calendarAnalysis",
          description: "Ver Análises do Calendário",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Calendário",
          value: false,
        },
        {
          code: "calendarAccess",
          description: "Ver Lista do Calendário",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Calendário",
          value: false,
        },
        //##################################################
        //  TELECOM - CENTRAL TELEFÓNICA   E DE MENSAGENS
        //################################################## 
        {
          code: "telecomAnalysis",
          description: "Ver Análises das comunicações",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Telecom",
          value: false,
        },
        {
          code: "telecomAccess",
          description: "Ver Informações/Configurações das comunicações",
          tooltip: {
            title: "Acesso às configurações das comunicações",
            items: [
              {
                description: "O Utilizador consegue visualizar redirecionamento de chamadas e contactos de redirecionamento;"
              },
              {
                description: "O Utilizador consegue visualizar o histórico de chamadas;"
              },
              {
                description: "O Utilizador tem acesso á gravação de chamadas,"
              },
              {
                description: "O Utilizador consegue criar e alterar notas das chamadas."
              }
            ],
          },
          group: "Telecom",
          value: false,
        },
        {
          code: "telecomManager",
          description: "Gerir Configurações das comunicações",
          tooltip: {
            title: "Autorização para gerir configurações das comunicações",
            items: [
              {
                description: "O Utilizador consegue alterar redirecionamento de chamadas;"
              },
              {
                description: "O Utilizador consegue adicionar / alterar e remover contactos de redirecionamento;"
              },
              {
                description: "O Utilizador consegue alterar AudioUrl e CallbackUrl,"
              },
              {
                description: "Pressupõe ter acesso às configurações das comunicações."
              },
            ],
          },
          group: "Telecom",
          value: false,
        },
        //##################################################
        //                 CLIENTES  
        //################################################## 
        {
          code: "clientEdit",
          description: "Criar/Editar Cliente",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Cliente",
          value: false,
        },
        {
          code: "clientAnalysis",
          description: "Ver Análises dos Clientes",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Cliente",
          value: false,
        },
        {
          code: "clientAccess",
          description: "Ver Lista dos Clientes",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Cliente",
          value: false,
        },
        {
          code: "clientDetail",
          description: "Ver Detalhes dos Clientes",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Cliente",
          value: false,
        },
        //##################################################
        //                 COLABORADORES  
        //################################################## 
        {
          code: "staffEdit",
          description: "Criar/Editar Colaboradores",
          tooltip: {
            title: "Permite Criar/Editar Colaboradores",
            items: [
              {
                description: "O Utilizador consegue adicionar colaboradores;"
              },
              {
                description: "O Utilizador consegue desativar colaboradores,"
              },
              {
                description: "O Utilizador consegue alterar dados dos colaboradores."
              }
            ],
          },
          group: "Colaboradores",
          value: false,
        },
        {
          code: "staffAccess",
          description: "Ver Lista de Colaboradores.",
          tooltip: {
            title: "Acesso à lista de colaboradores",
            items: [
              { description: "O Utilizador pode ver a lista de todos os colaboradores," },
              {
                description: "O Utilizador pode ver a informações básicas de todos os colaboradores, com telefone, e-mail, morada e permissões."
              },
            ],
          },
          group: "Colaboradores",
          value: false,
        },
        {
          code: "staffDetail",
          description: "Ver Detalhes de Colaboradores",
          tooltip: {
            title: "Acesso avançado de informações",
            items: [
              { description: "O Utilizador pode  ver informações avançadas dos colaboradores, como histórico." },
              {
                description: "Pressupõe ter acesso à lista de colaboradores "
              },
            ],
          },
          group: "Colaboradores",
          value: false,
        },
        {
          code: "staffChangePermissions",
          description: "Alterar permissões",
          tooltip: {
            title: "Possui autorização",
            items: [{
              description: "Para dar ou remover as permissões de outros colaboradores"
            },
            {
              description: "Para dar ou remover as suas próprias permissões"
            },
            {
              description: "Pressupõe ter acesso à lista de colaboradores "
            },
            ],
          },
          group: "Colaboradores",
          value: false,
        },
        //##################################################
        //                 FINANCEIRO  
        //################################################## 
        {
          code: "financeAnalysis",
          description: "Ver Análises Finaceiras",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Financeiro",
          value: false,
        },
        {
          code: "financeManager",
          description: "Fazer Gestão Financeira",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Financeiro",
          value: false,
        },
        //##################################################
        //                 SOLICITAÇÃO  
        //################################################## 
        {
          code: "requestAnalysis",
          description: "Ver Análises das Solicitações",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Solicitação",
          value: false,
        },
        {
          code: "requestManager",
          description: "Gerir Solicitações (Chefe de equipa)",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Solicitação",
          value: false,
        },
        //##################################################
        //                 SERVIÇOS  
        //################################################## 
        {
          code: "serviceDetail",
          description: "Ver detalhes",
          tooltip: {
            title: "Acesso à lista de serviços",
            items: [
              {
                description: "O Utilizador consegue adicionar / alterar e ativar / destivar os serviços;"
              },
              {
                description: "O Utilizador consegue alterar a ordem dos serviços por prioridades"
              },
              {
                description: "Pressupõe ter autorização para gerir serviço"
              },
            ],
          },
          group: "Serviços",
          value: false,
        },
        {
          code: "serviceManager",
          description: "Gerir Serviço",
          tooltip: {
            title: "Autorização para gerir configurações dos serviços",
            items: [
              {
                description: "O Utilizador consegue adicionar / alterar e ativar / destivar os serviços;"
              },
              {
                description: "O Utilizador consegue alterar a ordem dos serviços por prioridades"
              },
              {
                description: "Pressupões ter acesso à ver os detalhes do serviço"
              },
            ],
          },
          group: "Serviços",
          value: false,
        },
        {
          code: "serviceAnalysis",
          description: "Ver Análises dos Serviços",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Serviços",
          value: false,
        },
        {
          code: "serviceMake",
          description: "Fazer Intervenções",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Serviços",
          value: false,
        },
  
        //##################################################
        //                 TAREFAS  
        //################################################## 
        {
          code: "taskAnalysis",
          description: "Ver análises das tarefas",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Tarefas",
          value: false,
        },
        {
          code: "taskAccess",
          description: "Ver todas as tarefas",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Tarefas",
          value: false,
        },
        {
          code: "taskManager",
          description: "Gerir Tarefas",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Tarefas",
          value: false,
        },
        //##################################################
        //                 TRACKER  
        //################################################## 
        {
          code: "trackerEdit",
          description: "Ver e gerir Tracker",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Tracker",
          value: false,
        },
        //##################################################
        //                 VIATURAS  
        //################################################## 
        {
          code: "vehicleAccess",
          description: "Ver lista das Viaturas e Ficheiros",
          tooltip: {
            title: "Acesso à lista das Viaturas e  Ficheiros",
            items: [
              { description: "O Utilizador pode ver a lista de todas as Viaturas e Ficheiros," },
              {
                description: "O Utilizador pode fazer download de todos os ficheiros."
              },
            ],
          },
          group: "Viaturas",
          value: false,
        },
        {
          code: "vehicleAnalysis",
          description: "Ver Análises das Viaturas",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Viaturas",
          value: false,
        },
        {
          code: "vehicleEdit",
          description: "Criar e editar Viaturas",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Viaturas",
          value: false,
        },
        {
          code: "vehicleManager",
          description: "Gerir Viaturas",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Viaturas",
          value: false,
        },
        //##################################################
        //                 JURÍDICO  
        //################################################## 
        {
          code: "processAccess",
          description: "Ver listas do Jurídico e Ficheiros",
          tooltip: {
            title: "Acesso à listas do Jurídico e  Ficheiros",
            items: [
              { description: "O Utilizador pode ver todas as listas do Jurídico e Ficheiros," },
              {
                description: "O Utilizador pode fazer download de todos os ficheiros."
              },
            ],
          },
          group: "Jurídico",
          value: false,
        },
        {
          code: "processEdit",
          description: "Criar e editar listas e itens do Jurídico",
          tooltip: {
            title: "Permite Criar/Editar Listas e Itens do Jurídico",
            items: [
              {
                description: "O Utilizador consegue adicionar itens no jurídico;"
              },
              {
                description: "O Utilizador consegue remover itens do jurídico,"
              },
              {
                description: "O Utilizador consegue alterar dados dos itens do jurídico."
              }
            ],
          },
          group: "Jurídico",
          value: false,
        },
        {
          code: "processManager",
          description: "Gerir Jurídico",
          tooltip: {
            title: "",
            items: [
              // {
              //   description: ""
              // }
            ],
          },
          group: "Jurídico",
          value: false,
        },
      ]
    }
  },
  mutations: {

    //##################################################
    //  PERMISSION - CADASTRA PERMISSÕES   
    //################################################## 
    UPDATE_PERMISSIONS(state, payload) {
      state.permissions = payload;
    },
    //##################################################
    //  PERMISSION - RESETA PERMISSÕES   
    //################################################## 
    UPDATE_PERMISSION_RESET(state, payload) {
      state.listPermissions = Object.assign(state.listPermissions, payload);
    },
    //##################################################
    //  PERMISSION - COMPARA LISTAS - ALTERA P/ TRUE   
    //################################################## 
    UPDATE_PERMISSION(state, { itemPermission, listPermissions }) {
      itemPermission.forEach((permission) => {
        var code = permission.code;
        const index = listPermissions.findIndex(
          (listPermission) => listPermission.code == code
        );
        state.listPermissions[index].value = true;
        // state.permissionDescrip.push(descricao.description);
      });
    },
    UPDATE_PERMISSION_DELETE(state, { itemPermission, listPermissions }) {
      itemPermission.forEach((permission) => {
        var code = permission.code;
        const index = listPermissions.findIndex(
          (listPermission) => listPermission.code == code
        );
        state.listPermissions[index].value = false;
        // state.permissionDescrip.push(descricao.description);
      });
    },

    //##################################################
    //  PERMISSION - COMPARA LISTAS - PUXA DESCRIÇÃO   
    //################################################## 
    UPDATE_DESC(state, { itemPermission, listPermissions }) {
      // console.log(itemPermission)
      itemPermission.forEach((permission) => {
        var code = permission.code;
        const descricao = listPermissions.find(
          (listPermission) => listPermission.code == code
        );
        state.permissionDescrip.push(descricao.description);
      });
    },
    //##################################################
    //  PERMISSION - RESETA DESCRIÇÃO   
    //################################################## 
    UPDATE_RESET_PERMISSIONSDESC(state) {
      state.permissionDescrip.splice(0);
    },
    UPDATE_DELETE_PEMISSIONS(state, permission) {
      let permissions = state.permissions.filter(c => c.code != permission)
      state.permissions = permissions;
    },
    //#################################################
    //                     MODAL
    //##################################################
    UPDATE_MODAL_PERMISSION(state, payload) {
      state.showModalPermission = payload;
    },
  },
  actions: {
    //##################################################
    //                 MODAL  
    //##################################################
    openModalPermission(context) {
      context.commit("UPDATE_PERMISSION_RESET", this.getters["permission / listPermissionsReset"]
      );
      context.commit("UPDATE_PERMISSIONS", null)
      context.commit("UPDATE_MODAL_PERMISSION", true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    closeModalPermission(context) {
      context.commit("UPDATE_RESET_PERMISSIONSDESC")
      context.commit("UPDATE_MODAL_PERMISSION", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    //##################################################
    //         COLABORADOR -  PERMISSÕES  
    //##################################################  

    //PERMISSOES - DISTRIBUI - MUDA O QUE FOI ATIVADO/DESATIVADO E PUXA DESCRIÇÃO DO QUE ESTIVER ATIVADO
    distribPermission(context, { itemPermission, listPermissions }) {
      context.commit("UPDATE_PERMISSION", { itemPermission, listPermissions });
      context.commit("UPDATE_DESC", { itemPermission, listPermissions });

      //fim puxando as descrições
    },

    //PERMISSOES - PUXANDO
    getPermissions(context, { idCollaborator }) {
      context.commit("UPDATE_LOADING_PERMISSION", true, { root: true });
      context.commit("UPDATE_PERMISSIONS", null)
      context.commit("UPDATE_RESET_PERMISSIONSDESC")
      // console.log("idCollaborator", idCollaborator)
      return api.get(`/user/${idCollaborator}/permission`)
        .then(
          permissions => {
            // console.log("premissions", permissions)
            context.commit("UPDATE_PERMISSIONS", permissions.data);
            const itemPermission = permissions.data
            const listPermissions = context.state.listPermissions
            context.dispatch("distribPermission", { itemPermission, listPermissions });
            context.commit("UPDATE_LOADING_PERMISSION", false, { root: true });
            // context.commit("UPDATE_PERMISSION", { itemPermission, listPermissions });
            // context.commit("UPDATE_DESC", { itemPermission, listPermissions });
          })
        .catch(error => {
          // console.log(error.response)
          context.commit("UPDATE_LOADING_PERMISSION", false, { root: true });
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    refreshPermissions(context, { itemPermission, listPermissions }) {
      // console.log("itemPermission", itemPermission)
      // console.log("listPermissions", listPermissions)
      context.commit("UPDATE_PERMISSION_DELETE", {
        itemPermission, listPermissions
      });
    },

    //            GET PERMISSÕES PROVISóRIO
    //##################################################
    getPermission(context, { collaborator }) {
      if (collaborator != null) {
        context.commit("UPDATE_LOADING_PERMISSION", true, { root: true });
        context.commit("UPDATE_PERMISSIONS", null)
        context.commit("UPDATE_RESET_PERMISSIONSDESC")
        // console.log("collaborator", collaborator)
        context.commit("UPDATE_PERMISSIONS", collaborator);
        const itemPermission = collaborator
        const listPermissions = context.state.listPermissions
        context.dispatch("distribPermission", { itemPermission, listPermissions });
        context.commit("UPDATE_LOADING_PERMISSION", false, { root: true });
      }
    },
    //##################################################
    //            CADASTRAR PERMISSÕES
    //##################################################
    addPermission(context, { value, itemPermission, idCollaborator }) {
      if (value == "true") {
        api.post(`/user/${idCollaborator}/permission`, { "code": itemPermission })
          .then(() => {
            // context.commit("UPDATE_PERMISSIONS", null);
            // context.dispatch("collaborator/refreshCollaborator", idCollaborator, { root: true })
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Ativado!",
              body: "Permissão ativada com sucesso!",
            }, { root: true });
          })
          .catch(error => {
            // console.log(error.response)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        // console.log({ "code": itemPermission })
        api.deleteData(`/user/${idCollaborator}/permission`, { data: { "code": itemPermission } })
          .then(() => {
            context.dispatch("success", { //cria as informações do texto do toast
              id: Date.now(),
              h1: "Desativado!",
              body: "Permissão desativada com sucesso!",
            }, { root: true });
          })
          .catch(error => {
            // console.log(error.response)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },

  }
}