import { api } from "@/services.js";
import router from "@/router";

export default {
  namespaced: true,
  state: {
    // telecomConfigs: null,   
    config: {
      redirectPhoneID: null,
    },
    configPhone: null,
    telecomPhones: null,
    telecomPhone: {
      contactName: null,
      phone: "+351",
      description: null,
    },
    //###############
    //  CALLBACK
    //###############
    configCallback: {
      callBackUrl: null,
      audioUrl: null,
    },
    callBackConfirm: false,
    // callBackSave: true,
    //###############
    //  CHAMADAS
    //###############
    calls: null,
    loadingPhoneActual: false,
    loadingVariant: false,
    showModalCreateContact: false,
    showModalDeleteContact: false,
    showModalConfigCallback: false,
    showModalNote: false,
    showModalNameContact: false,
    showModalVariant: false,
    callNote: {
      note: null,
    },
    call: null,
    startDate: {
      startDate: null,
    },
    endDate: {
      endDate: null,
    },
    fromPhone: {
      fromPhone: null,
    },
    description: {
      description: null,
    },
    note: {
      note: null,
    },
    variantPhones: null,
    variant: {
      phone: "+351",
      description: null,
      redirects: []
    },
    // active: [],
    // inactive: [],
    redirectInactive: null,
    // redirectActive: null,
  },
  getters: {
    redirectActive(state) {
      // console.log("passou aqui 1", state.variant.redirects);
      var active = [];
      for (var contact in state.variant.redirects) {
        var contactName;
        for (var phone in state.telecomPhones) {
          if (state.telecomPhones[phone].id == state.variant.redirects[contact]) {
            contactName = state.telecomPhones[phone].contactName;
          }
        }
        active.push({
          id: state.variant.redirects[contact],
          contactName: contactName,
          selected: false,
        });
      }
      return active;
    },
    redirectInactive(state) {
      // console.log("passou aqui 2", state.variant.redirects);
      var inactive = [];

      for (var telecomPhone in state.telecomPhones) {
        var isActive = false;
        for (var index in state.variant.redirects) {
          if (
            state.variant.redirects[index] == state.telecomPhones[telecomPhone].id
          ) {
            isActive = true;
          }
        }
        if (isActive == false) {
          inactive.push({
            id: state.telecomPhones[telecomPhone].id,
            contactName: state.telecomPhones[telecomPhone].contactName,
            selected: false,
          });
        }
      }
      return inactive;
    },
    //###############
    //  PAGINAÇÃO
    //###############
    paginatedData(state, getters, rootState) {
      // console.log("rootState.currentPage", rootState.currentPage)//acessando o state da global
      let start = (rootState.currentPage - 1) * rootState.limit,
        end = start + rootState.limit;
      return state.calls.slice(start, end);
    },

    //###############
    //  CONTACTO
    //###############
    telecomPhoneReply(state) {
      return {
        "contactName": state.telecomPhone.contactName,
        "phone": state.telecomPhone.phone,
        "description": state.telecomPhone.description,
      }
    },
    telecomPhoneReset() {
      return {
        contactName: null,
        phone: "+351",
        description: null,
      }
    },

    configPhoneReply(state) {
      return {
        "redirectPhoneID": state.config.redirectPhoneID,
      }
    },
    configPhoneReset() {
      return {
        redirectPhoneID: null,
      }
    },

    //###############
    //  VARIANT
    //###############

    variantReply(state) {
      return {
        "phone": state.variant.phone,
        "description": state.variant.description,
        "redirects": state.variant.redirects,
      }
    },
    variantReset() {
      return {
        phone: "+351",
        description: null,
        redirects: []
      }
    },

    redirectReset() {
      return {
        redirect: [],
      }
    },

    redirectsReset() {
      return {
        redirects: [],
      }
    },

    //###############################################
    //    CALLBACK
    //###############################################
    configCallbackReply(state) {
      return {
        "callBackUrl": state.configCallback.callBackUrl,
        "audioUrl": state.configCallback.audioUrl,
      }
    },
    configCallbackReset() {
      return {
        callBackUrl: null,
        audioUrl: null,
      }
    },


    //###############################################
    //    CALLNOTE
    //###############################################
    callNoteReply(state) {
      return {
        "note": state.callNote.note,
      }
    },
    callNoteReset() {
      return {
        note: null,
      }
    },

    //###############################################
    //    CALLS - SEARCH
    //###############################################
    startDateReply(state) {
      return state.startDate.startDate
    },
    startDateReset() {
      return {
        startDate: null,
      }
    },
    endDateReply(state) {
      return state.endDate.endDate
    },
    endDateReset() {
      return {
        endDate: null,
      }
    },
    fromPhoneReply(state) {
      return state.fromPhone.fromPhone
    },
    fromPhoneReset() {
      return {
        fromPhone: null,
      }
    },
    descriptionReply(state) {
      return state.description.description
      // { "description": state.description.description }
      // 
    },
    descriptionReset() {
      return {
        description: null,
      }
    },
    noteReply(state) {
      return state.note.note
      // { "note": state.note.note }
      // 
    },
    noteReset() {
      return {
        note: null,
      }
    },



    closeModalNameContact() {
      return this.closeModalNameContact
    }
  },
  mutations: {
    UPDATE_REDIRECTACTIVE(state, active) {
      console.log(active)
      state.active.push(active);
    },
    UPDATE_REDIRECTINACTIVE(state, inactive) {
      state.inactive.push(inactive);
    },


    UPDATE_CALL(state, payload) {
      state.call = payload;
    },
    UPDATE_CALLNOTE(state, payload) {
      state.callNote = Object.assign(state.callNote, payload);
    },
    UPDATE_TELECOM_CONFIGCALLBACK(state, payload) {
      state.configCallback = Object.assign(state.configCallback, payload);
    },
    UPDATE_TELECOM_CALLBACKCONFIRM(state, payload) {
      state.callBackConfirm = payload;
    },
    UPDATE_TELECOM_CONFIG(state, payload) {
      state.config = Object.assign(state.config, payload);
    },
    UPDATE_LOADING_TELECOMPHONEACTUAL(state, payload) {
      state.loadingPhoneActual = payload;
    },
    UPDATE_CALLS(state, payload) {
      // state.calls = payload;
      // if (payload !== null)
      if (payload != null && payload != "")
        state.calls = payload.sort((a, b) => {
          let x = a.created.toUpperCase(),
            y = b.created.toUpperCase();
          return x == y ? 0 : x < y ? 1 : -1;
        });
      else state.calls = payload;
    },

    //###############
    //  VARIANT
    //###############
    UPDATE_LOADING_VARIANT(state, payload) {
      state.loadingVariant = payload;
    },

    UPDATE_VARIANT_PHONES(state, payload) {
      // state.variantPhones = payload;
      // console.log(payload)
      if (payload.description != null && payload.description != "")
        state.variantPhones = payload.sort((a, b) => {
          let x = a.description.toUpperCase(),
            y = b.description.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else state.variantPhones = payload;
    },

    UPDATE_VARIANT(state, payload) {
      state.variant = Object.assign(state.variant, payload);
    },

    UPDATE_VARIANT_REDIRECTS(state, active) {
      for (var index in active) {
        state.variant.redirects.push(active[index].id)
      }
    },
    RESET_VARIANT_REDIRECTS(state) {
      state.variant.redirects.splice(0)
    },

    ADD_REDIRECTS(state, redirectId) {
      // console.log("phone contato", redirectId.id);
      // console.log("antes", state.redirects);
      // state.redirects = redirectId;
      state.redirects.push(redirectId);
      // state.telecomPhones
      console.log("depois", state.redirects);
      // var id = redirectId.id;
      // const nameRedirect = state.telecomPhones.find(
      //   (telecomPhone) => telecomPhone.id == id
      // );
      // state.redirects.push(nameRedirect.contactName);
      // console.log("nameRedirect", nameRedirect.contactName);
    },
    RESET_REDIRECTS(state, error) {
      state.redirects = error
    },
    //###############
    //  PAGINAÇÃO
    //###############
    UPDATE_TOTAL_PAGES(state, payload) {
      state.totalPages = payload;
    },

    /////////////////////
    UPDATE_CONFIGPHONE(state, payload) {
      state.configPhone = Object.assign(state.config, payload);
    },
    UPDATE_TELECOMPHONES(state, payload) {
      if (payload.contactName !== null || payload.contactName !== "")
        state.telecomPhones = payload.sort((a, b) => {
          let x = a.contactName.toUpperCase(),
            y = b.contactName.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else state.telecomPhones = payload
    },
    UPDATE_TELECOMPHONE(state, payload) {
      state.telecomPhone = Object.assign(state.telecomPhone, payload);
    },
    //###############################################
    //    CALLS - SEARCH
    //###############################################
    UPDATE_STARTDATE(state, payload) {
      state.startDate = payload;
    },
    UPDATE_ENDDATE(state, payload) {
      state.endDate = payload;
    },
    UPDATE_FROMPHONE(state, payload) {
      state.fromPhone = payload;
    },
    UPDATE_DESCRIPTION(state, payload) {
      state.description = payload;
    },
    UPDATE_NOTE(state, payload) {
      state.note = payload;
    },

    //###############################################
    //    DELETE
    //###############################################
    UPDATE_SELECTED_ID(state, payload) { //armazena o ID para a confirmação no modal
      state.selectedId = payload;
    },
    UPDATE_DELETE_PHONES(state, phonesId) {
      let telecomPhones = state.telecomPhones.filter(c => c.id != phonesId)
      state.telecomPhones = telecomPhones;
    },
    UPDATE_DELETE_VARIANTS(state, phonesId) {
      let variantPhones = state.variantPhones.filter(c => c.id != phonesId)
      state.variantPhones = variantPhones;
    },
    //###############################################
    //    MODAL
    //###############################################

    UPDATE_MODAL_CONTACT(state, payload) {
      state.showModalCreateContact = payload;
    },
    UPDATE_MODAL_DELETE_CONTACT(state, payload) {
      state.showModalDeleteContact = payload;
    },
    UPDATE_MODAL_NOTE(state, payload) {
      state.showModalNote = payload;
    },
    UPDATE_MODAL_CONFIG_CALLBACK(state, payload) {
      state.showModalConfigCallback = payload;
    },
    UPDATE_MODAL_NAMECONTACT(state, payload) {
      state.showModalNameContact = payload;
    },
    UPDATE_MODAL_DELETE_VARIANT(state, payload) {
      state.showModalVariant = payload;
    },


  },
  actions: {
    //############################################
    //       CALLBACK CONFIRM   
    //############################################
    configCallBackConfirm(context) {
      context.commit("UPDATE_TELECOM_CALLBACKCONFIRM", true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    closeCalbackConfirm(context) {
      context.commit("UPDATE_TELECOM_CALLBACKCONFIRM", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    //############################################
    //       MODAL - CONTACTO   
    //############################################

    // MODAL CREATE CONTACT
    openModalCreateContact(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_CONTACT", true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    closeModalCreateContact(context) {
      context.commit("UPDATE_MODAL_CONTACT", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },

    //MODAL DELETE CONTACT 
    openModalDelContact(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_CONTACT", true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    closeModalDelContact(context) {
      context.commit("UPDATE_MODAL_DELETE_CONTACT", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },


    // MODAL CONFIG CALLBACK
    openModalConfigCallback(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_CONFIG_CALLBACK", true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    closeModalConfigCallback(context) {
      context.commit("UPDATE_MODAL_CONFIG_CALLBACK", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },

    //Delete - Delete 1 de 2
    confirmDeleteContact(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelContact");//abre o modal
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },

    //MODAL DELETE VARIANTS
    openModalDelVariant(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_VARIANT", true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    closeModalDelVariant(context) {
      context.commit("UPDATE_MODAL_DELETE_VARIANT", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    confirmDeleteVariant(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelVariant");//abre o modal
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },

    //MODAL NAME CONTACT INTERNAL FORM

    openModalNameContact(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_NAMECONTACT", true);
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },
    closeModalNameContact(context) {
      context.commit("UPDATE_MODAL_NAMECONTACT", false);
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },

    // MODAL NOTE
    openModalNote(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_NOTE", true);
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },
    closeModalNote(context) {
      context.commit("UPDATE_MODAL_NOTE", false);
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },

    //######################################################################
    //    CENTRAL TELEFONICA - REDIRECIONAMENTO DE CHAMADAS
    //######################################################################

    //PUXANDO CONTATO ATUAL
    currentConfig(context) {
      // .$router.push({ name: "restrito" })

      context.commit("UPDATE_LOADING_TELECOMPHONEACTUAL", true);
      // return api.get(`/user/${idCollaborator}/phone`)
      return api.get(`/telecom/config`)
        .then(
          config => {
            context.commit("UPDATE_TELECOM_CONFIGCALLBACK", config.data);
            const idPhone = config.data.redirectPhoneID;
            context.dispatch("getConfigPhone", { idPhone });
            context.dispatch("getTelecomPhones");
            context.commit("UPDATE_LOADING_TELECOMPHONEACTUAL", false);
          })
        .catch(error => {
          // console.log(error.response.status === 403)
          context.commit("UPDATE_LOADING_TELECOMPHONEACTUAL", false);
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //######################################################################
    //    CENTRAL TELEFONICA - UPDATE REDIRECIONAMENTO
    //######################################################################

    updateRedirect(context, { idPhone }) {
      // console.log(idPhone)
      context.commit("UPDATE_LOADING_TELECOMPHONEACTUAL", true);
      api.put(`/telecom/config`, idPhone)
        .then(() => {
          context.dispatch("currentConfig")
          // context.commit("UPDATE_LOADING_TELECOMPHONEACTUAL", false);
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Novo contacto redirecionado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_TELECOM_CONFIG", this.getters["telecom/configPhoneReset"]);//limpa o formulário depois de cadastrar        
          context.commit("UPDATE_TELECOMPHONE", this.getters["telecom/telecomPhoneReset"]);//limpa o formulário depois de cadastrar        
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_TELECOMPHONEACTUAL", false);
          // console.log(error.response.data.error)
          if (error.response.data.error === "insert or update on table \"_configorm\" violates foreign key constraint \"_configorm_redirectphone_id_fkey\"" || error.response.status === 400) {
            context.commit("UPDATE_ERRORS", "redirectError", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    updateCallback(context, { callback }) {
      // console.log(context, callback)
      context.commit("UPDATE_LOADING_CALLBACK", true, { root: true });
      api.put(`/telecom/config`, callback)
        .then(() => {
          context.dispatch("currentConfig")
          context.dispatch("closeCalbackConfirm")
          context.commit("UPDATE_LOADING_CALLBACK", false, { root: true });
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Callback alterado com sucesso!",
          }, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_CALLBACK", false, { root: true });
          console.log(error.response.data.error)
          if (error.response.data.error === "missing required body" || error.response.status === 400) {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    getConfigPhone(context, { idPhone }) {
      // console.log(idPhone)
      return api.get(`/telecom/phone/${idPhone}`)
        .then(
          phones => {
            // context.commit("UPDATE_TELECOM_CONFIG", this.getters["telecom/configPhoneReset"]);//limpa o formulário depois de cadastrar   
            context.commit("UPDATE_CONFIGPHONE", phones.data);
          })
        .catch(error => {
          // console.log(router)
          // console.log(router.currentRoute.name != "restrito")
          // console.log(error.response.status === 403)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //######################################################################
    //    CENTRAL TELEFONICA - PUXANDO VARIANTES DE TELEFONES
    //######################################################################


    getVariantPhones(context) {
      context.commit("UPDATE_LOADING_VARIANT", true);
      return api.get(`/telecom/variant_phone`)
        .then(
          phones => {
            context.commit("UPDATE_VARIANT_PHONES", phones.data);
            context.commit("UPDATE_LOADING_VARIANT", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING_VARIANT", false);
          console.log(error)
          console.log(error.response.status === 403)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },


    getVariant(context, { idVariant }) {
      context.commit("UPDATE_LOADING_VARIANT", true);
      // console.log(idPhone)
      return api.get(`/telecom/variant_phone/${idVariant}`)
        .then(
          phones => {
            // context.commit("UPDATE_TELECOM_CONFIG", this.getters["telecom/configPhoneReset"]);//limpa o formulário depois de cadastrar   
            context.commit("UPDATE_VARIANT", phones.data);
            context.commit("UPDATE_LOADING_VARIANT", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING_VARIANT", false);
          // console.log(router)
          // console.log(router.currentRoute.name != "restrito")
          // console.log(error.response.status === 403)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    addVariant(context, { variant }) {
      // console.log("variant", variant.phone.length > 13 || variant.phone.length < 12)
      context.commit("UPDATE_LOADING_VARIANT", true);
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      if (variant.description == null  || variant.description == "") {
        context.commit("UPDATE_LOADING_VARIANT", false);
        context.commit("UPDATE_ERRORS", "descriptionInvalid", { root: true });
      } 
      if (variant.phone == null || variant.phone.length > 13 || variant.phone.length < 12) {
        context.commit("UPDATE_LOADING_VARIANT", false);
        context.commit("UPDATE_ERRORS", "phoneInvalid", { root: true });
      } else {
        api.post(`/telecom/variant_phone`, variant)
          .then(() => {
            // context.commit("UPDATE_TELECOMPHONES", null);
            context.dispatch("getVariantPhones");
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Redirecionamento adicionado com sucesso!",
            }, { root: true });
            context.commit("UPDATE_LOADING_VARIANT", false);
          })
          .catch(error => {
            context.commit("UPDATE_LOADING_VARIANT", false);
            // console.log(error.response.data.error)
            // console.log(error.response.data.error === 'Exception: redirects is required.')
            if (error.response.data.error === "missing required body") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === 'Exception: redirects is required.') {
              context.commit("UPDATE_ERRORS", "redirectError", { root: true });
            }
            if (error.response.data.error === 'null value in column "phone" violates not-null constraint') {
              context.commit("UPDATE_ERRORS", "phoneContact", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },

    updateVariant(context, { variant, idVariant }) {
      context.commit("UPDATE_LOADING_VARIANT", true);
      if (variant.description == null || variant.description == "") {
        context.commit("UPDATE_LOADING_VARIANT", false);
        context.commit("UPDATE_ERRORS", "descriptionInvalid", { root: true });
      } 
      if (variant.phone == null || variant.phone.length > 13 || variant.phone.length < 12) {
        context.commit("UPDATE_LOADING_VARIANT", false);
        context.commit("UPDATE_ERRORS", "phoneInvalid", { root: true });
      } else {
        api.put(`/telecom/variant_phone/${idVariant}`, variant)
          .then(() => {
            context.commit("UPDATE_TELECOMPHONE", null);
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Redirecionamento editado com sucesso!",
            }, { root: true });
            context.commit("UPDATE_TELECOMPHONE", this.getters["telecom/variantReset"]);//limpa o formulário depois de cadastrar        
            context.commit("UPDATE_LOADING_VARIANT", false);
          })
          .catch(error => {
            context.commit("UPDATE_LOADING_VARIANT", false);
            console.log(error.response.data)
            if (error.response.data.error === "missing required body") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === 'Exception: redirects is required.') {
              context.commit("UPDATE_ERRORS", "redirectError", { root: true });
            }
            if (error.response.data.error === 'null value in column "phone" violates not-null constraint') {
              context.commit("UPDATE_ERRORS", "phoneContact", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },

    deleteVariant(context, { id }) {
      context.commit("UPDATE_LOADING_VARIANT", true);
      api.delete(`/telecom/variant_phone/${id}`)
        .then(() => {
          context.commit("UPDATE_DELETE_VARIANTS", id); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Redirecionamento deletado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING_VARIANT", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_VARIANT", false);
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //######################################################################
    //    CENTRAL TELEFONICA - PUXANDO TELEFONES
    //######################################################################
    getTelecomPhones(context) {
      context.commit("UPDATE_LOADING_TELECOMPHONE", true, { root: true });
      return api.get(`/telecom/internal_phone`)
        .then(
          phones => {
            context.commit("UPDATE_TELECOMPHONES", phones.data);
            context.commit("UPDATE_LOADING_TELECOMPHONE", false, { root: true });
          })
        .catch(error => {
          context.commit("UPDATE_LOADING_TELECOMPHONE", false, { root: true });
          // console.log(error.response.status === 403)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    getTelecomPhone(context, { idPhone }) {
      context.commit("UPDATE_LOADING_TELECOMPHONE", true, { root: true });
      return api.get(`/telecom/internal_phone/${idPhone}`)
        .then(
          phones => {
            // context.commit("UPDATE_TELECOM_CONFIG", this.getters["telecom/configPhoneReset"]);//limpa o formulário depois de cadastrar   
            context.commit("UPDATE_TELECOMPHONE", phones.data);
            context.commit("UPDATE_LOADING_TELECOMPHONE", false, { root: true });
          })
        .catch(error => {
          context.commit("UPDATE_LOADING_TELECOMPHONE", false, { root: true });
          console.log(error)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //######################################################################
    //    CENTRAL TELEFONICA - DELETE CONTACTO
    //######################################################################
    deleteContact(context, { id }) {
      context.commit("UPDATE_LOADING_TELECOMPHONE", true, { root: true });
      api.delete(`/telecom/internal_phone/${id}`)
        .then(() => {
          context.commit("UPDATE_DELETE_PHONES", id); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Contacto deletado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING_TELECOMPHONE", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_TELECOMPHONE", false, { root: true });
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //######################################################################
    //    CENTRAL TELEFONICA - ADICIONAR CONTACTO
    //######################################################################
    addTelecomPhone(context, { contact }) {
      // console.log("contacto", contact)
      context.commit("UPDATE_LOADING_TELECOMPHONE", true, { root: true });
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      if (contact.phone.length > 13 || contact.phone.length < 12) {
        context.commit("UPDATE_LOADING_TELECOMPHONE", false, { root: true });
        context.commit("UPDATE_ERRORS", "phoneInvalid", { root: true });
      } else {
        api.post(`/telecom/internal_phone`, contact)
          .then(() => {
            // context.commit("UPDATE_TELECOMPHONES", null);
            context.dispatch("getTelecomPhones");
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Contacto adicionado com sucesso!",
            }, { root: true });
            context.commit("UPDATE_TELECOMPHONE", this.getters["telecom/telecomPhoneReset"]);//limpa o formulário depois de cadastrar        
            context.commit("UPDATE_LOADING_TELECOMPHONE", false, { root: true });
          })
          .catch(error => {
            context.commit("UPDATE_LOADING_TELECOMPHONE", false, { root: true });
            console.log(error.response.data)
            if (error.response.data.error === "missing required body") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === 'Exception: contactName is required.') {
              context.commit("UPDATE_ERRORS", "contactName", { root: true });
            }
            if (error.response.data.error === 'null value in column "phone" violates not-null constraint') {
              context.commit("UPDATE_ERRORS", "phoneContact", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },

    //######################################################################
    //    CENTRAL TELEFONICA - EDITAR CONTACTO
    //######################################################################
    updateTelecomPhone(context, { contact, idPhone }) {
      context.commit("UPDATE_LOADING_TELECOMPHONE", true, { root: true });
      api.put(`/telecom/internal_phone/${idPhone}`, contact)
        .then(() => {
          context.commit("UPDATE_TELECOMPHONE", null);
          context.dispatch("getTelecomPhone", { idPhone })
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Contacto editado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_TELECOMPHONE", this.getters["telecom/telecomPhoneReset"]);//limpa o formulário depois de cadastrar        
          context.commit("UPDATE_LOADING_TELECOMPHONE", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_TELECOMPHONE", false, { root: true });
          console.log(error.response.data)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //######################################################################
    //   INPUTS- COLOCAR PESQUISA NO INPUT
    //######################################################################
    inputInfos(context) {
      // console.log(router.currentRoute.query)
      if (router.currentRoute.query.startDate) {
        context.commit("UPDATE_STARTDATE", {
          startDate: router.currentRoute.query.startDate
        });
      }
      if (router.currentRoute.query.endDate) {
        context.commit("UPDATE_ENDDATE", {
          endDate: router.currentRoute.query.endDate
        });
      }
      if (router.currentRoute.query.phone) {
        context.commit("UPDATE_FROMPHONE",
          { fromPhone: router.currentRoute.query.phone }
        );
      }
      if (router.currentRoute.query.description) {
        context.commit("UPDATE_DESCRIPTION", {
          description: router.currentRoute.query.description
        });
      }
      if (router.currentRoute.query.note) {
        context.commit("UPDATE_NOTE", {
          note: router.currentRoute.query.note
        });
      }
      
    },

    //######################################################################
    //   GET - CENTRAL TELEFONICA - HISTÓRICO DE CHAMADAS
    //######################################################################
    getCalls(context, { startDate, endDate, fromPhone, description, note }) {
      // console.log(description)
      context.dispatch("inputInfos")
      // console.log("query", router)
      // console.log("query", router.currentRoute.query)
      context.commit("UPDATE_CALLS", null);
      context.commit("UPDATE_LOADING_CALLS", true, { root: true });
      var searchCalls = [
        {
          type: "startDate",
          item: startDate
        },
        {
          type: "endDate",
          item: endDate
        },
        {
          type: "fromPhone",
          item: fromPhone
        },
        {
          type: "description",
          item: description
        },
        {
          type: "note",
          item: note
        }
      ];
      let urlCalls = null
      let queryCalls = null
      for (let index in searchCalls) {
        // console.log(searchCalls[index])
        if (searchCalls[index].item != null) {
          if (urlCalls == null) {
            urlCalls = `?${searchCalls[index].type}=${searchCalls[index].item}`
            queryCalls = `${searchCalls[index].type}: "${searchCalls[index].item}"`
          }
          else {
            urlCalls = urlCalls + `&${searchCalls[index].type}=${searchCalls[index].item}`
            queryCalls = queryCalls + `, ${searchCalls[index].type}: "${searchCalls[index].item}"`
          }
        }
      }
      let urlApi = urlCalls == null ? `/telecom/call` : `/telecom/call${urlCalls}`

      return api.get(urlApi)
        .then(
          calls => {
            context.commit("UPDATE_CALLS", calls.data);
            context.commit("UPDATE_LOADING_CALLS", false, { root: true });
          })
        .catch(error => {
          context.commit("UPDATE_LOADING_CALLS", false, { root: true });
          console.log(error)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })

    },


    //######################################################################
    //    CENTRAL TELEFONICA - GET NOTE E UPDATE
    //######################################################################
    getCall(context, { idCall }) {
      // console.log(context, idCall)
      context.commit("UPDATE_LOADING_CALLNOTE", true, { root: true });
      return api.get(`/telecom/call/${idCall}`)
        .then(
          call => {
            // context.commit("UPDATE_TELECOM_CONFIG", this.getters["telecom/configPhoneReset"]);//limpa o formulário depois de cadastrar   
            context.commit("UPDATE_CALLNOTE", call.data);
            context.commit("UPDATE_CALL", call.data);
            context.commit("UPDATE_LOADING_CALLNOTE", false, { root: true });
          })
        .catch(error => {
          context.commit("UPDATE_LOADING_CALLNOTE", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    updateCallNote(context, { idCall, callback }) {
      // console.log(context, callback, idCall)
      // context.commit("UPDATE_LOADING_CALLNOTE", true, { root: true });
      api.put(`/telecom/call/${idCall}`, callback)
        .then(() => {
          // const startDate = context.state.startDate.startDate;
          // const endDate = context.state.endDate.endDate;
          // const fromPhone = context.state.endDate.fromPhone
          // context.dispatch("getCalls", { startDate, endDate, fromPhone })
          // context.commit("UPDATE_LOADING_CALLNOTE", false, { root: true });
          context.dispatch("closeModalNote")
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Nota alterada com sucesso!",
          }, { root: true });
        })
        .catch(error => {
          // context.commit("UPDATE_LOADING_CALLNOTE", false, { root: true });
          console.log(error.response.data.error)
          if (error.response.data.error === "missing required body" || error.response.status === 400) {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

  }
}