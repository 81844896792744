<template>
  <div class="btnAdd">
    <input id="addMenu" type="checkbox" name="" value="" />
    <label for="addMenu" class="circle">
      <svg
        class="icon-add"
        xmlns="http://www.w3.org/2000/svg"
        xmlns:xlink="http://www.w3.org/1999/xlink"
        aria-hidden="true"
        focusable="false"
        width="18"
        height="18"
        style="
          -ms-transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        "
        preserveAspectRatio="xMidYMid meet"
        viewBox="0 0 1536 1536"
      >
        <path
          d="M1024 768q0-106-75-181t-181-75t-181 75t-75 181t75 181t181 75t181-75t75-181zm512-109v222q0 12-8 23t-20 13l-185 28q-19 54-39 91q35 50 107 138q10 12 10 25t-9 23q-27 37-99 108t-94 71q-12 0-26-9l-138-108q-44 23-91 38q-16 136-29 186q-7 28-36 28H657q-14 0-24.5-8.5T621 1506l-28-184q-49-16-90-37l-141 107q-10 9-25 9q-14 0-25-11q-126-114-165-168q-7-10-7-23q0-12 8-23q15-21 51-66.5t54-70.5q-27-50-41-99L29 913q-13-2-21-12.5T0 877V655q0-12 8-23t19-13l186-28q14-46 39-92q-40-57-107-138q-10-12-10-24q0-10 9-23q26-36 98.5-107.5T337 135q13 0 26 10l138 107q44-23 91-38q16-136 29-186q7-28 36-28h222q14 0 24.5 8.5T915 30l28 184q49 16 90 37l142-107q9-9 24-9q13 0 25 10q129 119 165 170q7 8 7 22q0 12-8 23q-15 21-51 66.5t-54 70.5q26 50 41 98l183 28q13 2 21 12.5t8 23.5z"
        />
      </svg>
    </label>
    <label for="addMenu" class="titleAdd">
      <slot name="title" />
    </label>
    <slot name="itemsMenu" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
input {
  display: none;
}
.titleAdd {
  font-size: 12px;
}
.circle {
  background: #1f2b42;
  width: 35px;
  height: 35px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.icon-add {
  fill: #fff;
}
.icon-add:hover {
  fill: #fff;
}
.circle:hover {
  background: #28a745;
  width: 35px;
  height: 35px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.btnAdd {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}
</style>