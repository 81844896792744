import { api } from "@/services.js";
// import router from "@/router";
// import { removeAccent } from "@/helpers.js";
// import { formatDate } from "@/helpers.js";

export default {
  namespaced: true,
  state: {
    //############################
    //       ADDRESS            
    //############################
    attachments: null,
    attachment: {
      filename: null,
      creatorID: null,
      readPermissions: null,
      writePermissions: null,
      permanentDeletePermissions: null,
      history: null,
    },
    //############################
    //         LOADING            
    //############################
    loadingAttachment: false,
    //############################
    //  PESQUISA
    //############################
    searchWord: null,
    filtered: null,
    //############################
    //  MODAL            
    //############################
    showModalAttachment: false,

  },
  //########################################################################################
  //                 GETTERS            
  //########################################################################################
  getters: {
    //############################
    //          ATTACHMENT            
    //############################
    attachmentReply(state) {
      return {
        "filename": state.attachment.filename,
        "creatorID": state.attachment.creatorID,
        "readPermissions": state.attachment.readPermissions,
        "writePermissions": state.attachment.writePermissions,
        "permanentDeletePermissions": state.attachment.permanentDeletePermissions,
        "history": state.attachment.history,
      }
    },
    attachmentReset() {
      return {
        filename: null,
        creatorID: null,
        readPermissions: null,
        writePermissions: null,
        permanentDeletePermissions: null,
        history: null,
      }
    },
  },
  mutations: {

    //########################### 
    //    LOADING
    //###########################
    UPDATE_LOADING(state, payload) {
      state.loadingAttachment = payload;
    },
    //################################################################# 
    //       ATTACHMENT
    //#################################################################
    UPDATE_ATTACHMENTS(state, payload) {
      state.attachments = payload
    },
    UPDATE_ATTACHMENT(state, payload) {
      state.attachment = Object.assign(state.attachment, payload);
    },
    UPDATE_DELETE_ATTACHMENTS(state, attachmentId) {
      let attachments = state.attachments.filter(c => c.id != attachmentId)
      state.attachments = attachments;
    },
    UPDATE_MODAL_ATTACHMENT(state, payload) {
      state.showModalAttachment = payload;
    },
    UPDATE_SELECTED_ID(state, payload) {
      state.selectedId = payload;
    },
  },
  actions: {
    attachment(context, { data, attachmentID, urlDelete, mutationDel, id }) {
      // console.log("data", id != undefined)
      // console.log("attachmentID", attachmentID)
      context.commit("UPDATE_LOADING_IMG", true, { root: true })
      context.commit("RESET_ERRORS", [], { root: true });
      api.post(`/attachment/${attachmentID}`, data)
        .then(() => {
          // console.log("gravou", response)
          context.commit("UPDATE_SELECTEDFILE", null, { root: true });
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Ficheiro adicionado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING_IMG", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_IMG", false, { root: true });
          // console.log(error.response.status)
          if (error.response.status === 413) {
            api.delete(urlDelete)
              .then(() => {
                console.log("delete ok")
                if (id != undefined) {
                  console.log("dentro", { id, attachmentID })
                  context.commit(eval(mutationDel), { id, attachmentID }, { root: true }); //atualiza 
                } else {
                  context.commit(eval(mutationDel), attachmentID, { root: true }); //atualiza 
                }
              })
              .catch(error => {
                console.log(error.response)
                context.commit("UPDATE_LOADING", false);
                if (error.response.status) {
                  const status = error.response.status
                  context.dispatch("errorArchiveStatus", status, { root: true })
                }
              })
          }
          const status = error.response.status
          context.dispatch("errorArchiveStatus", status, { root: true })
        })
    },
    getFile(context, { archiveID, fileName }) {
      // console.log(archiveID);
      // console.log(fileName);
      context.commit("UPDATE_LOADING_DOWNLOADIMG", true, { root: true })
      api.getFile(`/attachment/${archiveID}`)
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement('a');
          link.href = url;
          link.setAttribute('download', fileName);
          document.body.appendChild(link);
          link.click();
          context.commit("UPDATE_LOADING_DOWNLOADIMG", false, { root: true })
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_DOWNLOADIMG", false, { root: true })
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorArchiveStatus", status, { root: true })
        })
    },
  }

}