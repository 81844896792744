<template>
  <div class="ripple"></div>
</template>

<script>
export default {
  name: "LoadingCircle",
};
</script>

<style scoped>
.loader-container {
  display: grid;
  place-items: center;
}

.ripple {
  width: 2rem;
  height: 2rem;
  /* margin: 0 auto; */
  border-radius: 50%;
  border: 0.3rem solid#01ad43;
  transform: translate(50%);
  animation: 1s ripple ease-out infinite;
}

@keyframes ripple {
  from {
    transform: scale(0);
    opacity: 1;
  }

  to {
    transform: scale(1);
    opacity: 0;
  }
}
</style>
