<template>
  <section>
    <h1>Viaturas</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Lista de Tipos de Viaturas</h3>
        <div class="menuDropdown" v-if="vehicleEdit || vehicleManager">
          <router-link
            class="collabItens"
            :to="{
              name: 'adicionar-tipo-viatura',
            }"
          >
            <AddUser>
              <span slot="title">
                Adicionar<br />
                Tipo de Viatura</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Lista de Tipos de Viaturas</span>
          </li>
        </ol>
      </nav>
      <article>
        <div v-if="loadingVehicle">
          <Loading />
        </div>
        <div v-else>
          <div v-if="typeVehicles  && typeVehicles.length > 0">
            <!-- {{ typeVehicles }} -->
            <TypeVehicleSearch />
            <div class="titleCollab">
              <p class="titleBlue">Nome</p>
              <p class="titleBlue">Descrição</p>
            </div>
            <hr />
            <ul>
              <li
                class="itemList"
                v-for="typeVehicle in filteredTypeVehicles"
                :key="typeVehicle.id"
              >
                <TypeVehicleItem
                  :typeVehicle="typeVehicle"
                  :idTypeVehicle="typeVehicle.id"
                >
                  <button
                    slot="delete"
                    class="delete"
                    @click.prevent="confirmDeleteTypeVehicle(typeVehicle.id)"
                  ></button>
                </TypeVehicleItem>
              </li>
            </ul>
          </div>
          <div v-else>
            <p class="noResults">Não possui tipos de viaturas registradas</p>
          </div>
        </div>
      </article>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteTypeVehicle"
        @click="closeModalDelTypeVehicle"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteTypeVehicle">
        <button class="close btn" @click="closeModalDelTypeVehicle">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover esta tipo de viatura?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelTypeVehicle"
          />
          <button class="btnDel" @click.prevent="deleteTypeVehicle">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import TypeVehicleSearch from "@/components/vehicle/TypeVehicleSearch.vue";
import TypeVehicleItem from "@/components/vehicle/TypeVehicleItem.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddContact",
  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId"]),
    ...mapState("user", ["user"]),
    ...mapState("vehicle", [
      "typeVehicles",
      "loadingVehicle",
      "showModalDeleteTypeVehicle",
    ]),
    ...mapGetters("user", [
      "vehicleAccess",
      "vehicleManager",
      "vehicleAnalysis",
      "vehicleEdit",
    ]),
    ...mapGetters("vehicle", ["getFiltered"]),
    filteredTypeVehicles() {
      const a = this.getFiltered || this.typeVehicles;
      return a;
    },
  },
  components: {
    TypeVehicleItem,
    TypeVehicleSearch,
  },
  methods: {
    openModalDelTypeVehicle() {
      this.$store.dispatch("vehicle/openModalDelTypeVehicle");
    },
    closeModalDelTypeVehicle() {
      this.$store.dispatch("vehicle/closeModalDelTypeVehicle");
    },
    //######################################################
    //     DELETE
    //######################################################
    confirmDeleteTypeVehicle(id) {
      this.$store.dispatch("vehicle/confirmDeleteTypeVehicle", {
        id: `${id}`,
      });
    },
    deleteTypeVehicle() {
      this.$store.dispatch("vehicle/deleteTypeVehicle", {
        id: this.selectedId.id,
      });
      this.closeModalDelTypeVehicle();
    },
  },
  created() {
    this.$store.dispatch("vehicle/getTypeVehicles");
  },
};
</script>

<style scoped>
.modal_container {
  max-width: 450px;
}
.titleContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}

.titleCollab {
  display: grid;
  /* grid-template-columns: repeat(10, 130px); */
  /* grid-template-columns: 200px 150px 70px 90px repeat(2, 100px); */
  /* grid-template-columns: 350px 380px; */
  grid-template-columns: 250px minmax(200px, 380px);
  /* grid-gap: 5px; */
}

.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  min-width: 350px;
  max-width: 1000px;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  /* margin-bottom: 30px; */
}

.titleBlue {
  margin: 0 5px;
}
h3 {
  text-align: initial;
  margin-left: 10px;
}
@media screen and (max-width: 695px) {
  .titleBlue {
    display: none;
  }
  .containerTelecom {
    margin: 80px 10px 20px 10px;
  }
}
</style>
