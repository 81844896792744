<template>
  <form action="">
    <div class="formInfos">
      <div v-if="loadingVariant">
        <p><Loading /></p>
      </div>
      <div class="formInfos" v-else>
        <label for="description"> Descrição: </label>
        <input
          id="description"
          name="description"
          type="text"
          v-model="description"
          autocomplete="off"
          :class="[validateFull, validateDescriptionInvalid]"
        />
        <div v-if="!errors.indexOf('descriptionInvalid')" class="notifyCenter">
          A descrição é um campo requerido.
        </div>
        <label for="phone"> Telefone: </label>
        <input
          id="phone"
          name="phone"
          type="text"
          v-model.trim="phone"
          autocomplete="off"
          :class="[validateFull, validatePhone, validatePhoneInvalid]"
        />
        <div v-if="!errors.indexOf('phoneContact')" class="notifyCenter">
          O Telefone é um campo requerido.
        </div>
        <div v-if="!errors.indexOf('phoneInvalid')" class="notifyCenter">
          O Telefone precisa ser preenchido corretamente.
        </div>
        <label for="contactName"> Redirecionamentos: </label>
        <!-- {{ telecomPhones }} -->
        <div v-if="loadingVariant">
          <LoadingCircle />
        </div>
        <div v-else>
          <div v-if="redirectActive && redirectInactive">
            <DualListBox
              :source="redirectActive"
              :destination="redirectInactive"
              @onChangeList="onChangeList"
              :errors="errors"
            />
          </div>
        </div>
        <div v-if="!errors.indexOf('redirectError')" class="notifyCenter">
          O Contacto de Redirecionamento é um campo requerido.
        </div>
        <!-- @onChangeList="onChangeList" -->

        <div class="button">
          <slot></slot>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
// import VariantRedirectItem from "@/components/telecom/VariantRedirectItem.vue";
import DualListBox from "@/components/items/DualListBox.vue";
import { mapFields } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "VariantForm",
  props: ["variantPhone", "telecomPhones", "errors"],
  components: {
    // VariantRedirectItem,
    DualListBox,
  },
  data() {
    return {
      contactName: [],
    };
  },
  mounted() {},
  computed: {
    validateFull() {
      return this.errors.indexOf("full") ? "true" : "validate";
    },
    validateRedirect() {
      return this.errors.indexOf("redirectError") ? "true" : "validate";
    },
    validatePhone() {
      return this.errors.indexOf("phoneContact") ? "true" : "validate";
    },    
    validateDescriptionInvalid() {
      return this.errors.indexOf("descriptionInvalid") ? "true" : "validate";
    },
    validatePhoneInvalid() {
      return this.errors.indexOf("phoneInvalid") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["description", "phone", "redirects"], //nome dos campos
      module: "telecom", //qual módulo está acessando
      base: "variant", //colocar o state que vai receber
      mutation: "telecom/UPDATE_VARIANT", //nome da mutation que vai alterar o state
    }),
    ...mapState(["loadingVariant"]),
    ...mapState("telecom", ["loadingVariant", "variant", "redirectName"]),
    ...mapGetters("telecom", ["redirectActive", "redirectInactive"]),
  },

  methods: {
    onChangeList: function (active) {
      this.$store.commit("telecom/RESET_VARIANT_REDIRECTS");
      this.$store.commit("telecom/UPDATE_VARIANT_REDIRECTS", active);
      // this.redirectFinal = active;
    },
  },
  created() {
    // this.$store.commit("telecom/UPDATE_ACTIVE_REDIRECTS", {
    //   active: this.redirectActive,
    // });
  },
};
</script>

<style scoped>
.formInfos {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-top: 20px; */
  align-content: center;
  max-width: 500px;
}
input.validate {
  margin: 0;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
@media screen and (max-width: 690px) {
  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: auto;
    width: 100%;
  }
  label,
  input {
    width: 100%;
    justify-items: flex-start;
  }
}
</style>