<template>
  <section class="">
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Dados da Viatura</h3>
        <div v-if="vehicleManager || vehicleEdit">
          <router-link
            class="collabItens"
            :to="{
              name: 'editar-viatura',
              params: {
                idVehicle: idVehicle,
              },
            }"
          >
            <AddUser>
              <span slot="title">
                Editar<br />
                Viatura</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Dados da Viatura</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingVehicle">
        <Loading />
      </div>
      <div v-else>
        <article v-if="vehicle">
          <ul class="borderTable">
            <li
              class="items itemList"
              v-if="vehicle.registration && vehicle.registration.length > 0"
            >
              <span class="titleBlue">Matrícula</span>
              <div class="list">
                <span class="textGray">{{ vehicle.registration }}</span>
              </div>
            </li>
            <li
              class="items itemList"
              v-if="
                vehicle.nextInspectionDate &&
                vehicle.nextInspectionDate.length > 0
              "
            >
              <span class="titleBlue">Próxima Inspeção</span>
              <div class="list">
                <!-- {{ vehicle.nextInspectionDate }} -->
                <span class="textGray" :class="validateNextRevison">
                  {{ vehicle.nextInspectionDate | formatDateReverse }}
                </span>
                <!-- <span class="validate textGray" :class="validateNextRevison">
                  {{ vehicle.nextInspectionDate | expireDate }}
                </span> -->
                <span v-if="expireNextRevision" class="validate textGray"
                  >(expirado)</span
                >
              </div>
            </li>

            <li class="items itemList">
              <span class="titleBlue">Tipo de Viatura</span>

              <div class="list">
                <router-link
                  v-if="vehicleTypeName && vehicleTypeName.name != undefined"
                  :to="{
                    name: 'tipo-viatura',
                    params: { idTypeVehicle: vehicle.vehicleTypeID },
                  }"
                >
                  <span class="textGray"
                    >{{ vehicleTypeName.name }}
                    <span class="textGreen">(mais detalhes)</span></span
                  >
                </router-link>
                <span v-else>
                  <router-link
                    v-if="vehicleManager || vehicleEdit"
                    class="addTipeVehicle"
                    :to="{
                      name: 'editar-viatura',
                      params: {
                        idVehicle: idVehicle,
                      },
                    }"
                  >
                    <span class="textGray">Adicionar </span>
                    <svg
                      version="1.1"
                      id="noteAdd"
                      width="40"
                      height="38"
                      viewBox="4.5 0 19 24"
                    >
                      <path
                        d="M13.5,20 C14.3284271,20 15,19.3284271 15,18.5 C15,17.1192881 16.1192881,16 17.5,16 C18.3284271,16 19,15.3284271 19,14.5 L19,11.5 C19,11.2238576 19.2238576,11 19.5,11 C19.7761424,11 20,11.2238576 20,11.5 L20,14.5 C20,18.0898509 17.0898509,21 13.5,21 L6.5,21 C5.11928813,21 4,19.8807119 4,18.5 L4,5.5 C4,4.11928813 5.11928813,3 6.5,3 L12.5,3 C12.7761424,3 13,3.22385763 13,3.5 C13,3.77614237 12.7761424,4 12.5,4 L6.5,4 C5.67157288,4 5,4.67157288 5,5.5 L5,18.5 C5,19.3284271 5.67157288,20 6.5,20 L13.5,20 L13.5,20 Z M15.7913481,19.5014408 C16.9873685,18.9526013 17.9526013,17.9873685 18.5014408,16.7913481 C18.1948298,16.9255432 17.8561101,17 17.5,17 C16.6715729,17 16,17.6715729 16,18.5 C16,18.8561101 15.9255432,19.1948298 15.7913481,19.5014408 L15.7913481,19.5014408 Z M18,6 L20.5,6 C20.7761424,6 21,6.22385763 21,6.5 C21,6.77614237 20.7761424,7 20.5,7 L18,7 L18,9.5 C18,9.77614237 17.7761424,10 17.5,10 C17.2238576,10 17,9.77614237 17,9.5 L17,7 L14.5,7 C14.2238576,7 14,6.77614237 14,6.5 C14,6.22385763 14.2238576,6 14.5,6 L17,6 L17,3.5 C17,3.22385763 17.2238576,3 17.5,3 C17.7761424,3 18,3.22385763 18,3.5 L18,6 Z M8.5,9 C8.22385763,9 8,8.77614237 8,8.5 C8,8.22385763 8.22385763,8 8.5,8 L12.5,8 C12.7761424,8 13,8.22385763 13,8.5 C13,8.77614237 12.7761424,9 12.5,9 L8.5,9 Z M8.5,12 C8.22385763,12 8,11.7761424 8,11.5 C8,11.2238576 8.22385763,11 8.5,11 L15.5,11 C15.7761424,11 16,11.2238576 16,11.5 C16,11.7761424 15.7761424,12 15.5,12 L8.5,12 Z M8.5,15 C8.22385763,15 8,14.7761424 8,14.5 C8,14.2238576 8.22385763,14 8.5,14 L13.5,14 C13.7761424,14 14,14.2238576 14,14.5 C14,14.7761424 13.7761424,15 13.5,15 L8.5,15 Z"
                      />
                    </svg>
                  </router-link>
                </span>
              </div>
            </li>
            <li class="items itemList" v-if="modelName && modelName.name != undefined">
              <span class="titleBlue">Modelo da Viatura</span>

              <div class="list">
                <router-link
                  v-if="modelName && modelName.name != undefined"
                  :to="{
                    name: 'modelo',
                    params: { idModel: vehicle.modelID },
                  }"
                >
                  <span class="textGray"
                    >{{ modelName.name }}
                    <span class="textGreen">(mais detalhes)</span></span
                  >
                </router-link>                
              </div>
            </li>
             <li class="items itemList" v-if="brandsName && brandsName.name != undefined">
              <span class="titleBlue">Marca da Viatura</span>

              <div class="list">
                <router-link
                  v-if="brandsName && brandsName.name != undefined"
                  :to="{
                    name: 'marca',
                    params: { idBrand: brandsName.id },
                  }"
                >
                  <span class="textGray"
                    >{{ brandsName.name }}
                    <span class="textGreen">(mais detalhes)</span></span
                  >
                </router-link>                
              </div>
            </li>
            <li
              class="items itemList"
              v-if="vehicle.created && vehicle.created.length > 0"
            >
              <span class="titleBlue">Criado</span>
              <div class="list">
                <!-- <span class="textGray">{{ createdVehicle }}</span> -->
                <span class="textGray">{{
                  vehicle.created | formatDateReverse
                }}</span>
              </div>
            </li>
            <li
              class="items itemList"
              v-if="vehicle.modified && vehicle.modified.length > 0"
            >
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <!-- <span class="textGray">{{ modifiedVehicle }}</span> -->
                <span class="textGray">{{
                  vehicle.modified | formatDateReverse
                }}</span>
              </div>
            </li>
            <li class="items containerFile">
              <span class="titleBlue">Ficheiros</span>

              <div v-if="loadingImg">
                <LoadingCircle />
              </div>

              <div v-else>
                <div v-if="vehicleEdit || vehicleManager" class="containerFile">
                  <div class="inputList">
                    <label for="fileInput" class="inputFile"
                      >Selecione um ficheiro
                      <span class="spot">clique aqui</span></label
                    >
                    <span v-if="selectedFile != null" class="itemFile"
                      >{{ selectedFile.name
                      }}<span
                        @click.prevent="cleanFile($event)"
                        class="clearFile"
                        >&#215;</span
                      ></span
                    >
                    <input
                      type="file"
                      @change="onFileSelected"
                      id="fileInput"
                      class="inputNone"
                    />
                  </div>
                  <button class="btn addFile" @click="onUpload(vehicle.id)">
                    Adicionar
                  </button>
                </div>
              </div>
            </li>
            <article>
              <div v-if="loadingImg"></div>
              <div v-else>
                <div v-if="vehicle.files != null && vehicle.files.length > 0">
                  <article class="downloadImgLoading" v-if="loadingDownloadImg">
                    Aguarde, fazendo download...
                  </article>
                  <ul v-else>
                    <li v-for="archive in vehicle.files" :key="archive.id">
                      <!-- {{ archive }} -->
                      <article class="articleInfos">
                        <!-- <hr /> -->
                        <ArchiveItem
                          :archive="archive"
                          :idMicroservice="vehicle.id"
                          @getFile="getFile(archive.filename, archive.id)"
                        >
                          <button
                            slot="delete"
                            class="delete"
                            @click.prevent="
                              confirmDeleteFileVehicle(archive.id)
                            "
                          ></button>
                        </ArchiveItem>
                      </article>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <p class="noResults noArchive">
                    Não possui ficheiros registrados
                  </p>
                </div>
              </div>
            </article>
          </ul>
          <!--################################################# 
          //              INSPECTIONS - VEHICLES 
          ##################################################-->
          <article class="articleInfos">
            <h3 class="titleInfos">Inspeções</h3>
            <div v-if="vehicle.inspections && vehicle.inspections.length > 0">
              <span
                v-for="(inspection, i) in vehicle.inspections"
                :key="i"
                class="textGray"
              >
                <ul v-if="i === 0">
                  <li class="items itemList">
                    <span class="titleBlue">Data da Inspeção</span>
                    <div class="list">
                      <!-- <span :class="validateRevison">{{ revisionDate }}</span> -->
                      <!-- {{
                        vehicle.inspections
                          | getLastDate("date")
                          | formatDateReverse
                      }} -->
                      <span>{{
                        vehicle.inspections
                          | getLastDate("date")
                          | formatDateReverse
                      }}</span>

                      <!-- <span v-if="expireRevision" class="validate"
                        >(expirado)</span
                      > -->
                    </div>
                  </li>
                  <li class="items itemList">
                    <span class="titleBlue">Km</span>
                    <div class="list">
                      <span>{{ inspection.km }}</span>
                    </div>
                  </li>
                  <li class="items itemList">
                    <span class="titleBlue">Notas</span>
                    <div class="list">
                      <span>{{ inspection.description }}</span>
                    </div>
                  </li>
                  <li class="items itemList">
                    <span class="titleBlue">Custos</span>
                    <div class="list">
                      <span>{{ inspection.costs | numberPrice }}</span>
                    </div>
                  </li>
                </ul>
              </span>
            </div>
            <div class="articleInfos">
              <div v-if="vehicle.inspections && vehicle.inspections.length > 0">
                <router-link
                  class="containerLink"
                  :to="{
                    name: 'inspecoes-viatura',
                    params: { idVehicle: idVehicle },
                  }"
                >
                  <div class="linkGrey">Ver todas as inspeções</div>

                  <svg
                    version="1.1"
                    id="note"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 104.69 122.88"
                    style="enable-background: new 0 0 104.69 122.88"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        d="M31.54,86.92c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15H55c1.74,0,3.15,1.41,3.15,3.15 c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,86.92z M31.54,42.27c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,42.27z M7.33,0h90.02c2.02,0,3.85,0.82,5.18,2.15 c1.33,1.33,2.15,3.16,2.15,5.18v108.21c0,2.02-0.82,3.85-2.15,5.18c-1.33,1.33-3.16,2.15-5.18,2.15H7.33 c-2.02,0-3.85-0.82-5.18-2.15C0.82,119.4,0,117.57,0,115.55V7.33c0-2.02,0.82-3.85,2.15-5.18C3.48,0.82,5.31,0,7.33,0L7.33,0z M97.36,6.3H7.33c-0.28,0-0.54,0.12-0.73,0.3C6.42,6.8,6.3,7.05,6.3,7.33v108.21c0,0.28,0.12,0.54,0.31,0.72 c0.19,0.19,0.45,0.31,0.73,0.31h90.02c0.28,0,0.54-0.12,0.72-0.31c0.19-0.19,0.31-0.45,0.31-0.72V7.33c0-0.28-0.12-0.54-0.31-0.73 C97.89,6.42,97.64,6.3,97.36,6.3L97.36,6.3z M31.54,64.59c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,64.59z"
                      />
                    </g>
                  </svg>
                </router-link>
              </div>
              <div v-else class="">
                <router-link
                  v-if="vehicleManager || vehicleEdit"
                  class="containerLink"
                  :to="{
                    name: 'adicionar-inspecao',
                    params: {
                      idVehicle: idVehicle,
                    },
                  }"
                >
                  <div class="linkGrey">Adicionar Inspeção</div>

                  <svg
                    version="1.1"
                    id="noteAdd"
                    width="40"
                    height="38"
                    viewBox="4.5 0 19 24"
                  >
                    <path
                      d="M13.5,20 C14.3284271,20 15,19.3284271 15,18.5 C15,17.1192881 16.1192881,16 17.5,16 C18.3284271,16 19,15.3284271 19,14.5 L19,11.5 C19,11.2238576 19.2238576,11 19.5,11 C19.7761424,11 20,11.2238576 20,11.5 L20,14.5 C20,18.0898509 17.0898509,21 13.5,21 L6.5,21 C5.11928813,21 4,19.8807119 4,18.5 L4,5.5 C4,4.11928813 5.11928813,3 6.5,3 L12.5,3 C12.7761424,3 13,3.22385763 13,3.5 C13,3.77614237 12.7761424,4 12.5,4 L6.5,4 C5.67157288,4 5,4.67157288 5,5.5 L5,18.5 C5,19.3284271 5.67157288,20 6.5,20 L13.5,20 L13.5,20 Z M15.7913481,19.5014408 C16.9873685,18.9526013 17.9526013,17.9873685 18.5014408,16.7913481 C18.1948298,16.9255432 17.8561101,17 17.5,17 C16.6715729,17 16,17.6715729 16,18.5 C16,18.8561101 15.9255432,19.1948298 15.7913481,19.5014408 L15.7913481,19.5014408 Z M18,6 L20.5,6 C20.7761424,6 21,6.22385763 21,6.5 C21,6.77614237 20.7761424,7 20.5,7 L18,7 L18,9.5 C18,9.77614237 17.7761424,10 17.5,10 C17.2238576,10 17,9.77614237 17,9.5 L17,7 L14.5,7 C14.2238576,7 14,6.77614237 14,6.5 C14,6.22385763 14.2238576,6 14.5,6 L17,6 L17,3.5 C17,3.22385763 17.2238576,3 17.5,3 C17.7761424,3 18,3.22385763 18,3.5 L18,6 Z M8.5,9 C8.22385763,9 8,8.77614237 8,8.5 C8,8.22385763 8.22385763,8 8.5,8 L12.5,8 C12.7761424,8 13,8.22385763 13,8.5 C13,8.77614237 12.7761424,9 12.5,9 L8.5,9 Z M8.5,12 C8.22385763,12 8,11.7761424 8,11.5 C8,11.2238576 8.22385763,11 8.5,11 L15.5,11 C15.7761424,11 16,11.2238576 16,11.5 C16,11.7761424 15.7761424,12 15.5,12 L8.5,12 Z M8.5,15 C8.22385763,15 8,14.7761424 8,14.5 C8,14.2238576 8.22385763,14 8.5,14 L13.5,14 C13.7761424,14 14,14.2238576 14,14.5 C14,14.7761424 13.7761424,15 13.5,15 L8.5,15 Z"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </article>
          <!--################################################# 
          //              REVISION - VEHICLES 
          ##################################################-->
          <article class="articleInfos">
            <h3 class="titleInfos">Revisões</h3>
            <div v-if="vehicle.revisions && vehicle.revisions.length > 0">
              <span
                v-for="(revision, i) in vehicle.revisions"
                :key="i"
                class="textGray"
              >
                <ul v-if="i === 0">
                  <li class="items itemList">
                    <span class="titleBlue">Data da Revisão</span>
                    <div class="list">
                      <!-- <span :class="validateRevison">{{ revisionDate }}</span> -->
                      <span>{{
                        vehicle.revisions
                          | getLastDate("date")
                          | formatDateReverse
                      }}</span>
                      <!-- <span class="validate">{{
                        vehicle.revisions | getLastDate("date") | expireDate
                      }}</span> -->
                      <!-- <span v-if="expireRevision" class="validate"
                        >(expirado)</span
                      > -->
                    </div>
                  </li>
                  <li class="items itemList">
                    <span class="titleBlue">Km</span>
                    <div class="list">
                      <span>{{ revision.km }}</span>
                    </div>
                  </li>
                  <li class="items itemList">
                    <span class="titleBlue">Notas</span>
                    <div class="list">
                      <span>{{ revision.description }}</span>
                    </div>
                  </li>
                  <li class="items itemList">
                    <span class="titleBlue">Custos</span>
                    <div class="list">
                      <span>{{ revision.costs | numberPrice }}</span>
                    </div>
                  </li>
                </ul>
              </span>
            </div>
            <div class="articleInfos">
              <div v-if="vehicle.revisions && vehicle.revisions.length > 0">
                <router-link
                  class="containerLink"
                  :to="{
                    name: 'revisoes-viatura',
                    params: { idVehicle: idVehicle },
                  }"
                >
                  <div class="linkGrey">Ver todas as revisões</div>

                  <svg
                    version="1.1"
                    id="note"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 104.69 122.88"
                    style="enable-background: new 0 0 104.69 122.88"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        d="M31.54,86.92c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15H55c1.74,0,3.15,1.41,3.15,3.15 c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,86.92z M31.54,42.27c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,42.27z M7.33,0h90.02c2.02,0,3.85,0.82,5.18,2.15 c1.33,1.33,2.15,3.16,2.15,5.18v108.21c0,2.02-0.82,3.85-2.15,5.18c-1.33,1.33-3.16,2.15-5.18,2.15H7.33 c-2.02,0-3.85-0.82-5.18-2.15C0.82,119.4,0,117.57,0,115.55V7.33c0-2.02,0.82-3.85,2.15-5.18C3.48,0.82,5.31,0,7.33,0L7.33,0z M97.36,6.3H7.33c-0.28,0-0.54,0.12-0.73,0.3C6.42,6.8,6.3,7.05,6.3,7.33v108.21c0,0.28,0.12,0.54,0.31,0.72 c0.19,0.19,0.45,0.31,0.73,0.31h90.02c0.28,0,0.54-0.12,0.72-0.31c0.19-0.19,0.31-0.45,0.31-0.72V7.33c0-0.28-0.12-0.54-0.31-0.73 C97.89,6.42,97.64,6.3,97.36,6.3L97.36,6.3z M31.54,64.59c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,64.59z"
                      />
                    </g>
                  </svg>
                </router-link>
              </div>
              <div v-else class="">
                <router-link
                  v-if="vehicleManager || vehicleEdit"
                  class="containerLink"
                  :to="{
                    name: 'adicionar-revisao',
                    params: {
                      idVehicle: idVehicle,
                    },
                  }"
                >
                  <div class="linkGrey">Adicionar Revisão</div>

                  <svg
                    version="1.1"
                    id="noteAdd"
                    width="40"
                    height="38"
                    viewBox="4.5 0 19 24"
                  >
                    <path
                      d="M13.5,20 C14.3284271,20 15,19.3284271 15,18.5 C15,17.1192881 16.1192881,16 17.5,16 C18.3284271,16 19,15.3284271 19,14.5 L19,11.5 C19,11.2238576 19.2238576,11 19.5,11 C19.7761424,11 20,11.2238576 20,11.5 L20,14.5 C20,18.0898509 17.0898509,21 13.5,21 L6.5,21 C5.11928813,21 4,19.8807119 4,18.5 L4,5.5 C4,4.11928813 5.11928813,3 6.5,3 L12.5,3 C12.7761424,3 13,3.22385763 13,3.5 C13,3.77614237 12.7761424,4 12.5,4 L6.5,4 C5.67157288,4 5,4.67157288 5,5.5 L5,18.5 C5,19.3284271 5.67157288,20 6.5,20 L13.5,20 L13.5,20 Z M15.7913481,19.5014408 C16.9873685,18.9526013 17.9526013,17.9873685 18.5014408,16.7913481 C18.1948298,16.9255432 17.8561101,17 17.5,17 C16.6715729,17 16,17.6715729 16,18.5 C16,18.8561101 15.9255432,19.1948298 15.7913481,19.5014408 L15.7913481,19.5014408 Z M18,6 L20.5,6 C20.7761424,6 21,6.22385763 21,6.5 C21,6.77614237 20.7761424,7 20.5,7 L18,7 L18,9.5 C18,9.77614237 17.7761424,10 17.5,10 C17.2238576,10 17,9.77614237 17,9.5 L17,7 L14.5,7 C14.2238576,7 14,6.77614237 14,6.5 C14,6.22385763 14.2238576,6 14.5,6 L17,6 L17,3.5 C17,3.22385763 17.2238576,3 17.5,3 C17.7761424,3 18,3.22385763 18,3.5 L18,6 Z M8.5,9 C8.22385763,9 8,8.77614237 8,8.5 C8,8.22385763 8.22385763,8 8.5,8 L12.5,8 C12.7761424,8 13,8.22385763 13,8.5 C13,8.77614237 12.7761424,9 12.5,9 L8.5,9 Z M8.5,12 C8.22385763,12 8,11.7761424 8,11.5 C8,11.2238576 8.22385763,11 8.5,11 L15.5,11 C15.7761424,11 16,11.2238576 16,11.5 C16,11.7761424 15.7761424,12 15.5,12 L8.5,12 Z M8.5,15 C8.22385763,15 8,14.7761424 8,14.5 C8,14.2238576 8.22385763,14 8.5,14 L13.5,14 C13.7761424,14 14,14.2238576 14,14.5 C14,14.7761424 13.7761424,15 13.5,15 L8.5,15 Z"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </article>
          <!--################################################# 
          //              INSURANCE - VEHICLES
          ##################################################-->
          <article class="articleInfos">
            <h3 class="titleInfos">Seguro</h3>
            <div v-if="vehicle.insurances && vehicle.insurances.length > 0">
              <span
                v-for="(insurance, i) in vehicle.insurances"
                :key="i"
                class="textGray"
              >
                <ul v-if="i === 0">
                  <li class="items itemList">
                    <span class="titleBlue">Validade do Seguro</span>

                    <div class="list">
                      <span :class="validateIsurance">
                        <!-- {{insuranceEndDate}} -->
                        {{
                          vehicle.insurances
                            | getLastDate("endDate")
                            | formatDateReverse
                        }}
                      </span>
                      <!-- <span class="validate">
                        {{
                          vehicle.insurances
                            | getLastDate("endDate")
                            | expireDate
                        }}
                      </span> -->
                      <span v-if="expireInsurance" class="validate"
                        >(expirado)</span
                      >
                    </div>
                  </li>
                  <li class="items itemList">
                    <span class="titleBlue">Início do Seguro</span>
                    <div class="list">
                      <!-- <span>{{ insuranceStartDate }}</span> -->
                      <span>{{
                        vehicle.insurances
                          | getLastDate("startDate")
                          | formatDateReverse
                      }}</span>
                    </div>
                  </li>
                </ul>
              </span>
            </div>
            <!-- <div v-else>
              <ul>
                <li class="itemList">
                  <span class="textGray">Não possui seguros cadastrados</span>
                </li>
              </ul>
            </div> -->
            <div class="articleInfos">
              <div v-if="vehicle.insurances && vehicle.insurances.length > 0">
                <router-link
                  class="containerLink"
                  :to="{
                    name: 'seguros-viatura',
                    params: { idVehicle: idVehicle },
                  }"
                >
                  <div class="linkGrey">Ver todos os seguros</div>

                  <svg
                    version="1.1"
                    id="note"
                    xmlns="http://www.w3.org/2000/svg"
                    xmlns:xlink="http://www.w3.org/1999/xlink"
                    x="0px"
                    y="0px"
                    viewBox="0 0 104.69 122.88"
                    style="enable-background: new 0 0 104.69 122.88"
                    xml:space="preserve"
                  >
                    <g>
                      <path
                        d="M31.54,86.92c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15H55c1.74,0,3.15,1.41,3.15,3.15 c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,86.92z M31.54,42.27c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,42.27z M7.33,0h90.02c2.02,0,3.85,0.82,5.18,2.15 c1.33,1.33,2.15,3.16,2.15,5.18v108.21c0,2.02-0.82,3.85-2.15,5.18c-1.33,1.33-3.16,2.15-5.18,2.15H7.33 c-2.02,0-3.85-0.82-5.18-2.15C0.82,119.4,0,117.57,0,115.55V7.33c0-2.02,0.82-3.85,2.15-5.18C3.48,0.82,5.31,0,7.33,0L7.33,0z M97.36,6.3H7.33c-0.28,0-0.54,0.12-0.73,0.3C6.42,6.8,6.3,7.05,6.3,7.33v108.21c0,0.28,0.12,0.54,0.31,0.72 c0.19,0.19,0.45,0.31,0.73,0.31h90.02c0.28,0,0.54-0.12,0.72-0.31c0.19-0.19,0.31-0.45,0.31-0.72V7.33c0-0.28-0.12-0.54-0.31-0.73 C97.89,6.42,97.64,6.3,97.36,6.3L97.36,6.3z M31.54,64.59c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,64.59z"
                      />
                    </g>
                  </svg>
                </router-link>
              </div>
              <div v-else class="">
                <router-link
                  v-if="vehicleManager || vehicleEdit"
                  class="containerLink"
                  :to="{
                    name: 'adicionar-seguro',
                    params: {
                      idVehicle: idVehicle,
                    },
                  }"
                >
                  <div class="linkGrey">Adicionar seguro</div>

                  <svg
                    version="1.1"
                    id="noteAdd"
                    width="40"
                    height="38"
                    viewBox="4.5 0 19 24"
                  >
                    <path
                      d="M13.5,20 C14.3284271,20 15,19.3284271 15,18.5 C15,17.1192881 16.1192881,16 17.5,16 C18.3284271,16 19,15.3284271 19,14.5 L19,11.5 C19,11.2238576 19.2238576,11 19.5,11 C19.7761424,11 20,11.2238576 20,11.5 L20,14.5 C20,18.0898509 17.0898509,21 13.5,21 L6.5,21 C5.11928813,21 4,19.8807119 4,18.5 L4,5.5 C4,4.11928813 5.11928813,3 6.5,3 L12.5,3 C12.7761424,3 13,3.22385763 13,3.5 C13,3.77614237 12.7761424,4 12.5,4 L6.5,4 C5.67157288,4 5,4.67157288 5,5.5 L5,18.5 C5,19.3284271 5.67157288,20 6.5,20 L13.5,20 L13.5,20 Z M15.7913481,19.5014408 C16.9873685,18.9526013 17.9526013,17.9873685 18.5014408,16.7913481 C18.1948298,16.9255432 17.8561101,17 17.5,17 C16.6715729,17 16,17.6715729 16,18.5 C16,18.8561101 15.9255432,19.1948298 15.7913481,19.5014408 L15.7913481,19.5014408 Z M18,6 L20.5,6 C20.7761424,6 21,6.22385763 21,6.5 C21,6.77614237 20.7761424,7 20.5,7 L18,7 L18,9.5 C18,9.77614237 17.7761424,10 17.5,10 C17.2238576,10 17,9.77614237 17,9.5 L17,7 L14.5,7 C14.2238576,7 14,6.77614237 14,6.5 C14,6.22385763 14.2238576,6 14.5,6 L17,6 L17,3.5 C17,3.22385763 17.2238576,3 17.5,3 C17.7761424,3 18,3.22385763 18,3.5 L18,6 Z M8.5,9 C8.22385763,9 8,8.77614237 8,8.5 C8,8.22385763 8.22385763,8 8.5,8 L12.5,8 C12.7761424,8 13,8.22385763 13,8.5 C13,8.77614237 12.7761424,9 12.5,9 L8.5,9 Z M8.5,12 C8.22385763,12 8,11.7761424 8,11.5 C8,11.2238576 8.22385763,11 8.5,11 L15.5,11 C15.7761424,11 16,11.2238576 16,11.5 C16,11.7761424 15.7761424,12 15.5,12 L8.5,12 Z M8.5,15 C8.22385763,15 8,14.7761424 8,14.5 C8,14.2238576 8.22385763,14 8.5,14 L13.5,14 C13.7761424,14 14,14.2238576 14,14.5 C14,14.7761424 13.7761424,15 13.5,15 L8.5,15 Z"
                    />
                  </svg>
                </router-link>
              </div>
            </div>
          </article>
          <!--################################################# 
          //              SERVICE - VEHICLES
          ##################################################-->
          <article class="articleInfos">
            <h3 class="titleInfos">
              Serviços do Tipo de Viatura
              <span v-if="vehicleTypeName && vehicleTypeName.name != undefined">
                - {{ vehicleTypeName.name }}</span
              >
            </h3>
            <div v-if="vehicle.services && vehicle.services.length > 0">
              <router-link
                v-if="vehicleTypeName && vehicleTypeName.name != undefined"
                :to="{
                  name: 'tipo-viatura',
                  params: { idTypeVehicle: vehicle.vehicleTypeID },
                }"
              >
                <div v-for="service in vehicle.services" :key="service.id">
                  <ul>
                    <li class="itemList">
                      <!-- <span class="titleBlue">Nome</span> -->
                      <div class="list">
                        <span class="textGray item2">{{ service.name }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </router-link>
            </div>
            <div v-else>
              <ul>
                <li class="itemList">
                  <span class="textGray item2"
                    >Não possui Serviços registrados</span
                  >
                </li>
              </ul>
            </div>
          </article>
        </article>
      </div>
    </div>
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteFile"
        @click="closeModalDelFileVehicle"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteFile">
        <button class="close btn" @click="closeModalDelFileVehicle">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este ficheiro?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelFileVehicle"
          />
          <button class="btnDel" @click.prevent="deleteFileVehicle">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import ArchiveItem from "@/components/vehicle/ArchiveItem.vue";
import { getLastDate } from "@/helpers.js";
// import { formatDate } from "@/helpers.js";
import { alertDate } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Vehicle",
  props: ["idVehicle"],
  data() {
    return {
      searchHistory: false,
    };
  },
  components: {
    ArchiveItem,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "loadingDownloadImg",
      "selectedId",
      "loadingImg",
    ]),
    ...mapState("user", ["user"]),
    ...mapState("vehicle", [
      "vehicle",
      "loadingVehicle",
      "typeVehicles",
      "models",
      "brands",
      "showModalDeleteFile",
      "selectedFile",
      "archiveID",
      "fileID",
    ]),
    ...mapGetters("user", [
      "vehicleAccess",
      "vehicleManager",
      "vehicleAnalysis",
      "vehicleEdit",
    ]),

    //##################################################
    //                 VEHICLES
    //##################################################
    vehicleTypeName() {
      if (this.typeVehicles != null) {
        return this.typeVehicles.find((typeVehicle) => {
          return typeVehicle.id == this.vehicle.vehicleTypeID;
        });
      } else {
        return "";
      }
    },
    modelName() {
      if (this.models != null) {
        return this.models.find((model) => {          
          return model.id == this.vehicle.modelID;
        });
      } else {
        return "";
      }
    },
    brandsName(){
      var itemBrand = null
      if (this.models != null && this.brands != null) {
        itemBrand = this.models.find((typeVehicle) => {
          return typeVehicle.id == this.vehicle.modelID;
        });
        if(itemBrand != undefined){
        return itemBrand = this.brands.find((brand) => {          
          return brand.id == itemBrand.brandID;
        })}
      } else {
        return "";
      }
    },
    //##################################################
    //                NEXT REVISIONS
    //##################################################

    validateNextRevison() {
      return this.vehicle.status.inspection.status === "Danger" ||
        this.vehicle.status.inspection.status === "Warning"
        ? "proximitDate"
        : "nothing";
      // return alertDate(new Date(this.vehicle.nextInspectionDate), 30)
      //   ? "proximitDate"
      //   : "nothing";
    },

    expireNextRevision() {
      // let today = new Date();
      // let revision = new Date(this.vehicle.nextInspectionDate);
      // return revision < today;
      return this.vehicle.status.inspection.status === "Danger" ? true : false;
    },

    //##################################################
    //                 REVISIONS
    //##################################################

    validateRevison() {
      return alertDate(getLastDate(this.vehicle.revisions, "date"), 30)
        ? "proximitDate"
        : "nothing";
    },
    //##################################################
    //                 INSURANCE
    //##################################################

    validateIsurance() {
      return this.vehicle.status.insurance.status === "Danger" ||
        this.vehicle.status.insurance.status === "Warning"
        ? "proximitDate"
        : "nothing";
      // return alertDate(getLastDate(this.vehicle.insurances, "endDate"), 30)
      //   ? "proximitDate"
      //   : "nothing";
    },

    expireInsurance() {
      // let today = new Date();
      // let insurance = getLastDate(this.vehicle.insurances, "endDate");
      // return insurance < today;
      return this.vehicle.status.insurance.status === "Danger" ? true : false;
    },
  },
  methods: {
    //######################################################
    //     FILE ARQUIVO PADRÂO
    //######################################################
    getFile(filename, archiveID) {
      this.$store.dispatch("attachment/getFile", {
        archiveID: `${archiveID}`,
        fileName: `${filename}`,
      });
    },
    cleanFile() {
      this.$store.commit("vehicle/UPDATE_SELECTEDFILE", null);
    },
    onFileSelected(event) {
      this.$store.commit("vehicle/UPDATE_SELECTEDFILE", event.target.files[0]);
    },

    onUpload(id) {
      const formData = new FormData();
      formData.append("file", this.selectedFile, this.selectedFile.name);
      // console.log("formData", formData);
      this.$store.dispatch("vehicle/onUploadVehicle", {
        idVehicle: `${id}`,
        data: formData,
        namefile: this.selectedFile.name,
      });
      // this.$store.dispatch("onUpload", {
      //   data: formData,
      //   namefile: this.selectedFile.name,
      // });
    },
    //######################################################
    //    ARQUIVOS MODAL DELETE
    //######################################################
    openModalDelFileVehicle() {
      this.$store.dispatch("vehicle/openModalDelFileVehicle");
    },
    closeModalDelFileVehicle() {
      this.$store.dispatch("vehicle/closeModalDelFileVehicle");
    },
    //######################################################
    //     ARQUIVOS DELETE
    //######################################################
    confirmDeleteFileVehicle(archiveID) {
      // console.log(archiveID);
      this.$store.dispatch("vehicle/confirmDeleteFileVehicle", {
        archiveID: `${archiveID}`,
      });
    },
    deleteFileVehicle() {
      this.$store.dispatch("vehicle/deleteFileVehicle", {
        idVehicle: this.idVehicle,
        archiveID: this.archiveID,
      });
      this.closeModalDelFileVehicle();
    },
    openModalDelVehicle() {
      this.$store.dispatch("vehicle/openModalDelVehicle");
    },
    closeModalDelVehicle() {
      this.$store.dispatch("vehicle/closeModalDelVehicle");
    },
  },
  created() {
    this.$store.dispatch("vehicle/getTypeVehicles");
    this.$store.dispatch("vehicle/getModels");
    this.$store.dispatch("vehicle/getBrands");
    this.$store.dispatch("vehicle/getVehicle", {
      idVehicle: this.idVehicle,
    });
  },
};
</script>

<style scoped>
.proximitDate {
  color: red;
}
.validate {
  margin-left: 10px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}
/* files */
.clearFile {
  font-size: 24px;
  line-height: 28px;
  margin-left: 10px;
  font-weight: bold;
  color: #00732c;
  padding-top: 5px;
  cursor: pointer;
}
.containerFile {
  display: flex;
  align-content: center;
  align-items: baseline;
  /* grid-gap: 10px; */
  margin: 10px 0 0 0;
}
.inputNone {
  display: none;
}
.noArchive {
  margin: 20px 0 30px 0;
  margin-left: 10px;
}

.spot {
  font-size: 14px;
  color: #015eb0;
}
.list {
  /* display: grid; */
  align-content: center;
}
.inputList {
  display: grid;
}
/*INPUT FILE botão e arquivo */
.inputFile {
  cursor: pointer;
  font-size: 14px;
  width: 240px;
  border: 1px solid #015eb0;
  background: #eff7fe;
  border-radius: 9px;
  padding: 10px;
  color: #1f2b42;
  margin: 3px 10px 0 0;
  border-style: dotted;
}
/* fim files  */
.textGreen {
  margin-left: 10px;
  color: #00732c;
  font-size: 14px;
  line-height: 28px;
}
.textGreen:hover {
  text-decoration: underline;
}
.item2 {
  padding-left: 15px;
}
.addTipeVehicle {
  display: flex;
  align-items: center;
  /* margin-left: 15px; */
}
.containerLink {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.articleInfos,
.callsHistory {
  margin-top: 30px;
}

.titleInfos {
  margin-bottom: 10px;
}

.iconInfoSearchHistory {
  margin: 0 10px;
}
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .items li {
    padding: 0 0 0 10px;
  }
  .list {
    padding: 0 10px;
  }
  .inputFile {
    margin: 3px 10px 0 10px;
  }
}
@media screen and (max-width: 390px) {
  .containerFile {
    display: grid;
    grid-gap: 10px;
  }
}
</style>
