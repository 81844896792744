<template >
  <form action="">
    <div v-if="loadingAddress">
      <p><LoadingCircle /></p>
    </div>

    <div class="contentForm" v-else>
      <section v-if="confirmForm == true">
        <div class="locationContainer" @click.prevent="activeForm(false)">
          <div class="location">
            <figure>
              <img src="@/assets/svg/locationIcon.svg" />
            </figure>
            <span class="grid2 textGreen">Localizar Morada</span>
          </div>
        </div>
        <div class="addressGrid2">
          <div class="formInfos">
            <label for="street"> Morada: </label>
            <input
              id="street"
              name="street"
              type="text"
              v-model="street"
              :class="validateFull"
              @change="resetError"
            />
            <div
              v-if="!errors.indexOf('full') || !errors.indexOf('street')"
              class="notify"
            >
              A morada é um campo requerido
            </div>
          </div>
          <div class="formNumber">
            <label for="streetNumber"> Número: </label>
            <input
              id="streetNumber"
              name="streetNumber"
              type="number"
              v-model.number="streetNumber"
              :class="validateNumber"
              @change="resetError"
            />
            <span v-if="!errors.indexOf('streetNumber')" class="notify2">
              O Número é um campo requerido
            </span>
          </div>
        </div>

        <div class="formGrid2">
          <div class="formInfos">
            <label for="city"> Localidade: </label>
            <input
              id="city"
              name="city"
              type="text"
              v-model="city"
              :class="validateCity"
              @change="resetError"
            />
            <div v-if="!errors.indexOf('city')" class="notify2">
              A Localidade é um campo requerido
            </div>
          </div>
          <div class="formInfos">
            <label for="state"> Distrito: </label>
            <input
              id="state"
              name="state"
              type="text"
              v-model="state"
              :class="validateState"
              @change="resetError"
            />
            <div v-if="!errors.indexOf('state')" class="notify2">
              O Distrito é um campo requerido
            </div>
          </div>
        </div>

        <div class="formGrid2">
          <div class="formInfos">
            <label for="postalCode"> Código Postal: </label>
            <input
              id="postalCode"
              name="postalCode"
              type="text"
              v-model="postalCode"
              @change="resetError"
            />
          </div>
          <div class="formInfos">
            <label for="country"> País: </label>
            <input id="country" name="country" type="text" v-model="country" />
          </div>
        </div>
        <div class="formGrid2">
          <div class="formInfos">
            <label for="complement"> Complemento: </label>
            <textarea
              id="complement"
              name="complement"
              type="text"
              v-model="complement"
              autocomplete="off"
              placeholder="Escreva aqui..."
              @change="resetError"
            />
          </div>
          <div class="formInfos">
            <label for="reference"> Referência: </label>
            <!-- <input id="reference" name="reference" type="text" v-model="reference" /> -->
            <textarea
              id="reference"
              name="reference"
              type="text"
              v-model="reference"
              autocomplete="off"
              placeholder="Escreva aqui..."
            />
          </div>
        </div>
        <div class="formInfos">
            <label for="alias"> Notas: </label>
            <textarea
              id="alias"
              name="alias"
              type="text"
              v-model="alias"
              autocomplete="off"
              placeholder="Escreva aqui..."
              @change="resetError"
            />
        </div>
        
        <div class="activeForm">
            <label for="danger"> Área Complexa </label>
            <BtnChoice id="danger" v-model="danger" />
        </div>
        
        <div class="button">
          <slot name="cancel"></slot>
          <slot name="delete"></slot>
        </div>
      </section>
      <section v-if="confirmForm == false">
        <div class="formInfos">
          <label for="street"> Localizar Morada: </label>
          <div class="containerMap">
            <ul class="ulMap">
              <input
                id="street"
                name="street"
                type="text"
                @keyup="getGoogleMap"
                v-model="locationAddress"
                :class="validateFull"
                @change="resetError"
              />
              <div :class="boxActive">
                <li
                  id="address"
                  v-for="(city, index) in googleMap"
                  :key="index"
                  :value="city"
                  :selected="index === 0"
                  class="dataMap"
                  @click.prevent="getGoogleGeo(city.result[0].prediction, city.result[0].place_id)"
                >
                  <span
                    
                    >{{ city.result[0].prediction }}</span
                  >
                </li>
              </div>
            </ul>
          </div>
          <div
            v-if="!errors.indexOf('full') || !errors.indexOf('street')"
            class="notify"
          >
            A morada é um campo requerido
          </div>
        </div>
        <div v-if="iframe" class="iframe">
          <iframe
            width="100%"
            height="300px"
            frameborder="0"
            scrolling="no"
            marginheight="0"
            marginwidth="0"
            :src="iframe"
          >
          </iframe>
        </div>
        <div class="button">
            <slot name="cancel"></slot>
          <button class="btn btn-form grid2" @click.prevent="activeForm(true)">
            Confirmar
          </button>
        </div>
      </section>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddressForm",
  props: ["errors"],
  computed: {
    validateFull() {
      return this.errors.indexOf("full") || this.errors.indexOf("street")
        ? "true"
        : "validate";
    },
    validateNumber() {
      return this.errors.indexOf("streetNumber") ? "true" : "validate";
    },
    validateState() {
      return this.errors.indexOf("state") ? "true" : "validate";
    },
    validateCity() {
      return this.errors.indexOf("city") ? "true" : "validate";
    },
    ...mapFields({
      fields: [
        "street",
        "streetNumber",
        "city",
        "state",
        "country",
        "postalCode",
        "complement",
        "reference",
        "alias",
        "danger",
      ], //nome dos campos
      module: "address", //qual módulo está acessando
      base: "address", //colocar o state que vai receber
      mutation: "address/UPDATE_ADDRESS", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["partAddress"], //nome dos campos
      module: "address", //qual módulo está acessando
      base: "partAddress", //colocar o state que vai receber
      mutation: "address/UPDATE_SEARCH_CITY", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["locationAddress"], //nome dos campos
      module: "address", //qual módulo está acessando
      base: "locationAddress", //colocar o state que vai receber
      mutation: "address/UPDATE_LOCATION_ADDRESS", //nome da mutation que vai alterar o state
    }),
    boxActive() {
      return this.googleMap != null && this.googleMap != ""
        ? "boxMap"
        : "nothing";
    },
    // partAddress: {
    //   get() {
    //     return this.$store.state.address.partAddress;
    //   },
    //   set(value) {
    //     this.$store.commit("address/UPDATE_SEARCH_CITY", value);
    //   },
    // },
    googleMap: {
      get() {
        return this.$store.state.address.googleMap;
      },
      set(value) {
        this.$store.commit("address/UPDATE_GOOGLEMAP", value);
      },
    },
    ...mapState("address", ["address", "confirmForm"]),
    ...mapState(["loadingAddress", "loadingCircle"]),
    ...mapGetters("address", [
      "partAddressReset",
      "partAddressReply",
      "locationAddressReset",
    ]),
    iframe() {
      let url = null;
      if (this.address.location != null) {
        url = `https://maps.google.com/maps?q=${this.address.location.lat},${this.address.location.lng}&z=17&output=embed`;
      }
      return url;
    },
  },
  methods: {
    activeForm(confirm) {
      this.$store.commit("address/UPDATE_CONFIRMFORM", confirm);
    },
    resetError() {
      this.$store.commit("RESET_ERRORS", []);
    },
    getGoogleMap() {
      if (this.locationAddress == null || this.locationAddress == "") {
        this.$store.commit("address/UPDATE_GOOGLEMAP", null);
      } else if (this.locationAddress.length > 9) {
        this.$store.commit("address/UPDATE_SEARCH_CITY", {
          partAddress: this.locationAddress,
        });
        // console.log("partAddressReply", this.partAddressReply);
        this.$store.dispatch("address/getGoogleMap", {
          partAddress: this.partAddressReply,
        });
      }
    },
    getGoogleGeo(city, id) {
      // console.log("place_id", city);
      // console.log("id", id);
      // this.$store.commit("address/UPDATE_GOOGLEMAP", this.partAddressReset);      
      this.$store.commit("address/UPDATE_LOCATION_ADDRESS", { locationAddress: city });
      this.$store.commit("address/UPDATE_SEARCH_CITY", { partAddress: city });
      this.$store.dispatch("address/getGoogleGeo", {
        partAddress: this.partAddressReply,
        place_id: `${id}`,
      });
    },
  },
  created() {
    this.$store.commit("address/UPDATE_GOOGLEMAP", null);
    this.$store.commit(
      "address/UPDATE_LOCATION_ADDRESS",
      this.locationAddressReset
    );
    this.$store.commit("RESET_ERRORS", []);
  },
};
</script>

<style scoped>
.locationContainer {
  display: grid;
  justify-content: flex-end;
  width: 100%;
}
.location {
  display: flex;
  grid-gap: 10px;
  justify-content: flex-end;
  margin-bottom: 20px;
  align-content: center;
  align-items: center;
  cursor: pointer;
  max-width: 180px;
}
iframe {
  margin: 20px auto;
}
.notify,
.notify2 {
  text-align: center;
  margin: 0;
}
form {
  max-width: 600px;
  width: 100%;
  min-height: 350px;
}
.contentForm {
  display: grid;
  max-width: 800px;
  width: 100%;
}
.addressGrid2 {
  display: grid;
  grid-template-columns: 1fr minmax(80px, 150px);
  width: 100%;
  grid-gap: 10px;
  align-items: flex-start;
}
.formGrid2 {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  grid-gap: 10px;
  align-items: flex-start;
}
.containerMap {
  display: grid;
  margin-bottom: 50px;
  /* max-width: 490px; */
  width: 100%;
  position: relative;
}
.ulMap input {
  /* max-width: 490px; */
  width: 100%;
  /* left: 9999px; */
}
.ulMap {
  position: absolute;
  /* max-width: 490px; */
  width: 100%;
  /* left: 9999px; */
}
.dataMap {
  position: relative;
  font-size: 16px;
  padding: 5px 10px;
}
.boxMap {
  border-top: none;
  border-left: 1px solid rgba(31, 43, 66, 0.4);
  border-right: 1px solid rgba(31, 43, 66, 0.4);
  border-bottom: 1px solid rgba(31, 43, 66, 0.4);
  border-radius: 0 0 9px 9px;
  background: #fff;
  /* box-shadow: 0 0 0 1pt rgba(31, 43, 66, 0.5); */
}
li {
  border-bottom: 1px solid rgba(31, 43, 66, 0.4);
}
li:last-child {
  border-bottom: none;
}
.dataMap:hover {
  /* box-shadow: 0 0 0 1pt rgba(31, 43, 66, 0.5); */
  cursor: pointer;
  background: #dbebf3;
}
.googleMap {
  /* margin-top: -20px; */
  font-size: 16px;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(31, 43, 66, 0.4);
}
.googleMap:hover {
  /* box-shadow: 0 0 0 1pt rgba(31, 43, 66, 0.5); */
  cursor: pointer;
  background: #dbebf3;
}
.formInfos {
  display: grid;
  grid-template-columns: 1fr;
  /* grid-template-columns: minmax(300px, 490px); */
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  margin-top: 20px;
  margin-right: 10px;
  width: 100%;
}

.formNumber {
  display: grid;
  grid-template-columns: minmax(100px, 150px);
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  margin-top: 20px;
  margin-right: 10px;
  width: 100%;
}

.button {
  display: grid;
  grid-template-columns: repeat(2, 130px);
  justify-content: flex-end;
  margin-top: 10px;
  /* margin-right: 10px; */
}

.grid2 {
  grid-column: 2;
}
@media screen and (max-width: 690px) {
  .addressGrid2,
  .button,
  .formGrid2 {
    grid-template-columns: 1fr;
    padding: 10px;
  }
  .formNumber {
    grid-template-columns: 1fr;
  }
}
</style>