var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{},[_c('h1',[_vm._v("Jurídico")]),_c('div',{staticClass:"containerContact"},[_c('div',{staticClass:"titleContainer"},[_c('h3',[_vm._v("Dados da Posiçã̀o")]),(_vm.processAccess)?_c('div',[_c('router-link',{staticClass:"collabItens",attrs:{"to":{
            name: 'editar-posicao',
            params: {
              idProcessPosition: _vm.idProcessPosition,
            },
          }}},[_c('AddUser',[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Editar"),_c('br'),_vm._v(" Posição")])])],1)],1):_vm._e()]),(_vm.router == 'processo')?_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'processos', query: { archived: 'false'} }}},[_vm._v("Lista de Processos")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
              name: 'processo',
              params: { idProcess: _vm.idRouter },
            }}},[_vm._v("Processo")])],1),_vm._m(0)])]):_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'processos', query: { archived: 'false'} }}},[_vm._v("Lista de Processos")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'lista-posicoes' }}},[_vm._v("Lista de Posições")])],1),_vm._m(1)])]),(_vm.loadingProcess)?_c('div',[_c('Loading')],1):_c('div',[(_vm.processPosition)?_c('article',[_c('ul',{staticClass:"borderTable"},[(_vm.processPosition.name && _vm.processPosition.name.length > 0)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Nome")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.processPosition.name))])])]):_vm._e(),(
              _vm.processPosition.created && _vm.processPosition.created.length > 0
            )?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Criado")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm._f("formatDateReverse")(_vm.processPosition.created)))])])]):_vm._e(),(
              _vm.processPosition.modified && _vm.processPosition.modified.length > 0
            )?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Modificado")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm._f("formatDateReverse")(_vm.processPosition.modified)))])])]):_vm._e(),_c('li',{staticClass:"items containerFile"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Ficheiros")]),(_vm.loadingImg)?_c('div',[_c('LoadingCircle')],1):_c('div',[(_vm.processEdit || _vm.processManager)?_c('div',{staticClass:"containerFile"},[_c('div',{staticClass:"inputList"},[_vm._m(2),(_vm.selectedFile != null)?_c('span',{staticClass:"itemFile"},[_vm._v(_vm._s(_vm.selectedFile.name)),_c('span',{staticClass:"clearFile",on:{"click":function($event){$event.preventDefault();return _vm.cleanFile($event)}}},[_vm._v("×")])]):_vm._e(),_c('input',{staticClass:"inputNone",attrs:{"type":"file","id":"fileInput"},on:{"change":_vm.onFileSelected}})]),_c('button',{staticClass:"btn addFile",on:{"click":function($event){return _vm.onUpload(_vm.processPosition.id)}}},[_vm._v(" Adicionar ")])]):_vm._e()])]),_c('article',[(_vm.loadingImg)?_c('div'):_c('div',[(
                  _vm.processPosition.files != null &&
                  _vm.processPosition.files.length > 0
                )?_c('div',[(_vm.loadingDownloadImg)?_c('article',{staticClass:"downloadImgLoading"},[_vm._v(" Aguarde, fazendo download... ")]):_c('ul',_vm._l((_vm.processPosition.files),function(archive){return _c('li',{key:archive.id},[_c('article',{staticClass:"articleInfos"},[_c('ArchiveItem',{attrs:{"archive":archive,"idMicroservice":_vm.processPosition.id},on:{"getFile":function($event){return _vm.getFile(archive.filename, archive.id)}}},[_c('button',{staticClass:"delete",attrs:{"slot":"delete"},on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteFileProcessPosition(archive.id)}},slot:"delete"})])],1)])}),0)]):_c('div',[_c('p',{staticClass:"noResults noArchive"},[_vm._v(" Não possui ficheiros registrados ")])])])])])]):_vm._e()])]),_c('div',{staticClass:"modalDel"},[(_vm.showModalDeleteFile)?_c('div',{staticClass:"overlay",on:{"click":_vm.closeModalDelFileProcessPosition}}):_vm._e(),(_vm.showModalDeleteFile)?_c('div',{staticClass:"modalDel_container"},[_c('button',{staticClass:"close btn",on:{"click":_vm.closeModalDelFileProcessPosition}},[_vm._v(" x ")]),_vm._m(3),_c('div',{staticClass:"containerDel"}),_c('div',{staticClass:"buttonsDel"},[_c('input',{staticClass:"btn",attrs:{"type":"submit","value":"Cancel"},on:{"click":function($event){$event.preventDefault();return _vm.closeModalDelFileProcessPosition.apply(null, arguments)}}}),_c('button',{staticClass:"btnDel",on:{"click":function($event){$event.preventDefault();return _vm.deleteFileProcessPosition.apply(null, arguments)}}},[_vm._v(" Delete ")])])]):_vm._e()]),_c('NotifyError',{attrs:{"itemsError":_vm.itemsError}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v("Dados da Posição")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v("Dados da Posição")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('label',{staticClass:"inputFile",attrs:{"for":"fileInput"}},[_vm._v("Selecione um ficheiro "),_c('span',{staticClass:"spot"},[_vm._v("clique aqui")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"delItem"},[_c('span',[_vm._v("Você tem certeza que deseja remover este ficheiro?")])])
}]

export { render, staticRenderFns }