<template>
  <section>
    <h1>Viaturas</h1>

    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Editar Viatura</h3>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{ name: 'viatura', params: { idVehicle: idVehicle } }"
              >Dados da Viatura
            </router-link>
          </li>
          <li class="breadcrumb-item-active">
            <span> Editar Viatura</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingVehicle">
        <Loading />
      </div>
      <div v-else class="containerForm">
        <VehicleForm :idVehicle="idVehicle" :errors="errors">
          <button class="btn" @click.prevent="updateVehicle">Gravar</button>
        </VehicleForm>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import VehicleForm from "@/components/vehicle/VehicleForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditVehicle",
  props: ["idVehicle"],
  components: {
    VehicleForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("vehicle", [
      "vehicles",
      "loadingVehicle",
      "showModalDeleteVehicle",
    ]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),
    ...mapGetters("vehicle", ["vehicleReply"]),
  },
  methods: {
    updateVehicle() {
      this.$store.commit("RESET_ERRORS", []);
      let today = new Date();
      let nextRevisionDate = new Date(this.vehicleReply.nextRevisionDate);
      // console.log(nextRevisionDate <= today);
      if (nextRevisionDate <= today) {
        this.$store.commit("UPDATE_ERRORS", "errorNextRevision", {
          root: true,
        });
      } else {
        this.$store.dispatch("vehicle/updateVehicle", {
          idVehicle: this.idVehicle,
          vehicle: this.vehicleReply,
        });
      }
    },
  },
  created() {
    this.$store.dispatch("vehicle/getVehicle", {
      idVehicle: this.idVehicle,
    });
  },
};
</script>

<style scoped>
h3 {
  margin-left: 10px;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

@media screen and (max-width: 690px) {
  .containerTelecom {
    grid-template-columns: 1fr;
    justify-items: flex-start;
    grid-gap: 10px;
    margin: 30px 10px;
  }
  .containerForm {
    width: 100%;
  }
}
</style>