<template>
  <section class="itemContainer">
    <router-link
      :to="{
        name: 'tipo-viatura',
        params: { idTypeVehicle: typeVehicle.id },
      }"
    >
      <div class="collabItens">
        <div class="collabIten">
          <span class="titleBlue"></span>

          <div>
            <span class="titleBlue">Nome</span>
            <p>{{ typeVehicle.name }}</p>
          </div>
          <div>
            <div class="titleBlue">Descrição</div>
            <p v-if="typeVehicle.description">{{ typeVehicle.description }}</p>
            <p v-else>Não possui</p>
          </div>
        </div>
        <div class="collabConfig">
          <!-- <router-link
            v-if="vehicleManager"
            class="edit"
            :to="{
              name: 'editar-tipo-viatura',
              params: { idtypeVehicle: typeVehicle.id }
            }"
          >
          </router-link> -->
          <slot v-if="vehicleManager" name="delete" />
        </div>
      </div>
    </router-link>
  </section>
</template>  

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "VehicleItem",
  props: ["typeVehicle", "idTypeVehicle"],
  computed: {
    ...mapState("user", ["user"]),
    // ...mapState("vehicle", ["typeVehicles"]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),
  },

  created() {
    // this.$store.dispatch("vehicle/getTypeVehicles");
  },
};
</script>

<style scoped>
.telegramUsers {
  color: #1f2b42;
  font-size: 14px;
  line-height: 18px;
}

.itemContainer {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
  width: 100%;
}

.collabItens {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  grid-template-columns: 1fr 90px;
}

.collabIten {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  grid-template-columns: 250px minmax(200px, 380px) 50px;
  align-items: center;
  grid-gap: 3spx;
}

.collabConfig {
  display: grid;
  grid-template-columns: 50px;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

.collabItens:hover {
  background: #dbebf3;
}

.collabItens p {
  padding: 0 10px;
}
.titleBlue {
  display: none;
}

@media screen and (max-width: 695px) {
  .collabItens {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 10px;
    justify-items: flex-end;
  }
  .collabItens p {
    padding: 0px;
  }
  .collabIten {
    grid-template-columns: 260px;
  }
  .titleBlue {
    display: block;
    /* padding: 5px 0 0 10px; */
  }
  .itemContainer {
    width: 100%;
  }
}
</style>
