<template>
  <div class="btnAdd">
    <input id="addMenu" type="checkbox" name="" value="" />
    <label for="addMenu" class="circle">
      <svg
        class="icon-add"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
      </svg>
    </label>
    <label for="addMenu" class="titleAdd">
      <slot name="title" />
    </label>
    <slot name="itemsMenu" />
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
input {
  display: none;
}
.titleAdd {
  font-size: 12px;
  cursor: pointer;
}

.icon-add {
  fill: #fff;
}
.circle {
  background: #1f2b42;
  width: 35px;
  height: 35px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.icon-add:hover {
  fill: #fff;
}
.circle:hover {
  background: #28a745;
  width: 35px;
  height: 35px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.btnAdd {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}
</style>