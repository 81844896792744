<template>
  <section>
    <h1>Central Telefónica</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Lista de Contactos</h3>
        <!-- <div class="menuDropdown" v-if="staffEdit"> -->
        <div class="menuDropdown" v-if="telecomManager">
          <AddUser>
            <span slot="title">
              Adicionar <br />
              Contactos</span
            >
            <ul slot="itemsMenu" class="menuDropdownContent">
              <div class="titleMenuDropdown">Contactos</div>
              <li @click="openModalCreateContact">Adicionar</li>
            </ul>
          </AddUser>
        </div>
      </div>

      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'historico-chamadas' }"
              >Histórico de Chamadas</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Lista de Contactos Internos</span>
          </li>
        </ol>
      </nav>
      <article>
        <div v-if="loadingTelecomPhone">
          <Loading />
        </div>
        <div v-else>
          <div v-if="telecomPhones">
            <div class="titleCollab">
              <p class="titleBlue">Nome</p>
              <p class="titleBlue">Telefone</p>
            </div>
            <hr />
            <ul v-for="telecomPhone in telecomPhones" :key="telecomPhone.id">
              <li class="itemList">
                <TelecomPhoneItem
                  :telecomPhone="telecomPhone"
                  :idTelecomPhone="telecomPhone.id"
                >
                  <button
                    slot="delete"
                    class="delete"
                    @click.prevent="confirmDeleteContact(telecomPhone.id)"
                  ></button>
                </TelecomPhoneItem>
              </li>
            </ul>
          </div>
          <div v-else>
            <p class="noResults">Não possui contactos cadastrados</p>
          </div>
        </div>
      </article>
    </div>
    <div class="modal">
      <div
        class="overlay"
        v-if="showModalCreateContact"
        @click="closeModalCreateContact"
      ></div>
      <div class="modal_container" v-if="showModalCreateContact">
        <button class="close btn" @click="closeModalCreateContact">x</button>
        <h2>Adicionar Contacto</h2>
        <TelecomPhoneForm :telecomPhone="telecomPhone" :errors="errors">
          <button class="btn" @click.prevent="addTelecomPhone">
            Adicionar
          </button>
        </TelecomPhoneForm>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteContact"
        @click="closeModalDelContact"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteContact">
        <button class="close btn" @click="closeModalDelContact">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este Contacto?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelContact"
          />
          <button class="btnDel" @click.prevent="deleteContact">Delete</button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

// <script>
import TelecomPhoneForm from "@/components/telecom/TelecomPhoneForm.vue";
import TelecomPhoneItem from "@/components/telecom/TelecomPhoneItem.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddContact",
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "loadingTelecomPhone",
      "selectedId",
    ]),
    ...mapState("user", ["user"]),
    ...mapState("telecom", [
      "telecomPhones",
      "telecomPhone",
      "loadingPhoneActual",
      "configPhoneReply",
      "config",
      "showModalCreateContact",
      "showModalDeleteContact",
    ]),
    ...mapGetters("user", ["telecomManager"]),
    ...mapGetters("telecom", ["telecomPhoneReset", "telecomPhoneReply"]),
  },
  components: {
    TelecomPhoneItem,
    TelecomPhoneForm,
  },
  methods: {
    openModalCreateContact() {
      this.$store.commit("telecom/UPDATE_TELECOMPHONE", this.telecomPhoneReset);
      this.$store.dispatch("telecom/openModalCreateContact");
    },
    closeModalCreateContact() {
      this.$store.dispatch("telecom/closeModalCreateContact");
    },
    openModalDelContact() {
      this.$store.dispatch("telecom/openModalDelContact");
    },
    closeModalDelContact() {
      this.$store.dispatch("telecom/closeModalDelContact");
    },
    //######################################################
    //     DELETE
    //######################################################
    confirmDeleteContact(id) {
      this.$store.dispatch("telecom/confirmDeleteContact", {
        id: `${id}`,
      });
    },
    deleteContact() {
      this.$store.dispatch("telecom/deleteContact", {
        id: this.selectedId.id,
      });
      this.closeModalDelContact();
    },

    //######################################################
    //     ADICIONAR CONTACTO
    //######################################################
    addTelecomPhone() {
      this.$store.dispatch("telecom/addTelecomPhone", {
        contact: this.telecomPhoneReply, //enviar getters com as infos necessárias para o cadastro
      });
    },
  },
  created() {
    this.$store.dispatch("telecom/getTelecomPhones");
  },
};
</script>

<style scoped>
.modal_container {
  max-width: 450px;
}
.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

.titleCollab {
  display: grid;
  /* grid-template-columns: repeat(10, 130px); */
  /* grid-template-columns: 200px 150px 70px 90px repeat(2, 100px); */
  grid-template-columns: minmax(200px, 380px) 1fr 130px;
}

.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  /* margin-bottom: 30px; */
}

.titleBlue {
  margin: 0 5px;
}
h3 {
  text-align: initial;
  /* margin-left: 10px; */
}
@media screen and (max-width: 690px) {
  .titleBlue {
    display: none;
  }
  .containerTelecom {
    margin: 80px 10px 20px 10px;
  }
}
</style>
