<template>
  <section>
    <h1>Viaturas</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Lista de Marcas de Viaturas</h3>
        <div class="menuDropdown" v-if="vehicleEdit || vehicleManager">
          <div @click="openModalCreateBrand">
            <AddUser>
              <span slot="title">
                Adicionar<br />
                Marca</span
              >
            </AddUser>          
          </div>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Lista de Marcas de Viaturas</span>
          </li>
        </ol>
      </nav>
      <article>
        <div v-if="loadingVehicle">
          <Loading />
        </div>
        <div v-else>
          <div v-if="brands  && brands.length > 0">
            <!-- {{ typeVehicles }} -->
            <BrandSearch />
            <div class="titleCollab">
              <p class="titleBlue">Nome</p>
              <!-- <p class="titleBlue">Descrição</p> -->
            </div>
            <hr />
            <ul>
              <li
                class="itemList"
                v-for="brand in filteredBrands"
                :key="brand.id"
              >
                <BrandItem
                  :brand="brand"
                  :idBrand="brand.id"
                >
                  <button
                    slot="delete"
                    class="delete"
                    @click.prevent="confirmDeleteBrand(brand.id)"
                  ></button>
                </BrandItem>
              </li>
            </ul>
          </div>
          <div v-else>
            <p class="noResults">Não possui marcas de viaturas registradas</p>
          </div>
        </div>
      </article>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteBrand"
        @click="closeModalDelBrand"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteBrand">
        <button class="close btn" @click="closeModalDelBrand">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover esta marca de viatura?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelBrand"
          />
          <button class="btnDel" @click.prevent="deleteBrand">
            Delete
          </button>
        </div>
      </div>
    </div>
    <div class="modal">
      <div
        class="overlay"
        v-if="showModalCreateBrand"
        @click="closeModalCreateBrand"
      ></div>
      <div class="modal_container" v-if="showModalCreateBrand">
        <button class="close btn" @click="closeModalCreateBrand">x</button>
        <h2>Adicionar Marca</h2>
        <BrandForm :errors="errors">
          <button class="btn" @click.prevent="addBrand">
            Adicionar
          </button>
        </BrandForm>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import BrandSearch from "@/components/vehicle/BrandSearch.vue";
import BrandItem from "@/components/vehicle/BrandItem.vue";
import BrandForm from "@/components/vehicle/BrandForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddBrand",
  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId"]),
    ...mapState("user", ["user"]),
    ...mapState("vehicle", [
      "brands",
      "loadingVehicle",
      "showModalDeleteBrand",
      "showModalCreateBrand"
    ]),
    ...mapGetters("user", [
      "vehicleAccess",
      "vehicleManager",
      "vehicleAnalysis",
      "vehicleEdit",
    ]),
    ...mapGetters("vehicle", ["getFiltered", "brandReply", "brandReset"]),
    filteredBrands() {
      const a = this.getFiltered || this.brands;
      return a;
    },
  },
  components: {
    BrandItem,
    BrandSearch,
    BrandForm
  },
  methods: {
    openModalDelBrand() {
      this.$store.dispatch("vehicle/openModalDelBrand");
    },
    closeModalDelBrand() {
      this.$store.dispatch("vehicle/closeModalDelBrand");
    },
    //######################################################
    //     DELETE
    //######################################################
    confirmDeleteBrand(id) {
      console.log("id", id)
      this.$store.dispatch("vehicle/confirmDeleteBrand", {
        id: `${id}`,
      });
    },
    deleteBrand() {
      this.$store.dispatch("vehicle/deleteBrand", {
        id: this.selectedId.id,
      });
      this.closeModalDelBrand();
    },
    //######################################################
    //     ADICIONAR BRAND
    //######################################################
    openModalCreateBrand() {
      this.$store.commit("vehicle/UPDATE_BRAND", this.brandReset);
      this.$store.dispatch("vehicle/openModalCreateBrand");
    },
    closeModalCreateBrand() {
      this.$store.dispatch("vehicle/closeModalCreateBrand");
    },
    addBrand() {
      this.$store.dispatch("vehicle/addBrand", {
        brand: this.brandReply, //enviar getters com as infos necessárias para o cadastro
      });
    },
  },
  created() {
    this.$store.dispatch("vehicle/getBrands");
  },
};
</script>

<style scoped>
.modal_container {
  max-width: 450px;
}
.titleContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}

.titleCollab {
  display: grid;
  /* grid-template-columns: repeat(10, 130px); */
  /* grid-template-columns: 200px 150px 70px 90px repeat(2, 100px); */
  /* grid-template-columns: 350px 380px; */
  grid-template-columns: 250px minmax(200px, 380px);
  /* grid-gap: 5px; */
}

.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  min-width: 350px;
  max-width: 1000px;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  /* margin-bottom: 30px; */
}

.titleBlue {
  margin: 0 5px;
}
h3 {
  text-align: initial;
  margin-left: 10px;
}
@media screen and (max-width: 695px) {
  .titleBlue {
    display: none;
  }
  .containerTelecom {
    margin: 80px 10px 20px 10px;
  }
}
</style>
