var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h1',[_vm._v("Viaturas")]),_c('div',{staticClass:"containerTelecom"},[_vm._m(0),_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'viaturas' }}},[_vm._v("Lista de Viaturas")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
              name: 'viatura',
              params: { idVehicle: _vm.idVehicle },
            }}},[_vm._v("Dados da Viatura ")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
              name: 'inspecoes-viatura',
              params: { idVehicle: _vm.idVehicle },
            }}},[_vm._v("Inspeções da Viatura ")])],1),_vm._m(1)])]),(_vm.loadingVehicle)?_c('div',[_c('Loading')],1):_c('div',{staticClass:"containerForm"},[_c('InspectionForm',{attrs:{"idVehicle":_vm.idVehicle,"errors":_vm.errors}},[_c('button',{staticClass:"btn",on:{"click":function($event){$event.preventDefault();return _vm.updateInspection.apply(null, arguments)}}},[_vm._v("Gravar")])])],1)]),_c('NotifySuccess',{attrs:{"items":_vm.items}}),_c('NotifyError',{attrs:{"itemsError":_vm.itemsError}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleContainer"},[_c('h3',[_vm._v("Editar Inspeção da Viatura")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Editar Inspeção da Viatura")])])
}]

export { render, staticRenderFns }