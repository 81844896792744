var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{},[_c('div',{staticClass:"containerContact"},[_c('div',{staticClass:"titleContainer"},[_c('h3',[_vm._v("Dados do tipo do Cliente")]),(_vm.clientEdit)?_c('div',[_c('router-link',{staticClass:"collabItens",attrs:{"to":{
            name: 'editar-tipo-cliente',
            params: {
              idTypeClient: _vm.idTypeClient,
            },
          }}},[_c('AddUser',[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Editar"),_c('br'),_vm._v(" Tipo de Cliente")])])],1)],1):_vm._e()]),_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'clientes' }}},[_vm._v("Lista de Clientes")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'tipos-clientes' }}},[_vm._v("Lista de Tipos de Clientes")])],1),_vm._m(0)])]),(_vm.loadingClient)?_c('div',[_c('Loading')],1):_c('div',[(_vm.typeClient)?_c('article',[_c('ul',{staticClass:"borderTable"},[(_vm.typeClient.name)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Nome")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.typeClient.name))])])]):_vm._e(),(_vm.typeClient.description)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Descrição")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.typeClient.description))])])]):_vm._e(),(_vm.typeClient.created)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Criado")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm._f("formatDateReverse")(_vm.typeClient.created)))])])]):_vm._e(),(_vm.typeClient.modified)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Modificado")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm._f("formatDateReverse")(_vm.typeClient.modified)))])])]):_vm._e()])]):_vm._e()])]),_c('NotifySuccess',{attrs:{"items":_vm.items}}),_c('NotifyError',{attrs:{"itemsError":_vm.itemsError}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Dados do Tipo de Cliente")])])
}]

export { render, staticRenderFns }