<template>
  <section>
    <!-- pages>> {{ data.length }} -->
    <!-- {{ totalPages }} -->
    <ul class="pagination" v-if="data.length > 5 || currentPage > 1">
      <li class="pagination-item">
        <button
          type="button"
          @click="onClickFirstPage"
          :disabled="isInFirstPage"
        >
          &#171;
        </button>
      </li>

      <li class="pagination-item">
        <button
          type="button"
          @click="onClickPreviousPage"
          :disabled="isInFirstPage"
        >
          &lt;
        </button>
      </li>

      <li class="pagination-item" v-for="(page, index) in pages" :key="index">
        <button
          type="button"
          @click="onClickPage(page.number)"
          :class="{ active: isPageActive(page.number) }"
        >
          {{ page.number }}
        </button>
        <!-- <button
          type="button"
          @click="onClickPage(page.number)"
          :class="{ active: isPageActive((page.number = index + 1)) }"
        >
          {{ index + 1 }}
        </button> -->
      </li>

      <li class="pagination-item">
        <button type="button" @click="onClickNextPage" :disabled="isInLastPage">
          &gt;
        </button>
      </li>

      <li class="pagination-item">
        <button type="button" @click="onClickLastPage" :disabled="isInLastPage">
          &#187;
        </button>
      </li>
    </ul>
  </section>
</template>

<script>
export default {
  name: "Pagination",
  //Props Paginação
  props: {
    data: {
      type: Array,
      required: true,
    },
    maxVisibleButtons: {
      type: Number,
      required: false,
      default: 3,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  computed: {
    startPage() {
      if (this.currentPage === 1) return 1;
      if (this.currentPage === this.totalPages)
        return (
          this.totalPages -
          this.maxVisibleButtons +
          (this.maxVisibleButtons - 1)
        );
      return this.currentPage - 1;
    },
    endPage() {
      return Math.min(
        this.startPage + this.maxVisibleButtons - 1,
        this.totalPages
      );
    },
    pages() {
      let range = [];
      for (let i = this.startPage; i <= this.endPage; i += 1) {
        range.push({
          number: i,
          isDisabled: i === this.currentPage,
        });
      }
      return range;
    },
    isInFirstPage() {
      return this.currentPage === 1;
    },
    isInLastPage() {
      return this.currentPage === this.totalPages;
    },
  },
  methods: {
    //Paginação
    onClickFirstPage() {
      this.$emit("pagechanged", 1);
    },
    onClickPreviousPage() {
      this.$emit("pagechanged", this.currentPage - 1);
    },
    onClickPage(page) {
      // console.log(page);
      //numero centrais do controle --- o emit envia p/ a view a pagina que está atualmente
      this.$emit("pagechanged", page);
    },
    onClickNextPage() {
      this.$emit("pagechanged", this.currentPage + 1);
    },
    onClickLastPage() {
      this.$emit("pagechanged", this.totalPages);
    },
    isPageActive(page) {
      // console.log("pageActive", page);
      return this.currentPage === page;
    },
  },
  // created() {
  //   this.isPageActive(3);
  // },
};
</script>

<style scoped>
svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}
.active {
  color: #009fea;
}
.pagination {
  display: flex;
  justify-content: center;
  padding: 10px;
  margin: 2px;
  border-radius: 3px;
  font-size: 0.8em;
  cursor: pointer;
}
.pagination-item button {
  padding: 0.75rem 1rem;
  margin: 2px;
  border: 1px solid #cccccc;
  cursor: pointer;
  background-color: white;
  border-radius: 9px;
}
</style>