<template>
  <section v-if="client">
    <h1>Clientes</h1>
    <div class="contentGeneral">
      <h3>Adicionar Cliente</h3>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'clientes' }"
              >Lista de Clientes</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Adicionar Clientes</span>
          </li>
        </ol>
      </nav>
      <transition mode="out-in">
        <ClientForm :client="client" :errors="errors">
          <div slot="delete" id="client">
            <button class="btn btn-form" @click.prevent="addClient">
              Adicionar
            </button>
          </div>
        </ClientForm>
      </transition>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </section>
</template>

<script>
import ClientForm from "@/components/client/ClientForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ClientAdd",
  props: ["idClient"],
  data() {
    return {
      create: false,
    };
  },
  components: {
    ClientForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("client", ["client"]),
    ...mapGetters("client", ["clientReset", "clientReply"]),
  },
  methods: {
    addClient() {
      this.$store.dispatch("client/addClient", {
        client: this.clientReply, //enviar getters com as infos necessárias para o cadastro
        // idModule: this.idCollaborator,
        // mutation: "'collaborator/updateClientCollaborator'",
      });
    },
  },
  created() {
    this.$store.commit("client/UPDATE_CLIENT", this.clientReset);
  },
};
</script>

<style scoped>
#address {
  grid-column: 2;
}
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
</style>
