<template>
  <section class="">
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Dados do tipo de Viatura</h3>
        <div v-if="vehicleManager || vehicleEdit">
          <router-link
            class="collabItens"
            :to="{
              name: 'editar-tipo-viatura',
              params: {
                idTypeVehicle: idTypeVehicle,
              },
            }"
          >
            <AddUser>
              <span slot="title">
                Editar<br />
                Tipo de Viatura</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'tipos-viaturas' }"
              >Lista de Tipos de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Dados do Tipo de Viatura</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingVehicle">
        <Loading />
      </div>
      <div v-else>
        <article v-if="typeVehicle">
          <!-- <pre>{{ typeVehicle }}</pre> -->
          <ul class="borderTable">
            <li class="items itemList" v-if="typeVehicle.name">
              <span class="titleBlue">Nome</span>
              <div class="list">
                <span class="textGray">{{ typeVehicle.name }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="typeVehicle.description">
              <span class="titleBlue">Descrição</span>
              <div class="list">
                <span class="textGray">{{ typeVehicle.description }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="typeVehicle.created">
              <span class="titleBlue">Criado</span>
              <div class="list">
                <span class="textGray">{{
                  typeVehicle.created | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ createdVehicle }}</span> -->
              </div>
            </li>
            <li class="items itemList" v-if="typeVehicle.modified">
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <span class="textGray">{{
                  typeVehicle.modified | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ modifiedVehicle }}</span> -->
              </div>
            </li>
          </ul>
          <article class="articleInfos">
            <h3 class="titleInfos">Viaturas Associadas</h3>
            <div
              v-if="
                otherVehicles &&
                otherVehicles.length > 0 &&
                (vehicleEdit || vehicleManager)
              "
            >
              <div class="notify2">
                <select v-model="vehicleId" class="otherService">
                  <option value="null">Selecione uma viatura</option>
                  <option
                    id="config"
                    v-for="(vehicle, index) in otherVehicles"
                    :key="vehicle.id"
                    :selected="index"
                    :value="vehicle.id"
                  >
                    {{ vehicle.registration }}
                  </option>
                </select>
              </div>
              <div>
                <button class="btn btn-form" @click.prevent="updateVehicle()">
                  Adicionar
                </button>
              </div>
            </div>
            <div v-if="typeVehicle.vehicles && typeVehicle.vehicles.length > 0">
              <ul v-for="(vehicle, i) in typeVehicle.vehicles" :key="i">
                <router-link
                  class=""
                  :to="{ name: 'viatura', params: { idVehicle: vehicle.id } }"
                >
                  <div class="itemListVehicle">
                    <li class="items collabItens">
                      <span class="titleBlue">Matrícula</span>
                      <div class="list">
                        <span class="textGray">{{ vehicle.registration }}</span>
                      </div>
                    </li>
                    <button
                      slot="delete"
                      class="delete"
                      @click.prevent="confirmDeleteVehicle(vehicle.id)"
                    ></button>
                  </div>
                </router-link>
              </ul>
            </div>
            <div v-else>
              <span class="itemList textGray item2"
                >Não possui viaturas associadas</span
              >
            </div>
          </article>
          <article class="articleInfos">
            <h3 class="titleInfos">Serviços</h3>
            <div
              v-if="otherServices != null && (vehicleEdit || vehicleManager)"
            >
              <div class="notify2">
                <!-- <select v-model="serviceId" :class="validateConfig"> -->
                <select v-model="serviceID" class="otherService">
                  <option disabled value="null">Selecione um serviço</option>
                  <option
                    id="config"
                    v-for="(service, index) in otherServices"
                    :key="service.id"
                    :selected="index"
                    :value="service.id"
                  >
                    {{ service.name }}
                  </option>
                </select>
              </div>
              <div>
                <button
                  class="btn btn-form"
                  @click.prevent="addServiceTypeVehicle"
                >
                  Adicionar
                </button>
              </div>
            </div>
            <div v-if="typeVehicle.services && typeVehicle.services.length > 0">
              <!-- <div v-if="serviceActive && serviceInactive">
                <DualListBox
                  :source="serviceActive"
                  :destination="serviceInactive"
                  @onChangeList="onChangeList"
                  :errors="errors"
                />
              </div> -->
              <ul>
                <li
                  v-for="(service, index) in typeVehicle.services"
                  :key="service.id"
                >
                  <article class="articleInfos">
                    <ServiceTypeVehicleItem
                      :service="service"
                      :idTypeVehicle="idTypeVehicle"
                      :index="index"
                    >
                      <button
                        slot="delete"
                        class="delete"
                        @click.prevent="
                          confirmDeleteServiceTypeVehicle(service.id)
                        "
                      ></button>
                    </ServiceTypeVehicleItem>
                  </article>
                </li>
              </ul>
            </div>
            <div v-else>
              <span class="itemList textGray item2"
                >Não possui serviços associados</span
              >
            </div>
          </article>
        </article>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteVehicle"
        @click="closeModalDelVehicle"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteVehicle">
        <button class="close btn" @click="closeModalDelVehicle">x</button>
        <p class="delItem">
          <span
            >Você tem certeza que deseja remover a associação desta
            viatura?</span
          >
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelVehicle"
          />
          <button class="btnDel" @click.prevent="deleteVehicle">Delete</button>
        </div>
      </div>
    </div>
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteServiceVehicle"
        @click="closeModalDelServiceTypeVehicle"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteServiceVehicle">
        <button class="close btn" @click="closeModalDelServiceTypeVehicle">
          x
        </button>
        <p class="delItem">
          <span
            >Você tem certeza que deseja remover este serviço do tipo de
            viatura?</span
          >
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelServiceTypeVehicle"
          />
          <button class="btnDel" @click.prevent="deleteServiceTypeVehicle">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import ServiceTypeVehicleItem from "@/components/vehicle/ServiceTypeVehicleItem.vue";
// import DualListBox from "@/components/items/DualListBox.vue";
import { mapState, mapGetters } from "vuex";
// import { formatDate } from "@/helpers.js";
import { mapFields } from "@/helpers.js";

export default {
  name: "ContactoInternoInfos",
  props: ["idTypeVehicle"],
  data() {
    return {
      searchHistory: false,
    };
  },

  components: {
    ServiceTypeVehicleItem,
    // DualListBox,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId"]),
    ...mapState("user", ["user"]),
    ...mapState("vehicle", [
      "typeVehicle",
      "typeVehicles",
      "vehicles",
      "loadingVehicle",
      "showModalDeleteVehicle",
      "showModalDeleteServiceVehicle",
      "vehicleId",
    ]),
    ...mapState("service", ["services"]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),
    ...mapGetters("vehicle", [
      "serviceTypeVehicleReset",
      "serviceTypeVehicleReply",
      "otherServices",
      "otherVehicles",
    ]),

    ...mapFields({
      fields: ["serviceID"], //nome dos campos
      module: "vehicle", //qual módulo está acessando
      base: "serviceTypeVehicle", //colocar o state que vai receber
      mutation: "vehicle/UPDATE_SERVICETYPEVEHICLE", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["vehicleId"], //nome dos campos
      module: "vehicle", //qual módulo está acessando
      base: "vehicleId", //colocar o state que vai receber
      mutation: "vehicle/UPDATE_VEHICLEID", //nome da mutation que vai alterar o state
    }),
    //##################################################
    //                 VEHICLES
    //##################################################
    vehicleTypeName() {
      if (this.typeVehicles != null) {
        return this.typeVehicles.find((telecomPhone) => {
          return telecomPhone.id == this.vehicle.vehicleTypeID;
        }).name;
      } else {
        return "";
      }
    },
    // createdVehicle() {
    //   return formatDate(new Date(this.typeVehicle.created));
    // },

    // modifiedVehicle() {
    //   return formatDate(new Date(this.typeVehicle.modified));
    // },
  },
  methods: {
    updateVehicle() {
      this.$store.dispatch("vehicle/updateVehicle", {
        idVehicle: this.vehicleId,
        vehicle: { vehicleTypeID: this.typeVehicle.id },
        idTypeVehicle: this.typeVehicle.id,
      });
    },
    //######################################################
    //     DELETE SERVICE TYPE VEHICLE
    //######################################################
    openModalDelVehicle() {
      this.$store.dispatch("vehicle/openModalDelVehicle");
    },
    closeModalDelVehicle() {
      this.$store.dispatch("vehicle/closeModalDelVehicle");
    },
    confirmDeleteVehicle(id) {
      this.$store.dispatch("vehicle/confirmDeleteVehicle", {
        id: `${id}`,
      });
    },
    deleteVehicle() {
      this.$store.dispatch("vehicle/updateVehicle", {
        idVehicle: this.selectedId.id,
        vehicle: { vehicleTypeID: "null" },
        idTypeVehicle: this.typeVehicle.id,
      });
      this.closeModalDelVehicle();
    },
    //######################################################
    //     ADICIONAR SERVICE TYPE VEHICLE
    //######################################################
    addServiceTypeVehicle() {
      this.$store.dispatch("vehicle/addServiceTypeVehicle", {
        serviceTypeVehicle: this.serviceTypeVehicleReply,
        idTypeVehicle: this.idTypeVehicle,
      });
    },
    //######################################################
    //     DELETE SERVICE TYPE VEHICLE
    //######################################################
    openModalDelServiceTypeVehicle() {
      this.$store.dispatch("vehicle/openModalDelServiceTypeVehicle");
    },
    closeModalDelServiceTypeVehicle() {
      this.$store.dispatch("vehicle/closeModalDelServiceTypeVehicle");
    },
    confirmDeleteServiceTypeVehicle(id) {
      // console.log("clicou", id);
      this.$store.dispatch("vehicle/confirmDeleteServiceTypeVehicle", {
        id: `${id}`,
      });
    },
    deleteServiceTypeVehicle() {
      this.$store.dispatch("vehicle/deleteServiceTypeVehicle", {
        id: this.idTypeVehicle,
        idServiceTypeVehicle: this.selectedId.id,
      });
      this.closeModalDelServiceTypeVehicle();
    },
  },
  created() {
    this.$store.dispatch("vehicle/getTypeVehicle", {
      idTypeVehicle: this.idTypeVehicle,
      refreshId: "refreshId",
    });
    this.$store.dispatch("service/getServices");
    this.$store.commit(
      "vehicle/UPDATE_SERVICETYPEVEHICLE",
      this.serviceTypeVehicleReset
    );
    // this.$store.dispatch("service/getServices", "refresh");
    this.$store.dispatch("vehicle/getVehicles", "refresh");
    // this.$store.dispatch("vehicle/getTypeVehicles", "refresh");
  },
};
</script>

<style scoped>
.itemListVehicle {
  padding: 9px 0;
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  /* justify-content: space-between; */
  border-bottom: 1px solid #cbcbcb;
}
button.btn-form {
  margin: 20px auto;
}
.collabItens:hover {
  background: #dbebf3;
}

.collabItens {
  margin: 10px 0;
}
.borderTable {
  margin: 20px 0;
  box-shadow: 0 4px 8px rgb(30 60 90 / 20%);
  padding: 20px 10px;
  border-radius: 9px;
}

.item2 {
  padding-left: 15px;
}
.containerLink {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.articleInfos,
.callsHistory {
  margin-bottom: 30px;
}

.titleInfos {
  margin-bottom: 10px;
}

.iconInfoSearchHistory {
  margin: 0 10px;
}
.otherService,
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
}
</style>
