import { api } from "@/services.js";
import router from './../../router/index.js'
import { removeAccent } from "@/helpers.js";
import { serviceHasSearchWord } from "@/helpers.js";
import { getLastDate } from "@/helpers.js";
import { formatDate } from "@/helpers.js";


export default {
  namespaced: true,
  state: {
    //############################
    //       Vehicle            
    //############################
    vehicles: null,
    vehicle: {
      registration: null,
      vehicleTypeID: null,
      modelID: null,
      nextInspectionDate: null,
      files: null,
    },
    vehicleId: {
      vehicleId: null
    },
    //############################
    //       Dashboard            
    //############################
    vehicleStatus:null,
    //############################
    //           BRAND  
    //############################
    brands: null,
    brand: {
      name: null
    },
    //############################
    //           MODEL  
    //############################
    models: null,
    model: {
      name: null,
      brandID: null
    },
    //############################
    //       Type Vehicle            
    //############################
    typeVehicles: null,
    typeVehicle: {
      name: null,
      description: null,
      vehicles: [],
      services: [],
      insuranceFiles: []
    },
    //############################
    //     Inspections Vehicle            
    //############################
    inspections: null,
    inspectionHistory: null,
    inspection: {
      date: null,
      km: null,
      description: null,
      costs: null,
      files: [],
    },
    //############################
    //       Revision Vehicle            
    //############################
    revisions: null,
    revisionHistory: null,
    revision: {
      date: null,
      km: null,
      description: null,
      costs: null,
      files: [],
    },
    //############################
    //       Insurance Vehicle            
    //############################
    insurances: null,
    insurance: {
      startDate: null,
      endDate: null,
      apolicyNumber: null,
      insuranceFiles: [],
    },
    dateNow: null,
    // img: {
    //   file: null,
    // },  

    //############################
    //      Service Vehicle            
    //############################
    servicesTypeVehicle: null,
    serviceTypeVehicle: {
      serviceID: null,
    },
    //############################
    //      ficheiro            
    //############################
    selectedFile: null,
    archiveID: null,
    // fileID: null,
    //############################
    //         LOADING            
    //############################
    loadingVehicle: false,
    //############################
    //         MODAL            
    //############################
    showModalDeleteVehicle: false,
    showModalDeleteTypeVehicle: false,
    showModalDeleteInspection: false,
    showModalDeleteRevision: false,
    showModalDeleteServiceVehicle: false,
    showModalDeleteInsurance: false,
    showModalDeleteRefuelling: false,
    showModalDeleteFile: false,
    showModalPutNextInspection: false,
    showModalDeleteBrand: false,
    showModalCreateBrand: false,
    showModalDeleteModel: false,
    //###############
    //  PAGINAÇÃO
    //###############
    totalPages: 0,
    //###############
    //  PESQUISA
    //###############
    searchWord: null,
    dateInspection: null,
    dateRevision: null,
    insuranceStartDate: null,
    insuranceEndDate: null,
    inspectionStartDate: null,
    inspectionEndDate: null,
    revisonStartDate: null,
    revisonEndDate: null,
    nextInspectionStartDate: null,
    nextInspectionEndDate: null,
    dateInsurance: null,
    filtered: null,
    contactFilter: [],
  },
  //########################################################################################
  //                 GETTERS            
  //########################################################################################
  getters: {
    //###############
    //  PESQUISA
    //###############
    getFiltered: (state) => state.filtered,

    //###############
    //  VEHICLE
    //###############
    vehicleIdReply(state) {
      return { "id": state.vehicleId.vehicleId }
    },
    vehicleIdReset() { //Enviar somente estas informações para cadastro e edição.
      return {
        vehicleId: null,
      }
    },
    vehicleReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "registration": state.vehicle.registration,
        "vehicleTypeID": state.vehicle.vehicleTypeID,
        "modelID": state.vehicle.modelID,
        "nextInspectionDate": state.vehicle.nextInspectionDate,
      }
    },
    vehicleReset() { //Enviar somente estas informações para cadastro e edição.
      return {
        registration: null,
        vehicleTypeID: null,
        modelID: null,
        nextInspectionDate: null,
      }
    },
    vehicleStatus(state) {
      let vehiclesItens = state.vehicles;
      if (vehiclesItens != null) {
        vehiclesItens = state.vehicles.filter((vehicle) => {
          return (
            vehicle.status.inspection.status === "Danger" ||
            vehicle.status.inspection.status === "Warning" ||
            vehicle.status.insurance.reason === "Automovel sem seguro!" ||
            vehicle.status.insurance.status === "Danger" ||
            vehicle.status.insurance.status === "Warning"
          );
        });
      }
      return vehiclesItens;
    },
    //###############
    // TYPE VEHICLE
    //###############

    typeVehicleReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "name": state.typeVehicle.name,
        "description": state.typeVehicle.description,
      }
    },
    typeVehicleReset() { //Enviar somente estas informações para cadastro e edição.
      return {
        name: null,
        description: null,
        vehicles: [],
        services: []
      }
    },

    //###############
    //  SERVICE
    //###############

    serviceTypeVehicleReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "serviceID": state.serviceTypeVehicle.serviceID,
      }
    },
    serviceTypeVehicleReset() { //Enviar somente estas informações para cadastro e edição.
      return {
        serviceID: null,
      }
    },
    otherVehicles(state) {
      // return console.log(rootState.service.services)
      // typeVehicle.vehicles
      var active = state.vehicles;
      if (active != null) {
        for (var index in state.typeVehicle.vehicles) {
          active = active.filter(
            (service) => service.id != state.typeVehicle.vehicles[index].id
          );
        }
      }
      return active;
    },
    otherServices(state, getters, rootState) {
      // return console.log(rootState.service.services)
      var active = rootState.service.services;
      if (active != null) {
        for (var index in state.typeVehicle.services) {
          active = active.filter(
            (service) => service.id != state.typeVehicle.services[index].id
          );
        }
      }
      return active;
    },

    //###############
    //  INSPECTIONS
    //###############

    inspectionReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "date": state.inspection.date,
        "km": state.inspection.km,
        "description": state.inspection.description,
        "costs": state.inspection.costs,
      }
    },
    inspectionReset() { //Enviar somente estas informações para cadastro e edição.
      return {
        date: null,
        km: null,
        description: null,
        costs: null,
      }
    },

    //###############
    //  REVISION
    //###############

    revisionReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "date": state.revision.date,
        "km": state.revision.km,
        "description": state.revision.description,
        "costs": state.revision.costs,
      }
    },
    revisionReset() { //Enviar somente estas informações para cadastro e edição.
      return {
        date: null,
        km: null,
        description: null,
        costs: null,
      }
    },
    //###############
    //  INSURANCE
    //###############

    insuranceReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "startDate": state.insurance.startDate,
        "endDate": state.insurance.endDate,
        "apolicyNumber": state.insurance.apolicyNumber
      }
    },
    insuranceReset() { //Enviar somente estas informações para cadastro e edição.
      return {
        startDate: null,
        endDate: null,
        apolicyNumber: null,
      }
    },
    //###############
    //  BRAND
    //###############
    brandReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "name": state.brand.name
      }
    },
    brandReset() { //Enviar somente estas informações para cadastro e edição.
      return {
        name: null
      }
    },
    //###############
    //  MODEL
    //###############
    modelReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "name": state.model.name,
        "brandID": state.model.brandID,
      }
    },
    modelReset() { //Enviar somente estas informações para cadastro e edição.
      return {
        name: null,
        brandID: null,
      }
    },

    //###############
    //  PAGINAÇÃO
    //###############
    paginatedData(state, getters, rootState) {
      // console.log("rootState.currentPage", rootState.currentPage)//acessando o state da global
      let start = (rootState.currentPage - 1) * rootState.limit,
        end = start + rootState.limit;
      if (state.filtered) {
        return state.filtered.slice(start, end);
      } else {
        return state.vehicles.slice(start, end);
      }
    },

  },
  //########################################################################################
  //                 MUTATIONS            
  //########################################################################################
  mutations: {

    UPDATE_VEHICLEID(state, payload) {
      state.vehicleId = payload;
    },
    //###############
    //  FICHEIRO
    //###############
    UPDATE_SELECTEDFILE(state, payload) {
      state.selectedFile = payload;
    },
    //###############
    //  PAGINAÇÃO
    //###############
    UPDATE_TOTAL_PAGES(state, payload) {
      state.totalPages = payload;
    },
    //########################### 
    //    LOADING
    //###########################
    UPDATE_LOADING(state, payload) {
      state.loadingVehicle = payload;
    },

    //##################################################
    //                  DELETE
    //##################################################
    UPDATE_DELETE_VEHICLE(state, id) {
      let vehicles = state.vehicles.filter(c => c.id != id)
      state.vehicles = vehicles;
    },
    UPDATE_DELETE_TYPEVEHICLE(state, id) {
      let typeVehicles = state.typeVehicles.filter(c => c.id != id)
      state.typeVehicles = typeVehicles;
    },
    UPDATE_DELETE_SERVICETYPEVEHICLE(state, idServiceTypeVehicle) {
      let serviceTypeVehicle = state.typeVehicle.services.filter(c => c.id != idServiceTypeVehicle)
      state.typeVehicle.services = serviceTypeVehicle;
    },
    UPDATE_DELETE_INSPECTION(state, idInspection) {
      let inspections = state.inspections.filter(c => c.id != idInspection)
      state.inspections = inspections;
    },
    UPDATE_DELETE_REVISION(state, idRevision) {
      let revisions = state.revisions.filter(c => c.id != idRevision)
      state.revisions = revisions;
    },
    UPDATE_DELETE_INSURANCE(state, idInsurance) {
      let insurances = state.insurances.filter(c => c.id != idInsurance)
      state.insurances = insurances;
    },
    UPDATE_DELETE_BRAND(state, idBrand) {
      let brands = state.brands.filter(c => c.id != idBrand)
      state.brands = brands;
    },
    UPDATE_DELETE_MODEL(state, idModel) {
      let models = state.models.filter(c => c.id != idModel)
      state.models = models;
    },
    

    //DELETE ATTACHMENT
    //##################################################
    UPDATE_DELETE_FILE(state, { id, attachmentID }) {
      const index = state.insurances.findIndex(insurance => insurance.id == id)
      let files = state.insurances[index].files.filter(c => c.id != attachmentID)
      state.insurances[index].files = files;

    },
    UPDATE_DELETE_FILEINSPECTION(state, { id, attachmentID }) {
      // console.log("passou - archiveID", id, attachmentID)
      const index = state.inspections.findIndex(inspection => inspection.id == id)
      let files = state.inspections[index].files.filter(c => c.id != attachmentID)
      state.inspections[index].files = files;
    },
    UPDATE_DELETE_FILEREVISION(state, { id, attachmentID }) {
      const index = state.revisions.findIndex(revision => revision.id == id)
      let files = state.revisions[index].files.filter(c => c.id != attachmentID)
      state.revisions[index].files = files;
    },
    UPDATE_DELETE_FILEVEHICLE(state, archiveID) {
      let vehicleFile = state.vehicle.files.filter(c => c.id != archiveID)
      state.vehicle.files = vehicleFile;
    },
    UPDATE_SELECTED_ARCHIVE(state, { archiveID }) {
      state.archiveID = archiveID;
      // state.fileID = fileID;
    },

    //    MODAL ATUALIZAR NEXTINSPECTTION
    //##################################################
    UPDATE_MODAL_PUT_NEXTINSPECTION(state, payload) {
      state.showModalPutNextInspection = payload;
    },

    //    MODAL DELETE VEHICLE
    //##################################################
    UPDATE_MODAL_DELETE_VEHICLE(state, payload) {
      state.showModalDeleteVehicle = payload;
    },
    UPDATE_MODAL_DELETE_TYPEVEHICLE(state, payload) {
      state.showModalDeleteTypeVehicle = payload;
    },
    UPDATE_MODAL_DELETE__SERVICE_TYPEVEHICLE(state, payload) {
      state.showModalDeleteServiceVehicle = payload;
    },
    UPDATE_MODAL_DELETE_INSPECTION(state, payload) {
      state.showModalDeleteInspection = payload;
    },
    UPDATE_MODAL_DELETE_REVISION(state, payload) {
      state.showModalDeleteRevision = payload;
    },
    UPDATE_MODAL_DELETE_INSURANCE(state, payload) {
      state.showModalDeleteInsurance = payload;
    },
    UPDATE_MODAL_DELETE_FILE(state, payload) {
      state.showModalDeleteFile = payload;
    },
    UPDATE_MODAL_DELETE_BRAND(state, payload) {
      state.showModalDeleteBrand = payload;
    },      
    UPDATE_MODAL_DELETE_MODEL(state, payload) {
      state.showModalDeleteModel = payload;
    },
    //################################################################# 
    //       MODAL CREATE BRAND
    //#################################################################
    UPDATE_MODAL_CREATE_BRAND(state, payload) {
      state.showModalCreateBrand = payload;
    },  
    //################################################################# 
    //       VEHICLES
    //#################################################################
    UPDATE_VEHICLES(state, payload) {
      // console.log("payload", payload)
      if (payload != null && payload != "")
        state.vehicles = payload.sort((a, b) => {
          let x = a.registration.toUpperCase(),
            y = b.registration.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.vehicles = payload
        
      //Dashboard  
      // let vehiclesItens = state.vehicles;
      // if (vehiclesItens != null) {
      //   vehiclesItens = state.vehicles.filter((vehicle) => {
      //     return (
      //       vehicle.status.inspection.status === "Danger" ||
      //       vehicle.status.inspection.status === "Warning" ||
      //       vehicle.status.insurance.reason === "Automovel sem seguro!" ||
      //       vehicle.status.insurance.status === "Danger" ||
      //       vehicle.status.insurance.status === "Warning"
      //     );
      //   });
      // }
      // state.vehicleStatus = vehiclesItens
    },
    UPDATE_VEHICLE_DASHBOARD(state, payload) { 
      let vehiclesItens = payload;
      if (vehiclesItens != null) {
        vehiclesItens = payload.filter((vehicle) => {
          return (
            vehicle.status.inspection.status === "Danger" ||
            vehicle.status.inspection.status === "Warning" ||
            vehicle.status.insurance.reason === "Automovel sem seguro!" ||
            vehicle.status.insurance.status === "Danger" ||
            vehicle.status.insurance.status === "Warning"
          );
        });
      }
      state.vehicleStatus = vehiclesItens
    },
    //    VEHICLE
    //##################################################
    UPDATE_VEHICLE(state, payload) {
      state.vehicle = Object.assign(state.vehicle, payload);
    },

    //################################################################# 
    //       TYPE VEHICLES
    //#################################################################
    UPDATE_TYPEVEHICLES(state, payload) {
      // console.log(state.filtered)
      if (payload != null && payload != " ")
        state.typeVehicles = payload.sort((a, b) => {
          let x = a.name.toUpperCase(),
            y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.typeVehicles = payload
    },
    //    TYPE  VEHICLE
    //##################################################
    UPDATE_TYPEVEHICLE(state, payload) {
      state.typeVehicle = Object.assign(state.typeVehicle, payload);
    },

    //##################################################
    //              SERVICES - TYPE VEHICLES
    //################################################## 
    UPDATE_SERVICESTYPEVEHICLE(state, payload) {
      // console.log(state.filtered)
      if (payload != null && payload != " ")
        state.servicesTypeVehicle = payload.sort((a, b) => {
          let x = a.name.toUpperCase(),
            y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.servicesTypeVehicle = payload
    },
    // SERVICE - TYPE VEHICLES
    //################################################## 
    UPDATE_SERVICETYPEVEHICLE(state, payload) {
      state.serviceTypeVehicle = Object.assign(state.serviceTypeVehicle, payload);
    },

    //##################################################
    //              INSPECTIONS - VEHICLES
    //################################################## 
    UPDATE_INSPECTIONS(state, payload) {
      // console.log(state.filtered)
      if (payload != null && payload != " ")
        state.inspections = payload.sort((a, b) => {
          let x = a.date.toUpperCase(),
            y = b.date.toUpperCase();
          return x == y ? 0 : x < y ? 1 : -1;
        });
      else state.inspections = payload
    },
    // INSPECTION - VEHICLES
    //################################################## 
    UPDATE_INSPECTIONSHISTORY(state, payload) {
      state.inspectionHistory = payload
    },
    UPDATE_INSPECTION(state, payload) {
      state.inspection = Object.assign(state.inspection, payload);
    },

    //##################################################
    //              REVISIONS - VEHICLES
    //################################################## 
    UPDATE_REVISIONS(state, payload) {
      // console.log(state.filtered)
      if (payload != null && payload != " ")
        state.revisions = payload.sort((a, b) => {
          let x = a.date.toUpperCase(),
            y = b.date.toUpperCase();
          return x == y ? 0 : x < y ? 1 : -1;
        });
      else state.revisions = payload
    },
    // REVISION - VEHICLES
    //################################################## 
    UPDATE_REVISIONSHISTORY(state, payload) {
      state.revisionHistory = payload
    },
    UPDATE_REVISION(state, payload) {
      state.revision = Object.assign(state.revision, payload);
    },

    //##################################################
    //              INSURANCES - VEHICLES
    //################################################## 
    UPDATE_INSURANCES(state, payload) {
      // console.log(state.filtered)
      if (payload != null && payload != " ")
        state.insurances = payload.sort((a, b) => {
          let x = a.endDate.toUpperCase(),
            y = b.endDate.toUpperCase();
          return x == y ? 0 : x < y ? 1 : -1;
        });
      else state.insuraces = payload
    },
    // INSURANCE - VEHICLES
    //################################################## 
    UPDATE_INSURANCE(state, payload) {
      state.insurance = Object.assign(state.insurance, payload);
    },

    //################################################################# 
    //       BRANDS
    //#################################################################
    UPDATE_BRANDS(state, payload) {
      // console.log(state.filtered)
      if (payload != null && payload != " ")
        state.brands = payload.sort((a, b) => {
          let x = a.name.toUpperCase(),
            y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.brands = payload
    },
    //    BRAND
    //##################################################
    UPDATE_BRAND(state, payload) {
      state.brand = Object.assign(state.brand, payload);
    },

    //################################################################# 
    //       MODELS
    //#################################################################
    UPDATE_MODELS(state, payload) {
      // console.log(state.filtered)
      if (payload != null && payload != " ")
        state.models = payload.sort((a, b) => {
          let x = a.name.toUpperCase(),
            y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.models = payload
    },
    //    MODEL
    //##################################################
    UPDATE_MODEL(state, payload) {
      state.model = Object.assign(state.model, payload);
    },



    //##################################################
    //                FILTROS
    //##################################################

    //RESET FILTER
    //##################################################
    RESET_FILTER(state, clean) {
      state.searchWord = clean;
      state.filtered = clean;
      state.dateInspection = clean;
      state.dateRevision = clean;
      state.dateInsurance = clean;
      state.insuranceStartDate = clean;
      state.insuranceEndDate = clean;
      state.inspectionStartDate = clean;
      state.inspectionEndDate = clean;
      state.revisonStartDate = clean;
      state.revisonEndDate = clean;
      state.nextInspectionStartDate = clean;
      state.nextInspectionEndDate = clean;
    },
    RESET_FILTER_SEARCH(state, clean) {
      state.searchWord = clean;
      // state.filtered = clean;
    },
    RESET_FILTER_DATAINSPECTION(state, clean) {
      // state.filtered = clean;
      state.dateInspection = clean;
    },
    RESET_FILTER_DATAREVISION(state, clean) {
      // state.filtered = clean;
      state.dateRevision = clean;
    },
    RESET_FILTER_DATAINSURANCE(state, clean) {
      state.dateInsurance = clean;
    },
    //SEARCH WORD
    //##################################################
    UPDATE_SEARCHWORD(state, payload) {
      state.searchWord = payload;
    },
    RESET_FILTER_SEARCHWORD(state, clean) {
      // state.filtered = clean;
      state.searchWord = clean;
    },
    //PORXIMA INSPECTION - Início startDate e endDate
    //##################################################
    UPDATE_STARTDATE_NEXTINSPECTION(state, payload) {
      state.nextInspectionStartDate = payload;
    },
    UPDATE_ENDDATE_NEXTINSPECTION(state, payload) {
      state.nextInspectionEndDate = payload;
    },
    //REVISION - Início startDate e endDate
    //##################################################
    UPDATE_STARTDATE_INSPECTION(state, payload) {
      state.inspectionStartDate = payload;
    },
    UPDATE_ENDDATE_INSPECTION(state, payload) {
      state.inspectionEndDate = payload;
    },
    //REVISION - Início startDate e endDate
    //##################################################
    UPDATE_STARTDATE_REVISION(state, payload) {
      state.revisonStartDate = payload;
    },
    UPDATE_ENDDATE_REVISION(state, payload) {
      state.revisonEndDate = payload;
    },
    //Insurance - Início startDate e endDate
    //##################################################
    UPDATE_STARTDATE_INSURANCE(state, payload) {
      state.insuranceStartDate = payload;
    },
    UPDATE_ENDDATE_INSURANCE(state, payload) {
      state.insuranceEndDate = payload;
    },

    //    FILTERED VEHICLES
    //#################################################################
    FILTERED_VEHICLE(state, { searchWord, nextInspectionStartDate, nextInspectionEndDate, inspectionStartDate, inspectionEndDate, revisonStartDate, revisonEndDate, insuranceStartDate, insuranceEndDate }) {
      if ((searchWord == null || searchWord == "") && (nextInspectionStartDate == null) && (nextInspectionEndDate == null) && (inspectionStartDate == null) && (inspectionEndDate == null) && (revisonStartDate == null) && (revisonEndDate == null) && (insuranceStartDate == null) && (insuranceEndDate == null)) {
        // console.log("anular")

        state.filtered = null
      } else {
        let swTreated,
          dateNextInspection,
          startDateNextInspection,
          endDateNextInspection,
          // dateInspection,
          // startDateInspection,
          // endDateInspection,
          dateRevision,
          startDateRevision,
          endDateRevision,
          dateInsurance,
          startDateInsurance,
          endDateInsurance,
          filteredArray = [],
          registration,
          serviceName
        searchWord != null ? swTreated = removeAccent(searchWord.toString().toLowerCase().trim()) : null;
        state.vehicles.forEach(vehicle => {
          let failCounter = 0;
          if (swTreated != null || "") {
            serviceName = vehicle.services != null && vehicle.services.length > 0 && serviceHasSearchWord(swTreated, vehicle.services)
            registration = removeAccent(vehicle.registration.toString().toLowerCase().trim());
          }
          //NEXTINSPECTION
          nextInspectionStartDate != null ? startDateNextInspection = new Date(nextInspectionStartDate) : null
          nextInspectionEndDate != null ? endDateNextInspection = new Date(nextInspectionEndDate) : null
          dateNextInspection = new Date(vehicle.nextInspectionDate);
          // console.log("startDateNextInspection", startDateNextInspection)
          // console.log("endDateNextInspection", endDateNextInspection)
          // console.log("dateNextInspection", dateNextInspection)

          //INSPECTION
          // let inspectionMatch = 0
          // inspectionStartDate != null ? startDateInspection = new Date(inspectionStartDate) : null
          // inspectionEndDate != null ? endDateInspection = new Date(inspectionEndDate) : null

          // let lastInspectionDate = getLastDate(vehicle.inspections, "date")
          // console.log("lastInspectionDate", lastInspectionDate)
          // if (vehicle.inspections != null && vehicle.inspections.length > 0) {
          //   vehicle.inspections.forEach(inspection => {
          //     dateInspection = new Date(inspection.date);
          //     if (formatDate(dateInspection) == formatDate(lastInspectionDate)) {
          //       if (startDateInspection != null && endDateInspection == null) {
          //         if (dateInspection >= startDateInspection) {
          //           inspectionMatch++;
          //         }
          //       }
          //       if (endDateInspection != null && startDateInspection == null) {
          //         if (dateInspection <= endDateInspection) {
          //           inspectionMatch++;
          //         }
          //       }
          //       if (startDateInspection != null && endDateInspection != null) {
          //         if (startDateInspection <= dateInspection && dateInspection <= endDateInspection) {
          //           inspectionMatch++;
          //         }
          //       }
          //     }
          //   })
          // }
          // if (inspectionMatch == 0 && (startDateInspection != null || endDateInspection != null)) {
          //   failCounter++;
          // }

          //REVISION
          let revisionMatch = 0
          revisonStartDate != null ? startDateRevision = new Date(revisonStartDate) : null
          revisonEndDate != null ? endDateRevision = new Date(revisonEndDate) : null

          let lastRevisionDate = getLastDate(vehicle.revisions, "date")
          if (vehicle.revisions != null && vehicle.revisions.length > 0) {
            vehicle.revisions.forEach(revision => {
              dateRevision = new Date(revision.date);
              if (formatDate(dateRevision) == formatDate(lastRevisionDate)) {
                if (startDateRevision != null && endDateRevision == null) {
                  if (dateRevision >= startDateRevision) {
                    revisionMatch++;
                  }
                }
                if (endDateRevision != null && startDateRevision == null) {
                  if (dateRevision <= endDateRevision) {
                    revisionMatch++;
                  }
                }
                if (startDateRevision != null && endDateRevision != null) {
                  if (startDateRevision <= dateRevision && dateRevision <= endDateRevision) {
                    revisionMatch++;
                  }
                }
              }
            })
          }
          if (revisionMatch == 0 && (startDateRevision != null || endDateRevision != null)) {
            failCounter++;
          }

          //INSURANCE
          let insuranceMatch = 0
          insuranceStartDate != null ? startDateInsurance = new Date(insuranceStartDate) : null
          insuranceEndDate != null ? endDateInsurance = new Date(insuranceEndDate) : null

          let lastInsuranceDate = getLastDate(vehicle.insurances, "endDate")
          if (vehicle.insurances != null && vehicle.insurances.length > 0) {
            vehicle.insurances.forEach(insurance => {
              dateInsurance = new Date(insurance.endDate);
              if (formatDate(dateInsurance) == formatDate(lastInsuranceDate)) {
                if (startDateInsurance != null && endDateInsurance == null) {
                  if (startDateInsurance <= dateInsurance) {
                    insuranceMatch++;
                  }
                }
                if (endDateInsurance != null && startDateInsurance == null) {
                  if (dateInsurance <= endDateInsurance) {
                    insuranceMatch++;
                  }
                }
                if (startDateInsurance != null && endDateInsurance != null) {
                  if (startDateInsurance <= dateInsurance && dateInsurance <= endDateInsurance) {
                    insuranceMatch++;
                  }
                }
              }
            })
          }
          if (insuranceMatch == 0 && (startDateInsurance != null || endDateInsurance != null)) {
            failCounter++;
          }
          //VERIFICANDO REGISTRATION
          if (swTreated != null && swTreated != '') {
            if (!(registration.includes(swTreated) || serviceName)) {
              failCounter++;
            }
          }
          //VERIFICANDO NEXTINSPECTION
          if (startDateNextInspection != null) {
            if (startDateNextInspection > dateNextInspection) {
              failCounter++;
            }
          }
          if (endDateNextInspection != null) {
            if (endDateNextInspection < dateNextInspection) {
              failCounter++;
            }
          }
          failCounter == 0 ? filteredArray.push(vehicle) : null
        })

        return state.filtered = filteredArray
      }
    },

    //      FILTERED TYPE VEHICLES
    //#################################################################
    FILTERED_TYPEVEHICLES(state, word) {
      state.filtered = [];

      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())

        state.filtered = state.typeVehicles
          .filter(typeVehicle => {
            return (typeVehicle.name != null && removeAccent(typeVehicle.name.toLowerCase().trim()).includes(word)) || (typeVehicle.description != null && removeAccent(typeVehicle.description.toLowerCase().trim()).includes(word))
          })
      }
    },

    //INSPECTION - FILTRO
    //#################################################################
    FILTERED_VEHICLE_INSPECTION(state, { searchWord, startDate, endDate }) {
      if ((searchWord == null || searchWord == "") && (startDate == null) && (endDate == null)) {
        state.filtered = null
      } else {
        let swTreated,
          dateInspection,
          startDateInspection,
          endDateInspection,
          filteredArray = [],
          km, costs, description
        searchWord != null ? swTreated = removeAccent(searchWord.toString().toLowerCase().trim()) : null;
        state.vehicle.inspections.forEach(inspection => {
          let failCounter = 0;
          if (swTreated != null) {
            km = removeAccent(inspection.km.toString().toLowerCase().trim());
            costs = removeAccent(inspection.costs.toString().toLowerCase().trim());
            description = removeAccent(inspection.description.toString().trim().toLowerCase());
          }
          startDate != null ? startDateInspection = new Date(startDate) : null
          endDate != null ? endDateInspection = new Date(endDate) : null
          dateInspection = new Date(inspection.date);

          if (swTreated != null && swTreated != '') {
            if (!(km.includes(swTreated) || costs.includes(swTreated) || description.includes(swTreated))) {
              failCounter++;
            }
          }
          if (startDateInspection != null) {
            if (startDateInspection > dateInspection) {
              failCounter++;
            }
          }
          if (endDateInspection != null) {
            if (endDateInspection < dateInspection) {
              failCounter++;
            }
          }
          failCounter == 0 ? filteredArray.push(inspection) : null
        })

        return state.filtered = filteredArray
      }
    },

    //REVISION - FILTRO
    //#################################################################
    FILTERED_VEHICLE_REVISION(state, { searchWord, startDate, endDate }) {
      // console.log("startDate", new Date(startDate))
      // console.log("endDate", new Date(endDate))
      if ((searchWord == null || searchWord == "") && (startDate == null) && (endDate == null)) {
        state.filtered = null
      } else {
        let swTreated,
          dateRevision,
          startDateRevision,
          endDateRevision,
          filteredArray = [],
          km, costs, description
        searchWord != null ? swTreated = removeAccent(searchWord.toString().toLowerCase().trim()) : null;
        state.vehicle.revisions.forEach(revision => {
          let failCounter = 0;
          if (swTreated != null) {
            km = removeAccent(revision.km.toString().toLowerCase().trim());
            costs = removeAccent(revision.costs.toString().toLowerCase().trim());
            description = removeAccent(revision.description.toString().trim().toLowerCase());
          }
          startDate != null ? startDateRevision = new Date(startDate) : null
          endDate != null ? endDateRevision = new Date(endDate) : null
          dateRevision = new Date(revision.date);
          // console.log("endDate", endDate)
          if (swTreated != null && swTreated != '') {
            if (!(km.includes(swTreated) || costs.includes(swTreated) || description.includes(swTreated))) {
              failCounter++;
            }
          }
          if (startDateRevision != null) {
            if (startDateRevision > dateRevision) {
              failCounter++;
            }
          }
          if (endDateRevision != null) {
            if (endDateRevision < dateRevision) {
              failCounter++;
            }
          }
          failCounter == 0 ? filteredArray.push(revision) : null
        })

        return state.filtered = filteredArray
      }
    },

    //INSURANCE - FILTRO
    //#################################################################
    FILTERED_VEHICLE_INSURANCE(state, { searchWord, startDate, endDate }) {
      if ((searchWord == null || searchWord == "") && (startDate == null) && (endDate == null)) {
        state.filtered = null
      } else {
        let swTreated,
          dateInsurance,
          startDateInsurance,
          endDateInsurance,
          filteredArray = [],
          apolicyNumber
        searchWord != null ? swTreated = removeAccent(searchWord.toString().toLowerCase().trim()) : null;
        state.vehicle.insurances.forEach(insurance => {
          let failCounter = 0;
          if (swTreated != null && insurance.apolicyNumber != null) {
            apolicyNumber = removeAccent(insurance.apolicyNumber.toString().toLowerCase().trim());
          }
          startDate != null ? startDateInsurance = new Date(startDate) : null
          endDate != null ? endDateInsurance = new Date(endDate) : null
          dateInsurance = new Date(insurance.endDate);
          // console.log("startDate", startDateInsurance)
          if (swTreated != null && swTreated != '') {
            if (!(apolicyNumber.includes(swTreated))) {
              failCounter++;
            }
          }
          if (startDateInsurance != null) {
            if (startDateInsurance > dateInsurance) {
              failCounter++;
            }
          }
          if (endDateInsurance != null) {
            if (endDateInsurance < dateInsurance) {
              failCounter++;
            }
          }
          failCounter == 0 ? filteredArray.push(insurance) : null
        })

        return state.filtered = filteredArray
      }
    },
    //      FILTERED BRANDS
    //#################################################################
    FILTERED_BRANDS(state, word) {
      state.filtered = [];

      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())

        state.filtered = state.brands
          .filter(brand => {
            return (brand.name != null && removeAccent(brand.name.toLowerCase().trim()).includes(word))
          })
      }
    },

    //      FILTERED MODELS
    //#################################################################
    FILTERED_MODELS(state, word) {
      state.filtered = [];

      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())

        state.filtered = state.models
          .filter(model => {
            return (model.name != null && removeAccent(model.name.toLowerCase().trim()).includes(word))
          })
      }
    },

  },

  //########################################################################################
  //                 ACTION            
  //########################################################################################
  actions: {

    //##################################################
    //                 VEHICLES  
    //##################################################
    getFilterURL(context){
      context.commit("UPDATE_SEARCHWORD", router.currentRoute.query.searchWord || null);
      context.commit("UPDATE_STARTDATE_NEXTINSPECTION", router.currentRoute.query.nextInspectionStartDate || null);
      context.commit("UPDATE_ENDDATE_NEXTINSPECTION", router.currentRoute.query.nextInspectionEndDate || null);
      context.commit("UPDATE_STARTDATE_REVISION", router.currentRoute.query.revisonStartDate || null);
      context.commit("UPDATE_ENDDATE_REVISION", router.currentRoute.query.revisonEndDate || null);
      context.commit("UPDATE_STARTDATE_INSURANCE", router.currentRoute.query.insuranceStartDate || null);
      context.commit("UPDATE_ENDDATE_INSURANCE", router.currentRoute.query.insuranceEndDate || null);     
        context.commit("FILTERED_VEHICLE", {
        searchWord: context.state.searchWord || null,
        nextInspectionStartDate: context.state.nextInspectionStartDate || null,
        nextInspectionEndDate: context.state.nextInspectionEndDate || null,
        revisonStartDate: context.state.revisonStartDate || null,
        revisonEndDate: context.state.revisonEndDate || null,
        insuranceStartDate: context.state.insuranceStartDate || null,
        insuranceEndDate: context.state.insuranceEndDate || null,
      });
      let filterActive = 
      (context.state.searchWord == null || context.state.searchWord == "") && 
      (context.state.nextInspectionStartDate == null || context.state.nextInspectionStartDate == "") && 
      (context.state.nextInspectionEndDate == null || context.state.nextInspectionEndDate == "") &&
      (context.state.revisonStartDate == null || context.state.revisonStartDate == "") &&
      (context.state.revisonEndDate == null || context.state.revisonEndDate == "") &&
      (context.state.insuranceStartDate == null || context.state.insuranceStartDate == "") &&
      (context.state.insuranceEndDate == null || context.state.insuranceEndDate == "") ? false : true;
      context.commit("UPDATE_BTNFILTER", filterActive, {root: true})
    },
    getVehicles(context, refresh) {  
      // console.log("refresh", refresh)      
      // context.commit("RESET_FILTER", null);
      context.commit("UPDATE_LOADING", true);
      if (context.state.vehicles === null || refresh != undefined) {
        api.get(`/vehicle/`)
          .then(
            response => {
              const tempVehicles = response.data.map(function (vehicle) {
                vehicle.nextInspectionDate = formatDate(new Date(vehicle.nextInspectionDate));
                vehicle.created = formatDate(new Date(vehicle.created));
                vehicle.modified = formatDate(new Date(vehicle.modified));
                vehicle.inspections.map(function (inspection) {
                  inspection.date = formatDate(new Date(inspection.date));
                  inspection.created = formatDate(new Date(inspection.created));
                  inspection.modified = formatDate(new Date(inspection.modified));
                });
                vehicle.revisions.map(function (revision) {
                  revision.date = formatDate(new Date(revision.date));
                  revision.created = formatDate(new Date(revision.created));
                  revision.modified = formatDate(new Date(revision.modified));
                });
                vehicle.insurances.map(function (insurance) {
                  insurance.created = formatDate(new Date(insurance.created));
                  insurance.modified = formatDate(new Date(insurance.modified));
                  insurance.startDate = formatDate(new Date(insurance.startDate));
                  insurance.endDate = formatDate(new Date(insurance.endDate));
                });
                return vehicle;
              });
              // console.log(tempVehicles);
              context.commit("UPDATE_VEHICLES", tempVehicles);
              context.commit("UPDATE_VEHICLE_DASHBOARD", tempVehicles);
              context.dispatch("getFilterURL"); 
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_VEHICLES", context.state.vehicles);
        context.dispatch("getFilterURL"); 
        context.commit("UPDATE_LOADING", false);
      }
      
    },

    


    //    VECHICLE - INFORMAÇÕES - ACTIONS
    //##################################################

    getVehicle(context, { idVehicle }) {
      // console.log("refreshId", refreshId)
      context.commit("UPDATE_LOADING", true);
      // if (context.state.vehicles === null || refreshId != undefined) {
      api.get(`/vehicle/${idVehicle}`)
        .then(
          response => {
            let vehicle = {};
            Object.keys(response.data).filter(key => {
              // ##### formatando o array das revisions 
              let inspectionFormat;
              (key === "inspections") && (inspectionFormat = response.data[key].map(function (inspection) {
                inspection.date = formatDate(new Date(inspection.date))
                inspection.created = formatDate(new Date(inspection.created));
                inspection.modified = formatDate(new Date(inspection.modified));
                return inspection
              }))
              // ##### formatando o array das revisions 
              let revisionFormat;
              (key === "revisions") && (revisionFormat = response.data[key].map(function (revision) {
                revision.date = formatDate(new Date(revision.date))
                revision.created = formatDate(new Date(revision.created));
                revision.modified = formatDate(new Date(revision.modified));
                return revision
              }))
              // ##### formatando o array das insurances
              let insuranceFormat;
              (key === "insurances") && (insuranceFormat = response.data[key].map(function (insurance) {
                insurance.startDate = formatDate(new Date(insurance.startDate))
                insurance.endDate = formatDate(new Date(insurance.endDate))
                insurance.created = formatDate(new Date(insurance.created));
                insurance.modified = formatDate(new Date(insurance.modified));
                return insurance
              }))
              // ##### retornando todas as datas formatadas
              return ((key === "nextInspectionDate") && (vehicle[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "created") && (vehicle[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (vehicle[key] = formatDate(new Date(response.data[key])))) ||
                (key === "inspections") && (vehicle[key] = inspectionFormat) ||
                (key === "revisions") && (vehicle[key] = revisionFormat) ||
                (key === "insurances") && (vehicle[key] = insuranceFormat) ||
                (vehicle[key] = response.data[key])
            })

            // console.log(vehicle)
            context.commit("UPDATE_VEHICLE", vehicle);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
      // } else {
      //   const vehicle = context.state.vehicles.find(c => c.id == idVehicle)
      //   // console.log(vehicle)
      //   context.commit("UPDATE_VEHICLE", vehicle);
      //   context.commit("UPDATE_LOADING", false);
      // }
    },
    //   ADD - VEHICLE - ACTIONS
    //##################################################
    addVehicle(context, { vehicle }) {
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
      api.post(`/vehicle/`, vehicle)
        .then(response => {
          const idVehicle = response.data.id
          context.dispatch("getVehicles", "refresh");
          context.dispatch("getTypeVehicles", "refresh");
          context.dispatch("getVehicle", { idVehicle });
          // context.commit("UPDATE_VEHICLE", null);
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Viatura adicionada com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_VEHICLE", context.getters["vehicleReset"]);//limpa o formulário depois de cadastrar        
          context.commit("UPDATE_LOADING", false);
          router.push({ name: "viatura", params: { idVehicle } })
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "registration required.") {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          if (error.response.data.error === "Registration already exist") {
            context.commit("UPDATE_ERRORS", "registrationExist", { root: true });
          }
          if (error.response.data.error === "nextInspectionDate required.") {
            context.commit("UPDATE_ERRORS", "nextInspRequired", { root: true });
          }
          if (error.response.data.error === "nextInspectionDate invalid.") {
            context.commit("UPDATE_ERRORS", "errorNextRevision", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //   UPDATE - VEHICLE - ACTIONS
    //##################################################
    updateVehicle(context, { vehicle, idVehicle, idTypeVehicle }) {
      context.commit("UPDATE_LOADING", true);
      api.put(`/vehicle/${idVehicle}`, vehicle)
        .then(() => {
          context.dispatch("getVehicles", "refresh");
          context.dispatch("getVehicle", { idVehicle, refreshId: "refreshId" });
          if (idTypeVehicle != null) {
            context.dispatch("getTypeVehicle", { idTypeVehicle });
            context.commit("UPDATE_VEHICLEID", context.getters["vehicleIdReset"]);//limpa o formulário depois de cadastrar
          }
          // context.commit("UPDATE_VEHICLE", null);
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Viatura editada com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_LOADING", false);
          context.dispatch("closeModalPutNextInspection")
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "registration required.") {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          if (error.response.data.error === "Registration already exist") {
            context.commit("UPDATE_ERRORS", "registrationExist", { root: true });
          }
          if (error.response.data.error === "nextInspectionDate required.") {
            context.commit("UPDATE_ERRORS", "nextInspRequired", { root: true });
          }
          if (error.response.data.error === "nextInspectionDate invalid.") {
            context.commit("UPDATE_ERRORS", "errorNextRevision", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //MODAL PUT VEHICLE 
    openModalPutNextInspection(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_PUT_NEXTINSPECTION", true);
    },
    closeModalPutNextInspection(context) {
      context.commit("UPDATE_MODAL_PUT_NEXTINSPECTION", false);
    },

    //    DELETE - VEHICLE - ACTIONS
    //##################################################

    //MODAL DELETE VEHICLE 
    openModalDelVehicle(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_VEHICLE", true);
    },
    closeModalDelVehicle(context) {
      context.commit("UPDATE_MODAL_DELETE_VEHICLE", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteVehicle(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelVehicle");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteVehicle(context, { id }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/vehicle/${id}`)
        .then(() => {
          context.commit("UPDATE_DELETE_VEHICLE", id); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Viatura deletada com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },


    //##################################################
    //                TYPEVEHICLES  
    //##################################################

    getTypeVehicles(context, refresh) {
      // context.commit("RESET_FILTER", null);
      context.commit("UPDATE_LOADING", true);
      if (context.state.typeVehicles === null || refresh != undefined) {
        api.get(`/vehicle/type/`)
          .then(
            response => {
              const typeVehicle = response.data.map(function (typeVehicle) {
                typeVehicle.created = formatDate(new Date(typeVehicle.created));
                typeVehicle.modified = formatDate(new Date(typeVehicle.modified));
                typeVehicle.services.map(function (service) {
                  service.created = formatDate(new Date(service.created));
                  service.modified = formatDate(new Date(service.modified));
                });
                return typeVehicle;
              });
              context.commit("UPDATE_TYPEVEHICLES", typeVehicle);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_TYPEVEHICLES", context.state.typeVehicles);
        context.commit("UPDATE_LOADING", false);
      }
    },


    //    TYPEVECHICLE - INFORMAÇÕES - ACTIONS
    //##################################################

    getTypeVehicle(context, { idTypeVehicle }) {
      context.commit("UPDATE_LOADING", true);
      // if (context.state.typeVehicles === null || refreshId != undefined) {
      api.get(`/vehicle/type/${idTypeVehicle}`)
        .then(
          response => {
            let typeVehicle = {};
            Object.keys(response.data).filter(key => {
              let serviceFormat;
              (key === "services") && (serviceFormat = response.data[key].map(function (service) {
                service.created = formatDate(new Date(service.created));
                service.modified = formatDate(new Date(service.modified));
                return service
              }))
              return ((key === "created") && (typeVehicle[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (typeVehicle[key] = formatDate(new Date(response.data[key])))) ||
                (key === "services") && (typeVehicle[key] = serviceFormat) ||
                (typeVehicle[key] = response.data[key])
            })
            context.commit("UPDATE_TYPEVEHICLE", typeVehicle);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
      // } else {
      //   const typeVehicle = context.state.typeVehicles.find(c => c.id == idTypeVehicle)
      //   context.commit("UPDATE_TYPEVEHICLE", typeVehicle);
      //   context.commit("UPDATE_LOADING", false);
      // }
    },

    //   ADD - TYPEVEHICLE - ACTIONS
    //##################################################
    addTypeVehicle(context, { typeVehicle }) {
      context.commit("RESET_ERRORS", [], { root: true });
      if (typeVehicle.name == null || typeVehicle.name == "") {
        context.commit("UPDATE_ERRORS", "full", { root: true });
      } else if (typeVehicle.description == null || typeVehicle.description == "") {
        context.commit("UPDATE_ERRORS", "description", { root: true });
      } else {
        api.post(`/vehicle/type/`, typeVehicle)
          .then(() => {
            context.dispatch("getTypeVehicles", "refresh");
            // context.commit("UPDATE_VEHICLE", null);
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Tipo de Viatura adicionada com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_LOADING", false);
            router.push({ name: "tipos-viaturas" })
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "name already exist") {
              context.commit("UPDATE_ERRORS", "nameExist", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },
    //   UPDATE - TYPEVEHICLE - ACTIONS
    //##################################################
    updateTypeVehicle(context, { typeVehicle, idTypeVehicle }) {
      context.commit("UPDATE_LOADING", true);
      api.put(`/vehicle/type/${idTypeVehicle}`, typeVehicle)
        .then(() => {
          context.dispatch("getTypeVehicles", "refresh");
          context.dispatch("getTypeVehicle", { idTypeVehicle });
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Tipo de Viatura editada com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "registration required.") {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          if (error.response.data.error === "name already exist") {
            context.commit("UPDATE_ERRORS", "nameExist", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //    DELETE - TYPEVEHICLE - ACTIONS
    //##################################################

    //MODAL DELETE TYPEVEHICLE 
    openModalDelTypeVehicle(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_TYPEVEHICLE", true);
    },
    closeModalDelTypeVehicle(context) {
      context.commit("UPDATE_MODAL_DELETE_TYPEVEHICLE", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteTypeVehicle(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelTypeVehicle");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteTypeVehicle(context, { id }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/vehicle/type/${id}`)
        .then(() => {
          context.commit("UPDATE_DELETE_TYPEVEHICLE", id); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Tipo de Viatura deletada com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          }
        })
    },

    //##################################################
    //               SERVICES TYPEVEHICLES  
    //##################################################

    //   ADD - SERVICES TYPEVEHICLE - ACTIONS
    //##################################################
    addServiceTypeVehicle(context, { idTypeVehicle, serviceTypeVehicle }) {
      context.commit("UPDATE_LOADING", true);
      context.commit("RESET_ERRORS", [], { root: true });
      api.post(`/vehicle/type/${idTypeVehicle}/service/`, serviceTypeVehicle)
        .then(() => {
          context.dispatch("getVehicles", "refresh");
          context.dispatch("getTypeVehicle", { idTypeVehicle });
          context.commit("UPDATE_SERVICETYPEVEHICLE", context.getters["serviceTypeVehicleReset"]);//limpa o formulário depois de cadastrar       
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Serviço do Tipo de Viatura adicionado com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "name required.") {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          if (error.response.data.error === "name already exist") {
            context.commit("UPDATE_ERRORS", "nameExist", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //    DELETE - SERVICES TYPEVEHICLE - ACTIONS
    //##################################################

    //MODAL DELETE TYPEVEHICLE 
    openModalDelServiceTypeVehicle(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE__SERVICE_TYPEVEHICLE", true);
    },
    closeModalDelServiceTypeVehicle(context) {
      context.commit("UPDATE_MODAL_DELETE__SERVICE_TYPEVEHICLE", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteServiceTypeVehicle(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelServiceTypeVehicle");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteServiceTypeVehicle(context, { id, idServiceTypeVehicle }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/vehicle/type/${id}/service/${idServiceTypeVehicle}`)
        .then(() => {
          context.commit("UPDATE_DELETE_SERVICETYPEVEHICLE", idServiceTypeVehicle); //atualiza         
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Tipo de Viatura deletada com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          }
        })
    },
    //##################################################
    //                INSPECTIONS  
    //##################################################

    getInspections(context, { idVehicle }) {
      // console.log("refresh")
      context.commit("RESET_FILTER", null);
      context.commit("UPDATE_LOADING", true);
      context.commit("UPDATE_INSPECTIONSHISTORY", null);
      // if (context.state.revisions === null || refresh != undefined) {
      api.get(`/vehicle/${idVehicle}/inspection`)
        .then(
          response => {
            const inspections = response.data.map(function (inspection) {
              inspection.date = formatDate(new Date(inspection.date));
              inspection.created = formatDate(new Date(inspection.created));
              inspection.modified = formatDate(new Date(inspection.modified));
              return inspection
            });
            // console.log(revisions)
            context.commit("UPDATE_INSPECTIONS", inspections);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //    INSPECTION - INFORMAÇÕES - ACTIONS
    //##################################################

    getInspection(context, { idVehicle, idInspection }) {
      // console.log("refreshId")
      context.commit("UPDATE_LOADING", true);
      // if (context.state.inspections === null || refreshId != undefined) {
      api.get(`/vehicle/${idVehicle}/inspection/${idInspection}`)
        .then(
          response => {
            const inspection = {}
            Object.keys(response.data).filter(key => {
              return ((key === "date") && (inspection[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "created") && (inspection[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (inspection[key] = formatDate(new Date(response.data[key])))) ||
                (inspection[key] = response.data[key])
            })
            context.commit("UPDATE_INSPECTION", inspection);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
      // } else {
      //   const inspection = context.state.inspections.find(c => c.id == idInspection)
      //   // console.log(vehicle)
      //   context.commit("UPDATE_INSPECTION", inspection);
      //   context.commit("UPDATE_LOADING", false);
      // }
    },
    //   ADD - INSPECTION - ACTIONS
    //##################################################
    addInspection(context, { idVehicle, inspection }) {
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
      api.post(`/vehicle/${idVehicle}/inspection`, inspection)
        .then(() => {
          context.dispatch("getInspections", idVehicle);
          context.dispatch("getVehicles", "refresh");
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Inspeção adicionada com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_INSPECTION", context.getters["inspectionReset"]);//limpa o formulário depois de cadastrar        
          context.commit("UPDATE_LOADING", false);
          router.push({ name: "inspecoes-viatura", params: { idVehicle } })
          context.dispatch("getVehicle", { idVehicle });
          context.dispatch("openModalPutNextInspection")
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "date required.") {
            context.commit("UPDATE_ERRORS", "date", { root: true });
          }
          if (error.response.data.error === "Data de Inspeção não pode ser maior do que hoje.") {
            context.commit("UPDATE_ERRORS", "dateInvalid", { root: true });
          }
          if (error.response.data.error === "description required.") {
            context.commit("UPDATE_ERRORS", "description", { root: true });
          }
          if (error.response.data.error === "km required.") {
            context.commit("UPDATE_ERRORS", "km", { root: true });
          }
          if (error.response.data.error === "costs required.") {
            context.commit("UPDATE_ERRORS", "costs", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //   UPDATE - INSPECTION - ACTIONS
    //##################################################
    updateInspection(context, { idVehicle, idInspection, inspection }) {
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
      api.put(`/vehicle/${idVehicle}/inspection/${idInspection}`, inspection)
        .then(() => {
          context.dispatch("getInspections", idVehicle);
          context.dispatch("getVehicles", "refresh");
          context.dispatch("getInspection", { idVehicle, idInspection });
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Inspeção editada com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "date required.") {
            context.commit("UPDATE_ERRORS", "date", { root: true });
          }
          if (error.response.data.error === "Data de Inspeção não pode ser maior do que hoje.") {
            context.commit("UPDATE_ERRORS", "dateInvalid", { root: true });
          }
          if (error.response.data.error === "description required.") {
            context.commit("UPDATE_ERRORS", "description", { root: true });
          }
          if (error.response.data.error === "km required.") {
            context.commit("UPDATE_ERRORS", "km", { root: true });
          }
          if (error.response.data.error === "costs required.") {
            context.commit("UPDATE_ERRORS", "costs", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //    DELETE - INSPECTION - ACTIONS
    //##################################################

    //MODAL DELETE INSPECTION 
    openModalDelInspection(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_INSPECTION", true);
    },
    closeModalDelInspection(context) {
      context.commit("UPDATE_MODAL_DELETE_INSPECTION", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteInspection(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelInspection");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteInspection(context, { id, idInspection }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/vehicle/${id}/inspection/${idInspection}`)
        .then(() => {
          context.commit("UPDATE_DELETE_INSPECTION", idInspection); //atualiza 
          context.dispatch("getVehicles", "refresh");
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Inspeção deletada com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //##################################################
    //                REVISONS  
    //##################################################

    getRevisions(context, { idVehicle }) {
      // console.log("refresh")
      context.commit("RESET_FILTER", null);
      context.commit("UPDATE_LOADING", true);
      context.commit("UPDATE_REVISIONSHISTORY", null);
      // if (context.state.revisions === null || refresh != undefined) {
      api.get(`/vehicle/${idVehicle}/revision`)
        .then(
          response => {
            const revisions = response.data.map(function (revision) {
              revision.date = formatDate(new Date(revision.date));
              revision.created = formatDate(new Date(revision.created));
              revision.modified = formatDate(new Date(revision.modified));
              return revision
            });
            // console.log(revisions)
            context.commit("UPDATE_REVISIONS", revisions);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })

    },

    //    REVISON - INFORMAÇÕES - ACTIONS
    //##################################################

    getRevision(context, { idVehicle, idRevision }) {
      // console.log("refreshId")
      context.commit("UPDATE_LOADING", true);
      // if (context.state.revisions === null || refreshId != undefined) {
      api.get(`/vehicle/${idVehicle}/revision/${idRevision}`)
        .then(
          response => {
            const revision = {}
            Object.keys(response.data).filter(key => {
              return ((key === "date") && (revision[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "created") && (revision[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (revision[key] = formatDate(new Date(response.data[key])))) ||
                (revision[key] = response.data[key])
            })
            context.commit("UPDATE_REVISION", revision);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
      // } else {
      //   const revision = context.state.revisions.find(c => c.id == idRevision)
      //   // console.log(vehicle)
      //   context.commit("UPDATE_REVISION", revision);
      //   context.commit("UPDATE_LOADING", false);
      // }
    },
    //   ADD - REVISION - ACTIONS
    //##################################################
    addRevision(context, { idVehicle, revision }) {
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
      api.post(`/vehicle/${idVehicle}/revision`, revision)
        .then(() => {
          context.dispatch("getRevisions", idVehicle);
          context.dispatch("getVehicles", "refresh");
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Revisão adicionada com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_REVISION", context.getters["revisionReset"]);//limpa o formulário depois de cadastrar        
          context.commit("UPDATE_LOADING", false);
          router.push({ name: "revisoes-viatura", params: { idVehicle } })
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "date required.") {
            context.commit("UPDATE_ERRORS", "date", { root: true });
          }
          if (error.response.data.error === "Data de Revisão não pode ser maior do que hoje.") {
            context.commit("UPDATE_ERRORS", "dateInvalid", { root: true });
          }
          if (error.response.data.error === "description required.") {
            context.commit("UPDATE_ERRORS", "description", { root: true });
          }
          if (error.response.data.error === "km required.") {
            context.commit("UPDATE_ERRORS", "km", { root: true });
          }
          if (error.response.data.error === "costs required.") {
            context.commit("UPDATE_ERRORS", "costs", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //   UPDATE - REVISION - ACTIONS
    //##################################################
    updateRevision(context, { idVehicle, idRevision, revision }) {
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
      api.put(`/vehicle/${idVehicle}/revision/${idRevision}`, revision)
        .then(() => {
          context.dispatch("getRevisions", idVehicle);
          context.dispatch("getVehicles", "refresh");
          context.dispatch("getRevision", { idVehicle, idRevision });
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Revisão editada com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "date required.") {
            context.commit("UPDATE_ERRORS", "date", { root: true });
          }
          if (error.response.data.error === "Data de Revisão não pode ser maior do que hoje.") {
            context.commit("UPDATE_ERRORS", "dateInvalid", { root: true });
          }
          if (error.response.data.error === "description required.") {
            context.commit("UPDATE_ERRORS", "description", { root: true });
          }
          if (error.response.data.error === "km required.") {
            context.commit("UPDATE_ERRORS", "km", { root: true });
          }
          if (error.response.data.error === "costs required.") {
            context.commit("UPDATE_ERRORS", "costs", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //    DELETE - REVISION - ACTIONS
    //##################################################

    //MODAL DELETE REVISION 
    openModalDelRevision(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_REVISION", true);
    },
    closeModalDelRevision(context) {
      context.commit("UPDATE_MODAL_DELETE_REVISION", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteRevision(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelRevision");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteRevision(context, { id, idRevision }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/vehicle/${id}/revision/${idRevision}`)
        .then(() => {
          context.commit("UPDATE_DELETE_REVISION", idRevision); //atualiza 
          context.dispatch("getVehicles", "refresh");
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Revisão deletada com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //##################################################
    //                INSURANCES  
    //##################################################

    getInsurances(context, { idVehicle }) {
      // console.log("refresh", refresh)
      context.commit("RESET_FILTER", null);
      context.commit("UPDATE_LOADING", true);
      // context.commit("UPDATE_REVISIONSHISTORY", null);
      // if (context.state.revisions === null || refresh != undefined) {
      api.get(`/vehicle/${idVehicle}/insurance`)
        .then(
          response => {
            const insurances = response.data.map(function (insurance) {
              insurance.startDate = formatDate(new Date(insurance.startDate));
              insurance.endDate = formatDate(new Date(insurance.endDate));
              insurance.created = formatDate(new Date(insurance.created));
              insurance.modified = formatDate(new Date(insurance.modified));
              return insurance
            });
            // console.log(insurances)
            context.commit("UPDATE_INSURANCES", insurances);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //    INSURANCE - INFORMAÇÕES - ACTIONS
    //##################################################

    getInsurance(context, { idVehicle, idInsurance }) {
      // console.log("idTypeVehicle", idTypeVehicle)
      context.commit("UPDATE_LOADING", true);
      // if (context.state.insurances === null || refreshId != undefined) {
      api.get(`/vehicle/${idVehicle}/insurance/${idInsurance}`)
        .then(
          response => {
            // const insurance = response.data
            const insurance = {}
            Object.keys(response.data).filter(key => {
              return ((key === "startDate") && (insurance[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "endDate") && (insurance[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "created") && (insurance[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (insurance[key] = formatDate(new Date(response.data[key])))) ||
                (insurance[key] = response.data[key])
            })
            // console.log(insurance)
            context.commit("UPDATE_INSURANCE", insurance);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
      // } else {
      //   const insurance = context.state.insurances.find(c => c.id == idInsurance)
      //   // console.log(vehicle)
      //   context.commit("UPDATE_INSURANCE", insurance);
      //   context.commit("UPDATE_LOADING", false);
      // }
    },


    //   ADD - INSURANCE - ACTIONS
    //##################################################
    addInsurance(context, { idVehicle, insurance }) {
      // console.log("context", context)
      // console.log("vehicle", vehicle)
      // console.log("getter", context.getters["vehicleReset"])
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
      api.post(`/vehicle/${idVehicle}/insurance`, insurance)
        .then(() => {
          context.dispatch("getInsurances", idVehicle);
          context.dispatch("getVehicles", "refresh");
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Seguro adicionado com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_INSURANCE", context.getters["insuranceReset"]);//limpa o formulário depois de cadastrar        
          context.commit("UPDATE_LOADING", false);
          router.push({ name: "seguros-viatura", params: { idVehicle } })
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "Start date required.") {
            context.commit("UPDATE_ERRORS", "startDate", { root: true });
          }
          if (error.response.data.error === "End date required.") {
            context.commit("UPDATE_ERRORS", "endDate", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //   UPDATE - INSURANCE - ACTIONS
    //##################################################
    updateInsurance(context, { idVehicle, idInsurance, insurance }) {
      // console.log("context", context)
      // console.log("getter", context.getters["vehicleReset"])
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
      api.put(`/vehicle/${idVehicle}/insurance/${idInsurance}`, insurance)
        .then(() => {
          context.dispatch("getInsurances", idVehicle);
          context.dispatch("getInsurance", { idVehicle, idInsurance });
          context.dispatch("getVehicles", "refresh");
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Seguro editado com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "Start date required.") {
            context.commit("UPDATE_ERRORS", "startDate", { root: true });
          }
          if (error.response.data.error === "End date required.") {
            context.commit("UPDATE_ERRORS", "endDate", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //    DELETE - INSURANCE - ACTIONS
    //##################################################

    //MODAL DELETE INSURANCE 
    openModalDelInsurance(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_INSURANCE", true);
    },
    closeModalDelInsurance(context) {
      context.commit("UPDATE_MODAL_DELETE_INSURANCE", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteInsurance(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelInsurance");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteInsurance(context, { id, idInsurance }) {
      // console.log(context)
      // console.log(id,)
      // console.log(idRevision)
      context.commit("UPDATE_LOADING", true);
      api.delete(`/vehicle/${id}/insurance/${idInsurance}`)
        .then(() => {
          context.commit("UPDATE_DELETE_INSURANCE", idInsurance); //atualiza 
          context.dispatch("getVehicles", "refresh");
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Seguro deletado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //##################################################
    //    ATTACHMENT - REVISION - ACTIONS
    //##################################################


    //    ATTACHMENT - VEHICLE - ACTIONS
    //##################################################

    //ASSOCIAR ATTACHMENT VEHICLE
    onUploadVehicle(context, { data, idVehicle, namefile }) {
      let fileName = { 'filename': namefile }
      context.commit("RESET_ERRORS", [], { root: true });
      api.post(`/vehicle/${idVehicle}/attachment`, fileName)
        .then(response => {
          const attachmentID = response.data.id
          const urlDelete = `/vehicle/${idVehicle}/attachment/${attachmentID}`
          const mutationDel = "'vehicle/UPDATE_DELETE_FILEVEHICLE'"
          context.dispatch("attachment/attachment", { data, attachmentID, urlDelete, mutationDel }, { root: true })
          context.commit("UPDATE_SELECTEDFILE", null);
          context.dispatch("getVehicles", "refresh");
          context.dispatch("getVehicle", { idVehicle });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_IMG", false, { root: true });
          console.log(error.response.status)
          const status = error.response.status
          context.dispatch("errorArchiveStatus", status, { root: true })
        })
    },



    //MODAL DELETE ATTACHMENT VEHICLE 
    openModalDelFileVehicle(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_FILE", true);
    },
    closeModalDelFileVehicle(context) {
      context.commit("UPDATE_MODAL_DELETE_FILE", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteFileVehicle(context, { archiveID }) {
      context.commit("UPDATE_SELECTED_ARCHIVE", { archiveID }); //armazena o id para enviar ao delete depois
      context.dispatch("openModalDelFileVehicle");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteFileVehicle(context, { idVehicle, archiveID }) {
      // console.log("context", context)
      context.commit("UPDATE_LOADING", true);
      api.delete(`/vehicle/${idVehicle}/attachment/${archiveID}`)
        .then(() => {
          context.commit("UPDATE_DELETE_FILEVEHICLE", archiveID); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Ficheiro deletado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          console.log(error.response)
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorArchiveStatus", status, { root: true })
          }
        })
    },

    //    ATTACHMENT - INSPECTION - ACTIONS
    //##################################################

    //ASSOCIAR ATTACHMENT INSPECTION 
    onUploadInspection(context, { data, idInspection, idVehicle, namefile }) {
      let fileName = { 'filename': namefile }
      context.commit("RESET_ERRORS", [], { root: true });
      api.post(`/vehicle/inspection/${idInspection}/attachment`, fileName)
        .then(response => {
          const attachmentID = response.data.id
          const urlDelete = `/vehicle/inspection/${idInspection}/attachment/${attachmentID}`
          const mutationDel = "'vehicle/UPDATE_DELETE_FILEINSPECTION'"
          context.dispatch("attachment/attachment", { data, attachmentID, urlDelete, mutationDel, id: idInspection }, { root: true })
          context.commit("UPDATE_SELECTEDFILE", null);
          context.dispatch("getVehicles", "refresh");
          context.dispatch("getInspections", { idVehicle });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_IMG", false, { root: true })
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorArchiveStatus", status, { root: true })
        })
    },

    //MODAL DELETE ATTACHMENT INSPECTION 
    openModalDelFileInspection(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_FILE", true);
    },
    closeModalDelFileInspection(context) {
      context.commit("UPDATE_MODAL_DELETE_FILE", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteFileInspection(context, { id, archiveID }) {
      context.commit("UPDATE_SELECTED_ARCHIVE", { archiveID }); //armazena o id para enviar ao delete depois
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelFileInspection");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteFileInspection(context, { idInspection, archiveID }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/vehicle/inspection/${idInspection}/attachment/${archiveID}`)
        .then(() => {
          context.commit("UPDATE_DELETE_FILEINSPECTION", { id: idInspection, attachmentID: archiveID }); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Ficheiro deletado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          console.log(error.response)
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorArchiveStatus", status, { root: true })
          }
        })
    },

    //    ATTACHMENT - REVISION - ACTIONS
    //##################################################

    //ASSOCIAR ATTACHMENT REVISION 
    onUploadRevision(context, { data, idRevision, idVehicle, namefile }) {
      let fileName = { 'filename': namefile }
      context.commit("RESET_ERRORS", [], { root: true });
      api.post(`/vehicle/revision/${idRevision}/attachment`, fileName)
        .then(response => {
          const attachmentID = response.data.id
          const urlDelete = `/vehicle/revision/${idRevision}/attachment/${attachmentID}`
          const mutationDel = "'vehicle/UPDATE_DELETE_FILEREVISION'"
          context.dispatch("attachment/attachment", { data, attachmentID, urlDelete, mutationDel, id: idRevision }, { root: true })
          context.commit("UPDATE_SELECTEDFILE", null);
          context.dispatch("getVehicles", "refresh");
          context.dispatch("getRevisions", { idVehicle: idVehicle });
          // context.dispatch("success", {        //cria as informações do texto do toast
          //   id: Date.now(),
          //   h1: "Successo!",
          //   body: "Ficheiro adicionado com sucesso!",
          // }, { root: true });
          // context.commit("UPDATE_LOADING_IMG", false, { root: true })
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_IMG", false, { root: true })
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorArchiveStatus", status, { root: true })
        })
    },

    //MODAL DELETE ATTACHMENT REVISION 
    openModalDelFileRevision(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_FILE", true);
    },
    closeModalDelFileRevision(context) {
      context.commit("UPDATE_MODAL_DELETE_FILE", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteFileRevision(context, { id, archiveID }) {
      context.commit("UPDATE_SELECTED_ARCHIVE", { archiveID }); //armazena o id para enviar ao delete depois
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelFileRevision");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteFileRevision(context, { idRevision, archiveID }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/vehicle/revision/${idRevision}/attachment/${archiveID}`)
        .then(() => {
          context.commit("UPDATE_DELETE_FILEREVISION", { id: idRevision, attachmentID: archiveID }); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Ficheiro deletado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          console.log(error.response)
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorArchiveStatus", status, { root: true })
          }
        })
    },

    //    ATTACHMENT - INSURANCE - ACTIONS
    //##################################################

    //ASSOCIAR ATTACHMENT INSURANCE
    onUploadInsurance(context, { data, idInsurance, idVehicle, namefile }) {
      let fileName = { 'filename': namefile }
      context.commit("RESET_ERRORS", [], { root: true });
      api.post(`/vehicle/insurance/${idInsurance}/attachment`, fileName)
        .then(response => {
          const attachmentID = response.data.id
          const urlDelete = `/vehicle/insurance/${idInsurance}/attachment/${attachmentID}`
          const mutationDel = "'vehicle/UPDATE_DELETE_FILE'"
          context.dispatch("attachment/attachment", { data, attachmentID, urlDelete, mutationDel, id: idInsurance }, { root: true })
          context.commit("UPDATE_SELECTEDFILE", null);
          context.dispatch("getVehicles", "refresh");
          context.dispatch("getInsurances", { idVehicle: idVehicle });
          // context.dispatch("success", {        //cria as informações do texto do toast
          //   id: Date.now(),
          //   h1: "Successo!",
          //   body: "Ficheiro adicionado com sucesso!",
          // }, { root: true });
          // context.commit("UPDATE_LOADING_IMG", false, { root: true })
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_IMG", false, { root: true })
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorArchiveStatus", status, { root: true })
        })
    },

    //MODAL DELETE ATTACHMENT INSURANCE
    openModalDelFileInsurance(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_FILE", true);
    },
    closeModalDelFileInsurance(context) {
      context.commit("UPDATE_MODAL_DELETE_FILE", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteFileInsurance(context, { id, archiveID }) {
      context.commit("UPDATE_SELECTED_ARCHIVE", { archiveID }); //armazena o id para enviar ao delete depois
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelFileInsurance");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteFileInsurance(context, { idInsurance, archiveID }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/vehicle/insurance/${idInsurance}/attachment/${archiveID}`)
        .then(() => {
          context.commit("UPDATE_DELETE_FILE", { id: idInsurance, attachmentID: archiveID }); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Ficheiro deletado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          console.log(error.response)
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorArchiveStatus", status, { root: true })
          }
        })
    },

    //##################################################
    //                BRANDS  
    //##################################################

    getBrands(context, refresh) {
      // context.commit("RESET_FILTER", null);
      // context.commit("UPDATE_LOADING", true);
      if (context.state.brands === null || refresh != undefined) {
        api.get(`/vehicle/brand/all`)
          .then(
            response => {
              const brand = response.data.map(function (brand) {
                brand.created = formatDate(new Date(brand.created));
                brand.modified = formatDate(new Date(brand.modified));               
                return brand;
              });
              context.commit("UPDATE_BRANDS", brand);
              // context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            // context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_BRANDS", context.state.brands);
        // context.commit("UPDATE_LOADING", false);
      }
    },


    //    BRAND - INFORMAÇÕES - ACTIONS
    //##################################################

    getBrand(context, { idBrand }) {
      // console.log(idBrand)
      context.commit("UPDATE_LOADING", true);
      api.get(`/vehicle/brand/${idBrand}`)
        .then(
          response => {
            let brand = {};
            Object.keys(response.data).filter(key => {
              return ((key === "created") && (brand[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (brand[key] = formatDate(new Date(response.data[key])))) ||
                (brand[key] = response.data[key])
            })
            context.commit("UPDATE_BRAND", brand);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //   ADD - BRAND - ACTIONS
    //##################################################
    addBrand(context, { brand }) {
      // console.log(brand)
      context.commit("RESET_ERRORS", [], { root: true });
      if (brand.name == null || brand.name == "") {
        context.commit("UPDATE_ERRORS", "full", { root: true });
      } else {
        api.post(`/vehicle/brand`, brand)
          .then(() => {
            context.dispatch("getBrands", "refresh");
            context.commit("UPDATE_MODAL_CREATE_BRAND", false);
            // context.commit("UPDATE_VEHICLE", null);
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Marca adicionada com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_LOADING", false);
            // router.push({ name: "marcas" })
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "name already exist") {
              context.commit("UPDATE_ERRORS", "nameExist", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },
    //   UPDATE - BRAND - ACTIONS
    //##################################################
    updateBrand(context, { brand, idBrand }) {
      context.commit("UPDATE_LOADING", true);
      api.put(`/vehicle/brand/${idBrand}`, brand)
        .then(() => {
          context.dispatch("getBrands", "refresh");
          context.dispatch("getBrand", { idBrand });
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Marca editada com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "name required.") {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          if (error.response.data.error === "name already exist") {
            context.commit("UPDATE_ERRORS", "nameExist", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //    DELETE - BRAND - ACTIONS
    //##################################################

    //MODAL DELETE BRAND 
    openModalDelBrand(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_BRAND", true);
    },
    closeModalDelBrand(context) {
      context.commit("UPDATE_MODAL_DELETE_BRAND", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteBrand(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelBrand");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteBrand(context, { id }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/vehicle/brand/${id}`)
        .then(() => {
          context.commit("UPDATE_DELETE_BRAND", id); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Marca deletada com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          }
        })
    },    

    //MODAL CREATE BRAND 
    
    openModalCreateBrand(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_CREATE_BRAND", true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    closeModalCreateBrand(context) {
      context.commit("UPDATE_MODAL_CREATE_BRAND", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },

    //##################################################
    //                MODEL  
    //##################################################

    getModels(context, refresh) {
      // context.commit("RESET_FILTER", null);
      // context.commit("UPDATE_LOADING", true);
      if (context.state.models === null || refresh != undefined) {
        api.get(`/vehicle/model/all`)
          .then(
            response => {
              const brand = response.data.map(function (brand) {
                brand.created = formatDate(new Date(brand.created));
                brand.modified = formatDate(new Date(brand.modified));               
                return brand;
              });
              context.commit("UPDATE_MODELS", brand);
              // context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            // context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_MODELS", context.state.models);
        // context.commit("UPDATE_LOADING", false);
      }
    },


    //    MODEL - INFORMAÇÕES - ACTIONS
    //##################################################

    getModel(context, { idModel }) {
      // console.log('chamou')
      context.commit("UPDATE_LOADING", true);
      api.get(`/vehicle/model/${idModel}`)
        .then(
          response => {
            let model = {};
            Object.keys(response.data).filter(key => {
              return ((key === "created") && (model[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (model[key] = formatDate(new Date(response.data[key])))) ||
                (model[key] = response.data[key])
            })
            context.commit("UPDATE_MODEL", model);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //   ADD - MODEL - ACTIONS
    //##################################################
    addModel(context, { model }) {
      context.commit("RESET_ERRORS", [], { root: true });
      if (model.name == null || model.name == "") {
        context.commit("UPDATE_ERRORS", "full", { root: true });
      } else {
        api.post(`/vehicle/model`, model)
          .then(() => {
            context.dispatch("getModels", "refresh");
            // context.commit("UPDATE_VEHICLE", null);
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Modelo adicionado com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_LOADING", false);
            router.push({ name: "modelos" })
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "name already exist") {
              context.commit("UPDATE_ERRORS", "nameExist", { root: true });
            }
            if (error.response.data.error === "brandID required.") {
              context.commit("UPDATE_ERRORS", "brandID", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },
    //   UPDATE - MODEL - ACTIONS
    //##################################################
    updateModel(context, { model, idModel }) {
      // console.log(model)
      context.commit("UPDATE_LOADING", true);
      api.put(`/vehicle/model/${idModel}`, model)
        .then(() => {
          context.dispatch("getModels", "refresh");
          context.dispatch("getModel", { idModel });
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Modelo editado com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "name required.") {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          if (error.response.data.error === "name already exist") {
            context.commit("UPDATE_ERRORS", "nameExist", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //    DELETE - MODEL - ACTIONS
    //##################################################

    //MODAL DELETE MODEL 
    openModalDelModel(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_MODEL", true);
    },
    closeModalDelModel(context) {
      context.commit("UPDATE_MODAL_DELETE_MODEL", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteModel(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelModel");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteModel(context, { id }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/vehicle/model/${id}`)
        .then(() => {
          context.commit("UPDATE_DELETE_MODEL", id); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Moelo deletado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          }
        })
    },



  },

}