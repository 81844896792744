<template>
  <div>
    <div class="titleContainer">
      <h3>Editar Morada</h3>
    </div>

    <nav class="navBreadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'colaborador-ativo' }"
            >Lista Ativos</router-link
          >
        </li>
        <li class="breadcrumb-item">
          <router-link
            :to="{
              name: 'colaborador-info-ativo',
              params: { idCollaborator: idRouter },
            }"
            >Dados do Colaborador</router-link
          >
        </li>
        <li class="breadcrumb-item-active">
          <span> Editar Morada</span>
        </li>
      </ol>
    </nav>
    <div class="itemFormCollaborator">
      <AddressForm :address="address" :errors="errors">
        <div slot="cancel"></div>
        <div slot="delete" class="btnJustify">
          <button class="btn" @click.prevent="updateAddress">Gravar</button>
        </div>
      </AddressForm>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </div>
</template>

<script>
import AddressForm from "@/components/address/AddressForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditAddress",
  props: ["idAddress", "idRouter"],
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("address", ["address"]),
    ...mapGetters("address", ["addressReply"]),
  },
  components: {
    AddressForm,
  },
  methods: {
    updateAddress() {
      this.$store.dispatch("address/updateAddress", {
        idAddress: this.idAddress,
        address: this.addressReply,
        mutation: "collaborator",
        getAction: "getCollaborator",
        idRouter: this.idRouter,
      });
    },
  },
  created() {
    this.$store.dispatch("address/getAddress", {
      idAddress: this.idAddress,
    });
  },
};
</script>

<style scoped>
.btnJustify {
  display: grid;
  justify-content: flex-end;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
.itemFormCollaborator {
  margin-left: 10px;
}
</style>