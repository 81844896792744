import { api } from "@/services.js";

export default {
  namespaced: true,
  state: {
    user: null,
    permissions: [],
    phones: [],
    addresses: [],
    emails: null,
    permissionDescriptions: [],
    showModalPassword: false,
    password: {
      password: null,
    },
    checkPassword: {
      checkPassword: null,
    },
  },
  getters: {
    passwordReply(state) {
      return {
        "password": state.password.password,
      }
    },
    passwordReset() {
      return {
        password: null,
      }
    },
    checkPasswordReply(state) {
      return {
        "checkPassword": state.checkPassword.checkPassword,
      }
    },
    checkPasswordReset() {
      return {
        checkPassword: null,
      }
    },
    //#################################################
    //       CONFERINDO PERMISSÕES DO USUÁRIO
    //##################################################
    // MORADAS
    addressAccess(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "addressAccess") ? true : false
    },
    addressEdit(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "addressEdit") ? true : false
    },
    // FICHEIROS
    attachmentAccess(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "attachmentAccess") ? true : false
    },
    attachmentEdit(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "attachmentEdit") ? true : false
    },
    attachmentManager(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "attachmentManager") ? true : false
    },
    // CONTACTOS
    contactAccess(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "contactAccess") ? true : false
    },
    contactEdit(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "contactEdit") ? true : false
    },
    // CALENDÁRIO
    calendarEdit(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "calendarEdit") ? true : false
    },
    calendarAnalysis(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "calendarAnalysis") ? true : false
    },
    calendarAccess(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "calendarAccess") ? true : false
    },
    // TELECOM
    telecomAnalysis(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "telecomAnalysis") ? true : false
    },
    telecomAccess(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "telecomAccess") ? true : false
    },
    telecomManager(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "telecomManager") ? true : false
    },
    // CLIENTES
    clientEdit(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "clientEdit") ? true : false
    },
    clientAnalysis(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "clientAnalysis") ? true : false
    },
    clientAccess(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "clientAccess") ? true : false
    },
    clientDetail(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "clientDetail") ? true : false
    },
    // COLABORADORES
    staffEdit(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "staffEdit") ? true : false
    },
    staffAccess(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "staffAccess") ? true : false
    },
    staffDetail(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "staffDetail") ? true : false
    },
    staffChangePermissions(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "staffChangePermissions") ? true : false
    },
    // FINANCEIRO
    financeAnalysis(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "financeAnalysis") ? true : false
    },
    financeManager(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "financeManager") ? true : false
    },
    // SOLICITAÇÃO
    requestAnalysis(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "requestAnalysis") ? true : false
    },
    requestManager(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "requestManager") ? true : false
    },
    // SERVIÇOS
    serviceDetail(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "serviceDetail") ? true : false
    },
    serviceManager(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "serviceManager") ? true : false
    },
    serviceAnalysis(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "serviceAnalysis") ? true : false
    },
    serviceMake(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "serviceMake") ? true : false
    },
    // TAREFAS
    taskAnalysis(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "taskAnalysis") ? true : false
    },
    taskAccess(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "taskAccess") ? true : false
    },
    taskManager(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "taskManager") ? true : false
    },
    // TRACKER
    trackerEdit(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "trackerEdit") ? true : false
    },
    // VIATURAS
    vehicleAccess(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "vehicleAccess") ? true : false
    },
    vehicleAnalysis(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "vehicleAnalysis") ? true : false
    },
    vehicleEdit(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "vehicleEdit") ? true : false
    },
    vehicleManager(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "vehicleManager") ? true : false
    },
    // JURÍDICO
    processAccess(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "processAccess") ? true : false
    },
    processEdit(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "processEdit") ? true : false
    },
    processManager(state) {
      return state.user.superUser || state.user.permissions.find(
        (p1) => p1.code == "processManager") ? true : false
    },
  },
  mutations: {
    // UPDATE_USER(state, payload) {
    //   state.user = Object.assign(state.user, payload);
    // },
    UPDATE_DESC(state, { itemPermission, listPermissions }) {
      // console.log(itemPermission)
      itemPermission.forEach((permission) => {
        var code = permission.code;
        const descricao = listPermissions.find(
          (listPermission) => listPermission.code == code
        );
        state.permissionDescriptions.push(descricao.description);
      });
    },
    UPDATE_USER(state, payload) {
      state.user = payload;
    },
    UPDATE_PASSWORD(state, payload) {
      state.password = Object.assign(state.password, payload);
    },
    UPDATE_CHECKPASSWORD(state, payload) {
      state.checkPassword = Object.assign(state.checkPassword, payload);
    },
    //#################################################
    //                     MODAL
    //##################################################
    UPDATE_MODAL_PASSWORD(state, payload) {
      state.showModalPassword = payload;
    },

  },
  actions: {

    //##################################################
    //                 MODAL  
    //##################################################
    openModalPassword(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_PASSWORD", true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },

    closeModalPassword(context) {
      context.commit("UPDATE_MODAL_PASSWORD", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    //##################################################
    //            ALTERAR SENHA
    //##################################################
    updatePassword(context, { checkPassword, password, idCollaborator }) { //usando context por ter tanto "commit" quanto "dispatch"
      context.commit("UPDATE_LOADING_PASSWORD", true, { root: true });
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      if (password.password == checkPassword.checkPassword) {
        api.put(`/user/${idCollaborator}`, password)
          // console.log(collaborator)
          .then(() => {
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Password salvo com sucesso!",
            }, { root: true });
            context.commit("UPDATE_PASSWORD", this.getters["user/passwordReset"]);//limpa o formulário depois de cadastrar     
            context.commit("UPDATE_CHECKPASSWORD", this.getters["user/checkPasswordReset"]);//limpa o formulário depois de cadastrar           
            context.dispatch("closeModalPassword")
            context.commit("UPDATE_LOADING_PASSWORD", false, { root: true });
          })
          .catch(error => {
            context.commit("UPDATE_LOADING_PASSWORD", false, { root: true });
            console.log(error.response.data)
            if (error.response.data.error === "all data can't be null") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "password must have at least six characters") {
              context.commit("UPDATE_ERRORS", "password", { root: true });
            }
            if (error.response.data.error === "entity_already_exists" || error.response.status === 409) {
              context.commit("UPDATE_ERRORS", "notAvailable", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_LOADING_PASSWORD", false, { root: true });
        context.commit("UPDATE_ERRORS", "checkPassword", { root: true });
      }
    },
  }
}