import Vue from "vue";
import VueRouter from "vue-router";
import Cookies from 'js-cookie';
import store from '@/store';
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";

//##################################################
//       USER
//################################################## 
import User from "../views/user/User.vue";
import UserData from "../views/user/UserData.vue";
//##################################################
//       COLLABORATOR
//################################################## 
import CollaboratorInfoActive from "../views/collaborator/CollaboratorInfoActive.vue";
import CollaboratorInfoDisabled from "../views/collaborator/CollaboratorInfoDisabled.vue";
import Collaborators from "../views/collaborator/Collaborators.vue";
import CollabActive from "../views/collaborator/CollabActive.vue";
import CollabDisabled from "../views/collaborator/CollabDisabled.vue";
import CollaboratorEdit from "../views/collaborator/CollaboratorEdit.vue";
import CollabCreate from "../views/collaborator/CollabCreate.vue";
import EditEmail from "../views/collaborator/EditEmail.vue";
import EditPhone from "../views/collaborator/EditPhone.vue";
import EditAddressUser from "../views/collaborator/EditAddressUser.vue";
//##################################################
//       CLIENT
//################################################## 
import Clients from "../views/client/Clients.vue";
import Client from "../views/client/Client.vue";
import ClientEdit from "../views/client/ClientEdit.vue";
import ClientAdd from "../views/client/ClientAdd.vue";
//##################################################
//      TYPE CLIENT
//##################################################   
import TypeClients from "../views/client/TypeClients.vue";
import AddTypeClient from "../views/client/AddTypeClient.vue";
import TypeClient from "../views/client/TypeClient.vue";
import EditTypeClient from "../views/client/EditTypeClient.vue";
//##################################################
//       CONTACT
//################################################## 
import Contacts from "../views/contact/Contacts.vue";
import Contact from "../views/contact/Contact.vue";
import ContactEdit from "../views/contact/ContactEdit.vue";
import ContactAdd from "../views/contact/ContactAdd.vue";
//##################################################
//       ADDRESS
//################################################## 
import Addresses from "../views/address/Addresses.vue";
import Address from "../views/address/Address.vue";
import AddressEdit from "../views/address/AddressEdit.vue";
import AddressAdd from "../views/address/AddressAdd.vue";
//##################################################
//       TELECOM - CALL HISTORY
//################################################## 
import EditContact from "../views/telecom/EditContact.vue";
import CallHistory from "../views/telecom/CallHistory.vue";
import TelecomListPhone from "../views/telecom/TelecomListPhone.vue";
import TelecomPhoneInfo from "../views/telecom/TelecomPhoneInfo.vue";
import Services from "../views/service/Services.vue";
import Service from "../views/service/Service.vue";
import ServiceEdit from "../views/service/ServiceEdit.vue";
import ServiceAdd from "../views/service/ServiceAdd.vue";
import Variants from "../views/telecom/Variants.vue";
import VariantInfo from "../views/telecom/VariantInfo.vue";
import EditVariant from "../views/telecom/EditVariant.vue";
import AddVariant from "../views/telecom/AddVariant.vue";
//##################################################
//      TELECOM - MESSAGE
//################################################## 
import Messages from "../views/message/Messages.vue";
import Message from "../views/message/Message.vue";
import AddMessage from "../views/message/AddMessage.vue";
import EditMessage from "../views/message/EditMessage.vue";
import TelegramUsers from "../views/message/TelegramUsers.vue";
import TelegramUser from "../views/message/TelegramUser.vue";
//##################################################
//      VEHICLES
//##################################################   
import Vehicles from "../views/vehicle/Vehicles.vue";
import AddVehicle from "../views/vehicle/AddVehicle.vue";
import Vehicle from "../views/vehicle/Vehicle.vue";
import EditVehicle from "../views/vehicle/EditVehicle.vue";
//      TYPE VEHICLES
//##################################################   
import TypeVehicles from "../views/vehicle/TypeVehicles.vue";
import AddTypeVehicle from "../views/vehicle/AddTypeVehicle.vue";
import TypeVehicle from "../views/vehicle/TypeVehicle.vue";
import EditTypeVehicle from "../views/vehicle/EditTypeVehicle.vue";
//      TYPE VEHICLES - SERVICES
//##################################################   
import ServicesTypeVehicle from "../views/vehicle/ServicesTypeVehicle.vue";
//      INSPECTION - VEHICLES
//##################################################   
import Inspections from "../views/vehicle/Inspections.vue";
import AddInspection from "../views/vehicle/AddInspection.vue";
import EditInspection from "../views/vehicle/EditInspection.vue";
//      REVISION - VEHICLES
//##################################################   
import Revisions from "../views/vehicle/Revisions.vue";
import AddRevision from "../views/vehicle/AddRevision.vue";
import EditRevision from "../views/vehicle/EditRevision.vue";
//      INSURANCE - VEHICLES
//##################################################   
import Insurances from "../views/vehicle/Insurances.vue";
import AddInsurance from "../views/vehicle/AddInsurance.vue";
import EditInsurance from "../views/vehicle/EditInsurance.vue";
//##################################################
//      BRAND VEHICLES
//##################################################   
import Brands from "../views/vehicle/Brands.vue";
import Brand from "../views/vehicle/Brand.vue";
import EditBrand from "../views/vehicle/EditBrand.vue";
//##################################################
//      MODEL VEHICLES
//##################################################   
import Models from "../views/vehicle/Models.vue";
import AddModel from "../views/vehicle/AddModel.vue";
import Model from "../views/vehicle/Model.vue";
import EditModel from "../views/vehicle/EditModel.vue";
//      LEGAL
//##################################################   
import Opposites from "../views/legal/Opposites.vue";
import AddOpposite from "../views/legal/AddOpposite.vue";
import Opposite from "../views/legal/Opposite.vue";
import EditOpposite from "../views/legal/EditOpposite.vue";
//      PROCESS TAX - LEGAL
//##################################################   
import ProcessTypeTaxes from "../views/legal/processTypeTax/ProcessTypeTaxes.vue";
import AddProcessTypeTax from "../views/legal/processTypeTax/AddProcessTypeTax.vue";
import ProcessTypeTax from "../views/legal/processTypeTax/ProcessTypeTax.vue";
import EditProcessTypeTax from "../views/legal/processTypeTax/EditProcessTypeTax.vue";
//      PROCESS TAX - LEGAL
//##################################################   
// import ProcessTaxes from "../views/legal/processTax/ProcessTaxes.vue";
// import AddProcessTax from "../views/legal/processTax/AddProcessTax.vue";
// import ProcessTax from "../views/legal/processTax/ProcessTax.vue";
// import EditProcessTax from "../views/legal/processTax/EditProcessTax.vue";
//      PROCESS POSITION LEGAL
//##################################################   
import Positions from "../views/legal/processPosition/Positions.vue";
import AddPosition from "../views/legal/processPosition/AddPosition.vue";
import Position from "../views/legal/processPosition/Position.vue";
import EditPosition from "../views/legal/processPosition/EditPosition.vue";
//      PROCESS COURTS LEGAL
//##################################################   
import Courts from "../views/legal/processCourt/Courts.vue";
import AddCourt from "../views/legal/processCourt/AddCourt.vue";
import Court from "../views/legal/processCourt/Court.vue";
import EditCourt from "../views/legal/processCourt/EditCourt.vue";
//      PROCESS TYPE - LEGAL
//##################################################   
import ProcessTypes from "../views/legal/processType/ProcessTypes.vue";
import AddProcessType from "../views/legal/processType/AddProcessType.vue";
import ProcessType from "../views/legal/processType/ProcessType.vue";
import EditProcessType from "../views/legal/processType/EditProcessType.vue";
//      PROCESS - LEGAL
//##################################################   
import Processes from "../views/legal/process/Processes.vue";
import AddProcess from "../views/legal/process/AddProcess.vue";
import Process from "../views/legal/process/Process.vue";
import EditProcess from "../views/legal/process/EditProcess.vue";
//##################################################
//       STATUS
//################################################## 
import Forbidden from "../views/status/Forbidden.vue";
import ErrorBackend from "../views/status/ErrorBackend.vue";
import NotFound from "../views/status/NotFound.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "*",
    redirect: "pagina-nao-encontrada",
  },
  {
    path: "/",
    redirect: "/dashboard",
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    props: true,
    meta: {
      title: "Login - SimpliEsgoto®"
    }
  },
  {
    path: "/",
    component: User,
    meta: {
      login: true,
      title: "Usuário - SimpliEsgoto®"
    },
    children: [
      {
        path: "meus-dados",
        name: "meus-dados",
        component: UserData,
        props: true,
        meta: {
          login: true,
          title: "Meus Dados - SimpliEsgoto®"
        },
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard,
        meta: {
          login: true,
          title: "Dashboard - SimpliEsgoto®"
        },
      },
      //##################################################
      //       COLABORADORES
      //################################################## 
      {
        path: "colaboradores",
        name: "colaboradores",
        component: Collaborators,
        meta: {
          login: true,
          title: "Colaboradores - SimpliEsgoto®"
        },
        children: [
          {
            path: "colaborador-ativo",
            name: "colaborador-ativo",
            component: CollabActive,
            props: true,
            meta: {
              login: true,
              title: "Colaboradores Ativos - SimpliEsgoto®",
              codePermission: "staffAccess"
            },
          },
          {
            path: "colaborador-desativado",
            name: "colaborador-desativado",
            component: CollabDisabled,
            props: true,
            meta: {
              login: true,
              title: "Colaboradores desativados - SimpliEsgoto®",
              codePermission: "staffAccess"
            },
          },
          {
            path: "colaborador/:idCollaborator",
            name: "colaborador-info-ativo",
            component: CollaboratorInfoActive,
            props: true,
            meta: {
              login: true,
              title: "Informações - Colaborador Ativo - SimpliEsgoto®"
            },
          },
          {
            path: "colaborador/:idCollaborator",
            name: "colaborador-info-desativado",
            component: CollaboratorInfoDisabled,
            props: true,
            meta: {
              login: true,
              title: "Informações - Colaborador Desativado - SimpliEsgoto®"
            },
          },
          {
            path: "colaborador/:idCollaborator/email/:idEmail",
            name: "email",
            component: EditEmail,
            props: true,
            meta: {
              login: true,
              title: "Editar E-mail - Colaborador - SimpliEsgoto®",
              codePermission: "staffEdit"
            },
          },
          {
            path: "colaborador/:idCollaborator/phone/:idPhone",
            name: "telefone",
            component: EditPhone,
            props: true,
            meta: {
              login: true,
              title: "Editar Telefone - Colaborador - SimpliEsgoto®",
              codePermission: "staffEdit"
            },
          },
          {
            path: "colaborador/:idRouter/address/:idAddress",
            name: "moradaColaborador",
            component: EditAddressUser,
            props: true,
            meta: {
              login: true,
              title: "Editar Morada - Colaborador - SimpliEsgoto®",
              codePermission: "staffEdit"
            },
          },
          {
            path: "colaborador/:idCollaborator/editar-colaborador",
            name: "editar-colaborador",
            component: CollaboratorEdit,
            props: true,
            meta: {
              login: true,
              title: "Editar Usuário - Colaborador - SimpliEsgoto®",
              codePermission: "staffEdit"
            },
          },
          {
            path: "criar-colaborador",
            name: "criar-colaborador",
            component: CollabCreate,
            props: true,
            meta: {
              login: true,
              title: "Adicionar Colaborador - Colaborador - SimpliEsgoto®",
              codePermission: "staffEdit"
            },
          },
        ]
      },

      //##################################################
      //       CLIENTES
      //################################################## 
      {
        path: "clientes",
        name: "clientes",
        component: Clients,
        meta: {
          login: true,
          title: "Clientes - SimpliEsgoto®",
          codePermission: "clientAccess"
        },
      },
      {
        path: "clientes/criar-cliente",
        name: "criar-cliente",
        component: ClientAdd,
        meta: {
          login: true,
          title: "Adicionar Cliente - SimpliEsgoto®",
          codePermission: "clientEdit"
          // codePermission: "teste"
        },
      },
      {
        path: "clientes/cliente/:idClient",
        name: "cliente",
        component: Client,
        props: true,
        meta: {
          login: true,
          title: "Dados do Cliente - SimpliEsgoto®",
          codePermission: "clientAccess"
        },
      },
      {
        path: "cliente/:idClient/editar-cliente",
        name: "cliente-editar",
        component: ClientEdit,
        props: true,
        meta: {
          login: true,
          title: "Editar Cliente - SimpliEsgoto®",
          codePermission: "clientEdit"
        },
      },
      //##################################################
      //              TYPE VEHICLES
      //################################################## 

      {
        path: "clientes/tipos-clientes",
        name: "tipos-clientes",
        component: TypeClients,
        meta: {
          login: true,
          title: "Tipo de Clientes - SimpliEsgoto®",
          codePermission: "clientAccess",
        },
      },
      {
        path: "clientes/tipos-clientes/adicionar-tipo-cliente",
        name: "adicionar-tipo-cliente",
        component: AddTypeClient,
        meta: {
          login: true,
          title: "Adicionar Tipo de Cliente - SimpliEsgoto®",
          codePermission: "clientEdit",
        },
      },
      {
        path: "clientes/tipos-clientes/tipo-cliente/:idTypeClient",
        name: "tipo-cliente",
        component: TypeClient,
        props: true,
        meta: {
          login: true,
          title: "Tipo de Cliente - SimpliEsgoto®",
          codePermission: "clientAccess",
        },
      },
      {
        path: "clientes/tipos-clientes/tipo-cliente/:idTypeClient/editar-tipo-cliente",
        name: "editar-tipo-cliente",
        component: EditTypeClient,
        props: true,
        meta: {
          login: true,
          title: "Editar Tipo de Cliente - SimpliEsgoto®",
          codePermission: "clientEdit",
        },
      },
      //##################################################
      //       CONTACTOS
      //################################################## 
      {
        path: "contactos",
        name: "contactos",
        component: Contacts,
        meta: {
          login: true,
          title: "Contacto - SimpliEsgoto®",
          codePermission: "contactAccess"
        },
      },
      {
        path: "contactos/criar-contacto",
        name: "criar-contacto",
        component: ContactAdd,
        meta: {
          login: true,
          title: "Adicionar Contacto - SimpliEsgoto®",
          codePermission: "contactEdit"
          // codePermission: "teste"
        },
      },
      {
        path: "contactos/contacto/:idContact",
        name: "contacto",
        component: Contact,
        props: true,
        meta: {
          login: true,
          title: "Dados do Contacto - SimpliEsgoto®",
          codePermission: "contactAccess"
        },
      },
      {
        path: "contacto/:idContact/editar-contacto",
        name: "contacto-editar",
        component: ContactEdit,
        props: true,
        meta: {
          login: true,
          title: "Editar Contacto - SimpliEsgoto®",
          codePermission: "contactEdit"
        },
      },
      //##################################################
      //       ADDRESS
      //################################################## 
      {
        path: "moradas",
        name: "moradas",
        component: Addresses,
        meta: {
          login: true,
          title: "Morada - SimpliEsgoto®",
          codePermission: "addressAccess"
        },
      },
      {
        path: "moradas/criar-morada",
        name: "criar-morada",
        component: AddressAdd,
        meta: {
          login: true,
          title: "Adicionar Morada - SimpliEsgoto®",
          codePermission: "addressEdit"
          // codePermission: "teste"
        },
      },
      {
        path: "moradas/morada/:idAddress",
        name: "morada",
        component: Address,
        props: true,
        meta: {
          login: true,
          title: "Dados da Morada - SimpliEsgoto®",
          codePermission: "addressEdit"
        },
      },
      {
        path: "moradas/:idAddress/editar-morada",
        name: "morada-editar",
        component: AddressEdit,
        props: true,
        meta: {
          login: true,
          title: "Editar Morada - SimpliEsgoto®",
          codePermission: "addressEdit"
        },
      },
      //##################################################
      //  TELECOM - CENTRAL TELEFÓNICA
      //################################################## 

      // Histórico de chamadas
      {
        path: "central-telefonica/historico-chamadas",
        name: "historico-chamadas",
        component: CallHistory,
        meta: {
          login: true,
          title: "Histórico de Chamadas - Central Telefónica - SimpliEsgoto®",
          codePermission: "telecomAccess",
        },
      },
      // Lista telefones
      {
        path: "central-telefonica/lista-contacto",
        name: "lista-contacto",
        component: TelecomListPhone,
        meta: {
          login: true,
          title: "Lista de Contacto - Central Telefónica - SimpliEsgoto®",
          codePermission: "telecomAccess"
        },
      },
      {
        path: "central-telefonica/lista-contacto/contacto-interno-info/:idTelecomPhone",
        name: "contacto-interno-info",
        component: TelecomPhoneInfo,
        props: true,
        meta: {
          login: true,
          title: "Dados do Contacto - Central Telefónica - SimpliEsgoto®",
          codePermission: "telecomAccess"
        },
      },
      {
        path: "central-telefonica/lista-contacto/contacto-interno-info/:idTelecomPhone/editar-contacto",
        name: "editar-contacto",
        component: EditContact,
        props: true,
        meta: {
          login: true,
          title: "Editar Contacto - Central Telefónica - SimpliEsgoto®",
          codePermission: "telecomManager"
        },
      },

      //Variante
      {
        path: "central-telefonica/lista-redirecionamentos",
        name: "lista-redirecionamentos",
        component: Variants,
        meta: {
          login: true,
          title: "Lista de Variantes - Central Telefónica - SimpliEsgoto®",
          codePermission: "telecomAccess"
        },
      },

      {
        path: "central-telefonica/lista-redirecionamentos/dados-redirecionamento/:idVariant",
        name: "dados-redirecionamento",
        component: VariantInfo,
        props: true,
        meta: {
          login: true,
          title: "Dados do Redirecionamento - Central Telefónica - SimpliEsgoto®",
          codePermission: "telecomAccess"
        },
      },
      {
        path: "central-telefonica/lista-redirecionamentos/criar-redirecionamento",
        name: "criar-redirecionamento",
        component: AddVariant,
        props: true,
        meta: {
          login: true,
          title: "Adicionar Redirecionamento do Contacto - Central Telefónica - SimpliEsgoto®",
          codePermission: "telecomManager"
        },
      },
      {
        path: "central-telefonica/lista-redirecionamentos/dados-redirecionamento/:idVariant/editar-redirecionamento",
        name: "editar-redirecionamento",
        component: EditVariant,
        props: true,
        meta: {
          login: true,
          title: "Editar Redirecionamento do Contacto - Central Telefónica - SimpliEsgoto®",
          codePermission: "telecomManager"
        },
      },

      //##################################################
      //       SERVICES
      //################################################## 
      {
        path: "servicos",
        name: "servicos",
        component: Services,
        meta: {
          login: true,
          title: "Serviços - SimpliEsgoto®",
          codePermission: "serviceManager",
          codePermission2: "serviceDetail"
        },
      },
      {
        path: "servicos/criar-servico",
        name: "criar-servico",
        component: ServiceAdd,
        meta: {
          login: true,
          title: "Adicionar Serviço - SimpliEsgoto®",
          codePermission: "serviceManager"
        },
      },
      {
        path: "servicos/servico/:idService",
        name: "servico",
        component: Service,
        props: true,
        meta: {
          login: true,
          title: "Dados do Serviço - SimpliEsgoto®",
          codePermission: "serviceManager",
          codePermission2: "serviceDetail"
        },
      },
      {
        path: "servico/:idService/editar-servico",
        name: "editar-servico",
        component: ServiceEdit,
        props: true,
        meta: {
          login: true,
          title: "Editar Serviço - SimpliEsgoto®",
          codePermission: "serviceManager"
        },
      },
      //##################################################
      //              MESSAGE
      //################################################## 
      {
        path: "eventos",
        name: "mensagens",
        component: Messages,
        meta: {
          login: true,
          title: "Central de Eventos - SimpliEsgoto®",
          codePermission: "telecomAccess"
        },
      },
      {
        path: "eventos/adicionar-eventos",
        name: "adicionar-mensagem",
        component: AddMessage,
        meta: {
          login: true,
          title: "Adicionar Eventos - SimpliEsgoto®",
          codePermission: "telecomManager"
        },
      },
      {
        path: "eventos/evento/:idMessage",
        name: "mensagem",
        component: Message,
        props: true,
        meta: {
          login: true,
          title: "Detalhes do Evento - SimpliEsgoto®",
          codePermission: "telecomAccess"
        },
      },
      {
        path: "eventos/evento/:idMessage/editar-evento",
        name: "editar-mensagem",
        component: EditMessage,
        props: true,
        meta: {
          login: true,
          title: "Editar Evento - SimpliEsgoto®",
          codePermission: "telecomManager"
        },
      },
      {
        path: "eventos/usuarios-telegram",
        name: "usuarios-telegram",
        component: TelegramUsers,
        meta: {
          login: true,
          title: "Usuários do Telegram - SimpliEsgoto®",
          codePermission: "telecomAccess"
        },
      },
      {
        path: "eventos/usuarios-telegram/usuario-telegram/:idTelegramUser",
        name: "usuario-telegram",
        component: TelegramUser,
        props: true,
        meta: {
          login: true,
          title: "Detalhes da Usuário do Telegram - SimpliEsgoto®",
          codePermission: "telecomAccess"
        },
      },

      //##################################################
      //              VEHICLES
      //################################################## 

      {
        path: "viaturas",
        name: "viaturas",
        component: Vehicles,
        meta: {
          login: true,
          title: "Viaturas - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleAnalysis",
          codePermission3: "vehicleEdit",
          codePermission4: "vehicleAccess"
        },
      },
      {
        path: "viaturas/adicionar-viatura",
        name: "adicionar-viatura",
        component: AddVehicle,
        meta: {
          login: true,
          title: "Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit",
        },
      },
      {
        path: "viaturas/viatura/:idVehicle",
        name: "viatura",
        component: Vehicle,
        props: true,
        meta: {
          login: true,
          title: "Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleAnalysis",
          codePermission3: "vehicleEdit",
          codePermission4: "vehicleAccess"
        },
      },
      {
        path: "viaturas/viatura/:idVehicle/editar-viatura",
        name: "editar-viatura",
        component: EditVehicle,
        props: true,
        meta: {
          login: true,
          title: "Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit",
        },
      },
      //##################################################
      //              TYPE VEHICLES
      //################################################## 

      {
        path: "viaturas/tipos-viaturas",
        name: "tipos-viaturas",
        component: TypeVehicles,
        meta: {
          login: true,
          title: "Tipo de Viaturas - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleAnalysis",
          codePermission3: "vehicleEdit",
          codePermission4: "vehicleAccess"
        },
      },
      {
        path: "viaturas/tipos-viaturas/adicionar-tipo-viatura",
        name: "adicionar-tipo-viatura",
        component: AddTypeVehicle,
        meta: {
          login: true,
          title: "Adicionar Tipo de Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit",
        },
      },
      {
        path: "viaturas/tipos-viaturas/tipo-viatura/:idTypeVehicle",
        name: "tipo-viatura",
        component: TypeVehicle,
        props: true,
        meta: {
          login: true,
          title: "Tipo de Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleAnalysis",
          codePermission3: "vehicleEdit",
          codePermission4: "vehicleAccess"
        },
      },
      {
        path: "viaturas/tipos-viaturas/tipo-viatura/:idTypeVehicle/editar-tipo-viatura",
        name: "editar-tipo-viatura",
        component: EditTypeVehicle,
        props: true,
        meta: {
          login: true,
          title: "Editar Tipo de Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit",
        },
      },
      //##################################################
      //            SERVICE TYPE VEHICLES
      //################################################## 
      {
        path: "tipos-viaturas/tipo-viatura/:idTypeVehicle/servicos",
        name: "servicos-viatura",
        component: ServicesTypeVehicle,
        props: true,
        meta: {
          login: true,
          title: "Serviço do Tipo de Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleAnalysis",
          codePermission3: "vehicleEdit",
          codePermission4: "vehicleAccess"
        },
      },
      //##################################################
      //              INSPECTION - VEHICLES
      //################################################## 

      {
        path: "viaturas/viatura/:idVehicle/inspecoes",
        name: "inspecoes-viatura",
        component: Inspections,
        props: true,
        meta: {
          login: true,
          title: "Inspeção da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleAnalysis",
          codePermission3: "vehicleEdit",
          codePermission4: "vehicleAccess"
        },
      },
      {
        path: "viaturas/viatura/:idVehicle/inspecoes/adicionar-inspecao",
        name: "adicionar-inspecao",
        component: AddInspection,
        props: true,
        meta: {
          login: true,
          title: "Adicionar Inspeção da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit"
        },
      },
      {
        path: "viaturas/viatura/:idVehicle/inspecoes/:idInspection/editar-inspecao",
        name: "editar-inspecao",
        component: EditInspection,
        props: true,
        meta: {
          login: true,
          title: "Editar Inspeção da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit"
        },
      },
      //##################################################
      //              REVISION - VEHICLES
      //################################################## 

      {
        path: "viaturas/viatura/:idVehicle/revisoes",
        name: "revisoes-viatura",
        component: Revisions,
        props: true,
        meta: {
          login: true,
          title: "Revisão da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleAnalysis",
          codePermission3: "vehicleEdit",
          codePermission4: "vehicleAccess"
        },
      },
      {
        path: "viaturas/viatura/:idVehicle/revisoes/adicionar-revisao",
        name: "adicionar-revisao",
        component: AddRevision,
        props: true,
        meta: {
          login: true,
          title: "Adicionar Revisão da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit"
        },
      },
      {
        path: "viaturas/viatura/:idVehicle/revisoes/:idRevision/editar-revisao",
        name: "editar-revisao",
        component: EditRevision,
        props: true,
        meta: {
          login: true,
          title: "Editar Revisão da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit"
        },
      },

      //##################################################
      //              INSURANCE - VEHICLES
      //################################################## 

      {
        path: "viaturas/viatura/:idVehicle/seguro",
        name: "seguros-viatura",
        component: Insurances,
        props: true,
        meta: {
          login: true,
          title: "Seguros da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleAnalysis",
          codePermission3: "vehicleEdit",
          codePermission4: "vehicleAccess"
        },
      },
      {
        path: "viaturas/viatura/:idVehicle/seguro/adicionar-seguro",
        name: "adicionar-seguro",
        component: AddInsurance,
        props: true,
        meta: {
          login: true,
          title: "Adicionar Seguro da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit"
        },
      },
      {
        path: "viaturas/viatura/:idVehicle/seguro/:idInsurance/editar-seguro",
        name: "editar-seguro",
        component: EditInsurance,
        props: true,
        meta: {
          login: true,
          title: "Editar Seguro da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit"
        },
      },

      //##################################################
      //              BRAND  VEHICLES
      //################################################## 

      {
        path: "viaturas/marcas",
        name: "marcas",
        component: Brands,
        meta: {
          login: true,
          title: "Marcas das Viaturas - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleAnalysis",
          codePermission3: "vehicleEdit",
          codePermission4: "vehicleAccess"
        },
      },
      {
        path: "viaturas/marcas/marca/:idBrand",
        name: "marca",
        component: Brand,
        props: true,
        meta: {
          login: true,
          title: "Marca da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleAnalysis",
          codePermission3: "vehicleEdit",
          codePermission4: "vehicleAccess"
        },
      },
      {
        path: "viaturas/marcas/marca/:idBrand/editar-marca",
        name: "editar-marca",
        component: EditBrand,
        props: true,
        meta: {
          login: true,
          title: "Editar Modelo da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit",
        },
      },

      //##################################################
      //              MODEL  VEHICLES
      //################################################## 

      {
        path: "viaturas/modelos",
        name: "modelos",
        component: Models,
        meta: {
          login: true,
          title: "Modelos das Viaturas - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleAnalysis",
          codePermission3: "vehicleEdit",
          codePermission4: "vehicleAccess"
        },
      },
      {
        path: "viaturas/modelos/adicionar-modelo",
        name: "adicionar-modelo",
        component: AddModel,
        meta: {
          login: true,
          title: "Adicionar Modelo da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit",
        },
      },
      {
        path: "viaturas/modelos/modelo/:idModel",
        name: "modelo",
        component: Model,
        props: true,
        meta: {
          login: true,
          title: "Modelo da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleAnalysis",
          codePermission3: "vehicleEdit",
          codePermission4: "vehicleAccess"
        },
      },
      {
        path: "viaturas/modelos/modelo/:idModel/editar-modelo",
        name: "editar-modelo",
        component: EditModel,
        props: true,
        meta: {
          login: true,
          title: "Editar Modelo da Viatura - SimpliEsgoto®",
          codePermission: "vehicleManager",
          codePermission2: "vehicleEdit",
        },
      },


      //##################################################
      //              LEGAL
      //################################################## 
      {
        path: "juridico/intervenientes",
        name: "intervenientes",
        component: Opposites,
        meta: {
          login: true,
          title: "Jurídico - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
          codePermission3: "processAccess"
        },
      },
      {
        path: "juridico/adicionar-interveniente",
        name: "adicionar-interveniente",
        component: AddOpposite,
        meta: {
          login: true,
          title: "Jurídico - Adicionar Interveniente - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
        },
      },
      {
        path: "juridico/interveniente/:idOpposite",
        name: "interveniente",
        component: Opposite,
        props: true,
        meta: {
          login: true,
          title: "Jurídico - Interveniente - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
          codePermission3: "processAccess"
        },
      },
      {
        path: "juridico/interveniente/:idOpposite/editar-interveniente",
        name: "editar-interveniente",
        component: EditOpposite,
        props: true,
        meta: {
          login: true,
          title: "Jurídico - Editar Interveniente - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
        },
      },
      //              PROCESS TAX - LEGAL
      //################################################## 
      {
        path: "juridico/tipo-de-taxas",
        name: "tipo-de-taxas",
        component: ProcessTypeTaxes,
        meta: {
          login: true,
          title: "Jurídico - Tipo de Taxas - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
          codePermission3: "processAccess"
        },
      },
      {
        path: "juridico/taxas/adicionar-tipo-de-taxa",
        name: "adicionar-tipo-de-taxa",
        component: AddProcessTypeTax,
        meta: {
          login: true,
          title: "Jurídico - Adicionar Tipo de Taxa - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
        },
      },
      {
        path: "juridico/tipo-de-taxas/tipo-de-taxa/:idProcessTypeTax",
        name: "tipo-de-taxa",
        component: ProcessTypeTax,
        props: true,
        meta: {
          login: true,
          title: "Jurídico - Tipo de Taxa - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
          codePermission3: "processAccess"
        },
      },
      {
        path: "juridico/tipo-de-taxas/tipo-de-taxa/:idProcessTypeTax/editar-tipo-de-taxa",
        name: "editar-tipo-de-taxa",
        component: EditProcessTypeTax,
        props: true,
        meta: {
          login: true,
          title: "Jurídico - Editar Tipo de Taxa - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
        },
      },
      //              PROCESS POSITION  - LEGAL
      //################################################## 
      {
        path: "juridico/lista-posicoes",
        name: "lista-posicoes",
        component: Positions,
        meta: {
          login: true,
          title: "Jurídico - Posições - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
          codePermission3: "processAccess"
        },
      },
      {
        path: "juridico/lista-posicoes/adicionar-posicao",
        name: "adicionar-posicao",
        component: AddPosition,
        meta: {
          login: true,
          title: "Jurídico - Adicionar Posição - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
        },
      },
      {
        path: "juridico/lista-posicoes/posicao/:idProcessPosition",
        name: "posicao",
        component: Position,
        props: true,
        meta: {
          login: true,
          title: "Jurídico - Posição - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
          codePermission3: "processAccess"
        },
      },
      {
        path: "juridico/lista-posicoes/posicao/:idProcessPosition/editar-posicao",
        name: "editar-posicao",
        component: EditPosition,
        props: true,
        meta: {
          login: true,
          title: "Jurídico - Editar Posição - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
        },
      },
      //              PROCESS COURTS - LEGAL
      //################################################## 
      {
        path: "juridico/tribunais",
        name: "tribunais",
        component: Courts,
        meta: {
          login: true,
          title: "Jurídico - Tribunais - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
          codePermission3: "processAccess"
        },
      },
      {
        path: "juridico/tribunais/adicionar-tribunal",
        name: "adicionar-tribunal",
        component: AddCourt,
        meta: {
          login: true,
          title: "Jurídico - Adicionar Tribunal - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
        },
      },
      {
        path: "juridico/tribunais/tribunal/:idProcessCourt",
        name: "tribunal",
        component: Court,
        props: true,
        meta: {
          login: true,
          title: "Jurídico - Tribunal - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
          codePermission3: "processAccess"
        },
      },
      {
        path: "juridico/tribunais/tribunal/:idProcessCourt/editar-tribunal",
        name: "editar-tribunal",
        component: EditCourt,
        props: true,
        meta: {
          login: true,
          title: "Jurídico - Editar Tribunal - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
        },
      },
      //              PROCESS TYPE - LEGAL
      //################################################## 
      {
        path: "juridico/tipos-de-processos",
        name: "tipos-de-processos",
        component: ProcessTypes,
        meta: {
          login: true,
          title: "Jurídico - Tipos de Processos - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
          codePermission3: "processAccess"
        },
      },
      {
        path: "juridico/tipos-de-processos/adicionar-tipo-de-processo",
        name: "adicionar-tipo-de-processo",
        component: AddProcessType,
        meta: {
          login: true,
          title: "Jurídico - Adicionar Tipo de Processo - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
        },
      },
      {
        path: "juridico/tipos-de-processos/tipo-de-processo/:idProcessType",
        name: "tipo-de-processo",
        component: ProcessType,
        props: true,
        meta: {
          login: true,
          title: "Jurídico - Tipo de Processo - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
          codePermission3: "processAccess"
        },
      },
      {
        path: "juridico/tipos-de-processos/tipo-de-processo/:idProcessType/editar-tipo-de-processo",
        name: "editar-tipo-de-processo",
        component: EditProcessType,
        props: true,
        meta: {
          login: true,
          title: "Jurídico - Editar Tipo de Processo - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
        },
      },
      //              PROCESS - LEGAL
      //################################################## 
      {
        path: "juridico/processos",
        name: "processos",
        component: Processes,
        meta: {
          login: true,
          title: "Jurídico - Processos - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
          codePermission3: "processAccess"
        },
      },
      {
        path: "juridico/adicionar-processo",
        name: "adicionar-processo",
        component: AddProcess,
        // props: true,
        meta: {
          login: true,
          title: "Jurídico - Adicionar Processo - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
        },
      },
      {
        path: "juridico/processos/processo/:idProcess",
        name: "processo",
        component: Process,
        props: true,
        meta: {
          login: true,
          title: "Jurídico - Processo - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
          codePermission3: "processAccess"
        },
      },
      {
        path: "juridico/processos/processo/:idProcess/editar-processo",
        name: "editar-processo",
        component: EditProcess,
        props: true,
        meta: {
          login: true,
          title: "Jurídico - Editar Processo - SimpliEsgoto®",
          codePermission: "processManager",
          codePermission2: "processEdit",
        },
      },
      
      //##################################################
      //       STATUS - redirecionamento
      //################################################## 

      //###### 403
      {
        path: "restrito",
        name: "restrito",
        component: Forbidden,
        meta: {
          login: true,
          title: "Restrito - SimpliEsgoto®"
        },
      },
      //###### 404
      {
        path: "pagina-nao-encontrada",
        name: "pagina-nao-encontrada",
        component: NotFound,
        meta: {
          login: true,
          title: "Página não encontrada - SimpliEsgoto®"
        },
      },
      //###### 500
      {
        path: "erro-interno",
        name: "erro-interno",
        component: ErrorBackend,
        meta: {
          login: true,
          title: "Erro Interno - SimpliEsgoto®"
        },
      },

    ]
  },

];

const router = new VueRouter({
  mode: "history", /*tira o "#" da rota*/
  base: process.env.BASE_URL, /* é a "/" */
  routes,

});

router.beforeEach((to, from, next) => {
  /*###################################################################
  ##  PERMISSÕES - PEGANDO AS PERMISSÕES DO USUÁRIO E DA PÁGINA
  ###################################################################*/
  if (Cookies.get('expire')) {
    var user = JSON.parse(localStorage.getItem('UserCookie'))
    var permissions = user.permissions
    // var type = to.meta.typePermission
    var verifyPermission = to.meta.codePermission
    var verifyPermission2 = to.meta.codePermission2
    var verifyPermission3 = to.meta.codePermission3
    var verifyPermission4 = to.meta.codePermission4
  }

  // }
  // COMPARANDO AS PERMISSÕES DO USUÁRIO E DA PÁGINA
  if (permissions != undefined) {
    let permission = user.superUser == true || permissions.find(p => { return p.code === verifyPermission }) ? true : false
    let permission2 = user.superUser == true || permissions.find(p => { return p.code === verifyPermission2 && verifyPermission2 != undefined }) ? true : false
    let permission3 = user.superUser == true || permissions.find(p => { return p.code === verifyPermission3 && verifyPermission3 != undefined }) ? true : false
    let permission4 = user.superUser == true || permissions.find(p => { return p.code === verifyPermission4 && verifyPermission4 != undefined }) ? true : false
    if (user != undefined && verifyPermission != undefined && permission === false && permission2 === false && permission3 === false && permission4 === false) {
      next({ path: "/restrito" })
      permission = null
      permission2 = null
    }
    // if (type == "1") {
    //   console.log(permissions.find(p => { return p.code === verifyPermission }))
    //   if (permission === false && permission2 === false && permission3 === false)
    //     next({ path: "/restrito" })
    //   permission = null
    //   permission2 = null
    // }
  }
  /*###################################################################
  ##           TITLE DAS PÁGINAS DINÂMICO 
  ###################################################################*/
  const DEFAULT_TITLE = 'SimpliEsgoto®';
  document.title = to.meta.title || DEFAULT_TITLE
  // console.log("router index", to, from)

  /*###################################################################
  ##         VOLTA AO TOPO SOMENTE QUANDO MUDA DE PÁGINA
  ###################################################################*/
  to.name === from.name ? '' : next(window.scrollTo(0, 0))

  /*###################################################################
  ##    VERIFICANDO TOKEN + SE TEM URL NA MEMÓRIA
  ###################################################################*/
  // const resetModal = store.commit("UPDATE_MODAL_LOGIN", false)
  if (to.matched.some(record => record.meta.login)) {
    if (!Cookies.get('expire') && from.path != "/") {
      store.commit("UPDATE_MODAL_LOGIN", true);
    }
    if (!Cookies.get('expire') && from.path == "/") {
      localStorage.removeItem('tokenCookie')
      localStorage.removeItem('UserCookie')
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
      // next("/login");
    } else {
      next();
    }
  }
  else {
    next();
  }
});

export default router;

