<template>
  <footer>
    <figure>
      <img
        src="@/assets/logo-simpliesgoto-rodape-branco.png"
        alt="Simpliesgoto"
      />
    </figure>
    <p v-if="version">
      {{ version.data }}
    </p>
  </footer>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "TheFooter",
  computed: {
    ...mapGetters(["version"]),
    ...mapState(["version"]),
  },
};
</script>

<style scoped>
footer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: #1f2b42;
  font-size: 16px;
  position: relative;
  bottom: 0px;
}

footer p {
  color: #ffffff;
}

footer figure img {
  height: 35px;
}
@media screen and (max-width: 690px) {
  footer {
    justify-content: center;
  }
}
</style>