<template>
  <!-- <section class="contentGeneral" v-if="collaborator"> -->
  <section class="contentGeneral">
    <h3>Editar Colaborador</h3>
    <nav class="navBreadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'colaborador-ativo' }"
            >Lista Ativos</router-link
          >
        </li>
        <li class="breadcrumb-item">
          <router-link
            :to="{
              name: 'colaborador-info-ativo',
              params: { idCollaborator: idCollaborator },
            }"
            >Dados do Colaborador</router-link
          >
        </li>
        <li class="breadcrumb-item-active">
          <span> Editar Colaborador</span>
        </li>
      </ol>
    </nav>
    <transition mode="out-in">
      <!-- <CollaboratorForm :collaborator="collaborator">
        <button class="btn btn-form" @click.prevent="updateCollaborator">
          Salvar
        </button>
      </CollaboratorForm> -->
      <UserForm :collaborator="collaborator" :errors="errors">
        <button class="btn btn-form" @click.prevent="updateCollaborator">
          Gravar
        </button>
      </UserForm>
    </transition>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import UserForm from "@/components/collaborator/UserForm.vue";
// import CollaboratorForm from "@/components/collaborator/CollaboratorForm.vue";
import { mapGetters, mapState } from "vuex";
export default {
  name: "EditCollaborator",
  // props: ["idCollaborator", "collaborator"],
  props: ["idCollaborator"],
  // props: ["collaborator"],
  components: {
    UserForm,
    // CollaboratorForm,
  },
  data() {
    return {
      create: false,
    };
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("collaborator", ["collaborator"]),
    ...mapGetters("collaborator", [
      "collaboratorReply",
      "collaboratorReset",
      "checkPasswordReply",
      "checkPasswordReset",
    ]),
  },
  methods: {
    updateCollaborator() {
      this.$store.dispatch("collaborator/updateCollaborator", {
        idCollaborator: this.idCollaborator,
        collaborator: this.collaboratorReply, //enviar getters com as infos necessárias para o cadastro
        checkPassword: this.checkPasswordReply,
      });
      // this.$store.dispatch("collaborator/getCollaborators");
    },
    reset() {
      this.$store.commit("RESET_ERRORS", []);
      this.$store.commit(
        "collaborator/UPDATE_COLLABORATOR",
        this.collaboratorReset
      );
      this.$store.commit(
        "collaborator/UPDATE_CHECKPASSWORD",
        this.checkPasswordReset
      );
    },
  },
  created() {
    this.reset();
    this.$store.dispatch("collaborator/getCollaborator", {
      idCollaborator: this.idCollaborator,
    });
  },
};
</script>

<style scoped>
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
</style>