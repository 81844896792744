<template>
  <form action="">
    <div v-if="loadingPassword">
      <Loading />
    </div>
    <div v-else>
      <div class="formItens">
        <label for="password"> Nova Password </label>

        <!-- check password -->
        <input
          v-if="!errors.indexOf('full')"
          id="password"
          name="password"
          type="password"
          v-model="password"
          :class="validateFull"
          placeholder="Password é um campo requerido"
        />
        <input
          v-else-if="!errors.indexOf('password')"
          id="password"
          name="password"
          type="password"
          v-model="password"
          :class="validatePassword"
        />
        <input
          v-else
          id="password"
          name="password"
          type="password"
          v-model="password"
          :class="validateFull || validateCheckPassword"
        />
        <!-- password -->
      </div>
      <div class="formItens">
        <label for="password"> Confirme a Password </label>
        <input
          v-if="!errors.indexOf('full')"
          id="checkPassword"
          name="checkPassword"
          type="password"
          v-model="checkPassword"
          :class="validateFull"
          placeholder="Password é um campo requerido"
        />
        <input
          v-else-if="!errors.indexOf('password')"
          id="checkPassword"
          name="checkPassword"
          type="password"
          v-model="checkPassword"
          :class="validatePassword"
        />
        <input
          v-else
          id="checkPassword"
          name="checkPassword"
          type="password"
          v-model="checkPassword"
          :class="validateFull || validateCheckPassword"
        />
      </div>

      <!-- <div class="formItens" v-if="!errors.indexOf('full')">
        <label for="password"> Novo Password </label>
        <input
          id="password"
          name="password"
          type="password"
          v-model="password"
          :class="validateFull"
          placeholder="Password é um campo requerido"
        />
      </div>
      <div v-else-if="!errors.indexOf('password')">
        <div class="formItens">
          <label for="password"> Novo Password </label>
          <input
            id="password"
            name="password"
            type="password"
            v-model="password"
            :class="validatePassword"
          />
        </div>
        <div v-if="!errors.indexOf('password')" class="notify">
          Password deve ter pelo menos seis caracteres
        </div>
      </div> -->
      <!-- <div class="formItens" v-else>
        <label for="password"> Novo Password </label>
        <input
          id="password"
          name="password"
          type="password"
          v-model="password"
          :class="validateFull"
        />
      </div> -->
    </div>
    <div v-if="!errors.indexOf('checkPassword')" class="notify">
      As passwords não são iguais
    </div>
    <div v-if="!errors.indexOf('password')" class="notify">
      Password deve ter pelo menos seis caracteres
    </div>
    <div class="button">
      <slot></slot>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  props: ["collaborator", "errors"],
  computed: {
    ...mapState(["loadingPassword"]),
    // ...mapState("user", ["checkPassword", "password"]),
    validateFull() {
      return this.errors.indexOf("full") ? "true" : "validate";
    },

    validateNotAvailable() {
      return this.errors.indexOf("notAvailable") ? "true" : "validate";
    },

    validatePassword() {
      return this.errors.indexOf("password") ? "true" : "validate";
    },

    validateCheckPassword() {
      return this.errors.indexOf("checkPassword") ? "true" : "validate";
    },

    ...mapFields({
      fields: ["password"], //nome dos campos
      module: "user", //qual módulo está acessando
      base: "password", //colocar o state que vai receber
      mutation: "user/UPDATE_PASSWORD", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["checkPassword"], //nome dos campos
      module: "user", //qual módulo está acessando
      base: "checkPassword", //colocar o state que vai receber
      mutation: "user/UPDATE_CHECKPASSWORD", //nome da mutation que vai alterar o state
    }),
  },
  created() {
    // console.log(this.checkPassword.value != this.password.value);
  },
};
</script>

<style scoped>
form {
  display: grid;
  /* grid-template-columns: minmax(400px, 700px); */
  grid-template-columns: 1fr;
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
  padding: 0 20px;
}

.formItens {
  /* grid-template-columns: 100px 1fr; */
  grid-template-columns: 1fr;
}

.button {
  display: grid;
  grid-template-columns: 300px;
  /* padding: 20px; */
  /* grid-column:1fr 1fr; */
  /* justify-content: flex-end; */
  margin: 20px auto;
}

@media screen and (max-width: 560px) {
  .formItens {
    grid-template-columns: 1fr !important;
  }
  .button {
    grid-template-columns: 1fr !important;
  }
  .notify {
    text-align: center;
    margin-left: 0;
  }
}
.notify {
  text-align: center;
  margin-left: 0;
  margin-top: 15px;
  margin-bottom: 0;
}
/* .notify {
  text-align: center;
  margin-left: 110px;
  margin-bottom: 0;
} */
</style>