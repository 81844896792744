<template>
  <form action="">
    <div v-if="loadingProcess">
      <p><LoadingCircle /></p>
    </div>
    <div class="formInfos" v-else>
      <label for="value"> Valor: </label>
      <input
        id="value"
        name="value"
        type="text"
        v-model.number="value"
        :class="validateFull"
        @change="resetError"
      />
      <span v-if="!errors.indexOf('value')" class="notify2">
        O valor é um campo requerido
      </span>
      <label for="nif"> Tipo de Taxa: </label>
      <select
          v-model="taxTypeID"
          class="option"
          @change="resetError"
        >
          <option value="null">Selecione uma Taxa</option>
          <option
            id="taxTypeID"
            v-for="(processTypeTaxe, index) in processTypeTaxes"
            :key="processTypeTaxe.id"
            :selected="index"
            :value="processTypeTaxe.id"
          >
            {{ processTypeTaxe.name }}
          </option>
      </select>
      <div v-if="!errors.indexOf('taxTypeID')" class="notify">
        A Tipo de Taxa é um campo requerido.
      </div>  
      <label for="date" > Data de Pagamento: </label>
          <input
            id="date"
            type="date"
            v-model="paymentDate"
            autocomplete="off"
            @change="resetError"
          />
      <!-- <label for="date"> Ficheiro: </label>
      <slot name="ficheiro"></slot> -->
      
    </div>
    
        
    <div class="button">
      <slot name="cancel"></slot>
      <slot name="enviar"></slot>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "TaxForm",
  props: ["errors"],
  computed: {
    ...mapState("legal", ["processTax", "taxUpdate"]),
    validateFull() {
      return this.errors.indexOf("value") ? "true" : "validate";
    },
    validateTaxType() {
      return this.errors.indexOf("taxTypeID") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["value", "taxTypeID", "paymentDate"], //nome dos campos
      module: "legal", //qual módulo está acessando
      base: "processTax", //colocar o state que vai receber
      mutation: "legal/UPDATE_PROCESS_TAX", //nome da mutation que vai alterar o state
    }),
    ...mapState("legal", ["loadingProcess", "processTypeTaxes"]),
  },
  methods: {
    resetError() {
      this.$store.commit("RESET_ERRORS", []);
    },
  },
  created() {
    this.$store.dispatch("legal/getProcessTypeTaxes");
  },
};
</script>

<style scoped>
.formInfos {
  display: grid;
  grid-template-columns: 180px 100px;
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  margin: 20px 10px;
  align-content: center;
}
.icon-add {
  fill: #fff;
  /* stroke: #fff; */
  z-index: 99;
}
.buttonAdd {
  display: grid;
  justify-content: center;
  align-items: center;
  /* margin-left: 60px; */
  background-color: #00bf48;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin: 0 15px;
  overflow: hidden;
  /* fill: #fff; */
  color: #ffffff;
}
select {
  margin: 10px 0;
}
.selectContainer,
.radioContainer {
  margin: 20px 0;
  /* display: grid;
  grid-template-columns: 30px 1fr; */
}
.radioContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: 0 4px 8px rgb(31 43 66 / 10%);
  padding: 20px;
  border-radius: 9px;
  margin: 20px 0;
}

.radio {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  outline: none;
  box-shadow: none;
}
form {
  max-width: 500px;
}
.formInfos {
  display: grid;
  grid-template-columns: minmax(300px, 500px);
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  margin-top: 20px;
  margin-right: 10px;
  max-width: 490px;
}

label {
  margin-top: 10px;

  outline: none;
}

.formInfos2 label {
  cursor: pointer;
}
.button {
   display: flex;
  grid-gap: 10px;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}
</style>