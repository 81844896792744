<template>
  <div>
    <h1>Clientes</h1>
    <div class="contentGeneral">
      <div class="titleContainer">
        <h3>Editar Cliente</h3>
      </div>

      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'clientes' }"
              >Lista de Clientes</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'cliente',
                params: { idClient: idClient },
              }"
              >Dados do Cliente</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Editar Cliente</span>
          </li>
        </ol>
      </nav>
      <div class="itemFormCollaborator">
        <ClientForm :client="client" :errors="errors">
          <div slot="cancel"></div>
          <div slot="delete" class="btnJustify">
            <button class="btn" @click.prevent="updateClient">Gravar</button>
          </div>
        </ClientForm>
      </div>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </div>
</template>

<script>
import ClientForm from "@/components/client/ClientForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditClient",
  props: ["idClient", "idRouter"],
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("client", ["client"]),
    ...mapGetters("client", ["clientReply", "clientReset"]),
  },
  components: {
    ClientForm,
  },
  methods: {
    updateClient() {
      this.$store.dispatch("client/updateClient", {
        idClient: this.idClient,
        client: this.clientReply,
      });
    },
  },
  created() {
    this.$store.commit("client/UPDATE_CLIENT", this.clientReset);
    this.$store.dispatch("client/getClient", {
      idClient: this.idClient,
    });
  },
};
</script>

<style scoped>
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.btnJustify {
  display: grid;
  justify-content: flex-end;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
.itemFormCollaborator {
  margin-left: 10px;
}
</style>