<template>
  <form action="">
    <div class="">
      <div v-if="loadingVehicle">
        <p><Loading /></p>
      </div>
      <div class="formInfos" v-else>
        <label for="name"> Nome: </label>
        <input
          id="name"
          name="name"
          type="text"
          v-model="name"
          autocomplete="off"
          @change="resetError"
          :class="[validateFull, validateVehicleType]"
        />
        <div v-if="!errors.indexOf('full')" class="notify2">
          A nome é um campo requerido
        </div>
        <div v-if="!errors.indexOf('nameExist')" class="notify2">
          O nome já existe.
        </div>
        <label for="description"> Descrição: </label>
        <input
          id="description"
          name="description"
          type="text"
          v-model="description"
          autocomplete="off"
          @change="resetError"
          :class="[validateDescription]"
        />
        <div v-if="!errors.indexOf('description')" class="notify2">
          A descrição é um campo requerido
        </div>
        <div class="button">
          <slot></slot>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "MessageForm",
  props: ["errors"],
  data() {
    return {
      contactName: [],
    };
  },
  mounted() {},
  computed: {
    validateFull() {
      return this.errors.indexOf("full") ? "true" : "validate";
    },
    validateDescription() {
      return this.errors.indexOf("description") ? "true" : "validate";
    },
    validateVehicleType() {
      return this.errors.indexOf("nameExist") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["name", "description"], //nome dos campos
      module: "vehicle", //qual módulo está acessando
      base: "typeVehicle", //colocar o state que vai receber
      mutation: "vehicle/UPDATE_TYPEVEHICLE", //nome da mutation que vai alterar o state
    }),
    ...mapState(["loadingVehicle"]),
    ...mapState("vehicle", ["typeVehicle", "typeVehicles"]),
  },
  methods: {
    resetError() {
      this.$store.commit("RESET_ERRORS", []);
    },
  },
};
</script>

<style scoped>
.formInfos {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-top: 20px; */
  align-content: center;
  max-width: 500px;
}
input.validate {
  margin: 0;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
@media screen and (max-width: 690px) {
  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: auto;
    width: 100%;
  }
  label,
  input {
    width: 100%;
    justify-items: flex-start;
  }
}
</style>