<template>
  <div class="itemsCollab">
    <div v-if="loadingCollaborators">
      <Loading />
    </div>
    <div v-else>
      <div v-if="collabActive">
        <div class="titleContainer">
          <h3>Ativos</h3>
          <div v-if="staffEdit">
            <router-link
              class="collabItens"
              :to="{
                name: 'criar-colaborador',
              }"
            >
              <AddUser>
                <span slot="title">
                  Adicionar<br />
                  Colaborador</span
                >
              </AddUser>
            </router-link>
          </div>
        </div>
        <nav class="navBreadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item-active">
              <span> Lista Ativos</span>
            </li>
          </ol>
        </nav>
        <CollaboratorSearch />
        <div class="titleCollab">
          <p class="titleBlue">Nome</p>
          <p class="titleBlue">E-mail</p>
        </div>
        <hr />
        <ul
          v-for="collaborator in filteredCollaborators"
          :key="collaborator.id"
        >
          <li class="itemList">
            <CollaboratorActivItem :collaborator="collaborator" />
          </li>
        </ul>
        <!-- <Pagination
          :data="collabActive"
          :totalPages="Math.ceil(collabActive.length / limit)"
          :total="collabActive.length"
          :currentPage="currentPage"
          @pagechanged="onPageChange"
        >
        </Pagination> -->
      </div>
      <div v-else>
        <p class="noResults">Não possui colaboradores cadastrados</p>
      </div>
    </div>
  </div>
</template>

<script>
import CollaboratorActivItem from "@/components/collaborator/CollaboratorActivItem.vue";
import CollaboratorSearch from "@/components/collaborator/CollaboratorSearch.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "CollaboratorActiv",
  components: {
    CollaboratorActivItem,
    CollaboratorSearch,
  },
  computed: {
    ...mapState(["limit", "currentPage"]),
    ...mapState("collaborator", [
      "collaborator",
      "collabActive",
      "loadingCollaborators",
      "searchWord",
      "filtered",
    ]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["staffEdit"]),
    ...mapGetters("collaborator", ["paginatedData", "getFiltered"]),
    filteredCollaborators() {
      const a = this.getFiltered || this.collabActive;
      return a;
    },
  },
  methods: {
    //Paginação
    onPageChange(page) {
      this.$store.commit("UPDATE_CURRENTPAGE", page);
    },
  },
  created() {
    this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
  },
};
</script>

<style scoped>
.titleBlue {
  padding-left: 10px;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 5px 0;
  text-align: left;
  margin-left: 10px;
}
/* .itemsCollab {
  padding: 40px 20px;
} */

.noResults {
  margin: 60px auto;
  max-width: 500px;
  text-align: center;
  padding: 10px;
  transition: all 0.2s;
}

.breadcrumb-item-active {
  margin-left: 10px;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
}
</style>