<template>
  <section class="itemContainer">
    <router-link
      class="collabItens"
      :to="{
        name: 'contacto',
        params: { idContact: contact.id },
        query: nameQuery,
      }"
    >
      <!-- query: { phone: Number.parseInt(contact.phones[0]) }, -->
      <span class="titleBlue">Nome</span>
      <p v-if="contact.name">{{ contact.name }}</p>
      <p v-else>Sem nome</p>
      <span class="titleBlue">Descrição</span>
      <p v-if="contact.description">{{ contact.description }}</p>
      <p v-else>Sem descrição</p>
      <div v-if="contact.phones.length > 0" class="tooltip">
        <p>{{ contact.phones[0] }}</p>
        <div
          class="phone"
          v-for="(phone, index) in contact.phones"
          :key="index"
        >
          <div v-if="index > 0" class="tooltiptext">
            <p v-if="index > 0">{{ phone }}</p>
          </div>
        </div>
      </div>

      <!-- <div v-for="(phone, index) in contact.phones" :key="index" class="phone">
        <div class="tooltip">
          <span class="titleBlue">Telefone</span>
          <div>
            <p v-if="index == 0">{{ phone }}</p>
          </div>
          <div v-if="index == 0" class="tooltiptext">
            <p v-if="index == 0">{{ phone }}</p>
          </div>
          <div v-if="index > 0" class="tooltip">
            <p v-if="index > 0">{{ phone }}</p>
          </div>
        </div>
      </div> -->
      <!-- <span class="titleBlue">Telefone</span>
      <p v-for="(phone, index) in contact.phones" :key="index" class="phone">
        <span v-if="index == 0">{{ phone }}</span>
        <span v-if="index > 0"> {{ phone }}</span>
      </p> -->
    </router-link>
    <slot></slot>
  </section>
</template>  

<script>
export default {
  name: "ContactItem",
  props: ["contact"],
  computed: {
    nameQuery() {
      // console.log(this.contact.phones.length === 1);
      let contactPhone =
        this.contact.phones.length === 1
          ? { phone: Number.parseInt(this.contact.phones[0]) }
          : {};
      return contactPhone;
    },
  },
};
</script>

<style scoped>
.phone {
  display: grid;
  grid-template-columns: minmax(300px, 400px) 200px 1fr;
}
.titleBlue {
  display: none;
}

.itemContainer {
  display: grid;
  grid-template-columns: 1fr 50px;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
  width: 100%;
}

.collabItens {
  display: grid;
  /* grid-template-columns: repeat(10, 130px); */
  /* grid-template-columns: 200px 150px 70px 90px repeat(2, 100px); */
  grid-template-columns: 300px 250px 250px;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

.collabItens:hover {
  background: #dbebf3;
}

.collabItens p {
  padding: 0 10px;
}

@media screen and (max-width: 690px) {
  .titleBlue {
    display: block;
    padding: 5px 0 0 10px;
  }
  .collabItens {
    grid-template-columns: 300px;
  }
  .itemContainer {
    width: 100%;
  }
}
</style>
