<template>
  <section class="">
    <h1>Viaturas</h1>
    <Inspections :idVehicle="idVehicle" :vehicle="vehicle" />
     <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalPutNextInspection"
        @click="closeModalPutNextInspection"
      ></div>
      <div class="modalDel_container" v-if="showModalPutNextInspection">
        <button class="close btn" @click="closeModalPutNextInspection">x</button>
        <p class="delItem">
          <span>Deseja atualizar a data da Próxima Inspeção Técnica Periódica?</span>
        </p>
        <div class="containerForm">
       
        <input
          id="date"
          type="date"
          v-model="nextInspectionDate"
          autocomplete="off"
          :class="[validateNextRevision, validateNextInspectionRequired]"
          @change="resetError"
        />
        <div v-if="!errors.indexOf('errorNextRevision')" class="notifyCenter">
          A data da Próxima Inspeção tem que ser maior que hoje
        </div>
        <div v-if="!errors.indexOf('nextInspRequired')" class="notifyCenter">
          A data da Próxima Inspeção é um campo requerido
        </div>
        
        </div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalPutNextInspection"
          />
          <button class="btn" @click.prevent="updateVehicle">
            Gravar
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import Inspections from "@/components/vehicle/Inspections.vue";
import { mapFields } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "DataService",
  props: ["idVehicle", "vehicle"],
  components: {
    Inspections,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "wait",
      "showModalDel",
      "selectedId",
    ]),
    ...mapState("user", ["user", "permissions"]),
    ...mapState("vehicle", ["showModalPutNextInspection"]),

    validateNextRevision() {
      return this.errors.indexOf("errorNextRevision") ? "true" : "validate";
    },
    validateNextInspectionRequired() {
      return this.errors.indexOf("nextInspRequired") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["nextInspectionDate"], //nome dos campos
      module: "vehicle", //qual módulo está acessando
      base: "vehicle", //colocar o state que vai receber
      mutation: "vehicle/UPDATE_VEHICLE", //nome da mutation que vai alterar o state
    }),
     ...mapGetters("vehicle", ["vehicleReply"]),
  },
  methods: {
    //######################################################
    //    ARQUIVOS MODAL DELETE
    //######################################################
    openModalPutNextInspection() {
      this.$store.dispatch("vehicle/openModalPutNextInspection");
    },
    closeModalPutNextInspection() {
      this.$store.dispatch("vehicle/closeModalPutNextInspection");
    },
     resetError() {
      this.$store.commit("RESET_ERRORS", []);
    },
    updateVehicle(){
      this.$store.commit("RESET_ERRORS", []);
      let today = new Date();
      let nextRevisionDate = new Date(this.vehicleReply.nextRevisionDate);
      // console.log(nextRevisionDate <= today);
      if (nextRevisionDate <= today) {
        this.$store.commit("UPDATE_ERRORS", "errorNextRevision", {
          root: true,
        });
      } else {
        this.$store.dispatch("vehicle/updateVehicle", {
          idVehicle: this.idVehicle,
          vehicle: this.vehicleReply,
        });
      }
    }
  },
  // created() {
  //   this.$store.dispatch("vehicle/getVehicle", {
  //     idVehicle: this.idVehicle,
  //   });
  // },

  
};
</script>

<style scoped>
.containerForm{  
  padding: 10px 0 30px 0;
  display: grid;
  justify-items: center;
      text-align: center;
    max-width: 250px;
}
input#date{
  width: 200px;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 250px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

/* .true {
  width: 30px;
  height: 30px;
  background: #28a745;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
}

.false {
  width: 30px;
  height: 30px;
  background: #cbcbcb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
} */

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
}
</style>
