var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{},[_c('h1',[_vm._v("Central Telefónica")]),_c('div',{staticClass:"containerContact"},[_c('div',{staticClass:"titleContainer"},[_c('h3',[_vm._v("Dados do Contacto")]),(_vm.telecomManager)?_c('div',[_c('router-link',{staticClass:"collabItens",attrs:{"to":{
            name: 'editar-contacto',
            params: {
              idTelecomPhone: _vm.idTelecomPhone,
            },
          }}},[_c('AddUser',[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Editar"),_c('br'),_vm._v(" Contacto")])])],1)],1):_vm._e()]),_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'historico-chamadas' }}},[_vm._v("Histórico de Chamadas")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'lista-contacto' }}},[_vm._v("lista de Contactos Internos")])],1),_vm._m(0)])]),(_vm.loadingTelecomPhone)?_c('div',[_c('Loading')],1):_c('div',[(_vm.telecomPhone)?_c('article',[_c('ul',[(_vm.telecomPhone.contactName)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Nome")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.telecomPhone.contactName))])])]):_vm._e(),(_vm.telecomPhone.phone)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Telefone")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.telecomPhone.phone))])])]):_vm._e(),(_vm.telecomPhone.description)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Descrição")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.telecomPhone.description))])])]):_vm._e(),(_vm.telecomPhone.created)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Criado")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.createdContact))])])]):_vm._e(),(_vm.telecomPhone.modified)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Modificado")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.modifiedContact))])])]):_vm._e()])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Dados do Contacto Interno")])])
}]

export { render, staticRenderFns }