<template>
  <section>
    <div class="titleContainer">
      <h3>Editar E-mail</h3>
    </div>

    <nav class="navBreadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'colaborador-ativo' }"
            >Lista Ativos</router-link
          >
        </li>
        <li class="breadcrumb-item">
          <router-link
            :to="{
              name: 'colaborador-info-ativo',
              params: { idCollaborator: idCollaborator },
            }"
            >Dados do Colaborador</router-link
          >
        </li>
        <li class="breadcrumb-item-active">
          <span> Editar E-mail</span>
        </li>
      </ol>
    </nav>
    <div class="itemFormCollaborator">
      <EmailForm :email="email" :errors="errors">
        <button class="btn" @click.prevent="updateEmail">Gravar</button>
      </EmailForm>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import EmailForm from "@/components/collaborator/EmailForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditEmail",
  props: ["idEmail", "idCollaborator"],
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("collaborator", ["email"]),
    ...mapGetters("collaborator", ["emailReply"]),
  },
  components: {
    EmailForm,
  },
  methods: {
    updateEmail() {
      this.$store.dispatch("collaborator/updateEmail", {
        idCollaborator: this.idCollaborator,
        idEmail: this.idEmail,
        email: this.emailReply,
      });
    },
  },
  created() {
    this.$store.dispatch("collaborator/getEmail", {
      idCollaborator: this.idCollaborator,
      idEmail: this.idEmail,
    });
  },
};
</script>

<style scoped>
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
.itemFormCollaborator {
  margin-left: 10px;
}
</style>