<template>
  <section class="">
    <router-link
      class="collabItens itemList"
      :to="{
        name: 'tipo-de-taxa',
        params: { idProcessTypeTax: processTypeTax.id },
      }"
    >
      <article class="containerLinkForm">
        <div>{{ processTypeTax.name }}</div>
        <div>
          <span class="itemAddress">Descrição: &nbsp; </span>
          {{ processTypeTax.description }}
        </div>
      </article>
      <div class="delete">
        <slot name="delete" />
      </div>
    </router-link>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProcessTypeTaxItemList",
  props: ["processTypeTax"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["processEdit"]),
  },
};
</script>

<style scoped>
.itemList {
  align-items: center;
}
.containerLinkForm {
  display: grid;
  grid-template-columns: 310px 240px 150px;
  padding: 5px 10px;
}
.delete {
  margin-right: 10px;
}
.itemAddress {
  display: none;
}
@media screen and (max-width: 690px) {
  .containerLinkForm {
    grid-template-columns: 1fr;
    padding: 5px 10px;
  }
  .itemAddress {
    display: block;
  }
}
</style>