<template>
  <section class="itemContainer">
    <router-link
      class="collabItens"
      :to="{
        name: 'contacto-interno-info',
        params: { idTelecomPhone: telecomPhone.id },
      }"
    >
      <div class="collabItens">
        <div class="collabIten">
          <span class="titleBlue">Nome</span>
          <p>{{ telecomPhone.contactName }}</p>
          <span class="titleBlue">Telefone</span>
          <p>{{ telecomPhone.phone }}</p>
        </div>
        <div class="collabConfig">
          <!-- <router-link
            v-if="telecomManager"
            class="edit"
            :to="{
              name: 'editar-contacto',
              params: {
                idPhone: telecomPhone.id,
              },
            }"
          >
          </router-link> -->
          <slot v-if="telecomManager" name="delete" />
        </div>
      </div>
    </router-link>
  </section>
</template>  

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "TelecomPhoneItem",
  props: ["telecomPhone", "idTelecomPhone"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["telecomManager"]),
  },
};
</script>

<style scoped>
.itemContainer {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
  width: 100%;
}

.collabItens {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  grid-template-columns: 1fr 90px;
}

.collabIten {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  grid-template-columns: minmax(200px, 380px) 1fr;
}

.collabConfig {
  display: grid;
  grid-template-columns: 40px 50px;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

.collabItens:hover {
  background: #dbebf3;
}

.collabItens p {
  padding: 0 10px;
}
.titleBlue {
  display: none;
}

@media screen and (max-width: 690px) {
  .collabItens {
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* height: 30px; */
  }
  .collabIten {
    grid-template-columns: 260px;
  }
  .textGray {
    padding: 0 0 0 20px;
  }

  .titleBlue {
    display: block;
    padding: 5px 0 0 10px;
  }
  .itemContainer {
    width: 100%;
  }
}
</style>
