var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"itemsCollab"},[_c('h1',[_vm._v("Clientes")]),_c('div',{staticClass:"containerContact"},[_c('div',{staticClass:"titleContainer"},[_c('h3',[_vm._v("Lista de Clientes")]),(_vm.clientAccess || _vm.clientEdit)?_c('div',{staticClass:"menuDropdown"},[_c('BtnConfig',[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Controlo "),_c('br'),_vm._v(" de Clientes")]),_c('ul',{staticClass:"menuDropdownContent",attrs:{"slot":"itemsMenu"},slot:"itemsMenu"},[_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'tipos-clientes',
                }}},[_vm._v(" Tipo de Cliente ")])],1),(_vm.clientEdit)?_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'criar-cliente',
                }}},[_vm._v("Adicionar")])],1):_vm._e()])])],1):_vm._e()]),(_vm.loadingClient)?_c('div',[_c('Loading')],1):_c('div',[(_vm.clients && _vm.clients.length > 0)?_c('div',[_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_vm._t("default"),_vm._m(0)],2)]),_c('ClientSearch'),_vm._m(1),_c('hr'),_vm._l((_vm.paginatedData),function(client){return _c('ul',{key:client.id},[_c('li',[_c('ClientItem',{attrs:{"client":client}},[(_vm.clientEdit)?_c('button',{staticClass:"delete",attrs:{"slot":"delete"},on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteClient(client.id)}},slot:"delete"}):_vm._e()])],1)])}),_c('Pagination',{attrs:{"data":_vm.filteredClients,"totalPages":Math.ceil(_vm.filteredClients.length / _vm.limit),"total":_vm.filteredClients.length,"currentPage":_vm.currentPage},on:{"pagechanged":_vm.onPageChange}})],2):_c('div',[_c('p',{staticClass:"noResults"},[_vm._v("Não possui clientes cadastrados")])])])]),_c('NotifySuccess',{attrs:{"items":_vm.items}}),_c('div',{staticClass:"modalDel"},[(_vm.showModalClient)?_c('div',{staticClass:"overlay",on:{"click":_vm.closeModalDelClient}}):_vm._e(),(_vm.showModalClient)?_c('div',{staticClass:"modalDel_container"},[_c('button',{staticClass:"close btn",on:{"click":_vm.closeModalDelClient}},[_vm._v("x")]),_vm._m(2),_c('div',{staticClass:"containerDel"}),_c('div',{staticClass:"buttonsDel"},[_c('input',{staticClass:"btn",attrs:{"type":"submit","value":"Cancel"},on:{"click":function($event){$event.preventDefault();return _vm.closeModalDelClient.apply(null, arguments)}}}),_c('button',{staticClass:"btnDel",on:{"click":function($event){$event.preventDefault();return _vm.deleteClient.apply(null, arguments)}}},[_vm._v("Delete")])])]):_vm._e()]),_c('NotifyError',{attrs:{"itemsError":_vm.itemsError}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Lista de clientes")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleService"},[_c('p',{staticClass:"titleBlue"},[_vm._v("Nome")]),_c('p',{staticClass:"titleBlue"},[_vm._v("Nif")]),_c('p',{staticClass:"titleBlue"},[_vm._v("Tipo de Cliente")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"delItem"},[_c('span',[_vm._v("Você tem certeza que deseja remover este cliente?")])])
}]

export { render, staticRenderFns }