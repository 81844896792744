<template>
  <section>
    <h1>Viaturas</h1>

    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Adicionar Tipo de Viatura</h3>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'tipos-viaturas' }"
              >Lista de Tipos de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Adicionar Tipo de Viatura</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingVehicle">
        <Loading />
      </div>
      <div v-else class="containerForm">
        <TypeVehicleForm :errors="errors">
          <button class="btn" @click.prevent="addTypeVehicle">Adicionar</button>
        </TypeVehicleForm>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import TypeVehicleForm from "@/components/vehicle/TypeVehicleForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddVehicle",
  props: ["idTypeVehicle"],
  components: {
    TypeVehicleForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("vehicle", [
      "typeVehicle",
      "loadingVehicle",
      "showModalDeleteTypeVehicle",
    ]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),
    ...mapGetters("vehicle", ["typeVehicleReset", "typeVehicleReply"]),
  },
  methods: {
    addTypeVehicle() {
      this.$store.dispatch("vehicle/addTypeVehicle", {
        typeVehicle: this.typeVehicleReply,
      });
    },
  },
  created() {
    this.$store.commit("vehicle/UPDATE_TYPEVEHICLE", this.typeVehicleReset);
    this.$store.commit("RESET_ERRORS", []);
  },
};
</script>

<style scoped>
.breadcrumb {
  margin-left: -10px;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

@media screen and (max-width: 690px) {
  .containerTelecom {
    grid-template-columns: 1fr;
    justify-items: flex-start;
    grid-gap: 10px;
    margin: 30px 10px;
  }
  .containerForm {
    width: 100%;
  }
}
</style>