import { render, staticRenderFns } from "./NotifyDelete.vue?vue&type=template&id=ba0ac3ac&scoped=true&"
import script from "./NotifyDelete.vue?vue&type=script&lang=js&"
export * from "./NotifyDelete.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ba0ac3ac",
  null
  
)

export default component.exports