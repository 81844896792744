<template>
  <form action="">
    <div class="formInfos">
      <div v-if="loadingTelecomPhone">
        <p><Loading /></p>
      </div>
      <div class="formInfos" v-else>
        <label for="contactName"> Nome: </label>
        <input
          id="contactName"
          name="contactName"
          type="text"
          v-model="contactName"
          autocomplete="off"
          :class="[validateFull, validateContactName]"
        />
        <div v-if="!errors.indexOf('contactName')" class="notifyCenter">
          O Nome é um campo requerido.
        </div>
        <label for="description"> Description: </label>
        <input
          id="description"
          name="description"
          type="text"
          v-model="description"
          autocomplete="off"
          :class="[validateFull]"
        />
        <label for="phone"> Telefone: </label>
        <input
          id="phone"
          name="phone"
          type="text"
          v-model.trim="phone"
          autocomplete="off"
          :class="[validateFull, validatePhone, validatePhoneInvalid]"
        />
        <div v-if="!errors.indexOf('full')" class="notifyCenter">
          Os campos Nome e Telefone são requeridos.
        </div>
        <div v-if="!errors.indexOf('phoneContact')" class="notifyCenter">
          O Telefone é um campo requerido.
        </div>
        <div v-if="!errors.indexOf('phoneInvalid')" class="notifyCenter">
          O Telefone precisa ser preenchido corretamente.
        </div>

        <div class="button">
          <slot></slot>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "TelecomPhoneForm",
  props: ["telecomPhone", "errors"],
  computed: {
    validateFull() {
      return this.errors.indexOf("full") ? "true" : "validate";
    },
    validateContactName() {
      return this.errors.indexOf("contactName") ? "true" : "validate";
    },
    validatePhone() {
      return this.errors.indexOf("phoneContact") ? "true" : "validate";
    },
    validatePhoneInvalid() {
      return this.errors.indexOf("phoneInvalid") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["contactName", "description", "phone"], //nome dos campos
      module: "telecom", //qual módulo está acessando
      base: "telecomPhone", //colocar o state que vai receber
      mutation: "telecom/UPDATE_TELECOMPHONE", //nome da mutation que vai alterar o state
    }),
    ...mapState(["loadingTelecomPhone"]),
  },
};
</script>

<style scoped>
.formInfos {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-top: 20px; */
  align-content: center;
  max-width: 500px;
}
input.validate {
  margin: 0;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
@media screen and (max-width: 690px) {
  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: auto;
    width: 100%;
  }
  label,
  input {
    width: 100%;
    justify-items: flex-start;
  }
}
</style>