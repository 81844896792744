<template>
  <div>
    <h1>Jurídico</h1>
    <div class="contentGeneral">
      <div class="titleContainer">
        <h3>Editar Posição</h3>
      </div>

      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos', query: { archived: 'false'} }"  >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'lista-posicoes' }">Lista de Posições</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'posicao',
                params: { idProcessPosition: idProcessPosition },
              }"
              >Dados da Posição</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Editar Tribunal</span>
          </li>
        </ol>
      </nav>
      <div class="itemFormCollaborator">
        <ProcessPositionForm
          :processPosition="processPosition"
          :errors="errors"
        >
          <div slot="cancel"></div>
          <div slot="enviar" class="btnJustify">
            <button class="btn" @click.prevent="updateProcessPosition">
              Gravar
            </button>
          </div>
        </ProcessPositionForm>
      </div>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </div>
</template>

<script>
import ProcessPositionForm from "@/components/legal/ProcessPositionForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditProcessPosition",
  props: ["idProcessPosition", "idRouter"],
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("legal", ["processPosition"]),
    ...mapGetters("legal", ["processPositionReply", "processPositionReset"]),
  },
  components: {
    ProcessPositionForm,
  },
  methods: {
    updateProcessPosition() {
      this.$store.dispatch("legal/updateProcessPosition", {
        idProcessPosition: this.idProcessPosition,
        processPosition: this.processPositionReply,
      });
    },
  },
  created() {
    this.$store.commit(
      "legal/UPDATE_MODAL_PROCESS_POSITION",
      this.processPositionReset
    );
    this.$store.dispatch("legal/getProcessPosition", {
      idProcessPosition: this.idProcessPosition,
    });
    this.$store.commit("RESET_ERRORS", []);
  },
};
</script>

<style scoped>
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.btnJustify {
  display: grid;
  justify-content: flex-end;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
.itemFormCollaborator {
  margin-left: 10px;
}
</style>