<template>
  <section class="">
    <ProcessType
      :idProcessType="idProcessType"
      :idRouter="idRouter"
      :router="router"
    />
  </section>
</template>

<script>
import ProcessType from "@/components/legal/ProcessType.vue";

import { mapState } from "vuex";

export default {
  name: "DataClient",
  props: ["idProcessType", "idRouter", "router"],
  components: {
    ProcessType,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "wait",
      "showModalDel",
      "selectedId",
    ]),
    ...mapState("user", ["user", "permissions"]),
  },
};
</script>

<style scoped>
hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 250px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

.true {
  width: 30px;
  height: 30px;
  background: #28a745;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
}

.false {
  width: 30px;
  height: 30px;
  background: #cbcbcb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
}

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
}
</style>
