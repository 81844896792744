<template>
  <form action="">
    <div class="container-topSearch">
            <!-- {{router}} -->
        <div :class="router">
            <div>
          <label for="name"> Nome: </label>
          <input
            id="name"
            name="name"
            type="text"
            v-model="name"
            autocomplete="off"
            @keyup.enter="searchContact"
          />
            <!-- inputmode="decimal" -->
        </div>
        <div>
          <label for="phone"> Telefone: </label>
          <input
            id="phone"
            name="phone"
            type="text"
            v-model="phone"
            autocomplete="off"
            @keyup.enter="searchContact"
          />
            <!-- inputmode="decimal" -->
        </div>
        <div>
          <label for="mail"> E-mail: </label>
          <input
            id="mail"
            name="mail"
            type="text"
            v-model="mail"
            autocomplete="off"
            @keyup.enter="searchContact"
          />
            <!-- inputmode="decimal" -->
        </div>
        <div>
          <label for="description"> Descrição: </label>
          <input
            id="description"
            name="description"
            type="text"
            v-model.trim="description"
            autocomplete="off"
            @keyup.enter="searchContact"
          />
        </div>
        <div class="buttonSearch">
            <div class="button" @keyup.enter="searchContact" @click.prevent="searchContact">
                <BtnSearch />
            </div>
            <div class="button btnReset" @click.prevent="reset">
                <BtnReset />
            </div>
        </div>
        </div>
    </div>
    <!-- {{filterItem}} -->
    <ul class="searchActiveContainer" v-if="filterItem">
        <div class="filterActive" v-if="filterItem">Filtro activo:</div>
        <article v-if="nameReply" class="searchActive">
          <div>Nome</div>
          <div class="closeSearch" @click.prevent="removeName">
            x
          </div>
        </article>
        <article v-if="phoneReply" class="searchActive">
          <div>Telefone</div>
          <div class="closeSearch" @click.prevent="removePhone">x</div>
        </article>
        <article v-if="mailReply" class="searchActive">
          <div>E-mail</div>
          <div class="closeSearch" @click.prevent="removeMail">x</div>
        </article>
        <article v-if="descriptionReply" class="searchActive">
          <div>Descrição</div>
          <div class="closeSearch" @click.prevent="removeDescription">
            x
          </div>
        </article>        
      </ul>
  </form>
</template>

<script>
import { mapFields } from "@/helpers.js";
import { mapGetters, mapState } from "vuex";

export default {
  props: ["modal"],
  computed: {
    ...mapState(["filterItem"]),
    ...mapState("contact", ["page", "name", "phone", "mail", "description"]),
    // name: {
    //   get() {
    //     return this.$store.state.contact.name;
    //   },
    //   set(value) {
    //     this.$store.commit("contact/UPDATE_name", value);
    //   },
    // },
    ...mapFields({
      fields: ["name"], //nome dos campos
      module: "contact", //qual módulo está acessando
      base: "name", //colocar o state que vai receber
      mutation: "contact/UPDATE_SEARCH_NAME", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["phone"], //nome dos campos
      module: "contact", //qual módulo está acessando
      base: "phone", //colocar o state que vai receber
      mutation: "contact/UPDATE_SEARCH_PHONE", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["mail"], //nome dos campos
      module: "contact", //qual módulo está acessando
      base: "mail", //colocar o state que vai receber
      mutation: "contact/UPDATE_SEARCH_MAIL", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["description"], //nome dos campos
      module: "contact", //qual módulo está acessando
      base: "description", //colocar o state que vai receber
      mutation: "contact/UPDATE_SEARCH_DESCRIPTION", //nome da mutation que vai alterar o state
    }),

    ...mapGetters("contact", [
      "nameReply",
      "nameReset",
      "phoneReply",
      "phoneReset",
      "mailReply",
      "mailReset",      
      "descriptionReply",
      "descriptionReset",
    ]),
    router(){
        return this.$router.currentRoute.name == "contactos" ? "formInfos" : "formInfosModal"
    }
    
  },
  methods: {
    msgFilter() {
      return this.$store.commit("UPDATE_FILTER_ITEM",this.nameReply != null ||
        this.phoneReply != null ||
        this.mailReply != null ||
        this.descriptionReply )
    },
    removeName() {
      this.$store.commit("contact/UPDATE_SEARCH_NAME", this.nameReset);
      // this.$router.push({ query: {} });
      this.searchContact();
    },
    removePhone() {
      this.$store.commit("contact/UPDATE_SEARCH_PHONE", this.phoneReset);
      // this.$router.push({ query: {} });
      this.searchContact();
    },
    removeMail() {
      this.$store.commit("contact/UPDATE_SEARCH_MAIL", this.mailReset);
      // this.$router.push({ query: {} });
      this.searchContact();
    },
    removeDescription() {
      this.$store.commit("contact/UPDATE_SEARCH_DESCRIPTION", this.descriptionReset);
      // this.$router.push({ query: {} });
      this.searchContact();
    },
    resetInputs() {
      this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
      this.$store.commit("contact/UPDATE_SEARCH_NAME", this.nameReset);
      this.$store.commit("contact/UPDATE_SEARCH_PHONE", this.phoneReset);  
      this.$store.commit("contact/UPDATE_SEARCH_MAIL", this.mailReset);    
      this.$store.commit("contact/UPDATE_SEARCH_DESCRIPTION", this.descriptionReset);     
      this.searchContact();
    },
    updateQuert() {
      const filter = {
        name: this.name,
        phone: this.phone,
        mail: this.mail,
        description: this.description,
      };
      const query = Object.entries(filter).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      this.$router.replace({ query: { ...query } }).catch(() => {});
    },

    reset() {
      this.resetInputs();
      this.msgFilter()
      this.updateQuert()
      if (Object.entries(this.$router.currentRoute.query).length != 0) {
        this.$router.push({ query: {} });
    }
    this.searchContact()
    },
    
    searchContact() {
        this.msgFilter()
        this.updateQuert();
        // console.log("searchContact")
        this.$store.dispatch("contact/getContacts", {            
            page: this.$route.query.page || this.page.page,
            name: this.$route.query.name || this.name,
            phone:this.$route.query.phone || this.phone,
            mail:this.$route.query.mail || this.mail,
            description:
            this.$route.query.description || this.description,
        });
        
    },
  },
  created(){
    console.log(this.modal)
    if(this.modal == true){
        this.resetInputs();
      this.msgFilter()
      this.updateQuert()
      if (Object.entries(this.$router.currentRoute.query).length != 0) {
        this.$router.push({ query: {} });
    }
    }
  }
 
};
</script>

<style scoped>
.container-topSearch {
  display: grid;
  grid-template-columns: minmax(300px, 400px) repeat(1, 200px);
  grid-gap: 10px;
  width: 100%;  
  justify-content: flex-start;
}

.formInfos {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: flex-start;
  /* justify-items: center; */
  /* justify-self: center; */
  grid-gap: 10px;
  align-items: flex-end;
  margin: 30px 0 10px 0;
  align-content: center;
  max-width: 500px;
  padding: 0 10px 40px 0;
}

.formInfosModal {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  justify-content: flex-start;
  /* justify-items: center; */
  grid-gap: 10px;
  align-items: flex-end;
  margin: 10px 0 5px 0;
  align-content: center;
  max-width: 500px;
  padding: 0 0px 15px 0;
}

.buttonSearch {
  display: flex;
}

#search {
  width: 100%;
}
.searchContainer {
  position: relative;
  width: 100%;
}
#lupa {
  width: 62px;
  height: 46px;
  background: url("../../assets/svg/search2.svg") no-repeat center center;
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;
}
/* MSG Fitlro */

.searchActiveContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0px 0 30px 0;
  grid-gap: 10px;
  font-size: 0.8rem;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #373946;
  padding: 10px;
  border-radius: 4px;
  /* box-shadow: 0 4px 8px rgb(31 43 66 / 20%); */
  border-radius: 4px;
  border: 1px solid rgba(31, 43, 66, 0.4);
  /* justify-content: center; */
  justify-content: flex-start;
  max-width: 1000px;
}
.filterActive {
  font-weight: bold;
}

li.itemSearchActive {
  /* border-left: 1px solid #ccc; */
  justify-content: center;
}
li + li.itemSearchActive::before {
  content: "|";
  color: #6c6c6d;
  padding: 0 15px 0 0;
  /* border-left: 1px solid #ccc; */
  justify-content: center;
}
.searchActive {
  display: grid;
  grid-template-columns: minmax(60px, auto) 25px;
  grid-gap: 5px;
  justify-content: center;
  /* box-shadow: 0 4px 8px rgb(31 43 66 / 20%); */
  /* max-width: 320px; */
  border-radius: 4px;
  border: 1px solid rgba(31, 43, 66, 0.4);
  padding: 10px;
  transition: all 0.3s;
  outline: none;
}

.closeSearch {
  padding: 0 10px;
  /* padding: 0 10px; */
  border-left: 1px solid #ccc;
  cursor: pointer;
  font-weight: bold;
}

input {
  height: 50px;
}
.button {
  display: flex;
  justify-content: flex-end;
}

.buttonSearch {
  display: grid;
  grid-template-columns: repeat(2, 60px);
}

@media screen and (max-width: 560px) {
    .container-topSearch{
        grid-template-columns: 1fr;
        justify-items: flex-end;
        width: 100%;
    }
    .formInfosModal,
    .formInfos {
        grid-template-columns: 1fr;
        justify-content: flex-start;
        justify-items: self-end;
    }
    
  .topSearch {
    position: relative;
  }
  .formInfos {
    grid-template-columns: 1fr;
    /* display: flex;
    flex-wrap: wrap; */
    justify-items: center;
    margin: 10px 10px 50px 10px;
    width: 100%;
  }
  .formInfos div {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: flex-start;
  }
  input {
    min-width: 250px;
    width: 100%;
  }
  .formInfos div.buttonSearch {
    display: flex;
    justify-content: flex-end;
    width: 255px;
  }
  .buttonSearch .button {
    width: 55px;
    justify-items: flex-end;
  }
}


</style>