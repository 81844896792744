<template>
  <section class="">
    <h1>Moradas</h1>
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Dados da Morada</h3>
        <div class="menuDropdown" v-if="addressEdit">
          <BtnConfig>
            <span slot="title">
              Controlo da <br />
              Morada</span
            >
            <ul slot="itemsMenu" class="menuDropdownContent">
              <li>
                <router-link
                  class="collabItens"
                  :to="{
                    name: 'morada-editar',
                    params: { idAddress: idAddress },
                  }"
                >
                  Editar Morada
                </router-link>
              </li>
              <li @click.prevent="confirmDeleteAddress(address.id)">
                Apagar Morada
              </li>
            </ul>
          </BtnConfig>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <slot />
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'moradas' }"
              >Lista de moradas</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Dados da Morada</span>
          </li>
        </ol>
      </nav>

      <div v-if="loadingAddress">
        <Loading />
      </div>
      <div v-else>
        <article v-if="address">
          <!-- <pre>
             {{ address }}
          </pre> -->
          
          <ul>
            <li class="items itemList" v-if="address.street">
              <span class="titleBlue">Morada</span>
              <div class="list">
                <span class="textGray">{{ address.street }}<span v-if="address.streetNumber">, {{ address.streetNumber }}</span></span>
              </div>
            </li>
            <li class="items itemList" v-if="address.city">
              <span class="titleBlue">Localidade</span>
              <div class="list">
                <span class="textGray">{{ address.city }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="address.state">
              <span class="titleBlue">Distrito</span>
              <div class="list">
                <span class="textGray">{{ address.state }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="address.country">
              <span class="titleBlue">País</span>
              <div class="list">
                <span class="textGray">{{ address.country }}</span>
              </div>
            </li>          
            <li class="items itemList" v-if="address.complement">
              <span class="titleBlue">Complemento</span>
              <div class="list">
                <span class="textGray">{{ address.complement }}</span>
              </div>
            </li>
              <li class="items itemList" v-if="address.reference">
              <span class="titleBlue">Referência</span>
              <div class="list">
                <span class="textGray">{{ address.reference }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="address.alias">
              <span class="titleBlue">Notas</span>
              <div class="list">
                <span class="textGray">{{ address.alias }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="address.created">
              <span class="titleBlue">Criado</span>
              <div class="list">
                <span class="textGray">{{
                  address.created | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ createdaddress }}</span> -->
              </div>
            </li>
            <li class="items itemList" v-if="address.modified">
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <span class="textGray">{{
                  address.modified | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ modifiedContact }}</span> -->
              </div>
            </li>
          </ul>
          <!-- <div id="map-canvas"></div> -->
          <div v-if="iframe" class="iframe">
            <iframe
              width="100%"
              height="300px"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              :src="iframe"
            >
            </iframe>
          </div>
        </article>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelAddress"
        @click="closeModalDelAddress"
      ></div>
      <div class="modalDel_container" v-if="showModalDelAddress">
        <button class="close btn" @click="closeModalDelAddress">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover esta morada?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelAddress"
          />
          <button class="btnDel" @click.prevent="deleteAddress">Delete</button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ContactInfos",
  props: ["idAddress"],
  data() {
    return {
      searchHistory: false,
      
      
    };
  },

  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "wait",
      "selectedId",
      "loadingEmail",
      "loadingPhone",
      "loadingCalls",
      "limit",
      "currentPage",
    ]),
    ...mapState("address", [
      "address",
      "loadingAddress",
      "showModalDelAddress",
    ]),
    ...mapState("user", ["user", "permissions"]),
    ...mapGetters("address", ["addressReset"]),
    ...mapGetters("user", ["addressAccess", "addressEdit"]),
    iframe() {
      let url = null;
      if (this.address.location != null) {
        url = `https://maps.google.com/maps?q=${this.address.location.lat},${this.address.location.lng}&z=17&output=embed`;
      }
      return url;
    },
  },
  methods: {
    //######################################################
    //     DELETE
    //######################################################
    openModalDelAddress() {
      this.$store.dispatch("address/openModalDelAddress");
    },
    closeModalDelAddress() {
      this.$store.dispatch("address/closeModalDelAddress");
    },
    confirmDeleteAddress(id) {
      // console.log(id);
      this.$store.dispatch("address/confirmDeleteAddress", {
        id: `${id}`,
      });
    },
    deleteAddress() {
      // console.log("selectedId", this.selectedId.id);
      this.$store.dispatch("address/deleteAddress", {
        id: this.selectedId.id,
        item: "ïtem",
      });
      this.closeModalDelAddress();
    },
    
  },
 
  created() {
    this.$store.dispatch("address/getAddress", {
      idAddress: this.idAddress,
    });
  },
};
</script>

<style scoped>
.iframe {
  margin-top: 30px;
}
.callsHistory {
  margin-top: 30px;
}
.noContact,
.iconInfoSearchHistory {
  margin: 0 10px;
}
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 180px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
}
</style>
