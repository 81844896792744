<template>
  <section class="containerLinkForm">
    <div v-if="loadingTelecomPhone">
      <p><LoadingCircle /></p>
    </div>
    <div v-else>
      {{ redirectName }}
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "VariantRedirectItem",
  props: ["idNameRedirect", "index"],
  // components: { NameRedirect },
  computed: {
    ...mapState(["loadingTelecomPhone"]),
    ...mapState("user", ["user"]),
    ...mapState("telecom", ["telecomPhones"]),
    ...mapGetters("user", ["staffEdit"]),

    redirectName() {
      return this.telecomPhones.find((telecomPhone) => {
        return telecomPhone.id == this.idNameRedirect;
      }).contactName;
    },
  },
};
</script>

<style scoped>
</style>