<template>
  <section class="itemContainer" v-if="processOpposite">
    <div>
      <article class="containerLink">
        <div
          v-if="oppositeHistory != index"
          class="accordion"
          @click="activeOpposite(index)"
        >
          <li class="itemsProcess">
            <div class="itemsAcc">
              <div class="list">
                <span>{{ processOpposite.name }}</span>
              </div>
              <div class="list">
                <span class="mobileItem">Nif: &nbsp; </span>
                {{ processOpposite.nif }}
                <!-- <span>{{ processOpposite.nif }}</span> -->
              </div>
            </div>
            <!-- {{ process }} -->
          </li>
          <div class="open"></div>
        </div>
        <div v-else class="accordion" @click="removeOpposite()">
          <li class="itemsProcess">
            <div class="itemsAcc">
              <div class="list">
                <span>{{ processOpposite.name }}</span>
              </div>
              <div class="list">
                <span class="mobileItem">Nif: &nbsp; </span>
                {{ processOpposite.nif }}
              </div>
            </div>
          </li>
          <div class="close"></div>
        </div>
      </article>
      <article class="revisionBtns" v-if="oppositeHistory === index"></article>
      <div class="textGray" v-if="oppositeHistory === index">
    <div v-if="processOpposite.contacts && processOpposite.contacts.length > 0">
        <div v-for="contact in processOpposite.contacts" :key="contact.id">

         <div class="contactItens">           
          <div class="contactItem">
            <div>
                <!-- Telefone: -->
            </div>
            <div v-if="contact.phones.length > 0" >
              <div
                class="list"
                v-for="(phone, index) in contact.phones" :key="index"
              >
                <span>{{ phone }}</span>
                <span v-if="index + 1 < contact.phones.length">, </span>
              </div>
            </div>
          </div>
          <div v-if="contact.emails.length > 0" class="list">
            <div class="contactItem">
              <div>
                  <!-- Email: -->
              </div>
              <div            
              v-for="(email, index) in contact.emails"
              :key="index"
            >
              <span>{{ email }}</span>
              <span v-if="index + 1 < contact.emails.length">, </span>
            </div>
            </div>
          </div>
         </div>

         
        </div>
        <hr/>
    </div>
        <div v-if="processList && processList.length > 0">
          <!-- {{list}} -->
          <!-- {{list.name == nameOpposite ? process : null}} -->
          <div class="titleLegal">
            <!-- <p class="titleBlue">Tribunal</p> -->
            <p class="titleBlue">Tipo do Processo</p>
            <p class="titleBlue">Posição</p>
            <p class="titleBlue">Nº Processo</p>
            <p class="titleBlue">Valor</p>
            <!-- <p class="titleBlue">Taxa</p> -->
            <!-- <p class="titleBlue">Data Início</p> -->
          </div>
          <div v-for="list in processList" :key="list.id">
            <OppositeProcessItem
              :processTypes="processTypes"
              :processCourts="processCourts"
              :processPositions="processPositions"
              :process="list"
              :idOpposite="processOpposite.id"
              :index="index"
            >
            </OppositeProcessItem>
          </div>
        </div>
        <div v-else>
          <p class="noResults">Não possui processos registrados</p>
        </div>
      </div>
    </div>
  </section>
</template>  

<script>
import OppositeProcessItem from "@/components/legal/OppositeProcessItem.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "processOppositeItem",
  props: [
    "idOpposite",
    "nameOpposite",
    "processOpposite",
    "index",
    "process",
    "processes",
    "processTypes",
    "processCourts",
    "processPositions",
  ],
  components: {
    OppositeProcessItem,
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("legal", ["oppositeHistory"]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalyse",
      "processManager",
    ]),

    processList() {
      let newProcess = [];
      for (let process in this.processes) {
        if (
          this.processes[process].oppositeList != null ||
          this.processes[process].length > 0
        ) {
          let item = this.processes[process].oppositeList.find((opposite) => {
            // console.log("result", opposite.name == this.nameOpposite);
            return opposite.name == this.nameOpposite &&  this.processes[process].archived == false;
          });
          if (item != undefined || item != null)
            newProcess.push(this.processes[process]);
        }
      }

      return newProcess;
    },
  },
  methods: {
    activeOpposite(index) {
      this.$store.commit("legal/UPDATE_OPPOSITEHISTORY", index);
    },
    removeOpposite() {
      this.$store.commit("legal/UPDATE_OPPOSITEHISTORY", null);
    },
  },
};
</script>

<style scoped>
hr{
  max-width: 1120px;
  margin: 25px auto;
}
.contactItem{
  display: grid;
  flex-wrap: wrap;
  grid-gap: 5px;
}
.contactItens{
  display: grid;
  grid-template-columns: 1fr 304px;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 0 10px;
  /* grid-gap: 100px; */
  margin-bottom: 10px;
}
.list {
  display: flex;
}
.mobileItem {
  display: none;
}
.noResults {
  padding: 10px;
}
.itemSearch {
  display: grid;
  justify-content: flex-end;
  padding: 20px;
}
.titleLegal {
  margin: 40px 0;
  display: grid;
  grid-template-columns: 309px 120px 150px 140px 40px;
  /* grid-template-columns: 1fr 120px 140px 209px repeat(2, 150px) 40px; */
}
/* .legalItems {
  display: grid;
  grid-template-columns: 1fr 100px repeat(2, 70px) 190px repeat(2, 150px);
} */
li.itemsProcess {
  display: grid;
  grid-template-columns: 1fr 50px;
  grid-gap: 15px;
  width: 100%;
  align-items: flex-start;
}
.itemsAcc {
  display: grid;
  grid-template-columns: 1fr 192px;
  grid-gap: 15px;
  width: 100%;
}
.accDelete {
  margin-top: 10px;
}
.accordion {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-content: center;
}
.close::after {
  content: "❯";
  /* color: #858585; */
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  padding: 0 20px;
}
.close {
  transition: all 0.35s;
  transform: rotate(-89deg);
}
.open::after {
  content: "❯";
  width: 1em;
  /* color: #858585; */
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  padding: 0 20px;
}
.open {
  transition: all 0.35s;
  transform: rotate(89deg);
}
.containerLink {
  /* background: #f0f2f7; */
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* box-shadow: 0 4px 8px rgb(30 60 90 / 20%); */
  /* border-bottom: 1px solid #cbcbcb; */
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  align-content: center;
  padding: 10px;
  /* margin: 0 0 10px 0; */
  color: #1f2b42;
  cursor: pointer;
  font-size: 18px;
  line-height: 28px;
}
.revisionBtns {
  display: grid;
  /* grid-template-columns: repeat(2, 40px); */
  justify-content: flex-end;
  justify-items: flex-end;
  grid-gap: 5px;
  margin-right: 10px;
  margin-top: 10px;
}
.articleInfos,
.callsHistory {
  margin-top: 30px;
}
.titleInfos {
  margin-bottom: 10px;
}

.iconInfoSearchHistory {
  margin: 0 10px;
}
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}
.revision {
  margin-left: 40px;
}
.iconNull {
  fill: #88898f;
  margin-left: 35px;
  /* text-align: center; */
}
.telegramUsers {
  color: #1f2b42;
  font-size: 14px;
  line-height: 18px;
}

.itemContainer {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  align-items: flex-start;
  font-size: 0.8rem;
  grid-gap: 10px;
  padding: 5px 0;
  width: 100%;
}

.collabItens {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  /* grid-template-columns: 1fr 90px; */
}

.collabIten {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  grid-template-columns: 1fr 100px repeat(2, 70px) 190px repeat(2, 150px);
  justify-content: flex-start;
  align-items: center;
  grid-gap: 3px;
  padding: 15px 0;
}

.collabConfig {
  display: grid;
  grid-template-columns: 50px;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

.collabItens:hover {
  background: #dbebf3;
}

.collabItens p {
  padding: 0 10px;
}

@media screen and (max-width: 695px) {
  .mobileItem {
    display: block;
  }
  .titleLegal {
    display: none;
  }
  li.itemsProcess {
    grid-gap: 0;
  }
  .itemsAcc {
    grid-template-columns: 250px;
    padding: 0 10px;
  }
  .collabItens {
    display: grid;
    grid-template-columns: 1fr;
    padding: 0 10px;
    /* justify-items: flex-end; */
  }
  .collabItens p {
    padding: 0px;
  }
  .collabIten {
    grid-template-columns: 260px;
  }
  .titleBlue {
    display: block;
    /* padding: 5px 0 0 10px; */
  }
  .itemContainer {
    width: 100%;
  }
  .accordion {
    align-items: flex-start;
  }
}
</style>
