<template>
  <section class="itemsCollab">
    <h1>Contactos</h1>
    <div class="containerContact">
      <div v-if="loadingContact">
        <Loading />
      </div>
      <div v-else>
        <div v-if="contacts">
          <div class="titleContainer">
            <h3>Lista de Contactos</h3>
            <div v-if="contactEdit">
              <router-link
                class="collabItens"
                :to="{
                  name: 'criar-contacto',
                }"
              >
                <AddUser>
                  <span slot="title">
                    Adicionar<br />
                    Contacto</span
                  >
                </AddUser>
              </router-link>
            </div>
          </div>
          <nav class="navBreadcrumb">
            <ol class="breadcrumb">
              <slot />

              <li class="breadcrumb-item-active">
                <span> Lista de contactos</span>
              </li>
            </ol>
          </nav>
          <ContactSearch />
          <div class="titleService">
            <p class="titleBlue">Nome</p>
            <p class="titleBlue">Descrição</p>
            <p class="titleBlue">Telefone</p>
            <!-- <p class="titleBlue">Telefone 2</p>
            <p class="titleBlue">Telefone 3</p> -->
          </div>
          <hr />
          <!-- {{ filtered }} -->
          <!-- <ul v-for="contact in filteredContacts" :key="contact.id"> -->
          <ul v-for="contact in paginatedData" :key="contact.id">
            <li class="itemList">
              <ContactItem :contact="contact">
                <button
                  class="delete"
                  @click.prevent="confirmDeleteContact(contact.id)"
                ></button>
              </ContactItem>
            </li>
          </ul>
          <PaginationUrl
            :data="filteredContacts"
            :totalPages="maxPage"
            :total="filteredContacts.length"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
          >
          </PaginationUrl>
        </div>
        <div v-else>
          <p class="noResults">Não possui contactos cadastrados</p>
        </div>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalContact"
        @click="closeModalDelContact"
      ></div>
      <div class="modalDel_container" v-if="showModalContact">
        <button class="close btn" @click="closeModalDelContact">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este contacto?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelContact"
          />
          <button class="btnDel" @click.prevent="deleteContact">Delete</button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import ContactItem from "@/components/contact/ContactItem.vue";
import ContactSearch from "@/components/contact/ContactSearch.vue";

import { mapState, mapGetters } from "vuex";

export default {
  name: "Contacts",
  components: {
    ContactItem,
    ContactSearch,
  },
  computed: {
    ...mapState([
      "currentPage",
      "errors",
      "items",
      "itemsError",
      "selectedId",
    ]),
    ...mapState("contact", [
      "contacts",
      "loadingContact",
      "searchWord",
      "filtered",
      "showModalContact",
      "maxPage",
      "page",
      "name",
      "phone",
      "mail",
      "description",

    ]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["contactEdit"]),
    ...mapGetters("contact", [
        "paginatedData", 
        "getFiltered",
        "pageReset",
        "nameReset",
        "phoneReset",
        "mailReset",
        "descriptionReset",
        "nameReply",
        "nameReset",
        "phoneReply",
        "phoneReset",
        "mailReply",
        "mailReset",      
        "descriptionReply",
        "descriptionReset",
        ]),
    filteredContacts() {
      const a = this.getFiltered || this.contacts;
      return a;
    },

  },
  methods: {
    onPageChange(page) {
      //COLOCAR PAGINAÇÃO NA QUERY
      // this.$store.commit("UPDATE_CURRENTPAGE", page);
      if (this.$route.name === "contactos") {
        const filter = {
            page: page,
            name: this.$route.query.name || this.name.name,
            phone:this.$route.query.phone || this.phone.phone,
            mail:this.$route.query.mail || this.mail.mail,
            description:
            this.$route.query.description || this.description.description,
        };
        const query = Object.entries(filter).reduce((acc, [key, val]) => {
          if (!val) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$router.replace({ query: { ...query } }).catch(() => {});
      } else {
        this.$store.commit("UPDATE_CURRENTPAGE", page); //ZERAR PAGINATION
      }
      this.getContacts()
    },
    openModalDelContact() {
      this.$store.dispatch("contact/openModalDelContact");
    },
    closeModalDelContact() {
      this.$store.dispatch("contact/closeModalDelContact");
    },
    //######################################################
    //     DELETE
    //######################################################
    confirmDeleteContact(id) {
      // console.log(id);
      this.$store.dispatch("contact/confirmDeleteContact", {
        id: `${id}`,
      });
    },

    deleteContact() {
      // console.log("contactId", this.selectedId.id);
      // this.$store.commit("contact/UPDATE_DELETECONTACT", this.selectedId.id);
      this.$store.dispatch("contact/deleteContact", {
        id: this.selectedId.id,
      });
      this.closeModalDelContact();
    },
    msgFilter() {
      return this.$store.commit("UPDATE_FILTER_ITEM", this.$route.query.name ||
        this.$route.query.phone ||
        this.$route.query.mail ||
        this.$route.query.description )
    },
    getContacts() {
        this.msgFilter()
        this.$store.dispatch("contact/getContacts", {            
            page: this.$route.query.page || this.page.page,
            name: this.$route.query.name || this.name.name,
            phone:this.$route.query.phone || this.phone.phone,
            mail:this.$route.query.mail || this.mail.mail,
            description:
            this.$route.query.description || this.description.description,
         });
    }
  },
  watch: {
    // destrava a paginação e se voltar no navegador, verifica a paginação novamente.
    $route() {
      if (this.$router.back) {
        this.$store.commit(
          "UPDATE_CURRENTPAGE",
          Number(this.$route.query.page) || 1
        );

      }
    },
  },
  created() {
    this.getContacts()
    // this.$store.commit(
    //   "UPDATE_CURRENTPAGE",
    //   Number(this.$route.query.page) || 1
    // );
    // this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION    
    //  console.log(this.$router.currentRoute.query.page)
  },
};
</script>

<style scoped>
.containerContact {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1111px);
  justify-content: center;
  align-content: flex-start;
}
.titleBlue {
  padding-left: 10px;
}
hr {
  max-width: 1366px;
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 0 0 5px 0;
  text-align: left;
  margin-left: 10px;
}
/* .itemsCollab {
  padding: 40px 20px;
} */

.noResults {
  margin: 60px auto;
  max-width: 500px;
  text-align: center;
  padding: 10px;
  transition: all 0.2s;
}

.breadcrumb-item-active {
  margin-left: 10px;
}

.titleService {
  display: grid;
  /* grid-template-columns: minmax(300px, 400px) 200px 1fr; */
  grid-template-columns: 300px 250px 250px;
  height: 30px;
  max-width: 1366px;
  /* overflow-y: auto;
  overflow-x: auto; */
  border-radius: 5px;
}

@media screen and (max-width: 690px) {
  .titleService {
    display: none;
  }
}
</style>