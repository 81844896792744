var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h1',[_vm._v("Central de Eventos")]),_c('div',{staticClass:"containerTelecom"},[_c('div',{staticClass:"titleContainer"},[_c('h3',[_vm._v("Lista de Eventos")]),(_vm.telecomManager)?_c('div',{staticClass:"menuDropdown"},[_c('BtnConfig',[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Controlo de "),_c('br'),_vm._v(" Eventos")]),_c('ul',{staticClass:"menuDropdownContent",attrs:{"slot":"itemsMenu"},slot:"itemsMenu"},[_c('div',{staticClass:"titleMenuDropdown"},[_vm._v("Evento")]),_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'adicionar-mensagem',
                }}},[_vm._v("Adicionar")])],1),_c('div',{staticClass:"titleMenuDropdown"},[_vm._v("Telegram")]),_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'usuarios-telegram',
                }}},[_vm._v("Usuários")])],1)])])],1):_vm._e()]),_vm._m(0),_c('article',[(_vm.loadingMessage)?_c('div',[_c('Loading')],1):_c('div',[(_vm.filteredMessages)?_c('div',[_c('MessageSearch'),_vm._m(1),_c('hr'),_vm._l((_vm.filteredMessages),function(message){return _c('ul',{key:message.id},[_c('li',{staticClass:"itemList"},[_c('MessageItem',{attrs:{"message":message,"idMessage":message.id}},[_c('button',{staticClass:"delete",attrs:{"slot":"delete"},on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteMessage(message.id)}},slot:"delete"})])],1)])})],2):_c('div',[_c('p',{staticClass:"noResults"},[_vm._v("Não possui eventos cadastrados")])])])])]),_c('NotifySuccess',{attrs:{"items":_vm.items}}),_c('div',{staticClass:"modalDel"},[(_vm.showModalDeleteMessage)?_c('div',{staticClass:"overlay",on:{"click":_vm.closeModalDelMessage}}):_vm._e(),(_vm.showModalDeleteMessage)?_c('div',{staticClass:"modalDel_container"},[_c('button',{staticClass:"close btn",on:{"click":_vm.closeModalDelMessage}},[_vm._v("x")]),_vm._m(2),_c('div',{staticClass:"containerDel"}),_c('div',{staticClass:"buttonsDel"},[_c('input',{staticClass:"btn",attrs:{"type":"submit","value":"Cancel"},on:{"click":function($event){$event.preventDefault();return _vm.closeModalDelMessage.apply(null, arguments)}}}),_c('button',{staticClass:"btnDel",on:{"click":function($event){$event.preventDefault();return _vm.deleteMessage.apply(null, arguments)}}},[_vm._v("Delete")])])]):_vm._e()]),_c('NotifyError',{attrs:{"itemsError":_vm.itemsError}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Lista de Eventos")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleCollab"},[_c('p',{staticClass:"titleBlue"},[_vm._v("Descrição")]),_c('p',{staticClass:"titleBlue"},[_vm._v("Usuários do Telegram")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"delItem"},[_c('span',[_vm._v("Você tem certeza que deseja remover este evento?")])])
}]

export { render, staticRenderFns }