<template>
  <form action="">
    <div class="">
      <div v-if="loadingProcess">
        <p><Loading /></p>
      </div>
      <div class="formInfos" v-else>
        <!-- OPPOSITE -->
        <!-- <label for="oppositeID"> Interveniente: </label>
        <select
          v-model="oppositeID"
          :class="validateProcessOpposite"
          class="option"
          @change="resetError"
        >
          <option value="null">Nenhum Interveniente</option>
          <option
            id="oppositeID"
            v-for="(opposite, index) in processOpposites"
            :key="opposite.id"
            :selected="index"
            :value="opposite.id"
          >
            {{ opposite.name }}
          </option>
        </select>
        <div v-if="!errors.indexOf('opposite')" class="notify2">
          O Interveniente é um campo requerido
        </div> -->
        <!-- PROCESS POSITION -->
        <label for="positionID"> Posição: </label>
        <select
          v-model="positionID"
          :class="validateProcessPosition"
          class="option"
          @change="resetError"
        >
          <option value="null" disabled>Nenhuma Posição selecionada</option>
          <option
            id="positionID"
            v-for="(processPosition, index) in processPositions"
            :key="processPosition.id"
            :selected="index"
            :value="processPosition.id"
          >
            {{ processPosition.name }}
          </option>
        </select>
        <div v-if="!errors.indexOf('processPosition')" class="notify2">
          A posição é um campo requerido
        </div>
        <!-- COURT -->
        <label for="courtID"> Tribunal: </label>
        <select
          v-model="courtID"
          :class="validateProcessCourt"
          class="option"
          @change="resetError"
        >
          <option value="null" disabled>Nenhum Tribunal selecionado</option>
          <option
            id="courtID"
            v-for="(court, index) in processCourts"
            :key="court.id"
            :selected="index"
            :value="court.id"
          >
            {{ court.name }}
          </option>
        </select>
        <div v-if="!errors.indexOf('court')" class="notify2">
          O Tribunal é um campo requerido
        </div>
        <!-- PROCESS TYPE -->
        <label for="processTypeID"> Tipo de Processo: </label>
        <select
          v-model="processTypeID"
          :class="validateProcessType"
          class="option"
          @change="resetError"
        >
          <option value="null" disabled>Nenhum Tipo e Processo selecionado</option>
          <option
            id="processTypeID"
            v-for="(processType, index) in processTypes"
            :key="processType.id"
            :selected="index"
            :value="processType.id"
          >
            {{ processType.name }}
          </option>
        </select>
        <div v-if="!errors.indexOf('processType')" class="notify2">
          O Tipo de Processo é um campo requerido
        </div>
        <!-- VALUE -->
        <label for="value"> Valor: </label>
        <input
          id="value"
          name="value"
          type="number"
          v-model.number="value"
          :class="validateValue"
          @change="resetError"
        />
        <div v-if="!errors.indexOf('value')" class="notify">
          O valor é um campo requerido
        </div>
        <!-- PROCESS NUMBER -->
        <label for="processNumber"> Número do Processo: </label>
        <input
          id="processNumber"
          name="value"
          type="text"
          v-model="processNumber"
          :class="validateProcessNumber"
          @change="resetError"
        />
        <div v-if="!errors.indexOf('processNumber')" class="notify">
          Este número de processo já existe.
        </div>

        <!-- STARTDATE -->
        <label for="date"> Data de Início: </label>
        <input
          id="date"
          type="date"
          v-model="startDate"
          autocomplete="off"
          :class="[validateStartDate, validateDateInvalid]"
          @change="resetError"
        />
        <div v-if="!errors.indexOf('startDate')" class="notify2">
          A data de início é um campo requerido
        </div>
        <div v-if="!errors.indexOf('dateInvalid')" class="notify2">
          A data de início tem que ser menor ou igual que hoje.
        </div>
        <!-- ARCHIVED -->
        <div class="activeForm">
          <span v-if="archived === true">
            <label for="archived"> Desarquivar Processo</label>
          </span>
          <span v-else>
            <label for="archived"> Arquivar Processo</label>
          </span>
          <!-- <input id="archived" name="archived" type="text" v-model="archived" /> -->
          <BtnChoice v-model="archived" />
        </div>
        <div class="button">
          <slot></slot>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import BtnChoice from "@/components/items/BtnChoice.vue";
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "MessageForm",
  // props: ["errors", "idOpposite"],
  props: ["errors"],
  data() {
    return {
      contactName: [],
    };
  },
  components: {
    BtnChoice,
  },
  mounted() {},
  computed: {
    validateProcessOpposite() {
      return this.errors.indexOf("opposite") ? "true" : "validate";
    },
    validateProcessPosition() {
      return this.errors.indexOf("processPosition") ? "true" : "validate";
    },
    validateProcessCourt() {
      return this.errors.indexOf("court") ? "true" : "validate";
    },
    validateProcessType() {
      return this.errors.indexOf("processType") ? "true" : "validate";
    },
    validateProcessNumber() {
      return this.errors.indexOf("processNumber") ? "true" : "validate";
    },
    validateValue() {
      return this.errors.indexOf("value") ? "true" : "validate";
    },
    validateStartDate() {
      return this.errors.indexOf("startDate") ? "true" : "validate";
    },
    validateDateInvalid() {
      return this.errors.indexOf("dateInvalid") ? "true" : "validate";
    },
    ...mapFields({
      fields: [
        "oppositeID",
        "courtID",
        "positionID",
        "processTypeID",
        "value",
        "startDate",
        "processNumber",
        "archived",
      ], //nome dos campos
      module: "legal", //qual módulo está acessando
      base: "process", //colocar o state que vai receber
      mutation: "legal/UPDATE_PROCESS", //nome da mutation que vai alterar o state
    }),
    ...mapState(["loadingProcess"]),
    ...mapState("legal", [
      "processOpposites",
      "processTypes",
      "processCourts",
      "processPositions",
    ]),
    // processOpposite() {
    //   if (
    //     (this.processOpposites != null || this.processOpposites != undefined) &&
    //     (this.idOpposite != null || this.idOpposite != undefined)
    //   ) {
    //     return this.processOpposites.find((processPosition) => {
    //       return processPosition.id == this.idOpposite;
    //     }).name;
    //   }
    // },
  },
  methods: {
    resetError() {
      this.$store.commit("RESET_ERRORS", []);
    },
  },
  created() {
    this.$store.dispatch("legal/getProcessOpposites");
    this.$store.dispatch("legal/getProcessTypes");
    this.$store.dispatch("legal/getProcessCourts");
    this.$store.dispatch("legal/getProcessPositions");
  },
};
</script>

<style scoped>
.notify2 {
  margin: 10px 0;
}
select.option option {
  padding: 50px 0;
}
.formInfos {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-top: 20px; */
  align-content: center;
  max-width: 500px;
}
input.validate {
  margin: 0;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
@media screen and (max-width: 690px) {
  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: auto;
    width: 100%;
  }
  label,
  input {
    width: 100%;
    justify-items: flex-start;
  }
}
</style>