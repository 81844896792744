<template>
  <section class="itemContainer">
    <router-link
      :to="{
        name: 'viatura',
        params: {
          idVehicle: vehicle.id,
        },
      }"
    >
      <div class="collabItens">
        <div class="collabIten">
          <span class="titleBlue"></span>
          <div>
            <span class="titleBlue">Matrícula</span>
            <p>
              {{ vehicle.registration }}<br />
              <span
                class="brand"
                v-if="brandsName && brandsName.name != undefined"
                >{{ brandsName.name }}</span
              ><br />
              <span
                class="brand"
                v-if="modelName && modelName.name != undefined"
                >{{ modelName.name }}</span
              >
            </p>
          </div>
          <div>
            <span class="titleBlue">Próxima Inspeção</span>
            <p
              v-if="
                vehicle.nextInspectionDate &&
                vehicle.nextInspectionDate.length > 0
              "
            >
              <!-- {{ nextInspectionDate }} -->
              <span v-if="expireNextRevision">
                <!-- <span class="proximitDate revision">{{ nextInspectionDate }}</span><br /> -->
                <span class="proximitDate revision">{{
                  vehicle.nextInspectionDate | formatDateReverse
                }}</span
                ><br />
                <!-- <span class="validateNextRevision">expirado</span> -->
                <span class="validateNextRevision">{{
                  vehicle.nextInspectionDate | expireDate
                }}</span>
              </span>
              <span v-else class="revision" :class="validateNextRevison">
                <!-- {{ nextInspectionDate }} -->
                {{ vehicle.nextInspectionDate | formatDateReverse }}
              </span>
            </p>
            <p v-else class="iconNull">
              <span
                ><svg
                  class="iconNull"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.386"
                  height="19.47"
                  viewBox="0 0 19.386 19.47"
                >
                  <g transform="translate(-202.462 -30.337)">
                    <path
                      class="a"
                      d="M221.844,40.482c.009-.233,0-.467-.006-.7a9.037,9.037,0,0,0-2.685-6.462,11.633,11.633,0,0,0-1.669-1.373,9.161,9.161,0,0,0-3.638-1.455c-.042-.007-.085-.011-.127-.018-.22-.034-.439-.068-.659-.087a9.435,9.435,0,0,0-2.022.013,9.813,9.813,0,0,0-6.958,4.227h.046a9.875,9.875,0,0,0-.355,10.4,1.439,1.439,0,0,0,.319.537l.082.1a9.489,9.489,0,0,0,1.906,2.029c.086.068.179.123.267.188a8.924,8.924,0,0,0,5.611,1.927c.241,0,.488-.02.734-.037a9.57,9.57,0,0,0,1.3-.147l-.007-.007c.043-.007.084-.01.127-.018a9.673,9.673,0,0,0,7.426-7.082,1.339,1.339,0,0,0,.178-.631c.005-.051.011-.1.017-.149.014-.1.016-.2.027-.295a9.317,9.317,0,0,0,.086-.93ZM214.018,48.3a7.816,7.816,0,0,1-1.169.188l-.023-.023a8.54,8.54,0,0,1-2.953-.28c-.129-.037-.258-.075-.386-.118-.184-.06-.366-.127-.545-.2a8.384,8.384,0,0,1-.8-.377c-.056-.03-.114-.056-.169-.087a8.4,8.4,0,0,1-.834-14.074,8.4,8.4,0,0,1,8.172-1.044c.109.045.218.089.324.138.142.064.279.143.418.214A8.436,8.436,0,0,1,214.018,48.3Z"
                    />
                    <path
                      class="a"
                      d="M215.907,42.94,213.863,40.9c-.005-.005-.013-.007-.018-.012s-.007-.013-.013-.019l-.426-.426-.367-.367.367-.367.426-.426c.006-.006.007-.013.013-.019s.013-.007.018-.012l2.044-2.044a.625.625,0,0,0-.884-.884l-2.044,2.044c-.005.005-.007.013-.012.019s-.013.006-.019.012l-.426.426-.367.367-.366-.367-.427-.426c-.005-.006-.013-.007-.018-.012s-.007-.014-.013-.019l-2.044-2.044a.625.625,0,1,0-.883.884l2.043,2.044c.006,0,.013.007.019.012s.007.013.012.019l.427.426.366.367-.366.367-.427.426c0,.006-.007.013-.012.019s-.013.007-.019.012L208.4,42.94a.625.625,0,1,0,.883.884l2.044-2.044c.006-.006.007-.013.012-.019s.014-.007.019-.012l.427-.427.366-.366.367.366.426.427c.006.005.013.007.019.012s.007.013.012.019l2.044,2.044a.625.625,0,0,0,.884-.884Z"
                    />
                  </g></svg
              ></span>
            </p>
          </div>

          <div>
            <div class="titleBlue">Última Revisão</div>
            <div v-if="vehicle.revisions && vehicle.revisions.length > 0">
              <span class="telegramUsers">
                <!-- {{ revisionDate }} -->
                <!-- <span v-if="expireRevision" class="validate"
                  >Revisão vencida</span
                > -->
                <span class="revision">
                  <!-- {{ revisionDate }} -->
                  {{
                    vehicle.revisions | getLastDate("date") | formatDateReverse
                  }}
                </span>
              </span>
            </div>
            <div v-else class="iconNull">
              <span
                ><svg
                  class="iconNull"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.386"
                  height="19.47"
                  viewBox="0 0 19.386 19.47"
                >
                  <g transform="translate(-202.462 -30.337)">
                    <path
                      class="a"
                      d="M221.844,40.482c.009-.233,0-.467-.006-.7a9.037,9.037,0,0,0-2.685-6.462,11.633,11.633,0,0,0-1.669-1.373,9.161,9.161,0,0,0-3.638-1.455c-.042-.007-.085-.011-.127-.018-.22-.034-.439-.068-.659-.087a9.435,9.435,0,0,0-2.022.013,9.813,9.813,0,0,0-6.958,4.227h.046a9.875,9.875,0,0,0-.355,10.4,1.439,1.439,0,0,0,.319.537l.082.1a9.489,9.489,0,0,0,1.906,2.029c.086.068.179.123.267.188a8.924,8.924,0,0,0,5.611,1.927c.241,0,.488-.02.734-.037a9.57,9.57,0,0,0,1.3-.147l-.007-.007c.043-.007.084-.01.127-.018a9.673,9.673,0,0,0,7.426-7.082,1.339,1.339,0,0,0,.178-.631c.005-.051.011-.1.017-.149.014-.1.016-.2.027-.295a9.317,9.317,0,0,0,.086-.93ZM214.018,48.3a7.816,7.816,0,0,1-1.169.188l-.023-.023a8.54,8.54,0,0,1-2.953-.28c-.129-.037-.258-.075-.386-.118-.184-.06-.366-.127-.545-.2a8.384,8.384,0,0,1-.8-.377c-.056-.03-.114-.056-.169-.087a8.4,8.4,0,0,1-.834-14.074,8.4,8.4,0,0,1,8.172-1.044c.109.045.218.089.324.138.142.064.279.143.418.214A8.436,8.436,0,0,1,214.018,48.3Z"
                    />
                    <path
                      class="a"
                      d="M215.907,42.94,213.863,40.9c-.005-.005-.013-.007-.018-.012s-.007-.013-.013-.019l-.426-.426-.367-.367.367-.367.426-.426c.006-.006.007-.013.013-.019s.013-.007.018-.012l2.044-2.044a.625.625,0,0,0-.884-.884l-2.044,2.044c-.005.005-.007.013-.012.019s-.013.006-.019.012l-.426.426-.367.367-.366-.367-.427-.426c-.005-.006-.013-.007-.018-.012s-.007-.014-.013-.019l-2.044-2.044a.625.625,0,1,0-.883.884l2.043,2.044c.006,0,.013.007.019.012s.007.013.012.019l.427.426.366.367-.366.367-.427.426c0,.006-.007.013-.012.019s-.013.007-.019.012L208.4,42.94a.625.625,0,1,0,.883.884l2.044-2.044c.006-.006.007-.013.012-.019s.014-.007.019-.012l.427-.427.366-.366.367.366.426.427c.006.005.013.007.019.012s.007.013.012.019l2.044,2.044a.625.625,0,0,0,.884-.884Z"
                    />
                  </g></svg
              ></span>
            </div>
          </div>
          <div>
            <span class="titleBlue">Validade de Seguro</span>
            <div v-if="vehicle.insurances && vehicle.insurances.length > 0">
              <p>
                <!-- {{ insuranceStartDate }} até {{ insuranceEndDate }} -->
                <span v-if="expireInsurance">
                  <span class="proximitDate">
                    <!-- {{ insuranceStartDate }} até {{ insuranceEndDate }} -->
                    {{
                      vehicle.insurances
                        | getLastDate("startDate")
                        | formatDateReverse
                    }}
                    até
                    {{
                      vehicle.insurances
                        | getLastDate("endDate")
                        | formatDateReverse
                    }}
                  </span>
                  <!-- <span class="validate" id="insurance">expirado</span> -->
                  <span class="validate">
                    {{
                      vehicle.insurances | getLastDate("endDate") | expireDate
                    }}
                  </span>
                </span>
                <span v-else :class="validateIsurance">
                  <!-- {{ insuranceStartDate }} até {{ insuranceEndDate }} -->
                  {{
                    vehicle.insurances
                      | getLastDate("startDate")
                      | formatDateReverse
                  }}
                  até
                  {{
                    vehicle.insurances
                      | getLastDate("endDate")
                      | formatDateReverse
                  }}
                </span>
                <!-- <span >{{ insurance.endDate }}</span> -->
                <!-- <span v-if="i + 1 < vehicle.insurances.length">, </span> -->
              </p>
            </div>
            <div
              v-else-if="
                vehicle.status.insurance.reason === 'Automovel sem seguro!'
              "
              class="iconNull"
            >
              <span class="dangerInsurance"> Atenção, sem seguro!</span>
            </div>
          </div>
          <div>
            <span class="titleBlue">Serviços</span>
            <div v-if="vehicle.services && vehicle.services.length > 0">
              <span
                v-for="(service, i) in vehicle.services"
                :key="i"
                class="telegramUsers"
              >
                <span>{{ service.name }}</span>
                <span v-if="vehicle && i + 1 < vehicle.services.length"
                  >,
                </span>
              </span>
            </div>
            <div v-else class="iconNull">
              <span
                ><svg
                  class="iconNull"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.386"
                  height="19.47"
                  viewBox="0 0 19.386 19.47"
                >
                  <g transform="translate(-202.462 -30.337)">
                    <path
                      class="a"
                      d="M221.844,40.482c.009-.233,0-.467-.006-.7a9.037,9.037,0,0,0-2.685-6.462,11.633,11.633,0,0,0-1.669-1.373,9.161,9.161,0,0,0-3.638-1.455c-.042-.007-.085-.011-.127-.018-.22-.034-.439-.068-.659-.087a9.435,9.435,0,0,0-2.022.013,9.813,9.813,0,0,0-6.958,4.227h.046a9.875,9.875,0,0,0-.355,10.4,1.439,1.439,0,0,0,.319.537l.082.1a9.489,9.489,0,0,0,1.906,2.029c.086.068.179.123.267.188a8.924,8.924,0,0,0,5.611,1.927c.241,0,.488-.02.734-.037a9.57,9.57,0,0,0,1.3-.147l-.007-.007c.043-.007.084-.01.127-.018a9.673,9.673,0,0,0,7.426-7.082,1.339,1.339,0,0,0,.178-.631c.005-.051.011-.1.017-.149.014-.1.016-.2.027-.295a9.317,9.317,0,0,0,.086-.93ZM214.018,48.3a7.816,7.816,0,0,1-1.169.188l-.023-.023a8.54,8.54,0,0,1-2.953-.28c-.129-.037-.258-.075-.386-.118-.184-.06-.366-.127-.545-.2a8.384,8.384,0,0,1-.8-.377c-.056-.03-.114-.056-.169-.087a8.4,8.4,0,0,1-.834-14.074,8.4,8.4,0,0,1,8.172-1.044c.109.045.218.089.324.138.142.064.279.143.418.214A8.436,8.436,0,0,1,214.018,48.3Z"
                    />
                    <path
                      class="a"
                      d="M215.907,42.94,213.863,40.9c-.005-.005-.013-.007-.018-.012s-.007-.013-.013-.019l-.426-.426-.367-.367.367-.367.426-.426c.006-.006.007-.013.013-.019s.013-.007.018-.012l2.044-2.044a.625.625,0,0,0-.884-.884l-2.044,2.044c-.005.005-.007.013-.012.019s-.013.006-.019.012l-.426.426-.367.367-.366-.367-.427-.426c-.005-.006-.013-.007-.018-.012s-.007-.014-.013-.019l-2.044-2.044a.625.625,0,1,0-.883.884l2.043,2.044c.006,0,.013.007.019.012s.007.013.012.019l.427.426.366.367-.366.367-.427.426c0,.006-.007.013-.012.019s-.013.007-.019.012L208.4,42.94a.625.625,0,1,0,.883.884l2.044-2.044c.006-.006.007-.013.012-.019s.014-.007.019-.012l.427-.427.366-.366.367.366.426.427c.006.005.013.007.019.012s.007.013.012.019l2.044,2.044a.625.625,0,0,0,.884-.884Z"
                    />
                  </g></svg
              ></span>
            </div>
          </div>
        </div>
        <div class="collabConfig">
          <!-- <router-link
            v-if="vehicleManager"
            class="edit"
            :to="{
              name: 'editar-message',
              params: { idMessage: message.id }
            }"
          >
          </router-link> -->
          <slot v-if="vehicleManager" name="delete" />
        </div>
      </div>
    </router-link>
  </section>
</template>  

<script>
import { getLastDate } from "@/helpers.js";
// import { formatDate } from "@/helpers.js";
import { alertDate } from "@/helpers.js";
// import { formatDateString } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "VehicleItem",
  props: ["vehicle", "idVehicle", "models", "brands"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("vehicle", ["vehicles", "typeVehicles"]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),

    // nextInspectionDate() {
    //   return formatDateString(new Date(this.vehicle.nextInspectionDate));
    // },
    // revisionDate() {
    //   return formatDate(getLastDate(this.vehicle.revisions, "date"));
    // },

    // insuranceEndDate() {
    //   return formatDate(getLastDate(this.vehicle.insurances, "endDate"));
    // },

    // insuranceStartDate() {
    //   return formatDate(getLastDate(this.vehicle.insurances, "startDate"));
    // },
    modelName() {
      var itemModel = null;
      if (this.models != null && this.models != null) {
        itemModel = this.models.find((typeVehicle) => {
          return typeVehicle.id == this.vehicle.modelID;
        });
        // console.log(itemModel != undefined);
        return itemModel;
      } else {
        return "";
      }
    },
    brandsName() {
      var itemBrand = null;
      if (this.models != null && this.brands != null) {
        itemBrand = this.models.find((typeVehicle) => {
          return typeVehicle.id == this.vehicle.modelID;
        });
        if (itemBrand != undefined) {
          itemBrand = this.brands.find((brand) => {
            return brand.id == itemBrand.brandID;
          });
        }
        // console.log(itemBrand != undefined)
        return itemBrand;
      } else {
        return "";
      }
    },
    expireInsurance() {
      let today = new Date();
      let insurance = getLastDate(this.vehicle.insurances, "endDate");
      return insurance < today;
    },

    expireNextRevision() {
      // let today = new Date();
      // let revision = new Date(this.vehicle.nextInspectionDate);
      // return revision < today;
      return this.vehicle.status.inspection.status === "Danger" ? true : false;
    },

    validateNextRevison() {
      //   Se data de inspeção expirou (hj > inspection date) emite status Danger
      // Se data de inspeção está está até 30 dias do limite emite status Warning
      // Se data de inspeção está mais de 30 dias do limite emite status OK

      return this.vehicle.status.inspection.status === "Danger" ||
        this.vehicle.status.inspection.status === "Warning"
        ? "proximitDate"
        : "nothing";
      // return alertDate(new Date(this.vehicle.nextInspectionDate), 30)
      //   ? "proximitDate"
      //   : "nothing";
    },

    validateIsurance() {
      return alertDate(getLastDate(this.vehicle.insurances, "endDate"), 30)
        ? "proximitDate"
        : "nothing";
    },
  },
  methods: {},

  created() {
    // this.$store.dispatch("vehicle/getModels");
    // this.$store.dispatch("vehicle/getBrands");
    // console.log(this.alertDate);
    // this.$store.dispatch("vehicle/getTypeVehicles");
  },
};
</script>

<style scoped>
.brand {
  color: #28a745;
  font-size: 14px;
}
.proximitDate {
  color: red;
}
.dangerInsurance {
  color: red;
  font-size: 16px;
  line-height: 21px;
}
#insurance.validate {
  margin: 0;
  display: flex;
  justify-content: center;
  max-width: 190px;
}
.validate {
  margin-left: 17%;
  color: red;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}
.validateNextRevision {
  margin-left: 17%;
  color: red;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}
.revision {
  margin-left: 25px;
}
.iconNull {
  fill: #88898f;
  margin-left: 35px;
  /* text-align: center; */
}
.telegramUsers {
  color: #1f2b42;
  font-size: 16px;
  line-height: 21px;
}

.itemContainer {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
  width: 100%;
}

.collabItens {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  grid-template-columns: 1fr 90px;
}

.collabIten {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  grid-template-columns: 150px 200px 170px 265px minmax(200px, 380px) 50px;
  align-items: center;
  grid-gap: 3spx;
}

.collabConfig {
  display: grid;
  grid-template-columns: 50px;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

.collabItens:hover {
  background: #dbebf3;
}

.collabItens p {
  padding: 0 10px;
}
.titleBlue {
  display: none;
}

@media screen and (max-width: 695px) {
  .collabItens {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 10px;
    justify-items: flex-end;
  }
  .collabItens p {
    padding: 0px;
  }
  .collabIten {
    grid-template-columns: 260px;
  }
  .titleBlue {
    display: block;
    /* padding: 5px 0 0 10px; */
  }
  .itemContainer {
    width: 100%;
  }
  .validate,
  .revision {
    margin: 0;
  }
}
</style>
