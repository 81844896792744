<template>
  <section class="itemsCollab">
    <h1>Jurídico</h1>
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Lista de Processos</h3>
        <div v-if="processEdit" class="menuDropdown">
          <!-- <router-link
            class="collabItens"
            :to="{
              name: 'adicionar-processo',
            }"
          >
            <AddUser>
              <span slot="title">
                Adicionar<br />
                Processo</span
              >
            </AddUser>
          </router-link> -->
          <BtnConfig>
            <span slot="title">
              Controlo <br />
              do Jurídico</span
            >
            <ul slot="itemsMenu" class="menuDropdownContent">
              <div class="titleMenuDropdown">Adicionar</div>
               <li>
                <router-link
                  :to="{
                    name: 'adicionar-processo',
                  }"
                  >Processo</router-link
                >
              </li>      
              <div class="titleMenuDropdown topsubmenu">Controlo</div>
              <li>
                <router-link
                  :to="{
                    name: 'intervenientes',
                  }"
                  >Intervenientes</router-link
                >
              </li>                     
              <!-- <li>
                <router-link
                  :to="{
                    name: 'processos',
                  }"
                  >Processos</router-link
                >
              </li> -->
              <li>
                <router-link
                  :to="{
                    name: 'tipos-de-processos',
                  }"
                >
                  Tipos de Processos
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'tribunais',
                  }"
                >
                  Tribunal
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'tipo-de-taxas',
                  }"
                >
                  Tipos de Taxas
                </router-link>
              </li>
              <li>
                <router-link
                  :to="{
                    name: 'lista-posicoes',
                  }"
                >
                  Posições
                </router-link>
              </li>
            </ul>
          </BtnConfig>
        </div>
      </div>
      <div v-if="loadingLegal">
        <Loading />
      </div>
      <div v-else>
<!--        <div v-if="processes && processes.length > 0">-->
          <nav class="navBreadcrumb">
            <ol class="breadcrumb">
              <slot />
              <!-- <li class="breadcrumb-item">
                <router-link :to="{ name: 'processos', query: { archived: 'false'} }"  >Lista de Processos</router-link
            >
              </li> -->
              <li class="breadcrumb-item-active">
                <span> Lista de Processos </span>
              </li>
            </ol>
          </nav>
          <div class="btnContainer">
            <!-- <div>Serviços:</div> -->
           
            <button :class="activeVerify" @click.prevent="activeRouter">
              Activos
            </button>
            <button :class="disabledVerify" @click.prevent="disabledRouter">
              Arquivados
            </button>
             <button :class="allVerify" @click.prevent="resetActive">
              Todos
            </button>
          </div>
          <hr class="hrButtons">
          <!-- <ProcessSearch /> -->
        <div v-if="processes && processes.length > 0">
          <div class="filterBtn">
              <div @click.prevent="filterBtn">
                <BtnFilter>
                  <span slot="filtro">Filtro Processos</span>
                </BtnFilter>
              </div>
            </div>
            <div v-if="btnFilter">
              <ProcessSearch />
            </div>
          <div class="titleService">
            <p class="titleBlue">Interveniente</p>
            <p class="titleBlue">Número do Processo</p>
            <p class="titleBlue">Valor</p>
            <p class="titleBlue">Tribunal</p>
            <p class="titleBlue">Posição</p>
          </div>
          <hr>
          <!-- <ul v-for="process in filteredProcess" :key="process.id"> -->
          <ul v-for="process in paginatedProcess" :key="process.id">
            <!-- <ul v-for="address in filteredAddresses" :key="address.id"> -->
            <li>
              <ProcessItemList 
                :process="process"
                :processCourts="processCourts"
                :processPositions="processPositions"
              >
                <!-- <button
                  v-if="processEdit"
                  slot="delete"
                  class="delete"
                  @click.prevent="confirmDeleteProcess(process.id)"
                ></button> -->
              </ProcessItemList>
            </li>
          </ul>
          <Pagination
            :data="filteredProcess"
            :totalPages="Math.ceil(filteredProcess.length / limit)"
            :total="filteredProcess.length"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
          >
          </Pagination>
        </div>
        <div v-else>
          <p class="noResults">Não possui processos cadastrados</p>
        </div>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelProcess"
        @click="closeModalDelProcess"
      ></div>
      <div class="modalDel_container" v-if="showModalDelProcess">
        <button class="close btn" @click="closeModalDelProcess">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este processo?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelProcess"
          />
          <button class="btnDel" @click.prevent="deleteProcess">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import ProcessItemList from "@/components/legal/ProcessItemList.vue";
import ProcessSearch from "@/components/legal/ProcessSearch.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Processes",
  components: {
    ProcessItemList,
    ProcessSearch,
  },
  computed: {
    ...mapState([
      "limit",
      "currentPage",
      "errors",
      "items",
      "itemsError",
      "selectedId",
      "btnFilter",
    ]),
    ...mapState("legal", [
      "processes",
      "loadingLegal",
      "searchWord",
      "filtered",
      "showModalDelProcess",
      "processOpposites",
      "processCourts",
      "processPositions",
      "oppositeSearch",
      "numberSearch",
      "valueSearch",
      "courtSearch",
      "arSearch",
      "typeProcessSearch",
      "dateProcess",
    ]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalysis",
      "processManager",
    ]),
    ...mapGetters("legal", ["paginatedProcess", "getFiltered", "filteredProcess"]),
    
    allVerify() {
      return this.$route.query.archived === undefined
        ? "activeLi"
        : "activeNothing";
    },
    activeVerify() {
      return this.$route.query.archived === "false"
        ? "activeLi"
        : "activeNothing";
    },
    disabledVerify() {
      return this.$route.query.archived === "true" ? "activeLi" : "activeNothing";
      
    },
  },
  methods: {
    filterBtn() {
      this.btnFilter == true
        ? this.$store.commit("UPDATE_BTNFILTER", false)
        : this.$store.commit("UPDATE_BTNFILTER", true);
      // console.lgo(update);
    },
    onPageChange(page) {
      // this.$store.commit("UPDATE_CURRENTPAGE", page);
      if (this.$route.name === "processos") {
        const filter = {
          archived: this.$route.query.archived || null,
          oppositeSearch: this.oppositeSearch,
          numberSearch: this.numberSearch,
          valueSearch: this.valueSearch,
          courtSearch: this.courtSearch,
          arSearch: this.arSearch,
          typeProcessSearch: this.typeProcessSearch,
          dateProcess: this.dateProcess,
          page: page,
        };
        const query = Object.entries(filter).reduce((acc, [key, val]) => {
          if (!val) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$router.replace({ query: { ...query } }).catch(() => {});
      } else {
        this.$store.commit("UPDATE_CURRENTPAGE", page); //ZERAR PAGINATION
      }
      this.$store.commit(
      "UPDATE_CURRENTPAGE",
        Number(this.$route.query.page) || 1
      );
    },
    //######################################################
    //     TABS
    //######################################################
     activeRouter() {
     this.$route.query.archived != "false"
        ? this.$router.push({ query: { archived: false } })
        : "activeNothing";
      this.$store.commit("legal/RESET_FILTER", null);
      this.getProcesses()
      this.$store.commit("UPDATE_CURRENTPAGE", 1)
    },
    disabledRouter() {
       this.$route.query.archived != "true"
        ? this.$router.push({ query: { archived: true } })
        : "activeNothing";
      this.$store.commit("legal/RESET_FILTER", null);
      this.getProcesses()
      this.$store.commit("UPDATE_CURRENTPAGE", 1)
    },
    resetActive() {
      this.$route.query.archived != undefined
          ? this.$router.push(this.$route.path)
          : "nothing";
      this.getProcesses()
      this.$store.commit("UPDATE_CURRENTPAGE", 1)
    },
    //######################################################
    //     DELETE
    //######################################################
    openModalDelProcess() {
      this.$store.dispatch("legal/openModalDelProcess");
    },
    closeModalDelProcess() {
      this.$store.dispatch("legal/closeModalDelProcess");
    },
    confirmDeleteProcess(id) {
      // console.log(id);
      this.$store.dispatch("legal/confirmDeleteProcess", {
        id: `${id}`,
      });
    },
    deleteProcess() {
      // console.log("selectedId", this.selectedId.id);
      this.$store.dispatch("legal/deleteProcess", {
        id: this.selectedId.id,
      });
      this.closeModalDelProcess();
    },
    getProcesses(){
      // this.$route.query.archived === "false"
      this.$store.dispatch("legal/getProcesses", {
        refresh: "refresh"
      });      
    }
  },
  created() {    
    this.$store.commit(
      "UPDATE_CURRENTPAGE",
      Number(this.$route.query.page) || 1
    );
    // this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
    this.$store.commit("legal/RESET_FILTER", null);
    // this.$store.dispatch("legal/getProcesses");
    this.$store.dispatch("legal/getProcessOpposites");
    this.$store.dispatch("legal/getProcessCourts");
    this.$store.dispatch("legal/getProcessPositions");
    this.$store.dispatch("legal/getProcessTypes");
    this.getProcesses()
  },
};
</script>

<style scoped>
.filterBtn {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px 0;
}
.hrButtons {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}

.btnContainer {
  display: flex;
  justify-items: flex-start;
  /* grid-gap: 10px; */
  align-items: flex-end;
  /* margin-bottom: 30px; */
}

.activeLi {
  display: block;
  padding: 10px 30px;
  justify-self: center;
  /* border-radius: 9px; */
  border-radius: 9px 9px 0 0;
  overflow: none;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  background: #28a745;
  color: #fff;
}


.activeNothing {
  display: block;
  padding: 10px 30px;
  justify-self: center;
  /* border-radius: 9px; */
  border-radius: 9px 9px 0 0;
  overflow: none;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  background: #d8d8d8;
  color: #1f2b42;
}

.containerContact {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1200px);
  justify-content: center;
  align-content: flex-start;
}
.titleBlue {
  padding-left: 10px;
}
hr {
  max-width: 1366px;
  padding: 0px;
  margin-bottom: 10px;
}

h3 {
  padding: 0 0 5px 0;
  text-align: left;
  margin-left: 10px;
}
/* .itemsCollab {
  padding: 40px 20px;
} */

.noResults {
  margin: 60px 0;
  max-width: 500px;
  /* text-align: center; */
  padding: 10px;
  transition: all 0.2s;
}

.breadcrumb-item-active {
  margin-left: 10px;
}

.titleService {
  display: grid;
  /* grid-template-columns: minmax(300px, 400px) 200px 1fr; */
  grid-template-columns: 310px 230px 120px 225px 1fr 50px;
  /* grid-template-columns: 310px 240px; */
  height: 30px;
  max-width: 1366px;
  /* overflow-y: auto;
  overflow-x: auto; */
  border-radius: 5px;
  width: 100%;
}

@media screen and (max-width: 690px) {
  .titleService {
    display: none;
  }
}
</style>