<template>
  <div class="btnAdd">
    <div class="circle">
      <input id="burger" type="checkbox" name="" value="" checked />
      <label for="burger">
        <svg class="icon-add" viewBox="0 0 100 80" width="24" height="24">
          <rect width="100" height="20" rx="8"></rect>
          <rect y="30" width="100" height="20" rx="8"></rect>
          <rect y="60" width="100" height="20" rx="8"></rect>
        </svg>
      </label>
    </div>
    <!-- <span class="titleAdd">
      <slot />
    </span> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
input#burger {
  display: none;
}

label {
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

input[type="checkbox"]:checked + label .icon-add {
  fill: #fff;
}

.titleAdd {
  font-size: 12px;
}

.icon-add {
  fill: #28a745;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1f2b42;
  width: 50px;
  height: 50px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.circle:hover .icon-add {
  fill: #fff;
}
.circle:hover {
  background: #28a745;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.btnAdd {
  display: flex;
  align-items: center;
  cursor: pointer;
  /* padding: 10px; */
}
</style>