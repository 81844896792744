<template>
  <section class="archiveContainer" v-if="archive">
    <!-- {{ archive }}
    {{ archive.fileID }}<br />
    {{ archive.filename }}<br />
    {{ idInsurance }}<br /> -->
    <div
      class="collabIten"
      @click="getFile(archive.fileID, archive.filename, idMicroservice)"
    >
      <div class="file">
        <button class="btnDownload">
          <svg
            class="iconAudio"
            xmlns="http://www.w3.org/2000/svg"
            width="23.634"
            height="23.632"
            viewBox="0 0 23.634 23.632"
          >
            <g transform="translate(-320.076 -384.637)">
              <path
                class="a"
                d="M331.893,384.637A11.809,11.809,0,1,0,343.71,396.45,11.743,11.743,0,0,0,331.893,384.637ZM339.44,404a10.67,10.67,0,1,1,3.129-7.546A10.6,10.6,0,0,1,339.44,404Z"
              />
              <path
                class="a"
                d="M331.314,398.1a.738.738,0,0,0,1.159,0c.7-.714,1.411-1.411,2.115-2.117.285-.285.428-.615.108-.946s-.654-.194-.936.1c-.366.383-.726.771-1.169,1.243-.015-.112-.027-.217-.04-.325a.615.615,0,0,0,.026-.129v-4.786a.684.684,0,1,0-1.367,0v4.786a.615.615,0,0,0,.026.129c-.013.108-.025.213-.04.325-.443-.472-.8-.86-1.169-1.243-.281-.3-.608-.44-.936-.1s-.177.661.108.946C329.9,396.688,330.617,397.385,331.314,398.1Z"
              />
              <path
                class="a"
                d="M337.046,398.45a.685.685,0,0,0-.684.684v.941h-.007v.458a.911.911,0,0,1-.91.91H328.34a.911.911,0,0,1-.91-.91v-.458h-.007v-.941a.684.684,0,0,0-1.368,0v1.4a2.288,2.288,0,0,0,2.285,2.285h7.105a2.288,2.288,0,0,0,2.285-2.285v-1.4A.685.685,0,0,0,337.046,398.45Z"
              />
            </g>
          </svg>
        </button>
        <div class="nameArchive">
          {{ archive.filename }}
        </div>
      </div>
    </div>
    <div class="collabConfig">
      <slot v-if="processManager || processEdit" name="delete" />
    </div>
  </section>
</template>  

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "InsuranceItem",
  props: ["archive", "idMicroservice"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("processType", ["revisionHistory"]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalysis",
      "processManager",
    ]),
  },
  methods: {
    getFile(fileID, filename, idMicroservice) {
      // console.log(fileID, filename, idInsurance);
      // //numero centrais do controle --- o emit envia p/ a view a pagina que está atualmente
      this.$emit("getFile", fileID, filename, idMicroservice);
    },
  },
};
</script>

<style scoped>
.nameArchive {
  max-width: 400px;
  white-space: nowrap;
  overflow: hidden; /* "overflow" value must be different from "visible" */
  text-overflow: ellipsis;
}
.file {
  display: flex;
  cursor: pointer;
  grid-gap: 10px;
}
button.btnDownload {
  border: none;
  background: none;
  width: 40px;
}
.btnDownload {
  cursor: pointer;
}
.iconAudio {
  fill: #389832;
}

.archiveContainer {
  display: grid;
  grid-template-columns: 1fr 50px;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
  width: 100%;
}

.collabIten {
  align-items: center;
  padding: 5px 15px;
  justify-content: space-between;
}

.collabConfig {
  display: grid;
  grid-template-columns: 40px 50px;
  text-align: left;
  align-content: center;
  font-size: 0.8rem;
  padding: 5px 0 0 0;
}

.collabIten:hover {
  background: #dbebf3;
}

.collabItens p {
  padding: 0 10px;
}
.titleBlue {
  display: none;
}

@media screen and (max-width: 690px) {
  .collabIten {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }

  .nameArchive {
    max-width: 250px;
  }
  /* .collabIten {
    grid-template-columns: 260px;
  } */
  /* .textGray {
    padding: 0 0 0 20px;
  } */

  .titleBlue {
    display: block;
    padding: 5px 0 0 10px;
  }
  .archiveContainer {
    width: 100%;
  }
}
</style>
