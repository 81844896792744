<template>
  <section class="itemContainer">
    <router-link
      :to="{
        name: 'mensagem',
        params: { idMessage: message.id },
      }"
    >
      <div class="collabItens">
        <div class="collabIten">
          <div>
            <span class="titleBlue">Descrição</span>
            <p>{{ message.description }}: {{ message.event }}</p>
          </div>
          <div>
            <span class="titleBlue">Usuários do Telegram</span>
            <span v-for="(user, i) in users" :key="user" class="telegramUsers">
              <span>{{ user }}</span>
              <span v-if="i + 1 < users.length">, </span>
            </span>
          </div>
        </div>
        <div class="collabConfig">
          <!-- <router-link
            v-if="telecomManager"
            class="edit"
            :to="{
              name: 'editar-message',
              params: { idMessage: message.id }
            }"
          >
          </router-link> -->
          <slot v-if="telecomManager" name="delete" />
        </div>
      </div>
    </router-link>
  </section>
</template>  

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "MessageItem",
  props: ["message", "idMessage"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("message", ["telegramUsers"]),
    ...mapGetters("user", ["telecomManager"]),

    users() {
      let a = [];
      for (let i in this.telegramUsers) {
        this.message.telegramWatchers.find((user) => {
          user === this.telegramUsers[i].id
            ? a.push(this.telegramUsers[i].firstName)
            : "";
        });
      }
      return a;
    },
  },

  created() {
    this.$store.dispatch("message/getTelegramUsers");
  },
};
</script>

<style scoped>
.telegramUsers {
  color: #1f2b42;
  font-size: 14px;
  line-height: 18px;
}

.itemContainer {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
  width: 100%;
}

.collabItens {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  grid-template-columns: 1fr 90px;
}

.collabIten {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  grid-template-columns: 350px minmax(200px, 390px) 50px;
  align-items: center;
}

.collabConfig {
  display: grid;
  grid-template-columns: 50px;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

.collabItens:hover {
  background: #dbebf3;
}

.collabItens p {
  padding: 0 10px;
}
.titleBlue {
  display: none;
}

@media screen and (max-width: 695px) {
  .collabItens {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 10px;
    justify-items: flex-end;
  }
  .collabItens p {
    padding: 0px;
  }
  .collabIten {
    grid-template-columns: 260px;
  }
  .titleBlue {
    display: block;
    /* padding: 5px 0 0 10px; */
  }
  .itemContainer {
    width: 100%;
  }
}
</style>
