<template>
  <section class="">
    <router-link
      class="collabItens itemList"
      :to="{
        name: 'processo',
        params: { idProcess: process.id },
      }"
    >
      <article class="containerLinkForm">
        <!-- {{ process }} -->
        <!-- <div>{{ processCourt }}</div> -->
        <div>{{ processType }}</div>
        <div>{{ processPosition }}</div>
        <div>{{ process.processNumber }}</div>
        <div>{{ process.value | numberPrice }}</div>
        <!-- <div>{{ process.archived }}</div> -->
        <!-- <div>{{ processTax }}</div> -->
        <!-- <div>{{ process.startDate | formatDateReverse }}</div> -->
        <div class="delete">
          <slot name="delete" />
        </div>
      </article>
    </router-link>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "OppositeProcessItem",
  props: [
    "process",
    "processPositions",
    "processTypes",
    "processCourts",
    // "processTaxes",
  ],
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["processEdit"]),

    processPosition() {
      if (
        (this.processPositions != null || this.processPositions != undefined) &&
        (this.process.positionID != null ||
          this.process.positionID != undefined)
      ) {
        return this.processPositions.find((processPosition) => {
          return processPosition.id == this.process.positionID;
        }).name;
      }
    },
    processType() {
      if (
        (this.processTypes != null || this.processTypes != undefined) &&
        (this.process.processTypeID != null ||
          this.process.processTypeID != undefined)
      ) {
        return this.processTypes.find((processType) => {
          return processType.id == this.process.processTypeID;
        }).name;
      }
    },
    processCourt() {
      if (
        (this.processCourts != null || this.processCourts != undefined) &&
        (this.process.courtID != null || this.process.courtID != undefined)
      ) {
        return this.processCourts.find((processCourt) => {
          return processCourt.id == this.process.courtID;
        }).name;
      }
    },
    // processTax() {
    //   // console.log("tax", this.process.taxID);
    //   if (
    //     (this.processTaxes != null || this.processTaxes != undefined) &&
    //     (this.process.taxID != null || this.process.taxID != undefined)
    //   ) {
    //     return this.processTaxes.find((processTaxe) => {
    //       return processTaxe.id == this.process.taxID;
    //     }).value;
    //   }
    // },
    // processTaxe() {
    //   return this.processTaxes.find((processTaxe) => {
    //     return processTaxe.id == this.process.positionID;
    //   }).value;
    // },
  },
};
</script>

<style scoped>
.itemList {
  align-items: center;
  padding: 10px 0;
  /* margin-bottom: 30px; */
}
.containerLinkForm {
  display: grid;
  /* grid-template-columns: 1fr; */
  grid-template-columns: 300px 120px 135px 1fr 40px;
  grid-gap: 10px;
  padding: 5px 10px;
  width: 100%;
}
.titleLegal {
  display: grid;
}
.delete {
  margin-right: 10px;
}
.itemAddress {
  display: none;
}
@media screen and (max-width: 690px) {
  .containerLinkForm {
    grid-template-columns: 1fr;
    padding: 5px 10px;
  }
  .itemAddress {
    display: block;
  }
}
</style>