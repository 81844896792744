<template>
  <section v-if="service">
    <h1>Serviços</h1>
    <div class="contentGeneral">
      <h3>Editar Serviço</h3>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'servicos' }"
              >Lista de Serviços</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{ name: 'servico', params: { idService: idService } }"
              >Dados do Serviço</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Editar Service</span>
          </li>
        </ol>
      </nav>
      <transition mode="out-in">
        <ServiceForm :contact="service" :errors="errors">
          <button class="btn btn-form" @click.prevent="updateService">
            Gravar
          </button>
        </ServiceForm>
      </transition>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </section>
</template>

<script>
import ServiceForm from "@/components/service/ServiceForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ServiceAdd",
  props: ["idService"],

  data() {
    return {
      create: false,
    };
  },
  components: {
    ServiceForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("service", ["service"]),
    ...mapGetters("service", ["serviceReset", "serviceReply"]),
  },
  methods: {
    updateService() {
      // console.log(this.serviceReply);
      this.$store.dispatch("service/updateService", {
        idService: this.idService,
        service: this.serviceReply, //enviar getters com as infos necessárias para o cadastro
      });

      // this.$store.dispatch("collaborator/getCollaborators");
    },
  },
  created() {
    this.$store.commit("RESET_ERRORS", [], { root: true });
    this.$store.dispatch("service/getService", {
      idService: this.idService,
    });
  },
};
</script>

<style scoped>
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
</style>
