<template>
  <section class="itemContainer" v-if="insurance && index >= 0">
    <article v-if="index >= 0" class="containerLink">
      <div
        v-if="revisionHistory != index"
        class="accordion"
        @click="activeRevision(index)"
      >
        <li class="items">
          <span class="titleBlue">Validade do Seguro</span>
          <div class="list">
            <span :class="validateIsurance">{{
              insurance.endDate | formatDateReverse
            }}</span>
            <span class="validate">{{ insurance.endDate | expireDate }}</span>
            <!-- <span v-if="expireInsurance" class="validate">(expirado)</span> -->
          </div>
        </li>
        <div class="open"></div>
      </div>
      <div v-else class="accordion" @click="removeRevision()">
        <li class="items">
          <span class="titleBlue">Validade do Seguro</span>
          <div class="list">
            <span :class="validateIsurance">{{
              insurance.endDate | formatDateReverse
            }}</span>
            <span class="validate">{{ insurance.endDate | expireDate }}</span>
            <!-- <span v-if="expireInsurance" class="validate">(expirado)</span> -->
          </div>
        </li>
        <div class="close"></div>
      </div>
    </article>
    <article
      class="revisionBtns"
      v-if="index >= 0 && revisionHistory === index"
    >
      <!-- <article class="revisionBtns" v-if="index >= 0 && revisionHistory === index"> -->
      <router-link
        v-if="vehicleManager || vehicleEdit"
        :to="{
          name: 'editar-seguro',
          params: { idVehicle: idVehicle, idInsurance: idInsurance },
        }"
      >
        <button class="edit"></button>
      </router-link>
      <slot v-if="vehicleManager" name="delete" />
    </article>
    <div class="textGray" v-if="index >= 0 && revisionHistory === index">
      <ul>
        <!-- <li class="items itemList">
          <span class="titleBlue">Validade do Seguro</span>
          <div class="list">
            <span>{{ insuranceEndDate }}</span>
          </div>
        </li> -->
        <li class="items itemList">
          <span class="titleBlue">Início do Seguro</span>
          <div class="list">
            <span>{{ insurance.startDate | formatDateReverse }}</span>
          </div>
        </li>
        <li class="items itemList">
          <span class="titleBlue">Número da Apólice</span>
          <div class="list">
            <span v-if="insurance.apolicyNumber">{{
              insurance.apolicyNumber
            }}</span>
            <span v-else>Apólice não registrada</span>
          </div>
        </li>
        <li class="items itemList">
          <span class="titleBlue">Criado</span>
          <div class="list">
            <span>{{ insurance.created | formatDateReverse }}</span>
          </div>
        </li>
        <li class="items itemList">
          <span class="titleBlue">Modificado</span>
          <div class="list">
            <span>{{ insurance.modified | formatDateReverse }}</span>
          </div>
        </li>
        <li class="items containerFile">
          <span class="titleBlue">Ficheiros</span>
          <slot name="fileArchive" />
        </li>
        <slot name="listArchive" />
      </ul>
    </div>
  </section>
</template>  

<script>
import { alertDate } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "InsuranceItem",
  props: ["insurance", "idVehicle", "idInsurance", "index"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("vehicle", ["revisionHistory"]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),

    validateIsurance() {
       return alertDate(new Date(this.insurance.endDate), 30)
         ? "proximitDate"
        : "nothing";
      // return this.vehicle.status.insurance.status === "Danger" ||
      //   this.vehicle.status.insurance.status === "Warning"
      //   ? "proximitDate"
      //   : "nothing";
      // return alertDate(getLastDate(this.vehicle.insurances, "endDate"), 30)
      //   ? "proximitDate"
      //   : "nothing";
    },

    // expireInsurance() {
    //   let today = new Date();
    //   let insurance = getLastDate(this.vehicle.insurances, "endDate");
    //   return insurance < today;
    //   // return this.vehicle.status.insurance.status === "Danger" ? true : false;
    // },
  },
  methods: {
    activeRevision(index) {
      this.$store.commit("vehicle/UPDATE_SELECTEDFILE", null);
      this.$store.commit("vehicle/UPDATE_REVISIONSHISTORY", index);
    },
    removeRevision() {
      this.$store.commit("vehicle/UPDATE_SELECTEDFILE", null);
      this.$store.commit("vehicle/UPDATE_REVISIONSHISTORY", null);
    },
  },
};
</script>

<style scoped>
.proximitDate {
  color: red;
}
.validate {
  margin-left: 30px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}
.containerFile {
  display: flex;
  align-content: center;
  align-items: baseline;
  /* grid-gap: 10px; */
  margin: 10px 0 0 0;
}
.accordion {
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.close::after {
  content: "❯";
  color: #858585;
  width: 1em;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  padding: 0 20px;
}
.close {
  transition: all 0.35s;
  transform: rotate(-89deg);
}
.open::after {
  content: "❯";
  width: 1em;
  color: #858585;
  height: 1em;
  text-align: center;
  transition: all 0.35s;
  padding: 0 20px;
}
.open {
  transition: all 0.35s;
  transform: rotate(89deg);
}
.containerLink {
  background: #f0f2f7;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  /* box-shadow: 0 4px 8px rgb(30 60 90 / 20%); */
  /* border-bottom: 1px solid #cbcbcb; */
  display: flex;
  justify-content: flex-end;
  justify-items: flex-end;
  padding: 10px;
  /* margin: 0 0 10px 0; */
  color: #1f2b42;
  cursor: pointer;
  font-size: 18px;
  line-height: 28px;
}
.revisionBtns {
  display: grid;
  grid-template-columns: repeat(2, 40px);
  justify-content: flex-end;
  justify-items: flex-end;
  grid-gap: 5px;
  margin-right: 10px;
  margin-top: 10px;
}
.articleInfos,
.callsHistory {
  margin-top: 30px;
}
.titleInfos {
  margin-bottom: 10px;
}

.iconInfoSearchHistory {
  margin: 0 10px;
}
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}
.revision {
  margin-left: 40px;
}
.iconNull {
  fill: #88898f;
  margin-left: 35px;
  /* text-align: center; */
}
.telegramUsers {
  color: #1f2b42;
  font-size: 14px;
  line-height: 18px;
}

.itemContainer {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
  width: 100%;
}

.collabItens {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  grid-template-columns: 1fr 90px;
}

.collabIten {
  display: grid;
  /* grid-template-columns: minmax(200px, 380px) 1fr 40px 50px; */
  grid-template-columns: 150px 180px 250px minmax(200px, 380px) 50px;
  align-items: center;
  grid-gap: 3spx;
}

.collabConfig {
  display: grid;
  grid-template-columns: 50px;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

.collabItens:hover {
  background: #dbebf3;
}

.collabItens p {
  padding: 0 10px;
}

@media screen and (max-width: 695px) {
  .collabItens {
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding: 0 10px;
    justify-items: flex-end;
  }
  .collabItens p {
    padding: 0px;
  }
  .collabIten {
    grid-template-columns: 260px;
  }
  .titleBlue {
    display: block;
    /* padding: 5px 0 0 10px; */
  }
  .itemContainer {
    width: 100%;
  }

  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .items li {
    padding: 0 0 0 10px;
  }
  .list {
    padding: 0 0 0 10px;
  }
}
</style>