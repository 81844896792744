<template>
  <section>
    <h1>Clientes</h1>

    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Adicionar Tipo de Cliente</h3>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'clientes' }"
              >Lista de Clientes</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'tipos-clientes' }"
              >Lista de Tipos de Clientes</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Adicionar Tipo de Cliente</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingClient">
        <Loading />
      </div>
      <div v-else class="containerForm">
        <TypeClientForm :errors="errors">
          <button class="btn" @click.prevent="addTypeClient">Adicionar</button>
        </TypeClientForm>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import TypeClientForm from "@/components/client/TypeClientForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddClient",
  props: ["idTypeClient"],
  components: {
    TypeClientForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("client", [
      "typeClient",
      "loadingClient",
      "showModalDeleteTypeClient",
    ]),
    ...mapGetters("user", ["clientEdit"]),
    ...mapGetters("client", ["typeClientReset", "typeClientReply"]),
  },
  methods: {
    addTypeClient() {
      this.$store.dispatch("client/addTypeClient", {
        typeClient: this.typeClientReply,
      });
    },
  },
  created() {
    this.$store.commit("client/UPDATE_TYPECLIENT", this.typeClientReset);
    this.$store.commit("RESET_ERRORS", []);
  },
};
</script>

<style scoped>
.breadcrumb {
  margin-left: -10px;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

@media screen and (max-width: 690px) {
  .containerTelecom {
    grid-template-columns: 1fr;
    justify-items: flex-start;
    grid-gap: 10px;
    margin: 30px 10px;
  }
  .containerForm {
    width: 100%;
  }
}
</style>