<template>
  <form action="">
    <div class="container-topSearch">
      <div class="searchContainer">
        <!-- <div class="searchContainer formInfos"> -->
        <div>
          <label for="oppositeSearch">Interveniente:</label>
          <div class="searchLupa">
            <input
              type="text"
              name="oppositeSearch"
              id="oppositeSearch"
              v-model="oppositeSearch"
              autocomplete="off"
              placeholder="Interveniente..."
              @keyup.enter="searchProcess"
            />
            <!-- @click="event" -->
            <span id="lupa"></span>
          </div>
        </div>
        <div>
          <label for="numberSearch"> Nº do Processo: </label>
          <div class="searchLupa">
            <input
              type="text"
              name="numberSearch"
              id="numberSearch"
              v-model="numberSearch"
              autocomplete="off"
              placeholder="Nº do Processo..."
              @keyup.enter="searchProcess"
            />
            <!-- @click="event" -->
            <span id="lupa"></span>
          </div>
        </div>
        <div>
          <label for="valueSearch">Valor: </label>
          <div class="searchLupa">
            <input
              type="number"
              name="valueSearch"
              id="valueSearch"
              v-model.number="valueSearch"
              autocomplete="off"
              placeholder="Valor..."
              @keyup.enter="searchProcess"
            />
            <!-- @click="event" -->
            <!-- <span id="lupa"></span> -->
          </div>
        </div>
        <div>
          <label for="courtSearch">Tribunal: </label>
          <div class="searchLupa">
            <input
              type="text"
              name="courtSearch"
              id="courtSearch"
              v-model="courtSearch"
              autocomplete="off"
              placeholder="Tribunal..."
            />
            <!-- @click="event" -->
            <span id="lupa"></span>
          </div>
        </div>
        <div>
          <label for="arSearch">Posição: </label>
          <div class="searchLupa">
            <input
              type="text"
              name="arSearch"
              id="arSearch"
              v-model="arSearch"
              autocomplete="off"
              placeholder="Posição..."
              @keyup.enter="searchProcess"
            />
            <!-- @click="event" -->
            <span id="lupa"></span>
          </div>
        </div>
        <div>
          <label for="typeProcessSearch">Tipo de Processo: </label>
          <div class="searchLupa">
            <input
              type="text"
              name="typeProcessSearch"
              id="typeProcessSearch"
              v-model="typeProcessSearch"
              autocomplete="off"
              placeholder="Tipo de Processo..."
              @keyup.enter="searchProcess"
            />
            <!-- @click="event" -->
            <span id="lupa"></span>
          </div>
        </div>
        <div>
          <!-- <div class="dataSearch"> -->
          <label for="dateProcess"> Data Início: </label>
          <div class="searchLupa">
            <input
              id="dateProcess"
              type="date"
              v-model="dateProcess"
              autocomplete="off"
              @keyup.enter="searchProcess"
            />
          </div>
        </div>
        <div class="buttonSearch">
          <div
            class="button"
            @keyup.enter="searchProcess"
            @click.prevent="searchProcess"
          >
            <BtnSearch />
          </div>
          <div class="button btnReset" @click.prevent="reset" @keyup.esc="reset">
            <BtnReset />
          </div>
        </div>
      </div>
      <!-- {{filterItem}} -->
      <ul class="searchActiveContainer" v-if="filterItem">
        <div class="filterActive" v-if="filterItem">Filtro activo:</div>
        <article v-if="oppositeSearch" class="searchActive">
          <div>Interveniente...</div>
          <div class="closeSearch" @click.prevent="closeOppositeSearch">x</div>
        </article>
        <article v-if="numberSearch" class="searchActive">
          <div>Nº do Processo...</div>
          <div class="closeSearch" @click.prevent="closeNumberSearch">x</div>
        </article>
        <article v-if="valueSearch" class="searchActive">
          <div>Valor...</div>
          <div class="closeSearch" @click.prevent="closeValueSearch">x</div>
        </article>
        <article v-if="courtSearch" class="searchActive">
          <div>Tribunal...</div>
          <div class="closeSearch" @click.prevent="closeCourtSearch">x</div>
        </article>
        <article v-if="arSearch" class="searchActive">
          <div>Posição...</div>
          <div class="closeSearch" @click.prevent="closeArSearch">x</div>
        </article>
        <article v-if="typeProcessSearch" class="searchActive">
          <div>Tipo de Processo...</div>
          <div class="closeSearch" @click.prevent="closeTypeProcess">x</div>
        </article>
        <article v-if="dateProcess" class="searchActive">
          <div>Data de Início...</div>
          <div class="closeSearch" @click.prevent="closeStartDate">x</div>
        </article>
      </ul>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  
  computed: {
    ...mapState(["filterItem"]),
    ...mapState("legal", [
      "oppositeSearch",
      "numberSearch",
      "valueSearch",
      "courtSearch",
      "arSearch",
      "typeProcessSearch",
      "dateProcess",
    ]),
    // msgFilter() {
    //   return this.$store.commit("legal/UPDATE_FILTER_ITEM", this.oppositeSearch != null ||
    //     this.numberSearch != null ||
    //     this.valueSearch != null ||
    //     this.courtSearch != null ||
    //     this.arSearch != null ||
    //     this.typeProcessSearch != null ||
    //     this.dateProcess != null)
    // },
    oppositeSearch: {
      get() {
        return this.$store.state.legal.oppositeSearch;
      },
      set(value) {
        this.$store.commit("legal/UPDATE_OPPOSITE_SEARCH", value);
      },
    },
    numberSearch: {
      get() {
        return this.$store.state.legal.numberSearch;
      },
      set(value) {
        this.$store.commit("legal/UPDATE_NUMBER_SEARCH", value);
      },
    },
    valueSearch: {
      get() {
        return this.$store.state.legal.valueSearch;
      },
      set(value) {
        this.$store.commit("legal/UPDATE_VALUE_SEARCH", value);
      },
    },
    courtSearch: {
      get() {
        return this.$store.state.legal.courtSearch;
      },
      set(value) {
        this.$store.commit("legal/UPDATE_COURT_SEARCH", value);
      },
    },
    arSearch: {
      get() {
        return this.$store.state.legal.arSearch;
      },
      set(value) {
        this.$store.commit("legal/UPDATE_AR_SEARCH", value);
      },
    },
    typeProcessSearch: {
      get() {
        return this.$store.state.legal.typeProcessSearch;
      },
      set(value) {
        this.$store.commit("legal/UPDATE_TYPEPROCESS_SEARCH", value);
      },
    },
    dateProcess: {
      get() {
        return this.$store.state.legal.dateProcess;
      },
      set(value) {
        this.$store.commit("legal/UPDATE_DATEPROCESS", value);
      },
    },
  },
  methods: {
    msgFilter() {
      return this.$store.commit("UPDATE_FILTER_ITEM", this.oppositeSearch != null ||
        this.numberSearch != null ||
        this.valueSearch != null ||
        this.courtSearch != null ||
        this.arSearch != null ||
        this.typeProcessSearch != null ||
        this.dateProcess != null)
    },
    closeOppositeSearch() {
      this.$store.commit("legal/UPDATE_OPPOSITE_SEARCH", null);
      this.searchProcess();
    },
    closeNumberSearch() {
      this.$store.commit("legal/UPDATE_NUMBER_SEARCH", null);
      this.searchProcess();
    },
    closeValueSearch() {
      this.$store.commit("legal/UPDATE_VALUE_SEARCH", null);
      this.searchProcess();
    },
    closeCourtSearch() {
      this.$store.commit("legal/UPDATE_COURT_SEARCH", null);
      this.searchProcess();
    },
    closeArSearch() {
      this.$store.commit("legal/UPDATE_AR_SEARCH", null);
      this.searchProcess();
    },
    closeTypeProcess() {
      this.$store.commit("legal/UPDATE_TYPEPROCESS_SEARCH", null);
      this.searchProcess();
    },
    closeStartDate() {
      this.$store.commit("legal/UPDATE_DATEPROCESS", null);
      this.searchProcess();
    },
    reset() {
      this.$store.commit("legal/RESET_FILTER", null);
      this.$store.commit("UPDATE_CURRENTPAGE", 1);
      this.msgFilter()
      this.updateQuert()
      // this.$router.replace({ query: {} }).catch(() => {});
      // console.log(Object.entries(this.$router.currentRoute.query).length > 0);
      if(this.$router.currentRoute.query.archived == false && Object.entries(this.$router.currentRoute.query).length > 1){
        this.$router.push({ query: {archived:false} });
      }else if(this.$router.currentRoute.query.archived == true && Object.entries(this.$router.currentRoute.query).length > 1){
        this.$router.push({ query: {archived:true} });
      }else if(this.$router.currentRoute.query.archived == undefined && Object.entries(this.$router.currentRoute.query).length > 0){
        this.$router.replace({ query: {} }).catch(() => {});
      }
      // if (Object.entries(this.$router.currentRoute.query).length != 1) {
      //   this.$router.push({ query: {archived:false} });
      // }
      // this.$store.commit("UPDATE_BTNFILTER", false)
    },
    updateQuert(){
       const filter = {
          archived: this.$route.query.archived || null,
          oppositeSearch: this.oppositeSearch,
          numberSearch: this.numberSearch,
          valueSearch: this.valueSearch,
          courtSearch: this.courtSearch,
          arSearch: this.arSearch,
          typeProcessSearch: this.typeProcessSearch,
          dateProcess: this.dateProcess,
          page: null,
          // page: Number(this.$route.query.page),
        };
        const query = Object.entries(filter).reduce((acc, [key, val]) => {
          if (!val) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$router.replace({ query: { ...query } }).catch(() => {});
    },
    searchProcess() {    
      // this.$router.push({ query: { page: 1 } }) 
      this.$store.commit("UPDATE_CURRENTPAGE", 1); 
      this.$store.commit("legal/FILTERED_PROCESS", {
        oppositeSearch: this.oppositeSearch,
        numberSearch: this.numberSearch,
        valueSearch: this.valueSearch,
        courtSearch: this.courtSearch,
        arSearch: this.arSearch,
        typeProcessSearch: this.typeProcessSearch,
        dateProcess: this.dateProcess,
      });
      this.msgFilter();
      this.updateQuert();
    },
  },
  created(){
    this.msgFilter();
  }
};
</script>

<style scoped>
input{
  width: 100%;
}
input#dateProcess {
  display: flex;
  width: 100%;
}
.searchLupa {
  position: relative;
  margin-top: 10px;
}
.container-topSearch {
  display: grid;
  /* flex-wrap: wrap; */
  /* grid-template-columns: minmax(300px, 400px); */
  /* grid-template-columns: repeat(2, 1fr); */
  width: 100%;
  padding: 0 10px 40px 0;
  justify-content: center;
}

#search {
  width: 100%;
}
.searchContainer {
  /* position: relative; */
  margin-top: 20px;
  display: grid;
  /* max-width: 400px; */
  /* grid-template-columns: 350px repeat(2, 200px) auto; */
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  align-items: flex-end;
}

.buttonSearch {
  display: flex;
  grid-column: 3;
  justify-content: flex-end;
}
.btnReset {
  margin-right: -5px;
}
#lupa {
  width: 62px;
  height: 46px;
  background: url("../../assets/svg/search2.svg") no-repeat center center;
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;
}

.searchActiveContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0 30px 0;
  grid-gap: 10px;
  font-size: 0.8rem;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #373946;
  padding: 10px;
  border-radius: 4px;
  /* box-shadow: 0 4px 8px rgb(31 43 66 / 20%); */
  border-radius: 4px;
  border: 1px solid rgba(31, 43, 66, 0.4);
  /* justify-content: center; */
  justify-content: flex-start;
  max-width: 700px;
}
.searchActive {
  display: grid;
  grid-template-columns: minmax(60px, auto) 25px;
  grid-gap: 5px;
  justify-content: center;
  /* box-shadow: 0 4px 8px rgb(31 43 66 / 20%); */
  /* max-width: 320px; */
  border-radius: 4px;
  border: 1px solid rgba(31, 43, 66, 0.4);
  padding: 10px;
  transition: all 0.3s;
  outline: none;
}
.closeSearch {
  padding: 0 10px;
  /* padding: 0 10px; */
  border-left: 1px solid #ccc;
  cursor: pointer;
  font-weight: bold;
}
@media (min-width: 500px) and (max-width: 830px) {
  .searchContainer {
    grid-template-columns: repeat(2, 1fr);
    
  }
  .buttonSearch {
    grid-column: 2;
  }
}
@media screen and (max-width: 500px) {
  .searchContainer {
    grid-template-columns: 1fr;
    padding: 0 10px;
  }
  .searchActiveContainer{
    margin: 10px 10px;
  }
  .buttonSearch {
    grid-column: 1;
  }
}
</style>