import { render, staticRenderFns } from "./ProcessTypeItem.vue?vue&type=template&id=70c23424&scoped=true&"
import script from "./ProcessTypeItem.vue?vue&type=script&lang=js&"
export * from "./ProcessTypeItem.vue?vue&type=script&lang=js&"
import style0 from "./ProcessTypeItem.vue?vue&type=style&index=0&id=70c23424&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "70c23424",
  null
  
)

export default component.exports