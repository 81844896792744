<template>
  <section>
    <h1>Serviços</h1>
    <div class="contentGeneral">
      <h3>Adicionar Serviço</h3>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'servicos' }"
              >Lista de Serviços</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Adicionar Serviços</span>
          </li>
        </ol>
      </nav>
      <div v-if="wait">
        <Loading />
      </div>
      <div v-else>
        <ServiceForm :service="service" :errors="errors">
          <button class="btn btn-form" @click.prevent="addService">
            Adicionar
          </button>
        </ServiceForm>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import ServiceForm from "@/components/service/ServiceForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ServiceAdd",
  components: {
    ServiceForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("service", ["service"]),
    ...mapGetters("service", ["serviceReset", "serviceReply"]),
  },
  methods: {
    addService() {
      this.$store.dispatch("service/addService", {
        service: this.serviceReply, //enviar getters com as infos necessárias para o cadastro
      });
    },
  },
  created() {
    this.$store.commit("RESET_ERRORS", [], { root: true });
    this.$store.commit("service/UPDATE_SERVICE", this.serviceReset);
  },
};
</script>

<style scoped>
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
</style>
