<template>
  <form action="">
    <div v-if="wait">
      <Loading />
    </div>
    <div v-else>
      <div class="formItens">
        <label for="Name"> Nome </label>
        <input
          :class="[validateFull, validateRepeat]"
          id="Name"
          name="Name"
          type="text"
          v-model="name"
        />
      </div>
      <div v-if="!errors.indexOf('full')" class="notify">
        O nome é um campo requerido
      </div>
      <div v-if="!errors.indexOf('nameRepeat')" class="notify">
        O nome já existe em outro serviço
      </div>
      <div class="formItens">
        <label for="description"> Descrição </label>
        <input
          :class="[validateFull, validateDescription]"
          id="description"
          name="description"
          type="text"
          v-model="description"
        />
      </div>
      <div
        v-if="!errors.indexOf('full') || !errors.indexOf('description')"
        class="notify"
      >
        A descrição é um campo requerido
      </div>
    </div>
    <div class="formItens">
      <label for="active"> Activo </label>
      <BtnChoice v-model="active" />
    </div>
    <div class="formItens">
      <label for="active"> Serviço Padrão </label>
      <BtnChoice v-model="isDefault" />
    </div>

    <div class="button">
      <slot></slot>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  props: ["errors"],
  computed: {
    ...mapState(["wait"]),
    ...mapState("service", ["service"]),
    validateFull() {
      return this.errors.indexOf("full") ? "true" : "validate";
    },
    validateDescription() {
      return this.errors.indexOf("description") ? "true" : "validate";
    },
    validateRepeat() {
      return this.errors.indexOf("nameRepeat") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["name", "description", "active", "isDefault"], //nome dos campos
      module: "service", //qual módulo está acessando
      base: "service", //colocar o state que vai receber
      mutation: "service/UPDATE_SERVICE", //nome da mutation que vai alterar o state
    }),
  },
  created() {
    // this.$store.commit("RESET_ERRORS", [], { root: true });
  },
};
</script>

<style scoped>
.icon-add {
  fill: #fff;
  /* stroke: #fff; */
  z-index: 99;
}
form {
  display: grid;
  grid-template-columns: minmax(300px, 800px);
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
  padding: 0 20px;
}

.formItens {
  grid-template-columns: 130px 1fr;
}

.button {
  display: grid;
  grid-template-columns: 300px;
  padding: 20px;
  /* grid-column:1fr 1fr; */
  justify-content: center;
  margin: 20px auto;
}

.buttonAdd {
  display: grid;
  justify-content: center;
  align-items: center;
  /* margin-left: 60px; */
  background-color: #00bf48;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin: 0 15px;
  overflow: hidden;
  /* fill: #fff; */
  color: #ffffff;
}

li.items {
  display: grid;
  grid-template-columns: 105px 1fr;
  margin-top: 20px;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

.formInfos2,
.formInfos {
  display: grid;
  grid-template-columns: minmax(280px, 550px) 100px;
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  margin-top: 20px;
  align-content: center;
}

@media screen and (max-width: 560px) {
  li.items,
  .formItens {
    grid-template-columns: 1fr !important;
  }
  .button {
    grid-template-columns: 1fr !important;
  }
  .notifyCenter {
    /* justify-items: center; */
    color: #dc3545;
    font-size: 1.2rem;
    margin-bottom: 15px;
    margin-left: 85px;
  }

  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: 10px;
  }
}
</style>