<template>
  <section>
    <h1>Central Telefónica</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Lista de Redirecionamentos</h3>
        <!-- <div class="menuDropdown" v-if="staffEdit"> -->
        <div class="menuDropdown" v-if="telecomManager">
          <!-- <AddUser>
            <span slot="title">
              Adicionar <br />
              Contactos</span
            >
            <ul slot="itemsMenu" class="menuDropdownContent">
              <div class="titleMenuDropdown">Redirecionamento</div>
              <li @click="openModalCreateContact">Adicionar</li>
            </ul>
          </AddUser> -->
          <router-link
            class="collabItens"
            :to="{
              name: 'criar-redirecionamento',
            }"
          >
            <AddUser>
              <span slot="title">
                Adicionar<br />
                Redirecionamento</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>

      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'historico-chamadas' }"
              >Histórico de Chamadas</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Lista de Redirecionamentos</span>
          </li>
        </ol>
      </nav>
      <article>
        <div v-if="loadingVariant">
          <Loading />
        </div>
        <div v-else>
          <div v-if="variantPhones">
            <div class="titleCollab">
              <p class="titleBlue">Descrição</p>
              <p class="titleBlue">Telefone</p>
            </div>
            <hr />
            <ul v-for="variantPhone in variantPhones" :key="variantPhone.id">
              <li class="itemList">
                <VariantItem
                  :variantPhone="variantPhone"
                  :idVariant="variantPhone.id"
                >
                  <button
                    slot="delete"
                    class="delete"
                    @click.prevent="confirmDeleteVariant(variantPhone.id)"
                  ></button>
                </VariantItem>
              </li>
            </ul>
          </div>
          <div v-else>
            <p class="noResults">Não possui colaboradores cadastrados</p>
          </div>
        </div>
      </article>
    </div>

    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalVariant"
        @click="closeModalDelVariant"
      ></div>
      <div class="modalDel_container" v-if="showModalVariant">
        <button class="close btn" @click="closeModalDelVariant">x</button>
        <p class="delItem">
          <span
            >Você tem certeza que deseja remover este Redirecionamento?</span
          >
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelVariant"
          />
          <button class="btnDel" @click.prevent="deleteVariant">Delete</button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

// <script>
// import VariantForm from "@/components/telecom/VariantForm.vue";
import VariantItem from "@/components/telecom/VariantItem.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "VariantPhone",
  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId"]),
    ...mapState("user", ["user"]),
    ...mapState("telecom", [
      "variantPhones",
      "variantPhone",
      "loadingVariant",
      "configPhoneReply",
      "config",
      "showModalVariant",
    ]),
    ...mapGetters("user", ["telecomManager"]),
    ...mapGetters("telecom", ["telecomPhoneReset", "telecomPhoneReply"]),
  },
  components: {
    VariantItem,
    // VariantForm,
  },
  methods: {
    openModalDelVariant() {
      this.$store.dispatch("telecom/openModalDelVariant");
    },
    closeModalDelVariant() {
      this.$store.dispatch("telecom/closeModalDelVariant");
    },
    //######################################################
    //     DELETE
    //######################################################
    confirmDeleteVariant(id) {
      this.$store.dispatch("telecom/confirmDeleteVariant", {
        id: `${id}`,
      });
    },
    deleteVariant() {
      this.$store.dispatch("telecom/deleteVariant", {
        id: this.selectedId.id,
      });
      this.closeModalDelVariant();
    },
  },
  created() {
    this.$store.dispatch("telecom/getVariantPhones");
  },
};
</script>

<style scoped>
.modal_container {
  max-width: 450px;
}
.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

.titleCollab {
  display: grid;
  /* grid-template-columns: repeat(10, 130px); */
  /* grid-template-columns: 200px 150px 70px 90px repeat(2, 100px); */
  grid-template-columns: minmax(200px, 380px) 1fr 130px;
}

.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  /* margin-bottom: 30px; */
}

.titleBlue {
  margin: 0 5px;
}
h3 {
  text-align: initial;
  /* margin-left: 10px; */
}
@media screen and (max-width: 690px) {
  .titleBlue {
    display: none;
  }
  .containerTelecom {
    margin: 80px 10px 20px 10px;
  }
}
</style>
