<template>
  <div class="btnAdd">
    <div class="circle">
      <input id="filter" type="checkbox" name="" value="" v-model="btnFilter" />
      <label for="filter">
        <svg
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect
            class="fltr-line1"
            x="7"
            y="10"
            width="18"
            height="2"
            fill="#28a745"
          />
          <rect
            class="fltr-line2"
            x="7"
            y="20"
            width="18"
            height="2"
            fill="#28a745"
          />
          <circle
            class="fltr-crcl1"
            cx="13"
            cy="21"
            r="3"
            fill="white"
            stroke="#28a745"
          />
          <circle
            class="fltr-crcl2"
            cx="19"
            cy="11"
            r="3"
            fill="white"
            stroke="#28a745"
          />
        </svg>
        <slot name="filtro" />
      </label>
    </div>
    <!-- <span class="titleAdd">
      <slot />
    </span> -->
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["btnFilter"]),
  },
};
</script>

<style scoped>
label svg * {
  transition: 0.15s cubic-bezier(0.35, 0.35, 0, 1);
}
label circle {
  will-change: transform;
}
label rect {
  transform-origin: 50% 50%;
}
label:hover .fltr-crcl1 {
  transform: translateX(6px);
}
label:hover .fltr-crcl2 {
  transform: translateX(-6px);
}
label {
  opacity: 1;
}
input[type="checkbox"]:checked + label circle {
  opacity: 0;
}
input[type="checkbox"]:checked + label .fltr-line1 {
  transform: rotate(45deg) translateY(5.5px);
}
input[type="checkbox"]:checked + label .fltr-line2 {
  transform: rotate(-45deg) translateY(-4.5px);
}

input#filter {
  display: none;
}

label {
  display: flex;
  justify-items: center;
  align-items: center;
  padding: 0;
  margin: 0;
  cursor: pointer;
}

input[type="checkbox"]:checked + label .icon-add {
  fill: #fff;
}

.titleAdd {
  font-size: 12px;
}

.icon-add {
  fill: #28a745;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 2px solid #28a745; */
  /* width: 50px;
  height: 50px; */
  border-radius: 9px;
  padding: 2px 9px;
  cursor: pointer;
  margin-right: 5px;
  color: #28a745;
}

.circle:hover .icon-add {
  fill: #fff;
}
.circle:hover {
  /* border: 2px solid #28a745; */
  border-radius: 9px;
  cursor: pointer;
  margin-right: 5px;
}
.btnAdd {
  display: flex;
  /* max-width: fit-content; */
  align-items: center;
  cursor: pointer;
  /* padding: 10px; */
}
</style>