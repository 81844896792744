import { api } from "@/services.js";
import router from "@/router";
import { removeAccent } from "@/helpers.js";
import { formatDate } from "@/helpers.js";

export default {
  namespaced: true,
  state: {
    //############################
    //       ADDRESS            
    //############################
    addresses: null,
    address: {
      street: null,
      streetNumber: null,
      city: null,
      state: null,
      country: null,
      postalCode: null,
      complement: null,
      reference: null,
      alias: null,
      danger: false,
      location: null,
    },    
    //############################
    //         LOADING            
    //############################
    loadingAddress: false,
    //############################
    //  PESQUISA
    //############################
    filtered:null,
    searchWord: null,
    addressSearch: null,
    numberSearch: null,
    citySearch: null,
    stateSearch: null,
    postalCodeSearch: null,
    countrySearch: null,
    dangerSearch: null,

    //############################
    //  MODAL            
    //############################
    showModalAddress: false,
    showModalDelAddress: false,
    //############################
    //  MAP            
    //############################
    googleMap:null,
    partAddress:{
      partAddress: null,
    },
    locationAddress: {
        locationAddress: null,
    },
    confirmForm:false,

  },
  //########################################################################################
  //                 GETTERS            
  //########################################################################################
  getters: {
    getFiltered: (state) => state.filtered,
    //############################
    //          ADDRESS            
    //############################
    addressReply(state) {
      return {
        "street": state.address.street,
        "streetNumber": state.address.streetNumber,
        "city": state.address.city,
        "state": state.address.state,
        "country": state.address.country,
        "postalCode": state.address.postalCode,
        "complement": state.address.complement,
        "reference": state.address.reference,
        "alias": state.address.alias,
        "danger": state.address.danger,
        "location": state.address.location,
      }
    },
    addressReset() {
      return {
        street: null,
        streetNumber: null,
        city: null,
        state: null,
        country: null,
        postalCode: null,
        complement: null,
        reference: null,
        alias: null,
        danger: false,
        location: null,
      }
    },
    partAddressReply(state) {
      return {
        "partAddress": state.partAddress.partAddress,
      }
    },
    partAddressReset() {
      return {
        partAddress: null,
      }
    },

    locationAddressReply(state) {
        return {
          "locationAddress": state.locationAddress.locationAddress,
        }
    },
    locationAddressReset() {
        return {
            locationAddress: null,
        }
    },
    //###############
    //  FILTRO
    //###############
    filteredAddresses(state, getters) {   
        return getters.getFiltered || state.addresses;
      },
    //###############
    //  PAGINAÇÃO
    //###############
    paginatedData(state, getters, rootState) {
      // console.log("rootState.currentPage", rootState.currentPage)//acessando o state da global
      let start = (rootState.currentPage - 1) * rootState.limit,
        end = start + rootState.limit;
      if (state.filtered) {
        return getters.filteredAddresses.slice(start, end);
      } else {
        return getters.filteredAddresses.slice(start, end);
      }
    },
  },
  mutations: {

    //########################### 
    //    LOADING
    //###########################
    UPDATE_LOADING(state, payload) {
      state.loadingAddress = payload;
    },
    //########################### 
    //    MAP
    //###########################
    UPDATE_GOOGLEMAP(state, payload) {
      state.googleMap = payload;
    },
    UPDATE_LOCATION_ADDRESS(state, payload) {
    state.locationAddress = Object.assign(state.locationAddress, payload);
    },    
    UPDATE_SEARCH_CITY(state, payload) {
      // console.log(payload)
      state.partAddress = Object.assign(state.partAddress, payload);
    },
    UPDATE_CONFIRMFORM(state, payload) {
        state.confirmForm = payload;
    },
    
    //################################################################# 
    //       ADDRESS
    //#################################################################
    UPDATE_ADDRESSES(state, payload) {
      state.addresses = payload
    },
    UPDATE_ADDRESS(state, payload) {
      state.address = Object.assign(state.address, payload);
    },
    UPDATE_DELETE_EMAILS(state, serviceId) {
      let services = state.services.filter(c => c.id != serviceId)
      state.services = services;
    },
    UPDATE_MODAL_ADDRESS(state, payload) {
      state.showModalAddress = payload;
    },
    UPDATE_MODAL_DEL_ADDRESS(state, payload) {
      state.showModalDelAddress = payload;
    },    
    UPDATE_SELECTED_ID(state, payload) {
      state.selectedId = payload;
    },
    UPDATE_DELETE_ADDRESS(state, addressesId) {
      let addresses = state.addresses.filter(c => c.id != addressesId)
      state.addresses = addresses;
    },
    //###############
    //  PESQUISA
    //###############
    
    RESET_FILTER(state, clean) {
      state.searchWord = clean;
      state.filtered = clean;
      state.addressSearch = clean;
      state.numberSearch = clean;
      state.citySearch = clean;
      state.stateSearch = clean;
      state.postalCodeSearch = clean;
      state.countrySearch = clean;
      state.dangerSearch = clean;
    },
    UPDATE_ADDRESS_SEARCH(state, payload) {
      state.addressSearch = payload;
    },
    UPDATE_NUMBER_SEARCH(state, payload) {
      state.numberSearch = payload;
    },
    UPDATE_CITY_SEARCH(state, payload) {
      state.citySearch = payload;
    },
    UPDATE_STATE_SEARCH(state, payload) {
      state.stateSearch = payload;
    },
    UPDATE_POSTAL_SEARCH(state, payload) {
      state.postalCodeSearch = payload;
    },
    UPDATE_COUNTRY_SEARCH(state, payload) {
      state.countrySearch = payload;
    },
    UPDATE_DANGER_SEARCH(state, payload) {
      state.dangerSearch = payload;
    },
    FILTERED_ADDRESS(state, {addressSearch, numberSearch, citySearch,stateSearch, postalCodeSearch, countrySearch, dangerSearch}) {
      state.filtered = [];

    if ((addressSearch == null || addressSearch == "") && 
      (numberSearch == null || numberSearch == "") && 
      (citySearch == null || citySearch == "") &&
      (stateSearch == null || stateSearch == "") &&
      (postalCodeSearch == null || postalCodeSearch == "") &&
      (countrySearch == null || countrySearch == "") &&
      (dangerSearch == null  || dangerSearch == "")) {
        state.filtered = null
      } else {  
          let wordAddress = state.addressSearch != null ? removeAccent(addressSearch.trim().toLowerCase()): null,
                wordNumber = state.numberSearch != null ? numberSearch : null,
                wordCity = state.citySearch != null ? removeAccent(citySearch.trim().toLowerCase()): null,
                wordState = state.stateSearch != null ? removeAccent(stateSearch.trim().toLowerCase()): null,
                wordPostalCode = state.postalCodeSearch != null ? removeAccent(postalCodeSearch.trim().toLowerCase()): null,
                wordCountry = state.countrySearch != null ? removeAccent(countrySearch.trim().toLowerCase()): null,
                wordDanger = state.dangerSearch != null ? dangerSearch : null

            state.filtered = state.addresses
            .filter(address => {
                var searchAddress = [
                    {
                        item: addressSearch,
                        code: "hasAddress",
                    },
                    {
                        item: numberSearch,
                        code: "hasNumber",
                    },
                    {
                        item: citySearch,
                        code: "hasCity",
                    },
                    {
                        item: stateSearch,
                        code: "hasState",
                    },
                    {
                        item: postalCodeSearch,
                        code: "hasPostalCode",
                    },
                    {
                        item: countrySearch,
                        code: "hasCountry",
                    },
                    {
                        item: dangerSearch,
                        code: "hasDanger",
                    },
                ]
                
                let hasAddress = false;
                let hasNumber = false;
                let hasCity = false;
                let hasState = false;
                let hasPostalCode = false;
                let hasCountry = false;
                let hasDanger = false
     

                if(address.street != null){              
                    hasAddress = removeAccent(address.street.toLowerCase().trim()).includes(wordAddress)
                }
                if(address.streetNumber != null){              
                    hasNumber = address.streetNumber.toString().trim().includes(wordNumber) 
                }
                if(address.city != null){              
                    hasCity = removeAccent(address.city.toLowerCase().trim()).includes(wordCity)
                }
                if(address.state != null){              
                    hasState = removeAccent(address.state.toLowerCase().trim()).includes(wordState)
                }                
                if(address.postalCode != null){              
                    hasPostalCode = removeAccent(address.postalCode.toLowerCase().trim()).includes(wordPostalCode)
                }                     
                if(address.country != null){              
                    hasCountry = removeAccent(address.country.toLowerCase().trim()).includes(wordCountry)
                }                               
                if(address.danger != null){              
                    hasDanger = address.danger == wordDanger
                }

                let itemsAddress = null
                for (let index in searchAddress) {
                // console.log(searchAddress[index])
                    if (searchAddress[index].item != null) {
                        if (itemsAddress == null) {                  
                        itemsAddress = eval(searchAddress[index].code)
                        // console.log("itemsAddress", itemsAddress)
                        }
                        else {
                        itemsAddress = itemsAddress + '' + `&&` + '' + eval(searchAddress[index].code)
                        }
                    }
                }
                // console.log("eval", eval(itemsAddress))
                return eval(itemsAddress) 
                // (address.street != null && removeAccent(address.street.toLowerCase().trim()).includes(word)) ||
                // ((address.streetNumber != null && address.streetNumber.toString().trim()).includes(word) ) ||
                // (address.postalCode != null && removeAccent(address.postalCode.toLowerCase().trim()).includes(word)) ||
                // (address.city != null && removeAccent(address.city.toLowerCase().trim()).includes(word)) ||
                // (address.state != null && removeAccent(address.state.toLowerCase().trim()).includes(word))
            })
            // console.log(state.filtered)
    }    

    },
  },
  actions: {
    getFilterURL(context){        
        // console.log("filtro ativo")
        context.commit("FILTERED_ADDRESS", {
          addressSearch: context.state.addressSearch || null,
          numberSearch: context.state.numberSearch || null,
          citySearch: context.state.citySearch || null,
          stateSearch: context.state.stateSearch || null,
          postalCodeSearch: context.state.postalCodeSearch || null,
          countrySearch: context.state.countrySearch || null,
          dangerSearch: context.state.dangerSearch || null,
        })
        let filterActive = (context.state.addressSearch == null || context.state.addressSearch == "") && 
        (context.state.numberSearch == null || context.state.numberSearch == "") && 
        (context.state.citySearch == null || context.state.citySearch == "") &&
        (context.state.stateSearch == null || context.state.stateSearch == "") &&
        (context.state.postalCodeSearch == null || context.state.postalCodeSearch == "") &&
        (context.state.countrySearch == null || context.state.countrySearch == "") &&
        (context.state.dangerSearch == null || context.state.dangerSearch == "") ? false : true;
        context.commit("UPDATE_BTNFILTER", filterActive, {root: true})
        context.commit("UPDATE_LOADING", false);
      },
    getAddresses(context, refresh) {
        console.log(router.currentRoute.query.stateSearch)
        context.commit("UPDATE_ADDRESS_SEARCH", router.currentRoute.query.addressSearch || null);
        context.commit("UPDATE_NUMBER_SEARCH", Number(router.currentRoute.query.numberSearch) || null);
        context.commit("UPDATE_CITY_SEARCH", router.currentRoute.query.citySearch || null);
        context.commit("UPDATE_STATE_SEARCH", router.currentRoute.query.stateSearch || null);
        context.commit("UPDATE_POSTAL_SEARCH", router.currentRoute.query.postalCodeSearch || null);
        context.commit("UPDATE_COUNTRY_SEARCH", router.currentRoute.query.countrySearch || null);
        context.commit("UPDATE_DANGER_SEARCH", router.currentRoute.query.dangerSearch || null);
      context.commit("UPDATE_LOADING", true);
      if (context.state.addresses === null || refresh != undefined) {
        api.get(`/address/`)
          .then(
            response => {
              const addresses = response.data.map(function (address) {
                address.created = formatDate(new Date(address.created));
                address.modified = formatDate(new Date(address.modified));
                return address;
              });
              // console.log(address)
              context.commit("UPDATE_ADDRESSES", addresses);
              context.dispatch("getFilterURL");
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_ADDRESSES", context.state.addresses);
        context.commit("UPDATE_LOADING", false);
      }
    },
    //##################################################
    //    ADDRESS - INFORMAÇÕES - ACTIONS
    //##################################################
   
    getAddress(context, { idAddress }) {
      context.commit("UPDATE_LOADING", true);
      api.get(`/address/${idAddress}`)
        .then(
          response => {
            const address = {}
            Object.keys(response.data).filter(key => {
              // ##### retornando todas as datas formatadas
              return ((key === "created") && (address[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (address[key] = formatDate(new Date(response.data[key])))) ||
                (address[key] = response.data[key])
            })           
            context.commit("UPDATE_ADDRESS", address);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //########################################################################
    //            ADICIONAR ADDRESS
    //########################################################################

    addAddress(context, { address, idModule, mutation }) { //usando context por ter tanto "commit" quanto "dispatch" 
      console.log(address)
      // console.log("mutation", mutation)
      context.commit("UPDATE_GOOGLEMAP", null);
      context.commit("UPDATE_LOADING_ADDRESS", true, { root: true });
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      if (address.street == null || address.street == "") {
        context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
        context.commit("UPDATE_ERRORS", "full", { root: true });
      } else {
        api.post(`/address/`, address)
          .then(response => {
            // console.log("response", response)
            let idAddress = response.data.id
            if (mutation != null && mutation != "") {
              context.dispatch(eval(mutation), { idModule, idAddress }, { root: true })//eval() pegar o nome da constante e não o valor da sua variável.
              context.commit("UPDATE_MODAL_ADDRESS", false);
            }
            context.dispatch("getAddresses", "refresh");
            if(mutation == undefined ){
              router.push({ name: "moradas"})
            }
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Morada adicionada com sucesso!",
            }, { root: true });
            context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
            context.commit("UPDATE_ADDRESS", this.getters["address/addressReset"]);//limpa o formulário depois de cadastrar     
          })
          .catch(error => {
            context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
            console.log(error.response.data)
            
            if (error.response.data.error === "Invalid Request - No street") {
              context.commit("UPDATE_ERRORS", "street", { root: true });
            }
            if (error.response.data.error === "Invalid Request - No streetNumber") {
              context.commit("UPDATE_ERRORS", "streetNumber", { root: true });
            }
            if (error.response.data.error === "Invalid Request - No state") {
              context.commit("UPDATE_ERRORS", "state", { root: true });
            }
            if (error.response.data.error === "Invalid Request - No city") {
              context.commit("UPDATE_ERRORS", "city", { root: true });
            }
            
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },

    //########################################################################
    //            EDITAR ADDRESS
    //########################################################################
    updateAddress(context, { address, idAddress, idRouter }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log(idRouter)
      context.commit("UPDATE_GOOGLEMAP", null);
      context.commit("UPDATE_WAIT", true, { root: true });
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      if (address.street == null || address.street == "") {
        context.commit("UPDATE_WAIT", false, { root: true });
        context.commit("UPDATE_ERRORS", "full", { root: true });
      } else {
        api.put(`/address/${idAddress}`, address)
          .then(() => {
            context.commit("UPDATE_ADDRESS", this.getters["address/addressReset"]);//limpa o formulário depois de cadastrar     
            context.dispatch("getAddresses", "refresh");
            context.dispatch("getAddress", { idAddress, refreshId: "refreshId" });
            // const destiny = `"${mutation}/${getAction}"`
            context.dispatch("collaborator/getCollaborators", "refresh", { root: true });
            if (idRouter != undefined) {
              context.dispatch("collaborator/getCollaborator", { idCollaborator: idRouter, refreshId: "refreshId" }, { root: true });
            }
            // context.dispatch(destiny, { idRouter, refreshId: "refreshId" }, { root: true });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Morada editada com sucesso!",
            }, { root: true });
            context.commit("UPDATE_WAIT", false, { root: true });
          })
          .catch(error => {
            context.commit("UPDATE_WAIT", false, { root: true });
            console.log(error.response)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },

    //########################################################################
    //            GET MAP ADDRESS
    //########################################################################
    
    getGoogleMap(context, {partAddress}) {
      context.commit("UPDATE_LOADING_CIRCLE", true, { root: true });
      if (partAddress.partAddress.length > 9) {
        api.post(`/address/maps/autocomplete`, partAddress,)
        // api.post(`/address/geo/autocomplete`, { data : JSON.stringify(partAddress)},)
        // api.getGoogle(`/address/geo/autocomplete`, partAddress)
        .then(
          response => {
            // console.log("response.data", response.data)
            context.commit("UPDATE_GOOGLEMAP", response.data);
            // context.commit("RESET_FILTERCITY", null);
            context.commit("UPDATE_LOADING_CIRCLE", false, { root: true });
            // context.commit("RESET_CITY_CONVERT", null);
          })
        .catch(e => {
          context.commit("UPDATE_LOADING_CIRCLE", false, { root: true });
          console.log("Erro partAddress", e.response)
        })
      }else{
        context.commit("UPDATE_LOADING_CIRCLE", false, { root: true });
        // console.log("precisa ser maior de 3 caracteres")
      }  
    },

    getGoogleGeo(context, {partAddress, place_id}) {
        // console.log(context)
        // console.log("partAddress", partAddress)
        // console.log("place_id", place_id)
      context.commit("UPDATE_LOADING_CIRCLE", true, { root: true });
      if (partAddress.partAddress.length > 9) {
        api.post(`/address/geo/autocomplete/placeid/${place_id}`)
        .then(
          response => {
            // console.log("response.data", response.data)
            const address = {
              street: response.data.street,
              streetNumber: parseInt(response.data.street_number),
                // city: response.data.district,
              city: response.data.city || response.data.district,
              state: response.data.state,
              country: response.data.country,
              postalCode: response.data.postal_code,
              location: response.data.location,
            }
            context.commit("UPDATE_ADDRESS", address);
            // context.commit("UPDATE_SEARCH_CITY", this.getters["address/partAddressReply"]);
            context.commit("UPDATE_GOOGLEMAP", null);
            // context.commit("UPDATE_SEARCH_CITY", context.getters.);
            // context.commit("UPDATE_LOADING_CIRCLE", false, { root: true });
            // // context.commit("RESET_CITY_CONVERT", null);
          })
        .catch(e => {
          context.commit("UPDATE_LOADING_CIRCLE", false, { root: true });
          console.log("Erro partAddress", e.response) 
        })
      }else{
        context.commit("UPDATE_LOADING_CIRCLE", false, { root: true });
        console.log("precisa ser maior de 3 caracteres")
      }  
    },

    //############################################
    //       MODAL
    //############################################

    openModalAddress(context) {
      context.commit("UPDATE_MODAL_ADDRESS", true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    closeModalAddress(context) {
      context.commit("UPDATE_MODAL_ADDRESS", false);
    },

    //############################################
    //       DELETE
    //############################################

    //       MODAL - DELETE 
    openModalDelAddress(context) {
      context.commit("UPDATE_MODAL_DEL_ADDRESS", true);
    },    
    closeModalDelAddress(context) {
      context.commit("UPDATE_MODAL_DEL_ADDRESS", false);
    },

    //Confirmação abrindo modal Delete - Delete 1 de 2
    confirmDeleteAddress(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DEL_ADDRESS", true);//abre o modal
    },

    //Delete - Delete 2 de 2
    deleteAddress(context, { id, item }) {
      context.commit("UPDATE_LOADING_ADDRESS", true, { root: true });
      api.delete(`/address/${id}`)
        .then(() => {
          context.commit("UPDATE_DELETE_ADDRESS", id); //atualiza 
          context.dispatch("getAddresses", "refresh");
          if(item != undefined){
            router.push({ name: "moradas"})
          }
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Morada deletada com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
          // console.log("deletou", id)
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
          console.log(error)
          if(error.response.status){
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          }
        })
    },
  }

}