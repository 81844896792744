<template>
  <div class="btnAdd">
    <div class="circle">
      <svg
        class="icon-add"
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 19.24 17.441"
      >
        <g transform="translate(1.013 1.002)">
          <path
            d="M17.887,9.44A7.716,7.716,0,1,0,16.6,14.581"
            transform="translate(-2.493 -2.54)"
            fill="none"
            stroke="#fff"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
          />
          <path
            d="M46.207,21.23h0m-.51-1.59a.388.388,0,0,0-.122.024l-3.142,1.266a.317.317,0,0,0,0,.587l2.184.945a.28.28,0,0,0,.125.027.314.314,0,0,0,.294-.192l.938-2.2a.338.338,0,0,0-.29-.446Z"
            transform="translate(-28.717 -13.826)"
            fill="#fff"
            stroke="#fff"
            stroke-width="1"
          />
        </g>
      </svg>
    </div>
    <!-- <span class="titleAdd">
      <slot />
    </span> -->
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.titleAdd {
  font-size: 12px;
}

.icon-add {
  fill: #fff;
}
.circle {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #1f2b42;
  width: 50px;
  height: 50px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.icon-add:hover {
  fill: #fff;
}
.circle:hover {
  background: #28a745;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.btnAdd {
  display: flex;
  align-items: center;
  cursor: pointer;
  /* padding: 10px; */
}
</style>