export function serialize(obj) {
  let queryString = "";
  for (let key in obj) {
    queryString += `&${key}=${obj[key]}`;
  }
  return queryString;
}

export function mapFields(options) {
  const object = {};
  for (let x = 0; x < options.fields.length; x++) {
    const field = [options.fields[x]];
    object[field] = {
      get() {
        return this.$store.state[options.module][options.base][field];
      },
      set(value) {
        this.$store.commit(options.mutation, { [field]: value }, { root: true });
      }
    };
  }
  return object;
}

export function mapFieldsRoot(options) {
  const object = {};
  for (let x = 0; x < options.fields.length; x++) {
    const field = [options.fields[x]];
    object[field] = {
      get() {
        return this.$store.state[options.base][field];
      },
      set(value) {
        this.$store.commit(options.mutation, { [field]: value }, { root: true });
      }
    };
  }
  return object;
}


export function removeAccent(string) {
  const mapAccentHex = {
    a: /[\xE0-\xE6]/g,
    e: /[\xE8-\xEB]/g,
    i: /[\xEC-\xEF]/g,
    o: /[\xF2-\xF6]/g,
    u: /[\xF9-\xFC]/g,
    c: /\xE7/g
  }
  for (let letter in mapAccentHex) {
    var regEx = mapAccentHex[letter]
    string = string.replace(regEx, letter)
  }
  return string
}

//TRANSFORMAR EM DATA A DATA MAIS RECENTE

export function getLastDate(list, key) {
  let lastDate;
  if (list != undefined || key != undefined) {
    for (let i in list) {
      if (lastDate == null || new Date(lastDate) < new Date(list[i][key])) {
        lastDate = new Date(list[i][key]);
      }
    }
    return lastDate;
  }
}

//FORMATAR DATA - FRONT
export function formatDate(inputDate) {
  let day = String(inputDate.getDate()).padStart(2, "0"); //o padStart coloca um 0 se o número for menor que 10
  let month = String(inputDate.getMonth() + 1).padStart(2, "0");
  let year = inputDate.getFullYear();
  // inputDate = day + "-" + month + "-" + year;
  inputDate = year + "-" + month + "-" + day;
  return inputDate;
}

//SUBTRAIR OS DIAS P/ INFORMAR VENCIMENTO
export function alertDate(inputDate, validate) {
  let today = new Date();
  var data = inputDate;
  if (data != undefined) {
    // console.log("Hoje é: " + data);
    let priorDate = data.setDate(data.getDate() - validate);
    let dateProximity = new Date(priorDate);
    // console.log("dias antes", dateProximity);
    return data >= dateProximity && data < today
  }
}

//FORMAT DATE STRING
export function formatDateString(date) {
  // let array = date.split('-')
  // let year = array[0]
  // let month = array[1]
  // let day = array[2]
  // let finalDate = month + "/" + day + "/" + year;
  let day = String(date.getDate()).padStart(2, "0");
  let month = String(date.getMonth() + 1).padStart(2, "0");
  let year = String(date.getFullYear());
  let finalDate = day + "-" + month + "-" + year;
  return finalDate
}

// export function expireDate(date) {

//   let today = new Date();
//   let verifyDate = new Date(date);
//   return verifyDate < today;
// }

export function serviceHasSearchWord(keyword, listServices) {
  let hasFound = false
  listServices.forEach(service => {
    if ((removeAccent(service.name.toString().toLowerCase().trim())).includes(keyword)) {
      hasFound = true
    }
  })
  return hasFound
}