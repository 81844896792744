<template>
  <section class="containerLink">
    <div class="info">{{ email }}</div>
    <div v-if="contactEdit" class="delete"><slot name="delete" /></div>
  </section>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "EmailItem",
  props: ["idContact", "email"],
  computed: {
    ...mapState("user", ["user"]),
    //######################################################
    //     PERMISSÕES do COLABORADOR
    //######################################################
    contactEdit() {
      return this.contactEditPermission();
    },
  },
  methods: {
    //######################################################
    //     PERMISSÕES DO COLABORADOR
    //######################################################
    //Criar/Editar Colaboradores
    contactEditPermission() {
      const superUser = this.user.superUser;
      const contactEdit = this.user.permissions.find(
        (p1) => p1.code == "contactEdit"
      );
      if (superUser == true || contactEdit != undefined) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>

<style scoped>
.containerLink {
  display: grid;
  grid-template-columns: minmax(280px, 550px) 100px;
  padding: 5px 0;
  align-items: center;
  cursor: pointer;
}
.delete {
  display: grid;
  justify-content: flex-start;
  padding: 5px 0px;
  margin: 0 10px;
}
@media screen and (max-width: 930px) {
  .delete {
    margin: 0 7px;
  }
}
</style>