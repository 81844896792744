<template>
  <section>
    <h1>Viaturas</h1>

    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Editar Revisão da Viatura</h3>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'viatura',
                params: { idVehicle: idVehicle },
              }"
              >Dados da Viatura
            </router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'revisoes-viatura',
                params: { idVehicle: idVehicle },
              }"
              >Revisões da Viatura
            </router-link>
          </li>
          <li class="breadcrumb-item-active">
            <span> Editar Revisão da Viatura</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingVehicle">
        <Loading />
      </div>
      <div v-else class="containerForm">
        <RevisionForm :idVehicle="idVehicle" :errors="errors">
          <button class="btn" @click.prevent="updateRevision">Gravar</button>
        </RevisionForm>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import RevisionForm from "@/components/vehicle/RevisionForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditVehicle",
  props: ["idVehicle", "idRevision"],
  components: {
    RevisionForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("vehicle", ["revisions", "loadingVehicle"]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),
    ...mapGetters("vehicle", ["revisionReply"]),
  },
  methods: {
    updateRevision() {
      this.$store.dispatch("vehicle/updateRevision", {
        idVehicle: this.idVehicle,
        idRevision: this.idRevision,
        revision: this.revisionReply,
      });
    },
  },
  created() {
    this.$store.commit("RESET_ERRORS", []);
    this.$store.dispatch("vehicle/getRevision", {
      idVehicle: this.idVehicle,
      idRevision: this.idRevision,
    });
  },
};
</script>

<style scoped>
h3 {
  margin-left: 10px;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

@media screen and (max-width: 690px) {
  .containerTelecom {
    grid-template-columns: 1fr;
    justify-items: flex-start;
    grid-gap: 10px;
    margin: 30px 10px;
  }
  .containerForm {
    width: 100%;
  }
}
</style>