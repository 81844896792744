<template>
  <form action="">
    
    <div class="container-topSearch">
        <div class="searchContainer">
      <div>
        <label for="addressSearch">Morada:</label>
        <div class="searchLupa">
          <input
            type="text"
            name="addressSearch"
            id="addressSearch"
            v-model="addressSearch"
            autocomplete="off"
            placeholder="Morada..."
            @keyup.enter="searchAddress"
          />
          <!-- @click="event" -->
          <span id="lupa"></span>
        </div>
      </div>
      <div>
        <label for="numberSearch"> Número: </label>
        <div class="searchLupa">
          <input
            type="number"
            name="numberSearch"
            id="numberSearch"
            v-model.number="numberSearch"
            autocomplete="off"
            placeholder="Número..."
            @keyup.enter="searchAddress"
          />
          <!-- @click="event" -->
          <!-- <span id="lupa"></span> -->
        </div>
      </div>
      <div>
        <label for="citySearch">Localidade: </label>
        <div class="searchLupa">
          <input
            type="text"
            name="citySearch"
            id="citySearch"
            v-model="citySearch"
            autocomplete="off"
            placeholder="Localidade..."
            @keyup.enter="searchAddress"
          />
          <!-- @click="event" -->
          <span id="lupa"></span>
        </div>
      </div>
      <div>
        <label for="stateSearch">Distrito: </label>
        <div class="searchLupa">
          <input
            type="text"
            name="stateSearch"
            id="stateSearch"
            v-model="stateSearch"
            autocomplete="off"
            placeholder="Distrito..."
            @keyup.enter="searchAddress"
          />
          <!-- @click="event" -->
          <span id="lupa"></span>
        </div>
      </div>
      <div>
        <label for="postalCodeSearch">Código Postal: </label>
        <div class="searchLupa">
          <input
            type="text"
            name="postalCodeSearch"
            id="postalCodeSearch"
            v-model="postalCodeSearch"
            autocomplete="off"
            placeholder="Posição..."
            @keyup.enter="searchAddress"
          />
          <!-- @click="event" -->
          <span id="lupa"></span>
        </div>
      </div>
      <div>
        <label for="countrySearch">País: </label>
        <div class="searchLupa">
          <input
            type="text"
            name="countrySearch"
            id="countrySearch"
            v-model="countrySearch"
            autocomplete="off"
            placeholder="País..."
            @keyup.enter="searchAddress"
          />
          <!-- @click="event" -->
          <span id="lupa"></span>
        </div>
      </div>      
      <div class="activeForm">
        <label for="danger"> Área Complexa </label>
        <BtnChoice id="danger" v-model="dangerSearch" />
      </div>
      
      
      <div class="buttonSearch">
        <div
          class="button"
          @keyup.enter="searchAddress"
          @click.prevent="searchAddress"
        >
          <BtnSearch />
        </div>
        <div class="button btnReset" @click.prevent="reset" @keyup.esc="reset">
          <BtnReset />
        </div>
      </div>
    </div>
    <ul class="searchActiveContainer" v-if="filterItem">
        <div class="filterActive" v-if="filterItem">Filtro activo:</div>
        <article v-if="addressSearch" class="searchActive">
          <div>Morada...</div>
          <div class="closeSearch" @click.prevent="closeAddressSearch">x</div>
        </article>
        <article v-if="numberSearch" class="searchActive">
          <div>Número...</div>
          <div class="closeSearch" @click.prevent="closeNumberSearch">x</div>
        </article>
        <article v-if="citySearch" class="searchActive">
          <div>Localidade...</div>
          <div class="closeSearch" @click.prevent="closeCitySearch">x</div>
        </article>
        <article v-if="stateSearch" class="searchActive">
          <div>Distrito...</div>
          <div class="closeSearch" @click.prevent="closeStateSearch">x</div>
        </article>
        <article v-if="postalCodeSearch" class="searchActive">
          <div>Código Postal...</div>
          <div class="closeSearch" @click.prevent="closePostalCodeSearch">x</div>
        </article>
        <article v-if="countrySearch" class="searchActive">
          <div>País...</div>
          <div class="closeSearch" @click.prevent="closeCountrySearch">x</div>
        </article>
        <article v-if="dangerSearch" class="searchActive">
          <div>Data de Início...</div>
          <div class="closeSearch" @click.prevent="closeDangerSearch">x</div>
        </article>
      </ul>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
      ...mapState(["filterItem"]),
      ...mapState("address", [
      "addressSearch",
      "numberSearch",
      "citySearch",
      "stateSearch",
      "postalCodeSearch",
      "countrySearch",
      "dangerSearch",
    ]),
    addressSearch: {
      get() {
        return this.$store.state.address.addressSearch;
      },
      set(value) {
        this.$store.commit("address/UPDATE_ADDRESS_SEARCH", value);
      },
    },
    numberSearch: {
      get() {
        return this.$store.state.address.numberSearch;
      },
      set(value) {
        this.$store.commit("address/UPDATE_NUMBER_SEARCH", value);
      },
    },
    citySearch: {
      get() {
        return this.$store.state.address.citySearch;
      },
      set(value) {
        this.$store.commit("address/UPDATE_CITY_SEARCH", value);
      },
    },
    stateSearch: {
      get() {
        return this.$store.state.address.stateSearch;
      },
      set(value) {
        this.$store.commit("address/UPDATE_STATE_SEARCH", value);
      },
    },
    postalCodeSearch: {
      get() {
        return this.$store.state.address.postalCodeSearch;
      },
      set(value) {
        this.$store.commit("address/UPDATE_POSTAL_SEARCH", value);
      },
    },
    countrySearch: {
      get() {
        return this.$store.state.address.countrySearch;
      },
      set(value) {
        this.$store.commit("address/UPDATE_COUNTRY_SEARCH", value);
      },
    },
    dangerSearch: {
      get() {
        return this.$store.state.address.dangerSearch;
      },
      set(value) {
        this.$store.commit("address/UPDATE_DANGER_SEARCH", value);
      },
    },
  },
  methods: {
    msgFilter() {
      return this.$store.commit("UPDATE_FILTER_ITEM", this.addressSearch != null ||
        this.numberSearch != null ||
        this.citySearch != null ||
        this.stateSearch != null ||
        this.postalCodeSearch != null ||
        this.countrySearch != null ||
        this.dangerSearch != null)
    },  
    closeAddressSearch() {
      this.$store.commit("address/UPDATE_ADDRESS_SEARCH", null);
      this.searchAddress();
    },  
    closeNumberSearch() {
      this.$store.commit("address/UPDATE_NUMBER_SEARCH", null);
      this.searchAddress();
    },  
    closeCitySearch() {
      this.$store.commit("address/UPDATE_CITY_SEARCH", null);
      this.searchAddress();
    },  
    closeStateSearch() {
      this.$store.commit("address/UPDATE_STATE_SEARCH", null);
      this.searchAddress();
    },  
    closePostalCodeSearch() {
      this.$store.commit("address/UPDATE_POSTAL_SEARCH", null);
      this.searchAddress();
    },  
    closeCountrySearch() {
      this.$store.commit("address/UPDATE_COUNTRY_SEARCH", null);
      this.searchAddress();
    },  
    closeDangerSearch() {
      this.$store.commit("address/UPDATE_DANGER_SEARCH", null);
      this.searchAddress();
    },
    reset() {
      this.$store.commit("address/RESET_FILTER", null);
      this.$store.commit("UPDATE_CURRENTPAGE", 1);
      this.msgFilter()
      this.updateQuert()
    },
    updateQuert(){
       const filter = {
          addressSearch: this.addressSearch,
          numberSearch: this.numberSearch,
          citySearch: this.citySearch,
          stateSearch: this.stateSearch,
          postalCodeSearch: this.postalCodeSearch,
          countrySearch: this.countrySearch,
          dangerSearch: this.dangerSearch,
          page: null,
          // page: Number(this.$route.query.page),
        };
        const query = Object.entries(filter).reduce((acc, [key, val]) => {
          if (!val) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$router.replace({ query: { ...query } }).catch(() => {});
    },
    searchAddress() {    
      // this.$router.push({ query: { page: 1 } }) 
      this.$store.commit("UPDATE_CURRENTPAGE", 1); 
      this.$store.commit("address/FILTERED_ADDRESS", {
        addressSearch: this.addressSearch,
        numberSearch: this.numberSearch,
        citySearch: this.citySearch,
        stateSearch: this.stateSearch,
        postalCodeSearch: this.postalCodeSearch,
        countrySearch: this.countrySearch,
        dangerSearch: this.dangerSearch,
      });
      this.msgFilter();
      this.updateQuert();
    },
  },
  created(){
    this.msgFilter();
  }
};
</script>

<style scoped>
input{
  width: 100%;
}
input#dateProcess {
  display: flex;
  width: 100%;
}
.searchLupa {
  position: relative;
  margin-top: 10px;
}
.container-topSearch {
  display: grid;
  width: 100%;
  padding: 0 10px 40px 0;
  justify-content: center;
}

#search {
  width: 100%;
}
.searchContainer {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
  align-items: flex-end;
}

.buttonSearch {
  display: flex;
  grid-column: 3;
  justify-content: flex-end;
}
.btnReset {
  margin-right: -5px;
}
#lupa {
  width: 62px;
  height: 46px;
  background: url("../../assets/svg/search2.svg") no-repeat center center;
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;
}

.searchActiveContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 10px 0 30px 0;
  grid-gap: 10px;
  font-size: 0.8rem;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #373946;
  padding: 10px;
  border-radius: 4px;
  /* box-shadow: 0 4px 8px rgb(31 43 66 / 20%); */
  border-radius: 4px;
  border: 1px solid rgba(31, 43, 66, 0.4);
  /* justify-content: center; */
  justify-content: flex-start;
  max-width: 700px;
}
.searchActive {
  display: grid;
  grid-template-columns: minmax(60px, auto) 25px;
  grid-gap: 5px;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid rgba(31, 43, 66, 0.4);
  padding: 10px;
  transition: all 0.3s;
  outline: none;
}
.closeSearch {
  padding: 0 10px;
  border-left: 1px solid #ccc;
  cursor: pointer;
  font-weight: bold;
}
@media (min-width: 500px) and (max-width: 830px) {
  .searchContainer {
    grid-template-columns: repeat(2, 1fr);
    
  }
  .buttonSearch {
    grid-column: 2;
  }
}
@media screen and (max-width: 500px) {
  .searchContainer {
    grid-template-columns: 1fr;
    padding: 0 10px;
  }
  .searchActiveContainer{
    margin: 10px 10px;
  }
  .buttonSearch {
    grid-column: 1;
  }
}
</style>