<template>
  <section class="itemsCollab">
    <h1>Moradas</h1>
    <div class="containerContact">
      <div v-if="loadingAddress">
        <Loading />
      </div>
      <div v-else>
        <div v-if="addresses">
          <div class="titleContainer">
            <h3>Lista de Moradas</h3>
            <div v-if="addressEdit">
              <router-link
                class="collabItens"
                :to="{
                  name: 'criar-morada',
                }"
              >
                <AddUser>
                  <span slot="title">
                    Adicionar<br />
                    Morada</span
                  >
                </AddUser>
              </router-link>
            </div>
          </div>
          <nav class="navBreadcrumb">
            <ol class="breadcrumb">
              <slot />

              <li class="breadcrumb-item-active">
                <span> Lista de moradas</span>
              </li>
            </ol>
          </nav>          
          <div class="filterBtn">
              <div @click.prevent="filterBtn">
                <BtnFilter>
                  <span slot="filtro">Filtro Moradas</span>
                </BtnFilter>
              </div>
          </div>
          <div v-if="btnFilter">
              <AddressSearch />
          </div>
          <div class="titleService">
            <p class="titleBlue">Morada</p>
            <p class="titleBlue">Código Postal</p>
            <p class="titleBlue">Localidade</p>
            <p class="titleBlue">Distrito</p>
          </div>
          <hr />
          <ul v-for="address in paginatedData" :key="address.id">
            <!-- <ul v-for="address in filteredAddresses" :key="address.id"> -->
            <li>
              <AddressItemList :address="address">
                <button
                  v-if="addressEdit"
                  slot="delete"
                  class="delete"
                  @click.prevent="confirmDeleteAddress(address.id)"
                ></button>
              </AddressItemList>
            </li>
          </ul>
          <Pagination
            :data="filteredAddresses"
            :totalPages="Math.ceil(filteredAddresses.length / limit)"
            :total="filteredAddresses.length"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
          >
          </Pagination>
        </div>
        <div v-else>
          <p class="noResults">Não possui moradas cadastradas</p>
        </div>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelAddress"
        @click="closeModalDelAddress"
      ></div>
      <div class="modalDel_container" v-if="showModalDelAddress">
        <button class="close btn" @click="closeModalDelAddress">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover esta morada?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelAddress"
          />
          <button class="btnDel" @click.prevent="deleteAddress">Delete</button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import AddressItemList from "@/components/address/AddressItemList.vue";
import AddressSearch from "@/components/address/AddressSearch.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Addresses",
  components: {
    AddressItemList,
    AddressSearch,
  },
  computed: {
    ...mapState([
      "limit",
      "currentPage",
      "errors",
      "items",
      "itemsError",
      "selectedId",
      "btnFilter",
    ]),
    ...mapState("address", [
      "addresses",
      "loadingAddress",
      "searchWord",
      "filtered",
      "showModalDelAddress",
    ]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["addressEdit"]),
    ...mapGetters("address", ["paginatedData", "getFiltered", "filteredAddresses"]),
    // filteredAddresses() {
    //   const a = this.getFiltered || this.addresses;
    //   return a;
    // },
  },
  methods: {
    filterBtn() {
      this.btnFilter == true
        ? this.$store.commit("UPDATE_BTNFILTER", false)
        : this.$store.commit("UPDATE_BTNFILTER", true);
      // console.lgo(update);
    },
    onPageChange(page) {
      this.$store.commit("UPDATE_CURRENTPAGE", page);
    },
    openModalDelAddress() {
      this.$store.dispatch("address/openModalDelAddress");
    },
    closeModalDelAddress() {
      this.$store.dispatch("address/closeModalDelAddress");
    },
    //######################################################
    //     DELETE
    //######################################################
    confirmDeleteAddress(id) {
      console.log(id);
      this.$store.dispatch("address/confirmDeleteAddress", {
        id: `${id}`,
      });
    },

    deleteAddress() {
      // console.log("selectedId", this.selectedId.id);
      this.$store.dispatch("address/deleteAddress", {
        id: this.selectedId.id,
      });
      this.closeModalDelAddress();
    },
    getAddresses(){
        this.$store.dispatch("address/getAddresses", {
            refresh: "refresh"
        });
    }
  },
  created() {
    this.$store.commit(
      "UPDATE_CURRENTPAGE",
      Number(this.$route.query.page) || 1
    );
    this.$store.commit("address/RESET_FILTER", null);
    this.getAddresses()
    // this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
    
  },
};
</script>

<style scoped>
.filterBtn {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px 0;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.titleBlue {
  padding-left: 10px;
}
hr {
  max-width: 1400px;
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 0 0 5px 0;
  text-align: left;
  margin-left: 10px;
}
/* .itemsCollab {
  padding: 40px 20px;
} */

.noResults {
  margin: 60px auto;
  max-width: 500px;
  text-align: center;
  padding: 10px;
  transition: all 0.2s;
}

.breadcrumb-item-active {
  margin-left: 10px;
}

.titleService {
  display: grid;
  /* grid-template-columns: minmax(300px, 400px) 200px 1fr; */
  grid-template-columns:  310px  180px repeat(2, 200px);
  height: 30px;
  max-width: 1400px;
  /* overflow-y: auto;
  overflow-x: auto; */
  border-radius: 5px;
}

@media screen and (max-width: 690px) {
  .titleService {
    display: none;
  }
}
</style>