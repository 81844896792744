<template>
  <section>
    <h1>Colaboradores</h1>
    <div class="containerCollab">
      <nav class="sidenav">
        <ul class="menuItem">
          <li v-if="collabActive && collabActive.length" :class="activeRoute">
            <router-link :to="{ name: 'colaborador-ativo' }"
              >Lista Ativos</router-link
            >
          </li>
          <li
            v-if="collabDisabled && collabDisabled.length"
            :class="disabledRoute"
          >
            <router-link :to="{ name: 'colaborador-desativado' }"
              >Lista Desativados</router-link
            >
          </li>
        </ul>
      </nav>
      <hr />
      <router-view></router-view>
    </div>
  </section>
</template>

// <script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Collaborators",
  computed: {
    ...mapState("collaborator", [
      "collaborator",
      "collaborators",
      "collabActive",
      "collabDisabled",
    ]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["staffDetail"]),
    //##############################################################
    //     MATENDO BOTÃO ATIVADO/DESATIVADO - DEPENDENDO DA ROTA
    //##############################################################
    activeRoute() {
      return this.$route.name == "colaborador-info-ativo" ||
        this.$route.name == "criar-colaborador" ||
        this.$route.name == "email" ||
        this.$route.name == "telefone" ||
        this.$route.name == "morada" ||
        this.$route.name == "editar-colaborador"
        ? "activeLi"
        : "nothing";
    },
    disabledRoute() {
      return this.$route.name == "colaborador-info-desativado"
        ? "activeLi"
        : "nothing";
    },
  },
  created() {
    this.$store.dispatch("collaborator/getCollaborators");
  },
};
</script>

<style scoped>
.containerCollab {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}
</style>
