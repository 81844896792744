<template>
  <section v-if="processType">
    <h1>Jurídico</h1>
    <div class="contentGeneral">
      <h3>Adicionar Tipo de Processo</h3>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos', query: { archived: 'false'} }"  >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'tipos-de-processos' }"
              >Lista de Tipos de Pocessos</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Adicionar Tipo de Processo</span>
          </li>
        </ol>
      </nav>
      <transition mode="out-in">
        <ProcessTypeForm :processType="processType" :errors="errors">
          <div slot="enviar">
            <button class="btn btn-form" @click.prevent="addProcessType">
              Adicionar
            </button>
          </div>
        </ProcessTypeForm>
      </transition>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </section>
</template>

<script>
import ProcessTypeForm from "@/components/legal/ProcessTypeForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProcessTypeAdd",
  // props: ["idProcessType"],
  data() {
    return {
      create: false,
    };
  },
  components: {
    ProcessTypeForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("legal", ["processType"]),
    ...mapGetters("legal", ["processTypeReply", "processTypeReset"]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalysis",
      "processManager",
    ]),
  },
  methods: {
    addProcessType() {
      console.log(this.processTypeReply);
      this.$store.dispatch("legal/addProcessType", {
        processType: this.processTypeReply, //enviar getters com as infos necessárias para o cadastro
      });
    },
  },
  created() {
    this.$store.commit("legal/UPDATE_PROCESS_TYPE", this.processTypeReset);
    this.$store.commit("RESET_ERRORS", []);
  },
};
</script>

<style scoped>
#address {
  grid-column: 2;
}
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
</style>
