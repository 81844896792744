var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"action":""}},[(_vm.loadingPermission)?_c('div',{staticClass:"loading"},[_c('p',[_c('Loading')],1)]):_c('div',{staticClass:"containerPermissions"},[_c('article',[_c('h3',[_vm._v("Calendário")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Calendário')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2),_c('article',[_c('h3',[_vm._v("Cliente")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Cliente')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2),_c('article',[_c('h3',[_vm._v("Colaboradores")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Colaboradores')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2),_c('article',[_c('h3',[_vm._v("Contactos")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Contactos')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2),_c('article',[_c('h3',[_vm._v("Financeiro")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Financeiro')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2),_c('article',[_c('h3',[_vm._v("Jurídico")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Jurídico')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2),_c('article',[_c('h3',[_vm._v("Moradas")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Moradas')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2),_c('article',[_c('h3',[_vm._v("Serviços")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Serviços')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2),_c('article',[_c('h3',[_vm._v("Solicitação")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Solicitação')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2),_c('article',[_c('h3',[_vm._v("Telecom")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Telecom')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2),_c('article',[_c('h3',[_vm._v("Tarefas")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Tarefas')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2),_c('article',[_c('h3',[_vm._v("Tracker")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Tracker')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2),_c('article',[_c('h3',[_vm._v("Viaturas")]),_vm._l((_vm.listPermissions),function(permission){return _c('div',{key:permission.id},[(permission.group == 'Viaturas')?_c('div',{staticClass:"formInfos"},[_c('label',{staticClass:"tooltip",attrs:{"for":"code"}},[_vm._v(" "+_vm._s(permission.description)+" "),(
                permission.tooltip.items &&
                permission.tooltip.items.length > 0
              )?_c('span',{staticClass:"tooltiptext"},[_c('ul',[_c('span',{staticClass:"titleBlue"},[_vm._v(_vm._s(permission.tooltip.title))]),_vm._l((permission.tooltip.items),function(tooltip){return _c('li',{key:tooltip.description,staticClass:"itemTooltip"},[_vm._v(" "+_vm._s(tooltip.description)+" ")])})],2)]):_vm._e()]),_c('div',{on:{"click":function($event){return _vm.addPermission(permission)}}},[_c('BtnChoice',{model:{value:(permission.value),callback:function ($$v) {_vm.$set(permission, "value", $$v)},expression:"permission.value"}})],1)]):_vm._e()])})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }