<template>
  <form action="">
    <div class="formInfos">
      <div v-if="loadingEmail">
        <p><LoadingCircle /></p>
      </div>
      <input
        v-else
        id="email"
        name="email"
        type="email"
        v-model.trim="email"
        :class="validateFull"
      />
      <div class="button">
        <slot></slot>
      </div>
    </div>
    <div v-if="!errors.indexOf('full')" class="notify2">
      O e-mail é inválido ou não foi preenchido
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "EmailForm",
  props: ["collaborator", "errors"],
  computed: {
    validateFull() {
      return this.errors.indexOf("full") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["email"], //nome dos campos
      module: "collaborator", //qual módulo está acessando
      base: "email", //colocar o state que vai receber
      mutation: "collaborator/UPDATE_EMAIL", //nome da mutation que vai alterar o state
    }),
    ...mapState(["loadingEmail"]),
  },
};
</script>

<style scoped>
.formInfos {
  display: grid;
  grid-template-columns: minmax(300px, 500px) 100px;
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  margin-top: 20px;
  align-content: center;
}
input.validate {
  margin: 0;
}

@media screen and (max-width: 690px) {
  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: 10px;
  }
  input {
    width: 100%;
  }
}
</style>