<template>
  <div>
    <div class="titleContainer">
      <h3>Editar Telefone</h3>
    </div>

    <nav class="navBreadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'colaborador-ativo' }"
            >Lista Ativos</router-link
          >
        </li>
        <li class="breadcrumb-item">
          <router-link
            :to="{
              name: 'colaborador-info-ativo',
              params: { idCollaborator: idCollaborator },
            }"
            >Dados do Colaborador</router-link
          >
        </li>
        <li class="breadcrumb-item-active">
          <span> Editar Telefone</span>
        </li>
      </ol>
    </nav>
    <div class="itemFormCollaborator">
      <PhoneForm :phone="phone" :errors="errors">
        <button class="btn" @click.prevent="updatePhone">Gravar</button>
      </PhoneForm>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </div>
</template>

<script>
import PhoneForm from "@/components/collaborator/PhoneForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditPhone",
  props: ["idPhone", "idCollaborator"],
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("collaborator", ["collaborator", "phone"]),
    ...mapGetters("collaborator", ["phoneReply"]),
  },
  components: {
    PhoneForm,
  },
  methods: {
    updatePhone() {
      this.$store.dispatch("collaborator/updatePhone", {
        idCollaborator: this.idCollaborator,
        idPhone: this.idPhone,
        phone: this.phoneReply,
      });
    },
  },
  created() {
    this.$store.dispatch("collaborator/getPhone", {
      idCollaborator: this.idCollaborator,
      idPhone: this.idPhone,
    });
  },
};
</script>

<style scoped>
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
.itemFormCollaborator {
  margin-left: 10px;
}
</style>