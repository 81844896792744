<template>
  <section>
    <div v-if="calls && calls.length > 0">
      <div class="titleCollab">
        <p class="titleBlue">De</p>
        <p class="titleBlue">Descrição</p>
        <p class="titleBlue">Anotações</p>
        <p class="titleBlue">Data</p>
        <p class="titleBlue">Duração</p>
        <p class="titleBlue">Gravação</p>
      </div>
      <hr />
      <!-- <ul v-for="call in calls" :key="call.id"> -->
      <ul v-for="(call, index) in paginatedData" :key="index">
        <li class="itemList">
          <TelecomCallItem :call="call">
            <!-- <div
              slot="addNameteste"
              @click.prevent="openModalNameContact(call)"
            >
              Adicionar nome Teste
            </div> -->
            <div slot="addName" @click.prevent="openModalNameContact(call)">
              <img
                src="../../assets/svg/userContactAdd.svg"
                alt="icone histórico de chamada"
              />
            </div>
            <div v-if="call.note && call.note.length > 0" slot="note">
              <div @click.prevent="openModalNote(call.id)">
                <svg
                  version="1.1"
                  id="note"
                  xmlns="http://www.w3.org/2000/svg"
                  xmlns:xlink="http://www.w3.org/1999/xlink"
                  x="0px"
                  y="0px"
                  viewBox="0 0 104.69 122.88"
                  style="enable-background: new 0 0 104.69 122.88"
                  xml:space="preserve"
                >
                  <g>
                    <path
                      d="M31.54,86.92c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15H55c1.74,0,3.15,1.41,3.15,3.15 c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,86.92z M31.54,42.27c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,42.27z M7.33,0h90.02c2.02,0,3.85,0.82,5.18,2.15 c1.33,1.33,2.15,3.16,2.15,5.18v108.21c0,2.02-0.82,3.85-2.15,5.18c-1.33,1.33-3.16,2.15-5.18,2.15H7.33 c-2.02,0-3.85-0.82-5.18-2.15C0.82,119.4,0,117.57,0,115.55V7.33c0-2.02,0.82-3.85,2.15-5.18C3.48,0.82,5.31,0,7.33,0L7.33,0z M97.36,6.3H7.33c-0.28,0-0.54,0.12-0.73,0.3C6.42,6.8,6.3,7.05,6.3,7.33v108.21c0,0.28,0.12,0.54,0.31,0.72 c0.19,0.19,0.45,0.31,0.73,0.31h90.02c0.28,0,0.54-0.12,0.72-0.31c0.19-0.19,0.31-0.45,0.31-0.72V7.33c0-0.28-0.12-0.54-0.31-0.73 C97.89,6.42,97.64,6.3,97.36,6.3L97.36,6.3z M31.54,64.59c-1.74,0-3.15-1.41-3.15-3.15c0-1.74,1.41-3.15,3.15-3.15h41.61 c1.74,0,3.15,1.41,3.15,3.15c0,1.74-1.41,3.15-3.15,3.15H31.54L31.54,64.59z"
                    />
                  </g>
                </svg>
              </div>
            </div>
            <div v-else slot="note" @click.prevent="openModalNote(call.id)">
              <svg
                version="1.1"
                id="noteAdd"
                width="40"
                height="38"
                viewBox="4.5 0 19 24"
              >
                <path
                  d="M13.5,20 C14.3284271,20 15,19.3284271 15,18.5 C15,17.1192881 16.1192881,16 17.5,16 C18.3284271,16 19,15.3284271 19,14.5 L19,11.5 C19,11.2238576 19.2238576,11 19.5,11 C19.7761424,11 20,11.2238576 20,11.5 L20,14.5 C20,18.0898509 17.0898509,21 13.5,21 L6.5,21 C5.11928813,21 4,19.8807119 4,18.5 L4,5.5 C4,4.11928813 5.11928813,3 6.5,3 L12.5,3 C12.7761424,3 13,3.22385763 13,3.5 C13,3.77614237 12.7761424,4 12.5,4 L6.5,4 C5.67157288,4 5,4.67157288 5,5.5 L5,18.5 C5,19.3284271 5.67157288,20 6.5,20 L13.5,20 L13.5,20 Z M15.7913481,19.5014408 C16.9873685,18.9526013 17.9526013,17.9873685 18.5014408,16.7913481 C18.1948298,16.9255432 17.8561101,17 17.5,17 C16.6715729,17 16,17.6715729 16,18.5 C16,18.8561101 15.9255432,19.1948298 15.7913481,19.5014408 L15.7913481,19.5014408 Z M18,6 L20.5,6 C20.7761424,6 21,6.22385763 21,6.5 C21,6.77614237 20.7761424,7 20.5,7 L18,7 L18,9.5 C18,9.77614237 17.7761424,10 17.5,10 C17.2238576,10 17,9.77614237 17,9.5 L17,7 L14.5,7 C14.2238576,7 14,6.77614237 14,6.5 C14,6.22385763 14.2238576,6 14.5,6 L17,6 L17,3.5 C17,3.22385763 17.2238576,3 17.5,3 C17.7761424,3 18,3.22385763 18,3.5 L18,6 Z M8.5,9 C8.22385763,9 8,8.77614237 8,8.5 C8,8.22385763 8.22385763,8 8.5,8 L12.5,8 C12.7761424,8 13,8.22385763 13,8.5 C13,8.77614237 12.7761424,9 12.5,9 L8.5,9 Z M8.5,12 C8.22385763,12 8,11.7761424 8,11.5 C8,11.2238576 8.22385763,11 8.5,11 L15.5,11 C15.7761424,11 16,11.2238576 16,11.5 C16,11.7761424 15.7761424,12 15.5,12 L8.5,12 Z M8.5,15 C8.22385763,15 8,14.7761424 8,14.5 C8,14.2238576 8.22385763,14 8.5,14 L13.5,14 C13.7761424,14 14,14.2238576 14,14.5 C14,14.7761424 13.7761424,15 13.5,15 L8.5,15 Z"
                />
              </svg>
            </div>
            <div slot="tooltip" @click.prevent="openModalNote(call.id)">
              {{ call.note }}
            </div>
          </TelecomCallItem>
        </li>
      </ul>
      <!-- <Pagination :totalPages="totalPages" :limit="limit" :page="page" /> -->
      <Pagination
        :data="calls"
        :totalPages="Math.ceil(calls.length / limit)"
        :total="calls.length"
        :currentPage="currentPage"
        @pagechanged="onPageChange"
      >
      </Pagination>
    </div>
    <div v-else>
      <!-- MODAL NOTE -->
      <p class="noResults">Sem registo de chamadas.</p>
    </div>
    <div class="modal">
      <div class="overlay" v-if="showModalNote" @click="closeModalNote"></div>
      <div class="modal_container" v-if="showModalNote">
        <button class="close btn" @click="closeModalNote">x</button>
        <h1>Notas</h1>
        <CallNoteForm :callNote="callNote" :errors="errors">
          <button class="btn btnNote" @click.prevent="updateCallNote(callNote)">
            Gravar
          </button>
        </CallNoteForm>
      </div>
    </div>
    <!-- MODAL ADD NAME -->
    <div class="modal">
      <div
        class="overlay"
        v-if="showModalNameContact"
        @click="closeModalNameContact"
      ></div>
      <div class="modal_container" v-if="showModalNameContact">
        <button class="close btn" @click="closeModalNameContact">x</button>
        <h1>Adicionar Nome</h1>
        <!-- {{ call }} -->
        <AddNamePhoneForm :contact="call" :errors="errors">
          <button class="btn btnNote" @click.prevent="updateContactName(call)">
            Gravar
          </button>
        </AddNamePhoneForm>
      </div>
    </div>
    <NotifySuccess :items="items" />
  </section>
</template>

<script>
import TelecomCallItem from "@/components/telecom/TelecomCallItem.vue";
import CallNoteForm from "@/components/telecom/CallNoteForm.vue";
import AddNamePhoneForm from "@/components/telecom/AddNamePhoneForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "CallHistory",
  computed: {
    ...mapState(["errors", "items", "loadingCalls", "limit", "currentPage"]),
    ...mapState("user", ["user"]),
    ...mapState("telecom", [
      "calls",
      "call",
      "totalPages",
      "startDate",
      "endDate",
      "fromPhone",
      "showModalNote",
      "callNote",
      "showModalNameContact",
    ]),
    ...mapGetters("telecom", [
      "callNoteReset",
      "startDateReset",
      "endDateReset",
      "fromPhoneReset",
      "paginatedData",
      "callNoteReply",
      "startDateReply",
      "endDateReply",
      "fromPhoneReply",
      "descriptionReply",
      "noteReply",
    ]),
    ...mapGetters("contact", ["contactReply", "contactReset"]),
  },
  components: {
    TelecomCallItem,
    CallNoteForm,
    AddNamePhoneForm,
  },
  methods: {
    //Paginação
    onPageChange(page) {
      if (this.$route.name === "historico-chamadas") {
        const filter = {
          startDate: this.startDateReply,
          endDate: this.endDateReply,
          phone: this.fromPhoneReply,
          description: this.descriptionReply,
          note: this.noteReply,
          page: page,
        };
        const query = Object.entries(filter).reduce((acc, [key, val]) => {
          if (!val) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$router.replace({ query: { ...query } }).catch(() => {});
      } else {
        this.$store.commit("UPDATE_CURRENTPAGE", page); //ZERAR PAGINATION
      }
    },
    // NAME CONTACTO

    openModalNameContact(call) {
      // console.log(call);
      this.$store.commit("contact/UPDATE_CONTACT", this.contactReset);
      this.$store.dispatch("telecom/getCall", { idCall: `${call.id}` });
      this.$store.dispatch("contact/getContact", {
        idContact: `${call.contactID}`,
      });
      this.$store.dispatch("telecom/openModalNameContact");
    },
    closeModalNameContact() {
      this.$store.dispatch("telecom/closeModalNameContact");
      // this.$store.commit("telecom/UPDATE_CALLNOTE", this.callNoteReset);
      // this.getCalls();
    },
    updateContactName(id) {
      this.$store.dispatch("contact/updateContactName", {
        idContact: `${id.contactID}`,
        contact: this.contactReply,
      });
      this.closeModalNameContact();
      // this.$emit("getCalls");
    },

    // NOTE
    openModalNote(id) {
      this.$store.dispatch("telecom/getCall", { idCall: `${id}` });
      this.$store.dispatch("telecom/openModalNote");
    },
    closeModalNote() {
      this.$store.dispatch("telecom/closeModalNote");
      this.$store.commit("telecom/UPDATE_CALLNOTE", this.callNoteReset);
      // this.getCalls();
    },
    updateCallNote(id) {
      this.$store.dispatch("telecom/updateCallNote", {
        idCall: `${id.id}`,
        callback: this.callNoteReply,
      });
      this.closeModalNote();
      this.$emit("getCalls");
    },
  },
};
</script>

<style scoped>
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px 50px;
  overflow: hidden;
  padding: 0 10px;
  grid-gap: 5px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1050px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

.noResults {
  margin-left: 15px;
}

.nameContact {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  cursor: pointer;
}
@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
}
</style>
