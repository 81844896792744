<template>
  <section>
    <!-- v-if="statusInspection && statusInsurance" -->
    <!-- <section class="itemContainer"> -->
    <!-- <router-link
      v-if="vehicleAccess || vehicleManager || vehicleAnalysis || vehicleEdit"
      :to="{
        name: 'viatura',
        params: {
          idVehicle: vehicle.id,
        },
      }"
    > -->
    <div v-if="process">
      <!-- {{ process.processID }}
      {{ process.noteID }}
      {{ process.path }}
      {{ process.title }}
      {{ process.date }} -->
      <div class="collabIten">
        <div>
          <img src="@/assets/svg/timeline/alertIcon.svg" />
        </div>
        <div>
          <h4>{{ process.processNumber }}</h4>
          <hr />
          <h5 class="div2">{{ process.title }}</h5>
          <p class="textRed">{{ process.date | formatDateString }}</p>
        </div>
        <router-link
          :to="{
            path: process.path,
          }"
        >
          <img src="@/assets/svg/timeline/linkIcon.svg" />
        </router-link>
      </div>
    </div>
  </section>
</template>  

<script>
import { getLastDate } from "@/helpers.js";
// import { formatDate } from "@/helpers.js";
import { alertDate } from "@/helpers.js";
// import { formatDateString } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "DsVehicle",
  props: ["process", "idVehicle"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("vehicle", ["vehicles", "typeVehicles"]),
    ...mapGetters("user", [
      "vehicleAccess",
      "vehicleManager",
      "vehicleAnalysis",
      "vehicleEdit",
    ]),
  },
};
</script>

<style scoped>
.div2 {
  white-space: nowrap;
  width: 10em;
  overflow: hidden;
  text-overflow: ellipsis;
}
.textRed {
  color: red;
  font-size: 16px;
  line-height: 21px;
}

.iconNull {
  fill: #88898f;
  /* margin-left: 35px; */
  /* text-align: center; */
}
.telegramUsers {
  color: #1f2b42;
  font-size: 16px;
  line-height: 21px;
}

hr {
  margin: 10px 0;
  padding: 0;
}

h4 {
  line-height: 25px;
  font-size: 20px;
  margin: 20px 0;
}

h5 {
  /* line-height: 0; */
  font-size: 20px;
  margin: 10px 0;
  font-weight: 400;
}

.collabIten {
  display: grid;
  justify-content: center;
  justify-items: center;
  text-align: center;
  grid-gap: 20px;
}

.collabConfig {
  display: grid;
  grid-template-columns: 50px;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

@media screen and (max-width: 695px) {
}
</style>
