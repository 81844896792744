import { api } from "@/services.js";
import router from "@/router";
import { removeAccent } from "@/helpers.js";
import { formatDate } from "@/helpers.js";

export default {
  namespaced: true,
  state: {
    //############################
    //       CONTACTO            
    //############################
    contacts: null,
    contact: {
      name: null,
      description: null,
      phones: [],
      emails: [],
    },
    maxPage:null,
    //############################
    //         LOADING            
    //############################
    loadingContact: false,
    //############################
    //  EMAILS, PHONES, ADDRESS            
    //############################
    createEmail: false,
    createPhone: false,
    //############################
    //  MODAL            
    //############################
    showModalEmail: false,
    showModalPhone: false,
    showModalAddress: false,
    showModalContact: false,
    //###############
    //  PAGINAÇÃO
    //###############
    totalPages: 0,
    //###############
    //  PESQUISA
    //###############
    searchWord: null,
    filtered: null,
    contactFilter: [],
    //############################
    //  MODAL DELETE
    //############################
    showModalDeleteEmail: false,
    showModalDeletePhone: false,
    selectedId: null,
    repeatPhone: null,
    // ############################
    //  SearchContact URL
    //############################
    page: {
        page: null,
      },
    name: {
        name: null,
    },
    phone: {
        phone: null,
    },
    mail: {
        mail: null,
    },
    description: {
     description: null,
    },
  },
  //########################################################################################
  //                 GETTERS            
  //########################################################################################
  getters: {
    //###############
    //  PESQUISA
    //###############
    getFiltered: (state) => state.filtered,
    contactReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "name": state.contact.name,
        "description": state.contact.description,
        "phones": state.contact.phones,
        "emails": state.contact.emails,
      }
    },
    contactNameReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "name": state.contact.name,
        "description": state.contact.description,
        "phones": state.contact.phones,
        "emails": state.contact.emails,
      }
    },
    contactReset() {
      return {
        name: null,
        description: null,
        phones: [],
        emails: [],
      }
    },

    resetContact() {
      return {
        name: null,
        description: null,
        phones: [],
        emails: [],
      }
    },
    contactEmailPhoneReset() {
      return {
        phones: [],
        emails: [],
      }
    },
    // ############################
    //  SEARCH CONTACT URL
    //############################
    pageReply(state) {
        return state.page.page
      },
    pageReset() {
        return {
            page: null,
        }
    },
    nameReply(state) {
        return state.name.name
      },
    nameReset() {
        return {
            name: null,
        }
    },
    phoneReply(state) {
        return state.phone.phone
      },
    phoneReset() {
        return {
            phone: null,
        }
    },
    mailReply(state) {
        return state.mail.mail
      },
    mailReset() {
        return {
            mail: null,
        }
    },
    descriptionReply(state) {
        return state.description.description
      },
    descriptionReset() {
        return {
            description: null,
        }
    },

    //###############
    //  PAGINAÇÃO
    //###############
    paginatedData(state, getters, rootState) {
      // console.log("rootState.currentPage", rootState.currentPage)//acessando o state da global
    //   let start = (rootState.currentPage - 1) * 100,
    //     end = start + 100;
        return state.contacts;
    //   if (state.filtered) {
    //     return state.filtered.slice(start, end);
    //   } else {

    //     return state.contacts.slice(start, end);
    //   }
    },
  },
  //########################################################################################
  //                 MUTATIONS            
  //########################################################################################
  mutations: {
    UPDATE_DELETECONTACT(state, id) {
      let contacts = state.contacts.filter(c => c.id != id)
      state.contacts = contacts;
    },
    ADD_PHONE(state, phone) {
      // console.log("phone contato", phone);
      state.contact.phones.push(phone);
    },
    ADD_EMAIL(state, email) {
      // console.log("email contato", email);
      state.contact.emails.push(email);
    },
    REMOVE_PHONE(state, { index }) {
      state.contact.phones.splice(index, 1)
      // state.contact.phones.splice(index)
    },
    RESET_PHONE(state) {
      state.contact.phones.splice(0)
      // state.contact.phones.splice(index)
    },
    REMOVE_EMAIL(state, { index }) {
      state.contact.emails.splice(index, 1)
    },
    RESET_EMAIL(state) {
      state.contact.emails.splice(0)
    },
    UPDATE_PHONE_REPEAT(state, payload) {
      // console.log(payload)
      state.repeatPhone = payload;
    },
    UPDATE_MAXPAGE_CONCTACT(state, payload) {
      // console.log(payload)
      state.maxPage = payload;
    },
    
    //###############
    //  PESQUISA
    //###############
    UPDATE_SEARCHWORD(state, payload) {
      state.searchWord = payload;
    },
    RESET_FILTER(state, clean) {
      state.searchWord = clean;
      state.filtered = clean;
    },
    // ############################
    //  SEARCH CONTACT URL
    //############################
    UPDATE_SEARCH_NAME(state, payload) {
        state.name = payload;
    },
    UPDATE_SEARCH_PHONE(state, payload) {
    state.phone = payload;
    },
    UPDATE_SEARCH_MAIL(state, payload) {
    state.mail = payload;
    },
    UPDATE_SEARCH_DESCRIPTION(state, payload) {
    state.description = payload;
    },
    // FILTERED_CONTACT(state, word) {
    //   // console.log(word)
    //   state.filtered = [];

    //   if (!(word) || word === '{}') {
    //     state.searchWord = null
    //     state.filtered = null
    //   } else {
    //     state.searchWord = word
    //     word = removeAccent(word.trim().toLowerCase())

    //     state.filtered = state.contacts
    //       .filter(contact => {
    //         let hasPhone = false
    //         let hasEmails = false
    //         contact.phones.filter(phone => { phone.includes(word) ? hasPhone = true : false })
    //         contact.emails.filter(phone => { phone.includes(word) ? hasEmails = true : false })
    //         return (contact.name != null && removeAccent(contact.name.toLowerCase().trim()).includes(word)) || (contact.description != null && removeAccent(contact.description.toLowerCase().trim()).includes(word)) || (contact.emails != null && hasEmails) || (contact.phones != null && hasPhone)
    //       })
    //   }
    // },
    //##############################
    //  MODAL DELETE
    //##############################
    UPDATE_MODAL_DELETE_PHONE(state, payload) {
      state.showModalDeletePhone = payload;
    },
    UPDATE_MODAL_DELETE_EMAIL(state, payload) {
      state.showModalDeleteEmail = payload;
    },
    UPDATE_SELECTED_ID(state, payload) {
      state.selectedId = payload;
    },
    UPDATE_MODAL_DELETE_CONTACT(state, payload) {
      state.showModalContact = payload;
    },

    //###############
    //  PAGINAÇÃO
    //###############
    UPDATE_TOTAL_PAGES(state, payload) {
      state.totalPages = payload;
    },
    //########################### 
    //    LOADING
    //###########################
    UPDATE_LOADING(state, payload) {
      state.loadingContact = payload;
    },
    //################################################################# 
    //       CONTACTO
    //#################################################################
    UPDATE_CONTACTS(state, payload) {
      // console.log(state.filtered)
      if (payload != null)
        state.contacts = payload.sort((a, b) => {
          let x = a.name.toUpperCase(),
            y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else state.contacts = payload
    },

    //################################################## 
    //    CONTACT
    //##################################################
    UPDATE_CONTACT(state, payload) {
      // console.log("getters", this.getters["contact/resetContact"])
      // state.contact = {}
      state.contact = this.getters["contact/resetContact"]
      payload == null ? state.contact = null : state.contact = Object.assign(state.contact, payload);
    },
    UPDATE_CONTACTNAME(state, payload) {
      // console.log("name", payload.name)
      state.contact.name = payload.name;
    },

    //##################################################
    //    INFORMAÇÕES DOS COLABORADORES  - MUTATIONS
    //##################################################
    UPDATE_EMAILS(state, payload) {
      state.emails = payload;
    },
    UPDATE_PHONES(state, payload) {
      state.phones = payload;
    },
    //#############################################################
    //    INFORMAÇÕES DOS COLABORADORES  -  CADASTRO E UPDATE
    //#############################################################
    UPDATE_EMAIL(state, payload) {
      state.email = Object.assign(state.email, payload);
    },
    UPDATE_PHONE(state, payload) {
      state.phone = Object.assign(state.phone, payload);
    },
    //######################################################################
    //    INFO COLABORADORES - CADASTRO - HABILITAR PARA MOSTRAR O FORM
    //######################################################################
    UPDATE_CREATE_EMAIL(state, payload) {
      state.createEmail = payload;
    },
    UPDATE_CREATE_PHONE(state, payload) {
      state.createPhone = payload;
    },
    
  },
  //########################################################################################
  //                 ACTION            
  //########################################################################################
  actions: {
    //############################################
    //       DELETE
    //############################################

    // Modal Email
    openModalDelPhone(context) {
      context.commit("UPDATE_MODAL_DELETE_PHONE", true);
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },
    closeModalDelPhone(context) {
      context.commit("UPDATE_MODAL_DELETE_PHONE", false);
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },
    // Modal Email
    openModalDelEmail(context) {
      context.commit("UPDATE_MODAL_DELETE_EMAIL", true);
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },
    closeModalDelEmail(context) {
      context.commit("UPDATE_MODAL_DELETE_EMAIL", false);
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },
    //Delete Phone - Delete 1 de 2(no component contact form)
    confirmDeletePhone(context, id) {
      context.commit("UPDATE_SELECTED_ID", id); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DELETE_PHONE", true);//abre o modal
    },
    //Delete Phone - Delete 1 de 2(no component contact form)
    confirmDeleteEmail(context, index) {
      context.commit("UPDATE_SELECTED_ID", index); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DELETE_EMAIL", true);//abre o modal
    },
     //######################################################################
    //   INPUTS- COLOCAR PESQUISA NO INPUT
    //######################################################################
    inputInfos(context) {
        // console.log(router.currentRoute.query)
        if (router.currentRoute.query.name) {
          context.commit("UPDATE_SEARCH_NAME", {
            name: router.currentRoute.query.name
          });
        }
        if (router.currentRoute.query.phone) {
          context.commit("UPDATE_SEARCH_PHONE", {
            phone: router.currentRoute.query.phone
          });
        }
        if (router.currentRoute.query.mail) {
          context.commit("UPDATE_SEARCH_MAIL",
            { mail: router.currentRoute.query.mail }
          );
        }
        if (router.currentRoute.query.description) {
          context.commit("UPDATE_SEARCH_DESCRIPTION", {
            description: router.currentRoute.query.description
          });
        }        
      },
  

    //##################################################
    //                 CONTACTOS  
    //##################################################

    // getContacts(context, refresh) {
    getContacts(context, {page, name, phone, mail,description}) {
    //   console.log(page)
    //   console.log(name)
    //   console.log(phone)
    //   console.log(mail)
    //   console.log(description)
      context.dispatch("inputInfos")
      context.commit("RESET_FILTER", null);
      context.commit("UPDATE_LOADING", true);
      // console.log("contato", router.currentRoute.query.contact)
    //   https://www.simpliesgoto.pt/api/contact/?page=1&name=araujo
    var searchContact = [
        {
          type: "name",
          item: name
        },
        {
          type: "description",
          item: description
        },
        {
          type: "phone",
          item: phone
        },
        {
          type: "mail",
          item: mail
        }        
      ]; 
      let urlContact = null
      let queryContact = null
      for (let index in searchContact) {
        if (searchContact[index].item != null) {
          if (urlContact == null) {
            urlContact = `${searchContact[index].type}=${searchContact[index].item}`
            queryContact = `${searchContact[index].type}: "${searchContact[index].item}"`
          }
          else {
            urlContact = urlContact + `&${searchContact[index].type}=${searchContact[index].item}`
            queryContact = queryContact + `, ${searchContact[index].type}: "${searchContact[index].item}"`
          }
        }
      }
      let urlApi = null
    //   let pageContact = router.currentRoute.query.page || 
    // console.log(page)
      if(page == null){
        urlApi = urlContact == null ? `/contact/` : `/contact/?${urlContact}`
      }else{
        urlApi = urlContact == null ? `/contact/?page=${page}` : `/contact/?page=${page}&${urlContact}`
      }    

    //   console.log(urlApi)
    return api.get(urlApi)
        .then(
        response => {
        // console.log(response.data)
        const contacts = response.data[1].map(function (contact) {
            contact.created = formatDate(new Date(contact.created));
            contact.modified = formatDate(new Date(contact.modified));
            return contact;
        });
        // console.log(response.data)
        context.commit("UPDATE_MAXPAGE_CONCTACT", response.data[0].maxPage)
        context.commit("UPDATE_CONTACTS", contacts);
        context.commit(
            "UPDATE_CURRENTPAGE",
            Number(router.currentRoute.query.page) || 1, { root: true } 
        );        
        // context.commit("FILTERED_CONTACT", router.currentRoute.query.contact || null);
        context.commit("UPDATE_LOADING", false);
        }
        )
        .catch(error => {
        context.commit("UPDATE_LOADING", false);
        console.log(error)
        const status = error.response.status
        context.dispatch("errorStatus", status, { root: true })
        })
   
    },

    //##################################################
    //    CONTACTO - INFORMAÇÕES - ACTIONS
    //##################################################

    getContact(context, { idContact }) {
      context.commit("UPDATE_LOADING", true);
      api.get(`/contact/${idContact}`)
        .then(response => {
          const contact = {}
          Object.keys(response.data).filter(key => {
            // ##### retornando todas as datas formatadas
            return ((key === "created") && (contact[key] = formatDate(new Date(response.data[key])))) ||
              ((key === "modified") && (contact[key] = formatDate(new Date(response.data[key])))) ||
              (contact[key] = response.data[key])
          })
          // console.log("contact", contact)
          context.commit("UPDATE_CONTACT", contact);
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          console.log("error", error.response)
          context.commit("UPDATE_LOADING", false);
          context.commit("UPDATE_CONTACT", null); //getter reset
          context.dispatch("errorStatus", error.response.status, { root: true })
        })
    },

    //########################################################################
    //            CADASTRAR CONTACTOS
    //########################################################################
    addContact(context, { contact, idModule, mutation }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log(contact.phones == 0 && contact.emails == 0)
      // console.log("context", context),
      // console.log("contact", contact),
      //   console.log("idModule", idModule),
      //   console.log("mutation", mutation)
      let page= this.getters["contact/pageReply"]; 
      let name = this.getters["contact/nameReply"];      
      let phone = this.getters["contact/phoneReply"];      
      let mail = this.getters["contact/mailReply"];      
      let description = this.getters["contact/descriptionReply"]; 
      context.commit("UPDATE_WAIT", true, { root: true });
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      if (contact.name == null || contact.name == "") {
        context.commit("UPDATE_WAIT", false, { root: true });
        context.commit("UPDATE_ERRORS", "full", { root: true });
      }
      else if (contact.description == null || contact.description == "") {
        context.commit("UPDATE_WAIT", false, { root: true });
        context.commit("UPDATE_ERRORS", "description", { root: true });
      }
      else if (contact.phones.length == 0 && contact.emails.length == 0) {
        context.commit("UPDATE_WAIT", false, { root: true });
        context.commit("UPDATE_ERRORS", "contactRequired", { root: true });
      } else {
        api.post(`/contact/`, contact)
          .then(response => {
            let idContact = response.data.id
            if (mutation != null && mutation != "") {
              context.dispatch(eval(mutation), { idModule, idContact }, { root: true })//eval() pegar o nome da constante e não o valor da sua variável.
            }
            context.commit("UPDATE_CONTACTS", null);
            // context.dispatch("refreshContacts");
            context.commit("UPDATE_CONTACT", this.getters["contact/contactReset"]);//limpa o formulário depois de cadastrar     
            context.commit("RESET_PHONE", null);
            context.commit("RESET_EMAIL", null);
            context.dispatch("getContacts", { name, phone, mail, description});
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Contacto adicionado com sucesso!",
            }, { root: true });
            context.commit("UPDATE_WAIT", false, { root: true });
          })
          .catch(error => {
            context.commit("UPDATE_WAIT", false, { root: true });
            // console.log(error.response.data)
            // console.log(error.response.data.error != "Name required.")
            // console.log(error.response.data.error != "Name required.")
            if (error.response.status === 400 && error.response.data.error === "Name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.status === 400 && error.response.data.error != "Name required.") {
              const repeatPhone = error.response.data.error.replace(/\D/gim, '');
              context.commit("UPDATE_PHONE_REPEAT", repeatPhone);
              context.commit("UPDATE_ERRORS", "phoneMsg", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },
    updateContact(context, { contact, idContact }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("contato", contact)
      let page= this.getters["contact/pageReply"]; 
      let name = this.getters["contact/nameReply"];      
      let phone = this.getters["contact/phoneReply"];      
      let mail = this.getters["contact/mailReply"];   
      context.commit("UPDATE_WAIT", true, { root: true });
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      if (contact.name == null || contact.name == "") {
        context.commit("UPDATE_WAIT", false, { root: true });
        context.commit("UPDATE_ERRORS", "full", { root: true });
      }
      else if (contact.description == null || contact.description == "") {
        context.commit("UPDATE_WAIT", false, { root: true });
        context.commit("UPDATE_ERRORS", "description", { root: true });
      }
      else if (contact.phones.length == 0 && contact.emails.length == 0) {
        context.commit("UPDATE_WAIT", false, { root: true });
        context.commit("UPDATE_ERRORS", "contactRequired", { root: true });
      } else {
        api.put(`/contact/${idContact}`, contact)
          .then(() => {
            // context.commit("UPDATE_CONTACTS", null);
            // context.commit("UPDATE_CONTACT", this.getters["contact/contactReset"]);//limpa o formulário depois de cadastrar     
            // context.dispatch("refreshContacts");
            context.dispatch("getContacts", { name, phone, mail, description});
            // context.dispatch("refreshContact", { idContact });
            context.dispatch("getContact", { idContact });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Contacto editado com sucesso!",
            }, { root: true });
            context.commit("UPDATE_WAIT", false, { root: true });
          })
          .catch(error => {
            context.commit("UPDATE_WAIT", false, { root: true });
            console.log(error.response.data)
            if (error.response.status === 400 && error.response.data.error === "Name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error != "Name required.") {
              const repeatPhone = error.response.data.error.replace(/\D/gim, '');
              context.commit("UPDATE_PHONE_REPEAT", repeatPhone);
              context.commit("UPDATE_ERRORS", "phone", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },

    updateContactName(context, { contact, idContact }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("contato", router.currentRoute.query.startDate || context.rootState.telecom.startDate.startDate,)

      context.commit("UPDATE_WAIT", true, { root: true });
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      if (contact.name == null || contact.name == "") {
        context.commit("UPDATE_WAIT", false, { root: true });
        context.commit("UPDATE_ERRORS", "full", { root: true });
      } else {
        api.put(`/contact/${idContact}`, contact)
          .then(() => {
            context.dispatch("telecom/getCalls", {
              startDate: router.currentRoute.query.startDate || context.rootState.telecom.startDate.startDate,
              endDate: router.currentRoute.query.endDate || context.rootState.telecom.endDate.endDate,
              fromPhone:
                Number.parseInt(router.currentRoute.query.phone) || context.rootState.telecom.fromPhone.fromPhone,
              description:
                router.currentRoute.query.description || context.rootState.telecom.description.description,
            }, { root: true });
            context.dispatch("getContacts", { name, phone, mail, description});
            context.dispatch("getContact", { idContact });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Nome alterado com sucesso!",
            }, { root: true });
            context.commit("UPDATE_WAIT", false, { root: true });
          })
          .catch(error => {
            context.commit("UPDATE_WAIT", false, { root: true });
            console.log(error.response)
            if (error.response.status === 400 && error.response.data.error === "Name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.status === 400 && error.response.data.error != "Name required.") {
              const repeatPhone = error.response.data.error.replace(/\D/gim, '');
              context.commit("UPDATE_PHONE_REPEAT", repeatPhone);
              context.commit("UPDATE_ERRORS", "phone", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },
    
    //  LIMPANDO FILTROS 
    //######################################################################

    // ##INPUTS- COLOCAR PESQUISA NO INPUT
    updateQuert() {
        const filter = {
          name: this.name,
          phone: this.phone,
          mail: this.mail,
          description: this.description,
        };
        const query = Object.entries(filter).reduce((acc, [key, val]) => {
          if (!val) return acc;
          return { ...acc, [key]: val };
        }, {});
        router.replace({ query: { ...query } }).catch(() => {});
      },

    // ##INPUTS- COLOCAR PESQUISA NO INPUT
    resetInputs(context) {
        // console.log("testando")
        // context.commit("UPDATE_FILTER_ITEM", false, { root: true });
        context.commit("UPDATE_CURRENTPAGE", 1, { root: true }); //ZERAR PAGINATION
        context.commit("UPDATE_SEARCH_NAME", this.getters["contact/nameReset"]);
        context.commit("UPDATE_SEARCH_PHONE", this.getters["contact/phoneReset"]);  
        context.commit("UPDATE_SEARCH_MAIL", this.getters["contact/mailReset"]);    
        context.commit("UPDATE_SEARCH_DESCRIPTION", this.getters["contact/descriptionReset"]);   
    },

    //    DELETE - ACTIONS
    //##################################################

    //MODAL DELETE TYPEVEHICLE 
    openModalDelContact(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_CONTACT", true);
    },
    closeModalDelContact(context) {
      context.commit("UPDATE_MODAL_DELETE_CONTACT", false);
    },
    //Delete - Delete 1 de 2
    confirmDeleteContact(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelContact");//abre o modal      
    },
    
    deleteContact(context, { id, redirect }) {        
      let page= this.getters["contact/pageReply"]; 
      let name = this.getters["contact/nameReply"];      
      let phone = this.getters["contact/phoneReply"];      
      let mail = this.getters["contact/mailReply"];      
      let description = this.getters["contact/descriptionReply"];      
      api.delete(`/contact/${id}`)
        .then(() => {
            // context.dispatch("updateQuert");
            context.dispatch("resetInputs");
            // context.commit("UPDATE_DELETECONTACT", id); //atualiza 
            context.dispatch("success", {        //cria as informações do texto do toast
                id: Date.now(),
                h1: "Successo!",
                body: "Contacto deletado com sucesso!",
            }, { root: true });
            if (redirect != undefined) {
                router.push({ name: "contactos" })
            }
        context.dispatch("getContacts", { name, phone, mail, description});
        })
        .catch(error => {
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
  },
}