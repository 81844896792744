<template>
  <section class="">
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Inspeções da Viatura</h3>
        <div v-if="vehicleManager || vehicleEdit">
          <router-link
            class="collabItens"
            :to="{
              name: 'adicionar-inspecao',
              params: {
                idVehicle: idVehicle,
              },
            }"
          >
            <AddUser>
              <span slot="title">
                Adicionar<br />
                Inspeção</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'viatura',
                params: { idVehicle: idVehicle },
              }"
              >Dados da Viatura
            </router-link>
          </li>
          <li class="breadcrumb-item-active">
            <span> Inspeções da Viatura</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingVehicle">
        <Loading />
      </div>
      <div v-else>
        <article v-if="vehicle" class="containerVehicle">
          <ul>
            <li
              class="items itemList"
              v-if="vehicle.registration && vehicle.registration.length > 0"
            >
              <span class="titleBlue">Matrícula</span>
              <div class="list">
                <span class="textGray">{{ vehicle.registration }}</span>
              </div>
            </li>
            <li
              class="items itemList"
              v-if="
                vehicle.nextRevisionDate && vehicle.nextRevisionDate.length > 0
              "
            >
              <span class="titleBlue">Próxima Inspeção</span>
              <div class="list">
                <!-- <span :class="validateNextInspection">{{ nextRevisionDate }}</span> -->
                <span :class="validateNextInspection">{{
                  vehicle.nextRevisionDate | formatDateReverse
                }}</span>
                <span class="validate">{{
                  vehicle.nextRevisionDate | expireDate
                }}</span>
                <!-- <span v-if="expireNextRevision" class="validate"
                  >(Próxima Inspeção vencida)</span
                > -->
              </div>
            </li>
          </ul>
        </article>
        <div v-if="inspections && inspections.length > 0">
          <span
            v-for="(inspection, i) in inspections"
            :key="inspection.id"
            class="textGray"
          >
            <!-- {{ inspection }} -->
            <ul v-if="i === 0" class="borderTable">
              <div class="titleRecent">
                <div>Último registro</div>
                <div class="revisionBtns">
                  <router-link
                    v-if="vehicleManager || vehicleEdit"
                    :to="{
                      name: 'editar-inspecao',
                      params: {
                        idVehicle: idVehicle,
                        idInspection: inspection.id,
                      },
                    }"
                  >
                    <button class="edit"></button>
                  </router-link>
                  <button
                    v-if="vehicleManager"
                    slot="delete"
                    class="delete"
                    @click.prevent="confirmDeleteInspection(inspection.id)"
                  ></button>
                </div>
              </div>
              <li class="items itemList">
                <span class="titleBlue">Data da Inspeção</span>
                <div class="list">
                  <span >{{
                    inspection.date | formatDateReverse
                  }}</span>
                  <!-- <span class="validate">{{
                    inspection.date | expireDate
                  }}</span> -->
                  <!-- <span v-if="expireRevision" class="validate"
                    >(Inspeção vencida)
                  </span> -->
                </div>
              </li>
              <li class="items itemList">
                <span class="titleBlue">Km</span>
                <div class="list">
                  <span>{{ inspection.km }}</span>
                </div>
              </li>
              <li class="items itemList">
                <span class="titleBlue">Custos</span>
                <div class="list">
                  <span>{{ inspection.costs | numberPrice }}</span>
                </div>
              </li>
              <li class="items itemList">
                <span class="titleBlue">Notas</span>
                <div class="list">
                  <span>{{ inspection.description }}</span>
                </div>
              </li>
              <li class="items itemList">
                <span class="titleBlue">Criado</span>
                <div class="list">
                  <span>{{ inspection.created | formatDateReverse }}</span>
                </div>
              </li>
              <li class="items itemList">
                <span class="titleBlue">Modificado</span>
                <div class="list">
                  <span>{{ inspection.modified | formatDateReverse }}</span>
                </div>
              </li>
              <li class="items containerFile">
                <span class="titleBlue">Ficheiros</span>

                <div v-if="loadingImg">
                  <LoadingCircle />
                </div>

                <div v-else>
                  <div
                    v-if="vehicleEdit || vehicleManager"
                    class="containerFile"
                  >
                    <div class="inputList">
                      <label for="fileInput" class="inputFile"
                        >Selecione um ficheiro
                        <span class="spot">clique aqui</span></label
                      >
                      <span v-if="selectedFile != null" class="itemFile"
                        >{{ selectedFile.name
                        }}<span
                          @click.prevent="cleanFile($event)"
                          class="clearFile"
                          >&#215;</span
                        ></span
                      >
                      <input
                        type="file"
                        @change="onFileSelected"
                        id="fileInput"
                        class="inputNone"
                      />
                    </div>
                    <button
                      class="btn addFile"
                      @click="onUpload(inspection.id)"
                    >
                      Adicionar
                    </button>
                  </div>
                </div>
              </li>
              <article>
                <div v-if="loadingImg"></div>
                <div v-else>
                  <div
                    v-if="
                      inspection.files != null && inspection.files.length > 0
                    "
                  >
                    <article
                      class="downloadImgLoading"
                      v-if="loadingDownloadImg"
                    >
                      Aguarde, fazendo download...
                    </article>
                    <ul v-else>
                      <li v-for="archive in inspection.files" :key="archive.id">
                        <article class="articleInfos">
                          <!-- <hr /> -->
                          <ArchiveItem
                            :archive="archive"
                            :idMicroservice="inspection.id"
                            @getFile="getFile(archive.filename, archive.id)"
                          >
                            <button
                              slot="delete"
                              class="delete"
                              @click.prevent="
                                confirmDeleteFileInspection(
                                  inspection.id,
                                  archive.id
                                )
                              "
                            ></button>
                          </ArchiveItem>
                        </article>
                      </li>
                    </ul>
                  </div>
                  <div v-else>
                    <p class="noResults noArchive">
                      Não possui ficheiros registrados
                    </p>
                  </div>
                </div>
              </article>
            </ul>
          </span>
          <!-- <hr /> -->
          <article
            v-if="
              inspections.length > 1 || (filtered != null && insurances.length)
            "
          >
            <h3 class="titleInfos">Histórico de Inspeções</h3>
            <InspectionSearch />
            <ul>
              <li
                v-for="(inspection, index) in filteredInspections"
                :key="inspection.id"
              >
                <article
                  class="articleInfos"
                  v-if="
                    (filtered === null && index > 0) ||
                    (filtered != null && index >= 0)
                  "
                >
                  <!-- {{ inspection }} -->
                  <InspectionItem
                    :inspection="inspection"
                    :idInspection="inspection.id"
                    :idVehicle="idVehicle"
                    :typeVehicles="typeVehicles"
                    :index="index"
                  >
                    <div v-if="loadingImg">
                      <LoadingCircle />
                    </div>
                    <div v-else slot="fileArchive">
                      <div
                        v-if="vehicleEdit || vehicleManager"
                        class="containerFile"
                      >
                        <div class="inputList">
                          <label for="fileInput" class="inputFile"
                            >Selecione um ficheiro
                            <span class="spot">clique aqui</span></label
                          >
                          <span v-if="selectedFile != null" class="itemFile"
                            >{{ selectedFile.name
                            }}<span @click.prevent="cleanFile" class="clearFile"
                              >&#215;</span
                            ></span
                          >
                          <input
                            type="file"
                            @change="onFileSelected"
                            id="fileInput"
                            class="inputNone"
                          />
                        </div>
                        <button
                          class="btn addFile"
                          @click="onUpload(inspection.id)"
                        >
                          Adicionar
                        </button>
                      </div>
                    </div>
                    <article slot="listArchive">
                      <div v-if="loadingImg"></div>
                      <div v-else>
                        <div
                          v-if="
                            inspection.files != null &&
                            inspection.files.length > 0
                          "
                        >
                          <article
                            class="downloadImgLoading"
                            v-if="loadingDownloadImg"
                          >
                            Aguarde, fazendo download...
                          </article>
                          <ul v-else>
                            <li
                              v-for="archive in inspection.files"
                              :key="archive.id"
                            >
                              <article class="articleInfos">
                                <!-- <hr /> -->
                                <ArchiveItem
                                  :archive="archive"
                                  :idMicroservice="inspection.id"
                                  @getFile="
                                    getFile(archive.filename, archive.id)
                                  "
                                >
                                  <button
                                    slot="delete"
                                    class="delete"
                                    @click.prevent="
                                      confirmDeleteFileInspection(
                                        inspection.id,
                                        archive.id
                                      )
                                    "
                                  ></button>
                                </ArchiveItem>
                              </article>
                            </li>
                          </ul>
                        </div>
                        <div v-else>
                          <p class="noResults noArchive">
                            Não possui ficheiros registrados
                          </p>
                        </div>
                      </div>
                    </article>
                    <button
                      slot="delete"
                      class="delete"
                      @click.prevent="confirmDeleteInspection(inspection.id)"
                    ></button>
                  </InspectionItem>
                </article>
              </li>
            </ul>
          </article>
        </div>
        <div v-else>
          <p class="noResults">Não possui inpeções registrados</p>
        </div>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteInspection"
        @click="closeModalDelInspection"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteInspection">
        <button class="close btn" @click="closeModalDelInspection">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover esta Inspeção?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelInspection"
          />
          <button class="btnDel" @click.prevent="deleteInspection">
            Delete
          </button>
        </div>
      </div>
    </div>
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteFile"
        @click="closeModalDelFileInspection"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteFile">
        <button class="close btn" @click="closeModalDelFileInspection">
          x
        </button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este ficheiro?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelFileInspection"
          />
          <button class="btnDel" @click.prevent="deleteFileInspection">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import InspectionSearch from "@/components/vehicle/InspectionSearch.vue";
import InspectionItem from "@/components/vehicle/InspectionItem.vue";
import ArchiveItem from "@/components/vehicle/ArchiveItem.vue";
import { alertDate } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";
export default {
  name: "InspectionVehicle",
  props: ["idVehicle"],
  data() {
    return {
      searchHistory: false,
    };
  },
  components: {
    InspectionItem,
    InspectionSearch,
    ArchiveItem,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "loadingDownloadImg",
      "selectedId",
      "loadingImg",
    ]),
    ...mapState("user", ["user"]),
    ...mapState("vehicle", [
      "vehicle",
      "inspections",
      "typeVehicles",
      "loadingVehicle",
      "showModalDeleteInspection",
      "showModalDeleteFile",
      "filtered",
      "selectedFile",
      "archiveID",
      "fileID",
    ]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),
    ...mapGetters("vehicle", ["getFiltered"]),
    filteredInspections() {
      const a = this.getFiltered || this.inspections;
      return a;
    },
    //##################################################
    //                NEXT INSPECTIONS
    //##################################################

    validateNextInspection() {
      return alertDate(new Date(this.vehicle.nextInspectionDate), 30)
        ? "proximitDate"
        : "nothing";
    },

  },

  methods: {
    //######################################################
    //     FILE ARQUIVO PADRÂO
    //######################################################
    getFile(filename, archiveID) {
      this.$store.dispatch("attachment/getFile", {
        archiveID: `${archiveID}`,
        fileName: `${filename}`,
      });
    },
    cleanFile() {
      this.$store.commit("vehicle/UPDATE_SELECTEDFILE", null);
    },
    onFileSelected(event) {
      this.$store.commit("vehicle/UPDATE_SELECTEDFILE", event.target.files[0]);
    },

    //######################################################
    //     UPLOAD FILE ARQUIVO - INSPECTION
    //######################################################
    onUpload(id) {
      const formData = new FormData();

      formData.append("file", this.selectedFile, this.selectedFile.name);
      this.$store.dispatch("vehicle/onUploadInspection", {
        data: formData,
        idInspection: `${id}`,
        idVehicle: this.idVehicle,
        namefile: this.selectedFile.name,
      });
    },
    //######################################################
    //    ARQUIVOS MODAL DELETE
    //######################################################
    openModalDelFileInspection() {
      this.$store.dispatch("vehicle/openModalDelFileInspection");
    },
    closeModalDelFileInspection() {
      this.$store.dispatch("vehicle/closeModalDelFileInspection");
    },
    //######################################################
    //     ARQUIVOS DELETE
    //######################################################
    confirmDeleteFileInspection(id, archiveID) {
      this.$store.dispatch("vehicle/confirmDeleteFileInspection", {
        id: `${id}`,
        archiveID: `${archiveID}`,
      });
    },
    deleteFileInspection() {
      this.$store.dispatch("vehicle/deleteFileInspection", {
        idInspection: this.selectedId,
        archiveID: this.archiveID,
      });
      this.closeModalDelFileInspection();
    },
    openModalDelInspection() {
      this.$store.dispatch("vehicle/openModalDelInspection");
    },
    closeModalDelInspection() {
      this.$store.dispatch("vehicle/closeModalDelInspection");
    },
    //######################################################
    //     DELETE
    //######################################################
    confirmDeleteInspection(id) {
      this.$store.dispatch("vehicle/confirmDeleteInspection", {
        id: `${id}`,
      });
    },
    deleteInspection() {
      this.$store.dispatch("vehicle/deleteInspection", {
        id: this.idVehicle,
        idInspection: this.selectedId.id,
      });
      this.closeModalDelInspection();
    },
  },

  created() {
    this.$store.dispatch("vehicle/getVehicle", {
      idVehicle: this.idVehicle,
    });
    this.$store.dispatch("vehicle/getInspections", {
      idVehicle: this.idVehicle,
    });
  },
};
</script>

<style scoped>
.proximitDate {
  color: red;
}
.validate {
  margin-left: 10px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}
/* files */
.clearFile {
  font-size: 24px;
  line-height: 28px;
  margin-left: 10px;
  font-weight: bold;
  color: #00732c;
  padding-top: 5px;
  cursor: pointer;
}
.containerFile {
  display: flex;
  align-content: center;
  align-items: baseline;
  /* grid-gap: 10px; */
  margin: 10px 0 0 0;
}
.inputNone {
  display: none;
}
.noArchive {
  margin: 20px 0 30px 0;
  margin-left: 10px;
}

.spot {
  font-size: 14px;
  color: #015eb0;
}
.inputList {
  display: grid;
}
/*INPUT FILE botão e arquivo */
.inputFile {
  cursor: pointer;
  font-size: 14px;
  width: 240px;
  border: 1px solid #015eb0;
  background: #eff7fe;
  border-radius: 9px;
  padding: 10px;
  color: #1f2b42;
  margin: 3px 10px 0 0;
  border-style: dotted;
}
/* fim files  */

.titleRecent {
  font-size: 20px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 10px;
  color: #ccc;
  font-weight: bold;
}
.borderTable {
  /* border: 1px solid #ccc; */
  /* background: rgb(245, 240, 240); */
  box-shadow: 0 4px 8px rgb(30 60 90 / 20%);
  padding: 20px 10px;
  border-radius: 9px;
}
.containerVehicle {
  margin-bottom: 30px;
}
.revisionBtns {
  display: grid;
  grid-template-columns: repeat(2, 40px);
  justify-content: flex-end;
  justify-items: flex-end;
  grid-gap: 5px;
  margin-right: 10px;
}

.titleInfos {
  margin-top: 20px;
  margin-bottom: 10px;
}

.callsHistory {
  margin-top: 20px;
}

li article.articleInfos:nth-child(n + 1) {
  margin-top: 10px;
}
.iconInfoSearchHistory {
  margin: 0 10px;
}
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .items li {
    padding: 0 0 0 10px;
  }
  .list {
    padding: 0 10px;
  }
  .inputFile {
    margin: 3px 10px 0 10px;
  }
}
@media screen and (max-width: 390px) {
  .containerFile {
    display: grid;
    grid-gap: 10px;
  }
}
</style>
