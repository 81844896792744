<template>
  <section class="">
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Dados do tipo do Cliente</h3>
        <div v-if="clientEdit">
          <router-link
            class="collabItens"
            :to="{
              name: 'editar-tipo-cliente',
              params: {
                idTypeClient: idTypeClient,
              },
            }"
          >
            <AddUser>
              <span slot="title">
                Editar<br />
                Tipo de Cliente</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'clientes' }"
              >Lista de Clientes</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'tipos-clientes' }"
              >Lista de Tipos de Clientes</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Dados do Tipo de Cliente</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingClient">
        <Loading />
      </div>
      <div v-else>
        <article v-if="typeClient">
          <!-- <pre>{{ typeClient }}</pre> -->
          <ul class="borderTable">
            <li class="items itemList" v-if="typeClient.name">
              <span class="titleBlue">Nome</span>
              <div class="list">
                <span class="textGray">{{ typeClient.name }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="typeClient.description">
              <span class="titleBlue">Descrição</span>
              <div class="list">
                <span class="textGray">{{ typeClient.description }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="typeClient.created">
              <span class="titleBlue">Criado</span>
              <div class="list">
                <span class="textGray">{{
                  typeClient.created | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ createdClient }}</span> -->
              </div>
            </li>
            <li class="items itemList" v-if="typeClient.modified">
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <span class="textGray">{{
                  typeClient.modified | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ modifiedClient }}</span> -->
              </div>
            </li>
          </ul>
        </article>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ContactoInternoInfos",
  props: ["idTypeClient"],
  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId"]),
    ...mapState("user", ["user"]),
    ...mapState("client", [
      "typeClient",
      "typeClients",
      "clients",
      "loadingClient",
      "showModalDeleteClient",
    ]),
    ...mapGetters("user", ["clientEdit"]),
  },
  methods: {},
  created() {
    this.$store.dispatch("client/getTypeClient", {
      idTypeClient: this.idTypeClient,
    });
  },
};
</script>

<style scoped>
.itemListVehicle {
  padding: 9px 0;
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  /* justify-content: space-between; */
  border-bottom: 1px solid #cbcbcb;
}
button.btn-form {
  margin: 20px auto;
}
.collabItens:hover {
  background: #dbebf3;
}

.collabItens {
  margin: 10px 0;
}
.borderTable {
  margin: 20px 0;
  box-shadow: 0 4px 8px rgb(30 60 90 / 20%);
  padding: 20px 10px;
  border-radius: 9px;
}

.item2 {
  padding-left: 15px;
}
.containerLink {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.articleInfos,
.callsHistory {
  margin-bottom: 30px;
}

.titleInfos {
  margin-bottom: 10px;
}

.iconInfoSearchHistory {
  margin: 0 10px;
}
.otherService,
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
}
</style>
