<template>
  <div>
    <div class="toasts">
      <div class="toast" v-for="item in itemsError" :key="item.id">
        <div @click="close" class="close">x</div>
        <div class="toastContent">
          <h1 class="titleNotify">{{ item.h1 }}</h1>
          <span>{{ item.body }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";

export default {
  props: ["itemsError"],
  computed: {
    ...mapState(["msgError"]),
    ...mapActions(["removeNotifyError"]),
  },
  methods: {
    close() {
      this.removeNotifyError;
    },
  },
};
</script>

<style scoped>
@media screen and (max-width: 560px) {
  .toasts {
    right: 0.5rem !important;
  }
}

button {
  max-width: 150px;
}
.toasts {
  position: fixed;
  bottom: 0;
  right: 1rem;
  overflow: hidden;
  padding-left: 1rem;
  color: #fff;
  font-family: arial;
  z-index: 9999;
}

.titleNotify {
  margin: 0;
  padding: 0;
  color: #fff;
  font-size: 1rem;
  text-align: initial;
}

.toast {
  display: grid;
  align-items: center;
  margin: 0 0 1rem;
  padding: 1rem 2rem;
  background: #dc3545;
  width: 20rem;
  height: 100px;
  border-radius: 2px;
  box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

.close {
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  margin-bottom: -25px;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }
  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }
  to {
    bottom: 0;
    opacity: 0;
  }
}
</style>