<template>
  <div>
    <h1>Moradas</h1>
    <div class="contentGeneral">
      <div class="titleContainer">
        <h3>Editar Morada</h3>
      </div>
      <nav class="navBreadcrumb" v-if="router == 'Client'">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'clientes' }"
              >Lista de Clientes</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'cliente',
                params: { idClient: idRouter },
              }"
              >Dados do Cliente</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Editar Morada do Cliente</span>
          </li>
        </ol>
      </nav>
      <nav class="navBreadcrumb" v-else-if="router == 'ProcessOpposite'">
        <ol class="breadcrumb">
          <slot />
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos',
                   query: { archived: 'false'} }"
              >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'intervenientes'
              }"
              >Lista de Intervenientes</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
               name: 'interveniente',
                    params: { idOpposite: idRouter },
              }"
              >Dados do Interveniente</router-link
            >
          </li>          
          <li class="breadcrumb-item-active">
            <span> Editar Contacto do Interveniente</span>
          </li>
        </ol>
      </nav>
      <nav class="navBreadcrumb" v-else>
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'moradas' }"
              >Lista de Moradas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'morada',
                params: { idAddress: idAddress },
              }"
              >Dados da Morada</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Editar Morada</span>
          </li>
        </ol>
      </nav>
      <div class="itemFormCollaborator">
        <AddressForm :address="address" :errors="errors">
          <div slot="cancel"></div>
          <div slot="delete" class="btnJustify">
            <button class="btn" @click.prevent="updateAddress">Gravar</button>
          </div>
        </AddressForm>
      </div>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </div>
</template>

<script>
import AddressForm from "@/components/address/AddressForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditAddress",
  props: ["idAddress", "idRouter", "router"],
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("address", ["address", "confirmForm"]),
    ...mapGetters("address", ["addressReply"]),
  },
  components: {
    AddressForm,
  },
  methods: {
    updateAddress() {
      this.$store.dispatch("address/updateAddress", {
        idAddress: this.idAddress,
        address: this.addressReply,
      });
    },
  },
  created() {
    this.$store.dispatch("address/getAddress", {
      idAddress: this.idAddress,
    });    
    this.$store.commit("address/UPDATE_CONFIRMFORM", true);
  },
};
</script>

<style scoped>
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

.btnJustify {
  display: grid;
  justify-content: flex-end;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
.itemFormCollaborator {
  margin-left: 10px;
}
</style>