import axios from "axios";
// import Cookies from 'js-cookie';

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL
});

axiosInstance.interceptors.request.use(
  function (config) {
    const token = localStorage.getItem('tokenCookie');
    // const token = Cookies.get('tokenCookie');
    if (token) {
      config.headers.jwt = token
      // configImg.headers.jwt = token
    }
    return config;
  },
  function (error) {
    return Promise.reject(error);
  }
)

const config = {
  headers: {
    'Content-Type': 'application/json',
  }
}

// const configPostFile = {
//   headers: {
//     'filename': '',
//   }
// }

const configGetFile = {
  methods: 'GET',
  responseType: 'blob'
}

// var raw = null

// const configGoogle = {
//   // method: 'GET',  
//   data: `${raw}`,
// }

export const api = {
  get(endpoint) {
    return axiosInstance.get(endpoint);
  },

  post(endpoint, body) {
    return axiosInstance.post(endpoint, body)
  },

  put(endpoint, body) {
    return axiosInstance.put(endpoint, body)
  },

  delete(endpoint) {
    return axiosInstance.delete(endpoint);
  },

  deleteData(endpoint, body) {
    return axiosInstance.delete(endpoint, body);
  },

  login(endpoint, body) {
    // console.log(body);
    return axiosInstance.post(endpoint, body, config);
  },

  image(endpoint, body, headers) {
    // console.log(body);
    return axiosInstance.post(endpoint, body, headers);
  },
  getFile(endpoint) {
    return axiosInstance.get(endpoint, configGetFile);
  },
  getGoogle(endpoint, body) {
    // configGoogle.data = JSON.stringify(body)
    // configGoogle.data = body
    // console.log(configGoogle.data)
    // return axiosInstance.get(endpoint, configGoogle)
    return axiosInstance.get(endpoint, body)
  }
};
