<template>
  <div
    class="choice"
    @click="$emit('input', !value)"
    :class="{ activated: value, disabled: !value }"
  >
    <div class="btnChoice"></div>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      // required:true
    },
  },
};
</script>

<style scoped>
.choice {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 15px;
  width: 40px;
  border-radius: 20px;
  border: 1px solid #aaa;
}

.btnChoice {
  position: absolute;
  margin: 0px 5px;
  height: 25px;
  width: 25px;
  border-radius: 14px;
  background: #fff;
  box-shadow: 0px 0px 5px #000b;
}

.disabled {
  background-color: #aaa;
}

.activated {
  background-color: #adddb9;
}

.disabled .btnChoice {
  left: -10px;
}

.activated .btnChoice {
  background-color: #28a745;
  align-self: flex-end;
  left: 10px;
}
</style>
