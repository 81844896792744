var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{},[_c('div',{staticClass:"containerContact"},[_c('div',{staticClass:"titleContainer"},[_c('h3',[_vm._v("Dados do Evento")]),(_vm.telecomManager)?_c('div',[_c('router-link',{staticClass:"collabItens",attrs:{"to":{
            name: 'editar-mensagem',
            params: {
              idMessage: _vm.idMessage,
              iduser: _vm.message.telegramWatchers,
            },
          }}},[_c('AddUser',[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Editar"),_c('br'),_vm._v(" Evento")])])],1)],1):_vm._e()]),_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'mensagens' }}},[_vm._v("Lista de Eventos")])],1),_vm._m(0)])]),(_vm.loadingMessage)?_c('div',[_c('Loading')],1):_c('div',[(_vm.message)?_c('article',[_c('ul',[(_vm.message.description)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Descrição")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.message.description))])])]):_vm._e(),(_vm.message.created)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Criado")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm._f("formatDateReverse")(_vm.message.created)))])])]):_vm._e(),(_vm.message.modified)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Modificado")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm._f("formatDateReverse")(_vm.message.modified)))])])]):_vm._e(),_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Usuários Notificados")]),(_vm.users)?_c('div',_vm._l((_vm.users),function(user,i){return _c('ul',{key:user,staticClass:"items"},[_c('li',{staticClass:"textGray"},[_c('span',[_vm._v(_vm._s(user))]),(i + 1 < _vm.users.length)?_c('span',[_vm._v(", ")]):_vm._e()])])}),0):_c('div',[_c('p',{staticClass:"textGray"},[_vm._v("Não possui eventos")])])])])]):_vm._e()])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Dados do Evento")])])
}]

export { render, staticRenderFns }