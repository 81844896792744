<template>
  <section class="containerLink">
    <div class="info">{{ phone }}</div>
    <div v-if="contactEdit" class="delete"><slot name="delete" /></div>
  </section>
</template>


<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "PhoneItem",
  props: ["idContact", "phone"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["contactEdit"]),
  },
};
</script>


<style scoped>
.containerLink {
  display: grid;
  grid-template-columns: minmax(280px, 550px) 100px;
  padding: 5px 0;
  align-items: center;
  cursor: pointer;
}
.delete {
  display: grid;
  justify-content: flex-start;
  padding: 5px 0px;
  margin: 0 10px;
}
@media screen and (max-width: 930px) {
  .delete {
    margin: 0 7px;
  }
}
</style>