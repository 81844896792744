<template>
  <section class="itemContainer">
    <div class="textGray">
      <!-- <div
        v-if="call.contactName != '' && call.contactName != null"
        class="nameContact"
        @click.prevent="redirectContactName"
      >
        <img
          src="../../assets/svg/userContact.svg"
          alt="icone histórico de chamada"
        />
        <span>{{ call.contactName }}</span>
      </div> -->
      <router-link
        v-if="call.contactName != '' && call.contactName != null"
        class="nameContact"
        :to="{
          name: 'contacto',
          params: { idContact: call.contactID },
          query: { phone: this.call.fromPhone}
        }"
      >
          <!-- query: { phone: this.redirectPhoneInt }, -->
        <img
          src="../../assets/svg/userContact.svg"
          alt="icone histórico de chamada"
        />
        <span>{{ call.contactName }}</span>
      </router-link>

      <div v-else class="nameContact">
        <slot name="addName" />
        <router-link
          :to="{
            name: 'contacto',
            params: { idContact: call.contactID },
            query: { phone: this.call.fromPhone}
          }"
        >
            <!-- query: { phone: this.redirectPhoneInt }, -->
          <span>Adicionar nome</span>
        </router-link>
        <!-- <div @click.prevent="redirectContactName">
          <span>Adicionar nome</span>
        </div> -->
      </div>
    </div>
    <div class="collabItens">
      <span class="titleBlue">De</span>
      <p class="routePhone" @click.prevent="searchPhone">
        {{ call.fromPhone }}
      </p>
      <span class="titleBlue">Para</span>
      <!-- <p>{{ call.toPhone }}</p> -->
      <p class="routePhone" @click.prevent="searchDescription">
        {{ call.description }}
      </p>
      <span class="titleBlue">Anotações</span>
      <p class="tooltip">
        <slot name="note" />
        <span v-if="checkNote" class="tooltiptext">
          <slot name="tooltip" />
        </span>
      </p>
      <span class="titleBlue">Data</span>
      <!-- <p>{{ call.created }}</p> -->
      <p>{{ createdAtTelecom }}</p>
      <span class="titleBlue">Duração</span>
      <p>{{ duration }}</p>
      <span class="titleBlue">Gravação</span>
      <p class="noAudio">
        <span v-if="call.recordingUrl">
          <audio :src="call.recordingUrl" controls="controls"></audio>
        </span>
        <span v-else> Não possui gravação. </span>
      </p>
      <button
        v-if="call.recordingUrl"
        class="btnAudio"
        @click="audioDownload(call.recordingUrl)"
      >
        <svg
          class="iconAudio"
          xmlns="http://www.w3.org/2000/svg"
          width="23.634"
          height="23.632"
          viewBox="0 0 23.634 23.632"
        >
          <g transform="translate(-320.076 -384.637)">
            <path
              class="a"
              d="M331.893,384.637A11.809,11.809,0,1,0,343.71,396.45,11.743,11.743,0,0,0,331.893,384.637ZM339.44,404a10.67,10.67,0,1,1,3.129-7.546A10.6,10.6,0,0,1,339.44,404Z"
            />
            <path
              class="a"
              d="M331.314,398.1a.738.738,0,0,0,1.159,0c.7-.714,1.411-1.411,2.115-2.117.285-.285.428-.615.108-.946s-.654-.194-.936.1c-.366.383-.726.771-1.169,1.243-.015-.112-.027-.217-.04-.325a.615.615,0,0,0,.026-.129v-4.786a.684.684,0,1,0-1.367,0v4.786a.615.615,0,0,0,.026.129c-.013.108-.025.213-.04.325-.443-.472-.8-.86-1.169-1.243-.281-.3-.608-.44-.936-.1s-.177.661.108.946C329.9,396.688,330.617,397.385,331.314,398.1Z"
            />
            <path
              class="a"
              d="M337.046,398.45a.685.685,0,0,0-.684.684v.941h-.007v.458a.911.911,0,0,1-.91.91H328.34a.911.911,0,0,1-.91-.91v-.458h-.007v-.941a.684.684,0,0,0-1.368,0v1.4a2.288,2.288,0,0,0,2.285,2.285h7.105a2.288,2.288,0,0,0,2.285-2.285v-1.4A.685.685,0,0,0,337.046,398.45Z"
            />
          </g>
        </svg>
      </button>
    </div>
  </section>
</template>  

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "TelecomCallItem",
  props: ["call"],
  computed: {
    ...mapState(["errors", "items", "loadingCalls", "limit", "currentPage"]),
    ...mapState("user", ["user"]),
    ...mapState("telecom", ["startDate", "endDate"]),
    ...mapGetters("telecom", [
      "startDateReply",
      "endDateReply",
      "descriptionReply",
      "phoneReply",
      "descriptionReset",
    ]),
    //######################################################
    //     PERMISSÕES do COLABORADOR
    //######################################################
    telecomManager() {
      return this.telecomManagerPermission();
    },
    createdAtTelecom() {
      const [date, time] = this.call.created.split(" " && "T");
      // Dividindo a data em dia, mês e ano:
      const [DD, MM, YYYY] = date.split("-");
      // Dividindo o tempo em hora e minutos:
      const [HH, mm] = time.split(":");
      // Retornando a data formatada em um padrão compatível com ISO:
      return `${YYYY}-${MM}-${DD}  ${HH}:${mm}`;
    },
    checkNote() {
      if (this.call.note == null || this.call.note == "") {
        return false;
      } else {
        return true;
      }
    },
    duration() {
      // Dividindo os segundos em hora/minuto/segundo:
      const h = Math.floor(this.call.duration / 3600)
          .toString()
          .padStart(2, "0"),
        m = Math.floor((this.call.duration % 3600) / 60)
          .toString()
          .padStart(2, "0"),
        s = Math.floor(this.call.duration % 60)
          .toString()
          .padStart(2, "0");
      return h + ":" + m + ":" + s;
    },
    // redirectPhoneInt() {
    //   return Number.parseInt(this.call.fromPhone);
    // },
  },
  methods: {
    // NAME CONTACTO
    // redirectContactName() {
    //   const phone = Number.parseInt(this.call.fromPhone);
    //   this.$router.push({
    //     name: "contacto",
    //     params: { idContact: this.call.contactID },
    //     query: { phone: phone },
    //   });
    // },
    //#################################################################################################
    // Contacto ou description Search da lista dispara aqui. Catch previne o "NavigationDuplicated"
    //#################################################################################################
    // searchItem() {
    //   this.$router.replace({ query: {} });
    //   this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
    //   // this.getSearch();
    //   // this.$router.push({
    //   //   query: { phone: Number.parseInt(this.call.fromPhone) },
    //   // });
    //   const filter = {
    //     startDate: this.startDateReply,
    //     endDate: this.endDateReply,
    //     phone: Number.parseInt(this.call.fromPhone),
    //     description: this.call.description,
    //   };
    //   const query = Object.entries(filter).reduce((acc, [key, val]) => {
    //     if (!val) return acc;
    //     return { ...acc, [key]: val };
    //   }, {});
    //   // console.log("query", query);
    //   this.$router.push({ query: { ...query } }).catch(() => {});
    // },
    resetQuery() {
      if (
        this.$route.query.phone ||
        this.$route.query.startDate ||
        this.$route.query.endDate ||
        this.$route.query.description
        // ||
        // this.$route.query.page
      ) {
        this.$router.replace({ query: {} });
      }
    },
    searchPhone() {
      this.resetQuery();
      this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
      // this.getSearch();
      this.$router.push({
        query: { phone: this.call.fromPhone },
      });
        // query: { phone: Number.parseInt(this.call.fromPhone) },
    },
    searchDescription() {
      this.resetQuery(); //fix NavigationDuplication
      this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
      // this.getSearch();
      this.$router.push({
        query: { description: this.call.description },
      });
    },

    audioDownload(audio) {
      var fileUrl = audio;
      console.log(fileUrl);
      var fileLink = document.createElement("a");
      fileLink.href = fileUrl;

      fileLink.setAttribute("download", "image.jpg");
      document.body.appendChild(fileLink);

      fileLink.click();
    },

    // getCalls() {
    //   this.$store.dispatch("telecom/getCalls", {
    //     startDate: this.startDateReply,
    //     endDate: this.endDateReply,
    //     fromPhone: this.fromPhoneReply,
    //     description: this.descriptionReply,
    //   });
    // },
    // getSearch() {
    //   if (
    //     this.$route.query.phone ||
    //     this.$route.query.startDate ||
    //     this.$route.query.endDate ||
    //     this.$route.query.description
    //     // ||
    //     // this.$route.query.page
    //   ) {
    //     this.$router.replace({ query: {} });
    //     this.getCalls();
    //   } else {
    //     this.getCalls();
    //   }
    // },
  },
  created() {},
};
</script>

<style scoped>
button.btnAudio {
  border: none;
  background: none;
  width: 40px;
}
.btnAudio {
  cursor: pointer;
}
.iconAudio {
  fill: #389832;
}

.textGray {
  font-size: 15px;
}
.noAudio {
  height: 53.98px;
  display: flex;
  align-items: center;
}
.routePhone {
  cursor: pointer;
}

audio {
  overflow: hidden;
  outline: none;
  width: 250px;
}

.nameContact {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  cursor: pointer;
}

.nameContact span:hover {
  text-decoration: underline;
}

.itemContainer {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
  width: 100%;
  /* overflow: auto; */
}

.collabItens {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 260px 50px;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;

  grid-gap: 5px;
}

.collabItens:hover {
  background: #dbebf3;
}

.collabItens p {
  padding: 0 10px;
  font-size: 14px;
}

.titleBlue {
  display: none;
}

@media screen and (max-width: 690px) {
  .collabItens {
    display: grid;
    grid-template-columns: 70px 1fr 70px 1fr;
    /* grid-template-columns: repeat(4, 1fr); */
    margin: 0 10px;
    /* grid-template-columns: 90px 1fr; */
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 20px;
    font-size: 14px;
  }
  .titleBlue {
    display: block;
    font-size: 14px;
    margin: 10px 0;
  }
  audio {
    width: 250px;
  }
}

@media screen and (max-width: 490px) {
  .collabItens {
    grid-template-columns: 90px 1fr;
  }
  audio {
    width: 220px;
  }
}
</style>
