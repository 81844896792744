<template>
  <section>
    <h1>Viaturas</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Lista de Tipos de Clientes</h3>
        <div class="menuDropdown" v-if="clientEdit">
          <router-link
            class="collabItens"
            :to="{
              name: 'adicionar-tipo-cliente',
            }"
          >
            <AddUser>
              <span slot="title">
                Adicionar<br />
                Tipo de Cliente</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'clientes' }"
              >Lista de Clientes</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Lista de Tipos de Clientes</span>
          </li>
        </ol>
      </nav>
      <article>
        <div v-if="loadingClient">
          <Loading />
        </div>
        <div v-else>
          <div v-if="typeClients && typeClients.length > 0">
            <TypeClientSearch />
            <div class="titleCollab">
              <p class="titleBlue">Nome</p>
              <p class="titleBlue">Descrição</p>
            </div>
            <hr />
            <ul>
              <li
                class="itemList"
                v-for="typeClient in filteredTypeClients"
                :key="typeClient.id"
              >
                <TypeClientItem
                  :typeClient="typeClient"
                  :idTypeClient="typeClient.id"
                >
                  <button
                    slot="delete"
                    class="delete"
                    @click.prevent="confirmDeleteTypeClient(typeClient.id)"
                  ></button>
                </TypeClientItem>
              </li>
            </ul>
          </div>
          <div v-else>
            <p class="noResults">Não possui tipos de clientes registradas</p>
          </div>
        </div>
      </article>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteTypeClient"
        @click="closeModalDelTypeClient"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteTypeClient">
        <button class="close btn" @click="closeModalDelTypeClient">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover esta tipo de cliente?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelTypeClient"
          />
          <button class="btnDel" @click.prevent="deleteTypeClient">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import TypeClientSearch from "@/components/client/TypeClientSearch.vue";
import TypeClientItem from "@/components/client/TypeClientItem.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "TypeClients",
  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId"]),
    ...mapState("user", ["user"]),
    ...mapState("client", [
      "typeClients",
      "loadingClient",
      "showModalDeleteTypeClient",
    ]),
    ...mapGetters("user", ["clientEdit"]),
    ...mapGetters("client", ["getFiltered"]),
    filteredTypeClients() {
      const a = this.getFiltered || this.typeClients;
      return a;
    },
  },
  components: {
    TypeClientItem,
    TypeClientSearch,
  },
  methods: {
    openModalDelTypeClient() {
      this.$store.dispatch("client/openModalDelTypeClient");
    },
    closeModalDelTypeClient() {
      this.$store.dispatch("client/closeModalDelTypeClient");
    },
    //######################################################
    //     DELETE
    //######################################################
    confirmDeleteTypeClient(id) {
      this.$store.dispatch("client/confirmDeleteTypeClient", {
        id: `${id}`,
      });
    },
    deleteTypeClient() {
      this.$store.dispatch("client/deleteTypeClient", {
        id: this.selectedId.id,
      });
      this.closeModalDelTypeClient();
    },
  },
  created() {
    this.$store.dispatch("client/getTypeClients");
  },
};
</script>

<style scoped>
.modal_container {
  max-width: 450px;
}
.titleContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}

.titleCollab {
  display: grid;
  /* grid-template-columns: repeat(10, 130px); */
  /* grid-template-columns: 200px 150px 70px 90px repeat(2, 100px); */
  /* grid-template-columns: 350px 380px; */
  grid-template-columns: 250px minmax(200px, 380px);
  /* grid-gap: 5px; */
}

.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  min-width: 350px;
  max-width: 1000px;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  /* margin-bottom: 30px; */
}

.titleBlue {
  margin: 0 5px;
}
h3 {
  text-align: initial;
  margin-left: 10px;
}
@media screen and (max-width: 695px) {
  .titleBlue {
    display: none;
  }
  .containerTelecom {
    margin: 80px 10px 20px 10px;
  }
}
</style>
