<template>
  <section>
    <h1>Viaturas</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Lista de Modelos de Viaturas</h3>
        <div class="menuDropdown" v-if="vehicleEdit || vehicleManager">
          <router-link
            class="collabItens"
            :to="{
              name: 'adicionar-modelo',
            }"
          >
            <AddUser>
              <span slot="title">
                Adicionar<br />
                Modelo</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Lista de Modelos de Viaturas</span>
          </li>
        </ol>
      </nav>
      <article>
        <div v-if="loadingVehicle">
          <Loading />
        </div>
        <div v-else>
          <div v-if="models  && models.length > 0">
            <!-- {{ typeVehicles }} -->
            <ModelSearch />
            <div class="titleCollab">
              <p class="titleBlue">Nome</p>
              <!-- <p class="titleBlue">Descrição</p> -->
            </div>
            <hr />
            
            <ul>
              <li
                class="itemList"
                v-for="model in filteredModels"
                :key="model.id"
              >              
                <ModelItem
                  :model="model"
                  :idModel="model.id"
                >
                  <button
                    slot="delete"
                    class="delete"
                    @click.prevent="confirmDeleteModel(model.id)"
                  ></button>
                </ModelItem>
              </li>
            </ul>
          </div>
          <div v-else>
            <p class="noResults">Não possui modelos de viaturas registradas</p>
          </div>
        </div>
      </article>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteModel"
        @click="closeModalDelModel"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteModel">
        <button class="close btn" @click="closeModalDelModel">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este modelo de viatura?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelModel"
          />
          <button class="btnDel" @click.prevent="deleteModel">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import ModelSearch from "@/components/vehicle/ModelSearch.vue";
import ModelItem from "@/components/vehicle/ModelItem.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddContact",
  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId"]),
    ...mapState("user", ["user"]),
    ...mapState("vehicle", [
      "models",
      "loadingVehicle",
      "showModalDeleteModel",
    ]),
    ...mapGetters("user", [
      "vehicleAccess",
      "vehicleManager",
      "vehicleAnalysis",
      "vehicleEdit",
    ]),
    ...mapGetters("vehicle", ["getFiltered"]),
    filteredModels() {
      const a = this.getFiltered || this.models;
      return a;
    },
  },
  components: {
    ModelItem,
    ModelSearch,
  },
  methods: {
    //######################################################
    //     DELETE
    //######################################################
    openModalDelModel() {
      this.$store.dispatch("vehicle/openModalDelModel");
    },
    closeModalDelModel() {
      this.$store.dispatch("vehicle/closeModalDelModel");
    },
    confirmDeleteModel(id) {
      // console.log("id", id)
      this.$store.dispatch("vehicle/confirmDeleteModel", {
        id: `${id}`,
      });
    },
    deleteModel() {
      this.$store.dispatch("vehicle/deleteModel", {
        id: this.selectedId.id,
      });
      this.closeModalDelModel();
    },
  },
  created() {
    this.$store.dispatch("vehicle/getModels");
  },
};
</script>

<style scoped>
.modal_container {
  max-width: 450px;
}
.titleContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}

.titleCollab {
  display: grid;
  /* grid-template-columns: repeat(10, 130px); */
  /* grid-template-columns: 200px 150px 70px 90px repeat(2, 100px); */
  /* grid-template-columns: 350px 380px; */
  grid-template-columns: 250px minmax(200px, 380px);
  /* grid-gap: 5px; */
}

.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  min-width: 350px;
  max-width: 1000px;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  /* margin-bottom: 30px; */
}

.titleBlue {
  margin: 0 5px;
}
h3 {
  text-align: initial;
  margin-left: 10px;
}
@media screen and (max-width: 695px) {
  .titleBlue {
    display: none;
  }
  .containerTelecom {
    margin: 80px 10px 20px 10px;
  }
}
</style>
