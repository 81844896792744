<template>
  <section>
    <h1>Central de Eventos</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Lista de Eventos</h3>
        <!-- <div class="menuDropdown" v-if="staffEdit"> -->
        <div class="menuDropdown" v-if="telecomManager">
          <BtnConfig>
            <span slot="title">
              Controlo de <br />
              Eventos</span
            >
            <ul slot="itemsMenu" class="menuDropdownContent">
              <div class="titleMenuDropdown">Evento</div>
              <li>
                <router-link
                  :to="{
                    name: 'adicionar-mensagem',
                  }"
                  >Adicionar</router-link
                >
              </li>
              <div class="titleMenuDropdown">Telegram</div>
              <li>
                <router-link
                  :to="{
                    name: 'usuarios-telegram',
                  }"
                  >Usuários</router-link
                >
              </li>
            </ul>
          </BtnConfig>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item-active">
            <span> Lista de Eventos</span>
          </li>
        </ol>
      </nav>
      <article>
        <div v-if="loadingMessage">
          <Loading />
        </div>
        <div v-else>
          <div v-if="filteredMessages">
            <MessageSearch />
            <div class="titleCollab">
              <p class="titleBlue">Descrição</p>
              <p class="titleBlue">Usuários do Telegram</p>
            </div>
            <hr />
            <ul v-for="message in filteredMessages" :key="message.id">
              <li class="itemList">
                <MessageItem :message="message" :idMessage="message.id">
                  <button
                    slot="delete"
                    class="delete"
                    @click.prevent="confirmDeleteMessage(message.id)"
                  ></button>
                </MessageItem>
              </li>
            </ul>
          </div>
          <div v-else>
            <p class="noResults">Não possui eventos cadastrados</p>
          </div>
        </div>
      </article>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteMessage"
        @click="closeModalDelMessage"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteMessage">
        <button class="close btn" @click="closeModalDelMessage">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este evento?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelMessage"
          />
          <button class="btnDel" @click.prevent="deleteMessage">Delete</button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import MessageSearch from "@/components/message/MessageSearch.vue";
import MessageItem from "@/components/message/MessageItem.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddContact",
  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId"]),
    ...mapState("user", ["user"]),
    ...mapState("message", [
      "messages",
      "message",
      "loadingMessage",
      "showModalDeleteMessage",
    ]),
    ...mapGetters("user", ["telecomAccess", "telecomManager"]),
    ...mapGetters("message", ["messageReset", "messageReply", "getFiltered"]),

    filteredMessages() {
      const a = this.getFiltered || this.messages;
      return a;
    },
  },
  components: {
    MessageItem,
    MessageSearch,
  },
  methods: {
    openModalDelMessage() {
      this.$store.dispatch("message/openModalDelMessage");
    },
    closeModalDelMessage() {
      this.$store.dispatch("message/closeModalDelMessage");
    },
    //######################################################
    //     DELETE
    //######################################################
    confirmDeleteMessage(id) {
      this.$store.dispatch("message/confirmDeleteMessage", {
        id: `${id}`,
      });
    },
    deleteMessage() {
      this.$store.dispatch("message/deleteMessage", {
        id: this.selectedId.id,
      });
      this.closeModalDelMessage();
    },
  },
  created() {
    this.$store.dispatch("message/getMessages");
  },
};
</script>

<style scoped>
.modal_container {
  max-width: 450px;
}
.titleContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

.titleCollab {
  display: grid;
  /* grid-template-columns: repeat(10, 130px); */
  /* grid-template-columns: 200px 150px 70px 90px repeat(2, 100px); */
  grid-template-columns: 350px 380px;
}

.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  /* margin-bottom: 30px; */
}

.titleBlue {
  margin: 0 5px;
}
h3 {
  text-align: initial;
  /* margin-left: 10px; */
}
@media screen and (max-width: 695px) {
  .titleBlue {
    display: none;
  }
  .containerTelecom {
    margin: 80px 10px 20px 10px;
  }
}
</style>
