import { api } from "@/services.js";
import router from "@/router";
import { removeAccent } from "@/helpers.js";
import { formatDate } from "@/helpers.js";

export default {
  namespaced: true,
  state: {
    //############################
    //       CLIENT            
    //############################
    clients: null,
    client: {
      name: null,
      nif: null,
      clientTypeID: null,
      addresses: [],
      contacts: [],
    },
    typeClients: null,
    typeClient: {
      name: null,
      description: null,
    },
    contactID: {
      contactID: null,
    },
    //############################
    //         LOADING            
    //############################
    loadingClient: false,
    //############################
    //  PESQUISA
    //############################
    searchWord: null,
    filtered: null,
    //############################
    //  MODAL            
    //############################
    showModalClient: false,
    showModalDelContact: false,
    showModalContact: false,
    showModalDeleteTypeClient: false,
  },
  //########################################################################################
  //                 GETTERS            
  //########################################################################################
  getters: {
    getFiltered: (state) => state.filtered,
    //############################
    //          CLIENT            
    //############################
    clientReply(state) {
      return {
        "name": state.client.name,
        "nif": state.client.nif,
        "clientTypeID": state.client.clientTypeID,
      }
    },
    clientReset() {
      return {
        name: null,
        nif: null,
        clientTypeID: null,
      }
    },
    typeClientReply(state) {
      return {
        "name": state.typeClient.name,
        "description": state.typeClient.description,
      }
    },
    typeClientReset() {
      return {
        name: null,
        description: null,
      }
    },
    contactIDReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "contactID": state.contactID.contactID,
      }
    },
    contactIDReset() { //Enviar somente estas informações para cadastro e edição.
      return {
        contactID: null,
      }
    },
    otherContacts(state, getters, rootState) {
      let active = null;
      if (rootState.contact.filtered != null) {
        active = rootState.contact.filtered
      } else {
        active = rootState.contact.contacts;
      }
      if (active != null) {
        for (let index in state.client.contact) {
          active = active.filter(
            (contact) => contact.id != state.client.contacts[index].id
          );
        }
      }
      return active;
    },
    otherAddresses(state, getters, rootState) {
      let active = null;
      if (rootState.address.filtered != null) {
        active = rootState.address.filtered
      } else {
        active = rootState.address.addresses;
        // console.log("otherAddresses", rootState.address.addresses)
      }
      if (active != null) {
        for (let index in state.client.adress) {
          active = active.filter(
            (contact) => contact.id != state.client.adresses[index].id
          );
        }
      }
      return active;
    },
    // otherTypeClients(state) {
    // //  return console.log("router", router.currentRoute.name)
    // console.log("router", state.typeClients != null &&
    // state.client.clientType != null)
    //   var active;
    //   if (state.typeClients != null &&
    //     state.client.clientType != null) {       
      
    //       active = state.typeClients.filter(
    //         (typeClient) => typeClient.name != state.client.clientType
    //       );
     
    //   }
    //  return active;
    // },
    //###############
    //  PAGINAÇÃO
    //###############
    paginatedData(state, getters, rootState) {
      // console.log("rootState.currentPage", rootState.currentPage)//acessando o state da global
      let start = (rootState.currentPage - 1) * rootState.limit,
        end = start + rootState.limit;
      if (state.filtered) {
        return state.filtered.slice(start, end);
      } else {

        return state.clients.slice(start, end);
      }
    },
  },
  mutations: {

    //########################### 
    //    LOADING
    //###########################
    UPDATE_LOADING(state, payload) {
      state.loadingClient = payload;
    },
    //################################################################# 
    //       CLIENT
    //#################################################################
    UPDATE_CLIENTS(state, payload) {
      // state.clients = payload
      if (payload != null && payload != "")
        state.clients = payload.sort((a, b) => {
          let x = a.name.toUpperCase(),
            y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.clients = payload
    },
    UPDATE_CLIENT(state, payload) {
      state.client = Object.assign(state.client, payload);
    },
    UPDATE_TYPECLIENTS(state, payload) {
      state.typeClients = payload
      if (payload != null && payload != " ")
        state.typeClients = payload.sort((a, b) => {
          let x = a.name.toUpperCase(),
            y = b.name.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else
        state.typeClients = payload
    },
    UPDATE_TYPECLIENT(state, payload) {
      state.typeClient = Object.assign(state.typeClient, payload);
    },
    UPDATE_DELETE_TYPECLIENT(state, id) {
      let typeClients = state.typeClients.filter(c => c.id != id)
      state.typeClients = typeClients;
    },
    UPDATE_MODAL_DELETE_TYPECLIENT(state, payload) {
      state.showModalDeleteTypeClient = payload;
    },
    UPDATE_MODAL_CLIENT(state, payload) {
      state.showModalClient = payload;
    },
    UPDATE_SELECTED_ID(state, payload) {
      state.selectedId = payload;
    },
    UPDATE_DELETE_CLIENT(state, clientsId) {
      let clients = state.clients.filter(c => c.id != clientsId)
      state.clients = clients;
    },
    UPDATE_MODAL_CONTACT(state, payload) {
      state.showModalContact = payload;
    },
    UPDATE_MODAL_DEL_CONTACT(state, payload) {
      state.showModalDelContact = payload;
    },
    //###############
    //  PESQUISA
    //###############
    RESET_FILTER(state, clean) {
      state.searchWord = clean;
      state.filtered = clean;
    },
    FILTERED_CLIENT(state, word) {
      state.filtered = [];

      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())

        state.filtered = state.clients
          .filter(client => {
            return (client.name != null && removeAccent(client.name.toLowerCase().trim()).includes(word)) ||
              (client.nif != null && removeAccent(client.nif.toString().toLowerCase().trim()).includes(word)) ||
              (client.clientType != null && removeAccent(client.clientType.toLowerCase().trim()).includes(word))
          })
      }
    },
    //      FILTERED TYPE CLIENTS
    //#################################################################
    FILTERED_TYPECLIENTS(state, word) {
      state.filtered = [];

      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())

        state.filtered = state.typeClients
          .filter(typeClient => {
            return (typeClient.name != null && removeAccent(typeClient.name.toLowerCase().trim()).includes(word)) || (typeClient.description != null && removeAccent(typeClient.description.toLowerCase().trim()).includes(word))
          })
      }
    },
  },
  actions: {
    getClients(context, refresh, associate) {
      // context.commit("UPDATE_LOADING", true);
      if (!associate) {
        context.commit("UPDATE_LOADING", true);
      }
      if (context.state.clients === null || refresh != undefined) {
        api.get(`/client/`)
          .then(
            response => {
              const clients = response.data.map(function (client) {
                client.created = formatDate(new Date(client.created));
                client.modified = formatDate(new Date(client.modified));
                return client;
              });
              // console.log(client)
              context.commit("UPDATE_CLIENTS", clients);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_CLIENTS", context.state.clients);
        context.commit("UPDATE_LOADING", false);
      }
    },
    //##################################################
    //    CLIENT - INFORMAÇÕES - ACTIONS
    //##################################################

    getClient(context, { idClient }) {
      context.commit("UPDATE_LOADING", true);
      api.get(`/client/${idClient}`)
        .then(
          response => {
            const client = {}
            Object.keys(response.data).filter(key => {
              // ##### retornando todas as datas formatadas
              return ((key === "created") && (client[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (client[key] = formatDate(new Date(response.data[key])))) ||
                (client[key] = response.data[key])
            })
            // console.log(client)
            context.commit("UPDATE_CLIENT", client);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })

    },
    //########################################################################
    //            CADASTRAR CLIENT
    //########################################################################
    addClient(context, { client }) {
      // console.log(typeof client.nif != "number")
      context.commit("RESET_ERRORS", [], { root: true });
      context.commit("UPDATE_LOADING", true);
      if (typeof client.nif != "number") {
        context.commit("UPDATE_LOADING", false);
        context.commit("UPDATE_ERRORS", "nifNumber", { root: true });        
      } else {        
        api.post(`/client/`, client)
          .then(response => {
            const idClient = response.data.id
            context.dispatch("getClients", "refresh");
            context.dispatch("getClient", { idClient });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Cliente adicionado com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_CLIENT", context.getters["clientReset"]);//limpa o formulário depois de cadastrar        
            context.commit("UPDATE_LOADING", false);
            router.push({ name: "cliente", params: { idClient } })
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)          
            if (error.response.data.error === "NIF already exist") {
              context.commit("UPDATE_ERRORS", "nifExisty", { root: true });
            }
            if (error.response.data.error === "Nif length not 9") {
              context.commit("UPDATE_ERRORS", "nif", { root: true });
            }
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "type required.") {
              context.commit("UPDATE_ERRORS", "type", { root: true });
            }
            if (error.response.data.error === "nif required.") {
              context.commit("UPDATE_ERRORS", "nifRequired", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },
    //   UPDATE - VEHICLE - ACTIONS
    //##################################################
    updateClient(context, { client, idClient }) {
      context.commit("UPDATE_LOADING", true);
      if (typeof client.nif != "number") {
        context.commit("UPDATE_LOADING", false);
        context.commit("UPDATE_ERRORS", "nifNumber", { root: true });
      } else {
        api.put(`/client/${idClient}`, client)
          .then(() => {
            context.dispatch("getClients", "refresh");
            context.dispatch("getClient", { idClient, refreshId: "refreshId" });
            // context.dispatch("getTypeClients", "refresh");
            // context.commit("UPDATE_CLIENT", context.getters["clientotherTypeClientsReset"]);
            // context.commit("UPDATE_VEHICLE", null);
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Cliente editado com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_LOADING", false);
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            if (error.response.data.error === "NIF already exist") {
              context.commit("UPDATE_ERRORS", "nifExisty", { root: true });
            }
            if (error.response.data.error === "Nif length not 9") {
              context.commit("UPDATE_ERRORS", "nif", { root: true });
            }
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "type required.") {
              context.commit("UPDATE_ERRORS", "type", { root: true });
            }
            if (error.response.data.error === "nif required.") {
              context.commit("UPDATE_ERRORS", "nifRequired", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },
    //############################################
    //        TYPE CLIENTS
    //############################################
    getTypeClients(context, refresh) {
      // context.commit("UPDATE_LOADING", true);
      context.commit("UPDATE_LOADING", true);     
      if (context.state.typeClients === null || refresh != undefined) {
        api.get(`/client/type/all`)
          .then(
            response => {
              const typeClients = response.data.map(function (typeClient) {
                typeClient.created = formatDate(new Date(typeClient.created));
                typeClient.modified = formatDate(new Date(typeClient.modified));
                return typeClient;
              });
              context.commit("UPDATE_TYPECLIENTS", typeClients);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_TYPECLIENTS", context.state.typeClients);
        context.commit("UPDATE_LOADING", false);
      }
    },

    //############################################
    //        TYPE CLIENT
    //############################################
    getTypeClient(context, { idTypeClient }) {
      context.commit("UPDATE_LOADING", true);
      api.get(`/client/type/${idTypeClient}`)
        .then(
          response => {
            const typeClient = {}
            Object.keys(response.data).filter(key => {
              // ##### retornando todas as datas formatadas
              return ((key === "created") && (typeClient[key] = formatDate(new Date(response.data[key])))) ||
                ((key === "modified") && (typeClient[key] = formatDate(new Date(response.data[key])))) ||
                (typeClient[key] = response.data[key])
            })
            context.commit("UPDATE_TYPECLIENT", typeClient);
            context.commit("UPDATE_LOADING", false);
          })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    addTypeClient(context, { typeClient }) {
      context.commit("RESET_ERRORS", [], { root: true });
      // if (typeClient.name == null || typeClient.name == "") {
      //   context.commit("UPDATE_ERRORS", "full", { root: true });
      // } else if (typeClient.description == null || typeClient.description == "") {
      //   context.commit("UPDATE_ERRORS", "description", { root: true });
      // } else {
        api.post(`/client/type`, typeClient)
          .then(() => {
            context.dispatch("getTypeClients", "refresh");
            // context.commit("UPDATE_VEHICLE", null);
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Tipo de Cliente adicionado com sucesso!",
            }, { root: true });
            context.commit("RESET_ERRORS", [], { root: true });
            context.commit("UPDATE_LOADING", false);
            router.push({ name: "tipos-clientes" })
          })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            if (error.response.data.error === "name required.") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "ClientType name already exist") {
              context.commit("UPDATE_ERRORS", "nameExist", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      // }
    },
    //   UPDATE - TYPEVEHICLE - ACTIONS
    //##################################################
    updateTypeClient(context, { typeClient, idTypeClient }) {
      context.commit("UPDATE_LOADING", true);
      api.put(`/client/type/${idTypeClient}`, typeClient)
        .then(() => {
          context.dispatch("getTypeClients", "refresh");
          context.dispatch("getTypeClient", { idTypeClient });
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Tipo de Cliente editado com sucesso!",
          }, { root: true });
          context.commit("RESET_ERRORS", [], { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response)
          if (error.response.data.error === "name required.") {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          if (error.response.data.error === "Name already exist") {
            context.commit("UPDATE_ERRORS", "nameExist", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },


    //    DELETE - SERVICES TYPECLIENT - ACTIONS
    //##################################################

    //MODAL DELETE TYPECLIENT 
    openModalDelTypeClient(context) {
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      context.commit("UPDATE_MODAL_DELETE_TYPECLIENT", true);
    },
    closeModalDelTypeClient(context) {
      context.commit("UPDATE_MODAL_DELETE_TYPECLIENT", false);
    },

    //Delete - Delete 1 de 2
    confirmDeleteTypeClient(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId para confirmação
      context.dispatch("openModalDelTypeClient");//abre o modal      
    },

    //Delete - Delete 2 de 2
    deleteTypeClient(context, { id }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/client/type/${id}`)
        .then(() => {
          context.commit("UPDATE_DELETE_TYPECLIENT", id); //atualiza 
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Tipo de Cliente deletado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          if (error.response.status) {
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          }
        })
    },

    //############################################
    //       DELETE
    //############################################

    //       MODAL - DELETE 
    openModalClient(context) {
      context.commit("UPDATE_MODAL_CLIENT", true);
    },
    closeModalDelClient(context) {
      context.commit("UPDATE_MODAL_CLIENT", false);
    },

    //Confirmação abrindo modal Delete - Delete 1 de 2
    confirmDeleteClient(context, id) {
      context.commit("UPDATE_SELECTED_ID", id, { root: true }); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_CLIENT", true);//abre o modal
    },

    //Delete - Delete 2 de 2
    deleteClient(context, { id, redirect }) {
      // console.log(context)
      // console.log("id", id)
      context.commit("UPDATE_LOADING", true, { root: true });
      api.delete(`/client/${id}`)
        .then(() => {
          // context.commit("UPDATE_DELETE_CLIENT", id); //atualiza 
          context.dispatch("getClients", "refresh");
          if (redirect != undefined) {
            router.push({ name: "clientes" })
          }
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Cliente deletado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false, { root: true });
          // console.log("deletou", id)
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //##############################################################
    //    UPDATE ADDRESS - associando address ao cliente
    //##############################################################
    updateAddressClient(context, { idModule, idAddress }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("contextUser", context)
      // console.log("idModule", idModule)
      // console.log("idAddress", idAddress)
      context.commit("UPDATE_LOADING_ADDRESS", true, { root: true });
      api.put(`client/${idModule}/address/${idAddress}`)
        .then(() => {
          context.dispatch("getClient", { idClient: idModule, refreshId: "refreshId", address: "address" });
          // context.dispatch("success", {        //cria as informações do texto do toast
          //   id: Date.now(),
          //   h1: "Successo!",
          //   body: "Morada adicionada com sucesso!",
          // }, { root: true });
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    deleteAddressClient(context, { idClient, id }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("context", context)
      // console.log("idClient", idClient)
      // console.log("id", id)
      context.commit("UPDATE_LOADING_ADDRESS", true, { root: true });
      api.delete(`client/${idClient}/address/${id}`)
        .then(() => {
          context.dispatch("getClients", { refresh: "refresh", associate: "associate" });
          context.dispatch("getClient", { idClient, refreshId: "refreshId", address: "address" });
          // context.dispatch("address/deleteAddress", { id }, { root: true })
          // context.dispatch("success", {        //cria as informações do texto do toast
          //   id: Date.now(),
          //   h1: "Successo!",
          //   body: "Morada adicionada com sucesso!",
          // }, { root: true });
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //############################################
    //       MODAL - ADICIONAR CONTACT   
    //############################################
    openModalContact(context) {
      context.commit("UPDATE_MODAL_CONTACT", true);
    },
    closeModalContact(context) {
      context.commit("UPDATE_MODAL_CONTACT", false);
    },


    updateContactClient(context, { idModule, idContact }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("contextUser", context)
      // console.log("idModule", idModule)
      // console.log("idContact", idContact)
      context.commit("contact/UPDATE_LOADING", true, { root: true });
      api.put(`client/${idModule}/contact/${idContact}`)
        .then(() => {
          context.dispatch("getClients", { refresh: "refresh", associate: "associate" });
          context.dispatch("getClient", { idClient: idModule, refreshId: "refreshId", associate: "associate" });
          context.commit("UPDATE_MODAL_CONTACT", false);
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Contacto adicionado com sucesso!",
          }, { root: true });
          context.commit("contact/UPDATE_LOADING", false, { root: true });
        })
        .catch(error => {
          context.commit("contact/UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //############################################
    //       MODAL - DELETE CONTACT   
    //############################################

    openModalDelContactClient(context) {
      context.commit("UPDATE_MODAL_DEL_CONTACT", true);
    },
    closeModalDelContactClient(context) {
      context.commit("UPDATE_MODAL_DEL_CONTACT", false);
    },
    //Delete - Delete 1 de 2
    confirmDeleteContactClient(context, id) {
      context.commit("UPDATE_SELECTED_ID", id); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DEL_CONTACT", true);//abre o modal
    },
    //Delete - Delete 2 de 2 - desassociando
    deleteContactClient(context, { idClient, id }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("context", context)
      // console.log("idClient", idClient)
      // console.log("id", id)
      context.commit("contact/UPDATE_LOADING", true, { root: true });
      api.delete(`client/${idClient}/contact/${id}`)
        .then(() => {
          context.dispatch("getClients", { refresh: "refresh", associate: "associate" });
          context.dispatch("getClient", { idClient, refreshId: "refreshId", associate: "associate" });
          context.dispatch("contact/getContacts", { refresh: "refresh" }, { root: true });
          // context.dispatch("contact/deleteContact", { id }, { root: true })
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Contacto deletado com sucesso!",
          }, { root: true });
          context.commit("contact/UPDATE_LOADING", false, { root: true });
        })
        .catch(error => {
          context.commit("contact/UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

  }

}