<template>
  <form action="">
    <div id="topSearch">
      <div class="formInfos">
        <div>
          <label for="startDate"> Data Início: </label>
          <input
            id="startDate"
            type="date"
            v-model="startDate"
            @keyup.enter="searchCalls"
          />
        </div>
        <div>
          <label for="endDate"> Data Fim: </label>
          <input
            id="endDate"
            type="date"
            v-model="endDate"
            @keyup.enter="searchCalls"
          />
        </div>
        <div>
          <label for="fromPhone"> Telefone: </label>
          <input
            id="fromPhone"
            name="phone"
            type="text"
            v-model="fromPhone"
            autocomplete="off"
            @keyup.enter="searchCalls"
          />
            <!-- inputmode="decimal" -->
        </div>
        <div>
          <label for="description"> Descrição: </label>
          <input
            id="description"
            name="description"
            type="text"
            v-model.trim="description"
            autocomplete="off"
            @keyup.enter="searchCalls"
          />
        </div>
        <div>
          <label for="note"> Notas: </label>
          <input
            id="note"
            name="note"
            type="text"
            v-model.trim="note"
            autocomplete="off"
            @keyup.enter="searchCalls"
          />
        </div>
        <!-- <div class="button" @keyup.enter="getSearch" @click.prevent="getSearch"> -->
        <div class="buttonSearch">
          <div
            class="button"
            @keyup.enter="searchCalls"
            @click.prevent="searchCalls"
          >
            <BtnSearch />
          </div>
          <div class="button" @click.prevent="reset">
            <BtnReset />
          </div>
        </div>
      </div>
      <!-- <div>
      <button @click.prevent="reset">reset</button>
    </div> -->
      <!-- {{ startDateReply }}{{ endDateReply }}{{ fromPhoneReply
      }}{{ descriptionReply }} -->
      <ul class="searchActiveContainer" v-if="msgFilter">
        <div class="filterActive" v-if="msgFilter">Filtro activo:</div>
        <article v-if="startDateReply" class="searchActive">
          <div>Data início</div>
          <div class="closeSearch" @click.prevent="removeSearchStartDate">
            x
          </div>
        </article>
        <article v-if="endDateReply" class="searchActive">
          <div>Data Fim</div>
          <div class="closeSearch" @click.prevent="removeSearchEndDate">x</div>
        </article>
        <article v-if="fromPhoneReply" class="searchActive">
          <div>Telefone</div>
          <div class="closeSearch" @click.prevent="removeSearchPhone">x</div>
        </article>
        <article v-if="descriptionReply" class="searchActive">
          <div>Descrição</div>
          <div class="closeSearch" @click.prevent="removeSearchDescription">
            x
          </div>
        </article>
        <article v-if="noteReply" class="searchActive">
          <div>note</div>
          <div class="closeSearch" @click.prevent="removeSearchNote">x</div>
        </article>
      </ul>
      <!-- <div v-if="msgFilter">Filtro Activo!</div> -->
      <!-- </div> -->
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapGetters, mapState } from "vuex";

export default {
  name: "CallSearchForm",
  computed: {
    // ...mapState(["loadingTelecomPhone"]),
    ...mapFields({
      fields: ["startDate"], //nome dos campos
      module: "telecom", //qual módulo está acessando
      base: "startDate", //colocar o state que vai receber
      mutation: "telecom/UPDATE_STARTDATE", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["endDate"], //nome dos campos
      module: "telecom", //qual módulo está acessando
      base: "endDate", //colocar o state que vai receber
      mutation: "telecom/UPDATE_ENDDATE", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["fromPhone"], //nome dos campos
      module: "telecom", //qual módulo está acessando
      base: "fromPhone", //colocar o state que vai receber
      mutation: "telecom/UPDATE_FROMPHONE", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["description"], //nome dos campos
      module: "telecom", //qual módulo está acessando
      base: "description", //colocar o state que vai receber
      mutation: "telecom/UPDATE_DESCRIPTION", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["note"], //nome dos campos
      module: "telecom", //qual módulo está acessando
      base: "note", //colocar o state que vai receber
      mutation: "telecom/UPDATE_NOTE", //nome da mutation que vai alterar o state
    }),
    // startDate1() {
    //   var startDate = this.startDate;
    //   return startDate;
    // },
    ...mapState("telecom", ["variantPhones"]),
    ...mapGetters("telecom", [
      "startDateReply",
      "endDateReply",
      "fromPhoneReply",
      "startDateReset",
      "endDateReset",
      "fromPhoneReset",
      "descriptionReply",
      "descriptionReset",
      "noteReply",
      "noteReset",
    ]),
    msgFilter() {
      return this.startDateReply != null ||
        this.endDateReply != null ||
        this.fromPhoneReply != null ||
        this.descriptionReply != null ||
        this.noteReply != null
        ? true
        : false;
    },
  },
  methods: {
    removeSearchStartDate() {
      this.$store.commit("telecom/UPDATE_STARTDATE", this.startDateReset);
      // this.$router.push({ query: {} });
      this.searchCalls();
    },
    removeSearchEndDate() {
      this.$store.commit("telecom/UPDATE_ENDDATE", this.endDateReset);
      // this.$router.push({ query: {} });
      this.searchCalls();
    },
    removeSearchPhone() {
      this.$store.commit("telecom/UPDATE_FROMPHONE", this.fromPhoneReset);
      // this.$router.push({ query: {} });
      this.searchCalls();
    },
    removeSearchDescription() {
      this.$store.commit("telecom/UPDATE_DESCRIPTION", this.descriptionReset);
      // this.$router.push({ query: {} });
      this.searchCalls();
    },
    removeSearchNote() {
      this.$store.commit("telecom/UPDATE_NOTE", this.noteReset);
      // this.$router.push({ query: {} });
      this.searchCalls();
    },
    //#############################################
    // Fix bug do "@keyup.enter" duplicado
    //#############################################
    // searchDescription() {
    //   this.searchCalls();
    // },
    //#############################
    // Botão Search dispara aqui
    //#############################

    searchCalls() {
      this.getSearch();
      this.updateQuery();
    },

    //######################################################################################
    // Input - Aqui verifica quais items das busca foram preenchidos e coloca na rota
    //######################################################################################
    updateQuery() {
      const filter = {
        startDate: this.startDateReply,
        endDate: this.endDateReply,
        phone: this.fromPhoneReply,
        description: this.descriptionReply,
        note: this.noteReply,
        page: Number(this.$route.query.page),
      };
      const query = Object.entries(filter).reduce((acc, [key, val]) => {
        if (!val) return acc;
        return { ...acc, [key]: val };
      }, {});
      // console.log("query", query);
      this.$router.replace({ query: { ...query } }).catch(() => {});
    },

    //#######################################################################################
    // Get - faz o get, verifica se a rota está preenchida para limpar se for necessário
    //       Isso corrige o erro do "NavigationDuplicated"
    //#######################################################################################
    getSearch() {
      if (
        this.$route.query.phone ||
        this.$route.query.startDate ||
        this.$route.query.endDate ||
        this.$route.query.description ||
        this.$route.query.note
        // ||
        // this.$route.query.page
      ) {
        this.$router.replace({ query: {} });
        this.getCalls();
      } else {
        this.getCalls();
      }
    },
    getCalls() {
      this.$store.dispatch("telecom/getCalls", {
        startDate: this.startDateReply,
        endDate: this.endDateReply,
        fromPhone: this.fromPhoneReply,
        description: this.descriptionReply,
        note: this.noteReply,
      });
    },

    //##########################################################################
    //          Limpa os filtros, verifica se tem algo na rota e limpa,
    // RESET -  senão, segue somente limpando os filtros.
    //          Isso corrige o erro do "NavigationDuplicated"
    //##########################################################################
    reset() {
      if (this.$route.query.length != undefined) {
        this.$router.push({ query: {} });
        this.resetInputs();
      } else {
        this.resetInputs();
      }
    },
    resetInputs() {
      this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
      this.$store.commit("telecom/UPDATE_STARTDATE", this.startDateReset);
      this.$store.commit("telecom/UPDATE_ENDDATE", this.endDateReset);
      this.$store.commit("telecom/UPDATE_FROMPHONE", this.fromPhoneReset);
      this.$store.commit("telecom/UPDATE_DESCRIPTION", this.descriptionReset);
      this.$store.commit("telecom/UPDATE_NOTE", this.noteReset);
      this.getSearch();
    },

    //##########################################################################
    // FIXED SEARCH -  fixando o search no top quando rola o scroll
    //##########################################################################
    scrollFunction() {
      var div = document.querySelector("#topSearch");
      window.pageYOffset > 60 || document.documentElement.scrollTop > 60
        ? // window.scrollTop > 120 || document.documentElement.scrollTop > 120
          div.classList.add("topSearch")
        : div.classList.remove("topSearch");
    },
  },
  //##########################################################################
  // FIXED SEARCH -  ativando scroll antes de iniciar
  //##########################################################################
  beforeMount() {
    window.addEventListener("scroll", this.scrollFunction);
  },

  //##########################################################################
  // FIXED SEARCH -  removendo scroll antes de sair
  //##########################################################################
  beforeDestroy() {
    window.removeEventListener("scroll", this.scrollFunction);
  },
  created() {
    this.$store.dispatch("telecom/getVariantPhones");
  },
};
</script>

<style scoped>
.topSearch {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: stretch;
  top: 40px;
  position: fixed;
  z-index: 2;
  background-color: #fff;
  width: 100%;
}
.formInfos {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  justify-content: flex-start;
  /* justify-items: center; */
  grid-gap: 10px;
  align-items: flex-end;
  margin: 30px 0 10px 0;
  align-content: center;
  max-width: 500px;
}

.searchActiveContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0px 0 30px 0;
  grid-gap: 10px;
  font-size: 0.8rem;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #373946;
  padding: 10px;
  border-radius: 4px;
  /* box-shadow: 0 4px 8px rgb(31 43 66 / 20%); */
  border-radius: 4px;
  border: 1px solid rgba(31, 43, 66, 0.4);
  /* justify-content: center; */
  justify-content: flex-start;
  max-width: 1000px;
}
.filterActive {
  font-weight: bold;
}

li.itemSearchActive {
  /* border-left: 1px solid #ccc; */
  justify-content: center;
}
li + li.itemSearchActive::before {
  content: "|";
  color: #6c6c6d;
  padding: 0 15px 0 0;
  /* border-left: 1px solid #ccc; */
  justify-content: center;
}
.searchActive {
  display: grid;
  grid-template-columns: minmax(60px, auto) 25px;
  grid-gap: 5px;
  justify-content: center;
  /* box-shadow: 0 4px 8px rgb(31 43 66 / 20%); */
  /* max-width: 320px; */
  border-radius: 4px;
  border: 1px solid rgba(31, 43, 66, 0.4);
  padding: 10px;
  transition: all 0.3s;
  outline: none;
}

.closeSearch {
  padding: 0 10px;
  /* padding: 0 10px; */
  border-left: 1px solid #ccc;
  cursor: pointer;
  font-weight: bold;
}

input {
  height: 50px;
}
.button {
  display: flex;
  justify-content: flex-end;
}

.buttonSearch {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

@media screen and (max-width: 690px) {
  .topSearch {
    position: relative;
  }
  .formInfos {
    grid-template-columns: 1fr;
    /* display: flex;
    flex-wrap: wrap; */
    justify-items: center;
    margin: 10px 10px 50px 10px;
    width: 100%;
  }
  .formInfos div {
    display: grid;
    grid-template-columns: 1fr;
    justify-items: flex-start;
  }
  input {
    min-width: 250px;
    width: 100%;
  }
  .formInfos div.buttonSearch {
    display: flex;
    justify-content: flex-end;
    width: 255px;
  }
  .buttonSearch .button {
    width: 55px;
    justify-items: flex-end;
  }
}
</style>