<template>
  <section>
    <h1>Central Telefónica</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Histórico de Chamadas</h3>
        <div class="menuDropdown" v-if="telecomAccess || telecomManager">
          <BtnConfig>
            <span slot="title">
              Contactos Internos/ <br />
              Redirecionamentos
            </span>
            <ul slot="itemsMenu" class="menuDropdownContent">
              <!-- <div class="titleMenuDropdown">Contactos</div> -->
              <li>
                <router-link
                  :to="{
                    name: 'lista-contacto',
                  }"
                  >Contactos Internos</router-link
                >
              </li>
              <!-- <div class="titleMenuDropdown topsubmenu">Chamadas</div> -->
              <li>
                <router-link
                  :to="{
                    name: 'lista-redirecionamentos',
                  }"
                  >Redirecionamento</router-link
                >
              </li>
            </ul>
          </BtnConfig>
        </div>
      </div>

      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <!-- <li class="breadcrumb-item">
            <router-link :to="{ name: 'central-telefonica' }"
              >Redirecionamento de Chamadas</router-link
            >
          </li> -->
          <li class="breadcrumb-item-active">
            <span> Histórico de Chamadas</span>
          </li>
        </ol>
      </nav>
      <CallSearchForm />

      <article class="history">
        <div v-if="loadingCalls">
          <Loading />
        </div>
        <div v-else>
          <CallHistory @getCalls="getCalls" />
        </div>
      </article>
      <!-- {{ currentPage }} -->

      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </section>
</template>

<script>
import CallHistory from "@/components/telecom/CallHistory.vue";
import CallSearchForm from "@/components/telecom/CallSearchForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "CallHistoryView",
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "loadingCalls",
      "limit",
      "currentPage",
    ]),
    ...mapState("user", ["user"]),
    ...mapState("telecom", [
      "calls",
      "startDate",
      "endDate",
      "fromPhone",
      "description",
      "note",
    ]),
    ...mapGetters("user", ["telecomManager", "telecomAccess"]),
    ...mapGetters("telecom", [
      "startDateReset",
      "endDateReset",
      "fromPhoneReset",
      "descriptionReset",
      "noteReset",
    ]),
  },
  components: {
    CallSearchForm,
    CallHistory,
  },
  methods: {
    //#############################################################################################
    //  Verifica se tem alguma query e faz o get puxando a query, senão faz o get normal
    //#############################################################################################
    getCalls() {
      this.$store.dispatch("telecom/getCalls", {
        startDate: this.$route.query.startDate || this.startDate.startDate,
        endDate: this.$route.query.endDate || this.endDate.endDate,
        fromPhone:this.$route.query.phone || this.fromPhone.fromPhone,
        //   Number.parseInt(this.$route.query.phone) || this.fromPhone.fromPhone,
        description:
          this.$route.query.description || this.description.description,
        note: this.$route.query.note || this.note.note,
      });
    },
  },
  watch: {
    // verifica se volta no navegador e faz o get das chamadas novamente
    $route() {
      if (this.$router.back) {
        this.$store.commit(
          "UPDATE_CURRENTPAGE",
          Number(this.$route.query.page) || 1
        ); //ZERAR PAGINATION
        // this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
        this.$store.commit("telecom/UPDATE_STARTDATE", this.startDateReset);
        this.$store.commit("telecom/UPDATE_ENDDATE", this.endDateReset);
        this.$store.commit("telecom/UPDATE_FROMPHONE", this.fromPhoneReset);
        this.$store.commit("telecom/UPDATE_DESCRIPTION", this.descriptionReset);
        this.$store.commit("telecom/UPDATE_NOTE", this.noteReset);
        this.getCalls();
      }
    },
  },
  created() {
    // this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
    this.$store.commit(
      "UPDATE_CURRENTPAGE",
      Number(this.$route.query.page) || 1
    ); //ZERAR PAGINATION
    this.$store.commit("telecom/UPDATE_STARTDATE", this.startDateReset);
    this.$store.commit("telecom/UPDATE_ENDDATE", this.endDateReset);
    this.$store.commit("telecom/UPDATE_FROMPHONE", this.fromPhoneReset);
    this.$store.commit("telecom/UPDATE_DESCRIPTION", this.descriptionReset);
    this.$store.commit("telecom/UPDATE_NOTE", this.noteReset);
    this.getCalls();

    // this.$store.commit("UPDATE_CURRENTPAGE", 90);
  },
};
</script>

<style scoped>
.history {
  margin-top: 20px;
}
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1250px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
}
</style>
