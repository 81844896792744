<template>
  <div class="itemsCollab">
    <h1>Serviços</h1>
    <div class="containerCollab">
      <div
        class="titleContainer"
        v-if="activeRoute && disabledRoute == 'nothing'"
      >
        <h3>Lista de Serviços</h3>
        <div v-if="serviceManager">
          <router-link
            class="collabItens"
            :to="{
              name: 'criar-servico',
            }"
          >
            <AddUser>
              <span slot="title">
                Adicionar<br />
                Serviço</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <slot />

          <li class="breadcrumb-item-active">
            <span> Lista de serviços</span>
          </li>
        </ol>
      </nav>
      <ServicesList>
        <button
          slot="delete"
          class="delete"
          @click.prevent="confirmDeleteMessage(message.id)"
        ></button>
        <div slot="hr">
          <hr />
        </div>
      </ServicesList>
      <!-- <router-view></router-view> -->

      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </div>
</template>

<script>
import ServicesList from "@/components/service/ServicesList.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Services",
  components: { ServicesList },
  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId", "btnFilter"]),
    ...mapState("service", [
      "services",
      "loadingService",
      "searchWord",
      "filtered",
      "showModalDelete",
      "servicesActive",
      "servicesDisabled",
    ]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["serviceManager"]),
    //##############################################################
    //     MATENDO BOTÃO ATIVADO/DESATIVADO - DEPENDENDO DA ROTA
    //##############################################################
    activeRoute() {
      return this.$route.name == "servico" ||
        this.$route.name == "criar-servico" ||
        this.$route.name == "editar-servico"
        ? "activeLi"
        : "nothing";
    },
    disabledRoute() {
      return this.$route.name == "colaborador-info-desativado"
        ? "activeLi"
        : "nothing";
    },
  },
  created() {
    this.$store.dispatch("service/getServices");
  },
};
</script>

<style scoped>
/* CSS COLLABORATORS */

.containerCollab {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  margin-bottom: 30px;
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}
</style>