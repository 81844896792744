<template>
  <div>
    <h1>Jurídico</h1>
    <div class="contentGeneral">
      <div class="titleContainer">
        <h3>Editar Tipo de Taxa</h3>
      </div>

      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos', query: { archived: 'false'} }"  >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'tipo-de-taxas' }">Lista de Tipos de Taxas</router-link>
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'tipo-de-taxa',
                params: { idProcessTypeTax: idProcessTypeTax },
              }"
              >Dados do Tipo de Taxa</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Editar Tipo de Taxa</span>
          </li>
        </ol>
      </nav>
      <div class="itemFormCollaborator">
        <ProcessTypeTaxForm :processTypeTax="processTypeTax" :errors="errors">
          <div slot="cancel"></div>
          <div slot="enviar" class="btnJustify">
            <button class="btn" @click.prevent="updateProcessTypeTax">
              Gravar
            </button>
          </div>
        </ProcessTypeTaxForm>
      </div>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </div>
</template>

<script>
import ProcessTypeTaxForm from "@/components/legal/ProcessTypeTaxForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditProcessTypeTax",
  props: ["idProcessTypeTax", "idRouter"],
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("legal", ["processTypeTax"]),
    ...mapGetters("legal", ["processTypeTaxReply", "processTypeTaxReset"]),
  },
  components: {
    ProcessTypeTaxForm,
  },
  methods: {
    updateProcessTypeTax() {
      this.$store.dispatch("legal/updateProcessTypeTax", {
        idProcessTypeTax: this.idProcessTypeTax,
        processTypeTax: this.processTypeTaxReply,
      });
    },
  },
  created() {
    this.$store.commit("legal/UPDATE_MODAL_PROCESS_TYPETAX", this.processTypeTaxReset);
    this.$store.dispatch("legal/getProcessTypeTax", {
      idProcessTypeTax: this.idProcessTypeTax,
    });
    this.$store.commit("RESET_ERRORS", []);
  },
};
</script>

<style scoped>
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.btnJustify {
  display: grid;
  justify-content: flex-end;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
.itemFormCollaborator {
  margin-left: 10px;
}
</style>