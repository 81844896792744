<template>
  <section>
    <h1>Meus dados</h1>
    <article class="contentInfos" v-if="user">
      <ul>
        <li class="items itemList" v-if="user.fullName">
          <span class="titleBlue">Nome</span>
          <div class="list">
            <span class="textGray">{{ user.fullName }}</span>
          </div>
        </li>
        <li class="items itemList" v-if="user.nickName">
          <span class="titleBlue">Alcunha</span>
          <div class="list">
            <span class="textGray">{{ user.nickName }}</span>
          </div>
        </li>
        <li class="items itemList" v-if="user.username || emails.length">
          <span class="titleBlue">Usuário</span>
          <ul class="items">
            <span class="textGray">{{ user.username }}</span>
          </ul>
        </li>

        <li class="items itemList" id="address">
          <span class="titleBlue">Contacto</span>
          <ul class="items">
            <div v-if="loadingAddress">
              <p><LoadingCircle /></p>
            </div>
            <div v-else>
              <div v-if="user.contacts && user.contacts.length > 0">
                <!-- {{ user.contacts }} -->
                <li
                  class="textGray"
                  v-for="contact in user.contacts"
                  :key="contact.id"
                >
                  <ContactItem :contact="contact" :idRouter="user.id">
                    <!-- <button
                      slot="delete"
                      class="delete"
                      @click.prevent="confirmDeleteAddress(contact.id)"
                    ></button> -->
                  </ContactItem>
                </li>
              </div>
              <div v-else>
                <p class="textGray">Não possui Moradas</p>
              </div>
            </div>
          </ul>
        </li>
        <li class="items itemList">
          <span class="titleBlue">Morada</span>
          <ul class="items">
            <div v-if="loadingAddress">
              <p><LoadingCircle /></p>
            </div>
            <div v-else>
              <div v-if="user.addresses && user.addresses.length > 0">
                <li
                  class="textGray"
                  v-for="address in user.addresses"
                  :key="address.id"
                >
                  <AddressItem :address="address">
                    <router-link
                      v-if="staffEdit"
                      slot="editAddress"
                      class="edit"
                      :to="{
                        name: 'morada',
                        params: {
                          idAddress: address.id,
                          idRouter: user.id,
                        },
                      }"
                    >
                    </router-link>
                    <button
                      v-if="staffEdit"
                      slot="delete"
                      class="delete"
                      @click.prevent="confirmDeleteAddress(address.id)"
                    ></button>
                  </AddressItem>
                </li>
              </div>
              <div v-else>
                <p class="textGray">Não possui Moradas</p>
              </div>
            </div>
          </ul>
        </li>

        <li class="items itemList">
          <span class="titleBlue">Permissões</span>
          <ul class="items">
            <div v-if="loadingPermission">
              <p><LoadingCircle /></p>
            </div>
            <div v-else>
              <div v-if="user.superUser">
                <li class="textGray info">Super Usuário</li>
              </div>
              <div v-if="permissionDescrip && permissionDescrip.length > 0">
                <li
                  class="textGray"
                  v-for="permission in permissionDescrip"
                  :key="permission.id"
                >
                  <PermissionItem
                    :permission="permission"
                    :idCollaborator="user.id"
                  />
                </li>
              </div>
              <div v-else>
                <p class="textGray">Não possui Permissões</p>
              </div>
            </div>
          </ul>
        </li>
      </ul>
    </article>
  </section>
</template>

<script>
import PermissionItem from "@/components/user/PermissionItem.vue";
import AddressItem from "@/components/address/AddressItem.vue";
import ContactItem from "@/components/user/ContactItem.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "UserData",
  // props: ["idCollaborator"],
  components: {
    PermissionItem,
    AddressItem,
    ContactItem,
  },
  computed: {
    ...mapState([
      "loadingEmail",
      "loadingPhone",
      "loadingAddress",
      "loadingPermission",
    ]),
    ...mapState("collaborator", [
      "permissions",
      "phones",
      "addresses",
      "emails",
    ]),
    ...mapState("user", ["user"]),
    ...mapState("permission", ["listPermissions", "permissionDescrip"]),
    ...mapGetters("user", [
      "staffEdit",
      "staffDetail",
      "staffChangePermissions",
    ]),
  },
  created() {
    // this.$store.dispatch("user/getUserInfos", {
    //   idCollaborator: this.user.id,
    // });
    this.$store.dispatch("collaborator/getCollaborator", {
      idCollaborator: this.user.id,
      // listPermissions: this.listPermissions,
    });
    this.$store.dispatch("permission/getPermissions", {
      idCollaborator: this.user.id,
    });
  },
};
</script>

<style scoped>
.contentInfos {
  margin: 80px 0;
  padding: auto 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 700px);
  justify-content: center;
  align-content: flex-start;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 20px;
}

li.items {
  display: grid;
  grid-template-columns: 150px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 20px;
  }
}
</style>
