<template>
  <section>
    <!-- <slot /> -->
    <div class="titleContainer">
      <h3>Dados do Colaborador</h3>
      <div class="menuDropdown" v-if="staffEdit">
        <AddUser>
          <span slot="title"
            >Editar/Adicionar<br />
            Informações</span
          >
          <!-- </AddUser> -->
          <ul slot="itemsMenu" class="menuDropdownContent">
            <div class="titleMenuDropdown">Editar</div>
            <li>
              <router-link
                :to="{
                  name: 'editar-colaborador',
                  params: {
                    idCollaborator: idCollaborator,
                  },
                }"
                >Perfil</router-link
              >
            </li>
            <div class="titleMenuDropdown topsubmenu">Adicionar</div>
            <li @click="openModalContact">Contacto</li>
            <li v-if="addressEdit && staffEdit" @click="openModalAddress">
                Morada
              </li>
            <div>
              <li v-if="staffChangePermissions" @click="openModalPermission">
                <a href="#">Permissões</a>
              </li>
            </div>
          </ul>
        </AddUser>
      </div>
    </div>

    <nav class="navBreadcrumb">
      <ol class="breadcrumb">
        <slot />
        <li class="breadcrumb-item-active">
          <span> Dados do Colaborador</span>
        </li>
      </ol>
    </nav>

    <article class="contentInfos" v-if="collaborator">
      <ul>
        <li class="items itemList" v-if="collaborator.fullName">
          <span class="titleBlue">Nome</span>
          <div class="list">
            <span class="textGray">{{ collaborator.fullName }}</span>
          </div>
        </li>
        <li class="items itemList" v-if="collaborator.nickName">
          <span class="titleBlue">Alcunha</span>
          <div class="list">
            <span class="textGray">{{ collaborator.nickName }}</span>
          </div>
        </li>
        <li class="items itemList" v-if="collaborator.username">
          <span class="titleBlue">Usuário</span>
          <div class="items">
            <span class="textGray info">{{ collaborator.username }}</span>
          </div>
        </li>
        <li class="items itemList" id="address">
          <span class="titleBlue">Contacto</span>
          <ul class="items">
            <div v-if="loadingContact">
              <p><LoadingCircle /></p>
            </div>
            <div v-else>
              <div
                v-if="collaborator.contacts && collaborator.contacts.length > 0"
              >
                <!-- {{ collaborator.contacts }} -->
                <li
                  class="textGray"
                  v-for="contact in collaborator.contacts"
                  :key="contact.id"
                >
                  <ContactItem :contact="contact" :idRouter="idCollaborator">
                    <router-link
                      v-if="staffEdit"
                      slot="edit"
                      class="edit"
                      :to="{
                        name: 'contacto-editar',
                        params: {
                          idContact: contact.id,
                          idRouter: idCollaborator,
                          router: 'Colaborator',
                        },
                      }"
                    >
                    </router-link>
                    <button
                      v-if="staffEdit"
                      slot="delete"
                      class="delete"
                      @click.prevent="confirmDeleteContactCollab(contact.id)"
                    ></button>
                  </ContactItem>
                </li>
              </div>
              <div v-else>
                <p class="textGray">Não possui Contactos</p>
              </div>
            </div>
          </ul>
        </li>

        <!-- MORADA -->
        <li class="items itemList" id="address">
          <span class="titleBlue">Morada</span>
          <ul class="items">
            <div v-if="loadingAddress">
              <p><LoadingCircle /></p>
            </div>
            <div v-else>
              <div
                v-if="
                  collaborator.addresses && collaborator.addresses.length > 0
                "
              >
                <!-- {{ collaborator.addresses }} -->
                <li
                  class="textGray"
                  v-for="address in collaborator.addresses"
                  :key="address.id"
                >
                  <AddressItem :address="address">
                    <div slot="map" class="textGreen" @click.prevent="modalMap(address.location.lat, address.location.lng)">Ver mapa</div>
                    <router-link
                      v-if="staffEdit && addressEdit"
                      slot="editAddress"
                      class="edit"
                      :to="{
                        name: 'moradaColaborador',
                        params: {
                          idAddress: address.id,
                          idRouter: idCollaborator,
                        },
                      }"
                    >
                    </router-link>
                    <button
                      v-if="staffEdit && addressEdit"
                      slot="delete"
                      class="delete"
                      @click.prevent="confirmDeleteAddress(address.id)"
                    ></button>
                  </AddressItem>
                </li>
              </div>
              <div v-else>
                <p class="textGray">Não possui Moradas</p>
              </div>
            </div>
            <!-- <transition mode="out-in">
              <li v-if="createAddress">
                <AddressForm :address="address" :errors="errors">
                  <div slot="cancel">
                    <button
                      class="btn btn-cancel"
                      @click.prevent="desableAddress"
                    >
                      Cancelar
                    </button>
                  </div>
                  <div slot="delete">
                    <button class="btn btn-form" @click.prevent="addAddress">
                      Adicionar
                    </button>
                  </div>
                </AddressForm>
              </li>
            </transition> -->
          </ul>
        </li>
        <!-- PREMISSÃO -->
        <li class="items itemList">
          <span class="titleBlue">Permissões</span>
          <ul class="items">
            <div v-if="loadingPermission">
              <p><LoadingCircle /></p>
            </div>
            <div v-else>
              <div v-if="collaborator.superUser">
                <li class="textGray info">Super Usuário</li>
              </div>
              <div v-if="permissionDescrip && permissionDescrip.length > 0">
                <li
                  class="textGray"
                  v-for="permission in permissionDescrip"
                  :key="permission.id"
                >
                  <PermissionItem
                    :permission="permission"
                    :idCollaborator="idCollaborator"
                  >
                  </PermissionItem>
                </li>
              </div>
              <div v-else>
                <p class="textGray">Não possui Permissões</p>
              </div>
            </div>
          </ul>
        </li>
      </ul>
    </article>
    <!--#########################
    ##  MODAL SELECT ADDRESS
    ######################### -->
    
      <div class="modal">
      <div
        class="overlay"
        v-if="showModalAddress"
        @click="closeModalAddress"
      ></div>
      <div class="modal_container" v-if="showModalAddress">
        <button class="close btn" @click="closeModalAddress">x</button>
        <section class="modalOverflow">
          <article>
            <h3 class="center">Adicionar Morada</h3>
            <div class="btnAddItem" v-if="createAddress == false">
              <div class="btnAdd" @click="createAddress = !createAddress">
                <label class="circle">
                  <svg
                    class="icon-add"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                </label>
                <label class="titleAdd">
                  Criar<br />
                  Morada
                </label>
              </div>
            </div>
            <div v-if="createAddress == false">
              <AddressSearch />
              <div class="containerOtherContact">
                <ul class="otherContactItens">
                  <li
                    v-for="address in otherAddresses"
                    :key="address.id"
                    class="otherContactItem"
                  >                 
                    <div
                      v-if="address.address != ''"
                      @click.prevent="associateAddress(address.id)"
                    >
                      {{ address.street }}
                      <span v-if="address.streetNumber">, {{ address.streetNumber }}</span> 
                      <span v-if="address.city"> - {{address.city}}</span>
                      <span v-if="address.state"> - {{address.state}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="createAddress == true">
              <p class="containerAddress">
                <AddressForm :errors="errors">
                      <div slot="cancel">
                        <button
                          class="btn btn-cancel"
                          @click.prevent="createAddress = false"
                        >
                          Cancelar
                        </button>
                      </div>
                      <div slot="delete">
                        <button
                          class="btn btn-form"
                          @click.prevent="addAddress"
                        >
                          Adicionar
                        </button>
                      </div>
                    </AddressForm>
              </p>
            </div>
          </article>
        </section>
      </div>
    </div> 
    <!--#########################
    ##  MODAL SELET CONTACT
    ######################### -->
    <div class="modal">
      <div
        class="overlay"
        v-if="showModalContact"
        @click="closeModalContact"
      ></div>
      <div class="modal_container" v-if="showModalContact">
        <button class="close btn" @click="closeModalContact">x</button>
        <section class="modalOverflow">
          <article>
            <h3 class="center">Adicionar Contacto</h3>
            <div class="btnAddItem" v-if="createContact == false">
              <div class="btnAdd" @click="createContact = !createContact">
                <label class="circle">
                  <svg
                    class="icon-add"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                </label>
                <label class="titleAdd">
                  Criar<br />
                  Contacto
                </label>
              </div>
            </div>
            <div v-if="createContact == false">
                <ContactSearch :modal= "true" />
                <div v-if="loadingContact">
                    <LoadingCircle />
                </div>
                <div v-else>
                    <div class="containerOtherContact">
                        <div class="titleSearch">
                            <div class="titleSelectModal">Nome</div><div class="titleSelectModal">Telefone</div>
                        </div>
                        <hr />
                        <ul class="otherContactItens">
                        <li
                            v-for="contact in otherContacts"
                            :key="contact.id"
                            class="otherContactItem"
                        >
                        <div class="titleSearch" @click.prevent="associateContact(contact.id)">
                            <div>
                                <p v-if="contact.name">{{ contact.name }}</p>
                                <p v-else>Sem nome</p></div><div>{{contact.phones[0]}}
                        </div>
                        </div>
                        </li>
                        </ul>
                    </div>
                    <PaginationUrl
                        :data="filteredContacts"
                        :totalPages="maxPage"
                        :total="filteredContacts.length"
                        :currentPage="currentPage"
                        @pagechanged="onPageChange"
                    >
                    </PaginationUrl>
            </div>
            </div>
            <div v-if="createContact == true">
              <p>
                <ContactForm :errors="errors">
                  <div slot="cancel">
                  <button
                    class="btn btn-cancel"
                    @click.prevent="createContact = false"
                  >
                    Cancelar
                  </button>
                </div>
                  <div slot="enviar">
                  <button class="btn btn-form" @click.prevent="addContact">
                    Adicionar
                  </button>
                  </div>
                </ContactForm>
              </p>
            </div>
          </article>
        </section>

        <!-- <div class="containerDel"></div> -->
      </div>
    </div>
    <div class="modal">
      <div
        class="overlay"
        v-if="showModalPermission"
        @click="closeModalPermission"
      ></div>
      <div class="modal_container" v-if="showModalPermission">
        <button class="close btn" @click="closeModalPermission">x</button>
        <h2>Permissões</h2>
        <PermissionsForm :idCollaborator="idCollaborator"> </PermissionsForm>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelContact"
        @click="closeModalDelContactCollab"
      ></div>
      <div class="modalDel_container" v-if="showModalDelContact">
        <button class="close btn" @click="closeModalDelContactCollab">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este contacto?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelContactCollab"
          />
          <button class="btnDel" @click="deleteContactCollaborator">
            Delete
          </button>
        </div>
      </div>
    </div>

    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelAddress"
        @click="closeModalDelAddress"
      ></div>
      <div class="modalDel_container" v-if="showModalDelAddress">
        <button class="close btn" @click="closeModalDelAddress">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover esta Morada?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelAddress"
          />
          <button class="btnDel" @click="deleteAddress">Delete</button>
        </div>
      </div>
    </div>
     <!--#########################
    ##  MODAL MAP
    ######################### -->
    <div class="modal">
      <div
        class="overlay"
        v-if="showMap"
        @click="modalMap"
      ></div>
      <div class="modal_containerFixed" v-if="showMap">
        <button class="close btn" @click="modalMap">x</button>
           <div class="delItem">
            <h3>Localização</h3>       
          
          </div>
          <iframe
                width="100%"
                height="300px"
                frameborder="0"
                scrolling="no"
                marginheight="0"
                marginwidth="0"
                :src="iframe"
              >
          </iframe>
        </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import PermissionItem from "@/components/user/PermissionItem.vue";
import PermissionsForm from "@/components/collaborator/PermissionsForm.vue";
import AddressItem from "@/components/address/AddressItem.vue";
import AddressForm from "@/components/address/AddressForm.vue";
import ContactItem from "@/components/user/ContactItem.vue";
import ContactForm from "@/components/contact/ContactForm.vue";
import ContactSearch from "@/components/contact/ContactSearch.vue";
import AddressSearch from "@/components/address/AddressSearch.vue";
import { mapState, mapGetters } from "vuex";
import { mapFields } from "@/helpers.js";

export default {
  name: "CollaboratorData",
  props: ["collaborator", "idCollaborator"],
  data() {
    return {
      createContact:false,
      createAddress:false,
    };
  },
  components: {
    AddressItem,
    PermissionItem,
    // EmailForm,
    // PhoneForm,
    ContactItem,
    ContactForm,
    ContactSearch,
    AddressForm,
    PermissionsForm,
    AddressSearch,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "wait",
      "showModalDel",
      "selectedId",
      "loadingEmail",
      "loadingPhone",
      "loadingPermission",
      "showMap", 
      "iframe",
      "limit",
      "currentPage",
    ]),
    ...mapState("address", [
      "showModalDelAddress",
      "loadingAddress",
      "showModalAddress", 
      // "selectedId",
    ]),
    ...mapState("contact", ["loadingContact", "contacts", "maxPage", "page",
      "name",
      "phone",
      "mail",
      "description",]),
    ...mapState("user", ["user"]),
    ...mapState("collaborator", [
      "addresses",
      "permissions",
      "address",
      "permission",
      "contactID",
      // "createAddress",
      "showModalContact",
      "showModalDelContact",
    ]),
    ...mapState("permission", [
      "showModalPermission",
      "permissionDescrip",
      "listPermissions",
    ]),
    ...mapGetters("collaborator", [
      "emailReply",
      "phoneReply",
      "addressReply",
      "permissionReply",
      "otherContacts",
      "otherAddresses",
      "contactIDReply",
      "contactIDReset",
      "addressReset",
      "permissionReset",
      "listPermissionsReset",
    ]),
    ...mapGetters("contact", ["contactReply", "contactReset", "getFiltered",]),
    ...mapGetters("address", ["addressReply", "addressReset"]),
    ...mapGetters("permission", ["permissionReset", "listPermissionsReset"]),
    ...mapGetters("user", [
      "staffEdit",
      "staffDetail",
      "staffChangePermissions",
      "addressAccess",
      "addressEdit",
    ]),
    // filteredContacts() {
    //   const a = this.getFiltered || this.otherContacts;
    //   return a;
    // },
    
    filteredContacts() {
      const a = this.getFiltered || this.contacts;
      return a;
    },
    ...mapFields({
      fields: ["contactID"], //nome dos campos
      module: "collaborator", //qual módulo está acessando
      base: "contactID", //colocar o state que vai receber
      mutation: "collaborator/UPDATE_CONTACTID", //nome da mutation que vai alterar o state
    }),
  },
  methods: {
    //######################################################
    //     PAGINAÇÃO CONTACTS
    //######################################################
    onPageChange(page) {
        const filter = {
            page: page,
            name: this.$route.query.name || this.name.name,
            phone:this.$route.query.phone || this.phone.phone,
            mail:this.$route.query.mail || this.mail.mail,
            description:
            this.$route.query.description || this.description.description,
        };
        const query = Object.entries(filter).reduce((acc, [key, val]) => {
          if (!val) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$router.replace({ query: { ...query } }).catch(() => {});    
      this.getContacts()
    },
    //##################################################
    //                  CONTACT
    //##################################################
    associateContact(id) {
      this.closeModalContact();
      this.$store.dispatch("collaborator/updateContactCollaborator", {
        idContact: `${id}`,
        idModule: this.idCollaborator,
      });
      this.$store.commit("contact/RESET_FILTER", null);
    },
    addContact() {
      this.$store.dispatch("contact/addContact", {
        contact: this.contactReply,
        idModule: this.idCollaborator,
        mutation: "'collaborator/updateContactCollaborator'",
      });
    },
    openModalContact() {
      this.createContact = false
      this.desableContact();
      this.$store.dispatch("collaborator/openModalContact");
      this.$store.commit("contact/UPDATE_CONTACT", this.contactReset);
      this.$store.commit("contact/RESET_PHONE", null);
      this.$store.commit("contact/RESET_EMAIL", null);
      this.$store.commit("contact/RESET_FILTER", null);
    },
    closeModalContact() {
      this.$store.commit("collaborator/UPDATE_CREATE_CONTACT", false);
      this.$store.dispatch("collaborator/closeModalContact");
      if (Object.entries(this.$router.currentRoute.query).length != 0) {
        this.$router.push({ query: {} });
      }
    this.getContacts()
    },

    desableContact() {
      this.$store.commit("collaborator/UPDATE_CREATE_CONTACT", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    enableContact() {
      this.$store.commit("collaborator/UPDATE_CREATE_CONTACT", true);
    },

    openModalDelContactCollab() {
      this.$store.dispatch("collaborator/openModalDelContactCollab");
    },
    closeModalDelContactCollab() {
      this.$store.dispatch("collaborator/closeModalDelContactCollab");
    },
    confirmDeleteContactCollab(id) {
      this.$store.dispatch("collaborator/confirmDeleteContactCollab", {
        id: `${id}`,
      });
    },
    deleteContactCollaborator() {
      this.$store.dispatch("collaborator/deleteContactCollaborator", {
        idCollaborator: this.idCollaborator,
        id: this.$store.state.collaborator.selectedId.id,
      });
      this.closeModalDelContactCollab();
    },
    //##################################################
    //                 ADDRESS
    //##################################################
    associateAddress(id) {
      this.closeModalAddress();
      this.$store.dispatch("collaborator/updateAddressCollaborator", {
        idAddress: `${id}`,
        idModule: this.idCollaborator,
      });
      this.$store.commit("contact/RESET_FILTER", null);
    },
    addAddress() {
      this.$store.dispatch("address/addAddress", {
        address: this.addressReply, //enviar getters com as infos necessárias para o cadastro
        idModule: this.idCollaborator,
        mutation: "'collaborator/updateAddressCollaborator'",
      });
    },
    openModalAddress() {
      this.createAddress = false
      this.$store.commit("address/UPDATE_CONFIRMFORM", false);
      this.$store.commit("address/UPDATE_ADDRESS", this.addressReset);
      this.$store.dispatch("address/openModalAddress");
    },
    closeModalAddress() {
      this.$store.dispatch("address/closeModalAddress");
    },
    desableAddress() {
      this.$store.commit("RESET_ERRORS", []);
      this.$store.commit("address/UPDATE_ADDRESS", this.addressReset); //limpa o formulário depois de Adicionar
      this.$store.commit("collaborator/UPDATE_CREATE_ADDRESS", false);
    },
    enableAddress() {
      this.$store.commit("RESET_ERRORS", []);
      this.$store.commit("address/UPDATE_ADDRESS", this.addressReset); //limpa o formulário depois de Adicionar
      this.$store.commit("collaborator/UPDATE_CREATE_ADDRESS", true);
    },
    confirmDeleteAddress(id) {
      // console.log(id);
      this.$store.dispatch("address/confirmDeleteAddress", {
        id: `${id}`,
      });
    },
    deleteAddress() {
      // console.log(this.selectedId);
      this.$store.dispatch("collaborator/deleteAddressCollaborator", {
        idCollaborator: this.idCollaborator,
        id: this.selectedId.id,
      });
      this.closeModalDelAddress();
    },
    //               MODAL DELETE
    //##################################################
    openModalDelAddress() {
      this.$store.dispatch("address/openModalDelAddress");
    },
    closeModalDelAddress() {
      this.$store.dispatch("address/closeModalDelAddress");
    },
    //##################################################
    //                  PERMISSION
    //##################################################
    addPermission() {
      this.$store.commit("RESET_ERRORS", []);
      // console.log("Cadastrou Permissão");
    },
    openModalPermission() {
      this.$store.dispatch("permission/openModalPermission");
      this.$store.commit("collaborator/UPDATE_CREATE_ADDRESS", false);
      // this.$store.commit("RESET_ERRORS", []);
    },
    closeModalPermission() {
      this.$store.dispatch("collaborator/getCollaborators", {
        idCollaborator: this.idCollaborator,
        refresh: "refresh",
      });
      this.$store.dispatch("permission/closeModalPermission");
    },
     //##################################################
    //              IFRAME GOOGLE MAP
    //##################################################
    modalMap(lat, lng){
      // console.log(lat,lng)
      if(lat != null && lng != null){
        let url = `https://maps.google.com/maps?q=${lat},${lng}&z=17&output=embed`;      
        this.$store.commit("UPDATE_IFRAME", url);
        
      }
      this.$store.commit("UPDATE_MODAL_MAP", !this.showMap);
    },
    getContacts(){
        this.$store.dispatch("contact/getContacts", {            
        page: this.$route.query.page || this.page.page,
        name: this.$route.query.name || this.name.name,
        phone:this.$route.query.phone || this.phone.phone,
        mail:this.$route.query.mail || this.mail.mail,
        description:
        this.$route.query.description || this.description.description,
        });
    }
  },
  created() {
    this.desableAddress();
    this.getContacts()
    this.$store.dispatch("permission/getPermissions", {
      idCollaborator: this.idCollaborator,
    });
    this.$store.dispatch("address/getAddresses");
  },
};
</script>

<style scoped>
.containerAddress{
  display: flex;
  justify-content: center;
}
/* btn add item */
.inputList[data-v-1f25a6ce] {
    display: grid;
    justify-content: flex-start;
    justify-items: center;
}
.btn-cancel {
  background: #dc3545;
}
.btnAddItem {
  display: grid;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.btnAdd {
  display: flex;
  align-items: center;
  cursor: pointer;
  /* padding: 10px; */
}
.titleAdd {
  font-size: 12px;
  cursor: pointer;
}

.icon-add {
  fill: #fff;
}
.circle {
  background: #1f2b42;
  width: 35px;
  height: 35px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.icon-add:hover {
  fill: #fff;
}
.circle:hover {
  background: #28a745;
  width: 35px;
  height: 35px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
/* fim btn item */
.modalOverflow {
  overflow-y: auto;
  max-height: 620px;
  padding: 0 20px;
  margin: 30px 0;

  scrollbar-width: thin; /*padrão firefox*/
  scrollbar-color: #00bf48 transparent; /*padrão firefox*/
}
.modalOverflow::-webkit-scrollbar {
  width: 10px;
  background: #ecebeb;
  border-radius: 7px;
}

.modalOverflow::-webkit-scrollbar-thumb {
  background: #00bf48;
  opacity: 0.5;
  border-radius: 7px;
}
.containerOtherContact {
  box-shadow: 0 4px 8px rgb(30 60 90 / 20%);
  padding: 20px;
  border-radius: 9px;
  height: 220px;
  margin-bottom: 15px;
}
.otherContactItens {
  overflow-y: auto;
  height: 150px;
  line-height: 30px;
  margin-bottom: 20px;
  scrollbar-width: thin; /*padrão firefox*/
  scrollbar-color: #c5c5c5 transparent; /*padrão firefox*/
}
.otherContactItem:hover {
  background: #dbebf3;
  cursor: pointer;
}
.otherContactItens::-webkit-scrollbar {
  width: 10px;
  background: #ecebeb;
  border-radius: 7px;
}

.otherContactItens::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  opacity: 0.5;
  border-radius: 7px;
}
.btnContact {
  display: grid;
  justify-content: center;
}
.btnContact p {
  display: grid;
  justify-items: center;
  text-align: center;
  cursor: pointer;
}
.btnContact p#desable {
  margin-top: 40px;
}

.btnContact p#enable {
  margin-top: 40px;
}
.textAddContact {
  margin-top: 30px;
}
.center {
  text-align: center;
  margin-bottom: 30px;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  /* padding: 30px 0 0 0; */
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 150px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

.btn-cancel {
  background: #dc3545;
}

.titleSearch{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
}
</style>
