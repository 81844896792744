import Vue from "vue";
import Vuex from "vuex";
import { api } from "@/services.js";
import Cookies from 'js-cookie';
import router from './../router/index.js'
import user from './modules/user'
import collaborator from './modules/collaborator'
import permission from './modules/permission'
import telecom from './modules/telecom'
import contact from './modules/contact'
import service from './modules/service'
import message from './modules/message'
import vehicle from './modules/vehicle'
import address from './modules/address'
import attachment from './modules/attachment'
import client from './modules/client'
import legal from './modules/legal'

Vue.use(Vuex);

export default new Vuex.Store({
  modules: { user, collaborator, permission, telecom, contact, service, message, vehicle, address, attachment, client, legal },
  strict: process.env.NODE_ENV !== 'production', //fica verificando se está fazendo alterações direto no state sem mutation - habilita somente em modo de desenvolvimento.
  state: {
    version: {
      data: "22.12.19.20.45"
    },
    // login: false,
    errorLogin: [],
    errors: [],
    btnLogin: true,
    iframe: null,
    //######################################
    //             LOGIN     
    //######################################
    loginUser: {
      username: null,
      password: null,
    },
    //######################################
    //               MODAL     
    //######################################
    showMap: false,
    //######################################
    //               MODAL DEL      
    //######################################
    showModalDel: false,
    selectedId: null,
    showModalLogin: false,
    showModalFile: false,
    //######################################
    //       NOTIFICAÇÕES TOAST       
    //######################################
    //success
    items: [],
    msg: null,
    //error
    itemsError: [],
    msgError: null,
    //######################################
    //    LOADING - ALTERAÇÕES EM FORMS       
    //######################################
    wait: false,
    //######################################
    //       LOADING PARA GETS DE CADA       
    //######################################
    loadingLogin: false,
    loadingPermission: false,
    loadingEmail: false,
    loadingPhone: false,
    loadingAddress: false,
    loadingPassword: false,
    loadingTelecomPhone: false,
    loadingTelecomConfigs: false,
    loadingCalls: false,
    loadingCallback: false,
    loadingCallNote: false,
    loadingImg: false,
    loadingTax: false,
    loadingOpposite: false,
    loadingDownloadImg: false,
    loadingCircle: false,
    //###############
    //  PAGINAÇÃO
    //###############
    currentPage: 1,
    // currentPage: Number(router.history.current.query.page) || 1,
    limit: 10,
    //######################################
    //         BTN FILTER    
    //######################################
    btnFilter: false,
    filterItem:false,
    //############################
    //      ficheiro            
    //############################
    selectedFile: null,
    archiveID: null,
    fileID: null,
  },
  getters: {
    version(state) {
      return state.version
    },

    loginUserReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "username": state.loginUser.username,
        "password": state.loginUser.password,
      }
    },

  },
  //########################################################################################
  //                 MUTATION            
  //########################################################################################
  mutations: {

    //###############
    //  FILTRO
    //###############
    UPDATE_FILTER_ITEM(state, payload) {
      state.filterItem = payload;
    },  
    //###############
    //  FICHEIRO
    //###############
    UPDATE_MODAL_FILE(state, payload) {
      state.showModalFile = payload;
    },  
    UPDATE_SELECTEDFILE(state, payload) {
      state.selectedFile = payload;
    },
    UPDATE_BTNFILTER(state, payload) {
      state.btnFilter = payload;
    },
    //###############
    //  PAGINAÇÃO
    //###############
    UPDATE_CURRENTPAGE(state, payload) {
      state.currentPage = payload;
    },
    //############################
    //          LOGIN     
    //############################
    
    UPDATE_LOADING_CIRCLE(state, payload) {
      state.loadingCircle = payload;
    },
    UPDATE_USERNAME(state, payload) {
      state.loginUser.username = payload;
    },
    UPDATE_PASSWORD(state, payload) {
      state.loginUser.password = payload;
    },

    // UPDATE_LOGIN(state, payload) {
    //   state.login = payload;
    // },
    UPDATE_BTNLOGIN(state, payload) {
      state.btnLogin = payload;
    },
    //########################### 
    //    NOTIFICAÇÃO LOGIN
    //###########################
    UPDATE_ERRORLOGIN(state, payload) {
      state.errorLogin.push(payload);
    },
    RESET_ERRORLOGIN(state, payload) {
      state.errorLogin = payload;
    },
    //########################### 
    //    LOADING
    //###########################
    UPDATE_LOADING(state, payload) {
      state.loadingLogin = payload;
    },
    UPDATE_WAIT(state, payload) {
      state.wait = payload;
    },
    UPDATE_LOADING_PERMISSION(state, payload) {
      state.loadingPermission = payload;
    },
    UPDATE_LOADING_EMAIL(state, payload) {
      state.loadingEmail = payload;
    },
    UPDATE_LOADING_PHONE(state, payload) {
      state.loadingPhone = payload;
    },
    UPDATE_LOADING_ADDRESS(state, payload) {
      state.loadingAddress = payload;
    },
    UPDATE_LOADING_PASSWORD(state, payload) {
      state.loadingPassword = payload;
    },
    UPDATE_LOADING_TELECOMPHONE(state, payload) {
      state.loadingTelecomPhone = payload;
    },
    UPDATE_LOADING_TELECOMCONFIGS(state, payload) {
      state.loadingTelecomConfigs = payload;
    },
    UPDATE_LOADING_CALLS(state, payload) {
      state.loadingCalls = payload;
    },
    UPDATE_LOADING_CALLBACK(state, payload) {
      state.loadingCallback = payload;
    },
    UPDATE_LOADING_CALLNOTE(state, payload) {
      state.loadingCallNote = payload;
    },
    UPDATE_LOADING_IMG(state, payload) {
      state.loadingImg = payload;
    },
    UPDATE_LOADING_TAX(state, payload) {
      state.loadingTax = payload;
    },  
    UPDATE_LOADING_OPPOSITE(state, payload) {
      state.loadingOpposite = payload;
    },   
    UPDATE_LOADING_DOWNLOADIMG(state, payload) {
      state.loadingDownloadImg = payload;
    },
    //################################
    //    NOTIFICAÇÃO DE ERRO INPUTS
    //################################
    UPDATE_ERRORS(state, payload) {
      state.errors.push(payload);
    },
    RESET_ERRORS(state, payload) {
      state.errors = payload;
    },
    //############################
    //     NOTIFICAÇÕES TOAST 
    //############################
    //success
    UPDATE_ITEMS(state, msg) {
      state.items.unshift(msg); //insere o toast criado no item     ;
    },
    REMOVE_ITEMS(state, msg) {
      let index = state.items.indexOf(msg);//retorna o primeiro índice
      state.items.splice(index, 1)//pega o índice e substitui por 1
    },
    UPDATE_MSG(state, body) {
      state.msg = body
    },
    //error
    UPDATE_ITEMSERROR(state, msgError) {
      state.itemsError.unshift(msgError); //insere o toast criado no item     ;
    },
    REMOVE_ITEMSERROR(state, msgError) {
      let index = state.itemsError.indexOf(msgError);//retorna o primeiro índice
      state.itemsError.splice(index, 1)//pega o índice e substitui por 1
    },
    UPDATE_MSGERROR(state, body) {
      state.msgError = body
    },
    //############################
    //         MAP          
    //############################
    UPDATE_MODAL_MAP(state, payload) {
      state.showMap = payload;
    },  
    UPDATE_IFRAME(state, payload) {
      state.iframe = payload;
    },      
    //############################
    //         MODAL DEL            
    //############################
    UPDATE_MODAL_DEL(state, payload) {
      state.showModalDel = payload;
    },
    UPDATE_SELECTED_ID(state, payload) {
      state.selectedId = payload;
    },
    //############################
    //         MODAL LOGIN            
    //############################
    UPDATE_MODAL_LOGIN(state, payload) {
      state.showModalLogin = payload;
    },
    UPDATE_REDIRECT(state, payload) {
      // console.log("chamou")
      state.showModalLogin = payload;
    },

  },
  //########################################################################################
  //                 ACTIONS           
  //########################################################################################
  actions: {
    //############################################
    //                 MODAL            
    //############################################
    openModalDel(context) {
      context.commit("UPDATE_MODAL_DEL", true);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    closeModalDel(context) {
      context.commit("UPDATE_MODAL_DEL", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },
    //Delete - Delete 1 de 2
    confirmDelete(context, id) {
      context.commit("UPDATE_SELECTED_ID", id); //armazena o id no selectedId para confirmação
      context.commit("UPDATE_MODAL_DEL", true);//abre o modal
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      })
    },

      //          MODAL  FICHEIRO          
    //############################################  
    openModalFile(context) {
      context.commit("RESET_ERRORS", []); //limpa os erros
      context.commit("UPDATE_MODAL_FILE", true);
    },
    closeModalFile(context) {
      context.commit("UPDATE_MODAL_FILE", false);
    },
    //##################################################
    //             LOGIN/LOGOUT - ACTION         
    //##################################################
    login(context, { login, redirect }) {
      // console.log("redirect", redirect)
      context.commit("UPDATE_LOADING", true);
      context.commit("RESET_ERRORLOGIN", [])
      api.login(`/user/authenticate`, {
        username: login.username,
        password: login.password
      })
        .then(response => {
          const date = new Date();
          date.setTime(date.getTime() + (response.data.jwt_expire * 1000));
          Cookies.set('expire', `${date}`, {
            expires: date
          })
          localStorage.setItem('tokenCookie', response.data.jwt)
          localStorage.setItem('UserCookie', JSON.stringify(response.data.user))
          const myUser = JSON.parse(localStorage.getItem('UserCookie'))
          context.commit("user/UPDATE_USER", myUser, { root: true });
          // context.commit("UPDATE_LOGIN", true);
          context.commit("UPDATE_LOADING", false);
          context.commit("UPDATE_BTNLOGIN", true);
          //Redirect Se tiver "query" com caminho pesquisado antes do login, direcionar para o caminho, senão segue para o dashboard
          if (!context.state.showModalLogin) {
            router.push(redirect || { name: "dashboard" });
          } else {
            context.commit("UPDATE_MODAL_LOGIN", false);
            // console.log("redirect", redirect)
            // router.push(redirect);
            router.go(0)
          }
        }).catch(error => {
          context.commit("UPDATE_BTNLOGIN", true);
          context.commit("UPDATE_LOADING", false);
          console.log(error);
          if (error.response.data === null || error.response.data === "") {
            context.commit("UPDATE_ERRORLOGIN", 'Incorrect email or password'); //insere novo erro quando não preenche  corretamente o formulário
          }
          if (error.response.status === 403) {
            context.commit("UPDATE_ERRORLOGIN", 'Email ou senha incorretos'); //insere novo erro quando não preenche  corretamente o formulário
          }
          if (error.response.status === 500) {
            router.push({ name: "erro-interno" })
          }
        })
    },


    // GET USER      
    //############################################
    getUser(context) {
      const myUser = JSON.parse(localStorage.getItem('UserCookie'))
      context.commit("user/UPDATE_USER", myUser, { root: true });
    },


    //  LOGOUT      
    //############################################
    userLogout() {
      Cookies.remove('expire')
      localStorage.removeItem('tokenCookie')
      localStorage.removeItem('UserCookie')
      // context.commit("UPDATE_LOGIN", false)
    },

    //############################################
    //      NOTIFICAÇÕES TOAST - ACTION        
    //############################################

    //success
    //########################
    success(context, body) {
      context.commit("UPDATE_MSG", body);
      context.commit("UPDATE_ITEMS", context.state.msg);
      setTimeout(() => this.dispatch("removeNotify"), 3000)//remove após X segundos
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },

    //removeNotifySuccess
    //########################
    removeNotify(context) {
      context.commit("REMOVE_ITEMS", context.state.msg)
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },

    //error
    //########################
    errorToast(context, body) {
      context.commit("UPDATE_MSGERROR", body);
      context.commit("UPDATE_ITEMSERROR", context.state.msgError);
      setTimeout(() => this.dispatch("removeNotifyError"), 3000)//remove após X segundos
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },

    //removeNotifyError
    //########################
    removeNotifyError(context) {
      context.commit("REMOVE_ITEMSERROR", context.state.msgError)
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },

    //############################################
    //        TRATANDO ERRO STATUS        
    //############################################
    errorStatus(context, status) {
      // console.log("errorStatus", status === "400")
      switch (status) {
        case undefined:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Erro interno",
            body: "Contacte o Administrador!"
          });
          break;
        case 400:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Atenção!",
            body: "Ação não concluída!"
          });
          break;
        case 401:
          context.commit("UPDATE_MODAL_LOGIN", true);
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "401",
            body: "Não autenticado!"
          });
          break;
        case 403:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Acesso Negado!",
            body: "Ação não concluída!"
          });
          break;
        case 404:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Não encontrado",
            body: "Ação não concluída!"
          });
          // router.push({ name: "pagina-nao-encontrada" })
          break;
        case 413:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Ficheiro muito grande!",
            body: "Ação não concluída!"
          });
          // router.push({ name: "pagina-nao-encontrada" })
          break;
        case 500:
          // router.push({ name: "erro-interno" })
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Erro interno",
            body: "Contacte o Administrador!"
          });
          break;
        case 501:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Ficheiro muito grande!",
            body: "Ação não concluída!"
          });
          break;
        case 503:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Ficheiro muito grande!",
            body: "Ação não concluída!"
          });
          break;

      }
    },
    //############################################
    //     FICHEIROS - TRATANDO ERRO STATUS        
    //############################################
    errorArchiveStatus(context, status) {
      // console.log("errorStatus", status === "400")
      switch (status) {
        case undefined:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Erro interno " + status,
            body: "Contacte o Administrador!"
          });
          break;
        case 400:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Atenção!",
            body: "Ação não concluída!"
          });
          break;
        case 401:
          context.commit("UPDATE_MODAL_LOGIN", true);
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "401",
            body: "Não autenticado!"
          });
          break;
        case 403:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Acesso Negado!",
            body: "Ação não concluída!"
          });
          break;
        case 404:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Ficheiro não encontrado!",
            body: "Ação não concluída!"
          });
          // router.push({ name: "pagina-nao-encontrada" })
          break;
        case 413:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Ficheiro muito grande!",
            body: "Ação não concluída!"
          });
          // router.push({ name: "pagina-nao-encontrada" })
          break;
        case 500:
          // router.push({ name: "erro-interno" })
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Erro interno",
            body: "Contacte o Administrador!"
          });
          break;
        case 501:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Ficheiro muito grande!",
            body: "Ação não concluída!"
          });
          break;
        case 503:
          context.dispatch("errorToast", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Ficheiro muito grande!",
            body: "Ação não concluída!"
          });
          break;
      }
    },

  }
});

