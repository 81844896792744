<template>
  <section class="">
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Dados do Serviço</h3>
        <div v-if="serviceManager">
          <router-link
            class="collabItens"
            :to="{
              name: 'editar-servico',
              params: { idService: idService },
            }"
          >
            <AddUser>
              <span slot="title">
                Editar<br />
                Serviço</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <slot />
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'servicos' }"
              >Lista de Serviços</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Dados do Serviço</span>
          </li>
        </ol>
      </nav>

      <div v-if="loadingService">
        <Loading />
      </div>
      <div v-else>
        <article v-if="service">
          <ul>
            <li class="items itemList">
              <span class="titleBlue">Nome</span>
              <div class="list">
                <span class="textGray">{{ service.name }}</span>
              </div>
            </li>
            <li class="items itemList">
              <span class="titleBlue">Descrição</span>
              <div class="list">
                <span class="textGray">{{ service.description }}</span>
              </div>
            </li>
            <li class="items itemList">
              <span class="titleBlue">Activo</span>
              <div class="list" :class="active">
                <!-- <span class="textGray">{{ service.active }}</span> -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.431"
                  height="14.794"
                  viewBox="0 0 14.431 14.794"
                >
                  <g transform="translate(1.413 1.357)">
                    <g transform="translate(0 0)">
                      <path
                        d="M2.5,10.47l4.868,4.467L14.161,2.5"
                        transform="translate(-2.5 -2.5)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </li>
            <li class="items itemList">
              <span class="titleBlue">Serviço Padrão</span>
              <div class="list" :class="isDefault">
                <!-- <span class="textGray">{{ service.isDefault }}</span> -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="14.431"
                  height="14.794"
                  viewBox="0 0 14.431 14.794"
                >
                  <g transform="translate(1.413 1.357)">
                    <g transform="translate(0 0)">
                      <path
                        d="M2.5,10.47l4.868,4.467L14.161,2.5"
                        transform="translate(-2.5 -2.5)"
                        fill="none"
                        stroke="#fff"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        stroke-width="2"
                      />
                    </g>
                  </g>
                </svg>
              </div>
            </li>
            <li class="items itemList">
              <span class="titleBlue">Criado</span>
              <div class="list">
                <span class="textGray">{{
                  service.created | formatDateReverse
                }}</span>
              </div>
            </li>
            <li class="items itemList">
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <!-- {{ service }} -->
                <span class="textGray">
                  {{ service.modified | formatDateReverse }}</span
                >
              </div>
            </li>
          </ul>
        </article>
      </div>
    </div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Service",
  props: ["idService"],
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "wait",
      "showModalDel",
      "selectedId",
    ]),
    ...mapState("service", ["service", "loadingService"]),
    ...mapState("user", ["user", "permissions"]),
    ...mapGetters("service", ["serviceReset"]),
    ...mapGetters("user", ["serviceManager"]),
    active() {
      return this.service.active ? "true" : "false";
    },

    isDefault() {
      return this.service.isDefault ? "true" : "false";
    },
  },

  created() {
    this.$store.dispatch("service/getService", {
      idService: this.idService,
    });
  },
};
</script>

<style scoped>
hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 250px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

.true {
  width: 30px;
  height: 30px;
  background: #28a745;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
}

.false {
  width: 30px;
  height: 30px;
  background: #cbcbcb;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px;
}

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
}
</style>
