<template>
  <section class="">
    <div class="containerContact">
      <div class="titleContainer">
        <!-- <h3>Dados do Processo</h3>
        <div v-if="processAccess">
          <router-link
            class="collabItens"
            :to="{
              name: 'editar-processo',
              params: {
                idProcess: idProcess,
              },
            }"
          >
            <AddUser>
              <span slot="title">
                Editar<br />
                Processo</span
              >
            </AddUser>
          </router-link>
        </div> -->
        <h3>Processo</h3>
        <div class="menuDropdown" v-if="processEdit">
          <BtnConfig>
            <span slot="title">
              Controlo <br />
              do Processo</span
            >
            <ul slot="itemsMenu" class="menuDropdownContent">
              <div class="titleMenuDropdown">Adicionar</div>
              <li @click="openModalOpposite">Interveniente</li>
              <li @click="openModalTax">Taxa</li>
              <li v-if="processEdit || processManager" @click="openModalFile">
                Ficheiro
              </li>
              <div class="titleMenuDropdown topsubmenu">Editar</div>
              <li>
                <router-link
                  class="collabItens"
                  :to="{
                    name: 'editar-processo',
                    params: {
                      idProcess: idProcess,
                    },
                  }"
                >
                  Processo
                </router-link>
              </li>
              <div class="titleMenuDropdown topsubmenu" v-if="processEdit || processManager">Remover</div>
              <li @click.prevent="confirmDeleteProcess(idProcess)">               
                  Processo
              </li>
            </ul>
          </BtnConfig>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link
              :to="{ name: 'processos', query: { archived: 'false' } }"
              >Lista de Processos</router-link
            >
          </li>
          <!-- <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'interveniente',
                params: { idOpposite: process.oppositeID },
              }"
              >Dados do Interveniente</router-link
            >
          </li> -->
          <li class="breadcrumb-item-active">
            <span>Processo</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingProcess">
        <Loading />
      </div>
      <div v-else>
        <article v-if="process">
          <div class="topProcess">
            <div
              class="items numberProcess"
              v-if="process.processNumber && process.processNumber.length > 0"
            >
              <span class="titleBlue">Número do Processo</span>
              <div class="list">
                <span class="textGray">{{ process.processNumber }}</span>
              </div>
            </div>
            <div
              class="textGreen btnDetail"
              @click="detailsProcess = !detailsProcess"
            >
              Detalhes do Processo
            </div>
          </div>
          <ul class="borderTable" v-if="detailsProcess">
            <li
              class="items itemList"
              v-if="process.name && process.name.length > 0"
            >
              <span class="titleBlue">Nome</span>
              <div class="list">
                <span class="textGray">{{ process.name }}</span>
              </div>
            </li>
            <!-- <li
              class="items itemList"
              v-if="process.processNumber && process.processNumber.length > 0"
            >
              <span class="titleBlue">Número do Processo</span>
              <div class="list">
                <span class="textGray">{{ process.processNumber }}</span>
              </div>
            </li> -->
            <li
              class="items itemList"
              v-if="process.startDate && process.startDate.length > 0"
            >
              <span class="titleBlue">Início do Processo</span>
              <div class="list">
                <span class="textGray">{{
                  process.startDate | formatDateReverse
                }}</span>
              </div>
            </li>
            <!-- #################
            #     INTERVENIENTE 
            ################# -->

            <li
              class="items itemList"
              v-if="process.oppositeList && process.oppositeList.length > 0"
            >
              <span class="titleBlue">Interveniente</span>

              <div class="list">
                <div
                  v-for="opposite in process.oppositeList"
                  :key="opposite.id"
                >
                  <router-link
                    :to="{
                      name: 'interveniente',
                      params: {
                        idOpposite: opposite.id,
                        idRouter: idProcess,
                        router: 'processo',
                      },
                    }"
                  >
                    <span class="textGray"
                      >{{ opposite.name }}
                      <span class="textGreen">(mais detalhes)</span></span
                    >
                  </router-link>
                </div>
              </div>
            </li>
            <!-- #################
            #   PROCESS TYPE
            ################# -->
            <li class="items itemList">
              <span class="titleBlue">Tipo de Processo</span>

              <div class="list">
                <router-link
                  v-if="processTypeItem && processTypeItem.name != undefined"
                  :to="{
                    name: 'tipo-de-processo',
                    params: {
                      idProcessType: process.processTypeID,
                      idRouter: idProcess,
                      router: 'processo',
                    },
                  }"
                >
                  <span class="textGray"
                    >{{ processTypeItem.name }}
                    <span class="textGreen">(mais detalhes)</span></span
                  >
                </router-link>
              </div>
            </li>
            <!-- #################
            #   TRIBUNAL
            ################# -->
            <li class="items itemList">
              <span class="titleBlue">Tribunal</span>
              <div class="list">
                <div v-if="process.courtID != null">
                  <router-link
                    v-if="
                      processCourtItem && processCourtItem.name != undefined
                    "
                    :to="{
                      name: 'tribunal',
                      params: {
                        idProcessCourt: process.courtID,
                        idRouter: idProcess,
                        router: 'processo',
                      },
                    }"
                  >
                    <span class="textGray"
                      >{{ processCourtItem.name }}
                      <span class="textGreen">(mais detalhes)</span></span
                    >
                  </router-link>
                </div>
                <div v-else>
                  <p class="textGray">Não possui Tribunal</p>
                </div>
              </div>
            </li>

            <!-- #################
            #   Position
            ################# -->
            <li class="items itemList">
              <span class="titleBlue">Posição</span>

              <div class="list">
                <div v-if="process.positionID != null">
                  <router-link
                    v-if="
                      processPositionItem &&
                      processPositionItem.name != undefined
                    "
                    :to="{
                      name: 'posicao',
                      params: {
                        idProcessPosition: process.positionID,
                        idRouter: idProcess,
                        router: 'processo',
                      },
                    }"
                  >
                    <span class="textGray"
                      >{{ processPositionItem.name }}
                      <span class="textGreen">(mais detalhes)</span></span
                    >
                  </router-link>
                </div>
                <div v-else>
                  <p class="textGray">Não possui Posição</p>
                </div>
              </div>
            </li>
            <!-- TAXA -->
            <li class="items itemList" id="address">
              <span class="titleBlue">Taxa</span>
              <ul class="items">
                <div v-if="loadingProcess">
                  <p><LoadingCircle /></p>
                </div>
                <div v-else>
                  <div v-if="process.taxes && process.taxes.length > 0">
                    <li
                      class="textGray"
                      v-for="tax in process.taxes"
                      :key="tax.id"
                    >
                    <!-- {{tax}} -->
                    <!-- {{processTypeTaxes}} -->
                      <ProcessTaxItem :tax="tax" :processTypeTaxes="processTypeTaxes">
                        
                        <!-- <router-link
                          v-if="processEdit"
                          slot="editAddress"
                          class="edit"
                          :to="{
                            name: 'editar-tipo-de-taxa',
                            params: {
                              idProcessTypeTax: tax.id,
                            },
                          }"
                        >
                        </router-link>
                        <button
                          v-if="processEdit"
                          slot="delete"
                          class="delete"
                          @click.prevent="confirmDeleteProcessTypeTax(tax.id)"
                        ></button> -->
                      </ProcessTaxItem>
                    </li>
                  </div>
                  <div v-else>
                    <p class="textGray">Não possui Taxas</p>
                  </div>
                </div>
              </ul>
            </li>
            <!-- VALOR -->
            <li class="items itemList" v-if="process.value">
              <span class="titleBlue">Valor</span>
              <div class="list">
                <span class="textGray">{{ process.value | numberPrice }}</span>
              </div>
            </li>
            <li
              class="items itemList"
              v-if="process.created && process.created.length > 0"
            >
              <span class="titleBlue">Criado</span>
              <div class="list">
                <!-- <span class="textGray">{{ createdprocess }}</span> -->
                <span class="textGray">{{
                  process.created | formatDateReverse
                }}</span>
              </div>
            </li>
            <li
              class="items itemList"
              v-if="process.modified && process.modified.length > 0"
            >
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <!-- <span class="textGray">{{ modifiedprocess }}</span> -->
                <span class="textGray">{{
                  process.modified | formatDateReverse
                }}</span>
              </div>
            </li>
            <!-- <li class="items containerFile">
              <span class="titleBlue">Ficheiros</span>

              <div v-if="loadingImg">
                <LoadingCircle />
              </div>

              <div v-else>
                <div v-if="processEdit || processManager" class="containerFile">
                  <div class="inputList">
                    <label for="fileInput" class="inputFile"
                      >Selecione um ficheiro
                      <span class="spot">clique aqui</span></label
                    >
                    <span v-if="selectedFile != null" class="itemFile"
                      >{{ selectedFile.name
                      }}<span
                        @click.prevent="cleanFile($event)"
                        class="clearFile"
                        >&#215;</span
                      ></span
                    >
                    <input
                      type="file"
                      @change="onFileSelected"
                      id="fileInput"
                      class="inputNone"
                    />
                  </div>
                  <button class="btn addFile" @click="onUpload(process.id)">
                    Adicionar
                  </button>
                </div>
              </div>
            </li> -->
            <li class=" containerFile">
              <article>
              <div v-if="loadingImg"></div>
              <div v-else>
                <span class="titleBlue">Ficheiros</span>
                <div v-if="process.files != null && process.files.length > 0">
                  <article class="downloadImgLoading" v-if="loadingDownloadImg">
                    Aguarde, fazendo download...
                  </article>
                  <ul v-else>
                    <li v-for="archive in process.files" :key="archive.id">
                      <article class="articleInfos">
                        <ArchiveItem
                          :archive="archive"
                          :idMicroservice="process.id"
                          @getFile="getFile(archive.filename, archive.id)"
                        >
                          <button
                            slot="delete"
                            class="delete"
                            @click.prevent="
                              confirmDeleteFileProcess(archive.id)
                            "
                          ></button>
                        </ArchiveItem>
                      </article>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <p class="noResults noArchive">
                    Não possui ficheiros registrados
                  </p>
                </div>
              </div>
            </article>
            </li>
          </ul>
        </article>
        <!--#########################
        ##      TIMELINE
        ######################### -->
        <article class="timeline">
          <!--       ADD NOTE
          ######################### -->
          <div class="menuDropdown">
            <div
              v-if="processEdit"
              class="btnAddNote"
              @click.prevent="openModalNote"
            >
              <img src="@/assets/svg/noteAdd.svg" />
              <span class="titleAddNote">
                Adicionar<br />
                Nota</span
              >
            </div>
          </div>
          <!--  TIMELINE - HISTORY
          ######################### -->
          <!-- <TimeLine :process="process"> -->
          <div class="wrapper" v-if="timeline && timeline.length > 0">
            <div class="center-line">
              <p class="scroll-icon"></p>
            </div>
            <div v-for="processItem in timeline" :key="processItem.id">
              <div
                class="row"
                :class="{
                  row2: processItem.css == 'false',
                  row1: processItem.css == 'true',
                }"
              >
                <!-- :timeline="timeline" -->
                <TimeLineItem
                  :process="process"
                  :processItem="processItem"
                  :processTypes="processTypes"
                  :processCourts="processCourts"
                  :processPositions="processPositions"
                  :processOpposites="processOpposites"
                  :processTypeTaxes="processTypeTaxes"
                  @modalNote="openModalNote"
                  @modalTax="openModalTax"
                  @modalDelNote="confirmDeleteProcessNote"
                  @modalDelFile="confirmDeleteFileProcess"
                  @modalDelFileTax="confirmDeleteFileProcessTax"
                  @modalDelOpposite="confirmDeleteProcessOpposite"
                  @modalDelTax="confirmDeleteProcessTax"
                />
              </div>
            </div>
          </div>
          <div v-else>
            <p class="noResults">Não possui alterações no processo.</p>
          </div>
          <!-- </TimeLine> -->
        </article>
      </div>
    </div>
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteFile"
        @click="closeModalDelFileProcess"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteFile">
        <button class="close btn" @click="closeModalDelFileProcess">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este ficheiro?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelFileProcess"
          />
          <button class="btnDel" @click.prevent="deleteFileProcess">
            Delete
          </button>
        </div>
      </div>
    </div>
    <!-- MODAL DELETE FILE TAX -->
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteFileTax"
        @click="closeModalDelFileProcessTax"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteFileTax">
        <button class="close btn" @click="closeModalDelFileProcessTax">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este ficheiro?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelFileProcessTax"
          />
          <button class="btnDel" @click.prevent="deleteFileProcessTax">
            Delete
          </button>
        </div>
      </div>
    </div>
    <!--#########################
    ##  MODAL SELET TAX
    ######################### -->
    <div class="modal">
      <div
        class="overlay"
        v-if="showModalProcessTypeTax"
        @click="closeModalProcessTypeTax"
      ></div>
      <div class="modal_container" v-if="showModalProcessTypeTax">
        <button class="close btn" @click="closeModalProcessTypeTax">x</button>
        <section class="modalOverflow">
          <!-- <article>
            <h3 class="center">Adicionar taxa</h3>
            <p>
              <ProcessTaxForm :errors="errors">
               
                <div slot="enviar">
                  <button
                    class="btn btn-form"
                    @click.prevent="addProcessTax"
                  >
                    Adicionar
                  </button>
                </div>
              </ProcessTaxForm>
            </p>
          </article> -->
          <article>
            <h3 class="center">Adicionar Taxa</h3>
            <div class="btnAddItem" v-if="btnAddTypeTax == false">
              <div class="btnAdd" @click="btnAddTypeTax = !btnAddTypeTax">
                <label class="circle">
                  <svg
                    class="icon-add"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                </label>
                <label class="titleAdd">
                  Criar<br />
                  Tipo de Taxa
                </label>
              </div>
            </div>
            <div v-if="btnAddTypeTax == false">
              <ProcessTaxForm :errors="errors">
                
                <div slot="enviar">
                  <button 
                  v-if="taxUpdate == false"
                  class="btn btn-form"
                  @click.prevent="addProcessTax">
                    Adicionar
                  </button>
                  <button 
                  v-if="taxUpdate == true"
                  class="btn btn-form"
                  @click.prevent="updateProcessTax(taxID)">
                    Editar
                  </button>
                  
                </div>
              </ProcessTaxForm>
            </div>
            <div v-if="btnAddTypeTax == true">
              <ProcessTypeTaxForm :errors="errors">
                <div slot="cancel">
                  <button
                    class="btn btn-cancel"
                    @click.prevent="btnAddTypeTax = false"
                  >
                    Cancelar
                  </button>
                </div>
                <div slot="enviar">
                  <button class="btn btn-form" @click.prevent="addProcessTypeTax">
                    Adicionar
                  </button>
                </div>
              </ProcessTypeTaxForm>
            </div>
          </article>
        </section>
      </div>
    </div>
    <!-- MODAL OPPOSITE -->
    <div class="modal">
      <div
        class="overlay"
        v-if="showModalProcessOpposite"
        @click="closeModalProcessOpposite"
      ></div>
      <div class="modal_container" v-if="showModalProcessOpposite">
        <button class="close btn" @click="closeModalProcessOpposite">x</button>
        <section class="modalOverflow">
          <article>
            <h3 class="center">Adicionar Interveniente</h3>
            <div class="btnAddItem" v-if="btnAddOpposite == false">
              <div class="btnAdd" @click="btnAddOpposite = !btnAddOpposite">
                <label class="circle">
                  <svg
                    class="icon-add"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                </label>
                <label class="titleAdd">
                  Criar<br />
                  Interveniente
                </label>
              </div>
            </div>
            <div v-if="btnAddOpposite == false">
              <OppositeSearch />
              <div class="containerOtherTax">
                <ul class="otherContactItens">
                  <div class="taxTitle">
                    <span class="borderTax">Nome</span>
                    <span>Nif</span>
                  </div>
                  <li
                    v-for="opposite in filteredProcessOpposites"
                    :key="opposite.id"
                    class="otherContactItem"
                  >
                    <div
                      v-if="opposite.name != ''"
                      @click.prevent="associateOpposite(opposite.id)"
                    >
                      <div class="taxText">
                        <span class="borderTax"> {{ opposite.name }}</span>
                        <span> {{ opposite.nif }}</span>
                      </div>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="btnAddOpposite == true">
              <OppositeForm :errors="errors">
                <div slot="cancel">
                  <button
                    class="btn btn-cancel"
                    @click.prevent="btnAddOpposite = false"
                  >
                    Cancelar
                  </button>
                </div>
                <div slot="enviar">
                  <button class="btn btn-form" @click.prevent="addOpposite">
                    Adicionar
                  </button>
                </div>
              </OppositeForm>
            </div>
          </article>
        </section>

        <!-- <div class="containerDel"></div> -->
      </div>
    </div>
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelProcessTypeTax"
        @click="closeModalDelProcessTypeTax"
      ></div>
      <div class="modalDel_container" v-if="showModalDelProcessTypeTax">
        <button class="close btn" @click="closeModalDelProcessTypeTax">
          x
        </button>
        <p class="delItem">
          <span>Você tem certeza que deseja desassociar esta taxa do processo?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelProcessTypeTax"
          />
          <button class="btnDel" @click.prevent="deleteAssocieateTax">
            Delete
          </button>
        </div>
      </div>
    </div>
    <!--#########################
    ##  MODAL FICHEIRO
    ######################### -->
    <div class="modalDel">
      <div class="overlay" v-if="showModalFile" @click="closeModalFile"></div>
      <div class="modalDel_container" v-if="showModalFile">
        <button class="close btn" @click="closeModalFile">x</button>
        <h3 class="center">Adicionar Ficheiro</h3>
        <!-- <div class="containerDel"> -->
        <div v-if="processEdit || processManager" class="containerFile">
          <div class="inputList">
            <label for="fileInput" class="inputFile"
              >Selecione um ficheiro
              <span class="spot">clique aqui</span></label
            >
            <span v-if="selectedFile != null" class="itemFile"
              >{{ selectedFile.name
              }}<span @click.prevent="cleanFile($event)" class="clearFile"
                >&#215;</span
              ></span
            >
            <input
              type="file"
              @change="onFileSelected"
              id="fileInput"
              class="inputNone"
            />
          </div>
          <button class="btn addFile" @click="onUpload(process.id)">
            Adicionar
          </button>
          <!-- </div> -->
        </div>
      </div>
    </div>

    <!--#########################
    ##  MODAL DEL OPPOSITE
    ######################### -->

    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelProcessOpposite"
        @click="closeModalDelProcessOpposite"
      ></div>
      <div class="modalDel_container" v-if="showModalDelProcessOpposite">
        <button class="close btn" @click="closeModalDelProcessOpposite">
          x
        </button>
        <p class="delItem">
          <span>Você tem certeza que deseja desassociar este interveniente do processo?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelProcessOpposite"
          />
          <button class="btnDel" @click.prevent="deleteAssocieateOpposite">
            Delete
          </button>
        </div>
      </div>
    </div>
    <!--#########################
    ##  MODAL NOTE
    ######################### -->
    <div class="modal">
      <div
        class="overlay"
        v-if="showModalProcessNote"
        @click="closeModalNote"
      ></div>
      <div class="modal_container" v-if="showModalProcessNote">
        <button class="close btn" @click="closeModalNote">x</button>
        <h1>Nota</h1>
        <NoteForm :errors="errors" :processNote="processNote">
          <button
            v-if="noteUpdate == false"
            class="btn btnNote"
            @click.prevent="addProcessNote()"
          >
            Adicionar
          </button>
          <button
            v-if="noteUpdate == true"
            class="btn btnNote"
            @click.prevent="updateProcessNote(idNote)"
          >
            Gravar
          </button>
          <!-- {{ idNote }} -->
        </NoteForm>
      </div>
    </div>

    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelProcessNote"
        @click="closeModalDelProcessNote"
      ></div>
      <div class="modalDel_container" v-if="showModalDelProcessNote">
        <button class="close btn" @click="closeModalDelProcessNote">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover esta nota?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelProcessNote"
          />
          <button class="btnDel" @click.prevent="deleteProcessNote">
            Delete
          </button>
        </div>
      </div>
    </div>
    <!-- MODAL DELETE PROCESS -->
     <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelProcess"
        @click="closeModalDelProcess"
      ></div>
      <div class="modalDel_container" v-if="showModalDelProcess">
        <button class="close btn" @click="closeModalDelProcess">x</button>
        <div class="delItem">
          <span>Você tem certeza que deseja remover este processo? </span><br>
          <span> Digite corretamente o número do processo para remover: <br><span class="textRed">{{process.processNumber}}</span></span>
          <!-- {{processDel}} -->
          <input
            id="numberProcess"
            name="numberProcess"
            type="text"
            v-model="processDel"
            autocomplete="off"
            :class="validateProcessDel"
            @change="resetError"
          />
          <div v-if="!errors.indexOf('processNotify')" class="notify2">
            O Número do Processo está incorreto
          </div>
        </div>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelProcess"
          />
          <button class="btnDel" @click.prevent="deleteProcess">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import OppositeForm from "@/components/legal/OppositeForm.vue";
import NoteForm from "@/components/legal/NoteForm.vue";
import TimeLineItem from "@/components/legal/TimeLineItem.vue";
// import TimeLine from "@/components/legal/TimeLine.vue";
import ProcessTypeTaxSearch from "@/components/legal/ProcessTypeTaxSearch.vue";
import OppositeSearch from "@/components/legal/OppositeSearch.vue";
import ProcessTaxItem from "@/components/legal/ProcessTaxItem.vue";
import ProcessTaxForm from "@/components/legal/ProcessTaxForm.vue";
import ProcessTypeTaxForm from "@/components/legal/ProcessTypeTaxForm.vue";
import ArchiveItem from "@/components/legal/ArchiveItem.vue";
// import { getLastDate } from "@/helpers.js";
// import { formatDate } from "@/helpers.js";
// import { alertDate } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Process",
  props: ["idProcess"],
  data() {
    return {
      searchHistory: false,
      noteUpdate: false,
      // taxUpdate:false,
      idNote: null,
      detailsProcess: false,
      btnAddOpposite: false,
      btnAddTypeTax: false,
      // processDel:null,
    };
  },
  components: {
    ArchiveItem,
    ProcessTaxItem,
    ProcessTypeTaxSearch,
    ProcessTaxForm,
    OppositeSearch,
    ProcessTypeTaxForm,
    // TimeLine,
    NoteForm,
    TimeLineItem,
    OppositeForm,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "loadingDownloadImg",
      "selectedId",
      "loadingImg",
      "showModalFile",
    ]),
    ...mapState("user", ["user"]),
    ...mapState("legal", [
      "process",
      "processDel",
      "processTypes",
      "processCourts",
      "processPositions",
      "processOpposites",
      "processTypeTaxes",
      "loadingProcess",
      "showModalDelProcess",
      "showModalDeleteFile",
      "showModalDeleteFileTax", 
      "selectedFile",
      "archiveID",
      "taxID",
      "fileID",
      "showModalProcessTax",
      "showModalDelProcessTypeTax",
      "showModalProcessTypeTax",
      "showModalProcessOpposite",
      "showModalDelProcessOpposite",
      "showModalProcessNote",
      "showModalDelProcessNote",
      "timeline",
      "processNote",
      "taxUpdate",
    ]),
    ...mapState("collaborator", ["createAddress", "createContact"]),
    ...mapGetters("legal", [
      "processTaxReply",
      "processTaxReset",
      "processTypeTaxReply",
      "processTypeTaxReset",
      "processNoteReply",
      "processNoteReset",
      "otherTaxes",
      "getFiltered",
      "processOppositeReply",
      "processOppositeReset",
      "processTypeTaxReply", 
      "processTypeTaxReset"
    ]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalyse",
      "processManager",
    ]),
    // VALIDAÇÃO REMOVER PROCESSO
    processDel: {
      get() {
        return this.$store.state.legal.processDel;
      },
      set(value) {
        this.$store.commit("legal/UPDATE_PROCESS_DEL", value);
      },      
    },
    validateProcessDel() {
      return this.errors.indexOf("processNotify") ? "true" : "validate";
    },
    // Filtro OPPOSITE
    filteredProcessOpposites() {
      const a = this.getFiltered || this.processOpposites;
      return a;
    },
    // Filtro OPPOSITE
    filteredProcessTypeTaxes() {
      const a = this.getFiltered || this.processTypeTaxes;
      return a;
    },
    // PEGANDO INFORMAÇÕES PELO ID
    //##################################################
    //                 OPPOSITES - NAME
    //##################################################
    oppositeItem() {
      if (this.processOpposites != null) {
        return this.processOpposites.find((processOpposite) => {
          return processOpposite.id == this.process.oppositeID;
        });
      } else {
        return "";
      }
    },
    //##################################################
    //                 PROCESS TYPES
    //##################################################
    processTypeItem() {
      if (this.processTypes != null) {
        return this.processTypes.find((processType) => {
          return processType.id == this.process.processTypeID;
        });
      } else {
        return "";
      }
    },
    //##################################################
    //                 PROCESS COURTS
    //##################################################
    processCourtItem() {
      if (this.processCourts != null) {
        return this.processCourts.find((processCourt) => {
          return processCourt.id == this.process.courtID;
        });
      } else {
        return "";
      }
    },
    //##################################################
    //                 PROCESS POSITIONS
    //##################################################
    processPositionItem() {
      if (this.processPositions != null) {
        return this.processPositions.find((processPosition) => {
          return processPosition.id == this.process.positionID;
        });
      } else {
        return "";
      }
    },
    
   
  },
  methods: {
    
    //######################################################
    //     FILE ARQUIVO PADRÂO
    //######################################################
    getFile(filename, archiveID) {
      this.$store.dispatch("attachment/getFile", {
        archiveID: `${archiveID}`,
        fileName: `${filename}`,
      });
    },
    cleanFile() {
      this.$store.commit("legal/UPDATE_SELECTEDFILE", null);
    },
    onFileSelected(event) {
      this.$store.commit("legal/UPDATE_SELECTEDFILE", event.target.files[0]);
    },

    onUpload(id) {
      const formData = new FormData();
      formData.append("file", this.selectedFile, this.selectedFile.name);
      // console.log("formData", formData);
      this.$store.dispatch("legal/onUploadProcess", {
        idProcess: `${id}`,
        data: formData,
        namefile: this.selectedFile.name,
      });
    },

    //######################################################
    //    ARQUIVOS MODAL DELETE
    //######################################################
    openModalFile() {
      this.$store.dispatch("openModalFile");
    },
    closeModalFile() {
      this.$store.dispatch("closeModalFile");
    },

    //    FICHEIROS MODAL DELETE
    //######################################################
    openModalDelFileProcess() {
      this.$store.dispatch("legal/openModalDelFileProcess");
    },
    closeModalDelFileProcess() {
      this.$store.dispatch("legal/closeModalDelFileProcess");
    },
    //     FICHEIROS PROCESS DELETE
    //######################################################
    confirmDeleteFileProcess(id) {
      // console.log(archiveID);
      this.$store.dispatch("legal/confirmDeleteFileProcess", {
        archiveID: `${id}`,
      });
    },
    deleteFileProcess() {
      this.$store.dispatch("legal/deleteFileProcess", {
        idProcess: this.idProcess,
        archiveID: this.archiveID,
      });
      // this.$store.dispatch("legal/getProcess", {
      //   idProcess: this.idProcess,
      // });
      this.closeModalDelFileProcess();
    },
    openModalDel() {
      this.$store.dispatch("legal/openModalDel");
    },
    closeModalDel() {
      this.$store.dispatch("legal/closeModalDel");
    },
    //################################
    //              TAX
    //################################
    addProcessTax(){
      //  this.closeModalProcessTypeTax();     
      this.$store.dispatch("legal/addProcessTax", {
        processTax: this.processTaxReply, //enviar getters com as infos necessárias para o cadastro
        idModule: this.idProcess,
        mutation: "'legal/updateAssocieateTax'",
        // data: formData,
        // namefile: this.selectedFile.name,
      });
    },
    addProcessTypeTax() {
      // console.log(this.processTaxReply);
      this.$store.dispatch("legal/addProcessTypeTax", {
        processTypeTax: this.processTypeTaxReply, //enviar getters com as infos necessárias para o cadastro
      });
      this.btnAddTypeTax = false;
    },

    updateProcessTax(id){
      console.log(id)
      this.$store.dispatch("legal/updateProcessTax", {
        processTax: this.processTaxReply, //enviar getters com as infos necessárias para o cadastro
        idModule: this.idProcess,
        idProcessTax: `${id}`,
        // data: formData,
        // namefile: this.selectedFile.name,
      });
    },
    openModalTax(id, value) {   
      if (value != undefined) {
        this.$store.commit("legal/UPDATE_SELECTED_TAXID", id)   
        this.$store.commit("legal/UPDATE_TAX_UPDATE", true)  
        // this.taxUpdate = true;
        this.btnAddTypeTax = false;
      }else {
        this.$store.commit("legal/UPDATE_TAX_UPDATE", false)  
        // this.taxUpdate = false;
        this.$store.commit("legal/UPDATE_PROCESS_TYPETAX", this.processTypeTaxReset);   
         this.btnAddTypeTax = false;
        this.$store.commit(
          "legal/UPDATE_PROCESS_TAX",
          this.processTaxReset
        );
      }
      this.$store.dispatch("legal/openModalProcessTypeTax");
    },
    closeModalProcessTypeTax() {
      this.$store.dispatch("legal/closeModalProcessTypeTax");
    },
    // "DELETE TAX" desassociar
    // ############################
    openModalDelProcessTypeTax() {
      this.$store.dispatch("legal/openModalDelProcessTypeTax");
    },
    closeModalDelProcessTypeTax() {
      this.$store.dispatch("legal/closeModalDelProcessTypeTax");
    },
    confirmDeleteProcessTax(id) {
      // console.log(id);
      this.$store.dispatch("legal/confirmDeleteProcessTypeTax", {
        id: `${id}`,
      });
    },
    deleteAssocieateTax() {
      // console.log("selectedId", this.selectedId.id);
      this.$store.dispatch("legal/deleteAssocieateTax", {
        id: this.selectedId.id,
        idProcess: this.idProcess,
      });
      this.closeModalDelProcessTypeTax();
    },

    //     FICHEIROS TAX DELETE
    //######################################################
    confirmDeleteFileProcessTax(id, taxID) {      
      this.$store.dispatch("legal/confirmDeleteFileProcessTax", {
        archiveID: `${id}`,
        taxID: `${taxID}`,
      });
    },
    deleteFileProcessTax() {
      this.$store.dispatch("legal/deleteFileProcessTax", {
        idProcess: this.idProcess,
        archiveID: this.archiveID,
        taxID: this.taxID,
      });
      // this.$store.dispatch("legal/getProcess", {
      //   idProcess: this.idProcess,
      // });
      this.closeModalDelFileProcessTax();
    },
    openModalDelFileProcessTax() {
      this.$store.dispatch("legal/openModalDelFileProcessTax");
    },
    closeModalDelFileProcessTax() {
      this.$store.dispatch("legal/closeModalDelFileProcessTax");
    },
    // ############################
    //     Opposite
    // ############################
    addOpposite() {
      // console.log(this.processOppositeReply);
      this.$store.dispatch("legal/addProcessOpposite", {
        processOpposite: this.processOppositeReply, //enviar getters com as infos necessárias para o cadastro
        idModule: this.idProcess,
      });
    },
    //     Associar Opposite
    // ############################
    associateOpposite(id) {
      this.closeModalProcessOpposite();
      this.$store.dispatch("legal/updateAssocieateOpposite", {
        idOpposite: `${id}`,
        idModule: this.idProcess,
      });
      this.$store.commit("legal/RESET_FILTER", null);
    },
    openModalOpposite() {
      this.btnAddOpposite = false;
      this.$store.commit("legal/RESET_FILTER", null);
      this.$store.dispatch("legal/openModalProcessOpposite");
      this.$store.commit(
        "legal/UPDATE_PROCESS_OPPOSITE",
        this.processOppositeReset
      );
    },
    closeModalProcessOpposite() {
      this.$store.dispatch("legal/closeModalProcessOpposite");
    },
    // "DELETE OPPOSITE" desassociar
    // ############################
    openModalDelProcessOpposite() {
      this.$store.dispatch("legal/openModalDelProcessOpposite");
    },
    closeModalDelProcessOpposite() {
      this.$store.dispatch("legal/closeModalDelProcessOpposite");
    },
    confirmDeleteProcessOpposite(id) {
      // console.log(id);
      this.$store.dispatch("legal/confirmDeleteProcessOpposite", {
        id: `${id}`,
      });
    },
    deleteAssocieateOpposite() {
      // console.log("selectedId", this.selectedId.id);
      this.$store.dispatch("legal/deleteAssocieateOpposite", {
        id: this.selectedId.id,
        idProcess: this.idProcess,
      });
      this.closeModalDelProcessOpposite();
    },

    // ############################
    //          NOTE
    // ############################
    openModalNote(id, value) {
      // console.log(value);
      if (value != undefined) {
        this.idNote = id;
        this.noteUpdate = true;
        // console.log(id);
        this.$store.dispatch("legal/getProcessNote", {
          idProcess: this.idProcess,
          idProcessNote: `${id}`,
        });
      } else {
        this.idNote = "";
        this.noteUpdate = false;
        this.$store.commit("legal/UPDATE_PROCESS_NOTE", this.processNoteReset);
      }
      // this.idNote = value != undefined ? id : "";
      // this.noteUpdate = value != undefined ? true : false;

      this.$store.dispatch("legal/openModalNote");
    },
    closeModalNote() {
      this.$store.dispatch("legal/closeModalNote");
      this.$store.commit("legal/UPDATE_PROCESS_NOTE", this.processNoteReset);
      // this.getCalls();
    },
    //        ADD NOTE
    // ############################
    addProcessNote() {
      this.$store.dispatch("legal/addProcessNote", {
        idProcess: this.idProcess,
        processNote: this.processNoteReply,
      });
      // this.closeModalNote();
      // this.$store.dispatch("legal/getProcess", {
      //   idProcess: this.idProcess,
      // });
    },
    //       UPDATE NOTE
    // ############################
    updateProcessNote(id) {
      // console.log(id);
      // console.log(this.processNoteReply);
      this.$store.dispatch("legal/updateProcessNote", {
        idProcessNote: `${id}`,
        idProcess: this.idProcess,
        processNote: this.processNoteReply,
      });
      this.closeModalNote();
    },
    //         DELETE NOTE
    // ############################
    openModalDelProcessNote() {
      this.$store.dispatch("legal/openModalDelProcessNote");
    },
    closeModalDelProcessNote() {
      this.$store.dispatch("legal/closeModalDelProcessNote");
    },
    confirmDeleteProcessNote(id) {
      // console.log("aqui", id);
      this.$store.dispatch("legal/confirmDeleteProcessNote", {
        id: `${id}`,
      });
    },
    deleteProcessNote() {
      // console.log("selectedId", this.selectedId.id);
      this.$store.dispatch("legal/deleteProcessNote", {
        id: this.selectedId.id,
        idProcess: this.idProcess,
      });
      this.closeModalDelProcessNote();
    },
    //######################################################
    //     DELETE
    //######################################################
    openModalDelProcess() {  
      this.$store.dispatch("legal/openModalDelProcess");
    },
    closeModalDelProcess() {
      this.$store.dispatch("legal/closeModalDelProcess");
    },
    confirmDeleteProcess(id) {
      this.resetError()
      this.$store.commit("legal/UPDATE_PROCESS_DEL", null);
      // console.log(id);
      this.$store.dispatch("legal/confirmDeleteProcess", {
        id: `${id}`,
      });
    },
    deleteProcess() {
      if(this.processDel === this.process.processNumber){
        // console.log(this.processDel)
        // console.log(this.process.processNumber)
        this.$store.dispatch("legal/deleteProcess", {
          // id: this.process.id,
          id: this.selectedId.id,
        });
        this.closeModalDelProcess();
      }else{
        this.$store.commit("UPDATE_ERRORS", "processNotify");
      }
      // console.log("selectedId", this.selectedId.id);
    },
    resetError() {
      this.$store.commit("RESET_ERRORS", []);
    },
  },

  created() {
    this.closeModalProcessTypeTax();
    // this.$store.dispatch("legal/getProcess", {
    //   idProcess: this.idProcess,
    // });
    this.$store.dispatch("legal/getProcessOpposites");
    // this.$store.dispatch("legal/getProcessTypes");
    // this.$store.dispatch("legal/getProcessCourts");
    // this.$store.dispatch("legal/getProcessPositions");
    // this.$store.dispatch("legal/getProcessTypeTaxes");
  },
};
</script>

<style scoped>
.textRed{
  font-size: 16px;
}
.notify2{
  width: 100%;
}
.inputList[data-v-1f25a6ce] {
    display: grid;
    justify-content: flex-start;
    justify-items: center;
}
.btn-cancel {
  background: #dc3545;
}
.btnAddItem {
  display: grid;
  justify-content: flex-end;
}
.btnAdd {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
}
.titleAdd {
  font-size: 12px;
  cursor: pointer;
}

.icon-add {
  fill: #fff;
}
.circle {
  background: #1f2b42;
  width: 35px;
  height: 35px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.icon-add:hover {
  fill: #fff;
}
.circle:hover {
  background: #28a745;
  width: 35px;
  height: 35px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
.borderTable {
  margin-top: 30px;
}
.btnDetail {
  cursor: pointer;
}
.numberProcess {
  display: flex;
}
.topProcess {
  display: flex;
  justify-content: space-between;
}
.taxTitle {
  display: grid;
  grid-template-columns: 120px 1fr;
  font-weight: bold;
}
.taxText {
  display: grid;
  grid-template-columns: 120px 1fr;
}
/* .taxText .borderTax {
  border-right: 1px solid #1f2b42;
  margin-right: 5px;
} */

/* ######################### 
  #      INÍCIO TIMELINE
  ######################### */
.wrapper {
  max-width: 1080px;
  margin: 50px auto;
  padding: 0 20px;
  position: relative;
}
.wrapper .center-line {
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  background: #ececec;
}
.wrapper .row {
  display: flex;
}
.wrapper .row1 {
  justify-content: flex-start;
}
.wrapper .row2 {
  justify-content: flex-end;
}
.wrapper .row section {
  background: #fff;
  border-radius: 9px;
  width: calc(50% - 40px);
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 8px rgb(30 60 90 / 20%);
  margin-bottom: 30px;
}
.row1 section::before {
  right: -7px;
}
.row2 section::before {
  left: -7px;
}
.row section .icon,
.center-line .scroll-icon {
  position: absolute;
  background: #f2f2f2;
  height: 10px;
  width: 10px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #10a4e8;
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.center-line .scroll-icon {
  bottom: 0px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}
.row1 section .icon {
  top: 15px;
  right: -60px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.row2 section .icon {
  top: 15px;
  left: -60px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.row section .details,
.row section .bottom {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.row section .details .title {
  font-size: 22px;
  font-weight: 600;
}
.row section p {
  margin: 10px 0 17px 0;
}
.row section .bottom a {
  text-decoration: none;
  background: #10a4e8;
  color: #fff;
  padding: 7px 15px;
  border-radius: 5px;
  /* font-size: 17px; */
  font-weight: 400;
  transition: all 0.3s ease;
}
.row section .bottom a:hover {
  transform: scale(0.97);
}
@media (max-width: 790px) {
  .wrapper .center-line {
    left: 40px;
  }
  .wrapper .row {
    margin: 30px 0 3px 60px;
  }
  .wrapper .row section {
    width: 100%;
  }
  .row1 section::before {
    left: -7px;
  }
  .row1 section .icon {
    left: -60px;
  }
}
@media (max-width: 340px) {
  .wrapper .center-line,
  .row section::before,
  .row section .icon {
    display: none;
  }
  .wrapper .row {
    margin: 10px 0;
  }
}
/* ######################### 
  #      FIM TIMELINE
  ######################### */
/*NOTE */
.btnAddNote {
  display: flex;
  align-items: center;
  cursor: pointer;
  padding: 10px;
  grid-gap: 10px;
}
.titleAddNote {
  font-size: 12px;
  cursor: pointer;
}
.timeline {
  margin-top: 60px;
}
/* MODAL TAX */
.btnContact {
  display: grid;
  justify-content: center;
  margin-top: 30px;
}
.btnContact p {
  display: grid;
  justify-items: center;
  text-align: center;
  cursor: pointer;
}
.btnContact p#desable {
  margin-top: 40px;
}

.textGreen {
  margin-left: 0 !important;
}

.containerOtherTax {
  box-shadow: 0 4px 8px rgb(30 60 90 / 20%);
  padding: 20px;
  border-radius: 9px;
  height: 200px;
  margin: 20px 0;
}
.otherContactItens {
  overflow-y: auto;
  height: 150px;
  line-height: 30px;

  scrollbar-width: thin; /*padrão firefox*/
  scrollbar-color: #c5c5c5 transparent; /*padrão firefox*/
}
.otherContactItem:hover {
  background: #dbebf3;
  cursor: pointer;
}
.otherContactItens::-webkit-scrollbar {
  width: 10px;
  background: #ecebeb;
  border-radius: 7px;
}

.otherContactItens::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  opacity: 0.5;
  border-radius: 7px;
}
.btnContact {
  display: grid;
  justify-content: center;
}
.btnContact p {
  display: grid;
  justify-items: center;
  text-align: center;
  cursor: pointer;
}
.btnContact p#desable {
  margin-top: 40px;
}

.textAddContact {
  margin-top: 30px;
}
.center {
  text-align: center;
  margin-bottom: 30px;
}
.modalOverflow {
  overflow-y: auto;
  max-height: 600px;
  padding: 0 20px;
  margin: 30px 0;

  scrollbar-width: thin; /*padrão firefox*/
  scrollbar-color: #00bf48 transparent; /*padrão firefox*/
}
.modalOverflow::-webkit-scrollbar {
  width: 10px;
  background: #ecebeb;
  border-radius: 7px;
}

.modalOverflow::-webkit-scrollbar-thumb {
  background: #00bf48;
  opacity: 0.5;
  border-radius: 7px;
}
/* FIM MODAL TAX */
.proximitDate {
  color: red;
}
.validate {
  margin-left: 10px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}
/* files */
.clearFile {
  font-size: 24px;
  line-height: 28px;
  margin-left: 10px;
  font-weight: bold;
  color: #00732c;
  padding-top: 5px;
  cursor: pointer;
}
.containerFile {
  display: grid;
  align-content: center;
  align-items: baseline;
  grid-gap: 30px;
  margin: 20px 0 40px 0;
}
.inputNone {
  display: none;
}
.noArchive {
  margin: 20px 0 30px 0;
  margin-left: 10px;
}

.spot {
  font-size: 14px;
  color: #015eb0;
}
.list {
  /* display: grid; */
  align-content: center;
}
.inputList {
  display: grid;
  justify-content: center;
  justify-items: center;
}
/*INPUT FILE botão e arquivo */
.inputFile {
  cursor: pointer;
  font-size: 14px;
  width: 240px;
  border: 1px solid #015eb0;
  background: #eff7fe;
  border-radius: 9px;
  padding: 10px;
  color: #1f2b42;
  margin: 3px 10px 0 0;
  border-style: dotted;
}
/* fim files  */
.textGreen {
  margin-left: 10px;
  color: #00732c;
  font-size: 14px;
  line-height: 28px;
}
.textGreen:hover {
  text-decoration: underline;
}
.item2 {
  padding-left: 15px;
}
.addTipeVehicle {
  display: flex;
  align-items: center;
  /* margin-left: 15px; */
}
.containerLink {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.articleInfos,
.callsHistory {
  margin-top: 30px;
}

.titleInfos {
  margin-bottom: 10px;
}

.iconInfoSearchHistory {
  margin: 0 10px;
}
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

/* .center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
} */

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .items li {
    padding: 0 0 0 10px;
  }
  .list {
    padding: 0 10px;
  }
  .inputFile {
    margin: 3px 10px 0 10px;
  }
}
@media screen and (max-width: 390px) {
  .containerFile {
    display: grid;
    grid-gap: 10px;
  }
}
</style>
