<template>
  <section class="">
    <h1>Jurídico</h1>
    <div class="containerClient">
      <div class="titleContainer">
        <h3>Dados do Interveniente</h3>
        <div class="menuDropdown" v-if="processEdit">
          <BtnConfig>
            <span slot="title">
              Controlo <br />
              do Interveniente</span
            >
            <ul slot="itemsMenu" class="menuDropdownContent">
              <li>
                <router-link
                  class="collabItens"
                  :to="{
                    name: 'editar-interveniente',
                    params: { idOpposite: idOpposite },
                  }"
                >
                  Editar Interveniente
                </router-link>
              </li>
              <div class="titleMenuDropdown topsubmenu">Adicionar</div>
              <li>
                <router-link
                  :to="{
                    name: 'adicionar-processo',
                  }"
                  >Processo</router-link
                >
              </li>
              <li @click="openModalContact">Contacto</li>
              <li v-if="addressEdit && processEdit" @click="openModalAddress">
                Morada
              </li>
              <div class="titleMenuDropdown topsubmenu">Remover</div>
              <li
                @click.prevent="
                  confirmDeleteProcessOpposite(processOpposite.id)
                "
              >
                Interveniente
              </li>
            </ul>
          </BtnConfig>
        </div>
      </div>
      <nav class="navBreadcrumb" v-if="router == 'processo'">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos',
                   query: { archived: 'false'} }"
              >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'processo',
                params: { idProcess: idRouter },
              }"
              >Processo</router-link
            >
          </li>          
          <li class="breadcrumb-item-active">
            <span>Dados do Interveniente</span>
          </li>
        </ol>
      </nav>
      <nav class="navBreadcrumb" v-else>
        <ol class="breadcrumb">
          <slot />
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos',
                   query: { archived: 'false'} }"
              >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'intervenientes'
              }"
              >Lista de Intervenientes</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Dados do Interveniente</span>
          </li>
        </ol>
      </nav>

      <div v-if="loadingProcess">
        <Loading />
      </div>
      <div v-else>
        <!-- <pre>
        {{ processOpposite }}

        </pre> -->
        <article v-if="processOpposite">
          <ul class="borderTable">
            <li class="items itemList" v-if="processOpposite.name">
              <span class="titleBlue">Contária</span>
              <div class="list">
                <span class="textGray">{{ processOpposite.name }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="processOpposite.nif">
              <span class="titleBlue">Nif</span>
              <div class="list">
                <span class="textGray">{{ processOpposite.nif }}</span>
              </div>
            </li>
            <!--##################
            ##    CONTACTO LISTA
            ################## -->
            <li class="items itemList">
              <span class="titleBlue">Contacto</span>
              <ul class="items">
                <div v-if="loadingContact">
                  <p><LoadingCircle /></p>
                </div>
                <div v-else>
                  <div
                    v-if="
                      processOpposite.contacts &&
                      processOpposite.contacts.length > 0
                    "
                  >
                    <!-- {{ client.contacts }} -->
                    <li
                      class="textGray"
                      v-for="contact in processOpposite.contacts"
                      :key="contact.id"
                    >
                      <ContactItem :contact="contact" :idRouter="idOpposite">
                        <router-link
                          v-if="processEdit"
                          slot="edit"
                          class="edit"
                          :to="{
                            name: 'contacto-editar',
                            params: {
                              idContact: contact.id,
                              idRouter: idOpposite,
                              router: 'ProcessOpposite',
                            },
                          }"
                        >
                        </router-link>
                        <button
                          v-if="processEdit"
                          slot="delete"
                          class="delete"
                          @click.prevent="
                            confirmDeleteContactOpposite(contact.id)
                          "
                        ></button>
                      </ContactItem>
                    </li>
                  </div>
                  <div v-else>
                    <p class="textGray">Não possui Contactos</p>
                  </div>
                </div>
              </ul>
            </li>
            <!--##################
            ##    MORADA LISTA
            ################## -->
            <li class="items itemList" id="address">
              <span class="titleBlue">Morada</span>
              <ul class="items">
                <div v-if="loadingAddress">
                  <p><LoadingCircle /></p>
                </div>
                <div v-else>
                  <div
                    v-if="
                      processOpposite.addresses &&
                      processOpposite.addresses.length > 0
                    "
                  >
                    <li
                      class="textGray"
                      v-for="address in processOpposite.addresses"
                      :key="address.id"
                    >
                      <AddressItem :address="address">

                        <div slot="map" class="textGreen" @click.prevent="modalMap(address.location.lat, address.location.lng)">Ver mapa</div>

                        <router-link
                          v-if="processEdit && addressEdit"
                          slot="editAddress"
                          class="edit"
                          :to="{
                            name: 'morada-editar',
                            params: {
                              idAddress: address.id,
                              idRouter: idOpposite,
                              router: 'ProcessOpposite',
                            },
                          }"
                        >
                              <!-- router: 'Client', -->
                        </router-link>
                        <button
                          v-if="processEdit && addressEdit"
                          slot="delete"
                          class="delete"
                          @click.prevent="confirmDeleteAddress(address.id)"
                        ></button>
                      </AddressItem>
                    </li>
                  </div>
                  <div v-else>
                    <p class="textGray">Não possui Moradas</p>
                  </div>
                </div>
                <!-- <transition mode="out-in">
                  <li v-if="createAddress">
                    <AddressForm :errors="errors">
                      <div slot="cancel">
                        <button
                          class="btn btn-cancel"
                          @click.prevent="desableAddress"
                        >
                          Cancelar
                        </button>
                      </div>
                      <div slot="delete">
                        <button
                          class="btn btn-form"
                          @click.prevent="addAddress"
                        >
                          Adicionar
                        </button>
                      </div>
                    </AddressForm>
                  </li>
                </transition> -->

              </ul>
            </li>
            <li class="items itemList" v-if="processOpposite.created">
              <span class="titleBlue">Criado</span>
              <div class="list">
                <span class="textGray">{{
                  processOpposite.created | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ createdclient }}</span> -->
              </div>
            </li>
            <li class="items itemList" v-if="processOpposite.modified">
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <span class="textGray">{{
                  processOpposite.modified | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ modifiedContact }}</span> -->
              </div>
            </li>
            <li class="items containerFile">
              <span class="titleBlue">Ficheiros</span>

              <div v-if="loadingImg">
                <LoadingCircle />
              </div>

              <div v-else>
                <div v-if="processEdit || processManager" class="containerFile">
                  <div class="inputList">
                    <label for="fileInput" class="inputFile"
                      >Selecione um ficheiro
                      <span class="spot">clique aqui</span></label
                    >
                    <span v-if="selectedFile != null" class="itemFile"
                      >{{ selectedFile.name
                      }}<span
                        @click.prevent="cleanFile($event)"
                        class="clearFile"
                        >&#215;</span
                      ></span
                    >
                    <input
                      type="file"
                      @change="onFileSelected"
                      id="fileInput"
                      class="inputNone"
                    />
                  </div>
                  <button
                    class="btn addFile"
                    @click="onUpload(processOpposite.id)"
                  >
                    Adicionar
                  </button>
                </div>
              </div>
            </li>

            <article>
              <div v-if="loadingImg"></div>
              <div v-else>
                <div
                  v-if="
                    processOpposite.files != null &&
                    processOpposite.files.length > 0
                  "
                >
                  <article class="downloadImgLoading" v-if="loadingDownloadImg">
                    Aguarde, fazendo download...
                  </article>
                  <ul v-else>
                    <li
                      v-for="archive in processOpposite.files"
                      :key="archive.id"
                    >
                      <!-- {{ archive }} -->
                      <article class="articleInfos">
                        <!-- <hr /> -->
                        <ArchiveItem
                          :archive="archive"
                          :idMicroservice="processOpposite.id"
                          @getFile="getFile(archive.filename, archive.id)"
                        >
                          <button
                            slot="delete"
                            class="delete"
                            @click.prevent="
                              confirmDeleteFileProcessOpposite(archive.id)
                            "
                          ></button>
                        </ArchiveItem>
                      </article>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <p class="noResults noArchive">
                    Não possui ficheiros registrados
                  </p>
                </div>
              </div>
            </article>
          </ul>
        </article>
        <div class="titleContainer processContainer">
          <h3>Lista de Processos</h3>
          <div v-if="processEdit">
            <!-- <router-link
              class="collabItens"
              :to="{
                name: 'adicionar-processo',
                params: { idOpposite: idOpposite },
              }"
            >
              <AddUser>
                <span slot="title">
                  Adicionar<br />
                  Processo</span
                >
              </AddUser>
            </router-link> -->
          </div>
        </div>
        <section v-if="processList && processList.length > 0">
           <!-- <div v-for="list in processList" :key="list.id"> -->
              <div class="titleLegal">
                <!-- <p class="titleBlue">Tribunal</p> -->
                <p class="titleBlue">Tipo do Processo</p>
                <p class="titleBlue">Posição</p>
                <p class="titleBlue">Nº Processo</p>
                <p class="titleBlue">Valor</p>
                <!-- <p class="titleBlue">Data Início</p> -->
              </div>
              <div>
                <div>
                  <!-- <div v-if="processes && processes.length > 0">
                  <div v-for="process in processes" :key="process.id"> -->
                  <div v-for="list in processList" :key="list.id">
                      <OppositeProcessItem
                        :processTypes="processTypes"
                        :processCourts="processCourts"
                        :processPositions="processPositions"
                        :process="list"
                        :idOpposite="processOpposite.id"
                      >
                        <button
                          v-if="processEdit"
                          slot="delete"
                          class="delete"
                          @click.prevent="confirmDeleteProcess(process.id)"
                        ></button>
                      </OppositeProcessItem>
                    <!-- </div>
                  </div> -->
                </div>
              </div>
           </div>
        </section>
        <section v-else>
          <p class="noResults">Não possui processos registrados</p>
        </section>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelProcessOpposite"
        @click="closeModalDelProcessOpposite"
      ></div>
      <div class="modalDel_container" v-if="showModalDelProcessOpposite">
        <button class="close btn" @click="closeModalDelProcessOpposite">
          x
        </button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este interveniente?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelProcessOpposite"
          />
          <button class="btnDel" @click.prevent="deleteProcessOpposite">
            Delete
          </button>
        </div>
      </div>
    </div>
   
    <!--#########################
    ##  MODAL SELECT ADDRESS
    ######################### -->
    
      <div class="modal">
      <div
        class="overlay"
        v-if="showModalAddress"
        @click="closeModalAddress"
      ></div>
      <div class="modal_container" v-if="showModalAddress">
        <button class="close btn" @click="closeModalAddress">x</button>
        <section class="modalOverflow">
          <article>
            <h3 class="center">Adicionar Morada</h3>
            <div class="btnAddItem" v-if="createAddress == false">
              <div class="btnAdd" @click="createAddress = !createAddress">
                <label class="circle">
                  <svg
                    class="icon-add"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                </label>
                <label class="titleAdd">
                  Criar<br />
                  Morada
                </label>
              </div>
            </div>
            <div v-if="createAddress == false">
              <AddressSearch />
              <div class="containerOtherContact">
                <ul class="otherContactItens">
                  <li
                    v-for="address in otherAddresses"
                    :key="address.id"
                    class="otherContactItem"
                  >                 
                    <div
                      v-if="address.address != ''"
                      @click.prevent="associateAddress(address.id)"
                    >
                      {{ address.street }}
                      <span v-if="address.streetNumber">, {{ address.streetNumber }}</span> 
                      <span v-if="address.city"> - {{address.city}}</span>
                      <span v-if="address.state"> - {{address.state}}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="createAddress == true">
              <p class="containerAddress">
                <AddressForm :errors="errors">
                      <div slot="cancel">
                        <button
                          class="btn btn-cancel"
                          @click.prevent="createAddress = false"
                        >
                          Cancelar
                        </button>
                      </div>
                      <div slot="delete">
                        <button
                          class="btn btn-form"
                          @click.prevent="addAddress"
                        >
                          Adicionar
                        </button>
                      </div>
                    </AddressForm>
              </p>
            </div>
          </article>
        </section>
      </div>
    </div>          
    <!--#########################
    ##  MODAL DELETE ADDRESS
    ######################### -->
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelAddress"
        @click="closeModalDelAddress"
      ></div>
      <div class="modalDel_container" v-if="showModalDelAddress">
        <button class="close btn" @click="closeModalDelAddress">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover esta Morada?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelAddress"
          />
          <button class="btnDel" @click="deleteAddress">Delete</button>
        </div>
      </div>
    </div>

    <!--#########################
    ##  MODAL SELET CONTACT
    ######################### -->
    <div class="modal">
      <div
        class="overlay"
        v-if="showModalContact"
        @click="closeModalContact"
      ></div>
      <div class="modal_container" v-if="showModalContact">
        <button class="close btn" @click="closeModalContact">x</button>
        <section class="modalOverflow">
          <article>
            <h3 class="center">Adicionar Contacto</h3>
            <div class="btnAddItem" v-if="createContact == false">
              <div class="btnAdd" @click="createContact = !createContact">
                <label class="circle">
                  <svg
                    class="icon-add"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                </label>
                <label class="titleAdd">
                  Criar<br />
                  Contacto
                </label>
              </div>
            </div>
            <div v-if="createContact == false">
                <ContactSearch :modal= "true" />
                <div v-if="loadingContact">
                    <LoadingCircle />
                </div>
                <div v-else>
                    <div class="containerOtherContact">
                        <div class="titleSearch">
                            <div class="titleSelectModal">Nome</div><div class="titleSelectModal">Telefone</div>
                        </div>
                        <hr />
                        <ul class="otherContactItens">
                        <li
                            v-for="contact in otherContacts"
                            :key="contact.id"
                            class="otherContactItem"
                        >
                        <div class="titleSearch" @click.prevent="associateContact(contact.id)">
                            <div>
                                <p v-if="contact.name">{{ contact.name }}</p>
                                <p v-else>Sem nome</p></div><div>{{contact.phones[0]}}
                        </div>
                        </div>
                        </li>
                        </ul>
                    </div>
                    <PaginationUrl
                        :data="filteredContacts"
                        :totalPages="maxPage"
                        :total="filteredContacts.length"
                        :currentPage="currentPage"
                        @pagechanged="onPageChange"
                    >
                    </PaginationUrl>
                </div>
            </div>
            <div v-if="createContact == true">
              <p>
                <ContactForm :errors="errors">
                  <div slot="cancel">
                  <button
                    class="btn btn-cancel"
                    @click.prevent="createContact = false"
                  >
                    Cancelar
                  </button>
                </div>
                  <div slot="enviar">
                  <button class="btn btn-form" @click.prevent="addContact">
                    Adicionar
                  </button>
                  </div>
                </ContactForm>
              </p>
            </div>
          </article>
          <!-- <article class="btnContact">
            <div v-if="createContact">
              <p @click.prevent="desableContact" id="desable">
                <img src="@/assets/svg/selectUp.svg" />
                <span class="textGreen">Fechar</span>
              </p>
            </div>
            <div v-else>
              <p @click.prevent="enableContact" id="enable">
                <span class="textGreen">Criar Contacto</span>
                <img src="@/assets/svg/selectDown.svg" />
              </p>
            </div>
          </article> -->
          <!-- <article v-if="">
            <h3 class="center textAddContact">Criar Contacto</h3>
            <p>
              <ContactForm :errors="errors">
                <button class="btn btn-form" @click.prevent="addContact">
                  Adicionar
                </button>
              </ContactForm>
            </p>
          </article> -->
        </section>

        <!-- <div class="containerDel"></div> -->
      </div>
    </div>

    <!--#########################
    ##  MODAL DELETE CONTACT
    ######################### -->
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelContact"
        @click="closeModalDelContactOpposite"
      ></div>
      <div class="modalDel_container" v-if="showModalDelContact">
        <button class="close btn" @click="closeModalDelContactOpposite">
          x
        </button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este contacto?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelContactOpposite"
          />
          <button class="btnDel" @click="deleteContactOpposite">Delete</button>
        </div>
      </div>
    </div>
    <!--#########################
    ##  MODAL DELETE FILE
    ######################### -->
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteFile"
        @click="closeModalDelFileProcessOpposite"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteFile">
        <button class="close btn" @click="closeModalDelFileProcessOpposite">
          x
        </button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este ficheiro?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelFileProcessOpposite"
          />
          <button class="btnDel" @click.prevent="deleteFileProcessOpposite">
            Delete
          </button>
        </div>
      </div>
    </div>
    <!--#########################
    ##  MODAL DELETE PROCESS
    ######################### -->
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelProcess"
        @click="closeModalDelProcess"
      ></div>
      <div class="modalDel_container" v-if="showModalDelProcess">
        <button class="close btn" @click="closeModalDelProcess">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este processo?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelProcess"
          />
          <button class="btnDel" @click.prevent="deleteProcess">Delete</button>
        </div>
      </div>
    </div>
    <!--#########################
    ##  MODAL MAP
    ######################### -->
    <div class="modal">
      <div
        class="overlay"
        v-if="showMap"
        @click="modalMap"
      ></div>
      <div class="modal_containerFixed" v-if="showMap">
        <button class="close btn" @click="modalMap">x</button>
        <div class="delItem">
          <h3>Localização</h3>
          
        </div>
        <iframe
              width="100%"
              height="300px"
              frameborder="0"
              scrolling="no"
              marginheight="0"
              marginwidth="0"
              :src="iframe"
            >
        </iframe>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import ArchiveItem from "@/components/legal/ArchiveItem.vue";
import AddressItem from "@/components/address/AddressItem.vue";
import AddressForm from "@/components/address/AddressForm.vue";
import ContactItem from "@/components/user/ContactItem.vue";
import ContactForm from "@/components/contact/ContactForm.vue";
import ContactSearch from "@/components/contact/ContactSearch.vue";
import AddressSearch from "@/components/address/AddressSearch.vue";
import OppositeProcessItem from "@/components/legal/OppositeProcessItem.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "OppositeInfos",
  props: ["idOpposite", "idRouter", "router"],
  data() {
    return {
      searchHistory: false,
      createContact:false,
      createAddress:false,
    };
  },
  components: {
    AddressItem,
    ContactItem,
    ContactForm,
    ContactSearch,
    AddressForm,
    ArchiveItem,
    OppositeProcessItem,
    AddressSearch,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "wait",
      "selectedId",
      "loadingEmail",
      "loadingPhone",
      "loadingDownloadImg",
      "loadingCalls",
      "limit",
      "currentPage",
      "loadingImg",
      "showMap", 
      "iframe",
    ]),
    ...mapState("legal", [
      "processOpposite",
      "processes",
      "processTypes",
      "processCourts",
      "processPositions",
      "processTaxes",
      "loadingProcess",
      "showModalDelProcess",
      "showModalDelProcessOpposite",
      "showModalDelContact",
      "showModalContact",
      "showModalDeleteFile",
      "selectedFile",
      "archiveID",
      "fileID",
    ]),
    // ...mapState("collaborator", ["createAddress"]),
    ...mapState("address", ["showModalDelAddress", "showModalAddress", "loadingAddress"]),
    ...mapState("contact", ["loadingContact", "contacts", "maxPage", "page",
      "name",
      "phone",
      "mail",
      "description",]),
    ...mapState("user", ["user", "permissions"]),
    ...mapGetters("legal", [
      "processOppositeReset",
      "contactIDReply",
      "contactIDReset",
      "otherContacts",
      "otherAddresses",
    ]),
    ...mapGetters("contact", ["contactReply", "contactReset", "getFiltered",]),
    ...mapGetters("address", ["addressReply", "addressReset"]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalysis",
      "processManager",
      "addressEdit",
    ]),
     processList() {    
      let newProcess = [];
      for (let process in this.processes) {
        if (
          this.processes[process].oppositeList != null ||
          this.processes[process].length > 0
        ) {
          for(let opposite in this.processes[process].oppositeList){
            // console.log("Processo",this.processes[process].oppositeList[opposite].name)
            if(this.processes[process].oppositeList[opposite].name == this.processOpposite.name &&  this.processes[process].archived == false){
              newProcess.push(this.processes[process])
            }
          }
        }
      }
      return newProcess;
    },
    filteredContacts() {
      const a = this.getFiltered || this.contacts;
      return a;
    },
  },
  methods: {
     //######################################################
    //     PAGINAÇÃO CONTACTS
    //######################################################
    onPageChange(page) {
        const filter = {
            page: page,
            name: this.$route.query.name || this.name.name,
            phone:this.$route.query.phone || this.phone.phone,
            mail:this.$route.query.mail || this.mail.mail,
            description:
            this.$route.query.description || this.description.description,
        };
        const query = Object.entries(filter).reduce((acc, [key, val]) => {
          if (!val) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$router.replace({ query: { ...query } }).catch(() => {});    
      this.getContacts()
    },
    //######################################################
    //     DELETE OPPOSITE
    //######################################################
    openModalDelProcessOpposite() {
      this.$store.dispatch("legal/openModalDelProcessOpposite");
    },
    closeModalDelProcessOpposite() {
      this.$store.dispatch("legal/closeModalDelProcessOpposite");
    },
    confirmDeleteProcessOpposite(id) {
      // console.log(id);
      this.$store.dispatch("legal/confirmDeleteProcessOpposite", {
        id: `${id}`,
      });
    },
    deleteProcessOpposite() {
      // console.log("selectedId", this.selectedId.id);
      this.$store.dispatch("legal/deleteProcessOpposite", {
        id: this.selectedId.id,
        redirect: true,
      });
      this.closeModalDelProcessOpposite();
    },
    //######################################################
    //     DELETE POCESS
    //######################################################
    openModalDelProcess() {
      this.$store.dispatch("legal/openModalDelProcess");
    },
    closeModalDelProcess() {
      this.$store.dispatch("legal/closeModalDelProcess");
    },
    confirmDeleteProcess(id) {
      // console.log(id);
      this.$store.dispatch("legal/confirmDeleteProcess", {
        id: `${id}`,
      });
    },
    deleteProcess() {
      // console.log("selectedId", this.selectedId.id);
      this.$store.dispatch("legal/deleteProcess", {
        id: this.selectedId.id,
        idOpposite: this.idOpposite,
        redirect: true,
      });
      this.closeModalDelProcess();
    },
    //######################################################
    //     FILE ARQUIVO PADRÂO
    //######################################################
    getFile(filename, archiveID) {
      this.$store.dispatch("attachment/getFile", {
        archiveID: `${archiveID}`,
        fileName: `${filename}`,
      });
    },
    cleanFile() {
      this.$store.commit("legal/UPDATE_SELECTEDFILE", null);
    },
    onFileSelected(event) {
      this.$store.commit("legal/UPDATE_SELECTEDFILE", event.target.files[0]);
    },

    onUpload(id) {
      const formData = new FormData();
      formData.append("file", this.selectedFile, this.selectedFile.name);
      // console.log("formData", formData);
      this.$store.dispatch("legal/onUploadProcessOpposite", {
        idOpposite: `${id}`,
        data: formData,
        namefile: this.selectedFile.name,
      });
    },    
    
    //######################################################
    //    ARQUIVOS MODAL DELETE
    //######################################################
    openModalDelFileProcessOpposite() {
      this.$store.dispatch("legal/openModalDelFileProcessOpposite");
    },
    closeModalDelFileProcessOpposite() {
      this.$store.dispatch("legal/closeModalDelFileProcessOpposite");
    },
    //######################################################
    //     ARQUIVOS DELETE
    //######################################################
    confirmDeleteFileProcessOpposite(archiveID) {
      // console.log(archiveID);
      this.$store.dispatch("legal/confirmDeleteFileProcessOpposite", {
        archiveID: `${archiveID}`,
      });
    },
    deleteFileProcessOpposite() {
      this.$store.dispatch("legal/deleteFileProcessOpposite", {
        idOpposite: this.idOpposite,
        archiveID: this.archiveID,
      });
      this.closeModalDelFileProcessOpposite();
    },
    //##################################################
    //                  CONTACT
    //##################################################
    associateContact(id) {
      this.closeModalContact();
      this.$store.dispatch("legal/updateContactContact", {
        idContact: `${id}`,
        idModule: this.idOpposite,
      });
      this.$store.commit("contact/RESET_FILTER", null);
    },
    addContact() {
      this.$store.dispatch("contact/addContact", {
        contact: this.contactReply,
        idModule: this.idOpposite,
        mutation: "'legal/updateContactContact'",
      });
    },
    desableContact() {
      this.$store.commit("collaborator/UPDATE_CREATE_CONTACT", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    enableContact() {
      // this.createContact = false
      this.$store.commit("collaborator/UPDATE_CREATE_CONTACT", true);
    },

    openModalContact() {
      this.createContact = false
      this.desableContact();
      this.$store.dispatch("legal/openModalContact");
      this.$store.commit("contact/UPDATE_CONTACT", this.contactReset);
      this.$store.commit("contact/RESET_PHONE", null);
      this.$store.commit("contact/RESET_EMAIL", null);
      this.$store.commit("contact/RESET_FILTER", null);
    },
    closeModalContact() {
      this.$store.commit("collaborator/UPDATE_CREATE_CONTACT", false);
      this.$store.dispatch("legal/closeModalContact");
      if (Object.entries(this.$router.currentRoute.query).length != 0) {
        this.$router.push({ query: {} });
      }
      this.getContacts()
    },
    openModalDelContactOpposite() {
      this.$store.dispatch("legal/openModalDelContactOpposite");
    },
    closeModalDelContactOpposite() {
      this.$store.dispatch("legal/closeModalDelContactOpposite");
    },
    confirmDeleteContactOpposite(id) {
      this.$store.dispatch("legal/confirmDeleteContactOpposite", {
        id: `${id}`,
      });
    },
    deleteContactOpposite() {
      this.$store.dispatch("legal/deleteContactOpposite", {
        idOpposite: this.idOpposite,
        id: this.$store.state.legal.selectedId.id,
      });
      this.closeModalDelContactOpposite();
    },
    //##################################################
    //                 ADDRESS
    //##################################################
    
    associateAddress(id) {
      this.closeModalAddress();
      this.$store.dispatch("legal/updateAddressOpposite", {
        idAddress: `${id}`,
        idModule: this.idOpposite,
      });
      this.$store.commit("contact/RESET_FILTER", null);
    },
    addAddress() {
      this.$store.dispatch("address/addAddress", {
        address: this.addressReply, //enviar getters com as infos necessárias para o cadastro
        idModule: this.idOpposite,
        mutation: "'legal/updateAddressOpposite'",
      });
      this.desableAddress();
    },
    desableAddress() {
      this.$store.commit("RESET_ERRORS", []);
      this.$store.commit("address/UPDATE_ADDRESS", this.addressReset); //limpa o formulário depois de Adicionar
      this.$store.commit("collaborator/UPDATE_CREATE_ADDRESS", false);
    },
    enableAddress() {
      this.$store.commit("RESET_ERRORS", []);
      this.$store.commit("address/UPDATE_ADDRESS", this.addressReset); //limpa o formulário depois de Adicionar
      this.$store.commit("collaborator/UPDATE_CREATE_ADDRESS", true);
    },
    
    openModalAddress() {
      this.createAddress = false
      this.$store.commit("address/UPDATE_CONFIRMFORM", false);
      this.$store.commit("address/UPDATE_ADDRESS", this.addressReset);
      this.$store.dispatch("address/openModalAddress");
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth",
      // });
    },
    closeModalAddress() {
      this.$store.dispatch("address/closeModalAddress");
    },
    openModalDelAddress() {
      this.$store.dispatch("address/openModalDelAddress");
    },
    closeModalDelAddress() {
      this.$store.dispatch("address/closeModalDelAddress");
    },
    confirmDeleteAddress(id) {
      // console.log(id);
      this.$store.dispatch("address/confirmDeleteAddress", {
        id: `${id}`,
      });
    },
    deleteAddress() {
      // console.log(this.selectedId);
      this.$store.dispatch("legal/deleteAddressOpposite", {
        idOpposite: this.idOpposite,
        id: this.selectedId.id,
      });
      this.closeModalDelAddress();
    },
    //##################################################
    //              IFRAME GOOGLE MAP
    //##################################################
    modalMap(lat, lng){
      let url = `https://maps.google.com/maps?q=${lat},${lng}&z=17&output=embed`;      
      this.$store.commit("UPDATE_IFRAME", url);
      this.$store.commit("UPDATE_MODAL_MAP", !this.showMap);
    },
    getContacts(){
        this.$store.dispatch("contact/getContacts", {            
        page: this.$route.query.page || this.page.page,
        name: this.$route.query.name || this.name.name,
        phone:this.$route.query.phone || this.phone.phone,
        mail:this.$route.query.mail || this.mail.mail,
        description:
        this.$route.query.description || this.description.description,
        });
    }
  },

  created() {
    this.$store.dispatch("legal/getProcessOpposite", {
      idOpposite: this.idOpposite,
    });
    this.desableAddress();
    this.getContacts()
    // this.$store.dispatch("contact/getContacts");
    this.$store.dispatch("address/getAddresses");
    // this.$store.dispatch("legal/getProcessOpposites");
    this.$store.dispatch("legal/getProcesses");
    this.$store.dispatch("legal/getProcessTypes");
    this.$store.dispatch("legal/getProcessCourts");
    this.$store.dispatch("legal/getProcessPositions");
    this.$store.dispatch("legal/getProcessTypeTaxes");
  },
};
</script>

<style scoped>
.containerAddress{
  display: flex;
  justify-content: center;
}
/* btn add item */
.inputList[data-v-1f25a6ce] {
    display: grid;
    justify-content: flex-start;
    justify-items: center;
}
.btn-cancel {
  background: #dc3545;
}
.btnAddItem {
  display: grid;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.btnAdd {
  display: flex;
  align-items: center;
  cursor: pointer;
  /* padding: 10px; */
}
.titleAdd {
  font-size: 12px;
  cursor: pointer;
}

.icon-add {
  fill: #fff;
}
.circle {
  background: #1f2b42;
  width: 35px;
  height: 35px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.icon-add:hover {
  fill: #fff;
}
.circle:hover {
  background: #28a745;
  width: 35px;
  height: 35px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
/* fim btn item */
.titleLegal {
  margin: 40px 0;
  display: grid;
  grid-template-columns: 309px 120px 150px 1fr 40px;
  /* grid-template-columns: 1fr 120px 140px 209px repeat(2, 150px) 40px; */
}
.noResults {
  padding: 10px;
}

.processContainer {
  margin: 60px 0 30px 0;
}
/*INPUT FILE botão e arquivo */
.spot {
  font-size: 14px;
  color: #015eb0;
}
.inputList {
  display: grid;
}
.inputFile {
  cursor: pointer;
  font-size: 14px;
  width: 240px;
  border: 1px solid #015eb0;
  background: #eff7fe;
  border-radius: 9px;
  padding: 10px;
  color: #1f2b42;
  margin: 3px 10px 0 0;
  border-style: dotted;
}

.clearFile {
  font-size: 24px;
  line-height: 28px;
  margin-left: 10px;
  font-weight: bold;
  color: #00732c;
  padding-top: 5px;
  cursor: pointer;
}
.containerFile {
  display: flex;
  align-content: center;
  align-items: baseline;
  /* grid-gap: 10px; */
  margin: 10px 0;
}
.inputNone {
  display: none;
}
.noArchive {
  margin: 20px 0 30px 0;
  margin-left: 10px;
}

/*fim file */
.containerClient {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1120px);
  justify-content: center;
  align-content: flex-start;
}
.modalOverflow {
  overflow-y: auto;
  max-height: 620px;
  padding: 0 20px;
  margin: 30px 0;
  overflow-x: hidden;
  scrollbar-width: thin; /*padrão firefox*/
  scrollbar-color: #00bf48 transparent; /*padrão firefox*/
}
.modalOverflow::-webkit-scrollbar {
  width: 10px;
  background: #ecebeb;
  border-radius: 7px;
}

.modalOverflow::-webkit-scrollbar-thumb {
  background: #00bf48;
  opacity: 0.5;
  border-radius: 7px;
}

/* .modal .modal_container{
  max-width: 650px;
} */
.containerOtherContact {
  box-shadow: 0 4px 8px rgb(30 60 90 / 20%);
  padding: 20px;
  border-radius: 9px;
  height: 225px;
  margin-bottom: 15px;
}
.otherContactItens {
  overflow-y: auto;
  height: 150px;
  line-height: 30px;
  margin-bottom: 20px;
  scrollbar-width: thin; /*padrão firefox*/
  scrollbar-color: #c5c5c5 transparent; /*padrão firefox*/
}
.otherContactItem:hover {
  background: #dbebf3;
  cursor: pointer;
}
.otherContactItens::-webkit-scrollbar {
  width: 10px;
  background: #ecebeb;
  border-radius: 7px;
}

.otherContactItens::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  opacity: 0.5;
  border-radius: 7px;
}
.btnContact {
  display: grid;
  justify-content: center;
}
.btnContact p {
  display: grid;
  justify-items: center;
  text-align: center;
  cursor: pointer;
}
.btnContact p#desable {
  margin-top: 40px;
}

.textAddContact {
  margin-top: 30px;
}
.center {
  text-align: center;
  margin-bottom: 30px;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  /* padding: 30px 0 0 0; */
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 180px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

.btn-cancel {
  background: #dc3545;
}
.titleSearch{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}
@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
  .inputFile {
    margin: 3px 10px 0 10px;
  }
}
@media screen and (max-width: 390px) {
  .containerFile {
    display: grid;
    grid-gap: 10px;
  }
}
</style>
