<template>
  <div>
    <h1>Jurídico</h1>
    <div class="contentGeneral">
      <div class="titleContainer">
        <h3>Editar Interveniente</h3>
      </div>

      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos', query: { archived: 'false'} }"  >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'interveniente',
                params: { idOpposite: idOpposite },
              }"
              >Dados do Interveniente</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Editar Interveniente</span>
          </li>
        </ol>
      </nav>
      <div class="itemFormCollaborator">
        <OppositeForm :processOpposite="processOpposite" :errors="errors">
          <div slot="cancel"></div>
          <div slot="enviar" class="btnJustify">
            <button class="btn" @click.prevent="updateProcessOpposite">
              Gravar
            </button>
          </div>
        </OppositeForm>
      </div>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </div>
</template>

<script>
import OppositeForm from "@/components/legal/OppositeForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditOpposite",
  props: ["idOpposite", "idRouter"],
  components: {
    OppositeForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("legal", ["processOpposite"]),
    ...mapGetters("legal", ["processOppositeReply", "processOppositeReset"]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalysis",
      "processManager",
    ]),
  },
  methods: {
    updateProcessOpposite() {
      this.$store.dispatch("legal/updateProcessOpposite", {
        idOpposite: this.idOpposite,
        processOpposite: this.processOppositeReply,
      });
    },
  },
  created() {
    this.$store.commit(
      "legal/UPDATE_PROCESS_OPPOSITE",
      this.processOppositeReset
    );
    this.$store.dispatch("legal/getProcessOpposite", {
      idOpposite: this.idOpposite,
    });
    this.$store.commit("RESET_ERRORS", []);
  },
};
</script>

<style scoped>
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.btnJustify {
  display: grid;
  justify-content: flex-end;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
.itemFormCollaborator {
  margin-left: 10px;
}
</style>