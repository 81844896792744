<template>
  <section class="contentGeneral" v-if="collaborator">
    <h3>Adicionar Colaborador</h3>
    <nav class="navBreadcrumb">
      <ol class="breadcrumb">
        <li class="breadcrumb-item">
          <router-link :to="{ name: 'colaborador-ativo' }"
            >Lista Ativos</router-link
          >
        </li>
        <li class="breadcrumb-item-active">
          <span>Adicionar Colaborador</span>
        </li>
      </ol>
    </nav>
    <transition mode="out-in">
      <UserForm :collaborator="collaborator" :errors="errors">
        <button class="btn btn-form" @click.prevent="addCollaborator">
          Adicionar
        </button>
      </UserForm>
    </transition>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import UserForm from "@/components/collaborator/UserForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "CollaboratorsCreate",
  props: ["idCollaborator"],
  beforeRouteEnter(to, from, next) {
    if (to.name == "criar-colaborador") {
      next((vm) => {
        console.log(to.name == "criar-colaborador");
        // o "vm" substituirá o "this"
        console.log(vm.collaborator);
        console.log(vm.collaboratorReset);
        vm.$store.commit(
          "collaborator/UPDATE_COLLABORATOR",
          vm.collaboratorReset
        );
        vm.$store.commit(
          "collaborator/UPDATE_CHECKPASSWORD",
          vm.checkPasswordReset
        );
      });
    }
    next();
  },
  data() {
    return {
      create: false,
    };
  },
  components: {
    UserForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("collaborator", ["collaborator"]),
    ...mapGetters("collaborator", [
      "collaboratorReset",
      "collaboratorReply",
      "checkPasswordReset",
    ]),
  },
  methods: {
    addCollaborator() {
      this.$store.dispatch("collaborator/addCollaborator", {
        collaborator: this.collaboratorReply, //enviar getters com as infos necessárias para o cadastro
      });
      // this.$store.dispatch("collaborator/getCollaborators");
    },
  },
  created() {
    this.$store.commit("RESET_ERRORS", []);
    this.$store.commit(
      "collaborator/UPDATE_COLLABORATOR",
      this.collaboratorReset
    );
    this.$store.commit(
      "collaborator/UPDATE_CHECKPASSWORD",
      this.checkPasswordReset
    );
    this.$store.commit(
      "collaborator/UPDATE_COLLABORATOR",
      this.collaboratorReset
    );
  },
};
</script>

<style scoped>
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
</style>
