<template>
  <div class="itemsCollab">
    <div v-if="loadingCollaborators">
      <Loading />
    </div>
    <div v-else>
      <div v-if="collabDisabled">
        <h3>Desativados</h3>

        <nav class="navBreadcrumb">
          <ol class="breadcrumb">
            <li class="breadcrumb-item-active">
              <span> Lista Desativados</span>
            </li>
          </ol>
        </nav>
        <div class="titleCollab">
          <p class="titleBlue">Nome</p>
          <p class="titleBlue">E-mail</p>
        </div>
        <hr />
        <ul v-for="collaborator in collabDisabled" :key="collaborator.id">
          <li class="itemList">
            <CollaboratorDisabledItem :collaborator="collaborator" />
          </li>
        </ul>
      </div>
      <div v-else>
        <p class="noResults">Não possui colaboradores cadastrados</p>
      </div>
    </div>
  </div>
</template>

<script>
import CollaboratorDisabledItem from "@/components/collaborator/CollaboratorDisabledItem.vue";
import { mapState } from "vuex";

export default {
  name: "CollaboratorDisabled",
  components: {
    CollaboratorDisabledItem,
  },
  computed: {
    ...mapState("collaborator", [
      "collaborator",
      "collabDisabled",
      "loadingCollaborators",
    ]),
  },
};
</script>

<style scoped>
.titleBlue {
  padding-left: 10px;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 5px 0;
  text-align: left;
  margin-left: 10px;
}
/* .itemsCollab {
  padding: 40px 20px;
} */

.noResults {
  margin: 60px auto;
  max-width: 500px;
  text-align: center;
  padding: 10px;
  transition: all 0.2s;
}
/* 
.titleCollab {
  display: grid;
  grid-template-columns: minmax(300px, 400px) 1fr;
  height: 30px;
  max-width: 1366px;
  overflow-y: auto;
  overflow-x: auto;
  border-radius: 5px;
} */

.breadcrumb-item-active {
  margin-left: 10px;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
}
</style>