<template>
  <section>
    <h1>Viaturas</h1>

    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Editar Marca</h3>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'marcas' }"
              >Lista de Marcas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'marca',
                params: { idBrand: idBrand },
              }"
              >Dados da Marca</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Editar Marca</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingVehicle">
        <Loading />
      </div>
      
      <div v-else class="containerForm">
        <BrandForm :errors="errors">
          <button class="btn" @click.prevent="updateBrand">Gravar</button>
        </BrandForm>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import BrandForm from "@/components/vehicle/BrandForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddModel",
  props: ["idBrand"],
  components: {
    BrandForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("vehicle", [
      "brands",
      "loadingVehicle",
      "showModalDeleteVehicle",
    ]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),
    ...mapGetters("vehicle", ["brandReset", "brandReply"]),
  },
  methods: {    
    updateBrand() {
      this.$store.dispatch("vehicle/updateBrand", {
        brand: this.brandReply,
        idBrand: this.idBrand,
      });
    },
  },
  created() {
    this.$store.dispatch("vehicle/getBrand", {
      idBrand: this.idBrand,
    });
  },
};
</script>

<style scoped>
.breadcrumb {
  margin-left: -10px;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

@media screen and (max-width: 690px) {
  .containerTelecom {
    grid-template-columns: 1fr;
    justify-items: flex-start;
    grid-gap: 10px;
    margin: 30px 10px;
  }
  .containerForm {
    width: 100%;
  }
}
</style>