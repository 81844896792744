<template>
  <form action="">
    <div class="formInfos">
      <!-- {{ contact }} -->
      <div v-if="loadingTelecomPhone">
        <p><Loading /></p>
      </div>
      <div class="formInfos" v-else>
        <label for="name"> Nome: </label>
        <input
          id="name"
          name="name"
          type="text"
          v-model="name"
          autocomplete="off"
          :class="[validateFull, validateAlias]"
        />
        <div v-if="!errors.indexOf('aliasContact')" class="notifyCenter">
          O Nome é um campo requerido.
        </div>
        <div v-if="!errors.indexOf('full')" class="notifyCenter">
          O Nome é um campo requerido.
        </div>
        <div class="formInfos" v-if="contact">
          <label for="description">
            Contacto: <span>{{ contact.fromPhone }}</span></label
          >
        </div>
        <!-- {{ contact }} -->
        {{ contactID }}
        <div class="button">
          <slot></slot>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "TelecomPhoneForm",
  props: ["contact", "errors", "contactID"],
  computed: {
    validateFull() {
      return this.errors.indexOf("full") ? "true" : "validate";
    },
    validateAlias() {
      return this.errors.indexOf("aliasContact") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["name", "description", "phones", "emails"], //nome dos campos
      module: "contact", //qual módulo está acessando
      base: "contact", //colocar o state que vai receber
      mutation: "contact/UPDATE_CONTACTNAME", //nome da mutation que vai alterar o state
    }),
    ...mapState(["loadingTelecomPhone"]),
  },
};
</script>

<style scoped>
form {
  margin-top: 30px;
}
.formInfos {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-top: 20px; */
  align-content: center;
  max-width: 500px;
}
input.validate {
  margin: 0;
}

.button {
  display: flex;
  justify-content: flex-end;
  /* margin: 20px 0; */
}
@media screen and (max-width: 690px) {
  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: auto;
    width: 100%;
  }
  label,
  input {
    width: 100%;
    justify-items: flex-start;
  }
}
</style>