<template>
  <section>
    <h1>Central de Eventos</h1>

    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Adicionar Evento</h3>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'mensagens' }"
              >Lista de Eventos</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Adicionar Evento</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingMessage">
        <Loading />
      </div>
      <div v-else>
        <MessageForm :errors="errors">
          <button class="btn" @click.prevent="addMessage">Adicionar</button>
        </MessageForm>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import MessageForm from "@/components/message/MessageForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddMessage",
  props: ["idMessage"],
  components: {
    MessageForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("message", ["message", "telegramUsers", "loadingMessage"]),
    ...mapGetters("message", [
      "userActive",
      "userInactive",
      "messageReset",
      "messageReply",
    ]),
  },
  methods: {
    addMessage() {
      // console.log(this.variantReply);
      this.$store.dispatch("message/addMessage", {
        message: this.messageReply,
      });
    },
  },
  created() {
    this.$store.dispatch("message/getTelegramUsers");
    // this.$store.commit("message/RESET_MESSAGE_TELEGRAMWATCHERS");
    this.$store.commit("message/UPDATE_MESSAGE", this.messageReset);
    this.$store.commit("RESET_ERRORS", []);
  },
};
</script>

<style scoped>
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

@media screen and (max-width: 690px) {
  .containerTelecom {
    grid-template-columns: 1fr;
    justify-items: flex-start;
    grid-gap: 10px;
    margin-right: 10px;
  }
}
</style>