<template>
  <section class="itemContainer">
    <router-link
      class="collabItens"
      :to="{
        name: 'colaborador-info-desativado',
        params: { idCollaborator: collaborator.id },
      }"
    >
      <span class="titleBlue">Nome</span>
      <p>{{ collaborator.fullName }}</p>
      <span class="titleBlue">E-mail</span>
      <p>{{ collaborator.username }}</p>
    </router-link>
    <slot></slot>
  </section>
</template>  

<script>
export default {
  name: "CollaboratorDisabledItem",
  props: ["collaborator"],
};
</script>

<style scoped>
.itemContainer {
  display: grid;
  grid-template-columns: 1fr;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

.collabItens {
  display: grid;
  /* grid-template-columns: repeat(10, 130px); */
  /* grid-template-columns: 200px 150px 70px 90px repeat(2, 100px); */
  grid-template-columns: minmax(300px, 400px) 1fr;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

.collabItens:hover {
  background: #dbebf3;
}

.collabItens p {
  padding: 0 10px;
}
.titleBlue {
  display: none;
}
@media screen and (max-width: 690px) {
  .titleBlue {
    display: block;
    padding: 5px 0 0 10px;
  }
  .collabItens {
    grid-template-columns: 1fr;
  }
  .itemContainer {
    width: 100%;
  }
}
</style>
