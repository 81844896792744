<template>
  <section class="containerLinkForm">   
    <article>
      <!-- {{address}} -->
      <div class="info"> {{ address.street }} <span v-if="address.streetNumber">, {{ address.streetNumber }}</span></div>        
        
      <div class="itemAddress" v-if="address.postalCode">
        Código Postal: {{ address.postalCode }}
      </div>     
       <div class="itemAddress" v-if="address.city">
        Localidade: {{ address.city }}
      </div>
      <div class="itemAddress" v-if="address.state">
        Distrito: {{ address.state }}
      </div>  
      <div class="itemAddress" v-if="address.state">
        País: {{ address.country }}
      </div>   
      <div class="itemAddress" v-if="address.complement">
        Complemento: {{ address.complement }}
      </div>
      <div class="itemAddress" v-if="address.reference">
        Referência: {{ address.reference }}
      </div>  
      <div class="itemAddress" v-if="address.alias">
        Notas: {{ address.alias }}
      </div>    
      <div class="linkIframe" v-if="address.location">
        <slot name="map" />
      </div>
    </article>
    
    <slot name="editAddress" />
    <slot name="delete" />
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddressItem",
  props: ["address"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["staffEdit"]),
  },
};
</script>

<style scoped>
.itemAddress {
  margin-left: 1em;
}
.linkIframe{
  padding-left: 20px;
}
</style>