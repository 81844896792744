<template>
  <div>
    <div v-if="loadingCollaborators">
      <Loading />
    </div>
    <div v-else>
      <div v-if="collaborator">
        <CollaboratorInfos
          :collaborator="collaborator"
          :idCollaborator="idCollaborator"
        >
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'colaborador-ativo' }"
              >Lista Ativos</router-link
            >
          </li>
        </CollaboratorInfos>
      </div>
    </div>
  </div>
</template>

<script>
import CollaboratorInfos from "@/components/collaborator/CollaboratorInfos.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Collaborator",
  props: ["idCollaborator"],
  computed: {
    ...mapState("collaborator", ["collaborator", "loadingCollaborators"]),
    ...mapState("user", ["user", "permissions"]),
    ...mapGetters("collaborator", ["collaboratorReset"]),
  },
  components: {
    CollaboratorInfos,
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (from.name == "contacto-editar") {
        vm.$store.dispatch("collaborator/getCollaborator", {
          idCollaborator: vm.idCollaborator,
          refreshId: "refreshId",
        });
        vm.$store.dispatch("collaborator/getCollaborators", {
          refresh: "refresh",
        });
      }
    });
  },
  created() {
    this.$store.commit(
      "collaborator/UPDATE_COLLABORATOR",
      this.collaboratorReset
    );
    this.$store.dispatch("collaborator/getCollaborator", {
      idCollaborator: this.idCollaborator,
    });
  },
};
</script>

<style>
</style>