<template>
  <section class="containerLinkForm">
    <article>
      <!-- {{ contact }} -->
      <div class="info col-3" v-if="contact.name">
        <div class="bold">Nome:</div>
        <div class="textGray name">{{ contact.name }}</div>
      </div>
      <div class="itemContact col-2" v-if="contact.description">
        <div class="bold">Descrição:</div>
        <div>{{ contact.description }}</div>
      </div>
      <div v-if="contact.emails && contact.emails.length > 0">
        <div class="itemContact col-2 itemCol">
          <div class="col-1 bold">Email:</div>
          <div class="col-1">
            <div v-for="email in contact.emails" :key="email">
              <div class="textGray">{{ email }}</div>
              <!-- <p class="textGray">Não possui e-mails</p> -->
            </div>
          </div>
        </div>
      </div>
      <div v-if="contact.phones && contact.phones.length > 0">
        <div class="itemContact col-2 itemCol">
          <div class="col-1 bold">Telefone:</div>
          <div class="col-1">
            <div v-for="phone in contact.phones" :key="phone">
              <div class="textGray">{{ phone }}</div>
              <!-- <p class="textGray">Não possui telefones</p> -->
            </div>
          </div>
        </div>
      </div>
    </article>

    <!-- <router-link
      v-if="staffEdit"
      class="edit"
      :to="{
        name: 'contacto-editar',
        params: {
          idContact: contact.id,
          idCollaborator: idRouter,
        },
      }"
    >
    </router-link> -->
    <div><slot name="edit" /></div>
    <div><slot name="delete" /></div>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddressItem",
  props: ["contact", "idRouter"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["staffEdit"]),
  },
};
</script>

<style scoped>
/* .bold {
  font-weight: bold;
} */
.col-1 {
  display: grid;
}

.col-3 {
  display: grid;
  grid-template-columns: 1em minmax(50px, 100px) 1fr;
  align-items: flex-start;
}
.col-2 {
  display: grid;
  grid-template-columns: minmax(50px, 100px) 1fr;
  align-items: flex-start;
  /* margin-top: 5px; */
}
.itemContact {
  display: grid;
  justify-content: flex-start;
  margin-left: 1em;
}
/* .itemCol {
  margin-top: 10px 0;
  padding: 9px 0;
  border-top: 1px solid #cbcbcb;
  max-width: 400px;
  width: 100%;
} */
@media screen and (max-width: 690px) {
  .col-3 {
    grid-template-columns: 1em minmax(50px, 100px);
  }
  .name {
    margin-left: 1em;
    width: 300px;
  }
  .col-2 {
    grid-template-columns: 1fr;
  }
}
</style>
