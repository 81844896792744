<template>
  <form action="">
    <div class="formInfos">
      <div v-if="loadingCallNote">
        <p><Loading /></p>
      </div>
      <div class="formInfos" v-else>
        <p class="noteCenter">
          <span class="titleBlue">Data: </span> {{ createdAtTelecom }}
        </p>
        <p
          class="noteCenter"
          v-if="callNote.contactName != '' && callNote.contactName != null"
        >
          <span class="titleBlue">Nome: </span> {{ callNote.contactName }}
        </p>
        <p
          class="descriptionNote"
          v-if="callNote.description != '' && callNote.description != null"
        >
          <span class="titleBlue">Descrição: </span> {{ callNote.description }}
        </p>
        <p>
          <span class="titleBlue">De: </span> {{ callNote.fromPhone }} |
          <span class="titleBlue">Para: </span>
          {{ callNote.toPhone }}
        </p>
        <!-- {{ callNote.id }} -->
        <textarea
          id="note"
          name="note"
          v-model="note"
          placeholder="Escreva aqui..."
        ></textarea>
        <div>
          <slot />
        </div>
        <!-- <slot></slot> -->
      </div>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "CallbackConfigForm",
  props: ["callNote", "errors"],
  computed: {
    ...mapFields({
      fields: ["note"], //nome dos campos
      module: "telecom", //qual módulo está acessando
      base: "callNote", //colocar o state que vai receber
      mutation: "telecom/UPDATE_CALLNOTE", //nome da mutation que vai alterar o state
    }),
    // ...mapState("telecom", ["showModalNote"]),
    ...mapState(["loadingCallNote"]),
    ...mapGetters("telecom", ["callNoteReply"]),
    createdAtTelecom() {
      const [date, time] = this.callNote.created.split(" " && "T");
      // Dividindo a data em dia, mês e ano:
      const [DD, MM, YYYY] = date.split("-");
      // Dividindo o tempo em hora e minutos:
      const [HH, mm] = time.split(":");
      // Retornando a data formatada em um padrão compatível com ISO:
      return `${YYYY}-${MM}-${DD}  ${HH}:${mm}`;
    },
  },
  methods: {
    // updateCallNote(id) {
    //   // console.log(this.callNoteReply);
    //   this.$store.dispatch("telecom/updateCallNote", {
    //     idCall: `${id}`,
    //     callback: this.callNoteReply,
    //   });
    // },
  },
};
</script>

<style scoped>
.descriptionNote {
  max-width: 340px;
  text-align: center;
  margin-top: 10px;
}
.titleBlue {
  font-size: 16px;
}
.noteCenter {
  text-align: center;
  margin-top: 10px;
}
.formInfos {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  justify-items: center;
  grid-gap: 5px;
  /* align-items: flex-start; */
  /* margin-top: 20px; */
  /* align-content: center; */
  max-width: 500px;
}
.formItems {
  display: grid;
  grid-template-columns: 1fr;
}
input.validate {
  margin: 0;
}

textarea {
  min-width: 350px;
  max-width: 420px;
  min-height: 250px;
}
.button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
</style>