import { render, staticRenderFns } from "./AddUser.vue?vue&type=template&id=330b1920&scoped=true&"
import script from "./AddUser.vue?vue&type=script&lang=js&"
export * from "./AddUser.vue?vue&type=script&lang=js&"
import style0 from "./AddUser.vue?vue&type=style&index=0&id=330b1920&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "330b1920",
  null
  
)

export default component.exports