<template>
  <section class="">
    <router-link
      class="collabItens itemList"
      :to="{
        name: 'morada',
        params: { idAddress: address.id },
      }"
    >
    <!-- <pre>
             {{ address }}
          </pre> -->
      <article class="containerLinkForm">
        <div>{{ address.street }} <span v-if="address.streetNumber">, {{ address.streetNumber }}</span></div>        
        <!-- <div>{{ address.city }}</div>
        <div>{{ address.state }}</div>
        <div>{{ address.country }}</div>
        <div>{{ address.postalCode }}</div>
        <div>{{ address.reference }}</div>
        <div>{{ address.alias }}</div>
        <div>{{ address.location }}</div> -->

        <div >
          <span class="itemAddress">Código Postal:</span>{{ address.postalCode }}
        </div>
        <!-- <div class="itemAddress">Localidade: {{ address.place }}</div> -->
        <div>
          <span class="itemAddress">Localidade:</span>{{ address.city }}
        </div>
        <div>
          <span class="itemAddress">Distrito:</span>{{ address.state }}
        </div>
      
      </article>
      <div class="delete">
        <slot name="delete" />
      </div>
    </router-link>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddressItem",
  props: ["address"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["staffEdit"]),
  },
};
</script>

<style scoped>
.itemList {
  align-items: center;
}
.containerLinkForm {
  display: grid;
  grid-template-columns: 310px  180px repeat(2, 200px);
  padding: 5px 10px;
}
.delete {
  margin-right: 10px;
}
.itemAddress {
  display: none;
}
@media screen and (max-width: 690px) {
  .containerLinkForm {
    grid-template-columns: 1fr;
    padding: 5px 10px;
  }
  .itemAddress {
    display: block;
  }
}
</style>