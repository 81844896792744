<template>
  <section class="containerLinkForm">
    <div class="info">
      {{ permission }}
    </div>
    <div><slot name="edit" /></div>
    <div><slot name="delete" /></div>
  </section>
</template>

<script>
export default {
  name: "PermissionItem",
  props: ["permission"],
};
</script>

<style scoped>
</style>