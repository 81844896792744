<template>
  <section class="itemsCollab">
    <h1>Jurídico</h1>
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Lista de Posições</h3>
        <div v-if="processEdit" class="menuDropdown">
          <router-link
            class="collabItens"
            :to="{
              name: 'adicionar-posicao',
            }"
          >
            <AddUser>
              <span slot="title">
                Adicionar<br />
                Posição</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <div v-if="loadingProcess">
        <Loading />
      </div>
      <div v-else>
        <div v-if="processPositions && processPositions.length > 0">
          <nav class="navBreadcrumb">
            <ol class="breadcrumb">
              <slot />
              <li class="breadcrumb-item">
                <router-link :to="{ name: 'processos', query: { archived: 'false'} }"  >Lista de Processos</router-link
            >
              </li>
              <li class="breadcrumb-item-active">
                <span> Lista de Posições</span>
              </li>
            </ol>
          </nav>
          <ProcessPositionSearch />
          <div class="titleService">
            <p class="titleBlue">Nome</p>
          </div>
          <hr />
          <ul
            v-for="processPosition in paginatedProcessPosition"
            :key="processPosition.id"
          >
            <!-- <ul v-for="address in filteredAddresses" :key="address.id"> -->
            <li>
              <ProcessPositionItem :processPosition="processPosition">
                <button
                  v-if="processEdit"
                  slot="delete"
                  class="delete"
                  @click.prevent="
                    confirmDeleteProcessPosition(processPosition.id)
                  "
                ></button>
              </ProcessPositionItem>
            </li>
          </ul>
          <Pagination
            :data="filteredProcessPosition"
            :totalPages="Math.ceil(filteredProcessPosition.length / limit)"
            :total="filteredProcessPosition.length"
            :currentPage="currentPage"
            @pagechanged="onPageChange"
          >
          </Pagination>
        </div>
        <div v-else>
          <p class="noResults">Não possui posições cadastradas</p>
        </div>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelProcessPosition"
        @click="closeModalDelProcessPosition"
      ></div>
      <div class="modalDel_container" v-if="showModalDelProcessPosition">
        <button class="close btn" @click="closeModalDelProcessPosition">
          x
        </button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover esta posição?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelProcessPosition"
          />
          <button class="btnDel" @click.prevent="deleteProcessPosition">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import ProcessPositionItem from "@/components/legal/ProcessPositionItem.vue";
import ProcessPositionSearch from "@/components/legal/ProcessPositionSearch.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProcessPositions",
  components: {
    ProcessPositionItem,
    ProcessPositionSearch,
  },
  computed: {
    ...mapState([
      "limit",
      "currentPage",
      "errors",
      "items",
      "itemsError",
      "selectedId",
    ]),
    ...mapState("legal", [
      "processPositions",
      "loadingProcess",
      "searchWord",
      "filtered",
      "showModalDelProcessPosition",
    ]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalysis",
      "processManager",
    ]),
    ...mapGetters("legal", ["paginatedProcessPosition", "getFiltered"]),
    filteredProcessPosition() {
      const a = this.getFiltered || this.processPositions;
      return a;
    },
  },
  methods: {
    onPageChange(page) {
      this.$store.commit("UPDATE_CURRENTPAGE", page);
    },
    //######################################################
    //     DELETE
    //######################################################
    openModalDelProcessPosition() {
      this.$store.dispatch("legal/openModalDelProcessPosition");
    },
    closeModalDelProcessPosition() {
      this.$store.dispatch("legal/closeModalDelProcessPosition");
    },
    confirmDeleteProcessPosition(id) {
      // console.log(id);
      this.$store.dispatch("legal/confirmDeleteProcessPosition", {
        id: `${id}`,
      });
    },

    deleteProcessPosition() {
      // console.log("selectedId", this.selectedId.id);
      this.$store.dispatch("legal/deleteProcessPosition", {
        id: this.selectedId.id,
      });
      this.closeModalDelProcessPosition();
    },
  },
  created() {
    this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
    this.$store.commit("legal/RESET_FILTER", null);
    this.$store.dispatch("legal/getProcessPositions");
  },
};
</script>

<style scoped>
.containerContact {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 850px);
  justify-content: center;
  align-content: flex-start;
}
.titleBlue {
  padding-left: 10px;
}
hr {
  max-width: 1366px;
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 0 0 5px 0;
  text-align: left;
  margin-left: 10px;
}
/* .itemsCollab {
  padding: 40px 20px;
} */

.noResults {
  margin: 60px 0;
  max-width: 500px;
  /* text-align: center; */
  padding: 10px;
  transition: all 0.2s;
}

.breadcrumb-item-active {
  margin-left: 10px;
}

.titleService {
  display: grid;
  /* grid-template-columns: minmax(300px, 400px) 200px 1fr; */
  grid-template-columns: 1fr;
  /* grid-template-columns: 310px 240px; */
  height: 30px;
  max-width: 1366px;
  /* overflow-y: auto;
  overflow-x: auto; */
  border-radius: 5px;
}
</style>