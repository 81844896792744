<template>
  <form action="">
    <div class="formInfos">
      <div v-if="loadingMessage">
        <p><Loading /></p>
      </div>
      <div class="formInfos" v-else>
        <label for="description"> Description: </label>
        <input
          id="description"
          name="description"
          type="text"
          v-model="description"
          autocomplete="off"
          :class="[validateFull]"
        />

        <div class="button">
          <slot></slot>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "TelegramUserForm",
  props: ["telegramUser", "errors"],
  computed: {
    validateFull() {
      return this.errors.indexOf("full") ? "true" : "validate";
    },

    ...mapFields({
      fields: ["description"], //nome dos campos
      module: "message", //qual módulo está acessando
      base: "telegramUser", //colocar o state que vai receber
      mutation: "message/UPDATE_TELEGRAM_USER", //nome da mutation que vai alterar o state
    }),
    ...mapState(["loadingMessage"]),
  },
  // created() {
  //   this.$store.dispatch("message/getTelegramUser", {
  //     idTelegramUser: this.idTelegramUser,
  //   });
  // },
};
</script>

<style scoped>
.formInfos {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-top: 20px; */
  align-content: center;
  max-width: 500px;
}
input.validate {
  margin: 0;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
@media screen and (max-width: 690px) {
  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: auto;
    width: 100%;
  }
  label,
  input {
    width: 100%;
    justify-items: flex-start;
  }
}
</style>