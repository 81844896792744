<template>
  <div class="modalDel">
    <div class="overlay" v-if="showModalDel" @click="closeModalDel"></div>
    <div class="modalDel_container" v-if="showModalDel">
      <button class="close btn" @click="closeModalDel">x</button>
      <p class="delItem">
        <slot name="textDel" />
      </p>
      <div class="containerDel"></div>
      <div class="buttonsDel">
        <input
          type="submit"
          class="btn"
          value="Cancel"
          @click.prevent="closeModalDel"
        />
        <slot name="buttonDel" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  computed: {
    ...mapState(["showModalDel", "confirmDel"]),
  },
  methods: {
    openModalDel() {
      this.$store.dispatch("openModalDel");
    },
    closeModalDel() {
      this.$store.dispatch("closeModalDel");
      // this.$store.commit("UPDATE_CONFIRM_DEL", false);
    },

    // confirmDelete() {
    //   this.$store.commit("UPDATE_CONFIRM_DEL", true);
    //   console.log("passou aqui", this.$store.state.confirmDel);
    // },
  },
};
</script>

<style scoped>
</style>