<template>
  <section class="">
    <h1>Clientes</h1>
    <div class="containerClient">
      <div class="titleContainer">
        <h3>Dados do Cliente</h3>
        <div class="menuDropdown" v-if="clientEdit">
          <BtnConfig>
            <span slot="title">
              Controlo <br />
              de Cliente</span
            >
            <ul slot="itemsMenu" class="menuDropdownContent">
              <li>
                <router-link
                  class="collabItens"
                  :to="{
                    name: 'cliente-editar',
                    params: { idClient: idClient },
                  }"
                >
                  Editar Cliente
                </router-link>
              </li>
              <div class="titleMenuDropdown topsubmenu">Adicionar</div>
              <li @click="openModalContact">Contacto</li>
              <li v-if="addressEdit && clientEdit" @click="openModalAddress">
                Morada
              </li>
              <li @click.prevent="confirmDeleteClient(client.id)">
                Apagar Cliente
              </li>
            </ul>
          </BtnConfig>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <slot />
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'clientes' }"
              >Lista de clientes</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Dados do Cliente</span>
          </li>
        </ol>
      </nav>

      <div v-if="loadingClient">
        <Loading />
      </div>
      <div v-else>
        <!-- {{ client }} -->
        <article v-if="client">
          <ul>
            <li class="items itemList" v-if="client.name">
              <span class="titleBlue">Cliente</span>
              <div class="list">
                <span class="textGray">{{ client.name }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="client.nif">
              <span class="titleBlue">Nif</span>
              <div class="list">
                <span class="textGray">{{ client.nif }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="client.clientType">
              <span class="titleBlue">Tipo de Cliente</span>
              <div class="list">
                <span class="textGray">{{ client.clientType }}</span>
              </div>
            </li>

            <!--##################
            ##    CONTACTO LISTA
            ################## -->
            <li class="items itemList">
              <span class="titleBlue">Contacto</span>
              <ul class="items">
                <div v-if="loadingContact">
                  <p><LoadingCircle /></p>
                </div>
                <div v-else>
                  <div v-if="client.contacts && client.contacts.length > 0">
                    <!-- {{ client.contacts }} -->
                    <li
                      class="textGray"
                      v-for="contact in client.contacts"
                      :key="contact.id"
                    >
                      <ContactItem :contact="contact" :idRouter="idClient">
                        <router-link
                          v-if="clientEdit"
                          slot="edit"
                          class="edit"
                          :to="{
                            name: 'contacto-editar',
                            params: {
                              idContact: contact.id,
                              idRouter: idClient,
                              router: 'Client',
                            },
                          }"
                        >
                        </router-link>
                        <button
                          v-if="clientEdit"
                          slot="delete"
                          class="delete"
                          @click.prevent="
                            confirmDeleteContactClient(contact.id)
                          "
                        ></button>
                      </ContactItem>
                    </li>
                  </div>
                  <div v-else>
                    <p class="textGray">Não possui Contactos</p>
                  </div>
                </div>
              </ul>
            </li>
            <!--##################
            ##    MORADA LISTA
            ################## -->
            <li class="items itemList" id="address">
              <span class="titleBlue">Morada</span>
              <ul class="items">
                <div v-if="loadingAddress">
                  <p><LoadingCircle /></p>
                </div>
                <div v-else>
                  <div v-if="client.addresses && client.addresses.length > 0">
                    <!-- {{ client.addresses }} -->
                    <li
                      class="textGray"
                      v-for="address in client.addresses"
                      :key="address.id"
                    >
                      <AddressItem :address="address">
                        <div
                          slot="map"
                          class="textGreen"
                          @click.prevent="
                            modalMap(address.location.lat, address.location.lng)
                          "
                        >
                          Ver mapa
                        </div>
                        <router-link
                          v-if="clientEdit && addressEdit"
                          slot="editAddress"
                          class="edit"
                          :to="{
                            name: 'morada-editar',
                            params: {
                              idAddress: address.id,
                              idRouter: idClient,
                              router: 'Client',
                            },
                          }"
                        >
                        </router-link>
                        <button
                          v-if="clientEdit && addressEdit"
                          slot="delete"
                          class="delete"
                          @click.prevent="confirmDeleteAddress(address.id)"
                        ></button>
                      </AddressItem>
                    </li>
                  </div>
                  <div v-else>
                    <p class="textGray">Não possui Moradas</p>
                  </div>
                </div>
                <!-- <transition mode="out-in">
                  <li v-if="createAddress">
                    <AddressForm :errors="errors">
                      <div slot="cancel">
                        <button
                          class="btn btn-cancel"
                          @click.prevent="desableAddress"
                        >
                          Cancelar
                        </button>
                      </div>
                      <div slot="delete">
                        <button
                          class="btn btn-form"
                          @click.prevent="addAddress"
                        >
                          Adicionar
                        </button>
                      </div>
                    </AddressForm>
                  </li>
                </transition> -->
              </ul>
            </li>
            <li class="items itemList" v-if="client.created">
              <span class="titleBlue">Criado</span>
              <div class="list">
                <span class="textGray">{{
                  client.created | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ createdclient }}</span> -->
              </div>
            </li>
            <li class="items itemList" v-if="client.modified">
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <span class="textGray">{{
                  client.modified | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ modifiedContact }}</span> -->
              </div>
            </li>
          </ul>
        </article>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalClient"
        @click="closeModalDelClient"
      ></div>
      <div class="modalDel_container" v-if="showModalClient">
        <button class="close btn" @click="closeModalDelClient">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este cliente?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelClient"
          />
          <button class="btnDel" @click.prevent="deleteClient">Delete</button>
        </div>
      </div>
    </div>
    <!--#########################
    ##  MODAL DELETE ADDRESS
    ######################### -->
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelAddress"
        @click="closeModalDelAddress"
      ></div>
      <div class="modalDel_container" v-if="showModalDelAddress">
        <button class="close btn" @click="closeModalDelAddress">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover esta Morada?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelAddress"
          />
          <button class="btnDel" @click="deleteAddress">Delete</button>
        </div>
      </div>
    </div>

    <!--#########################
    ##  MODAL SELECT ADDRESS
    ######################### -->

    <div class="modal">
      <div
        class="overlay"
        v-if="showModalAddress"
        @click="closeModalAddress"
      ></div>
      <div class="modal_container" v-if="showModalAddress">
        <button class="close btn" @click="closeModalAddress">x</button>
        <section class="modalOverflow">
          <article>
            <h3 class="center">Adicionar Morada</h3>
            <div class="btnAddItem" v-if="createAddress == false">
              <div class="btnAdd" @click="createAddress = !createAddress">
                <label class="circle">
                  <svg
                    class="icon-add"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                </label>
                <label class="titleAdd">
                  Criar<br />
                  Morada
                </label>
              </div>
            </div>
            <div v-if="createAddress == false">
              <AddressSearch />
              <div class="containerOtherContact">
                <ul class="otherContactItens">
                  <li
                    v-for="address in otherAddresses"
                    :key="address.id"
                    class="otherContactItem"
                  >
                    <div
                      v-if="address.address != ''"
                      @click.prevent="associateAddress(address.id)"
                    >
                      {{ address.street }}
                      <span v-if="address.streetNumber"
                        >, {{ address.streetNumber }}</span
                      >
                      <span v-if="address.city"> - {{ address.city }}</span>
                      <span v-if="address.state"> - {{ address.state }}</span>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div v-if="createAddress == true">
              <p class="containerAddress">
                <AddressForm :errors="errors">
                  <div slot="cancel">
                    <button
                      class="btn btn-cancel"
                      @click.prevent="createAddress = false"
                    >
                      Cancelar
                    </button>
                  </div>
                  <div slot="delete">
                    <button class="btn btn-form" @click.prevent="addAddress">
                      Adicionar
                    </button>
                  </div>
                </AddressForm>
              </p>
            </div>
          </article>
        </section>
      </div>
    </div>
    <!--#########################
    ##  MODAL SELET CONTACT
    ######################### -->
    <div class="modal">
      <div
        class="overlay"
        v-if="showModalContact"
        @click="closeModalContact"
      ></div>
      <div class="modal_container" v-if="showModalContact">
        <button class="close btn" @click="closeModalContact">x</button>
        <section class="modalOverflow">
          <article>
            <h3 class="center">Adicionar Contacto</h3>
            <div class="btnAddItem" v-if="createContact == false">
              <div class="btnAdd" @click="createContact = !createContact">
                <label class="circle">
                  <svg
                    class="icon-add"
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                  >
                    <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                  </svg>
                </label>
                <label class="titleAdd">
                  Criar<br />
                  Contacto
                </label>
              </div>
            </div>
            <!-- {{otherContacts}} -->
            <div v-if="createContact == false">
                <ContactSearch :modal= "true" />
                <div v-if="loadingContact">
                    <LoadingCircle />
                </div>
                <div v-else>
                    <div class="containerOtherContact">
                        <div class="titleSearch">
                            <div class="titleSelectModal">Nome</div><div class="titleSelectModal">Telefone</div>
                        </div>
                        <hr />
                        <ul class="otherContactItens">
                        <li
                            v-for="contact in otherContacts"
                            :key="contact.id"
                            class="otherContactItem"
                        >
                        <div class="titleSearch" @click.prevent="associateContact(contact.id)">
                            <div>
                                <p v-if="contact.name">{{ contact.name }}</p>
                                <p v-else>Sem nome</p></div><div>{{contact.phones[0]}}
                        </div>
                        </div>
                        </li>
                        </ul>
                    </div>
                    <PaginationUrl
                        :data="filteredContacts"
                        :totalPages="maxPage"
                        :total="filteredContacts.length"
                        :currentPage="currentPage"
                        @pagechanged="onPageChange"
                    >
                    </PaginationUrl>
            </div>
            </div>
            <div v-if="createContact == true">
              <p>
                <ContactForm :errors="errors">
                  <div slot="cancel">
                    <button
                      class="btn btn-cancel"
                      @click.prevent="createContact = false"
                    >
                      Cancelar
                    </button>
                  </div>
                  <div slot="enviar">
                    <button class="btn btn-form" @click.prevent="addContact">
                      Adicionar
                    </button>
                  </div>
                </ContactForm>
              </p>
            </div>
          </article>
        </section>
      </div>
    </div>
    <!--#########################
    ##  MODAL DELETE CONTACT
    ######################### -->
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelContact"
        @click="closeModalDelContactClient"
      ></div>
      <div class="modalDel_container" v-if="showModalDelContact">
        <button class="close btn" @click="closeModalDelContactClient">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este contacto?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelContactClient"
          />
          <button class="btnDel" @click="deleteContactClient">Delete</button>
        </div>
      </div>
    </div>
    <!--#########################
    ##  MODAL MAP
    ######################### -->
    <div class="modal">
      <div class="overlay" v-if="showMap" @click="modalMap"></div>
      <div class="modal_containerFixed" v-if="showMap">
        <button class="close btn" @click="modalMap">x</button>
        <div class="delItem">
          <h3>Localização</h3>
        </div>
        <iframe
          width="100%"
          height="300px"
          frameborder="0"
          scrolling="no"
          marginheight="0"
          marginwidth="0"
          :src="iframe"
        >
        </iframe>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import AddressItem from "@/components/address/AddressItem.vue";
import AddressForm from "@/components/address/AddressForm.vue";
import ContactItem from "@/components/user/ContactItem.vue";
import ContactForm from "@/components/contact/ContactForm.vue";
import ContactSearch from "@/components/contact/ContactSearch.vue";
import AddressSearch from "@/components/address/AddressSearch.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ClientInfos",
  props: ["idClient"],
  data() {
    return {
      searchHistory: false,
      createContact: false,
      createAddress: false,
    };
  },
  components: {
    AddressItem,
    ContactItem,
    ContactForm,
    ContactSearch,
    AddressForm,
    AddressSearch,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "wait",
      "selectedId",
      "loadingEmail",
      "loadingPhone",
      "loadingCalls",
      "limit",
      "currentPage",
      "showMap",
      "iframe",
    ]),
    ...mapState("client", [
      "client",
      "typeClients",
      "loadingClient",
      "showModalClient",
      "showModalDelContact",
      "showModalContact",
    ]),
    // ...mapState("collaborator", ["createAddress"]),
    ...mapState("address", [
      "showModalDelAddress",
      "showModalAddress",
      "loadingAddress",
    ]),
    ...mapState("contact", ["loadingContact", "contacts", "maxPage", "page",
      "name",
      "phone",
      "mail",
      "description",]),
    ...mapState("user", ["user", "permissions"]),
    ...mapGetters("client", [
      "clientReset",
      "contactIDReply",
      "contactIDReset",
      "otherContacts",
      "otherAddresses",
    ]),
    ...mapGetters("contact", ["contactReply", "contactReset", "getFiltered",]),
    ...mapGetters("address", [
      "addressReply",
      "addressReset",
      "locationAddressReset",
      "partAddressReply",
    ]),
    ...mapGetters("user", ["clientAccess", "clientEdit", "addressEdit"]),
    //##################################################
    //                 ClientType
    //##################################################
    clientTypeName() {
      if (this.typeClients != null) {
        return this.typeClients.find((typeClient) => {
          return typeClient.id == this.client.clientTypeID;
        });
      } else {
        return "";
      }
    },
    filteredContacts() {
      const a = this.getFiltered || this.contacts;
      return a;
    },
  },
  methods: {
     //######################################################
    //     PAGINAÇÃO CONTACTS
    //######################################################
    onPageChange(page) {
        const filter = {
            page: page,
            name: this.$route.query.name || this.name.name,
            phone:this.$route.query.phone || this.phone.phone,
            mail:this.$route.query.mail || this.mail.mail,
            description:
            this.$route.query.description || this.description.description,
        };
        const query = Object.entries(filter).reduce((acc, [key, val]) => {
          if (!val) return acc;
          return { ...acc, [key]: val };
        }, {});
        this.$router.replace({ query: { ...query } }).catch(() => {});    
      this.getContacts()
    },
    //######################################################
    //     DELETE CLIENTE
    //######################################################
    openModalClient() {
      this.$store.dispatch("client/openModalClient");
    },
    closeModalDelClient() {
      this.$store.dispatch("client/closeModalDelClient");
    },
    confirmDeleteClient(id) {
      // console.log(id);
      this.$store.dispatch("client/confirmDeleteClient", {
        id: `${id}`,
      });
    },
    deleteClient() {
      // console.log("selectedId", this.selectedId.id);
      this.$store.dispatch("client/deleteClient", {
        id: this.selectedId.id,
        redirect: true,
      });
      this.closeModalDelClient();
    },
    //##################################################
    //                  CONTACT
    //##################################################
    associateContact(id) {
      this.closeModalContact();
      this.$store.dispatch("client/updateContactClient", {
        idContact: `${id}`,
        idModule: this.idClient,
      });
      this.$store.commit("contact/RESET_FILTER", null);
    },
    addContact() {
      this.$store.dispatch("contact/addContact", {
        contact: this.contactReply,
        idModule: this.idClient,
        mutation: "'client/updateContactClient'",
      });
    },
    desableContact() {
      this.$store.commit("collaborator/UPDATE_CREATE_CONTACT", false);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
    enableContact() {
      this.$store.commit("collaborator/UPDATE_CREATE_CONTACT", true);
    },

    openModalContact() {
      this.createContact = false;
      this.desableContact();
      this.$store.dispatch("client/openModalContact");
      this.$store.commit("contact/UPDATE_CONTACT", this.contactReset);
      this.$store.commit("contact/RESET_PHONE", null);
      this.$store.commit("contact/RESET_EMAIL", null);
      this.$store.commit("contact/RESET_FILTER", null);

    },
    closeModalContact() {
      this.$store.commit("collaborator/UPDATE_CREATE_CONTACT", false);
      this.$store.dispatch("client/closeModalContact");
      if (Object.entries(this.$router.currentRoute.query).length != 0) {
        this.$router.push({ query: {} });
      }
    this.getContacts()
    },
    openModalDelContactClient() {
      this.$store.dispatch("client/openModalDelContactClient");
    },
    closeModalDelContactClient() {
      this.$store.dispatch("client/closeModalDelContactClient");
    },
    confirmDeleteContactClient(id) {
      this.$store.dispatch("client/confirmDeleteContactClient", {
        id: `${id}`,
      });
    },
    deleteContactClient() {
      this.$store.dispatch("client/deleteContactClient", {
        idClient: this.idClient,
        id: this.$store.state.client.selectedId.id,
      });
      this.closeModalDelContactClient();
    },
    //##################################################
    //                 ADDRESS
    //##################################################
    associateAddress(id) {
      this.closeModalAddress();
      this.$store.dispatch("client/updateAddressClient", {
        idAddress: `${id}`,
        idModule: this.idClient,
      });
      this.$store.commit("contact/RESET_FILTER", null);
    },
    addAddress() {
      this.$store.dispatch("address/addAddress", {
        address: this.addressReply, //enviar getters com as infos necessárias para o cadastro
        idModule: this.idClient,
        mutation: "'client/updateAddressClient'",
      });
      this.desableAddress();
    },
    desableAddress() {
      this.$store.commit("RESET_ERRORS", []);
      this.$store.commit("address/UPDATE_ADDRESS", this.addressReset); //limpa o formulário depois de Adicionar
      this.$store.commit("collaborator/UPDATE_CREATE_ADDRESS", false);
    },
    enableAddress() {
      this.$store.commit("RESET_ERRORS", []);
      this.$store.commit("address/UPDATE_ADDRESS", this.addressReset); //limpa o formulário depois de Adicionar
      this.$store.commit("collaborator/UPDATE_CREATE_ADDRESS", true);
    },
    openModalAddress() {
      this.createAddress = false;
      this.$store.commit("address/UPDATE_CONFIRMFORM", false);
      this.$store.commit("address/UPDATE_ADDRESS", this.addressReset);
      this.$store.dispatch("address/openModalAddress");
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth",
      // });
    },
    closeModalAddress() {
      this.$store.dispatch("address/closeModalAddress");
    },
    openModalDelAddress() {
      this.$store.dispatch("address/openModalDelAddress");
    },
    closeModalDelAddress() {
      this.$store.dispatch("address/closeModalDelAddress");
    },
    confirmDeleteAddress(id) {
      // console.log(id);
      this.$store.dispatch("address/confirmDeleteAddress", {
        id: `${id}`,
      });
    },
    deleteAddress() {
      // console.log(this.selectedId);
      this.$store.dispatch("client/deleteAddressClient", {
        idClient: this.idClient,
        id: this.selectedId.id,
      });
      this.closeModalDelAddress();
    },
    //##################################################
    //              IFRAME GOOGLE MAP
    //##################################################
    modalMap(lat, lng) {
      // console.log(lat,lng)
      if (lat != null && lng != null) {
        let url = `https://maps.google.com/maps?q=${lat},${lng}&z=17&output=embed`;
        this.$store.commit("UPDATE_IFRAME", url);
      }
      this.$store.commit("UPDATE_MODAL_MAP", !this.showMap);
    },
    getContacts(){
        this.$store.dispatch("contact/getContacts", {            
        page: this.$route.query.page || this.page.page,
        name: this.$route.query.name || this.name.name,
        phone:this.$route.query.phone || this.phone.phone,
        mail:this.$route.query.mail || this.mail.mail,
        description:
        this.$route.query.description || this.description.description,
        });
    }
  },

  created() {
    this.desableAddress();
    this.getContacts()
    this.$store.dispatch("address/getAddresses");
    // this.$store.commit("contact/RESET_PHONE", null);
    this.$store.dispatch("client/getClient", {
      idClient: this.idClient,
    });
    this.$store.dispatch("client/getTypeClients");
    // this.getSearch2();
  },
};
</script>

<style scoped>
.containerAddress {
  display: flex;
  justify-content: center;
}
/* btn add item */
.inputList[data-v-1f25a6ce] {
  display: grid;
  justify-content: flex-start;
  justify-items: center;
}
.btn-cancel {
  background: #dc3545;
}
.btnAddItem {
  display: grid;
  justify-content: flex-end;
  margin-bottom: 10px;
}
.btnAdd {
  display: flex;
  align-items: center;
  cursor: pointer;
  /* padding: 10px; */
}
.titleAdd {
  font-size: 12px;
  cursor: pointer;
}

.icon-add {
  fill: #fff;
}
.circle {
  background: #1f2b42;
  width: 35px;
  height: 35px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}

.icon-add:hover {
  fill: #fff;
}
.circle:hover {
  background: #28a745;
  width: 35px;
  height: 35px;
  border-radius: 9px;
  padding: 5px;
  cursor: pointer;
  margin-right: 5px;
}
/* fim btn item */
.containerClient {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 850px);
  justify-content: center;
  align-content: flex-start;
}
.modalOverflow {
  overflow-y: auto;
  max-height: 620px;
  padding: 0 20px;
  margin: 30px 0;

  scrollbar-width: thin; /*padrão firefox*/
  scrollbar-color: #00bf48 transparent; /*padrão firefox*/
}
.modalOverflow::-webkit-scrollbar {
  width: 10px;
  background: #ecebeb;
  border-radius: 7px;
}

.modalOverflow::-webkit-scrollbar-thumb {
  background: #00bf48;
  opacity: 0.5;
  border-radius: 7px;
}
.containerOtherContact {
  box-shadow: 0 4px 8px rgb(30 60 90 / 20%);
  padding: 20px;
  border-radius: 9px;
  height: 225px;
  margin-bottom: 15px;
}
.otherContactItens {
  overflow-y: auto;
  height: 150px;
  line-height: 30px;
  margin-bottom: 20px;
  scrollbar-width: thin; /*padrão firefox*/
  scrollbar-color: #c5c5c5 transparent; /*padrão firefox*/
}
.otherContactItem:hover {
  background: #dbebf3;
  cursor: pointer;
}
.otherContactItens::-webkit-scrollbar {
  width: 10px;
  background: #ecebeb;
  border-radius: 7px;
}

.otherContactItens::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  opacity: 0.5;
  border-radius: 7px;
}
.btnContact {
  display: grid;
  justify-content: center;
}
.btnContact p {
  display: grid;
  justify-items: center;
  text-align: center;
  cursor: pointer;
}
.btnContact p#desable {
  margin-top: 40px;
}

.btnContact p#enable {
  margin-top: 40px;
}
.textAddContact {
  margin-top: 30px;
}
.center {
  text-align: center;
  margin-bottom: 30px;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  /* padding: 30px 0 0 0; */
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 180px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

.btn-cancel {
  background: #dc3545;
}

.titleSearch{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
}
</style>
