<template>
  <section class="containerForbidden">
    <h1>500</h1>
    <!-- <div class="lockContainer">
      <div class="lock"></div>
    </div> -->

    <svg
      id="robot_bounce"
      style="
        width: 80;
        height: 80;
        vertical-align: middle;
        fill: currentColor;
        overflow: hidden;
      "
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M829.45 126.77h-641a64.19 64.19 0 0 0-64 64v63.49a64.19 64.19 0 0 0 64 64h641a64.19 64.19 0 0 0 64-64v-63.49a64.19 64.19 0 0 0-64-64zM477.71 257H221.54a32 32 0 0 1 0-64h256.17a32 32 0 1 1 0 64z m191.91 0a32 32 0 1 1 32-32 32.09 32.09 0 0 1-32 32z m128.68 0a32 32 0 1 1 32-32 32.09 32.09 0 0 1-32 32zM831 416.72H190a64.19 64.19 0 0 0-64 64v63.49a64.19 64.19 0 0 0 64 64h641a64.19 64.19 0 0 0 64-64v-63.49a64.19 64.19 0 0 0-64-64zM479.24 547H223.07a32 32 0 1 1 0-64h256.17a32 32 0 0 1 0 64z m191.91 0a32 32 0 1 1 32-32 32.09 32.09 0 0 1-32 32z m128.68 0a32 32 0 1 1 32-32 32.09 32.09 0 0 1-32 32zM831 703.77H190a64.19 64.19 0 0 0-64 64v63.49a64.19 64.19 0 0 0 64 64h641a64.19 64.19 0 0 0 64-64v-63.49a64.19 64.19 0 0 0-64-64zM479.24 834H223.07a32 32 0 1 1 0-64h256.17a32 32 0 0 1 0 64z m191.91 0a32 32 0 1 1 32-32 32.09 32.09 0 0 1-32 32z m128.68 0a32 32 0 1 1 32-32 32.09 32.09 0 0 1-32 32z"
        fill="#00bf48"
      />
    </svg>

    <h2>Erro Interno</h2>
    <p>Contacte o Administrador.</p>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.containerForbidden {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  justify-items: center;
  text-align: center;
  grid-gap: 20px;
}

#robot_bounce {
  /* transform: scale(0.6) translate(0, 10); */
  transform: translateY(0px);
  animation: robot_bounce 1.5s;
}

@keyframes robot_bounce {
  0% {
    transform: translateY(10px);
  }

  50% {
    transform: translateY(-10px);
  }
  /* 
  75% {
    transform: translateY(5px);
  } */

  100% {
    transform: translateY(0px);
  }
}
</style>
