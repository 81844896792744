<template>
  <section v-if="contact">
    <h1>Contactos</h1>
    <div class="contentGeneral">
      <h3>Adicionar Contacto</h3>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'contactos' }"
              >Lista de Contactos</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Adicionar Contacto</span>
          </li>
        </ol>
      </nav>
      <transition mode="out-in">
        <ContactForm :contact="contact" :errors="errors">
          <button slot="enviar" class="btn btn-form" @click.prevent="addContact">
            Adicionar
          </button>
        </ContactForm>
      </transition>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </section>
</template>

<script>
import ContactForm from "@/components/contact/ContactForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ContactAdd",
  props: ["idContact"],
  // beforeRouteEnter(to, from, next) {
  //   console.log(to.name);
  //   if (to.name == "criar-contacto") {
  //     next((vm) => {
  //       console.log(to.name == "criar-contacto");
  //       vm.$store.com
  //     });
  //   }
  //   next();
  // },
  data() {
    return {
      create: false,
    };
  },
  components: {
    ContactForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("contact", ["contact"]),
    ...mapGetters("contact", ["contactReset", "contactReply"]),
  },
  methods: {
    addContact() {
      this.$store.dispatch("contact/addContact", {
        contact: this.contactReply, //enviar getters com as infos necessárias para o cadastro
      });
      // this.$store.dispatch("collaborator/getCollaborators");
    },
  },
  created() {
    this.$store.commit("contact/UPDATE_CONTACT", this.contactReset);
    this.$store.commit("contact/RESET_PHONE", null);
    this.$store.commit("contact/RESET_EMAIL", null);
  },
};
</script>

<style scoped>
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
</style>
