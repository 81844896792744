<template>
  <form action="">
    <div class="">
      <div v-if="loadingVehicle">
        <p><Loading /></p>
      </div>
      <div class="formInfos" v-else>
        <label for="date"> Data da Revisão: </label>
        <input
          id="date"
          type="date"
          v-model="date"
          autocomplete="off"
          :class="[validateDate, dateInvalid]"
          @change="resetError"
        />
        <div v-if="!errors.indexOf('date')" class="notifyCenter">
          A data da revisão é um campo requerido.
        </div>
        <div v-if="!errors.indexOf('dateInvalid')" class="notifyCenter">
          A data de revisão não pode ser maior do que hoje.
        </div>
        <label for="km"> Km: </label>
        <input
          id="km"
          name="km"
          type="number"
          v-model.number="km"
          autocomplete="off"
          :class="[validateKm]"
          @change="resetError"
        />
        <div v-if="!errors.indexOf('km')" class="notifyCenter">
          O km é um campo requerido
        </div>
        <label for="costs"> Custo: </label>
        <input
          id="costs"
          name="costs"
          type="number"
          v-model.number="costs"
          autocomplete="off"
          :class="[validateCosts]"
          @change="resetError"
        />
        <div v-if="!errors.indexOf('costs')" class="notifyCenter">
          O custo é um campo requerido
        </div>
        <label for="description"> Notas: </label>
        <textarea
          id="description"
          name="description"
          type="text"
          v-model="description"
          autocomplete="off"
          :class="[validateDescription]"
          placeholder="Escreva aqui..."
          @change="resetError"
        />
        <div v-if="!errors.indexOf('description')" class="notifyCenter">
          A nota é um campo requerido
        </div>

        <div class="button">
          <slot></slot>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "MessageForm",
  props: ["errors", "revision"],
  computed: {
    // validateFull() {
    //   return this.errors.indexOf("full") ? "true" : "validate";
    // },
    dateInvalid() {
      return this.errors.indexOf("dateInvalid") ? "true" : "validate";
    },

    validateDate() {
      return this.errors.indexOf("date") ? "true" : "validate";
    },
    validateDescription() {
      return this.errors.indexOf("description") ? "true" : "validate";
    },
    validateKm() {
      return this.errors.indexOf("km") ? "true" : "validate";
    },
    validateCosts() {
      return this.errors.indexOf("costs") ? "true" : "validate";
    },

    ...mapFields({
      fields: ["km", "description", "costs", "date"], //nome dos campos
      module: "vehicle", //qual módulo está acessando
      base: "revision", //colocar o state que vai receber
      mutation: "vehicle/UPDATE_REVISION", //nome da mutation que vai alterar o state
    }),
    ...mapState(["loadingVehicle"]),
  },
  methods: {
    resetError() {
      this.$store.commit("RESET_ERRORS", []);
    },
  },
};
</script>

<style scoped>
.formInfos {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-top: 20px; */
  align-content: center;
  max-width: 350px;
}
input.validate {
  margin: 0;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
textarea {
  min-height: 250px;
  width: 100%;
}
@media screen and (max-width: 690px) {
  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: auto;
    width: 100%;
  }
  label,
  input {
    width: 100%;
    justify-items: flex-start;
  }
}
</style>