<template>
  <section>
    <h1>Jurídico</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Lista de Intervenientes</h3>
        <div class="menuDropdown" v-if="processEdit">
          <router-link
            class="collabItens"
            :to="{
              name: 'adicionar-interveniente',
            }"
          >
            <AddUser>
              <span slot="title">
                Adicionar<br />
                Interveniente</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos', query: { archived: 'false'} }" >
              Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Lista de Intervenientes</span>
          </li>
        </ol>
      </nav>
      <article>
        <div v-if="loadingProcess">
          <Loading />
        </div>
        <div v-else>
          <div v-if="processOpposites && processOpposites.length > 0">

            <div class="topSearch">
              <OppositeSearch />
              <!-- <router-link
                :to="{
                  name: 'processos',
                   query: { archived: 'false'}
                }"
              >
            <div class="textGreen textProcess">
              <svg xmlns="http://www.w3.org/2000/svg" width="22.504" height="21.198" viewBox="0 0 22.504 21.198"><path d="M411.369,299.886a.173.173,0,0,0,.005-.022.5.5,0,0,0-.5-.5h-1.845V284.591a2.3,2.3,0,0,0-.046-.457,2.251,2.251,0,0,0-2.193-1.816H391.165a2.285,2.285,0,0,0-1.662.712,2.214,2.214,0,0,0-.612,1.527v2.613l.007,0c0,.011-.006.021-.006.032a.5.5,0,0,0,.5.5h1.888v13.537a2.3,2.3,0,0,0,.046.458,2.269,2.269,0,0,0,.337.813,2.323,2.323,0,0,0,.274.336,2.213,2.213,0,0,0,1.582.667h12.16V303.5l3.487-.07a2.283,2.283,0,0,0,1.647-.745,2.216,2.216,0,0,0,.582-1.539ZM391.28,285.264V286.7h-1.425v-2.148a1.269,1.269,0,0,1,.88-1.211,1.1,1.1,0,0,1,.218.173,1.312,1.312,0,0,1,.327.871Zm5.644,2.691h6.87a.468.468,0,0,1,0,.935h-6.87a.468.468,0,0,1,0-.935Zm0,4.494h6.87a.468.468,0,0,1,0,.935h-6.87a.468.468,0,0,1,0-.935Zm13.087,9.686a1.316,1.316,0,0,1-.864.344l-3.655.073h-9.029a2.2,2.2,0,0,0,.483-1.408l-.016-.78h13.485l.016.8A1.276,1.276,0,0,1,410.011,302.135Z" transform="translate(-388.891 -282.318)" fill="#28a745"/></svg>
              Processos</div>

</router-link> -->
            </div>
            
            <div class="titleCollab">
              <p class="titleBlue">Interveniente</p>
              <p class="titleBlue">Nif</p>
            </div>
            <hr />

            <ul>
              <!-- class="itemList" -->
              <li
                class="oppositItems"
                v-for="(processOpposite, index) in paginatedData"
                :key="processOpposite.id"
              >
                <!-- <div v-if="process.oppositeID == processOpposite.id"> -->
                <div class="borderTable">
                  <OppositeItem
                    :processOpposite="processOpposite"
                    :processes="processes"
                    :idOpposite="processOpposite.id"
                    :nameOpposite="processOpposite.name"
                    :index="index"
                    :processTypes="processTypes"
                    :processCourts="processCourts"
                    :processPositions="processPositions"
                  >
                    <!-- <div slot="process" >
                    
                    <div v-for="process in processes" :key="process.id">
                      <div
                        class="collabItens liProcess"
                        v-if="
                          process.oppositeID == processOpposite.id
                            ? process
                            : null
                        "
                      >
                        <ProcessItem
                          :processTypes="processTypes"
                          :processCourts="processCourts"
                          :processPositions="processPositions"
                          :processTaxes="processTaxes"
                          :process="process"
                          :idOpposite="processOpposite.id"
                          :index="index"
                        >
                        </ProcessItem>
                      </div>
                    </div>
                  </div> -->
                    <button
                      slot="delete"
                      class="delete"
                      @click.prevent="
                        confirmDeleteProcessOpposite(processOpposite.id)
                      "
                    ></button>
                  </OppositeItem>
                  <!-- </div> -->
                </div>
                <router-link
                  v-if="processEdit"
                  :to="{
                    name: 'interveniente',
                    params: { idOpposite: processOpposite.id },
                  }"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                  >
                    <g transform="translate(-1209.5 -835.749)">
                      <rect
                        width="30"
                        height="30"
                        rx="5"
                        transform="translate(1209.5 835.749)"
                        fill="#1f2b42"
                      />
                      <g transform="translate(810.085 705.194)">
                        <path
                          d="M426.384,157.227H425.9l-.173-.168a4.032,4.032,0,1,0-.434.434l.167.174v.49l3.1,3.09.923-.923Zm-3.716,0a2.788,2.788,0,1,1,2.787-2.788A2.784,2.784,0,0,1,422.668,157.227Z"
                          transform="translate(-2.319 -2.888)"
                          fill="#fff"
                        />
                        <path
                          d="M415.481,150.224a5.08,5.08,0,0,1,3.53-3.537,4.982,4.982,0,0,1,3.016.109h0v-8.524a1.475,1.475,0,0,0-.269-.849,1.586,1.586,0,0,0-1.3-.67H409.243a1.585,1.585,0,0,0-1.3.67,1.479,1.479,0,0,0-.269.849v13.373a1.479,1.479,0,0,0,.269.85,1.585,1.585,0,0,0,1.3.669h6.327A5,5,0,0,1,415.481,150.224Zm-3.523-8.582h5.783a.375.375,0,1,1,0,.75h-5.783a.375.375,0,1,1,0-.75Zm0,2.941h5.783a.375.375,0,1,1,0,.75h-5.783a.375.375,0,1,1,0-.75Zm2.713,3.692h-2.713a.376.376,0,1,1,0-.751h2.713a.376.376,0,1,1,0,.751Z"
                          transform="translate(0 0)"
                          fill="#fff"
                        />
                      </g>
                    </g>
                  </svg>
                </router-link>
              </li>
            </ul>
            <Pagination
              :data="filteredProcessOpposites"
              :totalPages="Math.ceil(filteredProcessOpposites.length / limit)"
              :total="filteredProcessOpposites.length"
              :currentPage="currentPage"
              @pagechanged="onPageChange"
            >
            </Pagination>
          </div>
          <div v-else>
            <p class="noResults">Não possui interveniente registrado</p>
          </div>
        </div>
      </article>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDelProcessOpposite"
        @click="closeModalDelProcessOpposite"
      ></div>
      <div class="modalDel_container" v-if="showModalDelProcessOpposite">
        <button class="close btn" @click="closeModalDelProcessOpposite">
          x
        </button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este interveniente?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelProcessOpposite"
          />
          <button class="btnDel" @click.prevent="deleteProcessOpposite">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import OppositeSearch from "@/components/legal/OppositeSearch.vue";
import OppositeItem from "@/components/legal/OppositeItem.vue";
// import ProcessItem from "@/components/legal/ProcessItem.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddContact",
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "selectedId",
      "btnFilter",
      "limit",
      "currentPage",
    ]),
    ...mapState("user", ["user"]),
    ...mapState("legal", [
      "processes",
      "processOpposites",
      "processTypes",
      "processCourts",
      "processPositions",
      "processTaxes",
      "loadingProcess",
      "showModalDelProcessOpposite",
      "searchWord",
      "filtered",
    ]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalysis",
      "processManager",
    ]),
    ...mapGetters("legal", ["paginatedData", "getFiltered"]),

    filteredProcessOpposites() {
      const a = this.getFiltered || this.processOpposites;
      return a;
    },
  },
  components: {
    OppositeItem,
    OppositeSearch,
    // ProcessItem,
  },
  methods: {
    onPageChange(page) {
      this.$store.commit("UPDATE_CURRENTPAGE", page);
    },
    openModalDelProcessOpposite() {
      this.$store.dispatch("legal/openModalDelProcessOpposite");
    },
    closeModalDelProcessOpposite() {
      this.$store.dispatch("legal/closeModalDelProcessOpposite");
    },
    //######################################################
    //     DELETE
    //######################################################
    confirmDeleteProcessOpposite(id) {
      this.$store.dispatch("legal/confirmDeleteProcessOpposite", {
        id: `${id}`,
      });
    },
    deleteProcessOpposite() {
      this.$store.dispatch("legal/deleteProcessOpposite", {
        id: this.selectedId.id,
      });
      this.closeModalDelProcessOpposite();
    },
  },
  created() {
    this.$store.dispatch("legal/getProcesses");
    this.$store.dispatch("legal/getProcessOpposites");
    this.$store.dispatch("legal/getProcessTypes");
    this.$store.dispatch("legal/getProcessCourts");
    this.$store.dispatch("legal/getProcessPositions");
    // this.$store.dispatch("legal/getProcessTaxes");
    this.$store.commit("legal/RESET_FILTER", null);
  },
};
</script>

<style scoped>
.topSearch{
  display: flex;
  justify-content: space-between;
  /* align-content: center; */
  align-items: center;
  padding-bottom: 40px;
}
.textProcess{
  display: flex;
  grid-gap: 5px;
  /* align-content: center; */
  align-items: center;
}
.oppositItems {
  display: grid;
  grid-template-columns: 1fr 30px;
  align-items: flex-start;
  grid-gap: 10px;
}
.oppositItems svg{
  margin-top: 35px;
}
.liProcess {
  margin-bottom: 30px;
}
.borderTable {
  margin: 10px 0;
  padding: 10px;
}
.titleLegal {
  display: grid;
  grid-template-columns: 1fr 120px repeat(2, 70px) 200px repeat(2, 150px);
  /* grid-template-columns: 1fr 120px repeat(2, 70px) 200px repeat(2, 150px); */
  /* padding: 5px 10px; */
  width: 100%;
  grid-gap: 10px;
}
.itemList {
  border-bottom: 3px solid #cbcbcb;
}
.modal_container {
  max-width: 450px;
}
.titleContainer {
  display: grid;
  grid-template-columns: 1fr 180px;
  justify-content: space-between;
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1200px);
  justify-content: center;
  align-content: flex-start;
}

.titleCollab {
  display: grid;
  grid-template-columns: 1fr 320px 50px;
  /* grid-template-columns: 1fr 320px 50px; */
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  min-width: 350px;
  max-width: 1200px;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  /* margin-bottom: 30px; */
}
.collabItens:hover {
  cursor: pointer;
}
.titleBlue {
  margin: 0 5px;
}
h3 {
  text-align: initial;
  /* margin-left: 10px; */
}
@media screen and (max-width: 695px) {
  .titleBlue {
    display: none;
  }
  .containerTelecom {
    margin: 80px 10px 20px 10px;
  }
  .titleLegal {
    display: none;
  }
}
</style>
