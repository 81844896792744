var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('h1',[_vm._v("Viaturas")]),_c('div',{staticClass:"containerTelecom"},[_c('div',{staticClass:"titleContainer"},[_c('h3',[_vm._v("Lista de Viaturas")]),(_vm.vehicleManager || _vm.vehicleAnalysis || _vm.vehicleEdit)?_c('div',{staticClass:"menuDropdown"},[_c('BtnConfig',[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Controlo "),_c('br'),_vm._v(" de Viaturas")]),_c('ul',{staticClass:"menuDropdownContent",attrs:{"slot":"itemsMenu"},slot:"itemsMenu"},[_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'tipos-viaturas',
                }}},[_vm._v(" Tipos ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'marcas',
                }}},[_vm._v(" Marcas ")])],1),_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'modelos',
                }}},[_vm._v(" Modelos ")])],1),(_vm.vehicleEdit || _vm.vehicleManager)?_c('li',[_c('router-link',{attrs:{"to":{
                  name: 'adicionar-viatura',
                }}},[_vm._v("Adicionar")])],1):_vm._e()])])],1):_vm._e()]),_vm._m(0),_c('article',[(_vm.loadingVehicle)?_c('div',[_c('Loading')],1):_c('div',[(_vm.vehicles && _vm.vehicles.length > 0)?_c('div',[_c('div',{staticClass:"filterBtn"},[_c('div',{on:{"click":function($event){$event.preventDefault();return _vm.filterBtn.apply(null, arguments)}}},[_c('BtnFilter',[_c('span',{attrs:{"slot":"filtro"},slot:"filtro"},[_vm._v("Filtro Viatura")])])],1)]),(_vm.btnFilter)?_c('div',[_c('VehicleSearch')],1):_vm._e(),_vm._m(1),_c('hr'),_c('ul',_vm._l((_vm.filteredVehicles),function(vehicle){return _c('li',{key:vehicle.id,staticClass:"itemList"},[_c('VehicleItem',{attrs:{"vehicle":vehicle,"brands":_vm.brands,"models":_vm.models,"idVehicle":vehicle.id}},[_c('button',{staticClass:"delete",attrs:{"slot":"delete"},on:{"click":function($event){$event.preventDefault();return _vm.confirmDeleteVehicle(vehicle.id)}},slot:"delete"})])],1)}),0)]):_c('div',[_c('p',{staticClass:"noResults"},[_vm._v("Não possui viaturas registradas")])])])])]),_c('NotifySuccess',{attrs:{"items":_vm.items}}),_c('div',{staticClass:"modalDel"},[(_vm.showModalDeleteVehicle)?_c('div',{staticClass:"overlay",on:{"click":_vm.closeModalDelVehicle}}):_vm._e(),(_vm.showModalDeleteVehicle)?_c('div',{staticClass:"modalDel_container"},[_c('button',{staticClass:"close btn",on:{"click":_vm.closeModalDelVehicle}},[_vm._v("x")]),_vm._m(2),_c('div',{staticClass:"containerDel"}),_c('div',{staticClass:"buttonsDel"},[_c('input',{staticClass:"btn",attrs:{"type":"submit","value":"Cancel"},on:{"click":function($event){$event.preventDefault();return _vm.closeModalDelVehicle.apply(null, arguments)}}}),_c('button',{staticClass:"btnDel",on:{"click":function($event){$event.preventDefault();return _vm.deleteVehicle.apply(null, arguments)}}},[_vm._v("Delete")])])]):_vm._e()]),_c('NotifyError',{attrs:{"itemsError":_vm.itemsError}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Lista de Viaturas")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleCollab"},[_c('p',{staticClass:"titleBlue"},[_vm._v("Matrícula")]),_c('p',{staticClass:"titleBlue"},[_vm._v("Próxima Inspeção")]),_c('p',{staticClass:"titleBlue"},[_vm._v("Última Revisão")]),_c('p',{staticClass:"titleBlue"},[_vm._v("Validade de Seguro")]),_c('p',{staticClass:"titleBlue"},[_vm._v("Serviços")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"delItem"},[_c('span',[_vm._v("Você tem certeza que deseja remover esta viatura?")])])
}]

export { render, staticRenderFns }