<template>
  <section>
    <h1>Central de Eventos</h1>
    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Lista de Usuários</h3>
        <!-- <div class="menuDropdown" v-if="staffEdit"> -->
        <div class="menuDropdown" v-if="telecomManager">
          <div @click="openModalTelegramUser">
            <BtnConfig>
              <span slot="title" class="addUser">
                Adicionar <br />
                Usuário</span
              >
            </BtnConfig>
          </div>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'mensagens' }"
              >Lista de Eventos</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Lista de Usuários do Telergam</span>
          </li>
        </ol>
      </nav>
      <article>
        <div v-if="loadingMessage">
          <Loading />
        </div>
        <div v-else>
          <div v-if="filteredTelegramUsers">
            <TelegramUserSearch />
            <div class="titleCollab">
              <p class="titleBlue">Telegram ID</p>
              <p class="titleBlue">Nome</p>
              <!-- <p class="titleBlue">Descrição</p> -->
              <p class="titleBlue">Ativo</p>
            </div>
            <hr />
            <ul
              v-for="telegramUser in filteredTelegramUsers"
              :key="telegramUser.id"
            >
              <li class="itemList">
                <TelegramUserItem
                  :telegramUser="telegramUser"
                  :idTelegramUser="telegramUser.id"
                >
                </TelegramUserItem>
              </li>
            </ul>
          </div>
          <div v-else>
            <p class="noResults">Não possui eventos cadastrados</p>
          </div>
        </div>
      </article>
    </div>
    <div class="modal">
      <div
        class="overlay"
        v-if="showModalTelegramUser"
        @click="closeModalTelegramUser"
      ></div>
      <div class="modal_container" v-if="showModalTelegramUser">
        <button class="close btn" @click="closeModalTelegramUser">x</button>
        <h2>Adicionar Usuário Telegram</h2>
        <article class="itemsUser">
          <p class="titleBlue">No Telegram</p>
          <div class="itemUser">
            <p class="textGray">1- Instalar o Telegram e Criar Registro</p>
            <p class="textGray">2 - Pesquisar no Telegram por:</p>
            <span class="textGreen center">@SimpliEsgotoBot</span>
            <p class="textGray">
              3 - Entrar no chat e digitar:
              <span class="textGreen">/start</span>
            </p>
            <p class="textGray">
              4- De seguida digitar:
              <span class="textGreen">/active</span>
            </p>
          </div>
        </article>
        <hr />
        <article class="itemsUser">
          <p class="titleBlue">No ERP</p>
          <div class="itemUser">
            <p class="textGray">1 - Menu Telecom</p>
            <p class="textGray">2 - Selecione "Central de Eventos"</p>
            <p class="textGray">3 - Selecione o Evento</p>
            <p class="textGray">3 - Editar Evento</p>
            <p class="textGray">
              5 - Adicionar o utilizador de telegram à lista de Usuários
              Notificados.
            </p>
          </div>
        </article>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import TelegramUserSearch from "@/components/message/TelegramUserSearch.vue";
import TelegramUserItem from "@/components/message/TelegramUserItem.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddContact",
  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId"]),
    ...mapState("user", ["user"]),
    ...mapState("message", [
      "telegramUsers",
      "loadingMessage",
      "showModalTelegramUser",
    ]),
    ...mapGetters("user", ["telecomAccess", "telecomManager"]),
    ...mapGetters("message", ["getFiltered"]),

    filteredTelegramUsers() {
      const a = this.getFiltered || this.telegramUsers;
      return a;
    },
  },
  components: {
    TelegramUserItem,
    TelegramUserSearch,
  },
  methods: {
    openModalTelegramUser() {
      this.$store.dispatch("message/openModalTelegramUser");
    },
    closeModalTelegramUser() {
      this.$store.dispatch("message/closeModalTelegramUser");
    },
  },
  created() {
    this.$store.dispatch("message/getTelegramUsers");
  },
};
</script>

<style scoped>
.modal_container hr {
  margin-top: 15px !important;
}
.itemsUser {
  display: grid;
}
span.textGreen.center {
  display: grid;
  justify-items: center;
}
.itemsUser .titleBlue {
  margin: 15px auto;
}
.itemUser {
  max-width: 350px;
}
.addUser {
  cursor: pointer;
}
.modal_container {
  max-width: 450px;
}
.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

.titleCollab {
  display: grid;
  /* grid-template-columns: repeat(10, 130px); */
  /* grid-template-columns: 200px 150px 70px 90px repeat(2, 100px); */
  /* grid-template-columns: minmax(200px, 380px) 1fr 130px; */
  grid-template-columns: repeat(3, 200px);
}

.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  /* margin-bottom: 30px; */
}

.titleBlue {
  margin: 0 5px;
}
h3 {
  text-align: initial;
  /* margin-left: 10px; */
}
@media screen and (max-width: 690px) {
  .titleBlue {
    display: none;
  }
  .containerTelecom {
    margin: 80px 10px 20px 10px;
  }
}
</style>
