<template>
  <form action="">
    <div class="">
      <div v-if="loadingVehicle">
        <p><Loading /></p>
      </div>
      <div class="formInfos" v-else>
        <label for="registration"> Matrícula: </label>
        <input
          id="registration"
          name="registration"
          type="text"
          v-model="registration"
          autocomplete="off"
          @change="resetError"
          :class="[validateFull, registrationExist]"
        />
        <div v-if="!errors.indexOf('full')" class="notify2">
          A matrícula é um campo requerido
        </div>
        <div v-if="!errors.indexOf('registrationExist')" class="notify">
          Esta matrícula já está registrada
        </div>
        <label for="vehicleTypeID"> Tipo de viatura: </label>
        <select
          v-model="vehicleTypeID"
          :class="validateVehicleType"
          class="option"
          @change="resetError"
        >
          <option value="null">Nenhum Tipo de Viatura</option>
          <option
            id="vehicleTypeID"
            v-for="(type, index) in typeVehicles"
            :key="type.id"
            :selected="index"
            :value="type.id"
          >
            {{ type.name }}
          </option>
        </select>
        <div v-if="!errors.indexOf('vehicleTypeID')" class="notify2">
          O tipo de viatura é um campo requerido
        </div>
        <label for="vehicleTypeID"> Modelo da viatura: </label>
        <select
          v-model="modelID"
          class="option"
          @change="resetError"
        >
          <option value="null">Selecione um modelo</option>
          <option
            id="modelID"
            v-for="(model, index) in models"
            :key="model.id"
            :selected="index"
            :value="model.id"
          >
            {{ model.name }}
          </option>
        </select>
        <!-- <div v-if="!errors.indexOf('vehicleTypeID')" class="notify2">
          O tipo de viatura é um campo requerido
        </div> -->
        <label for="date"> Próxima Inspeção Técnica Periódica: </label>
        <input
          id="date"
          type="date"
          v-model="nextInspectionDate"
          autocomplete="off"
          :class="[validateNextRevision, validateNextInspectionRequired]"
          @change="resetError"
        />
        <div v-if="!errors.indexOf('errorNextRevision')" class="notify2">
          A data da Próxima Inspeção tem que ser maior que hoje
        </div>
        <div v-if="!errors.indexOf('nextInspRequired')" class="notify2">
          A data da Próxima Inspeção é um campo requerido
        </div>
        <div class="button">
          <slot></slot>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "MessageForm",
  props: ["errors"],
  data() {
    return {
      contactName: [],
    };
  },
  mounted() {},
  computed: {
    validateFull() {
      return this.errors.indexOf("full") ? "true" : "validate";
    },
    registrationExist() {
      return this.errors.indexOf("registrationExist") ? "true" : "validate";
    },
    validateVehicleType() {
      return this.errors.indexOf("vehicleTypeID") ? "true" : "validate";
    },
    validateNextRevision() {
      return this.errors.indexOf("errorNextRevision") ? "true" : "validate";
    },
    validateNextInspectionRequired() {
      return this.errors.indexOf("nextInspRequired") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["registration", "vehicleTypeID", "modelID", "nextInspectionDate"], //nome dos campos
      module: "vehicle", //qual módulo está acessando
      base: "vehicle", //colocar o state que vai receber
      mutation: "vehicle/UPDATE_VEHICLE", //nome da mutation que vai alterar o state
    }),
    ...mapState(["loadingVehicle"]),
    ...mapState("vehicle", ["vehicle", "typeVehicles", "models"]),
  },
  methods: {
    resetError() {
      this.$store.commit("RESET_ERRORS", []);
    },
  },
  created() {
    this.$store.dispatch("vehicle/getTypeVehicles");
    this.$store.dispatch("vehicle/getModels");
  },
};
</script>

<style scoped>
.notify2 {
  margin: 10px 0;
}
select.option option {
  padding: 50px 0;
}
.formInfos {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-top: 20px; */
  align-content: center;
  max-width: 500px;
}
input.validate {
  margin: 0;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
@media screen and (max-width: 690px) {
  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: auto;
    width: 100%;
  }
  label,
  input {
    width: 100%;
    justify-items: flex-start;
  }
}
</style>