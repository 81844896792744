<template>
  <section class="">
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Serviços do Tipo de Viatura</h3>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'tipos-viaturas' }"
              >Lista de Tipos de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'tipo-viatura',
                params: { idTypeVehicle: idTypeVehicle },
              }"
              >Dados do Tipo de Viatura</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Serviços do Tipo de Viatura</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingVehicle">
        <Loading />
      </div>
      <div v-else>
        <article class="containerVehicle">
          <ul>
            <li class="items itemList" v-if="typeVehicle">
              <span class="titleBlue">Nome</span>
              <div class="list">
                <span class="textGray">{{ typeVehicle.name }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="typeVehicle">
              <span class="titleBlue">Descrição</span>
              <div class="list">
                <span class="textGray">{{ typeVehicle.description }}</span>
              </div>
            </li>
          </ul>
        </article>
        <div v-if="typeVehicle.services && typeVehicle.services.length > 0">
          <!-- <VehicleSearch /> -->
          <article>
            <h3 class="titleInfos">Serviços</h3>

            <div class="notify2">
              <select v-model="serviceId" :class="validateConfig">
                <option disabled value="null"></option>
                <option
                  id="config"
                  v-for="(service, index) in services"
                  :key="service.id"
                  :selected="index"
                  :value="service.id"
                >
                  {{ service.name }}
                </option>
              </select>
            </div>
            <div>
              <button class="btn btn-form" @click.prevent="updateService">
                Alterar
              </button>
            </div>

            <ul>
              <li
                v-for="(service, index) in typeVehicle.services"
                :key="service.id"
              >
                <article class="articleInfos">
                  <ServiceTypeVehicleItem
                    :service="service"
                    :idTypeVehicle="idTypeVehicle"
                    :index="index"
                  >
                    <button
                      slot="delete"
                      class="delete"
                      @click.prevent="confirmDeleteRevision(service.id)"
                    ></button>
                  </ServiceTypeVehicleItem>
                </article>
              </li>
            </ul>
          </article>
        </div>
        <div v-else>
          <p class="noResults">Não possui serviços registrados</p>
        </div>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import ServiceTypeVehicleItem from "@/components/vehicle/ServiceTypeVehicleItem.vue";
import { mapState, mapGetters } from "vuex";
import { formatDate } from "@/helpers.js";
import { mapFields } from "@/helpers.js";
export default {
  name: "RevisionVehicle",
  props: ["idTypeVehicle"],
  data() {
    return {
      searchHistory: false,
    };
  },
  components: {
    ServiceTypeVehicleItem,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId"]),
    ...mapState("user", ["user"]),
    ...mapState("vehicle", [
      "servicesTypeVehicle",
      "typeVehicle",
      "loadingVehicle",
      "showModalDeleteServiceVehicle",
    ]),
    ...mapState("service", ["services"]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),
    ...mapFields({
      fields: ["serviceId"], //nome dos campos
      module: "vehicle", //qual módulo está acessando
      base: "serviceTypeVehicle", //colocar o state que vai receber
      mutation: "vehicle/UPDATE_SERVICETYPEVEHICLE", //nome da mutation que vai alterar o state
    }),
    //##################################################
    //                 REVISIONS
    //##################################################

    revisionDate() {
      let dateRevision;
      for (let i in this.revisions) {
        if (i == 0) {
          const [date] = this.revisions[i].date.split(" " && "T");
          const [DD, MM, YYYY] = date.split("-");
          dateRevision = `${YYYY}-${MM}-${DD}`;
        }
      }
      return dateRevision;
    },
    createdTypeVehicle() {
      return formatDate(new Date(this.typeVehicle.created));
      // const [date, time] = this.typeVehicle.created.split(" " && "T");
      // // Dividindo a data em dia, mês e ano:
      // const [DD, MM, YYYY] = date.split("-");
      // // Dividindo o tempo em hora e minutos:
      // const [HH, mm] = time.split(":");
      // // Retornando a data formatada em um padrão compatível com ISO:
      // return `${YYYY}-${MM}-${DD}  ${HH}:${mm}`;
    },

    modifiedRevision() {
      return formatDate(new Date(this.typeVehicle.modified));
      // let modifiedRevision;
      // for (let i in this.revisions) {
      //   if (i == 0) {
      //     const [date, time] = this.typeVehicle[i].modified.split(" " && "T");
      //     // Dividindo a data em dia, mês e ano:
      //     const [DD, MM, YYYY] = date.split("-");
      //     // Dividindo o tempo em hora e minutos:
      //     const [HH, mm] = time.split(":");
      //     // Retornando a data formatada em um padrão compatível com ISO:
      //     modifiedRevision = `${YYYY}-${MM}-${DD}  ${HH}:${mm}`;
      //   }
      // }
      // return modifiedRevision;
    },
  },
  methods: {
    openModalDelRevision() {
      this.$store.dispatch("vehicle/openModalDelRevision");
    },
    closeModalDelRevision() {
      this.$store.dispatch("vehicle/closeModalDelRevision");
    },
    //######################################################
    //     DELETE
    //######################################################
    confirmDeleteRevision(id) {
      this.$store.dispatch("vehicle/confirmDeleteRevision", {
        id: `${id}`,
      });
    },
    deleteRevision() {
      this.$store.dispatch("vehicle/deleteRevision", {
        id: this.idTypeVehicle,
        idRevision: this.selectedId.id,
      });
      this.closeModalDelRevision();
    },
  },

  created() {
    this.$store.dispatch("service/getServices");
    this.$store.dispatch("vehicle/getTypeVehicle", {
      idTypeVehicle: this.idTypeVehicle,
    });
  },
};
</script>

<style scoped>
button.btn-form {
  margin: 20px auto 0 auto;
}
.borderTable {
  /* border: 1px solid #ccc; */
  /* background: rgb(245, 240, 240); */
  box-shadow: 0 4px 8px rgb(30 60 90 / 20%);
  padding: 20px 10px;
  border-radius: 9px;
}
.containerVehicle {
  margin-bottom: 30px;
}
.revisionBtns {
  display: grid;
  grid-template-columns: repeat(2, 40px);
  justify-content: flex-end;
  justify-items: flex-end;
  grid-gap: 5px;
  margin-right: 10px;
}

.titleInfos {
  margin-top: 20px;
  margin-bottom: 10px;
}

.callsHistory {
  margin-top: 20px;
}

li article.articleInfos:nth-child(n + 1) {
  margin-top: 10px;
}
.iconInfoSearchHistory {
  margin: 0 10px;
}
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
}
</style>
