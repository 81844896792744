var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{},[_c('h1',[_vm._v("Jurídico")]),_c('div',{staticClass:"containerContact"},[_c('div',{staticClass:"titleContainer"},[_c('h3',[_vm._v("Dados do Tribunal")]),(_vm.processAccess)?_c('div',[_c('router-link',{staticClass:"collabItens",attrs:{"to":{
            name: 'editar-tribunal',
            params: {
              idProcessCourt: _vm.idProcessCourt,
            },
          }}},[_c('AddUser',[_c('span',{attrs:{"slot":"title"},slot:"title"},[_vm._v(" Editar"),_c('br'),_vm._v(" Tribunal")])])],1)],1):_vm._e()]),(_vm.router == 'processo')?_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'processos', query: { archived: 'false'} }}},[_vm._v("Lista de Processos")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
              name: 'processo',
              params: { idProcess: _vm.idRouter },
            }}},[_vm._v("Processo")])],1),_vm._m(0)])]):_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'processos', query: { archived: 'false'} }}},[_vm._v("Lista de Processos")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'tribunais' }}},[_vm._v("Lista de Tribunais")])],1),_vm._m(1)])]),(_vm.loadingProcess)?_c('div',[_c('Loading')],1):_c('div',[(_vm.processCourt)?_c('article',[_c('ul',{staticClass:"borderTable"},[(_vm.processCourt.name && _vm.processCourt.name.length > 0)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Nome")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm.processCourt.name))])])]):_vm._e(),(_vm.processCourt.created && _vm.processCourt.created.length > 0)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Criado")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm._f("formatDateReverse")(_vm.processCourt.created)))])])]):_vm._e(),(_vm.processCourt.modified && _vm.processCourt.modified.length > 0)?_c('li',{staticClass:"items itemList"},[_c('span',{staticClass:"titleBlue"},[_vm._v("Modificado")]),_c('div',{staticClass:"list"},[_c('span',{staticClass:"textGray"},[_vm._v(_vm._s(_vm._f("formatDateReverse")(_vm.processCourt.modified)))])])]):_vm._e()])]):_vm._e()])]),_c('NotifyError',{attrs:{"itemsError":_vm.itemsError}})],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v("Dados do Tribunal")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v("Dados do Tribunal")])])
}]

export { render, staticRenderFns }