<template>
  <form action="">
    <div class="">
      <div v-if="loadingVehicle">
        <p><Loading /></p>
      </div>
      <div class="formInfos" v-else>
        <label for="apolicyNumber"> Número da Apólice: </label>
        <input
          id="apolicyNumber"
          name="apolicyNumber"
          type="text"
          @change="resetError"
          v-model="apolicyNumber"
          autocomplete="off"
        />
        <label for="startDate"> Início do Seguro: </label>
        <input
          id="startDate"
          type="date"
          v-model="startDate"
          autocomplete="off"
          @change="resetError"
          :class="[validateStartDate, validateStartDateGreater]"
        />
        <div v-if="!errors.indexOf('startDate')" class="notify2">
          A data de início do seguro é um campo requerido
        </div>
        <label for="endDate"> Validade do Seguro: </label>
        <input
          id="endDate"
          type="date"
          v-model="endDate"
          autocomplete="off"
          @change="resetError"
          :class="[validateEndDate, validateStartDateGreater]"
        />
        <div v-if="!errors.indexOf('endDate')" class="notify2">
          A data de validade do seguro é um campo requerido
        </div>
        <div v-if="!errors.indexOf('startDateGreater')" class="notify2">
          A data de validade deve ser posterior a data de início.
        </div>
        <div class="button">
          <slot></slot>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "MessageForm",
  props: ["errors", "revision"],
  computed: {
    // validateFull() {
    //   return this.errors.indexOf("full") ? "true" : "validate";
    // },
    validateStartDate() {
      return this.errors.indexOf("startDate") ? "true" : "validate";
    },
    validateStartDateGreater() {
      return this.errors.indexOf("startDateGreater") ? "true" : "validate";
    },
    validateEndDate() {
      return this.errors.indexOf("endDate") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["startDate", "endDate", "apolicyNumber"], //nome dos campos
      module: "vehicle", //qual módulo está acessando
      base: "insurance", //colocar o state que vai receber
      mutation: "vehicle/UPDATE_INSURANCE", //nome da mutation que vai alterar o state
    }),
    ...mapState(["loadingVehicle"]),
  },
  methods: {
    resetError() {
      this.$store.commit("RESET_ERRORS", []);
    },
  },
};
</script>

<style scoped>
.formInfos {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-top: 20px; */
  align-content: center;
  max-width: 350px;
}
input.validate {
  margin: 0;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
.notify2 {
  text-align: center;
  margin: 0 !important;
}
@media screen and (max-width: 690px) {
  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: auto;
    width: 100%;
  }
  label,
  input {
    width: 100%;
    justify-items: flex-start;
  }
}
</style>