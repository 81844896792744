<template>
  <section class="containerForbidden">
    <h1>403</h1>
    <!-- <div class="lockContainer">
      <div class="lock"></div>
    </div> -->

    <svg
      height="90"
      version="1.1"
      width="90"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 90 90"
    >
      <desc>Created with Snap</desc>
      <defs></defs>
      <g>
        <g id="matrix">
          <!-- <g transform="matrix(0.60144232,0,0,0.51599595,1.7120429,0.5915785)"> -->
          <path
            id="lock"
            fill="#00bf48"
            d="m 74.243261,65.070058 -8.771,0 0,-15.417951 c 0,-9.437 -5.68,-15.116 -15.117,-15.116 -9.438,0 -15.114,5.679 -15.114,15.116 l 0,24.451 -8.771,0 0,-24.451 c 0,-13.171 10.716,-23.885 23.886,-23.885 13.172,0 23.89,10.714 23.89,23.885 -0.0037,8.148799 0.0066,7.275332 -0.003,15.417951 z"
            transform="matrix(1,0,0,1,0,0)"
          ></path>
        </g>
        <rect
          fill="#00bf48"
          stroke-width="5"
          stroke-linecap="round"
          rx="5"
          width="42"
          height="33"
          x="26"
          y="42.5"
        ></rect>
      </g>
    </svg>

    <h2>O acesso a esta página é restrito</h2>
    <p>Por favor, verifique com a administração as suas permissões.</p>
  </section>
</template>

<script>
export default {};
</script>

<style scoped>
.containerForbidden {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: center;
  justify-items: center;
  text-align: center;
  grid-gap: 20px;
}

g#matrix {
  transform: matrix(0.70144232, 0, 0, 0.71599595, 11, 0);
  animation: dash 1.2s;
}

@keyframes dash {
  from {
    transform: matrix(0.70144232, 0, 0, 0.71599595, 11, -10);
  }
  to {
    transform: matrix(0.70144232, 0, 0, 0.71599595, 11, 0);
  }
}
</style>
