var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.contact)?_c('section',[_c('h1',[_vm._v("Contactos")]),_c('div',{staticClass:"contentGeneral"},[_c('h3',[_vm._v("Editar Contacto")]),(_vm.router == 'Colaborator')?_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'colaborador-ativo' }}},[_vm._v("Lista Ativos")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
              name: 'colaborador-info-ativo',
              params: { idCollaborator: _vm.idRouter },
            }}},[_vm._v("Dados do Colaborador")])],1),_vm._m(0)])]):(_vm.router == 'Client')?_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'clientes' }}},[_vm._v("Lista de Clientes")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
              name: 'cliente',
              params: { idClient: _vm.idRouter },
            }}},[_vm._v("Dados do Cliente")])],1),_vm._m(1)])]):(_vm.router == 'ProcessOpposite')?_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_vm._t("default"),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'processos',
                 query: { archived: 'false'} }}},[_vm._v("Lista de Processos")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
              name: 'intervenientes'
            }}},[_vm._v("Lista de Intervenientes")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
             name: 'interveniente',
                  params: { idOpposite: _vm.idRouter },
            }}},[_vm._v("Dados do Interveniente")])],1),_vm._m(2)],2)]):_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'contactos' }}},[_vm._v("Lista de Contactos")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'contacto', params: { idContact: _vm.idContact } }}},[_vm._v("Dados do Contacto")])],1),_vm._m(3)])]),_c('transition',{attrs:{"mode":"out-in"}},[_c('ContactForm',{attrs:{"contact":_vm.contact,"errors":_vm.errors}},[_c('button',{staticClass:"btn btn-form",attrs:{"slot":"enviar"},on:{"click":function($event){$event.preventDefault();return _vm.updateContact.apply(null, arguments)}},slot:"enviar"},[_vm._v(" Gravar ")])])],1),_c('NotifySuccess',{attrs:{"items":_vm.items}}),_c('NotifyError',{attrs:{"itemsError":_vm.itemsError}})],1)]):_vm._e()
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Editar Contacto do Colaborador")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Editar Contacto do Cliente")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Editar Contacto do Interveniente")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v("Editar Contacto")])])
}]

export { render, staticRenderFns }