<template>
  <div class="itemsCollab">
    <div class="containerContact">
      <div v-if="loadingService">
        <Loading />
      </div>
      <div v-else>
        <div v-if="services">
          <!-- <div class="titleContainer">
            <h3>Lista de Serviços Ativos</h3>
            <div v-if="permissionService">
              <router-link
                class="collabItens"
                :to="{
                  name: 'criar-servico',
                }"
              >
                <AddUser>
                  <span slot="title">
                    Adicionar<br />
                    Serviço</span
                  >
                </AddUser>
              </router-link>
            </div>
          </div>-->
          <article class="ItemsSearchEdit"></article>
          <div class="btnContainer">
            <!-- <div>Serviços:</div> -->
            <button :class="allVerify" @click.prevent="resetActive">
              Todos
            </button>
            <button :class="activeVerify" @click.prevent="activeRouter">
              Activos
            </button>
            <button :class="disabledVerify" @click.prevent="disabledRouter">
              Desativados
            </button>
          </div>
          <slot name="hr" />

          <article class="ItemsSearchEdit">
            <ServiceSearch />
            <div
              class="itemsEdit"
              v-if="permissionService && activeVerify === 'activeLi'"
            >
              <div v-if="editDrag" class="saveDrag">
                <button class="btn btn-form" @click.prevent="updateSequence">
                  Gravar
                </button>
                <button
                  class="btn btn-form cancelDrag"
                  @click.prevent="cancelDrag"
                >
                  Cancelar
                </button>
              </div>
              <div v-else class="sortIcon" @click.prevent="updateDrag">
                <img
                  class="dragDrop"
                  src="@/assets/svg/sortIcon.svg"
                  alt="Simpliesgoto"
                />
                <span class="textEditList">Editar Ordem</span>
              </div>
            </div>
          </article>
          <div class="titleService">
            <p class="titleBlue">Nome</p>
            <p class="titleBlue description">Descrição</p>
            <!-- <p class="titleBlue" v-if="editDrag">Delete</p> -->
          </div>
          <hr />
          <!-- <div v-if="activeVerify"> -->
          <ul v-for="(service, i) in filteredServices" :key="service.id">
            <li
              @dragover="(e) => onDragOver(service, i, e)"
              @dragend="(e) => finishDrag(service, i, e)"
              @dragstart="(e) => startDrag(service, i, e)"
              :class="{
                over: service === over.service && service !== dragFrom,
                [over.dir]: service === over.service && service !== dragFrom,
              }"
              draggable="true"
              class="itemList"
            >
              <svg
                version="1.1"
                class="dragDrop2"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
                x="0px"
                y="0px"
                viewBox="0 0 50 50"
                enable-background="new 0 0 50 50"
                xml:space="preserve"
              >
                <path
                  d="M8.667,15h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,15,8.667,15z"
                />
                <path
                  d="M8.667,37h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,37,8.667,37z"
                />
                <path
                  d="M8.667,26h30c0.552,0,1-0.447,1-1s-0.448-1-1-1h-30c-0.552,0-1,0.447-1,1S8.114,26,8.667,26z"
                />
              </svg>
              <ServiceItem :service="service" :editDragg="editDrag">
                <button
                  slot="delete"
                  class="delete"
                  @click.prevent="confirmDelete(service.id)"
                ></button>
              </ServiceItem>
            </li>
          </ul>
          <!-- </div> -->
          <!-- <div v- else>
            <ul v-for="service in filteredServices" :key="service.id">
              <li class="itemList">
                <ServiceItem :service="service" :editDragg="editDrag" />
              </li>
            </ul>
          </div> -->
        </div>
      </div>
    </div>
    <div class="modalDel">
      <div class="overlay" v-if="showModalDelete" @click="closeModalDel"></div>
      <div class="modalDel_container" v-if="showModalDelete">
        <button class="close btn" @click="closeModalDel">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este serviço?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDel"
          />
          <button class="btnDel" @click="deleteService">Delete</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ServiceItem from "@/components/service/ServiceItem.vue";
import ServiceSearch from "@/components/service/ServiceSearch.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "Services",
  components: {
    ServiceItem,
    ServiceSearch,
  },
  computed: {
    ...mapState("service", [
      "services",
      "loadingService",
      "searchWord",
      "filtered",
      "showModalDelete",
      "over",
      "startLoc",
      "dragging",
      "dragFrom",
      "editDrag",
      "servicesActive",
      "servicesDisabled",
    ]),
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["serviceManager", "serviceDetail"]),
    ...mapGetters("service", ["getFiltered"]),
    permissionService() {
      return this.serviceManager, this.serviceDetail;
    },
    filteredServices() {
      if (this.$route.query.active === "true") {
        return this.servicesActive;
        // return (
        //   this.getFiltered ||
        //   this.services.filter((service) => {
        //     return service.active === true;
        //   })
        // );
      } else if (this.$route.query.active === "false") {
        // return this.servicesDisabled;
        return (
          this.getFiltered ||
          this.services.filter((service) => {
            return service.active === false;
          })
        );
      } else {
        return this.getFiltered || this.services;
      }
      // const a = this.getFiltered || this.services;
      // return a;
    },
    allVerify() {
      return this.$route.query.active === undefined
        ? "activeLi"
        : "activeNothing";
    },
    activeVerify() {
      return this.$route.query.active === "true" ? "activeLi" : "activeNothing";
    },
    disabledVerify() {
      return this.$route.query.active === "false"
        ? "activeLi"
        : "activeNothing";
    },
    // disabledRouter
  },
  methods: {
    resetActive() {
      this.$route.query.active != undefined
        ? this.$router.push(this.$route.path)
        : "nothing";
    },
    activeRouter() {
      this.$route.query.active != "true"
        ? this.$router.push({ query: { active: true } })
        : "activeNothing";
      this.$store.commit("service/RESET_FILTER", null);
    },
    disabledRouter() {
      this.$route.query.active != "false"
        ? this.$router.push({ query: { active: false } })
        : "activeNothing";
      this.$store.commit("service/RESET_FILTER", null);
    },
    //DRAG & DROP

    //COMEÇANDO A ARRASTAR
    startDrag(service, e) {
      // console.log("esse é o e", e);
      this.$store.commit("service/STARTLOC", e.clientY);
      this.$store.commit("service/DRAGGING", true);
      this.$store.commit("service/DRAGFORM", service);
      // console.log("dragFrom", this.dragFrom);
    },

    //TERMINANDO DE ARRASTAR
    finishDrag(service, pos) {
      this.$store.commit("service/SLICE1", pos);
      this.$store.commit("service/SLICE2", service);
      this.$store.commit("service/OVER", {});
    },

    //QUANDO ARRASTA SOBRE
    onDragOver(service, pos, e) {
      // console.log("esse é o e", e.clientY);
      const dir = this.startLoc < e.clientY ? "down" : "up";
      setTimeout(() => {
        this.$store.commit("service/OVER", { service, pos, dir });
      }, 50);
      // this.$store.commit("service/SEQUENCE", pos);
      // this.$store.dispatch("service/updateSequence");
      // console.log("index", pos);
      // console.log("idUpdate", service.id);
    },

    // habilitar o editDrag para mostrar o modo de edicao
    updateDrag() {
      this.$store.commit("service/RESET_FILTER", null);
      this.$store.commit("service/EDITDRAG", true);
    },
    // cancel o editDrag para mostrar o modo de edicao
    cancelDrag() {
      this.$store.commit("service/EDITDRAG", false);
    },
    updateSequence() {
      this.$store.dispatch("service/updateSequence", { service: undefined });
    },

    // update(index, id) {
    //   console.log("index", index);
    //   console.log("idUpdate", id);
    // },

    openModalDel() {
      this.$store.dispatch("service/openModalDel");
    },
    closeModalDel() {
      this.$store.dispatch("service/closeModalDel");
    },
    // confirmDelete(id) {
    //   this.$store.dispatch("service/confirmDelete", {
    //     id: `${id}`,
    //   });
    // },
    // deleteService() {
    //   this.$store.dispatch("service/deleteService", {
    //     id: this.$store.state.service.selectedId.id,
    //   });
    //   this.closeModalDel();
    // },
    confirmDelete(id) {
      this.$store.dispatch("service/confirmDelete", {
        id: id,
      });
    },
    deleteService() {
      this.$store.dispatch("service/deleteService", {
        id: this.$store.state.service.selectedId.id,
      });
      this.closeModalDel();
    },
  },
  created() {
    this.$store.dispatch("service/getServices");
  },
};
</script>

<style scoped>
.activeLi {
  display: block;
  padding: 10px 30px;
  justify-self: center;
  /* border-radius: 9px; */
  border-radius: 9px 9px 0 0;
  overflow: none;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  background: #28a745;
  color: #fff;
}

.activeNothing {
  display: block;
  padding: 10px 30px;
  justify-self: center;
  /* border-radius: 9px; */
  border-radius: 9px 9px 0 0;
  overflow: none;
  outline: none;
  border: none;
  font-weight: 400;
  font-size: 16px;
  cursor: pointer;
  background: #d8d8d8;
  color: #1f2b42;
}
.btnContainer {
  display: flex;
  justify-items: flex-start;
  /* grid-gap: 10px; */
  align-items: flex-end;
  /* margin-bottom: 30px; */
}
.delete {
  justify-self: center;
}
.titleService {
  display: grid;
  grid-template-columns: minmax(300px, 350px) 1fr 100px;
  height: 30px;
  max-width: 1366px;
  border-radius: 5px;
}

.containerContact {
  /* margin: 28px 0; */
  padding: 30px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.titleBlue {
  padding-left: 10px;
}
hr {
  max-width: 1366px;
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 0 0 5px 0;
  text-align: left;
  margin-left: 10px;
}
/* .itemsCollab {
  padding: 40px 20px;
} */

.noResults {
  margin: 60px auto;
  max-width: 500px;
  text-align: center;
  padding: 10px;
  transition: all 0.2s;
}

.breadcrumb-item-active {
  margin-left: 10px;
}

.over {
  opacity: 0.6;
}

.down {
  transform: translateY(-20px);
}

.up {
  transform: translateY(20px);
}

/* Linha do Search e Edit */
.ItemsSearchEdit {
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: space-between;
  align-items: baseline;
}

/* Editar Lista */
.itemsEdit {
  display: flex;
  justify-content: flex-end;
  margin: 0 0 20px 0;
  grid-column: 2;
}
.sortIcon {
  display: grid;
  grid-template-columns: 30px 105px;
  grid-gap: 10px;
  color: #28a745;
  cursor: pointer;
}

/* Modo de edição da lista */
.saveDrag {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 10px;
  justify-items: flex-end;
  align-items: center;
}
.cancelDrag {
  background: #dc3545;
}

.cancelDrag:hover {
  background: #c42e3d;
}

/* svg drag drop */
.dragDrop2 {
  display: none;
}

@media screen and (max-width: 690px) {
  .ItemsSearchEdit {
    grid-template-columns: 1fr;
    margin: 0 10px;
    justify-items: center;
  }
  .titleService {
    display: none;
  }

  /* svg drag drop */
  .dragDrop2 {
    display: flex;
    /* flex-grow: 0; */
    flex: 1 0 1;
    width: 22px;
    /* margin: 0 10px; */
    fill: #858585;
    stroke: #858585;
  }
  .itemList {
    display: grid !important;
    grid-template-columns: 30px 1fr !important;
    align-items: center;
    margin-left: 10px;
  }
}
</style>