<template>
  <section class="">
    <router-link
      class="collabItens itemList"
      :to="{
        name: 'posicao',
        params: { idProcessPosition: processPosition.id },
      }"
    >
      <article class="containerLinkForm">
        <div>{{ processPosition.name }}</div>
      </article>
      <div class="delete">
        <slot name="delete" />
      </div>
    </router-link>
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProcessPositionItem",
  props: ["processPosition"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["processEdit"]),
  },
};
</script>

<style scoped>
.itemList {
  align-items: center;
}
.containerLinkForm {
  display: grid;
  grid-template-columns: 1fr;
  padding: 5px 10px;
}
.delete {
  margin-right: 10px;
}
.itemAddress {
  display: none;
}
@media screen and (max-width: 690px) {
  .containerLinkForm {
    grid-template-columns: 1fr;
    padding: 5px 10px;
  }
  .itemAddress {
    display: block;
  }
}
</style>