import { api } from "@/services.js";
// import router from "@/router";
import { removeAccent } from "@/helpers.js";
import { formatDate } from "@/helpers.js";

export default {
  namespaced: true,
  state: {
    //############################
    //       SERVIÇO            
    //############################
    services: null,
    servicesActive: null,
    service: {
      name: null,
      description: null,
      active: true,
      isDefault: false,
      sequence: 0
    },
    //############################
    //         LOADING            
    //############################
    loadingService: false,
    //############################
    //  PESQUISA
    //############################
    searchWord: null,
    filtered: null,
    //############################
    //  MODAL DELETE
    //############################
    showModalDelete: false,
    selectedId: null,

    //############################
    // DRAG & DROP
    //############################
    over: {},
    startLoc: 0,
    dragging: false,
    dragFrom: {},
    editDrag: false,
  },
  //########################################################################################
  //                 GETTERS            
  //########################################################################################
  getters: {
    //###############
    //  PESQUISA
    //###############
    getFiltered: (state) => state.filtered,
    serviceReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "name": state.service.name,
        "description": state.service.description,
        "active": state.service.active,
        "isDefault": state.service.isDefault,
        "sequence": state.service.sequence,
      }
    },
    serviceReset() {
      return {
        name: null,
        description: null,
        active: true,
        isDefault: false,
        sequence: 0
      }
    },

  },
  mutations: {
    //###############
    //  PESQUISA
    //###############
    RESET_FILTER(state, clean) {
      state.searchWord = clean;
      state.filtered = clean;
    },
    FILTERED_SERVICE(state, word) {
      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())
        state.filtered = state.services
          .filter(contact => {
            return Object.values(contact)
              .join(",")
              .toLowerCase()
              .includes(word);
          })
      }
    },
    //##############################
    // DRAG & DROP
    //##############################
    STARTLOC(state, payload) {
      state.startLoc = payload;
    },
    DRAGGING(state, payload) {
      state.dragging = payload;
    },
    DRAGFORM(state, payload) {
      state.dragFrom = payload;
    },
    OVER(state, payload) {
      state.over = payload;
    },

    //Slice1 - pos=posição atual e 1=remove a posição
    SLICE1(state, pos) {
      // console.log("SLICE1", pos)
      state.servicesActive.splice(pos, 1);
    },

    //Slice2 - service=posição atual e 1=remove a posição
    SLICE2(state, service) {
      // console.log("SLICE2", service)
      state.servicesActive.splice(state.over.pos, 0, service)
    },
    SLICEDEL(state, { pos, service }) {
      state.servicesActive.splice(pos, 0, service)
    },

    //habilitar modo de edição do drag & drop
    EDITDRAG(state, payload) {
      state.editDrag = payload;
    },
    //##############################
    //  MODAL DELETE
    //##############################
    UPDATE_MODAL_DELETE(state, payload) {
      state.showModalDelete = payload;
    },
    UPDATE_SELECTED_ID(state, payload) {
      state.selectedId = payload;
      // state.selectedId = Object.assign(state.selectedId, payload);
    },
    //########################### 
    //    LOADING
    //###########################
    UPDATE_LOADING(state, payload) {
      state.loadingService = payload;
    },
    //################################################################# 
    //       SERVICE
    //#################################################################
    UPDATE_SERVICES(state, payload) {
      let servicesActive = payload.filter(s => s.active == true)
      // console.log("servicesActive", servicesActive)
      state.servicesActive = servicesActive.sort((a, b) => {
        let x = a.sequence,
          y = b.sequence;
        return x == y ? 0 : x > y ? 1 : -1;
      });
      if (payload != null)
        state.services = payload.sort((a, b) => {
          let x = a.sequence,
            y = b.sequence;
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else state.services = payload
    },
    UPDATE_SERVICE(state, payload) {
      state.service = Object.assign(state.service, payload);
    },
    UPDATE_DELETE_EMAILS(state, serviceId) {
      let services = state.services.filter(c => c.id != serviceId)
      state.services = services;
    },
  },
  actions: {
    getServices(context, refresh) {
      context.commit("RESET_FILTER", null);
      context.commit("UPDATE_LOADING", true);
      if (context.state.services === null || refresh != undefined) {
        api.get(`/service/`)
          .then(
            response => {
              const services = response.data.map(function (service) {
                service.created = formatDate(new Date(service.created));
                service.modified = formatDate(new Date(service.modified));
                return service;
              });
              // console.log(services)
              context.commit("UPDATE_SERVICES", services);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("RESET_FILTER", null);
        context.commit("UPDATE_SERVICES", context.state.services);
        context.commit("UPDATE_LOADING", false);
      }
    },
    //##################################################
    //    SERVICE - INFORMAÇÕES - ACTIONS
    //##################################################

    getService(context, { idService, refreshId }) {
      context.commit("UPDATE_LOADING", true);
      if (context.state.services === null || refreshId != undefined) {
        api.get(`/service/${idService}`)
          .then(
            response => {
              const service = {}
              Object.keys(response.data).filter(key => {
                // ##### retornando todas as datas formatadas
                return ((key === "created") && (service[key] = formatDate(new Date(response.data[key])))) ||
                  ((key === "modified") && (service[key] = formatDate(new Date(response.data[key])))) ||
                  (service[key] = response.data[key])
              })
              // console.log(service)
              context.commit("UPDATE_SERVICE", service);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        const service = context.state.services.find(c => c.id == idService)
        // console.log(contact)
        context.commit("UPDATE_SERVICE", service);
        context.commit("UPDATE_LOADING", false);
      }
    },

    SLICE2(state, service) {
      state.services.splice(state.over.pos, 0, service)
    },
    //########################################################################
    //            CADASTRAR SERVIÇOS
    //########################################################################

    addService(context, { service }) { //usando context por ter tanto "commit" quanto "dispatch"
      context.commit("UPDATE_WAIT", true, { root: true });
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      // console.log("enviar sequence", `${idService}`)
      // context.dispatch("updateSequence", idService);
      if (service.name == null || service.name == "") {
        context.commit("UPDATE_WAIT", false, { root: true });
        context.commit("UPDATE_ERRORS", "full", { root: true });
      }
      if (service.description == null || service.description == "") {
        context.commit("UPDATE_WAIT", false, { root: true });
        context.commit("UPDATE_ERRORS", "full", { root: true });
      } else {
        api.post(`/service/`, service)
          .then(() => {
            context.dispatch("getServices", "refresh");
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Serviço editado com sucesso!",
            }, { root: true });
            context.commit("UPDATE_WAIT", false, { root: true });
            context.commit("UPDATE_SERVICE", this.getters["service/serviceReset"]);//limpa o formulário depois de cadastrar     
          })
          .catch(error => {
            context.commit("UPDATE_WAIT", false, { root: true });
            console.log(error.response)
            if (error.response.data.error === "Name already exist") {
              context.commit("UPDATE_ERRORS", "nameRepeat", { root: true });
              // context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "Service description can't be null") {
              context.commit("UPDATE_ERRORS", "description", { root: true });
              // context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },
    updateService(context, { service, idService }) { //usando context por ter tanto "commit" quanto "dispatch"
      context.commit("UPDATE_WAIT", true, { root: true });
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      // console.log("enviar sequence", `${idService}`)
      // context.dispatch("updateSequence", idService);
      if (service.name == null || service.name == "") {
        context.commit("UPDATE_WAIT", false, { root: true });
        context.commit("UPDATE_ERRORS", "full", { root: true });
      } else {
        api.put(`/service/${idService}`, service)
          .then(() => {
            // context.commit("UPDATE_SERVICES", null);
            context.commit("UPDATE_SERVICE", this.getters["service/serviceReset"]);//limpa o formulário depois de cadastrar     
            context.dispatch("getServices", "refresh");
            context.dispatch("getService", { idService, refreshId: "refreshId" });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Serviço editado com sucesso!",
            }, { root: true });
            context.commit("UPDATE_WAIT", false, { root: true });
            context.dispatch("updateSequence", { service: service.active, idService });
          })
          .catch(error => {
            context.commit("UPDATE_WAIT", false, { root: true });
            console.log(error.response)
            if (error.response.status === 400 && error.response.data.error === "Name already exist in another service") {
              context.commit("UPDATE_ERRORS", "nameRepeat", { root: true });
              // context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      }
    },

    //############################################
    //       DELETE
    //############################################

    //       MODAL - DELETE 
    openModalDel(context) {
      context.commit("UPDATE_MODAL_DELETE", true);
    },
    closeModalDel(context) {
      context.commit("UPDATE_MODAL_DELETE", false);
    },

    //Confirmação abrindo modal Delete - Delete 1 de 2
    confirmDelete(context, id) {
      context.commit("UPDATE_SELECTED_ID", id); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DELETE", true);//abre o modal
      // window.scrollTo({
      //   top: 0,
      //   behavior: "smooth"
      // })
    },

    //Delete - Delete 2 de 2
    deleteService(context, { id }) {
      context.commit("UPDATE_LOADING", true);
      api.delete(`/service/${id}`)
        .then(() => {
          context.commit("UPDATE_DELETE_EMAILS", id); //atualiza 
          context.dispatch("getServices", "refresh");
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Serviço deletado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_LOADING", false);
          // console.log("deletou", id)
        })
        .catch(error => {
          context.commit("UPDATE_LOADING", false);
          console.log(error.response.data)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    updateSequence(context, { service, idService }) {
      //## verifica se ativou ou desativou no update e muda a posição para o início ou final da lista
      if (service != undefined) {
        let index = context.state.services.length
        let sequencePos = service === false ? index : 0
        api.put(`/service/${idService}`, { "sequence": sequencePos })
          .then(() => {
            context.dispatch("getServices", "refresh");
            context.dispatch("getService", { idService, refreshId: "refreshId" });
          })
          .catch(error => {
            console.log(error.response)
          });
      } else {
        let index = 0
        for (let i in context.state.servicesActive) {
          api.put(`/service/${context.state.servicesActive[i].id}`, { "sequence": index })
            .then(() => {
              context.dispatch("getServices", "refresh");
              context.commit("UPDATE_SERVICE", this.getters["service/serviceReset"]);//limpa o formulário depois de cadastrar 
            })
            .catch(error => {
              console.log(error.response)
              const status = error.response.status
              context.dispatch("errorStatus", status, { root: true })
            })
          index += 1;
        }
        context.dispatch("success", {        //cria as informações do texto do toast
          id: Date.now(),
          h1: "Successo!",
          body: "Lista editada com sucesso!",
        }, { root: true });
        context.commit("EDITDRAG", false)
        context.dispatch("getServices", "refresh");
      }
    },

  }

}