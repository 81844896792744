<template>
  <form class="containerForm">
    <div v-if="loadingLogin">
      <Loading />
    </div>
    <div v-else>
      <p class="email">
        <label for="email">Email</label>
        <input
          type="email"
          name="username"
          id="username"
          v-model="username"
          :class="validateLogin"
        />
      </p>
      <p>
        <label for="password">Senha</label>
        <input
          type="password"
          name="senha"
          id="password"
          v-model="password"
          :class="validateLogin"
        />
      </p>
    </div>
    <p class="itemBtn">
      <ErrorLogin :errorLogin="errorLogin" />
      <slot />
    </p>
  </form>
</template>
<script>
import { mapState } from "vuex";

export default {
  props: ["collaborator", "errors"],
  computed: {
    ...mapState(["errorLogin", "loadingLogin"]),
    validateLogin() {
      // return this.errorLogin.indexOf("Incorrect email or password")
      return this.errorLogin.indexOf("Email ou senha incorretos")
        ? "true"
        : "validate";
    },
    username: {
      get() {
        return this.$store.state.loginUser.username;
      },
      set(value) {
        this.$store.commit("UPDATE_USERNAME", value);
      },
    },
    password: {
      get() {
        return this.$store.state.loginUser.password;
      },
      set(value) {
        this.$store.commit("UPDATE_PASSWORD", value);
      },
    },
  },
};
</script>

<style scoped>
.titleLogin {
  margin: 40px 0 70px 0;
  color: #ffffff;
}
form.containerForm {
  display: grid;
  grid-template-columns: 1fr;
  width: 100%;
  max-width: 596px;
  padding: 20px;
}
form.containerForm p {
  display: grid;
  grid-template-columns: 1fr;
  color: #00bf48;
  text-align: start;
}

.containerForm p input {
  background: none;
  border: none;
  border-bottom: 2px solid #00bf48;
  color: #ffffff;
  height: 40px;
  overflow: none;
  outline: none;
}

p.email {
  margin-bottom: 40px;
}

p.itemBtn {
  justify-content: center;
  width: 100%;
}
.btn {
  padding: 20px auto !important;
  margin: 40px auto 0px auto;
  width: 200px;
  justify-self: center;
  background: #00bf48;
  border-radius: 9px;
  overflow: none;
  outline: none;
  border: none;
  color: #ffffff;
  font-weight: 400;
  font-size: 24px;
  cursor: pointer;
}

.btn:hover {
  background: #01ad43;
}

.btn:active {
  background: #00732c;
}
/* input transparent */
input:-webkit-autofill {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #ffffff !important;
}

/* validar */
.containerForm p input.validate {
  border-bottom: 2px solid #87898b !important;
  /* border-bottom: 2px solid  #dc3545 !important; */
}

@media screen and (max-width: 690px) {
  form.containerForm {
    display: grid;
    grid-template-columns: 1fr;
    width: auto;
    max-width: 596px;
    padding: 20px;
  }
}
</style>