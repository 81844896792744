var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('h1',[_vm._v("Moradas")]),_c('div',{staticClass:"contentGeneral"},[_vm._m(0),(_vm.router == 'Client')?_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'clientes' }}},[_vm._v("Lista de Clientes")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
              name: 'cliente',
              params: { idClient: _vm.idRouter },
            }}},[_vm._v("Dados do Cliente")])],1),_vm._m(1)])]):(_vm.router == 'ProcessOpposite')?_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_vm._t("default"),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'processos',
                 query: { archived: 'false'} }}},[_vm._v("Lista de Processos")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
              name: 'intervenientes'
            }}},[_vm._v("Lista de Intervenientes")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
             name: 'interveniente',
                  params: { idOpposite: _vm.idRouter },
            }}},[_vm._v("Dados do Interveniente")])],1),_vm._m(2)],2)]):_c('nav',{staticClass:"navBreadcrumb"},[_c('ol',{staticClass:"breadcrumb"},[_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{ name: 'moradas' }}},[_vm._v("Lista de Moradas")])],1),_c('li',{staticClass:"breadcrumb-item"},[_c('router-link',{attrs:{"to":{
              name: 'morada',
              params: { idAddress: _vm.idAddress },
            }}},[_vm._v("Dados da Morada")])],1),_vm._m(3)])]),_c('div',{staticClass:"itemFormCollaborator"},[_c('AddressForm',{attrs:{"address":_vm.address,"errors":_vm.errors}},[_c('div',{attrs:{"slot":"cancel"},slot:"cancel"}),_c('div',{staticClass:"btnJustify",attrs:{"slot":"delete"},slot:"delete"},[_c('button',{staticClass:"btn",on:{"click":function($event){$event.preventDefault();return _vm.updateAddress.apply(null, arguments)}}},[_vm._v("Gravar")])])])],1),_c('NotifySuccess',{attrs:{"items":_vm.items}}),_c('NotifyError',{attrs:{"itemsError":_vm.itemsError}})],1)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"titleContainer"},[_c('h3',[_vm._v("Editar Morada")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Editar Morada do Cliente")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Editar Contacto do Interveniente")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"breadcrumb-item-active"},[_c('span',[_vm._v(" Editar Morada")])])
}]

export { render, staticRenderFns }