<template>
  <section class="containerLinkForm">
    <article>
      <!-- {{tax}} -->
      <div class="info" v-if="tax.value">Valor: {{ tax.value }}</div>
      <div class="itemAddress" v-if="typeTaxInfo">Tipo de taxa: {{ typeTaxInfo.description }}</div>
      <div class="itemAddress" v-if="tax.paymentDate">Data do pagamento: {{ tax.paymentDate  | formatDateString  }}</div>
      
    </article>

    <slot name="editAddress" />
    <slot name="delete" />
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ProcessTaxItem",
  props: ["tax", "processTypeTaxes"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", ["staffEdit"]),

    //##################################################
    //                 TAX TYPE TAX
    //##################################################
    typeTaxInfo(){
      let taxType = null;
      if (this.processTypeTaxes != null) {
          taxType = this.processTypeTaxes.find((processTypeTaxe) => {
            return processTypeTaxe.id == this.tax.taxTypeID;
          });
        } 
      return taxType
    }
  },
};
</script>

<style scoped>
.itemAddress {
  margin-left: 1em;
}
</style>