<template>
  <form action="">
    <div class="container-topSearch">
      <div class="searchContainer">
        <input
          type="text"
          name="collaboratorSearch"
          id="search"
          v-model="searchWord"
          autocomplete="off"
          placeholder="Search..."
        />
        <!-- @click="event" -->
        <!-- {{ searchWord }} -->
        <span id="lupa"></span>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  computed: {
    searchWord: {
      get() {
        return this.$store.state.vehicle.searchWord;
      },
      set(value) {
        this.$store.commit("vehicle/FILTERED_TYPEVEHICLES", value);
      },
    },
  },
  methods: {
    // event(e) {
    //   // console.log(e.type);
    //   e.type == "click"
    //     ? this.$store.commit("UPDATE_CURRENTPAGE", 1)
    //     : "nothing";
    // },
  },
};
</script>

<style scoped>
.container-topSearch {
  display: grid;
  grid-template-columns: minmax(300px, 400px);
  width: 100%;
  padding: 0 10px 40px 0;
  justify-content: flex-start;
}

#search {
  width: 100%;
}
.searchContainer {
  position: relative;
}
#lupa {
  width: 62px;
  height: 46px;
  background: url("../../assets/svg/search2.svg") no-repeat center center;
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;
}
</style>