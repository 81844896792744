<template>
  <form action="">
    <div v-if="loadingProcess">
      <p><LoadingCircle /></p>
    </div>
    <div class="formInfos" v-else>
      <label for="name"> Nome: </label>
      <input
        id="name"
        name="name"
        type="text"
        v-model="name"
        :class="validateFull"
        @change="resetError"
      />
      <div v-if="!errors.indexOf('name')" class="notify">
        O nome é um campo requerido
      </div>
      <label for="description"> Descrição: </label>
      <input
        id="description"
        name="description"
        type="text"
        v-model="description"
        :class="[validateDescription]"
        @change="resetError"
      />
      <div v-if="!errors.indexOf('description')" class="notify">
        A descrição é um campo requerido.
      </div>
    </div>
    <div v-if="!errors.indexOf('type')" class="notify">
      O tipo é um campo requerido.
    </div>

    <div class="button">
      <slot name="cancel"></slot>
      <slot name="enviar"></slot>
    </div>
  </form>
</template>
<script>
import { mapFields } from "@/helpers.js";
import { mapState } from "vuex";

export default {
  name: "TaxForm",
  props: ["errors"],
  computed: {
    ...mapState("legal", ["processTax"]),
    validateFull() {
      return this.errors.indexOf("name") ? "true" : "validate";
    },
    validateDescription() {
      return this.errors.indexOf("description") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["name", "description"], //nome dos campos
      module: "legal", //qual módulo está acessando
      base: "processTypeTax", //colocar o state que vai receber
      mutation: "legal/UPDATE_PROCESS_TYPETAX", //nome da mutation que vai alterar o state
    }),
    ...mapState("legal", ["loadingProcess", "processTypeTaxes"]),
  },
  methods: {
    resetError() {
      this.$store.commit("RESET_ERRORS", []);
    },
  },
  created() {
    // this.$store.dispatch("legal/getProcessTypeTaxes");
  },
};
</script>

<style scoped>
.formInfos2 {
  display: grid;
  grid-template-columns: 180px 100px;
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  margin-top: 10px;
  align-content: center;
}
.icon-add {
  fill: #fff;
  /* stroke: #fff; */
  z-index: 99;
}
.buttonAdd {
  display: grid;
  justify-content: center;
  align-items: center;
  /* margin-left: 60px; */
  background-color: #00bf48;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin: 0 15px;
  overflow: hidden;
  /* fill: #fff; */
  color: #ffffff;
}
select {
  margin: 10px 0;
}
.selectContainer,
.radioContainer {
  margin: 20px 0;
  /* display: grid;
  grid-template-columns: 30px 1fr; */
}
.radioContent {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  box-shadow: 0 4px 8px rgb(31 43 66 / 10%);
  padding: 20px;
  border-radius: 9px;
  margin: 20px 0;
}

.radio {
  display: grid;
  grid-template-columns: 30px 1fr;
  align-items: center;
  outline: none;
  box-shadow: none;
}
form {
  max-width: 500px;
}
.formInfos {
  display: grid;
  grid-template-columns: minmax(300px, 500px);
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  margin-top: 20px;
  margin-right: 10px;
  max-width: 490px;
}

label {
  margin-top: 10px;

  outline: none;
}

.formInfos2 label {
  cursor: pointer;
}
.button {
  display: flex;
  grid-gap: 10px;
  justify-content: flex-end;
  margin-top: 10px;
  margin-right: 10px;
}
</style>