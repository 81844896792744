<template>
  <section class="">
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Seguros da Viatura</h3>
        <div v-if="vehicleManager || vehicleEdit">
          <router-link
            class="collabItens"
            :to="{
              name: 'adicionar-seguro',
              params: {
                idVehicle: idVehicle,
              },
            }"
          >
            <AddUser>
              <span slot="title">
                Adicionar<br />
                Seguro</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'viatura',
                params: { idVehicle: idVehicle },
              }"
              >Dados da Viatura
            </router-link>
          </li>
          <li class="breadcrumb-item-active">
            <span> Seguros da Viatura</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingVehicle">
        <Loading />
      </div>
      <div v-else>
        <article v-if="vehicle" class="containerVehicle">
          <ul>
            <li
              class="items itemList"
              v-if="vehicle.registration && vehicle.registration.length > 0"
            >
              <span class="titleBlue">Matrícula</span>
              <div class="list">
                <span class="textGray">{{ vehicle.registration }}</span>
              </div>
            </li>
          </ul>
        </article>

        <div v-if="insurances && insurances.length > 0">
          <span v-for="(insurance, i) in insurances" :key="i" class="textGray">
            <ul v-if="i === 0" class="borderTable">
              <div class="titleRecent">
                <div>Último registro</div>
                <div class="revisionBtns">
                  <router-link
                    v-if="vehicleManager || vehicleEdit"
                    :to="{
                      name: 'editar-seguro',
                      params: {
                        idVehicle: idVehicle,
                        idInsurance: insurance.id,
                      },
                    }"
                  >
                    <button class="edit"></button>
                  </router-link>
                  <button
                    v-if="vehicleManager"
                    slot="delete"
                    class="delete"
                    @click.prevent="confirmDeleteInsurance(insurance.id)"
                  ></button>
                </div>
              </div>
              <li class="items itemList">
                <span class="titleBlue">Validade do Seguro</span>
                <div class="list">               
                  <span :class="validateIsurance">{{
                    insurance.endDate | formatDateReverse
                  }}</span>
                  <span class="validate">{{
                    insurance.endDate | expireDate
                  }}</span>
                  <!-- <span v-if="expireInsurance" class="validate"
                    >(expirado)</span
                  > -->
                </div>
              </li>
              <li class="items itemList">
                <span class="titleBlue">Início do Seguro</span>
                <div class="list">
                  <span>{{ insurance.startDate | formatDateReverse }}</span>
                </div>
              </li>
              <li class="items itemList">
                <span class="titleBlue">Número da Apólice</span>
                <div class="list">
                  <span v-if="insurance.apolicyNumber">{{
                    insurance.apolicyNumber
                  }}</span>
                  <span v-else>Apólice não registrada</span>
                </div>
              </li>
              <li class="items itemList">
                <span class="titleBlue">Criado</span>
                <div class="list">
                  <span>{{ insurance.created | formatDateReverse }}</span>
                </div>
              </li>
              <li class="items itemList">
                <span class="titleBlue">Modificado</span>
                <div class="list">
                  <span>{{ insurance.modified | formatDateReverse }}</span>
                </div>
              </li>
              <li class="items containerFile">
                <span class="titleBlue">Ficheiros</span>
                <div v-if="loadingImg">
                  <LoadingCircle />
                </div>
                <div v-else>
                  <div
                    v-if="vehicleEdit || vehicleManager"
                    class="containerFile"
                  >
                    <div class="inputList">
                      <label for="fileInput" class="inputFile"
                        >Selecione um ficheiro
                        <span class="spot">clique aqui</span></label
                      >
                      <span v-if="selectedFile != null" class="itemFile"
                        >{{ selectedFile.name
                        }}<span
                          @click.prevent="cleanFile($event)"
                          class="clearFile"
                          >&#215;</span
                        ></span
                      >
                      <input
                        type="file"
                        @change="onFileSelected"
                        id="fileInput"
                        class="inputNone"
                      />
                    </div>
                    <button class="btn addFile" @click="onUpload(insurance.id)">
                      Adicionar
                    </button>
                  </div>
                </div>
              </li>

              <article>
                <div v-if="loadingImg"></div>
                <div v-else>
                  <div
                    v-if="insurance.files != null && insurance.files.length > 0"
                  >
                    <article
                      class="downloadImgLoading"
                      v-if="loadingDownloadImg"
                    >
                      Aguarde, fazendo download...
                    </article>
                    <ul v-else>
                      <li v-for="archive in insurance.files" :key="archive.id">
                        <article class="articleInfos">
                          <!-- <hr /> -->
                          <ArchiveItem
                            :archive="archive"
                            :idMicroservice="insurance.id"
                            @getFile="getFile(archive.filename, archive.id)"
                          >
                            <button
                              slot="delete"
                              class="delete"
                              @click.prevent="
                                confirmDeleteFileInsurance(
                                  insurance.id,
                                  archive.id
                                )
                              "
                            ></button>
                          </ArchiveItem>
                        </article>
                      </li>
                    </ul>
                  </div>
                  <div v-else>
                    <p class="noResults noArchive">
                      Não possui ficheiros cadastrados
                    </p>
                  </div>
                </div>
              </article>
            </ul>
          </span>
          <!-- <hr /> -->
          <article
            v-if="
              insurances.length > 1 || (filtered != null && insurances.length)
            "
          >
            <h3 class="titleInfos">Histórico de Seguros</h3>
            <InsuranceSearch />
            <ul>
              <li
                v-for="(insurance, index) in filteredInsurance"
                :key="insurance.id"
              >
                <article
                  class="articleInfos"
                  v-if="
                    (filtered === null && index > 0) ||
                    (filtered != null && index >= 0)
                  "
                >
                  <InsuranceItem
                    :insurance="insurance"
                    :idInsurance="insurance.id"
                    :idVehicle="idVehicle"
                    :index="index"
                  >
                    <div v-if="loadingImg">
                      <LoadingCircle />
                    </div>
                    <div v-else slot="fileArchive">
                      <div
                        v-if="vehicleEdit || vehicleManager"
                        class="containerFile"
                      >
                        <div class="inputList">
                          <label for="fileInput" class="inputFile"
                            >Selecione um ficheiro
                            <span class="spot">clique aqui</span></label
                          >
                          <span v-if="selectedFile != null" class="itemFile"
                            >{{ selectedFile.name
                            }}<span @click.prevent="cleanFile" class="clearFile"
                              >&#215;</span
                            ></span
                          >
                          <input
                            type="file"
                            @change="onFileSelected"
                            id="fileInput"
                            class="inputNone"
                          />
                        </div>
                        <button
                          class="btn addFile"
                          @click="onUpload(insurance.id)"
                        >
                          Adicionar
                        </button>
                      </div>
                    </div>
                    <article slot="listArchive">
                      <div v-if="loadingImg"></div>
                      <div v-else>
                        <div
                          v-if="
                            insurance.files != null &&
                            insurance.files.length > 0
                          "
                        >
                          <article
                            class="downloadImgLoading"
                            v-if="loadingDownloadImg"
                          >
                            Aguarde, fazendo download...
                          </article>
                          <ul v-else>
                            <li
                              v-for="archive in insurance.files"
                              :key="archive.id"
                            >
                              <article class="articleInfos">
                                <!-- <hr /> -->
                                <ArchiveItem
                                  :archive="archive"
                                  :idMicroservice="insurance.id"
                                  @getFile="
                                    getFile(archive.filename, archive.id)
                                  "
                                >
                                  <button
                                    slot="delete"
                                    class="delete"
                                    @click.prevent="
                                      confirmDeleteFileInsurance(
                                        insurance.id,
                                        archive.id
                                      )
                                    "
                                  ></button>
                                </ArchiveItem>
                              </article>
                            </li>
                          </ul>
                        </div>
                        <div v-else>
                          <p class="noResults noArchive">
                            Não possui ficheiros cadastrados
                          </p>
                        </div>
                      </div>
                    </article>
                    <button
                      slot="delete"
                      class="delete"
                      @click.prevent="confirmDeleteInsurance(insurance.id)"
                    ></button>
                  </InsuranceItem>
                </article>
              </li>
            </ul>
          </article>
        </div>
        <div v-else>
          <p class="noResults">Não possui seguros cadastrados</p>
        </div>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteInsurance"
        @click="closeModalDelInsurance"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteInsurance">
        <button class="close btn" @click="closeModalDelInsurance">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este seguro?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelInsurance"
          />
          <button class="btnDel" @click.prevent="deleteInsurance">
            Delete
          </button>
        </div>
      </div>
    </div>
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteFile"
        @click="closeModalDelFileInsurance"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteFile">
        <button class="close btn" @click="closeModalDelFileInsurance">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este ficheiro?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelFileInsurance"
          />
          <button class="btnDel" @click.prevent="deleteFileInsurance">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import InsuranceSearch from "@/components/vehicle/InsuranceSearch.vue";
import InsuranceItem from "@/components/vehicle/InsuranceItem.vue";
import ArchiveItem from "@/components/vehicle/ArchiveItem.vue";
import { getLastDate } from "@/helpers.js";
import { alertDate } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "InsuranceVehicle",
  props: ["idVehicle"],
  data() {
    return {
      searchHistory: false,
      // selectedFile: null,
    };
  },
  components: {
    InsuranceItem,
    InsuranceSearch,
    ArchiveItem,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "loadingDownloadImg",
      "selectedId",
      "loadingImg",
    ]),
    ...mapState("user", ["user"]),
    ...mapState("vehicle", [
      "vehicle",
      "insurances",
      "typeVehicles",
      "loadingVehicle",
      "showModalDeleteInsurance",
      "showModalDeleteFile",
      "filtered",
      "selectedFile",
      "archiveID",
      "fileID",
    ]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),
    ...mapGetters("vehicle", ["getFiltered"]),
    filteredInsurance() {
      const a = this.getFiltered || this.insurances;
      return a;
    },
    //##################################################
    //                 REVISIONS
    //##################################################

    validateIsurance() {
      // return this.vehicle.status.insurance.status === "Danger" ||
      //   this.vehicle.status.insurance.status === "Warning"
      //   ? "proximitDate"
      //   : "nothing";
       return alertDate(getLastDate(this.vehicle.insurances, "endDate"), 30)
        ? "proximitDate"
        : "nothing";
    },

    // expireInsurance() {
    //   let today = new Date();
    //   let insurance = getLastDate(this.vehicle.insurances, "endDate");
    //   return insurance < today;
    //   // return this.vehicle.status.insurance.status === "Danger" ? true : false;
    // },
  },
  methods: {
    //######################################################
    //     FILE ARQUIVO PADRÂO
    //######################################################
    getFile(filename, archiveID) {
      this.$store.dispatch("attachment/getFile", {
        archiveID: `${archiveID}`,
        fileName: `${filename}`,
      });
    },
    cleanFile() {
      this.$store.commit("vehicle/UPDATE_SELECTEDFILE", null);
    },
    onFileSelected(event) {
      this.$store.commit("vehicle/UPDATE_SELECTEDFILE", event.target.files[0]);
    },
    onUpload(id) {
      const formData = new FormData();

      formData.append("file", this.selectedFile, this.selectedFile.name);

      this.$store.dispatch("vehicle/onUploadInsurance", {
        data: formData,
        idInsurance: `${id}`,
        idVehicle: this.idVehicle,
        namefile: this.selectedFile.name,
      });
    },
    //######################################################
    //    ARQUIVOS MODAL DELETE
    //######################################################
    openModalDelFileInsurance() {
      this.$store.dispatch("vehicle/openModalDelFileInsurance");
    },
    closeModalDelFileInsurance() {
      this.$store.dispatch("vehicle/closeModalDelFileInsurance");
    },
    //######################################################
    //     ARQUIVOS DELETE
    //######################################################
    confirmDeleteFileInsurance(id, archiveID) {
      this.$store.dispatch("vehicle/confirmDeleteFileInsurance", {
        id: `${id}`,
        archiveID: `${archiveID}`,
      });
    },
    deleteFileInsurance() {
      this.$store.dispatch("vehicle/deleteFileInsurance", {
        idInsurance: this.selectedId,
        archiveID: this.archiveID,
      });
      this.closeModalDelFileInsurance();
    },
    //######################################################
    //    INSURANCE MODAL DELETE
    //######################################################
    openModalDelInsurance() {
      this.$store.dispatch("vehicle/openModalDelInsurance");
    },
    closeModalDelInsurance() {
      this.$store.dispatch("vehicle/closeModalDelInsurance");
    },
    //######################################################
    //    INSURANCE DELETE
    //######################################################
    confirmDeleteInsurance(id) {
      this.$store.dispatch("vehicle/confirmDeleteInsurance", {
        id: `${id}`,
      });
    },
    deleteInsurance() {
      this.$store.dispatch("vehicle/deleteInsurance", {
        id: this.idVehicle,
        idInsurance: this.selectedId.id,
      });
      this.closeModalDelInsurance();
    },
  },

  created() {
    this.$store.dispatch("vehicle/getVehicle", {
      idVehicle: this.idVehicle,
    });
      this.$store.dispatch("vehicle/getInsurances", {
        idVehicle: this.idVehicle,
      });
  },
};
</script>

<style scoped>
.proximitDate {
  color: red;
}
.validate {
  margin-left: 30px;
  color: red;
  font-weight: bold;
  font-size: 14px;
}
.titleRecent {
  font-size: 20px;
  line-height: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 10px 10px;
  color: #ccc;
  font-weight: bold;
}
.clearFile {
  font-size: 24px;
  line-height: 28px;
  margin-left: 10px;
  font-weight: bold;
  color: #00732c;
  padding-top: 5px;
  cursor: pointer;
}
.containerFile {
  display: flex;
  align-content: center;
  align-items: baseline;
  /* grid-gap: 10px; */
  margin: 10px 0 0 0;
}
.inputList {
  display: grid;
}
/*INPUT FILE botão e arquivo */
.inputFile {
  cursor: pointer;
  font-size: 14px;
  width: 240px;
  border: 1px solid #015eb0;
  background: #eff7fe;
  border-radius: 9px;
  padding: 10px;
  color: #1f2b42;
  margin: 3px 10px 0 0;
  border-style: dotted;
}
.spot {
  font-size: 14px;
  color: #015eb0;
}
.itemFile {
  display: flex;
  align-items: center;
  padding: 5px 0 20px 0;
}
.inputNone {
  display: none;
}

.revisionBtns {
  display: grid;
  grid-template-columns: repeat(2, 40px);
  justify-content: flex-end;
  justify-items: flex-end;
  grid-gap: 5px;
  margin-right: 10px;
}
/* .articleInfos, */
.callsHistory {
  margin-top: 20px;
}

li article.articleInfos:nth-child(n + 1) {
  margin-top: 10px;
}

.titleInfos {
  margin-top: 30px;
}

.iconInfoSearchHistory {
  margin: 0 10px;
}
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}

.containerVehicle {
  margin-bottom: 30px;
}
hr {
  padding: 0px;
  margin: 30px 0 20px 0 !important;
  border-top: 5px solid #ccc !important;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}

.noArchive {
  margin: 20px 0 30px 0;
  margin-left: 10px;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .items li {
    padding: 0 0 0 10px;
  }
  .list {
    padding: 0 0 0 10px;
  }
  .inputFile {
    margin: 3px 10px 0 10px;
  }
}
@media screen and (max-width: 390px) {
  .containerFile {
    display: grid;
    grid-gap: 10px;
  }
}
</style>