import { api } from "@/services.js";
// import router from "@/router";
import { removeAccent } from "@/helpers.js";
import { formatDate } from "@/helpers.js";

export default {
  namespaced: true,
  state: {
    //############################
    //       COLLABORATOR            
    //############################
    collaborators: null,
    collaborator: {
      fullName: null,
      username: null,
      password: null,
      nickName: null,
      superUser: false,
      active: true,
      permissions: [],
      addresses: [],
      contacts: [],
    },
    contactID: {
      contactID: null,
    },
    collabActive: null,
    collabDisabled: null,
    //############################
    //         LOADING            
    //############################
    loadingCollaborators: false,
    //############################
    //  EMAILS, PHONES, ADDRESS            
    //############################   
    createContact: false,
    createAddress: false,
    //############################
    //  MODAL            
    //############################
    showModalDelContact: false,
    showModalContact: false,
    // showModalAddress: false,
    //###############
    //  PAGINAÇÃO
    //###############
    totalPages: 0,
    //###############
    //  PESQUISA
    //###############
    searchWord: null,
    filtered: null,
    checkPassword: {
      checkPassword: null,
    },

  },
  //########################################################################################
  //                 GETTERS            
  //########################################################################################
  getters: {
    //###############
    //  PESQUISA
    //###############
    getFiltered: (state) => state.filtered,
    //###############
    //  PAGINAÇÃO
    //###############
    paginatedData(state, getters, rootState) {
      // console.log("rootState", rootState)//acessando o state da global
      let start = (rootState.currentPage - 1) * rootState.limit,
        end = start + rootState.limit;
      return state.collabActive.slice(start, end);
    },

    collaboratorReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "fullName": state.collaborator.fullName,
        "username": state.collaborator.username,
        "password": state.collaborator.password,
        "nickName": state.collaborator.nickName,
        "active": state.collaborator.active,
        "superUser": state.collaborator.superUser,

      }
    },
    collaboratorReset() {
      return {
        fullName: null,
        username: null,
        password: null,
        nickName: null,
        active: true,
        superUser: false,
      }
    },
    checkPasswordReply(state) {
      return {
        "checkPassword": state.checkPassword.checkPassword,
      }
    },
    checkPasswordReset() {
      return {
        checkPassword: null,
      }
    },

    contactIDReply(state) { //Enviar somente estas informações para cadastro e edição.
      return {
        "contactID": state.contactID.contactID,
      }
    },
    contactIDReset() { //Enviar somente estas informações para cadastro e edição.
      return {
        contactID: null,
      }
    },
    otherContacts(state, getters, rootState) {
      let active = null;
      if (rootState.contact.filtered != null) {
        active = rootState.contact.filtered
      } else {
        active = rootState.contact.contacts;
      }
      if (active != null) {
        for (let index in state.collaborator.contacts) {
          active = active.filter(
            (contact) => contact.id != state.collaborator.contacts[index].id
          );
        }
      }
      return active;
    },
    otherAddresses(state, getters, rootState) {
      let active = null;
      if (rootState.address.filtered != null) {
        active = rootState.address.filtered
      } else {
        active = rootState.address.addresses;
        // console.log("otherAddresses", rootState.address.addresses)
      }
      if (active != null) {
        for (let index in state.collaborator.adress) {
          active = active.filter(
            (contact) => contact.id != state.collaborator.adresses[index].id
          );
        }
      }
      return active;
    },
  },
  //########################################################################################
  //                 MUTATIONS            
  //########################################################################################
  mutations: {
    //###############
    //  PESQUISA
    //###############
    RESET_FILTER(state, clean) {
      state.searchWord = clean;
      state.filtered = clean;
    },
    FILTERED_COLLABORATOR(state, word) {
      if (!(word) || word === '{}') {
        state.searchWord = null
        state.filtered = null
      } else {
        state.searchWord = word
        word = removeAccent(word.trim().toLowerCase())
        state.filtered = state.collabActive
          .filter(collaborator => {
            return Object.values(collaborator)
              .join(",")
              .toLowerCase()
              .includes(word);
          })
      }
    },
    //###############
    //  PAGINAÇÃO
    //###############
    UPDATE_TOTAL_PAGES(state, payload) {
      state.totalPages = payload;
    },
    //########################### 
    //    LOADING
    //###########################
    UPDATE_LOADING(state, payload) {
      state.loadingCollaborators = payload;
    },
    //################################################################# 
    //       COLABORADORES E COLABORADORES ATIVOS/DESATIVADOS
    //#################################################################
    UPDATE_COLLABORATORS(state, payload) {
      if (payload !== null)
        state.collaborators = payload.sort((a, b) => {
          let x = a.fullName.toUpperCase(),
            y = b.fullName.toUpperCase();
          return x == y ? 0 : x > y ? 1 : -1;
        });
      else state.collaborators = payload
    },
    UPDATE_COLLABORATOR_ACTIVE(state, payload) {
      state.collabActive = payload.filter((collab) => {
        return collab.active === true;
      })
    },
    UPDATE_COLLABORATOR_DISABLED(state, payload) {
      state.collabDisabled = payload.filter((collab) => {
        return collab.active === false;
      })
    },
    //################################################## 
    //    COLABORADOR
    //##################################################
    UPDATE_COLLABORATOR(state, payload) {
      state.collaborator = Object.assign(state.collaborator, payload);
    },
    UPDATE_CHECKPASSWORD(state, payload) {
      state.checkPassword = Object.assign(state.checkPassword, payload);
    },
    UPDATE_CREATE_CONTACT(state, payload) {
      state.createContact = payload;
    },
    UPDATE_CONTACTID(state, payload) {
      state.contactID = Object.assign(state.contactID, payload);
    },
    UPDATE_CREATE_ADDRESS(state, payload) {
      state.createAddress = payload;
    },

    //###############################################
    //    INFORMAÇÕES DOS COLABORADORES  -  DELETE
    //###############################################
    // UPDATE_DELETE_EMAILS(state, emailsId) {
    //   let emails = state.emails.filter(c => c.id != emailsId)
    //   state.emails = emails;
    // },
    // UPDATE_DELETE_PHONES(state, phonesId) {
    //   let phones = state.phones.filter(c => c.id != phonesId)
    //   state.phones = phones;
    // },
    //###############################################
    //    MODAL
    //###############################################

    // UPDATE_MODAL_EMAIL(state, payload) {
    //   state.showModalEmail = payload;
    // },
    UPDATE_MODAL_CONTACT(state, payload) {
      state.showModalContact = payload;
    },
    UPDATE_MODAL_DEL_CONTACT(state, payload) {
      state.showModalDelContact = payload;
    },
    UPDATE_SELECTED_ID(state, payload) {
      state.selectedId = payload;
    },
  },
  //########################################################################################
  //                 ACTION            
  //########################################################################################
  actions: {

    //##################################################
    //                 COLABORADORES  
    //##################################################
    distribCollaborators(context, collaborators) {
      context.commit("UPDATE_COLLABORATORS", collaborators);
      context.commit("UPDATE_COLLABORATOR_ACTIVE", collaborators);
      context.commit("UPDATE_COLLABORATOR_DISABLED", collaborators);
    },

    getCollaborators(context, refresh, associate) {
      context.commit("RESET_FILTER", null);
      if (!associate) {
        context.commit("UPDATE_LOADING", true);
      }
      if (context.state.collaborators === null || refresh != undefined) {
        api.get(`/user/`)
          .then(
            response => {
              const collaborators = response.data.map(function (collaborator) {
                collaborator.created = formatDate(new Date(collaborator.created));
                collaborator.modified = formatDate(new Date(collaborator.modified));
                return collaborator;
              });
              // console.log(address)
              // context.commit("UPDATE_ADDRESSES", collaborators);
              context.dispatch("distribCollaborators", collaborators);
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("RESET_FILTER", null);
        context.dispatch("distribCollaborators", context.state.collaborators);
        // context.commit("UPDATE_COLLABORATORS", context.state.collaborators);
        context.commit("UPDATE_LOADING", false);
      }
    },

    //##################################################
    //    COLABORADOR - INFORMAÇÕES - ACTIONS
    //##################################################
    getCollaborator(context, { idCollaborator, refreshId, associate }) {
      if (!associate) {
        context.commit("UPDATE_LOADING", true);
      }
      if (context.state.collaborators === null || refreshId != undefined) {
        api.get(`/user/${idCollaborator}`)
          .then(
            response => {
              const collaborator = {}
              Object.keys(response.data).filter(key => {
                // ##### retornando todas as datas formatadas
                return ((key === "created") && (collaborator[key] = formatDate(new Date(response.data[key])))) ||
                  ((key === "modified") && (collaborator[key] = formatDate(new Date(response.data[key])))) ||
                  (collaborator[key] = response.data[key])
              })
              context.commit("UPDATE_COLLABORATOR", collaborator);
              // console.log(collaborator.permissions)
              // context.dispatch("permission/getPermission", { collaborator: collaborator.permissions }, { root: true });
              context.commit("UPDATE_LOADING", false);
            })
          .catch(error => {
            context.commit("UPDATE_LOADING", false);
            console.log(error.response)
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        const collaborator = context.state.collaborators.find(c => c.id == idCollaborator)
        // console.log(contact)
        context.commit("UPDATE_COLLABORATOR", collaborator);
        // context.dispatch("permission/getPermission", { collaborator: collaborator.permissions }, { root: true });
        context.commit("UPDATE_LOADING", false);
      }
    },

    //######################################################################
    //    COLABORADOR - PERMISSIONS - PHONES - ADDRESS E E-MAIL - ACTIONS
    //######################################################################
    resetInfosArray(context,) {
      context.commit("permission/UPDATE_PERMISSIONS", [], { root: true })
      context.commit("UPDATE_PHONES", [])
      context.commit("UPDATE_ADDRESSES", [])
      context.commit("UPDATE_EMAILS", [])
    },

    getCollaboratorInfos(context, idCollaborator) {
      context.dispatch("resetInfosArray");
      // context.dispatch("getCollaborator", idCollaborator);
      context.dispatch("permission/getPermissions", idCollaborator, { root: true });
      context.dispatch("getPhones", idCollaborator);
      context.dispatch("getAddresses", idCollaborator);
      context.dispatch("getEmails", idCollaborator)
    },

    //########################################################################
    //            CADASTRAR COLABORADORES  
    //########################################################################
    addCollaborator(context, { collaborator }) { //usando context por ter tanto "commit" quanto "dispatch"
      context.commit("UPDATE_WAIT", true, { root: true });
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      api.post(`/user/`, collaborator)
        // console.log(collaborator)
        .then(() => {
          context.commit("UPDATE_COLLABORATORS", null);
          context.dispatch("getCollaborators");
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Colaborador adicionado com sucesso!",
          }, { root: true });
          context.commit("UPDATE_COLLABORATOR", this.getters["collaborator/collaboratorReset"]);//limpa o formulário depois de cadastrar        
          context.commit("UPDATE_WAIT", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_WAIT", false, { root: true });
          console.log(error.response)
          if (error.response.data.error === "username, fullName and password can' t be null or empty") {
            context.commit("UPDATE_ERRORS", "full", { root: true });
          }
          if (error.response.data.error === "password must have at least six characters") {
            context.commit("UPDATE_ERRORS", "password", { root: true });
          }
          if (error.response.data.error === "entity_already_exists" || error.response.status === 409) {
            context.commit("UPDATE_ERRORS", "notAvailable", { root: true });
          }
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //##################################################
    //            EDIT COLABORADOR
    //##################################################
    updateCollaborator(context, { checkPassword, collaborator, idCollaborator }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log(collaborator.password);
      // console.log(checkPassword.checkPassword);
      context.commit("UPDATE_WAIT", true, { root: true });
      context.commit("RESET_ERRORS", [], { root: true }); //limpa os erros
      if (collaborator.password == checkPassword.checkPassword) {
        console.log(collaborator)
        api.put(`/user/${idCollaborator}`, collaborator)
          .then(() => {
            context.commit("UPDATE_COLLABORATORS", null);
            context.dispatch("getCollaborator", { idCollaborator, refreshId: "refreshId" });
            context.dispatch("success", {        //cria as informações do texto do toast
              id: Date.now(),
              h1: "Successo!",
              body: "Colaborador editado com sucesso!",
            }, { root: true });
            context.commit("UPDATE_WAIT", false, { root: true });
          })
          .catch(error => {
            console.log(error)
            context.commit("UPDATE_WAIT", false, { root: true });
            if (error.response.data.error === "username, fullName and password can' t be null or empty") {
              context.commit("UPDATE_ERRORS", "full", { root: true });
            }
            if (error.response.data.error === "password must have at least six characters") {
              context.commit("UPDATE_ERRORS", "password", { root: true });
            }
            if (error.response.data.error === "entity_already_exists" || error.response.status === 409) {
              context.commit("UPDATE_ERRORS", "notAvailable", { root: true });
            }
            const status = error.response.status
            context.dispatch("errorStatus", status, { root: true })
          })
      } else {
        context.commit("UPDATE_WAIT", false, { root: true });
        context.commit("UPDATE_ERRORS", "checkPass", { root: true });
      }
    },
    //##############################################################
    //    UPDATE ADDRESS - associando address ao colaborador
    //##############################################################
    updateAddressCollaborator(context, { idModule, idAddress }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("contextUser", context)
      // console.log("idModule", idModule)
      // console.log("idAddress", idAddress)
      context.commit("UPDATE_LOADING_ADDRESS", true, { root: true });
      api.put(`user/${idModule}/address/${idAddress}`)
        .then(() => {
          context.dispatch("getCollaborator", { idCollaborator: idModule, refreshId: "refreshId", address: "address" });
          // context.dispatch("success", {        //cria as informações do texto do toast
          //   id: Date.now(),
          //   h1: "Successo!",
          //   body: "Morada adicionada com sucesso!",
          // }, { root: true });
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    deleteAddressCollaborator(context, { idCollaborator, id }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("context", context)
      // console.log("idCollaborator", idCollaborator)
      // console.log("id", id)
      context.commit("UPDATE_LOADING_ADDRESS", true, { root: true });
      api.delete(`user/${idCollaborator}/address/${id}`)
        .then(() => {
          context.dispatch("getCollaborators", { refresh: "refresh", associate: "associate" });
          context.dispatch("getCollaborator", { idCollaborator, refreshId: "refreshId", address: "address" });
          // context.dispatch("address/deleteAddress", { id }, { root: true })
          // context.dispatch("success", {        //cria as informações do texto do toast
          //   id: Date.now(),
          //   h1: "Successo!",
          //   body: "Morada adicionada com sucesso!",
          // }, { root: true });
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
        })
        .catch(error => {
          context.commit("UPDATE_LOADING_ADDRESS", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },
    //##############################################################
    //    UPDATE CONTACT - associando address ao colaborador
    //##############################################################
    //############################################
    //       MODAL - ADICIONAR CONTACT   
    //############################################
    openModalContact(context) {
      context.commit("UPDATE_MODAL_CONTACT", true);
    },
    closeModalContact(context) {
      context.commit("UPDATE_MODAL_CONTACT", false);
    },


    updateContactCollaborator(context, { idModule, idContact }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("contextUser", context)
      // console.log("idModule", idModule)
      // console.log("idContact", idContact)
      context.commit("contact/UPDATE_LOADING", true, { root: true });
      api.put(`user/${idModule}/contact/${idContact}`)
        .then(() => {
          context.dispatch("getCollaborators", { refresh: "refresh", associate: "associate" });
          context.dispatch("getCollaborator", { idCollaborator: idModule, refreshId: "refreshId", associate: "associate" });
          context.commit("UPDATE_MODAL_CONTACT", false);
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Contacto adicionado com sucesso!",
          }, { root: true });
          context.commit("contact/UPDATE_LOADING", false, { root: true });
        })
        .catch(error => {
          context.commit("contact/UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    },

    //############################################
    //       MODAL - DELETE CONTACT   
    //############################################

    openModalDelContactCollab(context) {
      context.commit("UPDATE_MODAL_DEL_CONTACT", true);
    },
    closeModalDelContactCollab(context) {
      context.commit("UPDATE_MODAL_DEL_CONTACT", false);
    },
    //Delete - Delete 1 de 2
    confirmDeleteContactCollab(context, id) {
      context.commit("UPDATE_SELECTED_ID", id); //armazena o id no selectedId
      context.commit("UPDATE_MODAL_DEL_CONTACT", true);//abre o modal
    },
    //Delete - Delete 2 de 2 - desassociando
    deleteContactCollaborator(context, { idCollaborator, id }) { //usando context por ter tanto "commit" quanto "dispatch"
      // console.log("context", context)
      // console.log("idCollaborator", idCollaborator)
      // console.log("id", id)
      context.commit("contact/UPDATE_LOADING", true, { root: true });
      api.delete(`user/${idCollaborator}/contact/${id}`)
        .then(() => {
          context.dispatch("getCollaborators", { refresh: "refresh", associate: "associate" });
          context.dispatch("getCollaborator", { idCollaborator, refreshId: "refreshId", associate: "associate" });
          context.dispatch("contact/getContacts", { refresh: "refresh" }, { root: true });
          // context.dispatch("contact/deleteContact", { id }, { root: true })
          context.dispatch("success", {        //cria as informações do texto do toast
            id: Date.now(),
            h1: "Successo!",
            body: "Contacto deletado com sucesso!",
          }, { root: true });
          context.commit("contact/UPDATE_LOADING", false, { root: true });
        })
        .catch(error => {
          context.commit("contact/UPDATE_LOADING", false, { root: true });
          console.log(error.response)
          const status = error.response.status
          context.dispatch("errorStatus", status, { root: true })
        })
    }

  }
}