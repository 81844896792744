<template>
  <div>
    <h1>Jurídico</h1>
    <div class="contentGeneral">
      <div class="titleContainer">
        <h3>Editar Tribunal</h3>
      </div>

      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'processos', query: { archived: 'false'} }"  >Lista de Processos</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'tribunais' }"
              >Lista de Tribunais</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'tribunal',
                params: { idProcessCourt: idProcessCourt },
              }"
              >Dados do Tribunal</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Editar Tribunal</span>
          </li>
        </ol>
      </nav>
      <div class="itemFormCollaborator">
        <ProcessCourtForm :processCourt="processCourt" :errors="errors">
          <div slot="cancel"></div>
          <div slot="enviar" class="btnJustify">
            <button class="btn" @click.prevent="updateProcessCourt">
              Gravar
            </button>
          </div>
        </ProcessCourtForm>
      </div>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </div>
</template>

<script>
import ProcessCourtForm from "@/components/legal/ProcessCourtForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "EditProcessCourt",
  props: ["idProcessCourt", "idRouter"],
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("legal", ["processCourt"]),
    ...mapGetters("legal", ["processCourtReply", "processCourtReset"]),
  },
  components: {
    ProcessCourtForm,
  },
  methods: {
    updateProcessCourt() {
      this.$store.dispatch("legal/updateProcessCourt", {
        idProcessCourt: this.idProcessCourt,
        processCourt: this.processCourtReply,
      });
    },
  },
  created() {
    this.$store.commit(
      "legal/UPDATE_MODAL_PROCESS_COURT",
      this.processCourtReset
    );
    this.$store.dispatch("legal/getProcessCourt", {
      idProcessCourt: this.idProcessCourt,
    });
    this.$store.commit("RESET_ERRORS", []);
  },
};
</script>

<style scoped>
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.btnJustify {
  display: grid;
  justify-content: flex-end;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
.itemFormCollaborator {
  margin-left: 10px;
}
</style>