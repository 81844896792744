<template>
  <form action="">
    <div class="container-topSearch">
      <article class="searchContainer formInfos">
        <div>
          <label for="search"> Notas / Km / Custos: </label>
          <div class="searchLupa">
            <input
              type="text"
              name="collaboratorSearch"
              id="search"
              placeholder="Search..."
              v-model.number="searchWord"
              autocomplete="off"
              @keyup.enter="searchRevision"
            />
            <span id="lupa"></span>
          </div>
        </div>
        <div class="dataSearch">
          <label for="revisonStartDate"> Data Início: </label>
          <input
            id="revisonStartDate"
            type="date"
            v-model="revisonStartDate"
            autocomplete="off"
            @keyup.enter="searchRevision"
          />
        </div>
        <div class="dataSearch">
          <label for="revisonEndDate"> Data fim: </label>
          <input
            id="revisonEndDate"
            type="date"
            v-model="revisonEndDate"
            autocomplete="off"
            @keyup.enter="searchRevision"
          />
          <!-- @click="event" -->
        </div>
        <div class="buttonSearch">
          <div
            class="button"
            @keyup.enter="searchRevision"
            @click.prevent="searchRevision"
          >
            <BtnSearch />
          </div>
          <div class="button btnReset" @click.prevent="reset">
            <BtnReset />
          </div>
        </div>
      </article>
      <ul class="searchActiveContainer" v-if="msgFilter">
        <div class="filterActive" v-if="msgFilter">Filtro activo:</div>
        <article v-if="searchWord" class="searchActive">
          <div>Notas / Km / Custos...</div>
          <div class="closeSearch" @click.prevent="closeSearchWord">x</div>
        </article>
        <article v-if="revisonStartDate" class="searchActive">
          <div>Data início</div>
          <div class="closeSearch" @click.prevent="closeStartDate">x</div>
        </article>
        <article v-if="revisonEndDate" class="searchActive">
          <div>Data Fim</div>
          <div class="closeSearch" @click.prevent="closeEndDate">x</div>
        </article>
      </ul>
    </div>
  </form>
</template>

<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState("vehicle", [
      "searchWord",
      "revisonStartDate",
      "revisonEndDate",
    ]),
    msgFilter() {
      return this.searchWord != null ||
        this.revisonStartDate != null ||
        this.revisonEndDate != null
        ? true
        : false;
    },
    searchWord: {
      get() {
        return this.$store.state.vehicle.searchWord;
      },
      set(value) {
        this.$store.commit("vehicle/UPDATE_SEARCHWORD", value);
        // this.$store.commit("vehicle/FILTERED_REVISIONS", value);
      },
    },
    revisonStartDate: {
      get() {
        return this.$store.state.vehicle.revisonStartDate;
      },
      set(value) {
        this.$store.commit("vehicle/UPDATE_STARTDATE_REVISION", value);
        // this.$store.commit("vehicle/FILTERED_VEHICLE_REVISIO_RANGEDATE");
      },
    },
    revisonEndDate: {
      get() {
        return this.$store.state.vehicle.revisonEndDate;
      },
      set(value) {
        this.$store.commit("vehicle/UPDATE_ENDDATE_REVISION", value);
        // this.$store.commit("vehicle/FILTERED_VEHICLE_REVISIO_RANGEDATE");
      },
    },
  },
  methods: {
    closeSearchWord() {
      this.$store.commit("vehicle/UPDATE_SEARCHWORD", null);
      this.searchRevision();
    },
    closeStartDate() {
      this.$store.commit("vehicle/UPDATE_STARTDATE_REVISION", null);
      this.searchRevision();
    },
    closeEndDate() {
      this.$store.commit("vehicle/UPDATE_ENDDATE_REVISION", null);
      this.searchRevision();
    },
    reset() {
      this.$store.commit("vehicle/RESET_FILTER", null);
    },

    searchRevision() {
      // this.$store.commit("vehicle/FILTERED_REVISIONS", this.searchWord);
      this.$store.commit("vehicle/FILTERED_VEHICLE_REVISION", {
        searchWord: this.searchWord,
        startDate: this.revisonStartDate,
        endDate: this.revisonEndDate,
      });
    },
    // event(e) {
    //   // console.log(e.type);
    //   e.type == "click"
    //     ? this.$store.commit("vehicle/RESET_FILTER", null)
    //     : "nothing";
    // },
    // event(e) {
    //   // console.log(e.type);
    //   e.type == "click"
    //     ? this.$store.commit("UPDATE_CURRENTPAGE", 1)
    //     : "nothing";
    // },
  },
};
</script>

<style scoped>
.searchActive {
  display: grid;
  grid-template-columns: minmax(60px, auto) 25px;
  grid-gap: 5px;
  justify-content: center;
  /* box-shadow: 0 4px 8px rgb(31 43 66 / 20%); */
  /* max-width: 320px; */
  border-radius: 4px;
  border: 1px solid rgba(31, 43, 66, 0.4);
  padding: 10px;
  transition: all 0.3s;
  outline: none;
}
.searchActiveContainer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin: 0px 0 30px 0;
  grid-gap: 10px;
  font-size: 0.8rem;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  color: #373946;
  padding: 10px;
  border-radius: 4px;
  /* box-shadow: 0 4px 8px rgb(31 43 66 / 20%); */
  border-radius: 4px;
  border: 1px solid rgba(31, 43, 66, 0.4);
  /* justify-content: center; */
  justify-content: flex-start;
  max-width: 1000px;
}
.closeSearch {
  padding: 0 10px;
  /* padding: 0 10px; */
  border-left: 1px solid #ccc;
  cursor: pointer;
  font-weight: bold;
}

.buttonSearch {
  display: flex;
}
.dataSearch {
  display: grid;
  grid-template-columns: 1fr;
}
.searchLupa {
  margin-top: 5px;
  display: grid;
  position: relative;
}
.container-topSearch {
  display: grid;
  grid-template-columns: minmax(300px, 885px);
  width: 100%;
  padding: 0 10px 40px 0;
  justify-content: flex-start;
  grid-gap: 10px;
}

#search {
  width: 100%;
}
.searchContainer {
  margin-top: 20px;
  display: grid;
  grid-template-columns: 350px repeat(2, 200px) auto;
  grid-gap: 10px;
  align-items: flex-end;
}
#lupa {
  width: 62px;
  height: 46px;
  background: url("../../assets/svg/search2.svg") no-repeat center center;
  border: none;
  position: absolute;
  top: 0px;
  right: 0px;
}

@media screen and (max-width: 690px) {
  .searchContainer {
    grid-template-columns: 1fr;
    margin-left: 10px;
  }
  .btnReset {
    justify-self: end;
  }
  .buttonSearch {
    width: 100%;
    justify-content: flex-end;
    justify-items: flex-end;
  }
}
</style>