<template>
  <section>
    <!-- v-if="statusInspection && statusInsurance" -->
    <!-- <section class="itemContainer"> -->
    <!-- <router-link
      v-if="vehicleAccess || vehicleManager || vehicleAnalysis || vehicleEdit"
      :to="{
        name: 'viatura',
        params: {
          idVehicle: vehicle.id,
        },
      }"
    > -->
    <div>
      <div class="collabIten">
        <div>
          <h4>{{ vehicle.registration }}</h4>
        </div>
        <hr />
        <router-link
          v-if="
            vehicleAccess || vehicleManager || vehicleAnalysis || vehicleEdit
          "
          :to="{
            name: 'viatura',
            params: {
              idVehicle: vehicle.id,
            },
          }"
        >
          <div v-if="statusInspection">
            <h5>Próxima Inspeção</h5>
            <p
              v-if="
                vehicle.nextInspectionDate &&
                vehicle.nextInspectionDate.length > 0
              "
            >
              <span v-if="expireNextRevision">
                <span class="proximitDate">{{
                  vehicle.nextInspectionDate | formatDateReverse
                }}</span
                ><br />
                <span class="validateNextRevision">{{
                  vehicle.nextInspectionDate | expireDate
                }}</span>
              </span>
              <span v-else :class="validateNextRevison">
                {{ vehicle.nextInspectionDate | formatDateReverse }}
              </span>
            </p>
            <p v-else class="iconNull">
              <span
                ><svg
                  class="iconNull"
                  xmlns="http://www.w3.org/2000/svg"
                  width="19.386"
                  height="19.47"
                  viewBox="0 0 19.386 19.47"
                >
                  <g transform="translate(-202.462 -30.337)">
                    <path
                      class="a"
                      d="M221.844,40.482c.009-.233,0-.467-.006-.7a9.037,9.037,0,0,0-2.685-6.462,11.633,11.633,0,0,0-1.669-1.373,9.161,9.161,0,0,0-3.638-1.455c-.042-.007-.085-.011-.127-.018-.22-.034-.439-.068-.659-.087a9.435,9.435,0,0,0-2.022.013,9.813,9.813,0,0,0-6.958,4.227h.046a9.875,9.875,0,0,0-.355,10.4,1.439,1.439,0,0,0,.319.537l.082.1a9.489,9.489,0,0,0,1.906,2.029c.086.068.179.123.267.188a8.924,8.924,0,0,0,5.611,1.927c.241,0,.488-.02.734-.037a9.57,9.57,0,0,0,1.3-.147l-.007-.007c.043-.007.084-.01.127-.018a9.673,9.673,0,0,0,7.426-7.082,1.339,1.339,0,0,0,.178-.631c.005-.051.011-.1.017-.149.014-.1.016-.2.027-.295a9.317,9.317,0,0,0,.086-.93ZM214.018,48.3a7.816,7.816,0,0,1-1.169.188l-.023-.023a8.54,8.54,0,0,1-2.953-.28c-.129-.037-.258-.075-.386-.118-.184-.06-.366-.127-.545-.2a8.384,8.384,0,0,1-.8-.377c-.056-.03-.114-.056-.169-.087a8.4,8.4,0,0,1-.834-14.074,8.4,8.4,0,0,1,8.172-1.044c.109.045.218.089.324.138.142.064.279.143.418.214A8.436,8.436,0,0,1,214.018,48.3Z"
                    />
                    <path
                      class="a"
                      d="M215.907,42.94,213.863,40.9c-.005-.005-.013-.007-.018-.012s-.007-.013-.013-.019l-.426-.426-.367-.367.367-.367.426-.426c.006-.006.007-.013.013-.019s.013-.007.018-.012l2.044-2.044a.625.625,0,0,0-.884-.884l-2.044,2.044c-.005.005-.007.013-.012.019s-.013.006-.019.012l-.426.426-.367.367-.366-.367-.427-.426c-.005-.006-.013-.007-.018-.012s-.007-.014-.013-.019l-2.044-2.044a.625.625,0,1,0-.883.884l2.043,2.044c.006,0,.013.007.019.012s.007.013.012.019l.427.426.366.367-.366.367-.427.426c0,.006-.007.013-.012.019s-.013.007-.019.012L208.4,42.94a.625.625,0,1,0,.883.884l2.044-2.044c.006-.006.007-.013.012-.019s.014-.007.019-.012l.427-.427.366-.366.367.366.426.427c.006.005.013.007.019.012s.007.013.012.019l2.044,2.044a.625.625,0,0,0,.884-.884Z"
                    />
                  </g></svg
              ></span>
            </p>
          </div>
        </router-link>
        <hr v-if="statusInspection && statusInsurance" />
        <!-- <div v-if="expireInsurance || validateIsurance == 'proximitDate'"> -->
        <router-link
          v-if="
            vehicleAccess || vehicleManager || vehicleAnalysis || vehicleEdit
          "
          :to="{
            name: 'seguros-viatura',
            params: {
              idVehicle: vehicle.id,
            },
          }"
        >
          <div v-if="statusInsurance">
            <h5>Validade de Seguro</h5>
            <div v-if="vehicle.insurances && vehicle.insurances.length > 0">
              <p>
                <span v-if="expireInsurance">
                  <span class="proximitDate">
                    {{
                      vehicle.insurances
                        | getLastDate("startDate")
                        | formatDateReverse
                    }}
                    até
                    {{
                      vehicle.insurances
                        | getLastDate("endDate")
                        | formatDateReverse
                    }}
                  </span>
                  <span class="validate">
                    {{
                      vehicle.insurances | getLastDate("endDate") | expireDate
                    }}
                  </span>
                </span>
                <span v-else :class="validateIsurance">
                  {{
                    vehicle.insurances
                      | getLastDate("startDate")
                      | formatDateReverse
                  }}
                  até
                  {{
                    vehicle.insurances
                      | getLastDate("endDate")
                      | formatDateReverse
                  }}
                </span>
              </p>
            </div>
            <div
              v-else-if="
                vehicle.status.insurance.reason === 'Automovel sem seguro!'
              "
              class="iconNull"
            >
              <span class="dangerInsurance"> Atenção, sem seguro!</span>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <!-- </router-link> -->
  </section>
</template>  

<script>
import { getLastDate } from "@/helpers.js";
// import { formatDate } from "@/helpers.js";
import { alertDate } from "@/helpers.js";
// import { formatDateString } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "DsVehicle",
  props: ["vehicle", "idVehicle"],
  computed: {
    ...mapState("user", ["user"]),
    ...mapState("vehicle", ["vehicles", "typeVehicles"]),
    ...mapGetters("user", [
      "vehicleAccess",
      "vehicleManager",
      "vehicleAnalysis",
      "vehicleEdit",
    ]),

    statusInspection() {
      return (
        this.vehicle.status.inspection.status === "Danger" ||
        this.vehicle.status.inspection.status === "Warning"
      );
    },

    statusInsurance() {
      return (
        this.vehicle.status.insurance.reason === "Automovel sem seguro!" ||
        this.vehicle.status.insurance.status === "Danger" ||
        this.vehicle.status.insurance.status === "Warning"
      );
    },

    expireInsurance() {
      let today = new Date();
      let insurance = getLastDate(this.vehicle.insurances, "endDate");
      return insurance < today;
    },

    expireNextRevision() {
      return this.vehicle.status.inspection.status === "Danger" ? true : false;
    },

    validateNextRevison() {
      //   Se data de inspeção expirou (hj > inspection date) emite status Danger
      // Se data de inspeção está está até 30 dias do limite emite status Warning
      // Se data de inspeção está mais de 30 dias do limite emite status OK

      return this.vehicle.status.inspection.status === "Danger" ||
        this.vehicle.status.inspection.status === "Warning"
        ? "proximitDate"
        : "nothing";
    },

    validateIsurance() {
      return alertDate(getLastDate(this.vehicle.insurances, "endDate"), 30)
        ? "proximitDate"
        : "nothing";
    },
  },
};
</script>

<style scoped>
.proximitDate {
  color: red;
}
.dangerInsurance {
  color: red;
  font-size: 16px;
  line-height: 21px;
}
#insurance.validate {
  margin: 0;
  display: flex;
  justify-content: center;
  max-width: 190px;
}
.validate {
  /* margin-left: 17%; */
  color: red;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}
.validateNextRevision {
  /* margin-left: 17%; */
  color: red;
  font-weight: bold;
  font-size: 14px;
  text-align: center;
}

.iconNull {
  fill: #88898f;
  /* margin-left: 35px; */
  /* text-align: center; */
}
.telegramUsers {
  color: #1f2b42;
  font-size: 16px;
  line-height: 21px;
}

hr {
  margin: 10px 0;
  padding: 0;
}

h4 {
  line-height: 0;
  font-size: 20px;
  margin: 20px 0;
}

h5 {
  /* line-height: 0; */
  font-size: 20px;
  margin: 10px 0;
  font-weight: 400;
}
.collabIten {
  display: grid;
  justify-content: center;
  justify-items: center;
  text-align: center;
}

.collabConfig {
  display: grid;
  grid-template-columns: 50px;
  text-align: left;
  align-items: center;
  font-size: 0.8rem;
  padding: 5px 0;
}

@media screen and (max-width: 695px) {
}
</style>
