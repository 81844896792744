<template>
  <form action="">
    <div v-if="wait">
      <Loading />
    </div>
    <div v-else>
      <div class="formItens">
        <label for="Name"> Nome </label>
        <input
          :class="validateFull"
          id="Name"
          name="Name"
          type="text"
          v-model="name"
        />
      </div>
      <div v-if="!errors.indexOf('full')" class="notify">
        O nome é um campo requerido
      </div>
      <div class="formItens">
        <label for="description"> Descrição </label>
        <input
          :class="validateDescription"
          id="description"
          name="description"
          type="text"
          v-model="description"
        />
      </div>
      <div v-if="!errors.indexOf('description')" class="notify">
        A descrição é um campo requerido
      </div>
      <ul class="addItems">
        <li class="items">
          <label for="email"> Telefone </label>
          <ul class="items">
            <div v-if="loadingPhone">
              <p><LoadingCircle /></p>
            </div>
            <div v-else>
              <div v-if="contact.phones && contact.phones.length > 0">
                <li
                  class="textGray"
                  v-for="(phone, index) in contact.phones"
                  :key="phone"
                >
                  <PhoneItem :phone="phone" :index="index">
                    <button
                      slot="delete"
                      class="delete"
                      @click.prevent="confirmDeletePhone(index)"
                    ></button>
                  </PhoneItem>
                </li>
                <div v-if="!errors.indexOf('phoneMsg')" class="notify2">
                  O telefone ( {{ repeatPhone }} ) já existe.
                </div>
              </div>
              <div v-else>
                <p class="textGray">Não possui telefones</p>
              </div>
            </div>
            <transition mode="out-in">
              <li>
                <div class="formInfos2">
                  <div v-if="loadingPhone">
                    <p><LoadingCircle /></p>
                  </div>
                  <input
                    v-else
                    id="phone"
                    name="phone"
                    type="text"
                    v-model="phone"
                    :class="[validatePhoneEmpty, validadeContactRequired]"
                  />
                  <div>
                    <button class="buttonAdd" @click.prevent="addPhone">
                      <svg
                        class="icon-add"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                      </svg>
                    </button>
                  </div>
                </div>
                <div v-if="!errors.indexOf('validPhone')" class="notify">
                  Utilize um telefone válido.
                </div>
              </li>
            </transition>
          </ul>
        </li>
        <li class="items">
          <label for="email"> E-mail </label>
          <ul class="items">
            <div v-if="loadingEmail">
              <p><LoadingCircle /></p>
            </div>
            <div v-else>
              <div v-if="contact.emails && contact.emails.length > 0">
                <li
                  class="textGray"
                  v-for="(email, index) in contact.emails"
                  :key="email"
                >
                  <EmailItem :email="email">
                    <button
                      slot="delete"
                      class="delete"
                      @click.prevent="confirmDeleteEmail(index)"
                    ></button>
                  </EmailItem>
                </li>
              </div>
              <div v-else>
                <p class="textGray">Não possui e-mails</p>
              </div>
            </div>
            <transition mode="out-in">
              <li>
                <div class="formInfos2">
                  <div v-if="loadingEmail">
                    <p><LoadingCircle /></p>
                  </div>
                  <input
                    v-else
                    id="email"
                    name="email"
                    type="text"
                    v-model="email"
                    :class="[validateEmail, validadeContactRequired]"
                  />
                  <div class="">
                    <button class="buttonAdd" @click.prevent="addEmail">
                      <svg
                        class="icon-add"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                      >
                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
                      </svg>
                    </button>
                  </div>
                  <div v-if="!errors.indexOf('emailRequired')" class="notify">
                    Utilize um e-mail válido.
                  </div>
                </div>
              </li>
            </transition>
          </ul>
        </li>
      </ul>
    </div>
    <div v-if="!errors.indexOf('contactRequired')" class="notify">
      É obrigatório ter pelo menos um telefone ou e-mail.
    </div>
    <div class="button">
      <slot name="cancel"></slot>
      <slot name="enviar"></slot>
    </div>
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeletePhone"
        @click="closeModalDelPhone"
      ></div>
      <div class="modalDel_container" v-if="showModalDeletePhone">
        <button class="close btn" @click="closeModalDelPhone">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este telefone?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelPhone"
          />
          <button class="btnDel" @click.prevent="deletePhone">Delete</button>
        </div>
      </div>
    </div>
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteEmail"
        @click="closeModalDelEmail"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteEmail">
        <button class="close btn" @click="closeModalDelEmail">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este e-mail?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelEmail"
          />
          <button class="btnDel" @click.prevent="deleteEmail">Delete</button>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import EmailItem from "@/components/contact/EmailItem.vue";
import PhoneItem from "@/components/contact/PhoneItem.vue";
import { mapFields } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  props: ["errors"],
  data() {
    return {
      phone: [],
      email: [],
    };
  },
  components: {
    EmailItem,
    PhoneItem,
  },

  computed: {
    ...mapState(["wait", "loadingEmail", "loadingPhone"]),
    ...mapState("contact", [
      "contact",
      "showModalDeleteEmail",
      "showModalDeletePhone",
      "selectedId",
      "repeatPhone",
    ]),
    ...mapGetters("contact", ["contactReset", "contactEmailPhoneReset"]),
    validateFull() {
      return this.errors.indexOf("full") ? "true" : "validate";
    },
    validateDescription() {
      return this.errors.indexOf("description") ? "true" : "validate";
    },
    validatePhone() {
      return this.errors.indexOf("phoneMsg") ? "true" : "validate";
    },
    validatePhoneEmpty() {
      return this.errors.indexOf("validPhone") ? "true" : "validate";
    },
    validateEmail() {
      return this.errors.indexOf("emailRequired") ? "true" : "validate";
    },
    validadeContactRequired() {
      return this.errors.indexOf("contactRequired") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["name", "description", "phones"], //nome dos campos
      module: "contact", //qual módulo está acessando
      base: "contact", //colocar o state que vai receber
      mutation: "contact/UPDATE_CONTACT", //nome da mutation que vai alterar o state
    }),
  },
  methods: {
    validEmail: function (email) {
      var re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },
    addPhone() {
      // console.log();
      this.$store.commit("RESET_ERRORS", []);
      if (this.phone.length > 13 || this.phone.length < 12) {
        this.$store.commit("UPDATE_ERRORS", "validPhone", { root: true });
      } else {
        this.$store.commit("contact/ADD_PHONE", this.phone);
        this.$store.commit("RESET_ERRORS", []);
        this.phone = [];
      }
    },
    addEmail() {
      this.$store.commit("RESET_ERRORS", []);
      if (!this.email || !this.validEmail(this.email)) {
        this.$store.commit("UPDATE_ERRORS", "emailRequired", { root: true });
      } else {
        this.$store.commit("contact/ADD_EMAIL", this.email);
        this.$store.commit("RESET_ERRORS", []);
        this.email = [];
      }
    },
    confirmDeletePhone(index) {
      console.log(index);
      this.$store.dispatch("contact/confirmDeletePhone", index);
    },
    confirmDeleteEmail(index) {
      this.$store.dispatch("contact/confirmDeleteEmail", index);
    },
    // confirmDeletePhone(index) {
    //   this.$store.commit("contact/REMOVE_PHONE", index);
    // },
    // confirmDeleteEmail(index) {
    //   this.$store.commit("contact/REMOVE_EMAIL", index);
    // },
    //############################################
    //       MODAL
    //############################################
    openModalDelPhone() {
      this.$store.dispatch("contact/openModalDelPhone");
    },
    closeModalDelPhone() {
      this.$store.dispatch("contact/closeModalDelPhone");
    },
    openModalDelEmail() {
      this.$store.dispatch("contact/openModalDelEmail");
    },
    closeModalDelEmail() {
      this.$store.dispatch("contact/closeModalDelEmail");
    },
    //############################################
    //       DELETE
    //############################################
    //Delete PHONE - Delete 1 de 2
    deletePhone() {
      this.$store.commit("contact/REMOVE_PHONE", { index: this.selectedId });
      this.closeModalDelPhone();
      this.$store.commit("RESET_ERRORS", [], { root: true });
      this.$store.commit("contact/UPDATE_PHONE_REPEAT", null);
      this.phone = [];
    },
    //DeleteEMAIL - Delete 1 de 2
    deleteEmail() {
      this.$store.commit("contact/REMOVE_EMAIL", { index: this.selectedId });
      this.closeModalDelEmail();
      this.$store.commit("RESET_ERRORS", [], { root: true });
      this.email = [];
    },
  },
  created() {
    this.phone = [];
    this.email = [];
    this.$store.commit("contact/UPDATE_PHONE_REPEAT", null);
    this.$store.commit("RESET_ERRORS", [], { root: true });
    this.$store.commit("contact/UPDATE_CONTACT", this.contactReset);
  },
};
</script>

<style scoped>
.icon-add {
  fill: #fff;
  /* stroke: #fff; */
  z-index: 99;
}
form {
  display: grid;
  grid-template-columns: minmax(300px, 800px);
  align-items: center;
  justify-content: center;
  /* margin-top: 20px; */
  padding: 0 20px;
}

.formItens {
  grid-template-columns: 100px 1fr;
}

.button {
  display: flex;
  grid-template-columns: 300px;
  padding: 20px;
  /* grid-column:1fr 1fr; */
  justify-content: center;
  grid-gap: 30px;
  margin: 20px auto;
}

.buttonAdd {
  display: grid;
  justify-content: center;
  align-items: center;
  /* margin-left: 60px; */
  background-color: #00bf48;
  border: none;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border-radius: 4px;
  margin: 0 15px;
  overflow: hidden;
  /* fill: #fff; */
  color: #ffffff;
}

li.items {
  display: grid;
  grid-template-columns: 105px 1fr;
  margin-top: 20px;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

.formInfos2,
.formInfos {
  display: grid;
  grid-template-columns: minmax(280px, 550px) 100px;
  justify-content: flex-start;
  grid-gap: 5px;
  align-items: center;
  margin-top: 20px;
  align-content: center;
}

@media screen and (max-width: 560px) {
  li.items,
  .formItens {
    grid-template-columns: 1fr !important;
  }
  .button {
    grid-template-columns: 1fr !important;
  }
  .notifyCenter {
    /* justify-items: center; */
    color: #dc3545;
    font-size: 1.2rem;
    margin-bottom: 15px;
    margin-left: 85px;
  }

  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: 10px;
  }
}
</style>