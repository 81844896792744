<template>
  <section class="">
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Dados da Marca</h3>
        <div v-if="vehicleManager || vehicleEdit">
          <router-link
            class="collabItens"
            :to="{
              name: 'editar-marca',
              params: {
                idBrand: idBrand,
              },
            }"
          >
            <AddUser>
              <span slot="title">
                Editar<br />
                Marcar</span
              >
            </AddUser>
          </router-link>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'marcas' }"
              >Lista de Marcas</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Dados da Marca</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingVehicle">
        <Loading />
      </div>
      <div v-else>
        <article v-if="brand">
          <!-- <pre>{{ brand }}</pre> -->
          <ul class="borderTable">
            <li class="items itemList" v-if="brand.name">
              <span class="titleBlue">Nome</span>
              <div class="list">
                <span class="textGray">{{ brand.name }}</span>
              </div>
            </li> 
            <li class="items itemList" v-if="brand.created">
              <span class="titleBlue">Criado</span>
              <div class="list">
                <span class="textGray">{{
                  brand.created | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ createdVehicle }}</span> -->
              </div>
            </li>
            <li class="items itemList" v-if="brand.modified">
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <span class="textGray">{{
                  brand.modified | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ modifiedVehicle }}</span> -->
              </div>
            </li>
          </ul>   
          <h3 class="titleInfos">Modelos Associados</h3>   
          <div v-if="brand.models && brand.models.length > 0">
              <ul v-for="(model, i) in brand.models" :key="i">
                <router-link
                  class=""
                  :to="{ name: 'modelo', params: { idModel: model.id } }"
                >
                  <div class="itemListVehicle">
                    <li class="items collabItens">
                      <span class="titleBlue">Modelo</span>
                      <div class="list">
                        <span class="textGray">{{ model.name }}</span>
                      </div>
                    </li>
                    <!-- <button
                      slot="delete"
                      class="delete"
                      @click.prevent="confirmDeleteModel(model.id)"
                    ></button> -->
                  </div>
                </router-link>
              </ul>
            </div>
            <div v-else>
              <span class="itemList textGray item2"
                >Não possui marcas associadas</span
              >
            </div>
        </article>
      </div>
    </div>
    <NotifySuccess :items="items" />
  <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalDeleteModel"
        @click="closeModalDelModel"
      ></div>
      <div class="modalDel_container" v-if="showModalDeleteModel">
        <button class="close btn" @click="closeModalDelModel">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este modelo de viatura?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelModel"
          />
          <button class="btnDel" @click.prevent="deleteModel">
            Delete
          </button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "ContactoInternoInfos",
  props: ["idBrand"],
  data() {
    return {
      searchHistory: false,
    };
  },

  computed: {
    ...mapState(["errors", "items", "itemsError", "selectedId"]),
    ...mapState("user", ["user"]),
    ...mapState("vehicle", [
      "brand",
      "models",
      "brands",
      "loadingVehicle",
      "showModalDeleteModel",
    ]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),    
    //##################################################
    //                 BRAND
    //##################################################
    modelName() {
      if (this.brands != null) {
        return this.brands.find((brand) => {
          return brand.id == this.model.brandID;
        });
      } else {
        return "";
      }
    },
   
   
  },
  methods: {
    //######################################################
    //     DELETE
    //######################################################
    openModalDelModel() {
      this.$store.dispatch("vehicle/openModalDelModel");
    },
    closeModalDelModel() {
      this.$store.dispatch("vehicle/closeModalDelModel");
    },
    confirmDeleteModel(id) {
      // console.log("id", id)
      this.$store.dispatch("vehicle/confirmDeleteModel", {
        id: `${id}`,
      });
    },
    deleteModel() {
      this.$store.dispatch("vehicle/deleteModel", {
        id: this.selectedId.id,
      });
      this.closeModalDelModel();
    },
  },
  created() {
    this.$store.dispatch("vehicle/getBrand", {
      idBrand: this.idBrand
    });
  },
};
</script>

<style scoped>
.itemListVehicle {
  padding: 9px 0;
  display: grid;
  grid-template-columns: 1fr 50px;
  align-items: center;
  /* justify-content: space-between; */
  border-bottom: 1px solid #cbcbcb;
}
button.btn-form {
  margin: 20px auto;
}
.collabItens:hover {
  background: #dbebf3;
}

.collabItens {
  margin: 10px 0;
}
.borderTable {
  margin: 20px 0;
  box-shadow: 0 4px 8px rgb(30 60 90 / 20%);
  padding: 20px 10px;
  border-radius: 9px;
}

.item2 {
  padding-left: 15px;
}
.containerLink {
  display: flex;
  align-items: center;
  margin-left: 15px;
}
.articleInfos,
.callsHistory {
  margin-bottom: 30px;
}

.titleInfos {
  margin-bottom: 10px;
}

.iconInfoSearchHistory {
  margin: 0 10px;
}
.otherService,
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 250px 1fr;
  align-items: center;
}

hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 220px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
}
</style>
