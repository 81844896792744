<template>
  <section v-if="address">
    <h1>Moradas</h1>
    <div class="contentGeneral">
      <h3>Adicionar Morada</h3>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'moradas' }"
              >Lista de Moradas</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span>Adicionar Moradas</span>
          </li>
        </ol>
      </nav>
      <transition mode="out-in">
        <AddressForm :address="address" :errors="errors">
          <div slot="delete" id="address">
            <button class="btn btn-form" @click.prevent="addAddress">
              Adicionar
            </button>
          </div>
        </AddressForm>
      </transition>
      <NotifySuccess :items="items" />
      <NotifyError :itemsError="itemsError" />
    </div>
  </section>
</template>

<script>
import AddressForm from "@/components/address/AddressForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddressAdd",
  props: ["idAddress"],
  data() {
    return {
      create: false,
    };
  },
  components: {
    AddressForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("address", ["address"]),
    ...mapGetters("address", ["addressReset", "addressReply"]),
  },
  methods: {
    addAddress() {
      this.$store.dispatch("address/addAddress", {
        address: this.addressReply, //enviar getters com as infos necessárias para o cadastro
        // idModule: this.idCollaborator,
        // mutation: "'collaborator/updateAddressCollaborator'",
      });
    },
  },
  created() {
    this.$store.commit("address/UPDATE_ADDRESS", this.addressReset);
    this.$store.commit("address/UPDATE_CONFIRMFORM", false);
  },
};
</script>

<style scoped>
#address {
  grid-column: 2;
}
.contentGeneral {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
h3 {
  padding: 30px 0 0 0;
  text-align: left;
  margin-left: 10px;
}
</style>
