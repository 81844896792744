<template>
  <section class="itemContainer" v-if="processItem">
    <div
      v-if="processItem.type == 'note' && newData != null"
      :id="newData.id"
    ></div>
    <i class="icon"><img :src="getImgUrl(processItem.img)" /></i>
    <div class="details">
      <div class="textBlueDark">
        <span :class="processItem.classOperation" class="bold"
          >{{ processItem.operation }}
        </span>
      </div>
      <div class="textGray">
        <span> {{ processItem.date | formatDateReverse }}</span>
      </div>
    </div>
    <hr />
    <div v-if="processItem.type == 'archived'">
      <div>
        <span class="title textRed" v-if="this.processItem.newData == 'true'">{{
          processItem.nameProcess
        }}</span>
        <span
          class="title textGreen"
          v-if="this.processItem.newData == 'false'"
          >{{ processItem.nameProcess2 }}</span
        >
      </div>
    </div>
    <div class="details" v-else>
      <span class="title">{{ processItem.nameProcess }}</span>
    </div>

    <p class="textBlueDark">
      <span class="titleBlue">Usuário: </span>
      {{ processItem.username }}
    </p>

    <!-- ################################
    #     DADOS PROCESS  STARTDATE
    ################################# -->

    <div v-if="processItem.type == 'startDate'">
      <div v-if="processItem.newData != null">
        <span class="titleBlue bold">Atual: </span>
        <span class="textBlueDark">{{
          processItem.newData | formatDateString
        }}</span>
      </div>
      <div v-if="processItem.oldData != null" class="oldData">
        <hr />
        <span class="bold">Antigo: </span>
        <span>{{ processItem.oldData | formatDateString }}</span>
      </div>
    </div>

    <!-- ################################
    #         DADOS PROCESS NUMBER
    ################################# -->
    <div v-if="processItem.type == 'processNumberID'">
      <div v-if="processItem.newData != null">
        <span class="titleBlue bold">Atual: </span>
        <span class="textBlueDark">{{ processItem.newData }}</span>
      </div>
      <div v-if="processItem.oldData != null" class="oldData">
        <hr />
        <span class="bold">Antigo: </span>
        <span>{{ processItem.oldData }}</span>
      </div>
    </div>

    <!-- ################################
    #            DADOS VALUE
    ################################# -->
    <div v-if="processItem.type == 'value'">
      <div v-if="processItem.newData != null">
        <span class="titleBlue bold">Atual: </span>
        <span class="textBlueDark">{{
          processItem.newData | numberPrice
        }}</span>
      </div>
      <div v-if="processItem.oldData != null" class="oldData">
        <hr />
        <span class="bold">Antigo: </span>
        <span>{{ processItem.oldData | numberPrice }}</span>
      </div>
    </div>
    <!-- ################################
    #            DADOS TAX
    ################################# -->
    <!-- <pre>
    {{ newData }}
    </pre> -->
    <div v-if="processItem.type == 'tax'">
      <div v-if="newData != null">
        <span class="titleBlue bold">Atual: </span>
        <div class="subItemTax">
          <span class="textBlueDark" v-if="newData.value"
            >Valor: {{ newData.value | numberPrice }}</span
          ><br />
          <span
            class="textBlueDark"
            v-if="newData.taxTypeID && taxType != null"
            >Tipo de Taxa: {{ taxType.name }}</span
          ><br />
          <span class="textBlueDark" v-if="newData.paymentDate != null"
            >Data do pagamento:
            {{ newData.paymentDate | formatDateString }}</span
          ><br />
        </div>
          <article class="fileSubItem">
              <div v-if="loadingImg"></div>
              <div v-else>
                <div v-if="newData.files != null && newData.files.length > 0">
                  <span class="textBlueDark">Ficheiro: </span>
                  <article class="downloadImgLoading" v-if="loadingDownloadImg">
                    Aguarde, fazendo download...
                  </article>
                  <ul v-else>
                    <li v-for="archive in newData.files" :key="archive.id">
                      <article class="articleInfos">
                       <!-- {{archive.id}}
                       {{newData.id}} -->
                        <ArchiveItem
                          :archive="archive"
                          :idMicroservice="newData.id"
                          @getFile="getFile(archive.filename, archive.id)"
                        >
                          <button
                            slot="delete"
                            class="delete"
                            @click.prevent="
                              deleteFileProcessTax(archive.id, newData.id)
                            "
                          ></button>
                        </ArchiveItem>
                      </article>
                    </li>
                  </ul>
                </div>
                <div v-else>
                  <p class="noResults noArchive">
                    Não possui ficheiros registrados
                  </p>
                </div>
              </div>
            </article>
        
        <div class="items fileSelect" v-if="taxIconNull != false">
          <span class="titleBlue bold">Ficheiro: </span>

          <div v-if="loadingImg">
            <LoadingCircle />
          </div>

          <div v-else>
            <div v-if="processEdit || processManager" class="fileSelect">
              <div class="inputList">
                <label for="fileInput" class="inputFile"
                  >Selecione um ficheiro
                  <span class="spot">clique aqui</span></label
                >
                <span v-if="selectedFile != null" class="itemFile"
                  >{{ selectedFile.name
                  }}<span @click.prevent="cleanFile($event)" class="clearFile"
                    >&#215;</span
                  ></span
                >
                <input
                  type="file"
                  @change="onFileSelected"
                  id="fileInput"
                  class="inputNone"
                />
              </div>
              <button
                class="btn addFile fileAddSelect"
                @click="onUpload(newData.id)"
              >
                Adicionar
              </button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="oldData != null" class="oldData">
        <hr />
        <span class="bold">Antigo: </span> <br />
        <div class="subItem">
          <span v-if="oldData.value"
            >Valor: {{ oldData.value | numberPrice }}</span
          ><br />
          <span
            class="textBlueDark"
            v-if="oldData.taxTypeID && oldTaxType != null"
            >Tipo de Taxa: {{ oldTaxType.name }}</span
          ><br />
          <span class="textBlueDark" v-if="oldData.taxTypeID"
            >Data do pagamento:
            {{ oldData.paymentDate | formatDateString }}</span
          >
        </div>
      </div>
    </div>

    <!-- ################################
    #          DADOS ATTACMENT 
    ################################# -->
    <!-- {{ newData }} -->
    <div v-if="processItem.type == 'attachment'">
      <div v-if="newData != null">
        <span class="titleBlue bold">Atual: </span>
        <div class="subItem">
          <span class="textBlueDark" v-if="newData"
            >Ficheiro: {{ newData.filename }}</span
          >
        </div>
      </div>
      <div v-if="oldData != null" class="oldData">
        <hr />
        <span class="bold">Antigo: </span> <br />
        <div class="subItem">
          <span v-if="oldData">Ficheiro: {{ oldData.filename }}</span>
        </div>
      </div>
    </div>

    <!-- ################################
    #          DADOS COURT 
    ################################# -->
    <!-- {{ newData }} -->
    <div v-if="processItem.type == 'court'">
      <div v-if="newData != null">
        <span class="titleBlue bold">Atual: </span>
        <div class="subItem">
          <span class="textBlueDark" v-if="newData.name"
            >Nome: {{ newData.name }}</span
          >
        </div>
      </div>
      <div v-if="oldData != null" class="oldData">
        <hr />
        <span class="bold">Antigo: </span> <br />
        <div class="subItem">
          <span v-if="oldData.name">Nome: {{ oldData.name }}</span>
        </div>
      </div>
    </div>

    <!-- ################################
    #          DADOS PROCESS TYPE 
    ################################# -->
    <!-- {{ newData }} -->
    <div v-if="processItem.type == 'processType'">
      <div v-if="newData != null">
        <span class="titleBlue bold">Atual: </span>
        <div class="subItem">
          <span class="textBlueDark" v-if="newData.name"
            >Nome: {{ newData.name }}</span
          >
          <span class="textBlueDark" v-if="newData.description"
            >Descrição: {{ newData.description }}</span
          >
        </div>
      </div>
      <div v-if="oldData != null" class="oldData">
        <hr />
        <span class="bold">Antigo: </span> <br />
        <div class="subItem">
          <span v-if="oldData.name">Nome: {{ oldData.name }}</span>
          <span v-if="oldData.description"
            >Descrição: {{ oldData.description }}</span
          >
        </div>
      </div>
    </div>

    <!-- ################################
    #          DADOS POSITION 
    ################################# -->
    <!-- {{ newData }} -->
    <div v-if="processItem.type == 'position'">
      <div v-if="newData != null">
        <span class="titleBlue bold">Atual: </span>
        <div class="subItem">
          <span class="textBlueDark" v-if="newData.name"
            >Nome: {{ newData.name }}</span
          >
        </div>
      </div>
      <div v-if="oldData != null" class="oldData">
        <hr />
        <span class="bold">Antigo: </span> <br />
        <div class="subItem">
          <span v-if="oldData.name">Nome: {{ oldData.name }}</span>
        </div>
      </div>
    </div>

    <!-- ################################
    #          DADOS OPPOSITE
    ################################# -->
    <!-- {{ newData }} -->
    <div v-if="processItem.type == 'opposite'">
      <div v-if="newData != null">
        <span class="titleBlue bold">Atual: </span>
        <div class="subItem">
          <span class="textBlueDark" v-if="newData.name"
            >Nome: {{ newData.name }}</span
          >
        </div>
      </div>
      <div v-if="oldData != null" class="oldData">
        <hr />
        <span class="bold">Antigo: </span> <br />
        <div class="subItem">
          <span v-if="oldData.name">Nome: {{ oldData.name }}</span>
        </div>
      </div>
    </div>

    <!-- ################################ 
    #           DADOS NOTA 
    ################################# -->
    <div v-if="processItem.type == 'note'">
      <!-- {{ newData }} -->
      <div v-if="newData != null" class="noteItem">
        <span class="titleBlue bold">Atual: </span>
        <div class="subItem">
          <p class="textBlueDark" v-if="newData.title">
            <span class="bold">Titulo:</span> {{ newData.title }}
          </p>
          <p class="textBlueDark" v-if="newData.notes">
            <span class="bold">Nota:</span> {{ newData.notes }}
          </p>
          <p class="textBlueDark" v-if="newData.rememberDate">
            <span class="bold">lembrete:</span>
            {{ newData.rememberDate | formatDateString }}
          </p>
        </div>
      </div>
      <div v-if="oldData != null" class="oldData">
        <hr />
        <span class="bold">Antigo: </span> <br />
        <div class="subItem">
          <p v-if="oldData.title">
            <span class="bold">Titulo:</span> {{ oldData.title }}
          </p>
          <p v-if="oldData.notes">
            <span class="bold">Nota:</span> {{ oldData.notes }}
          </p>
          <p v-if="oldData.rememberDate">
            <span class="bold">lembrete:</span>
            {{ oldData.rememberDate | formatDateString }}
          </p>
        </div>
      </div>
    </div>

    <!-- ################################
    #            ÍCONES
    ################################# -->

    <!-- ##############################
    #     FOOTER - ÍCONE - FICHEIRO 
        ################################# -->
    <div class="bottom textBlueDark" >
      <div
        v-if="
          processItem.type == 'attachment' &&
          fileNull != false &&
          processItem.operation != 'Remoção'
        "
        class="deleteNote"
        @click.prevent="getFile(newData.filename, newData.id)"
      >
        <div>
          <img src="@/assets/svg/timeline/downloadIcon.svg" />
        </div>
      </div>
      <div>
        <button
          v-if="processEdit && fileNull != false"
          slot="delete"
          class="delete"
          @click.prevent="deleteFileProcess(newData.id)"
        ></button>
      </div>
    
    <div v-if="
          processItem.type == 'attachment' && fileNull != true && processItem.operation != 'Remoção'">
      <span class="textRed">Removido</span>
    </div>
    </div>
    <!-- ##############################
    #     FOOTER - ÍCONES NOTAS 
    ################################ -->
    <div
      class="bottom textBlueDark"
      v-if="
        processItem.type == 'note' &&
        newData != null &&
        noteNull != false &&
        this.process.notes.length > 0
      "
    >
      <!--#   ALERT ################################ -->
      <div v-if="this.processItem.type == 'note' && alertType == 'icon'">
        <img src="@/assets/svg/timeline/alertIcon.svg" />
      </div>
      <!--#  TOOLTIP - NOTE  ################################ -->
      <div class="tooltip">
        <img
          src="@/assets/svg/timeline/editNote.svg"
          @click.prevent="modalNote(newData.id)"
          class="hover"
        />
        <!-- <span class="tooltiptext">
            <div @click.prevent="modalNote(newData.id)">
              {{ newData.id }}
              <p>Data: {{ newData.date }}</p>
              <p>Lembrete: {{ newData.rememberDate | formatDateReverse }}</p>
              <p>Título: {{ newData.title }}</p>
              <p>Nota: {{ newData.notes }}</p>

              {{ alertType == "icon" }}
            </div>
          </span> -->
      </div>
      <!--  #  DELETE   ################################ -->
      <div class="deleteNote" @click.prevent="deleteNote(newData.id)">
        <!-- this.processItem.operation != 'remove' && -->
        <img src="@/assets/svg/timeline/deleteIcon.svg" />
      </div>
    </div>

    <!-- ##############################
    #     FOOTER - ÍCONES TAXAS 
    ################################ -->
    <!-- {{newData.id}} -->
    <div class="bottom textBlueDark" v-if="processItem.type == 'tax'">
      <div>
        <img
          src="@/assets/svg/timeline/editNote.svg"
          @click.prevent="modalTax(newData.id)"
          class="hover"
        />
      </div>
      <!-- <div
        v-if="processItem.operation != 'Remoção'"
      >       -->
        <div v-if="processItem.operation != 'Remoção' && newData != null && taxIconNull != false">
          <button
            v-if="processEdit"
            slot="delete"
            class="delete"
            @click.prevent="deleteProcessTax(newData.id)"
          ></button>
        </div>
      <!-- </div> -->
      <div v-if="
          taxIconNull != true && processItem.operation != 'Remoção'">
      <span class="textRed">Removido</span>
    </div>
    </div>
    <!-- ##############################
    #   FOOTER - ÍCONES OPPOSITES 
    ################################ -->
    <div class="bottom textBlueDark">
      <div class="iconsTimeline" v-if="processItem.path != null">
        <!--#   ÍCONE - LINK 
        ################################ -->
        <router-link :to="{ path: processItem.path + `${newData.id}` }">
          <img src="@/assets/svg/timeline/linkIcon.svg" />
        </router-link>
        
        <div>
          <div v-if="processItem.type == 'opposite'">
            <div v-if="newData != null && oppositeIconNull != false">
              <button
                v-if="processEdit"
                class="delete"
                @click.prevent="deleteProcessOpposite(newData.id)"
              ></button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>  

<script>
import NoteForm from "@/components/legal/NoteForm.vue";
import ArchiveItem from "@/components/legal/ArchiveItem.vue";
import { formatDate } from "@/helpers.js";
// import BtnChoice from "@/components/items/BtnChoice.vue";
// import { getLastDate } from "@/helpers.js";
import { alertDate } from "@/helpers.js";

import { mapState, mapGetters } from "vuex";

export default {
  name: "Timeline",
  props: [
    "process",
    "processItem",
    "processTypes",
    "processCourts",
    "processPositions",
    "processOpposites",
    "processTaxes",
    "processTypeTaxes",
  ],
  // "timeline",
  data() {
    return {
      noteNull: false,
      alertType: null,
      classOperation: null,
      fileNull: false,
      oppositeIconNull: false,
      taxIconNull: false,
      taxType: null,
      oldTaxType: null,
      // rememberNoteBtn: false,
      // url: null,
      // path: null,
    };
  },
  components: {
    NoteForm,
    ArchiveItem,
    // BtnChoice,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "loadingDownloadImg",
      "selectedId",
      "loadingImg",
    ]),
    ...mapState("user", ["user"]),
    ...mapState("legal", [
      "loadingProcess",
      "showModalProcessNote",
      "noteItem",
      "selectedFile",
    ]),
    // ...mapGetters("legal", ["timeline"]),
    ...mapGetters("user", [
      "processEdit",
      "processAccess",
      "processAnalyse",
      "processManager",
    ]),
    ...mapGetters("legal", ["processNoteReply", "processNoteReset"]),

    newData() {
      //NOTA - zerando ativação dos ícones edit, delete e alert
      this.noteNull = false;
      this.alertType = "nothing";

      //Função para puxar dados atuais
      var newData;
      if (
        this.processItem.newData != null &&
        this.processItem.newData != undefined
      ) {
        newData = this.processItem.newData;
      } else {
        newData = null;
      }

      //########################################################
      //FICHEIRO - função de ativação do icone de download
      //########################################################
      if (
        this.processItem.type == "attachment" &&
        this.processItem.operation != "Remoção" &&
        newData != null
      ) {
        if (this.process.files != null && this.process.files.length > 0) {
          this.process.files.find((file) => {
            // console.log("file", file.id);
            // console.log("newData", newData.id);
            if (file.id == newData.id) {
              this.fileNull = true;
            }
          });
        } else {
          this.fileNull = false;
        }
      }
      //########################################################
      //NOTA - função de ativação dos ícones edit, delete e alert
      //########################################################
      if (this.processItem.type == "note") {
        this.process.notes.find((note) => {
          if (note.id == newData.id && note.title == newData.title) {
            if (note.modified == newData.modified) {
              this.noteNull = true;
            }
          }
          if (
            newData.rememberDate != null &&
            newData.rememberDate != undefined
          ) {
            let date = newData.rememberDate;
            if (note.id == newData.id && note.rememberDate == date) {
              this.rememberNoteBtn = true;
              this.alertType = alertDate(new Date(note.rememberDate), 60)
                ? "icon"
                : "nothing";
            } else {
              this.rememberNoteBtn = false;
            }
          }
        });
      }
      //########################################################
      //Interveniente - função de ativação dos ícones edit, delete e alert
      //########################################################
      if (this.processItem.type == "opposite") {
        this.process.oppositeList.find((opposite) => {
          if (opposite.id == newData.id) {
            if (opposite.modified == opposite.modified) {
              this.oppositeIconNull = true;
            } else {
              this.oppositeIconNull = false;
            }
          }
        });
      }
      if (
        this.processItem.type == "opposite" &&
        this.processItem.operation != "Remoção" &&
        newData != null
      ) {
        if (
          this.process.oppositeList != null &&
          this.process.oppositeList.length > 0
        ) {
          this.process.oppositeList.find((opposite) => {
            if (opposite.id == newData.id) {
              this.oppositeIconNull = true;
            }
          });
        } else {
          this.oppositeIconNull = false;
        }
      }
      //########################################################
      // tax - função de ativação dos ícones edit, delete e alert
      //########################################################

      if (
        this.processItem.type == "tax" &&
        this.processItem.operation != "Remoção" &&
        newData != null
      ) {
        if (this.process.taxes != null && this.process.taxes.length > 0) {
          this.process.taxes.find((tax) => {
            if (tax.id == newData.id) {
              this.taxIconNull = true;
            }
          });
        } else {
          this.taxIconNull = false;
        }
      }
      if (this.processItem.type == "tax" && newData.taxTypeID != null) {
        if (this.processTypeTaxes != null) {
          this.taxType = this.processTypeTaxes.find((processTypeTaxe) => {
            return processTypeTaxe.id == newData.taxTypeID;
          });
        } else {
          this.taxType = null;
        }
      }
      return newData;
    },
    oldData() {
      var oldData;
      if (
        this.processItem.oldData != null &&
        this.processItem.oldData != undefined
      ) {
        oldData = this.processItem.oldData;
      } else {
        oldData = null;
      }

      if (
        this.processItem.type == "tax" &&
        oldData != null &&
        oldData.taxTypeID != null
      ) {
        if (this.processTypeTaxes != null) {
          this.oldTaxType = this.processTypeTaxes.find((processTypeTaxe) => {
            return processTypeTaxe.id == oldData.taxTypeID;
          });
        } else {
          this.oldTaxType = null;
        }
      }
      return oldData;
    },    
    // Tipo de taxa
    //##################################################
    //                 PROCESS TAX
    //##################################################
  },
  methods: {
    modalTax(id){
      this.$emit("modalTax", id, "update");
      // console.log("id", id)
      // let tax = this.process.taxes.filter((tax) => tax.id == id)
      let taxItem;
      for (let index in this.process.taxes){
        // console.log(taxItem[index])
        if(this.process.taxes[index].id == id){
          taxItem = {
            value: this.process.taxes[index].value,
            taxTypeID: this.process.taxes[index].taxTypeID,
            paymentDate: formatDate(new Date( this.process.taxes[index].paymentDate)),            
          }
          return this.$store.commit("legal/UPDATE_PROCESS_TAX", taxItem)
        }
      }
      // this.$store.commit("legal/UPDATE_PROCESS_TAX", `${tax}`)
      
      // console.log(tax)
    },
    deleteProcessTax(id) {
      this.$emit("modalDelTax", id);
    },
    // modalEditTax(id){
    //   this.$emit("modalTax", id);
    // },


    deleteProcessOpposite(id) {
      this.$emit("modalDelOpposite", id);
    },

    deleteNote(id) {
      this.$emit("modalDelNote", id);
    },
    modalNote(id) {
      // console.log("chamou");
      this.$store.commit("legal/UPDATE_PROCESS_NOTE", this.rememberDateReset);
      this.$emit("modalNote", id, "update");
    },
    // File #################
    onUpload(id) {
      const formData = new FormData();
      formData.append("file", this.selectedFile, this.selectedFile.name);
      // console.log("formData", formData);
      this.$store.dispatch("legal/onUploadProcessTax", {
        idProcess: this.process.id,
        idProcessTax: `${id}`,
        data: formData,
        namefile: this.selectedFile.name,
      });
    },
    onFileSelected(event) {
      this.$store.commit("legal/UPDATE_SELECTEDFILE", event.target.files[0]);
    },
    cleanFile() {
      this.$store.commit("legal/UPDATE_SELECTEDFILE", null);
    },
    getFile(filename, archiveID) {
      this.$store.dispatch("attachment/getFile", {
        archiveID: `${archiveID}`,
        fileName: `${filename}`,
      });
    },
    getImgUrl(svg) {
      return require("@/assets/svg/timeline/" + svg);
    },
    // NOTE #################
    // openModalNote(id) {
    //   // this.$store.dispatch("legal/getCall", { idCall: `${id}` });
    //   this.$store.dispatch("legal/openModalNote");
    // },
    // closeModalNote() {
    //   this.$store.dispatch("legal/closeModalNote");
    //   this.$store.commit("legal/UPDATE_PROCESS_NOTE", this.processNoteReset);
    //   // this.getCalls();
    // },
    // addProcessNote() {
    //   this.$store.dispatch("legal/addProcessNote", {
    //     idProcess: this.idProcess,
    //     processNote: this.processNoteReply,
    //   });
    // },
    // updateCallNote(id) {
    //   this.$store.dispatch("legal/updateProcessNote", {
    //     idProcess: this.process.id,
    //     processNote: this.processNoteReply,
    //   });
    //   // this.closeModalNote();
    // },
    //    FICHEIROS MODAL DELETE
    //######################################################
    deleteFileProcessTax(id, taxID){
      this.$emit("modalDelFileTax", id, taxID);
    },
    deleteFileProcess(id) {
      this.$emit("modalDelFile", id);
    },
  },
  // scrollBehavior(to, from) {
  //   console.log(to.hash);
  //   location.hash = "#" + "dd0946c7-7e63-4b63-9d13-d691a4105fdd";
  // },
  // scrollBehavior(to, from, savedPosition) {
  //   if (to.hash) {
  //     return { selector: to.hash };
  //   }
  // },
  mounted() {
    // console.log(this.$route.hash);
    // console.log(
    //   this.processItem.type == "note" ? this.processItem.newData : "null"
    // );
    // if (this.$route.hash != "") {
    //   const el = document.querySelector(this.$route.hash);
    //   el && el.scrollIntoView();
    // }
    if (this.$route.hash != "") {
      // console.log(this.$route.hash);
      const hash = this.$route.hash.replace("#", "");
      // console.log("hash", hash);
      const el = document.getElementById(hash);
      el && el.scrollIntoView();
    }
  },
  created() {
    // console.log("#" + "dd0946c7-7e63-4b63-9d13-d691a4105fdd");
    // console.log(location.hasg);
    // if (location.hash == "#" + "dd0946c7-7e63-4b63-9d13-d691a4105fdd") {
    //   window.scrollTo(element);
    // }
    // scrollBehavior (to, from) {
    //   // console.log(to)
    //   // location.hash = "#" + "dd0946c7-7e63-4b63-9d13-d691a4105fdd";
    // }
  },
};
</script>

<style scoped>
.bottom.textBlueDark div {
    display: flex;
}
/* files */
.fileAddSelect {
  margin-top: 10px;
}
.fileSelect {
  display: grid;
  justify-content: flex-start;
  margin-top: 10px;
}
.clearFile {
  font-size: 24px;
  line-height: 28px;
  margin-left: 10px;
  font-weight: bold;
  color: #00732c;
  padding-top: 5px;
  cursor: pointer;
}
.containerFile {
  display: grid;
  align-content: center;
  align-items: baseline;
  grid-gap: 30px;
  margin: 20px 0 40px 0;
}
.inputNone {
  display: none;
}
.noArchive {
  margin: 20px 0 30px 0;
  margin-left: 10px;
}

.spot {
  font-size: 14px;
  color: #015eb0;
}
.list {
  /* display: grid; */
  align-content: center;
}
.inputList {
  display: grid;
  justify-content: center;
  justify-items: center;
}
/*INPUT FILE botão e arquivo */
.inputFile {
  cursor: pointer;
  font-size: 14px;
  width: 240px;
  border: 1px solid #015eb0;
  background: #eff7fe;
  border-radius: 9px;
  padding: 10px;
  color: #1f2b42;
  margin: 3px 10px 0 0;
  border-style: dotted;
}
/* fim files  */
.bold {
  font-weight: bold;
}

.textGray {
  white-space: nowrap;
}
.oldData,
.textGray,
.titleBlue,
.textBlueDark {
  font-size: 16px;
  line-height: 28px;
}

.details .titleBlue,
.details .textGreen,
.details .textRed {
  font-size: 18px;
  line-height: 28px;
  font-weight: normal;
}

.oldData p,
.oldData {
  color: #989eaa;
}

.itemContainer .oldData hr {
  display: block;
  height: 5px;
  padding: 0;
  border: 0;
  border-top: 1px solid #dee0e4;
  margin: 20px auto;
  width: 100%;
}

.noteItem {
  margin-bottom: 40px;
}
.fileSubItem{
  margin-left: 20px;
  margin-bottom: 20px;
}
.subItemTax {
  margin-left: 20px;
  overflow-y: auto;
  max-height: 200px;
  /* margin-bottom: 20px; */
}
.subItem {
  margin-left: 20px;
  overflow-y: auto;
  max-height: 200px;
  margin-bottom: 20px;
}
.subItem::-webkit-scrollbar {
  width: 10px;
  background: #ecebeb;
  border-radius: 7px;
}

.subItem::-webkit-scrollbar-thumb {
  background: #c5c5c5;
  opacity: 0.5;
  border-radius: 7px;
}
.hover {
  cursor: pointer;
}

.deleteNote {
  cursor: pointer;
}
.row section .bottom a {
  background: none !important;
}
.tooltip .tooltiptext {
  right: 5px;
}
.iconsTimeline {
  display: flex;
  grid-gap: 10px;
  align-content: center;
  align-items: center;
  margin-top: 20px;
}
.wrapper {
  max-width: 1080px;
  margin: 50px auto;
  padding: 0 20px;
  position: relative;
}
.wrapper .center-line {
  position: absolute;
  height: 100%;
  width: 4px;
  background: #fff;
  left: 50%;
  top: 20px;
  transform: translateX(-50%);
  background: #ececec;
}
.wrapper .row {
  display: flex;
}
.wrapper .row1 {
  justify-content: flex-start;
}
.wrapper .row2 {
  justify-content: flex-end;
}
.wrapper .row section {
  background: #fff;
  border-radius: 9px;
  width: 100%;
  padding: 20px;
  position: relative;
  box-shadow: 0 4px 8px rgb(30 60 90 / 20%);
  margin-bottom: 10px;
}
.row1 section::before {
  right: -7px;
}
.row2 section::before {
  left: -7px;
}
.row section .icon,
.center-line .scroll-icon {
  position: absolute;
  background: #f2f2f2;
  height: 40px;
  width: 40px;
  text-align: center;
  line-height: 40px;
  border-radius: 50%;
  color: #10a4e8;
  font-size: 17px;
  box-shadow: 0 0 0 4px #fff, inset 0 2px 0 rgba(0, 0, 0, 0.08),
    0 3px 0 4px rgba(0, 0, 0, 0.05);
}
.center-line .scroll-icon {
  bottom: 0px;
  left: 50%;
  font-size: 25px;
  transform: translateX(-50%);
}
.row1 section .icon {
  top: 15px;
  right: -60px;
  display: grid;
  justify-content: center;
  align-items: center;
}
.row2 section .icon {
  top: 15px;
  left: -60px;
  display: grid;
  justify-content: center;
  align-items: center;
}

.itemContainer hr {
  display: block;
  height: 5px;
  padding: 0;
  border: 0;
  border-top: 1px solid #c3c4c7;
  margin: 5px auto 20px auto;
  width: 100%;
}

.row section .details {
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: space-between;
  margin-top: 10px;
  grid-gap: 10px;
  margin-bottom: 0px;
}
.row section .bottom {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  /* margin-top: 40px; */
  margin-bottom: 10px;
  grid-gap: 10px;
}
.row section .details .title {
  font-size: 22px;
  font-weight: 600;
}
.row section p {
  margin: 10px 0 17px 0;
}
.row section .bottom a {
  text-decoration: none;
  background: #10a4e8;
  color: #fff;
  /* padding: 7px 15px; */
  border-radius: 5px;
  /* font-size: 17px; */
  font-weight: 400;
  transition: all 0.3s ease;
}
.row section .bottom a:hover {
  transform: scale(0.97);
}
@media (max-width: 790px) {
  .wrapper .center-line {
    left: 40px;
  }
  .wrapper .row {
    margin: 30px 0 3px 60px;
  }
  .wrapper .row section {
    width: 100%;
  }
  .row1 section::before {
    left: -7px;
  }
  .row1 section .icon {
    left: -60px;
  }
}
/* @media (max-width: 440px) {
  .wrapper .center-line,
  .row section::before,
  .row section .icon {
    display: none;
  }
  .wrapper .row {
    margin: 10px 0;
  }
} */
</style>
