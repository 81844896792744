<template>
  <transition>
    <ul class="error" v-if="errorLogin.length">
      <li v-for="(errorLog, index) in errorLogin" :key="index">
        {{ errorLog }}
      </li>
    </ul>    
  </transition>
</template>

<script>
export default {
  name: "ErrorLogin",
  props: {
    errorLogin: Array
  },
};
</script>
<style>
.error {
  margin: 20px auto;
}

.error li {
  color:  #87898B;
  /* color:  #dc3545; */
  text-align: center;
  font-size: 1.5rem;
  padding: 10px 0;
}

</style>