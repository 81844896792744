<template>
  <section class="">
    <h1>Contactos</h1>
    <div class="containerContact">
      <div class="titleContainer">
        <h3>Dados do Contacto</h3>
        <div class="menuDropdown" v-if="contactEdit && contact != null">
          <BtnConfig>
            <span slot="title">
              Controlo dos <br />
              Contactos</span
            >
            <ul slot="itemsMenu" class="menuDropdownContent">
              <li>
                <router-link
                  class="collabItens"
                  :to="{
                    name: 'contacto-editar',
                    params: { idContact: idContact },
                  }"
                >
                  Editar Contacto
                </router-link>
              </li>
              <li @click.prevent="confirmDeleteContact(contact.id)">
                Apagar Contacto
              </li>
            </ul>
          </BtnConfig>
        </div>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <slot />
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'contactos' }"
              >Lista de contactos</router-link
            >
          </li>
          <li class="breadcrumb-item-active">
            <span> Dados do Contacto</span>
          </li>
        </ol>
      </nav>

      <div v-if="loadingContact">
        <Loading />
      </div>
      <div v-else>
        <!--Quando deletam o contacto e tenta acessar no futuro-->
        <div v-if="contact == null" class="textRed noContact">
          Contacto não encontrado. Este contacto foi removido?
        </div>

        <!-- {{ contact }} -->
        <article v-if="contact">
          <ul>
            <li class="items itemList" v-if="contact.name">
              <span class="titleBlue">Nome</span>
              <div class="list">
                <span class="textGray">{{ contact.name }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="contact.description">
              <span class="titleBlue">Descrição</span>
              <div class="list">
                <span class="textGray">{{ contact.description }}</span>
              </div>
            </li>
            <li class="items itemList" v-if="contact.created">
              <span class="titleBlue">Criado</span>
              <div class="list">
                <span class="textGray">{{
                  contact.created | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ createdContact }}</span> -->
              </div>
            </li>
            <li class="items itemList" v-if="contact.modified">
              <span class="titleBlue">Modificado</span>
              <div class="list">
                <span class="textGray">{{
                  contact.modified | formatDateReverse
                }}</span>
                <!-- <span class="textGray">{{ modifiedContact }}</span> -->
              </div>
            </li>
            <li class="items itemList">
              <span class="titleBlue">E-mail</span>
              <ul class="items">
                <div v-if="loadingEmail">
                  <p><LoadingCircle /></p>
                </div>
                <div v-else>
                  <div v-if="contact.emails && contact.emails.length > 0">
                    <li
                      class="textGray"
                      v-for="email in contact.emails"
                      :key="email.index"
                    >
                      <EmailItem :email="email" :idContact="idContact" />
                    </li>
                  </div>
                  <div v-else>
                    <p class="textGray">Não possui e-mails</p>
                  </div>
                </div>
                <!-- <transition mode="out-in">
                  <li>
                    <EmailForm :email="contact.email" :errors="errors">
                      <button class="btn btn-form" @click.prevent="addEmail">
                        Adicionar
                      </button>
                    </EmailForm>
                  </li>
                </transition> -->
              </ul>
            </li>

            <li class="items itemList">
              <span class="titleBlue">Telefone</span>
              <ul class="items">
                <div v-if="loadingPhone">
                  <p><LoadingCircle /></p>
                </div>
                <div v-else>
                  <div v-if="contact.phones && contact.phones.length > 0">
                    <li
                      class="phoneHistory textGray"
                      v-for="phone in contact.phones"
                      :key="phone.id"
                    >
                      <PhoneItem :phone="phone" :idContact="idContact" />
                      <div
                        class="historyIcon"
                        @click.prevent="searchPhone(phone)"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="21.576"
                          height="19.472"
                          viewBox="0 0 21.576 19.472"
                        >
                          <g
                            id="historyIconSmal"
                            transform="translate(-181.764 -68.193)"
                          >
                            <path
                              id="Caminho_22954"
                              data-name="Caminho 22954"
                              d="M203.2,76.358a9.7,9.7,0,0,0-19.1-.349c-.087.467-.142.943-.2,1.448-.018.165-.038.334-.059.508l-.209-.224c-.23-.246-.44-.471-.652-.695a.82.82,0,0,0-.569-.305h0a.615.615,0,0,0-.444.213c-.206.214-.357.565.1,1.021l.681.681c.4.4.808.805,1.2,1.214a.79.79,0,0,0,.6.309.838.838,0,0,0,.593-.308c.519-.537,1.058-1.072,1.58-1.59l.264-.263c.164-.163.6-.6.152-1.058a.632.632,0,0,0-.453-.22h0a.967.967,0,0,0-.642.378c-.208.224-.414.452-.619.679l-.218.239-.005,0c.008-.128.013-.257.019-.386a8.744,8.744,0,0,1,.077-.97,8.432,8.432,0,1,1,1.419,6.078c-.035-.05-.07-.1-.1-.16-.137-.218-.293-.466-.549-.514a.647.647,0,0,0-.514.162.667.667,0,0,0-.3.443,1.04,1.04,0,0,0,.331.731c.032.04.064.079.093.117a9.43,9.43,0,0,0,7.773,4.131,11.671,11.671,0,0,0,2.152-.208A9.712,9.712,0,0,0,203.2,76.358Z"
                              fill="#28a745"
                            />
                            <path
                              id="Caminho_22955"
                              data-name="Caminho 22955"
                              d="M192.946,77.962a1.264,1.264,0,0,0,.61.648c.4.185.8.36,1.228.542l2.295,1a.639.639,0,0,0,.262.056.659.659,0,0,0,.613-.9.652.652,0,0,0-.35-.364c-2.231-.964-2.325-1-2.326-1h0l-.239-.1c-.161-.067-.321-.134-.483-.2a.892.892,0,0,1-.576-.625c-.232-.737-.483-1.482-.725-2.2-.194-.575-.388-1.151-.574-1.728a.861.861,0,0,0-.4-.551.63.63,0,0,0-.507-.005c-.269.1-.546.343-.334.967q.232.69.461,1.383C192.237,75.895,192.584,76.94,192.946,77.962Z"
                              fill="#28a745"
                            />
                          </g>
                        </svg>
                      </div>
                    </li>
                  </div>
                  <div v-else>
                    <p class="textGray">Não possui telefones</p>
                  </div>
                </div>
              </ul>
            </li>
          </ul>
        </article>
        <article class="infoHistory" v-if="searchHistory == false">
          Prima
          <img
            class="iconInfoSearchHistory"
            src="../../assets/svg/historyIconSmall.svg"
            alt="icone histórico de chamada"
          />
          para ver o histórico de chamadas do contacto.
        </article>
        <article class="callsHistory" v-if="searchHistory">
          <div v-if="loadingCalls">
            <Loading />
          </div>
          <div v-else>
            <h3>Histórico de chamadas</h3>
            <br /><br />
            <CallHistory />
          </div>
        </article>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <div class="modalDel">
      <div
        class="overlay"
        v-if="showModalContact"
        @click="closeModalDelContact"
      ></div>
      <div class="modalDel_container" v-if="showModalContact">
        <button class="close btn" @click="closeModalDelContact">x</button>
        <p class="delItem">
          <span>Você tem certeza que deseja remover este contacto?</span>
        </p>
        <div class="containerDel"></div>
        <div class="buttonsDel">
          <input
            type="submit"
            class="btn"
            value="Cancel"
            @click.prevent="closeModalDelContact"
          />
          <button class="btnDel" @click.prevent="deleteContact">Delete</button>
        </div>
      </div>
    </div>
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import EmailItem from "@/components/contact/EmailItem.vue";
import PhoneItem from "@/components/contact/PhoneItem.vue";
import CallHistory from "@/components/telecom/CallHistory.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "ContactInfos",
  props: ["idContact"],
  data() {
    return {
      searchHistory: false,
    };
  },

  components: {
    EmailItem,
    PhoneItem,
    CallHistory,
  },
  computed: {
    ...mapState([
      "errors",
      "items",
      "itemsError",
      "wait",
      "selectedId",
      "loadingEmail",
      "loadingPhone",
      "loadingCalls",
      "limit",
      "currentPage",
    ]),
    ...mapState("contact", ["contact", "loadingContact", "showModalContact"]),
    ...mapState("user", ["user", "permissions"]),
    ...mapGetters("contact", ["contactReset"]),

    ...mapState("telecom", ["call", "startDate", "endDate"]),
    ...mapGetters("telecom", ["startDateReply", "endDateReply"]),
    //######################################################
    //     PERMISSÕES DO CONTACTO
    //######################################################
    contactEdit() {
      return this.contactEditPermission();
    },
  },
  methods: {
    searchQuery() {
      const phone = Number.parseInt(this.$route.query.phone);
      return isNaN(phone)
        ? (this.searchHistory = false)
        : this.getSearch(phone);
    },
    getSearch(phone) {
      this.searchHistory = true;
      this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
      this.$store.dispatch("telecom/getCalls", {
        fromPhone: Number.parseInt(phone),
      });
    },
    //##########################################
    // Contacto Search da lista dispara aqui
    //##########################################
    searchPhone(phone) {
      (this.searchHistory = true), this.$store.commit("UPDATE_CURRENTPAGE", 1); //ZERAR PAGINATION
      // this.getSearch(phone);
      this.$store.dispatch("telecom/getCalls", {
        startDate: this.startDateReply,
        endDate: this.endDateReply,
        fromPhone: Number.parseInt(phone),
      });
      this.$router.push({
        query: { phone: Number.parseInt(phone) },
      });
    },

    //######################################################
    //     PERMISSÕES DO CONTACTO
    //######################################################
    //Criar/Editar Colaboradores
    contactEditPermission() {
      const superUser = this.user.superUser;
      const contactEdit = this.user.permissions.find(
        (p1) => p1.code == "contactEdit"
      );
      if (superUser == true || contactEdit != undefined) {
        return true;
      } else {
        return false;
      }
    },
    //######################################################
    //     DELETE
    //######################################################
    openModalDelContact() {
      this.$store.dispatch("contact/openModalDelContact");
    },
    closeModalDelContact() {
      this.$store.dispatch("contact/closeModalDelContact");
    },
    confirmDeleteContact(id) {
      // console.log(id);
      this.$store.dispatch("contact/confirmDeleteContact", {
        id: `${id}`,
      });
    },

    deleteContact() {
      // console.log("contactId", this.selectedId.id);
      // this.$store.commit("contact/UPDATE_DELETECONTACT", this.selectedId.id);
      this.$store.dispatch("contact/deleteContact", {
        id: this.selectedId.id,
        redirect: true,
      });
      this.closeModalDelContact();
    },
  },

  created() {
    // console.log(this.$route);
    this.searchQuery();
    // this.$store.commit("contact/RESET_PHONE", null);
    this.$store.dispatch("contact/getContact", {
      idContact: this.idContact,
    });
    // this.getSearch2();
  },
};
</script>

<style scoped>
.callsHistory {
  margin-top: 30px;
}
.noContact,
.iconInfoSearchHistory {
  margin: 0 10px;
}
.infoHistory {
  display: flex;
  align-self: center;
  margin-top: 20px;
}
.historyIcon {
  display: grid;
  align-self: center;
  cursor: pointer;
}
.phoneHistory {
  display: grid;
  grid-template-columns: 200px 1fr;
  align-items: center;
}
hr {
  padding: 0px;
  margin-bottom: 10px;
}
.containerContact {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.navBreadcrumb {
  margin-top: 10px;
}
/* .titleContainer {
  margin-top: 80px;
} */
h3 {
  padding: 20px 0 0 0;
  text-align: left;
  margin-left: 10px;
}

.titleBlue {
  /* text-align: end; */
  padding: 0 10px;
}

li.items {
  display: grid;
  grid-template-columns: 150px 1fr;
  /* height: 30px; */
}

li.info::before {
  content: "•";
  color: #00bf48;
  display: inline-block;
  width: 1em;
}

@media screen and (max-width: 690px) {
  li.items {
    display: grid;
    grid-template-columns: 1fr;
    /* height: 30px; */
  }
  .textGray {
    padding: 0 0 0 10px;
  }
  .items li {
    padding: 0 0 0 10px;
  }
}

/* HISTóRICO */
.btnNote {
  margin: 20px 0;
}
.titleCollab {
  display: grid;
  grid-template-columns: repeat(2, 140px) 150px 150px 130px 300px;
  overflow: hidden;
  padding: 0 10px;
}

.titleContainer {
  align-items: center;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 1000px);
  justify-content: center;
  align-content: flex-start;
}
.menuItem {
  display: grid;
  grid-template-columns: repeat(2, 180px);
  /* grid-gap: 5px; */
}
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 0;
  padding: 0;
  max-width: 100%;
  /* margin-bottom: 30px; */
}
.sidenav a,
.sidenav button {
  padding: 10px;
  display: block;
  background: #d8d8d8;
  /* margin-top: 30px; */
  border-radius: 9px 9px 0 0;
  color: #1f2b42;
  /* max-width: 200px; */
}

.sidenav a:hover,
.sidenav button:hover,
.sidenav a.router-link-exact-active {
  background: #28a745;
  color: #fff;
}

.activeLi a {
  background: #28a745;
  color: #fff;
}

.sidenav button {
  border: none;
  width: 100%;
  font-size: 1rem;
  text-align: left;
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  cursor: pointer;
  color: #373946;
}

h3 {
  margin-left: 10px;
}

.center {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
}

svg#note {
  width: 24px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #01ad43;
  /* fill: #009fea; */
}

svg#noteAdd {
  width: 32px;
  margin: 0px 40px;
  cursor: pointer;
  fill: #9a9ba0;
}

.modal_container {
  max-width: 500px;
}

.modal .modal_container {
  position: fixed;
}

@media screen and (max-width: 690px) {
  .titleCollab {
    display: none;
  }
  .containerTelecom {
    margin: 80px 0;
    padding: 80px 20px 40px auto;
    display: grid;
    grid-template-columns: minmax(300px, 1000px);
    justify-content: center;
    align-content: flex-start;
  }
}
</style>
