<template>
  <form action="">
    <div class="">
      <div v-if="loadingProcess">
        <p><Loading /></p>
      </div>
      <div class="formInfos" v-else>
        <!-- VALUE -->
        <label for="title"> Título: </label>
        <input
          id="title"
          name="title"
          type="text"
          v-model="title"
          :class="validateTitle"
          @change="resetError"
        />
        <div v-if="!errors.indexOf('title')" class="notify">
          O título é um campo requerido
        </div>

        <!-- DATE -->
        <label for="date"> Data </label>
        <input
          id="date"
          type="date"
          v-model="date"
          autocomplete="off"
          :class="[validateDate]"
          @change="resetError"
        />
        <div v-if="!errors.indexOf('date')" class="notify2">
          A data de início é um campo requerido
        </div>
        <div v-if="!errors.indexOf('dateInvalid')" class="notify2">
          A data de início tem que ser menor ou igual que hoje.
        </div>

        <!-- NOTEE -->
        <label for="notes"> Descrição </label>
        <textarea
          id="notes"
          name="notes"
          v-model="notes"
          placeholder="Escreva aqui..."
        ></textarea>

        <!-- REMEMBERDATE -->
        <div class="grid-2">
          <label for="rememberDate"> Lembrete </label>
          <span @click.prevent="statusRememberDate"
            ><BtnChoice v-model="btnRememberDate"
          /></span>
        </div>
        <input
          v-if="btnRememberDate"
          id="rememberDate"
          type="date"
          v-model="rememberDate"
          autocomplete="off"
          :class="[validateRememberDate]"
          @change="resetError"
        />
        <div v-if="!errors.indexOf('rememberDate')" class="notify2">
          A data do lembrete tem que ser maior que hoje.
        </div>

        <div class="button">
          <slot></slot>
        </div>
      </div>
    </div>
  </form>
</template>
<script>
import BtnChoice from "@/components/items/BtnChoice.vue";
import { mapFields } from "@/helpers.js";
import { mapState, mapGetters } from "vuex";

export default {
  name: "MessageForm",
  // props: ["errors", "idOpposite"],
  props: ["errors", "processNote"],
  data() {
    return {
      contactName: [],
      // btnRememberDate: false,
    };
  },
  components: {
    BtnChoice,
  },
  mounted() {},
  computed: {
    validateTitle() {
      return this.errors.indexOf("title") ? "true" : "validate";
    },
    validateDate() {
      return this.errors.indexOf("date") ? "true" : "validate";
    },
    validateRememberDate() {
      return this.errors.indexOf("rememberDate") ? "true" : "validate";
    },
    ...mapFields({
      fields: ["title", "notes", "date", "rememberDate"], //nome dos campos
      module: "legal", //qual módulo está acessando
      base: "processNote", //colocar o state que vai receber
      mutation: "legal/UPDATE_PROCESS_NOTE", //nome da mutation que vai alterar o state
    }),
    ...mapFields({
      fields: ["btnRememberDate"], //nome dos campos
      module: "legal", //qual módulo está acessando
      base: "btnRememberDate", //colocar o state que vai receber
      mutation: "legal/UPDATE_PROCESS_NOTE_REMEMBERDATE", //nome da mutation que vai alterar o state
    }),
    // btnRememberDate: {
    //   get() {
    //     return this.$store.state.legal.btnRememberDate;
    //   },
    //   set(value) {
    //     this.$store.commit("legal/UPDATE_PROCESS_NOTE_REMEMBERDATE", {
    //       btnRememberDate: value,
    //     });
    //   },
    // },
    ...mapState(["loadingProcess"]),
    ...mapState("legal", [
      // "processOpposites",
      // "processTypes",
      // "processCourts",
      // "processPositions",
      // "btnRememberDate",
    ]),
    ...mapGetters("legal", ["rememberDateReset", "rememberDateReply"]),
    // processOpposite() {
    //   if (
    //     (this.processOpposites != null || this.processOpposites != undefined) &&
    //     (this.idOpposite != null || this.idOpposite != undefined)
    //   ) {
    //     return this.processOpposites.find((processPosition) => {
    //       return processPosition.id == this.idOpposite;
    //     }).name;
    //   }
    // },
  },
  methods: {
    resetError() {
      this.$store.commit("RESET_ERRORS", []);
    },
    statusRememberDate() {
      if (this.btnRememberDate == false) {
        this.$store.commit("legal/UPDATE_PROCESS_NOTE", this.rememberDateReset);
      }
      // console.log("status", this.btnRememberDate);
    },
    // activeStatusRememberDate() {
    //   // console.log("chamou", this.rememberDate != null);
    //   this.btnRememberDate = this.rememberDate != null ? true : false;
    // },
  },
  created() {
    // this.btnRememberDate = this.rememberDate != null ? true : false;
    // this.activeStatusRememberDate();
    // console.log("rememberDate", this.rememberDate != null);
    // console.log("id", this.id);
    // this.$store.dispatch("legal/getProcessOpposites");
    // this.$store.dispatch("legal/getProcessTypes");
    // this.$store.dispatch("legal/getProcessCourts");
    // this.$store.dispatch("legal/getProcessPositions");
  },
};
</script>

<style scoped>
.grid-2 {
  display: flex;
  grid-gap: 20px;
  margin-top: 10px;
  align-items: center;
}
.notify2 {
  margin: 10px 0;
}
select.option option {
  padding: 50px 0;
}
.formInfos {
  display: grid;
  grid-template-columns: 1fr;
  justify-content: flex-start;
  grid-gap: 10px;
  align-items: flex-start;
  /* margin-top: 20px; */
  align-content: center;
  max-width: 500px;
}
input.validate {
  margin: 0;
}

.button {
  display: flex;
  justify-content: flex-end;
  margin: 20px 0;
}
@media screen and (max-width: 690px) {
  .formInfos {
    grid-template-columns: 1fr;
    justify-items: flex-end;
    grid-gap: 10px;
    margin-right: auto;
    width: 100%;
  }
  label,
  input {
    width: 100%;
    justify-items: flex-start;
  }
}
</style>