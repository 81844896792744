<template>
  <section>
    <h1>Viaturas</h1>

    <div class="containerTelecom">
      <div class="titleContainer">
        <h3>Adicionar Inspeção da Viatura</h3>
      </div>
      <nav class="navBreadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <router-link :to="{ name: 'viaturas' }"
              >Lista de Viaturas</router-link
            >
          </li>
          <li class="breadcrumb-item">
            <router-link
              :to="{
                name: 'viatura',
                params: { idVehicle: idVehicle },
              }"
              >Dados da Viatura
            </router-link>
          </li>
          <li
            class="breadcrumb-item"
            v-if="inspections && inspections.length > 0"
          >
            <router-link
              :to="{
                name: 'inspecoes-viatura',
                params: { idVehicle: idVehicle },
              }"
              >Inspeções da Viatura
            </router-link>
          </li>
          <li class="breadcrumb-item-active">
            <span>Adicionar Inspeção da Viatura</span>
          </li>
        </ol>
      </nav>
      <div v-if="loadingVehicle">
        <Loading />
      </div>
      <div v-else class="containerForm">
        <InspectionForm :errors="errors">
          <button class="btn" @click.prevent="addInspection">Adicionar</button>
        </InspectionForm>
      </div>
    </div>
    <NotifySuccess :items="items" />
    <NotifyError :itemsError="itemsError" />
  </section>
</template>

<script>
import InspectionForm from "@/components/vehicle/InspectionForm.vue";
import { mapState, mapGetters } from "vuex";

export default {
  name: "AddVehicle",
  props: ["idVehicle"],
  components: {
    InspectionForm,
  },
  computed: {
    ...mapState(["errors", "items", "itemsError", "wait"]),
    ...mapState("vehicle", [
      "inspections",
      "loadingVehicle",
      "showModalDeleteTypeVehicle",
    ]),
    ...mapGetters("user", ["vehicleManager", "vehicleAnalysis", "vehicleEdit"]),
    ...mapGetters("vehicle", ["inspectionReset", "inspectionReply"]),
  },
  methods: {
    addInspection() {
      this.$store.dispatch("vehicle/addInspection", {
        inspection: this.inspectionReply,
        idVehicle: this.idVehicle,
      });
    },
  },
  created() {
    this.$store.dispatch("vehicle/getInspections", {
      idVehicle: this.idVehicle,
    });
    this.$store.commit("vehicle/UPDATE_INSPECTION", this.inspectionReset);
    this.$store.commit("RESET_ERRORS", []);
  },
};
</script>

<style scoped>
.breadcrumb {
  margin-left: -10px;
}
.containerTelecom {
  margin: 80px 0;
  padding: 80px 20px 40px auto;
  display: grid;
  grid-template-columns: minmax(350px, 800px);
  justify-content: center;
  align-content: flex-start;
}

@media screen and (max-width: 690px) {
  .containerTelecom {
    grid-template-columns: 1fr;
    justify-items: flex-start;
    grid-gap: 10px;
    margin: 30px 10px;
  }
  .containerForm {
    width: 100%;
  }
}
</style>